import React, { useState } from "react";
import { OrganizationChart } from "primereact/organizationchart";
import { Tag } from "primereact/tag"; // Importing Tag component for status tags
import ReportFiltersPage from "./components/report-filters/ReportFiltersPage";
import BusinessAdminLottie from "./components/lottie/business_admin.json";
import { Panel } from "primereact/panel";
import Lottie from "lottie-react";
import { ProgressBar } from "primereact/progressbar";
import Tooltip from "@mui/material/Tooltip";

import Moment from "moment";

const ReportsTrackerChart = ({ data, loggedInUserData }) => {
    const [filterParams, setFilterParams] = useState();
    const [filterQueryIsLoading, setFilterQueryIsLoading] = useState();
    const [reportsData, setReportsData] = useState();
    const [filterSelectedObjects, setFilterSelectedObjects] = useState();

    console.log("tracker reports data to be displayed is : ", reportsData);

    //
    // console.log("sample data to be displayed is : ", data);

    const nodeTemplate = (node) => {
        const statusSeverity = node?.report_status === "submitted" ? "success" : "danger";
        const approvalSeverity = node?.report?.spatie_current_status?.name === "approved" ? "success" : "warning";

        return (
            <div>
                {node?.label && <h5>{node.label}</h5>}
                <div>
                    {!node?.report?.spatie_current_status && node?.report && node?.report !== "No report available" && <Tag value="approved" severity={"success"} className="p-mr-2"></Tag>}
                    {node?.report?.spatie_current_status && (
                        <div>
                            <Tag severity={approvalSeverity} className="p-mr-2">
                                <Tooltip title={`Comment: ${node?.report?.spatie_current_status?.reason}`}>{node.report.spatie_current_status.name}</Tooltip>
                            </Tag>
                        </div>
                    )}
                    {node?.report === "No report available" && <Tag value="No report available" severity="info" className="p-mr-2" style={{ backgroundColor: "#f9c851", color: "#000" }}></Tag>}
                </div>

                {node?.report?.spatie_current_status && <p>Approved on: {new Date(node.report.spatie_current_status.created_at).toLocaleDateString()}</p>}
                {!node?.report?.spatie_current_status && node?.report && node?.report !== "No report available" && <p>Approved on: {new Date(node.report.created_at).toLocaleDateString()}</p>}
            </div>
        );
    };

    return (
        <div>
            <ReportFiltersPage
                reportsData={reportsData}
                setReportsData={setReportsData}
                filterQueryIsLoading={filterQueryIsLoading}
                setFilterQueryIsLoading={setFilterQueryIsLoading}
                filterParams={filterParams}
                setFilterParams={setFilterParams}
                filterSelectedObjects={filterSelectedObjects}
                setFilterSelectedObjects={setFilterSelectedObjects}
                loggedInUserData={loggedInUserData}
            />
            {filterQueryIsLoading && (
                <div style={{ margin: "20px 0" }}>
                    <ProgressBar mode="indeterminate" style={{ height: "6px" }} />
                </div>
            )}

            {!filterParams?.fyear_id || !filterParams?.quarter_id ? (
                <Panel style={{ marginBottom: "20px" }}>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            justifyContent: "center",
                            marginTop: "3rem",
                        }}
                    >
                        <div style={{ width: "40%" }}>
                            <h3 style={{ margin: "1rem" }}>Filter To Track Reports</h3>
                            <Lottie animationData={BusinessAdminLottie} loop={true} autoplay={true} />
                        </div>
                    </div>
                </Panel>
            ) : Array.isArray(reportsData) && reportsData.length > 0 && !filterQueryIsLoading ? (
                <OrganizationChart value={reportsData} nodeTemplate={nodeTemplate} />
            ) : !filterQueryIsLoading ? (
                <p>No data found Yet</p>
            ) : (
                <p></p>
            )}
        </div>
    );
};

export default ReportsTrackerChart;
