import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import ProjectReportForm from "./ProjectReportForm";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import BreadcrumbNav from "../../../../../../../components/mweComponents/BreadcrumbNav";

function ProjectReportFormViewPage() {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    let { state } = useLocation();
    let subProjectReports = state?.subProjectReports ? state?.subProjectReports : null;
    let selectedprojectItem = state?.selectedprojectItem ? state?.selectedprojectItem : null;

    useEffect(() => {
        if (!subProjectReports || !Array.isArray(subProjectReports)) {
            navigate("/");
        }
    }, []);

    return (
        <div style={{ width: "100%" }}>
            <BreadcrumbNav />
            <ProjectReportForm subProjectReports={subProjectReports} selectedprojectItem={selectedprojectItem} />
        </div>
    );
}

export default ProjectReportFormViewPage;
