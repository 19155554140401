import React from "react";
import { Card } from "primereact/card";
import CountUp from "react-countup";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Lottie from "lottie-react";
//
// import Sanitation from "../../../assets/sanitationbubble.json";
// import WaterAccess from "../../../assets/wateraccess.json";
// import WaterCoverage from "../../../assets/watercoverage.json";
// import DrinkingWater from "../../../assets/drinkingwater.json";
// import PipedWater from "../../../assets/pipedwater.json";
// import Villages from "../../../assets/villages.json";

//
import { LocalDrink, Sanitizer, Water, Waves } from "@mui/icons-material"; // Import MUI icons

const StatisticsDisplay = ({ data = [] }) => {
    console.log("databdnsbds : ", data);

    //
    const groupByOutputId = (data) => {
        const grouped = {};

        // Handling 'data' part
        data?.data?.forEach((item) => {
            if (!grouped[item.source_type_abbrv]) {
                grouped[item.source_type_abbrv] = {
                    outputId: item?.source_type_abbrv,
                    name: item?.source_type?.name,
                    details: [],
                    locations: [],
                };
            }
            grouped[item.source_type_abbrv].details.push(item);
        });

        // Handling 'subproject_locations' part
        data?.subproject_locations?.forEach((location) => {
            // Assuming outputIndicator (or equivalent) represents your outputId in the locations
            const outputId = location.outputIndicator; // Modify as necessary based on actual attribute names

            // Ensure there's a default group for locations with missing corresponding 'data' part
            if (!grouped[outputId]) {
                grouped[outputId] = {
                    outputId: outputId,
                    name: location?.outputIndicator, // Assuming we might not know the name here, adjust if you can get it
                    details: [],
                    locations: [],
                };
            }
            grouped[outputId].details.push(location);
        });

        return Object.values(grouped);
    };
    const groupedData = groupByOutputId(data ?? []);

    console.log("groupedData fgsd : ", groupedData);

    const cardColors = [
        "#FF6384",
        "#36A2EB",
        "#FFCE56",
        "#4BC0C0",
        "#9966FF", // Existing colors
        "#FF9F40",
        "#FFCD56",
        "#4D5360",
        "#23CBAF",
        "#C0392B", // New colors
        "#8E44AD",
        "#3498DB",
        "#1ABC9C",
        "#2ECC71",
        "#F1C40F", // More colors
        "#E74C3C",
        "#34495E",
        "#9B59B6",
        "#16A085",
        "#27AE60", // Additional colors
    ];

    const getRandomColor = () => {
        const randomIndex = Math.floor(Math.random() * cardColors.length);
        return cardColors[randomIndex];
    };

    // const lottieAnimations = [Sanitation, WaterAccess, WaterCoverage, PipedWater];
    // const getRandomLottie = () => {
    //     const randomIndex = Math.floor(Math.random() * lottieAnimations.length);
    //     return lottieAnimations[randomIndex];
    // };

    const waterIcons = [
        <LocalDrink style={{ width: "80px", height: "80px", margin: "auto" }} />,
        <Sanitizer style={{ width: "80px", height: "80px", margin: "auto" }} />,
        <Water style={{ width: "80px", height: "80px", margin: "auto" }} />,
        <Waves style={{ width: "80px", height: "80px", margin: "auto" }} />,
    ]; // Array of MUI icons

    const getRandomIcon = () => {
        const randomIndex = Math.floor(Math.random() * waterIcons.length);
        return waterIcons[randomIndex];
    };

    const getTextColor = (backgroundColor) => {
        const color = backgroundColor.substring(1); // Remove '#'
        const rgb = parseInt(color, 16); // Convert hex to integer
        const r = (rgb >> 16) & 0xff; // Extract red
        const g = (rgb >> 8) & 0xff; // Extract green
        const b = (rgb >> 0) & 0xff; // Extract blue

        const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // Calculate luma
        return luma < 140 ? "white" : "black"; // Return text color based on brightness
    };

    // If 'utility' key exists, just display total number of gazettes
    if (data?.utility) {
        const totalGazettes = data.utility.gazettes?.length || 0;

        return (
            <div style={{ display: "flex", justifyContent: "center", margin: "20px" }}>
                <Card
                    style={{
                        textAlign: "center",
                        boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                        width: "300px",
                    }}
                >
                    <h2>Total Gazettes</h2>
                    <div style={{ fontSize: "2em", fontWeight: "bold" }}>
                        <CountUp end={totalGazettes} duration={2.75} />
                    </div>
                </Card>
            </div>
        );
    }

    return (
        <div className="col-12 xl:col-12" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap", gap: "1rem" }}>
            {groupedData.map((group, index) => {
                const bgColor = getRandomColor();
                const textColor = getTextColor(bgColor);
                console.log("Rendering group: ", group);

                return (
                    <Card
                        key={index}
                        style={{
                            textAlign: "center",
                            boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                            backgroundColor: bgColor,
                            width: "300px",
                            color: textColor,
                        }}
                    >
                        <strong style={{ wordWrap: "break-word" }}>{group?.name}</strong>
                        {/* <Lottie animationData={getRandomLottie()} loop={true} autoplay={true} style={{ width: "80px", height: "80px", margin: "auto" }} /> */}
                        <div>
                            <center>{getRandomIcon()}</center>
                        </div>

                        <div style={{ fontSize: "2em", fontWeight: "bold" }}>
                            <CountUp end={group?.details?.length} duration={2.75} />
                        </div>
                    </Card>
                );
            })}
        </div>
    );
};
export default StatisticsDisplay;
