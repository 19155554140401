import axiosAPI from "../axiosApi";

export async function getAllSubprojectLocations(params = {}) {
    const response = await axiosAPI.get("subprojects-location", { params: params });
    return response;
}

export async function getSubprojectLocationById(id) {
    const response = await axiosAPI.get(`subprojects-location/${id}`);
    return response;
}

export async function postSubprojectLocation(data) {
    const response = await axiosAPI.post(`subprojects-location`, data);
    return response;
}

export async function updateSubprojectLocation(id, data) {
    const response = await axiosAPI.put(`subprojects-location/${id}`, data);
    return response;
}

export async function deleteSubprojectLocationById(id) {
    const response = await axiosAPI.delete(`subprojects-location/${id}`);
    return response;
}

export async function getSubprojectLocationWithVillages(params = {}) {
    const response = await axiosAPI.get("subprojectLocationWithVillages", {
        params: params,
    });
    return response;
}
