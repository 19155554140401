import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import ProjectsCreateForm from "./ProjectsCreateForm";

import ProjectsEditForm from "./ProjectsEditForm";
import { getDepartmentById } from "../../../services/departments/department-service";

import { deleteProjectById, getAllProjects, getProjectsByProjectIdOrRole, getProjectsByDepartmentIdAndRole } from "../../../services/projects/project-service";

import moment from "moment";

import { Link } from "react-router-dom";

import { ProgressBar } from "primereact/progressbar";

import WaterIsLoading from "../../../components/mweComponents/WaterIsLoading";
import MuiTable from "../../../components/mweComponents/MuiTable";
import * as XLSX from "xlsx";
import { useLocation } from "react-router-dom";

import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import { Panel } from "primereact/panel";
import { TabView, TabPanel } from "primereact/tabview";

import { useNavigate } from "react-router-dom";

function ProjectsList({ selectedParentDetail, ...props }) {
    const navigate = useNavigate();
    // "props.loggedInUserData";
    let commissionerDepartmentId = props?.loggedInUserData?.department_id;

    const departmentId = props.departmentId || commissionerDepartmentId;

    console.log("departments is under project vvvsss : ", departmentId);
    let prevPage = props?.prevPage;

    const [isLoading, setIsLoading] = useState(false);
    const [projectsData, setProjectsData] = useState();
    console.log("projectsData : ", projectsData);
    const queryClient = useQueryClient();

    // const fetchAllProjects = useQuery(["projects"], getProjectsByProjectIdOrRole, {
    //     enabled: !departmentId ? true : false,
    //     onSuccess: (data) => {
    //         console.log("getProjectsByProjectIdOrRole onsuccess fetching projects : ", data);
    //         setIsLoading(false);
    //         setProjectsData(data.data);
    //     },
    //     onError: (error) => {
    //         console.log("error : ", error);
    //     },
    // });

    // const {
    //     data,
    //     isError,
    //     error,
    //     isLoading: projectByIdIsLoading,
    //     status,
    // } = useQuery(
    //     ["projects", departmentId],
    //     // () => getDepartmentById(departmentId),
    //     () => getProjectsByDepartmentIdAndRole(departmentId),
    //     {
    //         enabled: departmentId ? true : false,
    //         onSuccess: (data) => {
    //             console.log("getProjectsByProjectIdOrRole by department id: ", data);
    //             setIsLoading(false);
    //         },
    //         onError: (error) => {
    //             console.log("error : ", error);
    //         },
    //     }
    // );
    // console.log("isLoading is : ", isLoading);
    // console.log("data fetched is : ", data);
    // console.log(data);
    // isError && toast.error("There was an error while fetching data");

    const fetchAllProjects = useQuery(["projects", selectedParentDetail?.department_id, selectedParentDetail?.id], () => (!departmentId ? getAllProjects() : getAllProjects({ department_id: selectedParentDetail?.department_id, department_administrations_id: selectedParentDetail?.id })), {
        onSuccess: (data) => {
            console.log("getAllProjects onsuccess fetching projects : ", data);
            setIsLoading(false);
            setProjectsData(data.data);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
            console.log("get list of projects : ", error);
        },
    });

    const projectDeleteMutation = useMutation((variables) => deleteProjectById(variables), {
        onSuccess: (data) => {
            toast.success("Project Deleted Successfully");
            // queryClient.resetQueries(["projects"]);
            queryClient.invalidateQueries(["projects"]);
            queryClient.invalidateQueries(["programs", "programs-hireachy"]);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
            console.log("Delete list of projects : ", error);
        },
    });

    //=================== handle delete ====================
    const handleDelete = (event, id) => {
        let idToBeDeleted = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(idToBeDeleted),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (idToBeDeleted) => {
        if (idToBeDeleted !== null) {
            projectDeleteMutation.mutate(idToBeDeleted);
        }
    };

    const cancelDelete = () => {
        // setDeleteProgramId(null);
    };

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    let tableId = 0;
    const columns = [
        {
            title: "#",
            width: "5%",
            field: "id",
            hidden: true,
        },
        {
            title: "Project Title",
            field: "project_title",
            cellStyle: {
                whiteSpace: "wrap",
                padding: "8px",
            },
            // render: (rowData) => {
            //     return (
            //         <Link
            //             // to={"/programs/" + item.id}
            //             // project/subprojects
            //             to="subprojects"
            //             state={{ id: rowData.id, name: rowData.name }}
            //             className="text-decoration-none"
            //         >
            //             {rowData.name}
            //         </Link>
            //     );
            // },
        },
        { title: "Project Code", field: "project_code" },
        {
            title: "Progress",
            field: "completion",
            render: (rowData) => {
                return <ProgressBar value={rowData.completion} label={`${rowData.completion}% Complete`} showValue={true} />;
            },
        },
        { title: "Vote name", field: "vote", hidden: true },
        { title: "Vote Code", field: "vote_code", hidden: true },
        {
            title: "Start Date",
            field: "start_date",
            render: (rowData) => {
                return moment(rowData.start_date).format("DD/MM/YY");
            },
        },
        {
            title: "End Date",
            field: "proposed_end_date",
            hidden: true,
        },
        { title: "Status", field: "completion_status", hidden: true },
        { title: "Capital-Current Ratio", field: "capital_current_ratio", hidden: true },
        { title: "Project Type", field: "project_type", hidden: true },
        { title: "Responsible Officer Title", field: "responsible_officer_title", hidden: true },
        { title: "Responsible Officer Phone", field: "responsible_officer_phonenumber", hidden: true },
        { title: "Responsible Officer Email", field: "responsible_officer_email", hidden: true },
        { title: "Responsible Officer Name", field: "responsible_officer_name", hidden: true },
        { title: "Target Beneficiaries", field: "target_beneficiaries", hidden: true },
        { title: "Department", field: "department.name", hidden: true }, // Assuming 'department' is an object with a 'name' property
        { title: "Department Functions", field: "department_function.name", hidden: true },
        { title: "Project Details", field: "project_details", hidden: true },
        {
            title: "Date",
            field: "created_at",
            hidden: true,
            render: (rowData) => {
                return moment(rowData.created_at).format("lll");
            },
        },
    ];
    // /projects/view
    // =========================== download to excell ===================
    let excelldata = fetchAllProjects?.data?.data?.data;
    const downloadExcel = () => {
        const newData = excelldata.map((row) => {
            delete row.tableData;
            return row;
        });
        const workSheet = XLSX.utils.json_to_sheet(newData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "Projects");
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
        //Download
        XLSX.writeFile(workBook, "ProjectsData.xlsx");
    };

    console.log("data hhhh activeUser : ", activeUser);
    console.log("data hhhh activeUser?.department_id : ", activeUser?.department_id);
    console.log("data hhhh departmentId : ", departmentId);
    console.log("data hhhh props.loggedInUserData : ", props.loggedInUserData);

    //

    const location = useLocation();
    const isProjectPath = location.pathname.includes("project");

    return (
        <div style={{ width: "100%" }}>
            {/* <TabView>
                <TabPanel header="Projects"> */}
            <div className="col-12 xl:col-12">
                <div className="card">
                    <p>The Projects below belong to departments</p>
                </div>
            </div>
            <Panel header="Projects" style={{ marginBottom: "20px" }}>
                {/* {props?.loggedInUserData?.permissions?.includes("add projects") && props?.loggedInUserData?.user_department_id == departmentId && ( */}
                {props?.loggedInUserData?.permissions?.includes("add projects") && (
                    <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                        <Button onClick={() => setShowAddForm(true)}>Add Project</Button>

                        <ProjectsCreateForm selectedParentDetail={selectedParentDetail} loggedInUserData={props?.loggedInUserData} show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} />
                    </div>
                )}

                {!commissionerDepartmentId && props?.loggedInUserData.role == "Commissioner" && <strong style={{ color: "orange" }}> You dont belong to any department </strong>}

                <MuiTable
                    tableTitle={prevPage ? `Projects Under ${prevPage}` : isProjectPath ? "Project" : "projects"}
                    tableData={fetchAllProjects?.data?.data?.data ?? []}
                    tableColumns={columns}
                    handleShowEditForm={handleShowEditForm}
                    handleDelete={(e, item_id) => handleDelete(e, item_id)}
                    // showEdit={props.loggedInUserData?.permissions?.includes("edit projects") && props?.loggedInUserData?.user_department_id == departmentId}
                    // showDelete={props.loggedInUserData?.permissions?.includes("delete projects") && props?.loggedInUserData?.user_department_id == departmentId}
                    showEdit={props.loggedInUserData?.permissions?.includes("edit projects")}
                    showDelete={props.loggedInUserData?.permissions?.includes("delete projects")}
                    // hideRowEdit={false}
                    handleViewPage={(rowData) => {
                        navigate("project", { state: { projectData: rowData } });
                    }}
                    showViewPage={true}
                    hideRowViewPage={false}
                    // hideRowDelete={false}
                    // hideRowEdit={(rowData) => (props.loggedInUserData?.permissions?.includes("edit projects") && props.loggedInUserData?.user_project_id == rowData.id ? false : true)}
                    // hideRowDelete={(rowData) => (props.loggedInUserData?.permissions?.includes("edit projects") && props.loggedInUserData?.user_project_id == rowData.id ? false : true)}
                    hideRowEdit={(rowData) => (props.loggedInUserData?.permissions?.includes("edit projects") ? false : true)}
                    hideRowDelete={(rowData) => (props.loggedInUserData?.permissions?.includes("edit projects") ? false : true)}
                    loading={isLoading || projectDeleteMutation.isLoading || fetchAllProjects.isLoading || fetchAllProjects.status === "loading"}
                    excelexporting={true}
                    downloadExcel={downloadExcel}
                    exportButton={true}
                    pdfExportTitle="Projects"
                    csvExportTitle="Projects"
                />
                {/* <ConfirmDialog /> */}
                <ProjectsEditForm loggedInUserData={props?.loggedInUserData} projectdata={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} />
                {(isLoading || projectDeleteMutation.isLoading || !departmentId ? fetchAllProjects.isLoading || fetchAllProjects.status === "loading" : false) && <WaterIsLoading />}
            </Panel>
            {/* </TabPanel>
                <TabPanel header="Department Indicators">
                    <DepartmentIndicatorsPage />
                </TabPanel>
                <TabPanel header="Budget Outputs">
                    <DepartmentBudgetOutPutsPage />
                </TabPanel>

                <TabPanel header="Department Reports"></TabPanel>
            </TabView> */}
        </div>
    );
}

export default ProjectsList;
