import React, { useEffect, useState, useRef } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
// import CreateForm from "./CreateForm";

// import EditForm from "./EditForm";

import moment from "moment";

import { postToAggregateUtilityReports, getAllUtilityReports, getUtilityReportById, postUtilityReport, updateUtilityReport, deleteUtilityReportById } from "../../../../../services/departments/utility-dept/departments-utility-report-service.js";

import WaterIsLoading from "../../../../../components/mweComponents/WaterIsLoading";
import MuiTable from "../../../../../components/mweComponents/MuiTable";
import { toast } from "react-toastify";
import { Button } from "primereact/button";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useNavigate } from "react-router-dom";

import { Panel } from "primereact/panel";
import UtilityReportPdfModal from "./UtilityReportPdfModal";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Tooltip from "@mui/material/Tooltip";
import AggregationModal from "./AggregationModal";

function ListPage({ filterQueryIsLoading, reportsData, selectedUtilityData, subprojectDetail, subprojectViewPageActiveIndex, subprojectViewPageChildActiveIndex, ...props }) {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    console.log("subprojectDetail ggggg : ", subprojectDetail);
    console.log("selectedUtilityData utility reports : ", selectedUtilityData);

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showBudjetOutPutAddForm, setShowBudjetOutPutAddForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    const onBudjetOutPutFormClose = () => {
        setShowBudjetOutPutAddForm(false);
    };

    // 'department_indicator_id',
    //     'fyear_id',
    //     'quarter_id',
    //     'baseline',
    //     'actual',
    //     'target',
    //     'reason_of_variance',
    //     'created_by',
    //     'updated_by'

    // departmentIndicator;
    // financialYear;
    // quarter;
    // createdBy;
    // updatedBy;
    //  /* // 'base_year', // 'base_value', // 'target_year', // 'target_value', // 'financial_year_target', */
    let tableId = 0;

    const columns = [
        {
            title: "#",
            width: "5%",
            field: "id",
            render: (rowData) => {
                // tableId = rowData.tableData.id;
                tableId = tableId++;
                return <div>{rowData.tableData.index + 1}</div>;
                // return <div>{rowData.tableData.id}</div>;
            },
        },
        {
            title: "Name",
            field: "name",
        },
        {
            title: "Financial Year",
            field: "financial_year.name",
        },
        {
            title: "Quarter",
            field: "quarter.name",
        },

        // {
        //     title: "Released Funds",
        //     field: "released_funds",
        //     hidden: true,
        // },

        {
            title: "Approval Status",
            field: "current_status.name",
            render: (rowData) => {
                let labelStyle = { padding: "3px", color: "#fff", borderRadius: "4px" };
                let labelClass = "";

                switch (rowData?.current_status?.name) {
                    case "draft":
                        labelStyle = { ...labelStyle, backgroundColor: "#f0ad4e" }; // Bootstrap warning color
                        labelClass = "label label-default";
                        break;
                    case "submitted":
                        labelStyle = { ...labelStyle, backgroundColor: "#5bc0de" }; // Bootstrap info color
                        labelClass = "label label-info";
                        break;
                    case "approved":
                        labelStyle = { ...labelStyle, backgroundColor: "#5cb85c" }; // Bootstrap success color
                        labelClass = "label label-success";
                        break;
                    case "rejected":
                        labelStyle = { ...labelStyle, backgroundColor: "#d9534f" }; // Bootstrap danger color
                        labelClass = "label label-danger";
                        break;
                    default:
                        labelStyle = { ...labelStyle, backgroundColor: "#5bc0de" }; // Bootstrap info color
                        labelClass = "label label-info";
                }

                return (
                    <strong style={labelStyle} className={labelClass}>
                        {rowData?.current_status?.name.charAt(0).toUpperCase() + rowData?.current_status?.name.slice(1)}
                    </strong>
                );
            },
        },
        {
            title: "View",
            field: "view",
            render: (rowData) => {
                return (
                    <div style={{ color: "blue", cursor: "pointer" }} onClick={(e) => handleShowPDF(e, rowData)}>
                        <Tooltip title="Click to view report">
                            <PictureAsPdfIcon style={{ color: "orange" }} />
                            {/* <span>{rowData?.subproject?.name}</span> */}
                        </Tooltip>
                    </div>
                );
            },
        },

        {
            title: "Status",
            field: "status",
            hidden: true,
            render: (rowData) => {
                let labelStyle = { padding: "3px", color: "#fff", borderRadius: "4px" };
                let labelClass = "";

                switch (rowData.status) {
                    case "draft":
                        labelStyle = { ...labelStyle, backgroundColor: "#f0ad4e" }; // Bootstrap warning color
                        labelClass = "label label-default";
                        break;
                    case "submitted":
                        labelStyle = { ...labelStyle, backgroundColor: "#5bc0de" }; // Bootstrap info color
                        labelClass = "label label-info";
                        break;
                    case "approved":
                        labelStyle = { ...labelStyle, backgroundColor: "#5cb85c" }; // Bootstrap success color
                        labelClass = "label label-success";
                        break;
                    case "rejected":
                        labelStyle = { ...labelStyle, backgroundColor: "#d9534f" }; // Bootstrap danger color
                        labelClass = "label label-danger";
                        break;
                    default:
                        labelStyle = { ...labelStyle, display: "none" }; // Hide label if status is unknown
                }

                return (
                    <strong style={labelStyle} className={labelClass}>
                        {rowData.status.charAt(0).toUpperCase() + rowData.status.slice(1)}
                    </strong>
                );
            },
        },

        {
            title: "Updated By",
            hidden: true,
            field: "updated_by_user.email",
        },

        {
            title: "Created By",
            hidden: true,
            field: "created_by_user.email",
        },
        {
            title: "Date",
            hidden: true,
            field: "created_at",
            render: (rowData) => {
                return moment(rowData.created_at).format("lll");
            },
        },
    ];

    //======================= pdf ====================
    //  const [selectedItem, setSelectedItem] = useState({ id: null });
    const [showPDF, setShowPDF] = useState(false);
    const handleShowPDF = (e, item) => {
        setSelectedItem(item);
        setShowPDF(true);
    };
    const handleClosePDF = () => {
        setSelectedItem({ id: null });
        setShowPDF(false);
    };

    const [aggregateReportsData, setAggregateReportsData] = useState([]);

    //======================= Agregated report ====================
    //  const [selectedItem, setSelectedItem] = useState({ id: null });
    const [showAggregatedPDF, setShowAggregatedPDF] = useState(false);
    const handleShowAggregatedPDF = (item) => {
        // setSelectedItem(item);
        setAggregateReportsData(item);
        setShowAggregatedPDF(true);
    };
    const handleCloseAggregatedPDF = () => {
        // setSelectedItem({ id: null });
        setShowAggregatedPDF(false);
    };

    // get aggregateSubProjectReportsData
    const postToAggregateUtilityReportsDataMutation = useMutation(postToAggregateUtilityReports, {
        onSuccess: (data) => {
            //   queryClient.invalidateQueries(["projects"]);
            //   toast.success("Project Created Successfully");
            console.log("postToAggregateUtilityReportsDataMutation data : ", data);

            handleShowAggregatedPDF(data?.data?.data);
        },
        onError: (error) => {
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
        },
    });

    //======================== table selection===============
    const primeToast = useRef(null);
    const [tableSelectedRows, setTableSelectedRows] = useState([]);
    const [isCreateConfirmDialogVisible, setIsCreateConfirmDialogVisible] = useState(false);

    const validateSelectedRows = () => {
        if (tableSelectedRows.length === 0) {
            primeToast.current.show({ severity: "warn", summary: "Warning", detail: "No rows selected", life: 3000 });
            return false;
        }

        // const firstRow = tableSelectedRows[0];
        // const isSameQuarterAndYear = tableSelectedRows.every((row) => row.financial_year.name === firstRow.financial_year.name && row.quarter.name === firstRow.quarter.name);

        // if (!isSameQuarterAndYear) {
        //     primeToast.current.show({ severity: "warn", summary: "Warning", detail: "All selected rows must have the same quarter and financial year", life: 3000 });
        //     return false;
        // }

        return true;
    };

    const handleCreateAggregateReportConfirm = () => {
        setIsCreateConfirmDialogVisible(false);
        setAggregateReportsData([]);
        // Logic for creating the project report goes here
        console.log("AggregateUtilityReports report selected report : ", tableSelectedRows);
        postToAggregateUtilityReportsDataMutation.mutate({ reports_data: tableSelectedRows });
        // navigate("report", { state: { subProjectReports: tableSelectedRows, selectedprojectItem: selectedprojectItem } });
    };

    const handleCreateAggregateReportReject = () => {
        setIsCreateConfirmDialogVisible(false);
        // Logic for rejection (if any) goes here
    };

    const handleCreateAggregatedReportClick = () => {
        if (validateSelectedRows()) {
            setIsCreateConfirmDialogVisible(true);
        }
    };

    return (
        <div style={{ width: "100%" }}>
            <Panel header="Utility Reports" style={{ marginBottom: "20px" }}>
                {/* <div className="d-flex justify-content-end mb-3" style={{ width: "100%" }}>
                    {activeUser?.permissions.includes("add programs") && <Button label="Add Program" className="p-button-primary right-0" onClick={() => setShowAddForm(true)} />}
                    <CreateForm show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} />
                </div> */}

                {activeUser?.permissions.includes("add reports") && tableSelectedRows.length > 0 && (
                    <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                        <Button
                            label="Aggregate Report"
                            className="p-button-primary"
                            disabled={postToAggregateUtilityReportsDataMutation.isLoading}
                            onClick={() => {
                                handleCreateAggregatedReportClick();
                            }}
                        />
                    </div>
                )}

                <MuiTable
                    tableTitle="Utility Reports"
                    tableData={reportsData}
                    tableColumns={columns}
                    hideRowEdit={true}
                    showDelete={false}
                    //view reports pdf
                    handleViewPdf={(e, item_id) => handleShowPDF(e, item_id)}
                    //
                    hideViewPdfRow={(rowData) => {
                        console.log("rowdata llllvvccd : ", rowData);
                        return rowData.status === "submitted" || rowData.status === "approved" ? false : true;
                    }}
                    showViewPdf={true}
                    exportButton={true}
                    loading={filterQueryIsLoading || postToAggregateUtilityReportsDataMutation.isLoading}
                    //
                    selection={true}
                    showSelectAllCheckbox={true}
                    showTextRowsSelected={true}
                    selectionChange={(selectedRows) => {
                        console.log("selected rows on sselection change : ", selectedRows);
                        setTableSelectedRows(selectedRows);
                    }}
                    handleDataApproval={() => {}}
                />

                <UtilityReportPdfModal selectedUtilityData={selectedUtilityData} selectedItem={selectedItem} loggedInUserData={props?.loggedInUserData} show={showPDF} onHide={() => setShowPDF(false)} onClose={onFormClose} />
                {showAggregatedPDF && <AggregationModal aggregatedData={aggregateReportsData} loggedInUserData={props?.loggedInUserData} show={showAggregatedPDF} onHide={() => setShowAggregatedPDF(false)} />}

                {/* {selectedItem && <EditForm rowData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} subprojectDetail={subprojectDetail} />} */}

                {/* <ConfirmDialog /> */}
            </Panel>

            <ConfirmDialog
                visible={isCreateConfirmDialogVisible}
                onHide={() => setIsCreateConfirmDialogVisible(false)}
                message="Are you sure you want to create an aggregated report?"
                header="Confirmation"
                icon="pi pi-exclamation-triangle"
                accept={handleCreateAggregateReportConfirm}
                reject={handleCreateAggregateReportReject}
            />
        </div>
    );
}

export default ListPage;
