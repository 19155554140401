import React, { useState } from "react";
import { Form, Field, useForm } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";
import moment from "moment";

import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber"; // Import for InputNumber
import { Calendar } from "primereact/calendar";
import { deleteProjectById, getAllProjects, getProjectsByProjectIdOrRole, getProjectsByDepartmentIdAndRole } from "../../../../services/projects/project-service";

import { getAllPrograms } from "../../../../services/programs/program-service";
import { getAllDepartments } from "../../../../services/departments/department-service";
import { classNames } from "primereact/utils";

import { createSubProject } from "../../../../services/sub-projects/sub-project-service";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { updateSubProject } from "../../../../services/sub-projects/sub-project-service";
import { ProgressSpinner } from "primereact/progressspinner";

import { getAllfunders, getfundersById, postfunders, updatefunders, deletefundersById } from "../../../../services/settings/funders-service";
import { AutoComplete } from "primereact/autocomplete";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DeleteIcon from "@mui/icons-material/Delete";
import setFieldTouched from "final-form-set-field-touched";
import { getAllCurrencies, getCurrenciesById, postCurrencies, updateCurrencies, deleteCurrenciesById } from "../../../../services/settings/currencies.jsx";

import { getAllOperationAndMaintenanceStructures, getOperationAndMaintenanceStructuresById, postOperationAndMaintenanceStructures, updateOperationAndMaintenanceStructures, deleteOperationAndMaintenanceStructuresById } from "../../../../services/settings/operation-and-maintanance-service.jsx";

const SubProjectsRowForm = ({ subprojectdata = {}, selectedprojectItem, ...props }) => {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);
    const queryClient = useQueryClient();

    if (props?.currentProjectId) {
        subprojectdata = { project_id: props?.currentProjectId, ...subprojectdata };
    }

    // Fetching necessary data for dropdowns

    const fetchAllProjects = useQuery(["projects", selectedprojectItem?.department_id], () => getAllProjects({ department_id: selectedprojectItem?.department_id }), {
        onSuccess: (data) => {
            console.log("getAllProjects onsuccess fetching projects : ", data);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
            console.log("get list of projects : ", error);
        },
    });

    const validate = (values) => {
        const errors = {};
        // Add validation logic here

        if (!values.name) errors.name = "Please provide a valid Name.";
        if (!values.code) errors.code = "Please provide a valid code.";
        if (!values.procurement_no) errors.procurement_no = "Please provide a valid Procurement Number.";
        if (!values.status) errors.status = "Please provide a valid value.";
        if (!values.start_date) errors.start_date = "Please provide a valid Date.";
        if (!values.proposed_end_date) errors.proposed_end_date = "Please provide a valid date.";
        // Date validation to ensure end date is after start date
        if (values.start_date && values.proposed_end_date) {
            const startDate = new Date(values.start_date);
            const endDate = new Date(values.proposed_end_date);
            if (endDate <= startDate) {
                errors.proposed_end_date = "End date must be after the start date.";
            }
        }
        if (!values.completion || values.completion < 1 || values.completion > 100) errors.completion = "Please provide a valid value between 0 and 100.";

        if (values.completion && values.completion == 100 && !values.actual_end_date) errors.actual_end_date = "Please provide an actual end date";
        if (values.completion && values.completion == 100 && !values.actual_cost) errors.actual_cost = "Please provide an actual cost";

        // if (!values.estimated_cost || values.estimated_cost <= 0) errors.estimated_cost = "Please provide a valid value.";
        if (!values.estimated_cost || values.estimated_cost <= 0) {
            errors.estimated_cost = "Please provide a valid value.";
        } else {
            // Convert to string and remove commas
            const numericValue = String(values.estimated_cost).replace(/,/g, "");
            if (numericValue.split(".")[0].length > 15) {
                errors.estimated_cost = "Estimated cost must be less than or equal to 15 digits.";
            }
        }

        if (!values.target_beneficiaries_total) errors.target_beneficiaries_total = "Please provide a valid value.";
        if (!values.target_beneficiaries) errors.target_beneficiaries = "Please provide a valid value.";
        if (!values.funder_ids) errors.funder_ids = "Please provide a valid value.";

        if (!values.contractor) errors.contractor = "Please provide a valid value.";
        // if (!values.contract_amount || values.contract_amount <= 1) errors.contract_amount = "Please provide a valid value.";
        // if (!values.contract_amount || values.contract_amount <= 0) {
        //     errors.contract_amount = "Please provide a valid value.";
        // } else {
        //     // Remove commas and check if the number is too large
        //     const numericValue = values.contract_amount.replace(/,/g, "");
        //     if (numericValue.split(".")[0].length > 15) {
        //         errors.contract_amount = "Contract amount must be less than or equal to 15 digits.";
        //     }
        // }

        if (!values.contract_amount || values.contract_amount <= 0) {
            errors.contract_amount = "Please provide a valid value.";
        } else {
            // Ensure values.contract_amount is treated as a string
            const contractAmountString = String(values.contract_amount);
            const numericValue = contractAmountString.replace(/,/g, "");

            if (numericValue.split(".")[0].length > 15) {
                errors.contract_amount = "Contract amount must be less than or equal to 15 digits.";
            }
        }

        // Supervisor
        if (!values.supervisor) errors.supervisor = "Please provide a valid supervisor type.";
        // if (!values.currency) errors.currency = "Please provide a valid Currency.";

        // Location Type
        if (!values.location_type) errors.location_type = "Please provide a valid location type.";
        // console.log("values.per_capita_investment_cost ", values.per_capita_investment_cost);
        // Per Capita Investment Cost
        // if (!values.per_capita_investment_cost || values.per_capita_investment_cost <= 1) errors.per_capita_investment_cost = "Please provide a valid value greater than 0.";
        if (!values.capital_current_ratio || values.capital_current_ratio <= 1) errors.capital_current_ratio = "Please provide a valid value greater than 0.";

        // // Program
        // if (!values.program_id) errors.program_id = "Please provide a valid program.";

        // // Department
        // if (!values.department_id) errors.department_id = "Please provide a valid department.";

        // Parent Project
        if (!values.project_id) errors.project_id = "Please provide a valid parent project.";

        // Completion Status
        if (!values.completion_status) errors.completion_status = "Please provide a valid completion status.";

        // OM Structure
        if (!values.operators_ids) errors.operators_ids = "Please choose atleast one operation and maintenance structure.";

        if (!values.currency_ids || values.currency_ids.length === 0) errors.currency_ids = "At least one Currency is required";

        // Add more validations as needed
        return errors;
    };

    // ================ subProject create form mutation ===========================================
    const createSubProjectMutation = useMutation(createSubProject, {
        onSuccess: (data) => {
            toast.success("Subproject created successfully");
            // queryClient.invalidateQueries(["subprojects"]);
            // queryClient.resetQueries(["subprojects"]);
            queryClient.invalidateQueries(["subprojects"]);
            queryClient.invalidateQueries(["programs", "programs-hireachy"]);
            props?.onHide();
            console.log("finished creating subprojects");
            // let sub_project_id = data.data["Sub project created"].id;
            // console.log("the created subroject id is :  ", sub_project_id);

            // createSublocationMutations.mutate(sub_project_id);
        },
        onError: (error, variables, context) => {
            // An error happened!
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
            console.log(error);
        },
    });

    // ================ subProject edit form mutation ===========================================
    const createSubProjectEditMutation = useMutation((variables) => updateSubProject(subprojectdata?.id, variables), {
        onSuccess: (data) => {
            toast.success("Subproject edited successfully");
            // queryClient.resetQueries(["subprojects"]);
            queryClient.invalidateQueries(["subprojects"]);
            queryClient.invalidateQueries(["programs", "programs-hireachy"]);
            props?.onHide();
            // queryClient.invalidateQueries(["subprojects"]);
            // let sub_project_id = data.data["Updated sub project"].id;
            // console.log("subproject id to update sublocation is : ", sub_project_id);
        },
        onError: (error, variables, context) => {
            // An error happened!
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
            console.log(error);
        },
    });

    // const onSubmitForm = async (data, form) => {
    //     const errors = validate(data);
    //     if (Object.keys(errors).length === 0) {
    //         const contractAmount = parseFloat(data?.contract_amount.replace(/,/g, ""));
    //         const submissionData = {
    //             ...data,
    //             contract_amount: contractAmount,
    //         };
    //         setPendingData(submissionData);
    //         setShowConfirmDialog(true);
    //     } else {
    //         toast.warning("Please fill in all required fields.");
    //         form.batch(() => {
    //             Object.keys(errors).forEach((field) => {
    //                 form.change(field, form.getFieldState(field)?.value);
    //             });
    //         });
    //     }
    // };

    const onSubmitForm = async (data, form) => {
        const errors = validate(data);

        if (Object.keys(errors).length === 0) {
            // Check if contract_amount is a string and contains commas
            const contractAmount = typeof data?.contract_amount === "string" ? parseFloat(data.contract_amount.replace(/,/g, "")) : data?.contract_amount;
            const estimated_cost = typeof data?.estimated_cost === "string" ? parseFloat(data.estimated_cost.replace(/,/g, "")) : data?.estimated_cost;
            const actual_cost = typeof data?.actual_cost === "string" ? parseFloat(data.actual_cost.replace(/,/g, "")) : data?.actual_cost;
            const per_capita_investment_cost = typeof data?.per_capita_investment_cost === "string" ? parseFloat(data.per_capita_investment_cost.replace(/,/g, "")) : data?.per_capita_investment_cost;

            const target_beneficiaries_total = typeof data?.target_beneficiaries_total === "string" ? parseFloat(data.target_beneficiaries_total.replace(/,/g, "")) : data?.target_beneficiaries_total;

            // Calculate the per capita investment cost using the current form values
            const perCapitaInvestmentCost = calculatePerCapitaInvestmentCost(contractAmount, target_beneficiaries_total);

            const submissionData = {
                ...data,
                contract_amount: contractAmount,
                estimated_cost: estimated_cost,
                per_capita_investment_cost: perCapitaInvestmentCost,
                target_beneficiaries_total,
                actual_cost,
            };
            setPendingData(submissionData);
            setShowConfirmDialog(true);
        } else {
            toast.warning("Please fill in all required fields.");
            form.batch(() => {
                Object.keys(errors).forEach((field) => {
                    form.change(field, form.getFieldState(field)?.value);
                });
            });
        }
    };

    const onConfirm = async () => {
        if (pendingData) {
            // Replace this with actual create or update logic
            // await createOrUpdateSubProject(pendingData);

            if (Object.keys(subprojectdata).length > 0 && !props?.currentProjectId) {
                console.log("subprojectdata is not empty");
                console.log("subproject data is set so am just going to update : ", subprojectdata);
                createSubProjectEditMutation.mutate(pendingData);
            } else {
                console.log("subprojectdata is empty");
                console.log("subproject data is not set so am just going to create : ", pendingData);
                createSubProjectMutation.mutate(pendingData);
            }
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => setShowConfirmDialog(false);

    //================ Operation And Maitenance =======================

    const getAllOperatorsQuery = useQuery(["operation-and-maintenance-structure"], getAllOperationAndMaintenanceStructures, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching getAllfunders is : ", error);
        },
    });

    if (subprojectdata?.operators) {
        subprojectdata = { ...subprojectdata, operators_ids: subprojectdata?.operators?.map((district) => district.id) };
    }

    //  const [dropdownItem, setDropdownItem] = useState([]);

    //  const [selectedAutoCompleteDropdownItem, setSelectedAutoCompleteDropdownItem] = useState(DirectorateData.programs || []);

    // const [dropdownDistricts, setDropdownDistricts] = useState([]);
    const [dropOMdownItem, setOMDropdownItem] = useState([]);
    // const [selectedDistrict, setSelectedDistrict] = useState(ProjectsData.districts || []);
    const [selectedOmAutoCompleteDropdownItem, setSelectedOmAutoCompleteDropdownItem] = useState(subprojectdata?.operators || []);

    console.log("kkkkkkk selectedDistrict xxxxx  : ", selectedOmAutoCompleteDropdownItem);

    // Special option for selecting all districts
    const selectOperatorsAllOption = { id: "all", name: "Select All" };

    //
    const fetchOMSuggestions = (query) => {
        let filteredItems = getAllOperatorsQuery.data?.data?.data.filter((district) => {
            return !selectedOmAutoCompleteDropdownItem.map((sp) => sp.id).includes(district.id) && district.name.toLowerCase().includes(query.toLowerCase());
        });

        // Add 'Select All' option only if no districts are selected
        if (selectedOmAutoCompleteDropdownItem.length > 0 && (query.toLowerCase() === "select all" || query === "")) {
            filteredItems = [selectOperatorsAllOption, ...filteredItems];
        }

        setOMDropdownItem(filteredItems);
    };

    //=========================== data table pagination ======================
    const [omPage, setOmPage] = useState(0);
    const [omRows, setOmRows] = useState(5); // Convert to state variable

    // Function to handle page changes
    const omOnPaging = (event) => {
        setOmPage(event.first / event.rows);
        setOmRows(event.rows); // Update number of rows per page
    };

    //==================================== funders ===========================================

    const getAllFundersQuery = useQuery(["funders"], getAllfunders, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching getAllfunders is : ", error);
        },
    });

    if (subprojectdata?.funders) {
        subprojectdata = { ...subprojectdata, funder_ids: subprojectdata?.funders?.map((funder) => funder.id) };
    }

    //  const [dropdownItem, setDropdownItem] = useState([]);

    //  const [selectedAutoCompleteDropdownItem, setSelectedAutoCompleteDropdownItem] = useState(DirectorateData.programs || []);

    // const [dropdownDistricts, setDropdownDistricts] = useState([]);
    const [dropdownItem, setDropdownItem] = useState([]);
    // const [selectedDistrict, setSelectedDistrict] = useState(ProjectsData.districts || []);
    const [selectedAutoCompleteDropdownItem, setSelectedAutoCompleteDropdownItem] = useState(subprojectdata?.funders || []);

    console.log("kkkkkkk selectedDistrict xxxxx  : ", selectedAutoCompleteDropdownItem);

    // Special option for selecting all districts
    const selectAllOption = { id: "all", name: "Select All" };

    //
    const fetchSuggestions = (query) => {
        let filteredItems = getAllFundersQuery.data?.data?.data.filter((district) => {
            return !selectedAutoCompleteDropdownItem.map((sp) => sp.id).includes(district.id) && district.name.toLowerCase().includes(query.toLowerCase());
        });

        // Add 'Select All' option only if no districts are selected
        if (selectedAutoCompleteDropdownItem.length > 0 && (query.toLowerCase() === "select all" || query === "")) {
            filteredItems = [selectAllOption, ...filteredItems];
        }

        setDropdownItem(filteredItems);
    };

    //=========================== data table pagination ======================
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(5); // Convert to state variable

    // Function to handle page changes
    const onPaging = (event) => {
        setPage(event.first / event.rows);
        setRows(event.rows); // Update number of rows per page
    };

    //============================================================== Projects Currencies ============================================================

    const getAllCurrenciesQuery = useQuery(["currencies"], getAllCurrencies, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching getAllcurrencies is : ", error);
        },
    });

    const [currencyDropdownItem, setCurrencyDropdownItem] = useState([]);
    // const [selectedDistrict, setSelectedDistrict] = useState(ProjectsData.districts || []);
    const [selectedCurrencyAutoCompleteDropdownItem, setSelectedCurrencyAutoCompleteDropdownItem] = useState(subprojectdata?.currencies || []);

    //currency_ids;
    if (subprojectdata?.currencies) {
        subprojectdata = { ...subprojectdata, currency_ids: subprojectdata?.currencies?.map((item) => item.id) };
    }
    console.log("kkkkkkk selectedDistrict xxxxx  : ", selectedCurrencyAutoCompleteDropdownItem);

    // Special option for selecting all districts
    const selectAllCurrencyOption = { id: "all", name: "Select All" };

    //
    const fetchCurrencySuggestions = (query) => {
        let filteredItems = getAllCurrenciesQuery.data?.data?.data.filter((district) => {
            return !selectedCurrencyAutoCompleteDropdownItem.map((sp) => sp.id).includes(district.id) && district.name.toLowerCase().includes(query.toLowerCase());
        });

        // Add 'Select All' option only if no districts are selected
        if (selectedCurrencyAutoCompleteDropdownItem.length > 0 && (query.toLowerCase() === "select all" || query === "")) {
            filteredItems = [selectAllCurrencyOption, ...filteredItems];
        }

        setCurrencyDropdownItem(filteredItems);
    };

    //=========================== data table pagination ======================
    const [currencyPage, setCurrencyPage] = useState(0);
    const [currencyRows, setCurrencyRows] = useState(5); // Convert to state variable

    // Function to handle page changes
    const onCurrencyPaging = (event) => {
        setCurrencyPage(event.first / event.rows);
        setCurrencyRows(event.rows); // Update number of rows per page
    };

    // Define a function that will be used to calculate the per capita investment cost
    // const calculatePerCapitaInvestmentCost = (contractAmount, targetBeneficiariesTotal) => {
    //     const amount = parseFloat(contractAmount?.replace(/,/g, "")) || 0;
    //     const total = parseFloat(targetBeneficiariesTotal) || 0;
    //     return (amount / total).toFixed(2);
    // };

    const calculatePerCapitaInvestmentCost = (contractAmount, targetBeneficiariesTotal) => {
        // Ensure both values are strings before trying to replace characters
        const amountString = String(contractAmount);
        const totalString = String(targetBeneficiariesTotal);

        // Replace non-numeric characters and parse to float
        const amount = parseFloat(amountString.replace(/,/g, "")) || 0;
        const total = parseFloat(totalString) || 0;

        // Perform the calculation
        return total !== 0 ? (amount / total).toFixed(2) : "0.00";
    };
    return (
        <div className="col-12 md:col-12">
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={subprojectdata}
                    initialValuesEqual={() => true}
                    // initialValuesEqual with a function returning true prevents the form from
                    // reinitializing when the initialValues prop changes. This is useful when you
                    // want to avoid re-rendering or reinitializing the form due to changes in initial values.
                    // Be cautious using this if your initial values are meant to be dynamic and responsive
                    // to changes in your application's state.
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {
                        // Calculate the per capita investment cost using the current form values
                        const contractAmount = typeof values?.contract_amount === "string" ? parseFloat(values.contract_amount.replace(/,/g, "")) : values?.contract_amount;
                        const target_beneficiaries_total = typeof values?.target_beneficiaries_total === "string" ? parseFloat(values.target_beneficiaries_total.replace(/,/g, "")) : values?.target_beneficiaries_total;
                        const perCapitaInvestmentCost = calculatePerCapitaInvestmentCost(contractAmount, target_beneficiaries_total);
                        return (
                            <form onSubmit={handleSubmit}>
                                <Field name="name">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Name</label>
                                            <InputText {...input} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                                <Field name="code">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Code</label>
                                            <InputText {...input} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                                <Field name="procurement_no">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Procurement Number</label>
                                            <InputText {...input} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                                {/* <Field name="status">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label>Status</label>
                                        <InputText {...input} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field> */}
                                <div style={{ marginBottom: "1rem" }}>
                                    <Field name="project_id">
                                        {({ input, meta }) => (
                                            <div className="p-field m-4">
                                                <label>Project</label>
                                                <Dropdown
                                                    value={input.value}
                                                    options={fetchAllProjects?.data?.data?.data.map((item) => ({
                                                        label: item.project_title,
                                                        value: item.id,
                                                    }))}
                                                    onChange={(e) => input.onChange(e.value)}
                                                    placeholder="Select Project"
                                                    className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                />
                                            </div>
                                        )}
                                    </Field>
                                </div>

                                <Field name="start_date">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="start_date">Start Date</label>
                                            <Calendar
                                                {...input}
                                                value={input.value ? new Date(input.value) : null} // Set the Calendar value
                                                showIcon
                                                showTime
                                                dateFormat="dd-mm-yy"
                                                hourFormat="24"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                onSelect={(e) => {
                                                    // Format the date when selected and update the input value
                                                    const formattedDate = moment(e.value).format("YYYY-MM-DD HH:mm:ss");
                                                    input.onChange(formattedDate);
                                                }}
                                                onChange={(e) => {
                                                    // Update the input field only if the value is a valid date
                                                    if (e.value instanceof Date) {
                                                        const formattedDate = moment(e.value).format("YYYY-MM-DD HH:mm:ss");
                                                        input.onChange(formattedDate);
                                                    }
                                                }}
                                            />
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                                <Field name="proposed_end_date">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Proposed End Date</label>
                                            <Calendar
                                                {...input}
                                                value={input.value ? new Date(input.value) : null}
                                                showIcon
                                                showTime
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                dateFormat="dd-mm-yy"
                                                hourFormat="24"
                                                onChange={(e) => {
                                                    // Update the input field only if the value is a valid date
                                                    if (e.value instanceof Date) {
                                                        const formattedDate = moment(e.value).format("YYYY-MM-DD HH:mm:ss");
                                                        input.onChange(formattedDate);
                                                    }
                                                }}
                                                onSelect={(e) => {
                                                    // Format the date when selected and update the input value
                                                    const formattedDate = moment(e.value).format("YYYY-MM-DD HH:mm:ss");
                                                    input.onChange(formattedDate);
                                                }}
                                            />
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>

                                <Field name="actual_end_date">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Actual End Date</label>
                                            <Calendar
                                                {...input}
                                                value={input.value ? new Date(input.value) : null}
                                                showIcon
                                                showTime
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                dateFormat="dd-mm-yy"
                                                hourFormat="24"
                                                onChange={(e) => {
                                                    // Update the input field only if the value is a valid date
                                                    if (e.value instanceof Date) {
                                                        const formattedDate = moment(e.value).format("YYYY-MM-DD HH:mm:ss");
                                                        input.onChange(formattedDate);
                                                    }
                                                }}
                                                onSelect={(e) => {
                                                    // Format the date when selected and update the input value
                                                    const formattedDate = moment(e.value).format("YYYY-MM-DD HH:mm:ss");
                                                    input.onChange(formattedDate);
                                                }}
                                            />
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>

                                {/* <div style={{ marginBottom: "1rem" }}>
                                    <Field name="actual_cost">
                                        {({ input, meta }) => (
                                            <div className="p-field m-4">
                                                <label htmlFor="actual_cost">Actual Subproject Cost</label>
                                                <InputText
                                                    {...input}
                                                    id="actual_cost"
                                                    type="text" // Use text type to allow formatting
                                                    className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    value={input.value}
                                                    onChange={(e) => {
                                                        const handleNumberChange = (input, value) => {
                                                            // Strip out non-numeric characters (except decimal point)
                                                            const numericValue = value.replace(/[^\d.]/g, "");

                                                            // Check the length of digits before the decimal point does not exceed 15
                                                            if (numericValue.split(".")[0].length > 15) {
                                                                // If it does, do not update the input value and potentially show a warning message
                                                                return;
                                                            }

                                                            // Convert to a number and then back to a string to remove leading zeroes
                                                            const number = numericValue ? parseFloat(numericValue).toString() : "";

                                                            // Format the number with commas for display purposes
                                                            const formattedValue = number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                                                            // Update the input's displayed value with formattedValue
                                                            input.onChange(formattedValue);
                                                        };
                                                        return handleNumberChange(input, e.target.value);
                                                    }}
                                                />
                                                {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                            </div>
                                        )}
                                    </Field>
                                </div> */}

                                <div style={{ marginBottom: "1rem" }}>
                                    <Field name="actual_cost">
                                        {({ input, meta }) => (
                                            <div className="p-field m-4">
                                                <label htmlFor="actual_cost">Actual Subproject Cost</label>
                                                <InputText
                                                    {...input}
                                                    id="actual_cost"
                                                    type="text" // Use text type to allow formatting
                                                    className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    value={input.value}
                                                    onChange={(e) => {
                                                        const handleNumberChange = (input, value) => {
                                                            // Strip out non-numeric characters (except decimal point)
                                                            const numericValue = value.replace(/[^\d.]/g, "");

                                                            // Check the length of digits before the decimal point does not exceed 15
                                                            if (numericValue.split(".")[0].length > 15) {
                                                                // If it does, do not update the input value and potentially show a warning message
                                                                return;
                                                            }

                                                            // Convert to a number and then back to a string to remove leading zeroes
                                                            const number = numericValue ? parseFloat(numericValue).toString() : "";

                                                            // Format the number with commas for display purposes
                                                            const formattedValue = number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                                                            // Update the input's displayed value with formattedValue
                                                            input.onChange(formattedValue);
                                                        };
                                                        return handleNumberChange(input, e.target.value);
                                                    }}
                                                />
                                                {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                            </div>
                                        )}
                                    </Field>
                                </div>

                                {/* <Field name="funding">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label>Funding</label>
                                        <InputText {...input} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field> */}
                                {/* <Field name="funding">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="funding">Funding</label>
                                        <Dropdown
                                            id="funding"
                                            {...input}
                                            options={[
                                                { label: "Govt of Uganda", value: "Govt of Uganda" },
                                                { label: "United Nation", value: "United Nation" },
                                                { label: "World Bank", value: "World Bank" },
                                            ]}
                                            placeholder="Select"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                        />
                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field> */}
                                {/* <div style={{ marginBottom: "1rem" }}>
                                <Field name="funding">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Funding</label>
                                            <Dropdown
                                                value={input.value}
                                                options={getAllFundersQuery?.data?.data?.data.map((item) => ({
                                                    label: item.project_title,
                                                    value: item.id,
                                                }))}
                                                onChange={(e) => input.onChange(e.value)}
                                                placeholder="Select Funding"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                        </div>
                                    )}
                                </Field>
                            </div> */}

                                {/* <Field name="estimated_cost">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label>Estimated Cost</label>
                                        <InputText
                                            {...input}
                                            id="estimated_cost"
                                            type="number"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                // Allow only non-negative numbers
                                                if (!value || parseFloat(value) >= 0) {
                                                    input.onChange(value);
                                                }
                                            }}
                                        />

                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field> */}

                                <Field name="funder_ids">
                                    {({ input, meta }) => {
                                        console.log("kkkkkkk first main funders input.value : ", input.value);
                                        return (
                                            <div className="p-field m-4">
                                                <label htmlFor="funder_ids">Source of Funders</label>
                                                <AutoComplete
                                                    {...input}
                                                    suggestions={dropdownItem}
                                                    completeMethod={(e) => fetchSuggestions(e.query)}
                                                    multiple={true}
                                                    value={selectedAutoCompleteDropdownItem}
                                                    disabled={getAllFundersQuery.isLoading} // Disable component if data is still loading
                                                    field="name"
                                                    dropdown={true}
                                                    className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    onChange={(e) => {
                                                        const newSelected = e.value;

                                                        // Handle 'Select All Districts'
                                                        if (newSelected.some((district) => district.id === "all")) {
                                                            // Add all districts to the selection
                                                            const allDistricts = getAllFundersQuery.data?.data?.data || [];
                                                            setSelectedAutoCompleteDropdownItem(allDistricts);
                                                            input.onChange(allDistricts.map((district) => district.id));
                                                        } else {
                                                            if (newSelected.length < selectedAutoCompleteDropdownItem.length) {
                                                                // District removed
                                                                const removedDistrict = selectedAutoCompleteDropdownItem.find((district) => !newSelected.includes(district));
                                                                if (removedDistrict) {
                                                                    const updatedSelectedDistricts = selectedAutoCompleteDropdownItem.filter((district) => district.id !== removedDistrict.id);
                                                                    setSelectedAutoCompleteDropdownItem(updatedSelectedDistricts);
                                                                    input.onChange(updatedSelectedDistricts.map((district) => district.id));
                                                                }
                                                            } else {
                                                                // District added
                                                                const addedDistrict = newSelected[newSelected.length - 1];
                                                                setSelectedAutoCompleteDropdownItem([...selectedAutoCompleteDropdownItem, addedDistrict]);
                                                                input.onChange([...input.value, addedDistrict.id]);
                                                            }
                                                        }

                                                        fetchSuggestions("");
                                                    }}
                                                    id="funder_ids"
                                                />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                                {/* DataTable to display selected programs */}
                                                {input.value && input.value.length > 0 && (
                                                    <div className="p-field m-4">
                                                        <DataTable
                                                            value={selectedAutoCompleteDropdownItem}
                                                            paginator
                                                            rows={rows}
                                                            first={page * rows}
                                                            onPage={onPaging}
                                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                            rowsPerPageOptions={[5, 10, 20]}
                                                        >
                                                            <Column field="id" header="ID"></Column>
                                                            <Column field="name" header="Name"></Column>
                                                            <Column field="code" header="code"></Column>
                                                            <Column
                                                                body={(rowData) => (
                                                                    <DeleteIcon
                                                                        style={{ color: "tomato", cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            const updatedSelectedDistrict = selectedAutoCompleteDropdownItem.filter((district) => district.id !== rowData.id);
                                                                            setSelectedAutoCompleteDropdownItem(updatedSelectedDistrict);
                                                                            input.onChange(updatedSelectedDistrict.map((prog) => prog.id));
                                                                            fetchSuggestions(""); // Refresh the dropdown suggestions
                                                                        }}
                                                                    />
                                                                )}
                                                                header="Actions"
                                                            ></Column>
                                                        </DataTable>
                                                    </div>
                                                )}

                                                {getAllFundersQuery.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            </div>
                                        );
                                    }}
                                </Field>

                                <Field name="currency_ids">
                                    {({ input, meta }) => {
                                        console.log("kkkkkkk first currency ids input.value : ", input.value);
                                        return (
                                            <div className="p-field m-4">
                                                <label htmlFor="cunrrency_ids">Subproject Currencies</label>
                                                <AutoComplete
                                                    {...input}
                                                    suggestions={currencyDropdownItem}
                                                    completeMethod={(e) => fetchCurrencySuggestions(e.query)}
                                                    multiple={true}
                                                    value={selectedCurrencyAutoCompleteDropdownItem}
                                                    disabled={getAllCurrenciesQuery.isLoading} // Disable component if data is still loading
                                                    field="name"
                                                    dropdown={true}
                                                    className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    onChange={(e) => {
                                                        const newSelected = e.value;

                                                        // Handle 'Select All Districts'
                                                        if (newSelected.some((district) => district.id === "all")) {
                                                            // Add all districts to the selection
                                                            const allDistricts = getAllCurrenciesQuery.data?.data?.data || [];
                                                            setSelectedCurrencyAutoCompleteDropdownItem(allDistricts);
                                                            input.onChange(allDistricts.map((district) => district.id));
                                                        } else {
                                                            if (newSelected.length < selectedCurrencyAutoCompleteDropdownItem.length) {
                                                                // District removed
                                                                const removedDistrict = selectedCurrencyAutoCompleteDropdownItem.find((district) => !newSelected.includes(district));
                                                                if (removedDistrict) {
                                                                    const updatedSelectedDistricts = selectedCurrencyAutoCompleteDropdownItem.filter((district) => district.id !== removedDistrict.id);
                                                                    setSelectedCurrencyAutoCompleteDropdownItem(updatedSelectedDistricts);
                                                                    input.onChange(updatedSelectedDistricts.map((district) => district.id));
                                                                }
                                                            } else {
                                                                // District added
                                                                const addedDistrict = newSelected[newSelected.length - 1];
                                                                setSelectedCurrencyAutoCompleteDropdownItem([...selectedCurrencyAutoCompleteDropdownItem, addedDistrict]);
                                                                input.onChange([...input.value, addedDistrict.id]);
                                                            }
                                                        }

                                                        fetchCurrencySuggestions("");
                                                    }}
                                                    id="currency_ids"
                                                />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                                {/* DataTable to display selected programs */}
                                                {input.value && input.value.length > 0 && (
                                                    <div className="p-field m-4">
                                                        <DataTable
                                                            value={selectedCurrencyAutoCompleteDropdownItem}
                                                            paginator
                                                            rows={currencyRows}
                                                            first={currencyPage * currencyRows}
                                                            onPage={onCurrencyPaging}
                                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                            rowsPerPageOptions={[5, 10, 20]}
                                                        >
                                                            <Column field="id" header="ID"></Column>
                                                            <Column field="name" header="Name"></Column>
                                                            <Column field="code" header="code"></Column>
                                                            <Column
                                                                body={(rowData) => (
                                                                    <DeleteIcon
                                                                        style={{ color: "tomato", cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            const updatedSelectedCurrency = selectedCurrencyAutoCompleteDropdownItem.filter((district) => district.id !== rowData.id);
                                                                            setSelectedCurrencyAutoCompleteDropdownItem(updatedSelectedCurrency);
                                                                            input.onChange(updatedSelectedCurrency.map((prog) => prog.id));
                                                                            fetchCurrencySuggestions(""); // Refresh the dropdown suggestions
                                                                        }}
                                                                    />
                                                                )}
                                                                header="Actions"
                                                            ></Column>
                                                        </DataTable>
                                                    </div>
                                                )}

                                                {getAllFundersQuery.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            </div>
                                        );
                                    }}
                                </Field>

                                {/* <Field name="currency">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="currency">Currency</label>
                                            <Dropdown
                                                id="currency"
                                                {...input}
                                                options={[
                                                    { label: "Ugandan Shilling (UGX)", value: "UGX" },
                                                    { label: "United States Dollar (USD)", value: "USD" },
                                                    { label: "Euro (EUR)", value: "EUR" },
                                                    { label: "Japanese Yen (JPY)", value: "JPY" },
                                                    { label: "British Pound (GBP)", value: "GBP" },
                                                    { label: "Australian Dollar (AUD)", value: "AUD" },
                                                    { label: "Canadian Dollar (CAD)", value: "CAD" },
                                                    { label: "Swiss Franc (CHF)", value: "CHF" },
                                                    { label: "Chinese Yuan (CNY)", value: "CNY" },
                                                    { label: "Hong Kong Dollar (HKD)", value: "HKD" },
                                                    { label: "Singapore Dollar (SGD)", value: "SGD" },
                                                    { label: "Russian Ruble (RUB)", value: "RUB" },
                                                    { label: "Egyptian Pound (EGP)", value: "EGP" },
                                                    { label: "Saudi Arabian Riyal (SAR)", value: "SAR" },
                                                    { label: "Kenyan Shilling (KES)", value: "KES" },
                                                    { label: "Tanzanian Shilling (TZS)", value: "TZS" },
                                                ]}
                                                placeholder="Select"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field> */}

                                <div style={{ marginBottom: "1rem" }}>
                                    <Field name="estimated_cost">
                                        {({ input, meta }) => (
                                            <div className="p-field m-4">
                                                <label htmlFor="estimated_cost">Projected Cost</label>
                                                <InputText
                                                    {...input}
                                                    id="estimated_cost"
                                                    type="text" // Use text type to allow formatting
                                                    className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    value={input.value}
                                                    onChange={(e) => {
                                                        const handleNumberChange = (input, value) => {
                                                            // Strip out non-numeric characters (except decimal point)
                                                            const numericValue = value.replace(/[^\d.]/g, "");

                                                            // Check the length of digits before the decimal point does not exceed 15
                                                            if (numericValue.split(".")[0].length > 15) {
                                                                // If it does, do not update the input value and potentially show a warning message
                                                                return;
                                                            }

                                                            // Convert to a number and then back to a string to remove leading zeroes
                                                            const number = numericValue ? parseFloat(numericValue).toString() : "";

                                                            // Format the number with commas for display purposes
                                                            const formattedValue = number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                                                            // Update the input's displayed value with formattedValue
                                                            input.onChange(formattedValue);
                                                        };
                                                        return handleNumberChange(input, e.target.value);
                                                    }}
                                                />
                                                {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <Field name="contractor">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Contractor</label>
                                            <InputText {...input} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                                {/* <Field name="contract_amount">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="contract_amount">Contract Amount</label>
                                        <InputText
                                            {...input}
                                            id="capital_current_ratio"
                                            type="number"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                // Allow only non-negative numbers
                                                if (!value || parseFloat(value) >= 0) {
                                                    input.onChange(value);
                                                }
                                            }}
                                        />
                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field> */}

                                <div style={{ marginBottom: "1rem" }}>
                                    <Field name="contract_amount">
                                        {({ input, meta }) => (
                                            <div className="p-field m-4">
                                                <label htmlFor="contract_amount">Contract Amount</label>
                                                <InputText
                                                    {...input}
                                                    id="contract_amount"
                                                    type="text" // Use text type to allow formatting
                                                    className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    value={input.value}
                                                    onChange={(e) => {
                                                        const handleNumberChange = (input, value) => {
                                                            // Strip out non-numeric characters (except decimal point)
                                                            const numericValue = value.replace(/[^\d.]/g, "");

                                                            // Check the length of digits before the decimal point does not exceed 15
                                                            if (numericValue.split(".")[0].length > 15) {
                                                                // If it does, do not update the input value and potentially show a warning message
                                                                return;
                                                            }

                                                            // Convert to a number and then back to a string to remove leading zeroes
                                                            const number = numericValue ? parseFloat(numericValue).toString() : "";

                                                            // Format the number with commas for display purposes
                                                            const formattedValue = number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                                                            // Update the input's displayed value with formattedValue
                                                            input.onChange(formattedValue);
                                                        };
                                                        return handleNumberChange(input, e.target.value);
                                                    }}
                                                />
                                                {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                {/* <Field name="target_beneficiaries_total">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="target_beneficiaries_total">Target beneficiaries (Number)</label>
                                            <InputText
                                                {...input}
                                                id="target_beneficiaries_total"
                                                type="number"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    // Allow only non-negative numbers
                                                    if (!value || parseFloat(value) >= 0) {
                                                        input.onChange(value);
                                                    }
                                                }}
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field> */}

                                <div style={{ marginBottom: "1rem" }}>
                                    <Field name="target_beneficiaries_total">
                                        {({ input, meta }) => (
                                            <div className="p-field m-4">
                                                <label htmlFor="target_beneficiaries_total">Target beneficiaries (Number)</label>
                                                <InputText
                                                    {...input}
                                                    id="target_beneficiaries_total"
                                                    type="text" // Use text type to allow formatting
                                                    className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    value={input.value}
                                                    onChange={(e) => {
                                                        const handleNumberChange = (input, value) => {
                                                            // Strip out non-numeric characters (except decimal point)
                                                            const numericValue = value.replace(/[^\d.]/g, "");

                                                            // Check the length of digits before the decimal point does not exceed 15
                                                            if (numericValue.split(".")[0].length > 15) {
                                                                // If it does, do not update the input value and potentially show a warning message
                                                                return;
                                                            }

                                                            // Convert to a number and then back to a string to remove leading zeroes
                                                            const number = numericValue ? parseFloat(numericValue).toString() : "";

                                                            // Format the number with commas for display purposes
                                                            const formattedValue = number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                                                            // Update the input's displayed value with formattedValue
                                                            input.onChange(formattedValue);
                                                        };
                                                        return handleNumberChange(input, e.target.value);
                                                    }}
                                                />
                                                {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                            </div>
                                        )}
                                    </Field>
                                </div>

                                {/* Per Capita Investment Cost Field */}
                                {/* Per Capita Investment Cost Field */}
                                <div style={{ marginBottom: "1rem" }}>
                                    <Field name="per_capita_investment_cost">
                                        {({ input, meta }) => (
                                            <div className="p-field m-4">
                                                <label htmlFor="per_capita_investment_cost">Per Capita Investment Cost</label>
                                                <InputText
                                                    {...input}
                                                    id="per_capita_investment_cost"
                                                    type="text"
                                                    value={perCapitaInvestmentCost} // Display the calculated value
                                                    className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    readOnly // The field is read-only since its value is calculated
                                                />
                                                {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                            </div>
                                        )}
                                    </Field>
                                </div>

                                {/* <div style={{ marginBottom: "1rem" }}>
                                <Field name="per_capita_investment_cost">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Per Capita Investment Cost (USD)</label>
                                            <InputText
                                                {...input}
                                                id="per_capita_investment_cost"
                                                type="number"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    // Allow only non-negative numbers
                                                    if (!value || parseFloat(value) >= 0) {
                                                        input.onChange(value);
                                                    }
                                                }}
                                            />
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            </div> */}

                                <Field name="target_beneficiaries">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="target_beneficiaries">Target Beneficiaries</label>
                                            <InputTextarea {...input} id="target_beneficiaries" rows={5} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>

                                <Field name="capital_current_ratio">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="capital_current_ratio">Capital Current Ratio</label>
                                            <InputText {...input} id="capital_current_ratio" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>

                                <div style={{ marginBottom: "1rem" }}>
                                    <Field name="completion_status">
                                        {({ input, meta }) => (
                                            <div className="p-field m-4">
                                                <label>Completion Status</label>
                                                <Dropdown
                                                    value={input.value}
                                                    options={[
                                                        { label: "Ongoing", value: "Ongoing" },
                                                        { label: "Completed", value: "Completed" },
                                                    ]}
                                                    onChange={(e) => input.onChange(e.value)}
                                                    placeholder="Select Status"
                                                    className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                />
                                            </div>
                                        )}
                                    </Field>
                                </div>

                                <Field name="completion">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Completion (%)</label>
                                            <InputText
                                                {...input}
                                                id="completion"
                                                type="number"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    // Allow only non-negative numbers
                                                    if (!value || parseFloat(value) >= 0) {
                                                        input.onChange(value);
                                                    }
                                                }}
                                            />
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>

                                <Field name="status">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="status">Physical perfomence</label>
                                            <InputTextarea {...input} rows={5} cols={30} id="status" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>

                                <div style={{ marginBottom: "1rem" }}>
                                    <Field name="supervisor">
                                        {({ input, meta }) => (
                                            <div className="p-field m-4">
                                                <label>Supervisor</label>
                                                <Dropdown
                                                    value={input.value}
                                                    options={[
                                                        { label: "None", value: "" },
                                                        { label: "Internal", value: "Internal" },
                                                        { label: "External", value: "External" },
                                                    ]}
                                                    onChange={(e) => input.onChange(e.value)}
                                                    placeholder="Select Supervisor Type"
                                                    className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                />
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div style={{ marginBottom: "1rem" }}>
                                    <Field name="location_type">
                                        {({ input, meta }) => (
                                            <div className="p-field m-4">
                                                <label>Location Type</label>
                                                <Dropdown
                                                    value={input.value}
                                                    options={[
                                                        { label: "None", value: "" },
                                                        { label: "District", value: "District" },
                                                        { label: "Inter-District", value: "Inter-District" },
                                                    ]}
                                                    onChange={(e) => input.onChange(e.value)}
                                                    placeholder="Select Location Type"
                                                    className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                />
                                            </div>
                                        )}
                                    </Field>
                                </div>

                                <Field name="operators_ids">
                                    {({ input, meta }) => {
                                        console.log("kkkkkkk first main funders input.value : ", input.value);
                                        return (
                                            <div className="p-field m-4">
                                                <label htmlFor="operators_ids">Operation And Maintanace</label>
                                                <AutoComplete
                                                    {...input}
                                                    suggestions={dropOMdownItem}
                                                    completeMethod={(e) => fetchOMSuggestions(e.query)}
                                                    multiple={true}
                                                    value={selectedOmAutoCompleteDropdownItem}
                                                    disabled={getAllOperatorsQuery.isLoading} // Disable component if data is still loading
                                                    field="name"
                                                    dropdown={true}
                                                    className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    onChange={(e) => {
                                                        const newSelected = e.value;

                                                        // Handle 'Select All Districts'
                                                        if (newSelected.some((district) => district.id === "all")) {
                                                            // Add all districts to the selection
                                                            const allDistricts = getAllOperatorsQuery.data?.data?.data || [];
                                                            setSelectedOmAutoCompleteDropdownItem(allDistricts);
                                                            input.onChange(allDistricts.map((district) => district.id));
                                                        } else {
                                                            if (newSelected.length < selectedOmAutoCompleteDropdownItem.length) {
                                                                // District removed
                                                                const removedDistrict = selectedOmAutoCompleteDropdownItem.find((district) => !newSelected.includes(district));
                                                                if (removedDistrict) {
                                                                    const updatedSelectedDistricts = selectedOmAutoCompleteDropdownItem.filter((district) => district.id !== removedDistrict.id);
                                                                    setSelectedOmAutoCompleteDropdownItem(updatedSelectedDistricts);
                                                                    input.onChange(updatedSelectedDistricts.map((district) => district.id));
                                                                }
                                                            } else {
                                                                // District added
                                                                const addedDistrict = newSelected[newSelected.length - 1];
                                                                setSelectedOmAutoCompleteDropdownItem([...selectedOmAutoCompleteDropdownItem, addedDistrict]);
                                                                input.onChange([...input.value, addedDistrict.id]);
                                                            }
                                                        }

                                                        fetchOMSuggestions("");
                                                    }}
                                                    id="operators_ids"
                                                />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                                {/* DataTable to display selected programs */}
                                                {input.value && input.value.length > 0 && (
                                                    <div className="p-field m-4">
                                                        <DataTable
                                                            value={selectedOmAutoCompleteDropdownItem}
                                                            paginator
                                                            rows={omRows}
                                                            first={omPage * omRows}
                                                            onPage={omOnPaging}
                                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                            rowsPerPageOptions={[5, 10, 20]}
                                                        >
                                                            <Column field="id" header="ID"></Column>
                                                            <Column field="name" header="Name"></Column>
                                                            <Column field="code" header="code"></Column>
                                                            <Column
                                                                body={(rowData) => (
                                                                    <DeleteIcon
                                                                        style={{ color: "tomato", cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            const updatedSelectedDistrict = selectedOmAutoCompleteDropdownItem.filter((district) => district.id !== rowData.id);
                                                                            setSelectedOmAutoCompleteDropdownItem(updatedSelectedDistrict);
                                                                            input.onChange(updatedSelectedDistrict.map((prog) => prog.id));
                                                                            fetchOMSuggestions(""); // Refresh the dropdown suggestions
                                                                        }}
                                                                    />
                                                                )}
                                                                header="Actions"
                                                            ></Column>
                                                        </DataTable>
                                                    </div>
                                                )}

                                                {getAllOperatorsQuery.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            </div>
                                        );
                                    }}
                                </Field>

                                {/* <Field name="o_m_structure">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="o_m_structure">Operation And Maintanace structure</label>
                                            <Dropdown
                                                id="o_m_structure"
                                                {...input}
                                                options={[
                                                    { label: "National Water", value: "National Water" },
                                                    { label: "Umbrella / Utility & Regulation", value: "Umbrella / Utility & Regulation" },
                                                    { label: "Private Sector", value: "Private Sector" },
                                                    { label: "Community", value: "Community" },
                                                ]}
                                                placeholder="Select"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field> */}

                                <Button type="submit" label="Submit" disabled={submitting} />
                                {(createSubProjectMutation.isLoading || createSubProjectEditMutation.isLoading) && (
                                    <center>
                                        <ProgressSpinner
                                            style={{
                                                width: "50px",
                                                height: "50px",
                                                borderWidth: "50px", // Border thickness
                                                borderColor: "blue", // Border color
                                                animationDuration: "1s",
                                            }}
                                            strokeWidth="8"
                                            animationDuration="1s"
                                        />
                                    </center>
                                )}
                                <Dialog
                                    visible={showConfirmDialog}
                                    style={{ width: "450px" }}
                                    header="Confirm"
                                    modal
                                    onHide={() => setShowConfirmDialog(false)}
                                    footer={
                                        <div>
                                            <Button label="No" icon="pi pi-times" onClick={onCancel} className="p-button-text" />
                                            <Button label="Yes" icon="pi pi-check" onClick={onConfirm} autoFocus />
                                        </div>
                                    }
                                >
                                    Are you sure you want to submit this form?
                                </Dialog>
                            </form>
                        );
                    }}
                />
            </div>
        </div>
    );
};

export default SubProjectsRowForm;
