import React, { useState, useMemo } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Tree } from "primereact/tree";

import { Dropdown } from "primereact/dropdown";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { classNames } from "primereact/utils";

import setFieldTouched from "final-form-set-field-touched";

//
import { toast } from "react-toastify";

import { ProgressSpinner } from "primereact/progressspinner";

//
import { getAllCensus, getCensusById, postCensus, updateCensus, deleteCensusById } from "../../../../../../services/settings/census";
import { getAllVillage, getVillageById, postVillage, updateVillage, deleteVillageById } from "../../../../../../services/village/village-service";

function RowForm({ selectedVillageItem, handleSubmit, initialData, ...props }) {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);
    const queryClient = useQueryClient();
    console.log("selectedVillageItem lllll : ", selectedVillageItem);
    console.log("initialData dfgsdv : ", initialData);
    const validate = (values) => {
        const errors = {};
        if (!values.census_id) errors.census_id = "A Department is required";
        if (!values.village_id) errors.village_id = "A Village is required";
        if (!values.population) errors.population = "A Population is required";
        if (!values.comment) errors.comment = "Comment is required";

        return errors;
    };

    const onSubmitForm = (data, form) => {
        // Add 'form' as an argument
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            // Mark all fields as touched to show validation errors
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("First fill in all required fields.");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    //================ census ==============
    const fetchAllCensus = useQuery(["census", "census-by-id", selectedVillageItem?.directorate_id], () => (!selectedVillageItem?.directorate_id ? getAllCensus() : getAllCensus({ directorate_id: selectedVillageItem?.directorate_id })), {
        onSuccess: (data) => {
            // setDepartments(data.data);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
            console.log("get list of census : ", error);
        },
    });

    //================ census ==============

    if (selectedVillageItem?.id) {
        initialData = { village_id: selectedVillageItem?.id, ...initialData };
    }

    // initialData = { project_id: props?.project_id, ...initialData };
    // console.log("props?.selectedVillageItem ooo :", selectedVillageItem);

    return (
        <div className="col-12 md:col-12">
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    mutators={{ setFieldTouched }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            // onSubmit={(event) => {
                            //     event.preventDefault();
                            //     console.log("values hhh : ", values);
                            //     console.log("event fffff : ", event);
                            //     onSubmitForm(values);
                            //     // handleSubmit(event, values);
                            // }}
                            onSubmit={handleSubmit}
                        >
                            <div style={{ marginBottom: "1rem" }}>
                                <Field name="census_id">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Census</label>
                                            <Dropdown
                                                value={input.value}
                                                options={fetchAllCensus?.data?.data?.data.map((item) => ({
                                                    label: item.name,
                                                    value: item.id,
                                                }))}
                                                onChange={(e) => input.onChange(e.value)}
                                                placeholder="Select Census"
                                                disabled={fetchAllCensus.isLoading}
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                            {fetchAllCensus.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <div style={{ marginBottom: "1rem" }}>
                                <Field name="village_id">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Vilage</label>
                                            <Dropdown
                                                value={input.value}
                                                options={[selectedVillageItem].map((item) => ({
                                                    label: item.name,
                                                    value: item.id,
                                                }))}
                                                onChange={(e) => input.onChange(e.value)}
                                                placeholder="Select Village"
                                                disabled={fetchAllCensus.isLoading}
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                            {fetchAllCensus.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <div style={{ marginBottom: "1rem" }}>
                                <Field name="population">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Population</label>
                                            <InputText {...input} type="number" placeholder="Enter Population" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <Field name="comment">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="comment">Comment</label>
                                        <InputTextarea {...input} rows={5} cols={30} id="comment" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <div className="d-grid gap-2">
                                <Button type="submit" label="Save" className="p-button-primary" icon="pi pi-check" />
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    style={{ width: "30vw" }}
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to submit?
                </Dialog>
            </div>
        </div>
    );
}

export default RowForm;
