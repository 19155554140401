import axiosAPI from "../axiosApi";

export async function getAllDepartmentOutcomeIndicators(params = {}) {
    const response = await axiosAPI.get("departmentOutcomeIndicator", { params: params });
    return response;
}

export async function getDepartmentOutcomeIndicatorById(id) {
    const response = await axiosAPI.get(`departmentOutcomeIndicator/${id}`);
    return response;
}

export async function postDepartmentOutcomeIndicator(data) {
    const response = await axiosAPI.post(`departmentOutcomeIndicator`, data);
    return response;
}

export async function updateDepartmentOutcomeIndicator(id, data) {
    const response = await axiosAPI.put(`departmentOutcomeIndicator/${id}`, data);
    return response;
}

export async function deleteDepartmentOutcomeIndicatorById(id) {
    const response = await axiosAPI.delete(`departmentOutcomeIndicator/${id}`);
    return response;
}
