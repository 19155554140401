import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";

import { postDepartments } from "../../../services/departments/department-service";
import DepartmentsRowForm from "./widgets/DepartmentsRowForm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

function DepartmentsCreateForm({ selectedDirectorateItem, ...props }) {
    const queryClient = useQueryClient();

    const creactDepartmentsMutation = useMutation(postDepartments, {
        onSuccess: () => {
            queryClient.invalidateQueries(["departments"]);
            queryClient.invalidateQueries(["programs-hireachy"]);
            toast.success("Department details submitted successfully");
            props.onClose();
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
            console.log("create departments error : ", error);
        },
    });

    const handleSubmit = async (data) => {
        console.log("data we are submitting : ", data);
        creactDepartmentsMutation.mutate(data);
    };

    const footer = (
        <>
            {creactDepartmentsMutation.isLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px", // Border thickness
                            borderColor: "blue", // Border color
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={props.onHide} />
        </>
    );

    return (
        <Dialog header="Departments Form" visible={props.show} modal footer={footer} onHide={props.onHide} maximizable>
            <p>Fill in the form below</p>
            <DepartmentsRowForm selectedDirectorateItem={selectedDirectorateItem} directorateId={props?.directorateId} handleSubmit={handleSubmit} />
        </Dialog>
    );
}

export default DepartmentsCreateForm;
