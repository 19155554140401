import axiosAPI from "../../axiosApi";

export async function getAllDepartmentThemes(params = {}) {
    const response = await axiosAPI.get("utility-themes", { params: params });
    return response;
}

export async function getDepartmentThemeById(id) {
    const response = await axiosAPI.get(`utility-themes/${id}`);
    return response;
}

export async function postDepartmentTheme(data) {
    const response = await axiosAPI.post(`utility-themes`, data);
    return response;
}

export async function updateDepartmentTheme(id, data) {
    const response = await axiosAPI.put(`utility-themes/${id}`, data);
    return response;
}

export async function deleteDepartmentThemeById(id) {
    const response = await axiosAPI.delete(`utility-themes/${id}`);
    return response;
}
