import React from "react";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { postDirectorate } from "../../../services/departments/directorate-service";
import DirectorateRowForm from "./widgets/DirectorateRowForm";

function DirectorateForm(props) {
    const queryClient = useQueryClient();

    const createDirectorateMutation = useMutation(postDirectorate, {
        onSuccess: () => {
            queryClient.invalidateQueries(["directorate"]);
            queryClient.invalidateQueries(["programs", "programs-hireachy"]);
            toast.success("Directorate created successfully");
            props.onHide();
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
            console.log("create programs error : ", error);
        },
    });

    const handleSubmit = (data) => {
        createDirectorateMutation.mutate(data);
    };

    return (
        <Dialog header="Directorate Form" visible={props.show} onHide={() => props.onHide()} maximizable>
            <p>Fill in the form below</p>
            <DirectorateRowForm programDetail={props?.programDetail} currentProgramId={props?.currentProgramId} handleSubmit={handleSubmit} />
            {createDirectorateMutation.isLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px",
                            borderColor: "blue",
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
        </Dialog>
    );
}

export default DirectorateForm;
