// Recommendations.js
import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import RecommendationsFormModal from "./RecommendationsFormModal";
import { Dialog } from "primereact/dialog";
import RecomendationsHistory from "./RecomendationsHistory";

const Recommendations = ({ recommendations, setRecommendations, isSubmitDisabled, selectedSubProjectReports }) => {
    // const [recommendations, setRecommendations] = useState([]);
    const [showForm, setShowForm] = useState(false);

    const addRecommendation = (recommendationData) => {
        // Assuming each recommendation has a unique 'id' field
        setRecommendations([...recommendations, { id: recommendations.length + 1, ...recommendationData }]);
    };

    const deleteRecommendation = (id) => {
        setRecommendations(recommendations.filter((item) => item.id !== id));
    };

    const actionBodyTemplate = (rowData, index) => {
        return (
            <Button
                label="Delete"
                icon="pi pi-times"
                className="p-button-danger"
                onClick={(e) => {
                    e.preventDefault();
                    deleteRecommendation(rowData.id);
                }}
            />
        );
    };

    const [showHistoryModal, setShowHistoryModal] = useState(false);

    return (
        <div>
            <div style={{ height: "3rem", margin: "1rem", gap: "1rem", display: "flex", justifyContent: "flex-end" }}>
                <Button
                    label="View history"
                    onClick={(e) => {
                        e.preventDefault();
                        setShowHistoryModal(true);
                    }}
                    className="p-mt-2"
                />
                <Button
                    label="Add Challange"
                    icon="pi pi-plus"
                    onClick={(e) => {
                        e.preventDefault();
                        setShowForm(true);
                    }}
                    disabled={isSubmitDisabled}
                />
            </div>

            <DataTable value={recommendations}>
                <Column field="challenges" header="Challenges"></Column>
                <Column field="recommendations" header="Recommendations"></Column>
                <Column body={actionBodyTemplate}></Column>
            </DataTable>

            {showForm && <RecommendationsFormModal onHide={() => setShowForm(false)} onSubmit={addRecommendation} />}
            <Dialog header="Subproject Challenges and Recomendations History" visible={showHistoryModal} maximizable style={{ minWidth: "50vw" }} onHide={() => setShowHistoryModal(false)} modal>
                <RecomendationsHistory selectedSubProjectReports={selectedSubProjectReports} />
            </Dialog>
        </div>
    );
};

export default Recommendations;
