import React, { useState, useRef } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import setFieldTouched from "final-form-set-field-touched";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { AutoComplete } from "primereact/autocomplete";
import { ProgressSpinner } from "primereact/progressspinner";

import { Dropdown } from "primereact/dropdown";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { classNames } from "primereact/utils";

//
import { getAllYears, getYearById, postYears, updateYears, deleteYearById } from "../../../../../services/national-dashboard/years-service.js";

//
import { deleteSubProjectById, getAllSubProjects, getAllSubProjectsUnderUserProjectsByProjectIdOrRole } from "../../../../../services/sub-projects/sub-project-service";
import { deleteDistrictById, getAllDistricts } from "../../../../../services/districts/districts-service";
import { deleteCountyById, getAllCounty } from "../../../../../services/county/county-service";
import { deleteSubcountyById, getAllSubcounty } from "../../../../../services/subcounty/subcounty-service";
import { deleteParishById, getAllParish } from "../../../../../services/parish/parish-service";
import { deleteVillageById, getAllVillage } from "../../../../../services/village/village-service";
import { deleteProjectById, getAllProjects, filterProjectToGetItsDistricts, getProjectsByProjectIdOrRole, getProjectsByDepartmentIdAndRole } from "../../../../../services/projects/project-service";
import { Toast as PrimeReactToast } from "primereact/toast";
import { MapContainer, Map, TileLayer, useMap, Marker, Popup, LayersControl } from "react-leaflet";
import { deleteDepartmentById, getAllDepartments, getDepartmentByProgramId } from "../../../../../services/departments/department-service";
import {
    getAllDepartmentAdministrations,
    getDepartmentAdministrationById,
    postDepartmentAdministration,
    filterToGetDepartmentAdministrationDistrictsForMap,
    updateDepartmentAdministration,
    deleteDepartmentAdministrationById,
} from "../../../../../services/departments/department-administrations-service";
import { getAllDepartmentUtilities, getDepartmentUtilitieById, postDepartmentUtilities, updateDepartmentUtilities, deleteDepartmentUtilitieById } from "../../../../../services/departments/utility-dept/department-utilities-service.js";
import { getAllUtilityTypes, getUtilityTypesById, postUtilityTypes, updateUtilityTypes, deleteUtilityTypesById } from "../../../../../services/settings/utility-types-service.jsx";
import { getAllFinancialYears, getFinancialYearsById, postFinancialYears, updateFinancialYears, deleteFinancialYears } from "../../../../../services/financial-years/financial-years-service";
import { getAllQuarters, getQuartersById, postQuarters, updateQuarters, deleteQuarters } from "../../../../../services/financial-years/quarters";

function ProjectFilters({ setFilteredDataWithDistricts, loggedInUserData, handleSubmit, initialData, selectedParentItem, departmentId, setFilteredGeoJson, ...props }) {
    const toastRef = useRef(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);
    const queryClient = useQueryClient();

    const validate = (values) => {
        const errors = {};

        // if (!values.project_id) {
        //     errors.project_id = "A project is required.";
        // }

        if (!values.department_id) {
            errors.department_id = "Department is required.";
        }

        if (selectedDepartment?.code == "03" && !!selectedDepartment) {
            if (!values.utility_id) {
                errors.utility_id = "A Utility is required.";
            }

            if (!values.utility_type_id) {
                errors.utility_type_id = "A Utility Type is required.";
            }

            if (!values.year_id) {
                errors.year_id = "Year is required.";
            }
        }

        //
        // if (!values.subproject_id) {
        //     errors.subproject_id = "Subproject is required.";
        // }
        // if (!values.county_id) {
        //     errors.county_id = "County is required.";
        // }
        // if (!values.sub_county_id) {
        //     errors.sub_county_id = "Sub-county is required.";
        // }
        // if (!values.parish_id) {
        //     errors.parish_id = "Parish is required.";
        // }
        // if (!values.village_id) {
        //     errors.village_id = "Village is required.";
        // }

        // Perform any other validation checks that you might need

        return errors;
    };

    const onSubmitForm = (data, form) => {
        // Add 'form' as an argument
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            console.log("The data we are going to filter with : ", data);
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            // Mark all fields as touched to show validation errors
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("First fill in all required fields.");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            // handleSubmit(pendingData);
            // setProjectDistricts(null);
            setFilteredDataWithDistricts(null);
            setFilteredGeoJson(null);

            filterToGetDeptAdDistrictsForMapMutation.mutate(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    // ================== get finacial years =================
    const [selectedFinancialYear, setSelectedFinancialYear] = useState();
    const [filteredFinancialYears, setFilteredFinancialYears] = useState([]);

    const [selectedQuarter, setSelectedQuarter] = useState();
    const [filteredQuarters, setFilteredQuarters] = useState([]);

    const getAllFinancialYearsQuery = useQuery(["financialYear"], getAllFinancialYears, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching getAllFinancialYears is : ", error);
        },
    });

    // ================= quarters ================

    const getAllQuartersQuery = useQuery(["quarters"], getAllQuarters, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching quarters is : ", error);
        },
    });

    //==================== Departments ============
    const [selectedDepartment, setSelectedDepartment] = useState();
    const [filteredDepartment, setFilteredDepartment] = useState();
    // output_id;
    //loggedInUserData
    const getListOfDepartments = useQuery(["departments", loggedInUserData?.user_directorate_id], () => (!loggedInUserData?.user_directorate_id ? getAllDepartments() : getAllDepartments({ directorate_id: loggedInUserData?.user_directorate_id })), {
        enabled: !!loggedInUserData?.user_directorate_id,
        onSuccess: (data) => {
            if (!data || data.length === 0 || data.data.data.length === 0) {
                toastRef.current.show({ severity: "info", summary: "Info", detail: "Seems the there are no departments yet please contact admin", life: 3000 });
            }
        },
        onError: (error) => {
            console.log("Error fetching Years : ", error);
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
        },
    });

    console.log("getListOfDepartments xxxx : ", getListOfDepartments);

    // ======================  utility type ==============================
    const [selectedUtilityType, setSelectedUtilityType] = useState();
    const [filteredUtilityType, setFilteredUtilityType] = useState();
    const fetchAllDepartmentUtilityTypes = useQuery(["utility-types"], () => getAllUtilityTypes(), {
        enabled: selectedDepartment?.code == "03",
        onSuccess: (data) => {
            console.log("success department fetching utility-types is : ", data);
        },
        onError: (error) => {
            console.log("Error fetching utility-types is : ", error);
        },
    });

    // ====================== department utility ==============================
    const [selectedUtility, setSelectedUtility] = useState();
    const [filteredUtility, setFilteredUtility] = useState();
    const fetchAllDepartmentUtilities = useQuery(["utilities", selectedDepartment?.id, selectedUtilityType?.id], () => (!selectedDepartment?.id ? getAllDepartmentUtilities() : getAllDepartmentUtilities({ department_id: selectedDepartment?.id, utility_type_id: selectedUtilityType?.id })), {
        enabled: selectedDepartment?.code == "03" && !!selectedUtilityType?.id,
        onSuccess: (data) => {
            console.log("success department fetching utilities is : ", data);
        },
        onError: (error) => {
            console.log("Error fetching utilities is : ", error);
        },
    });

    //==================== Departments Administration ============

    const [selectedDepartmentAdministration, setSelectedDepartmentAdministration] = useState();
    const [filteredDepartmentAdministration, setFilteredDepartmentAdministration] = useState();
    //loggedInUserData
    const getListOfDepartmentAdministrations = useQuery(["department-administration", "departments", selectedDepartment?.id], () => (!selectedDepartment?.id ? getAllDepartmentAdministrations() : getAllDepartmentAdministrations({ department_id: selectedDepartment?.id })), {
        enabled: !!selectedDepartment?.id && selectedDepartment?.code !== "03",
        onSuccess: (data) => {
            if (!data || data.length === 0 || data.data.data.length === 0) {
                toastRef.current.show({ severity: "info", summary: "Info", detail: "Seems the there are no departments administrations yet please contact admin", life: 3000 });
            }
        },
        onError: (error) => {
            console.log("Error fetching getListOfDepartmentAdministrations: ", error);
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
        },
    });

    console.log("getListOfDepartmentAdministrations xxxx : ", getListOfDepartmentAdministrations);

    //================= projects ===============================
    const [selectedProject, setSelectedProject] = useState();
    const [filteredProjects, setFilteredProjects] = useState();

    const getAllProjectsQuery = useQuery(["projects", selectedDepartmentAdministration?.id], () => (!selectedDepartmentAdministration?.id ? getAllProjects() : getAllProjects({ department_administrations_id: selectedDepartmentAdministration?.id })), {
        enabled: !!selectedDepartmentAdministration?.id,
        onSuccess: (data) => {
            console.log("getAllProjectsQuery fetching projects : ", data);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
            console.log("get list of getAllProjectsQuery : ", error);
        },
    });

    // ========================== fetch all sub projects =======================
    const [selectedSubProject, setSelectedSubProject] = useState();
    const [filteredSubProjects, setFilteredSubProjects] = useState();

    const fetchAllSubProjects = useQuery(["subprojects", selectedProject?.id], () => getAllSubProjects({ project_id: selectedProject?.id }), {
        enabled: !!selectedProject?.id, // Only run the query if a project is selected
        onSuccess: (data) => {
            console.log("getAllProjects onsuccess fetching projects dsfsd : ", data);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
            console.log("get list of projects : ", error);
        },
    });

    console.log("selectedProject : ", selectedProject);

    //

    //==================== districts ============
    const [selectedDistrict, setSelectedDistrict] = useState();
    const [filteredDistricts, setFilteredDistricts] = useState();
    // districts are fetched from those attached to the project so if the project has non also the subproject wont have
    // subproject_id;
    const getListOfDistricts = useQuery(["districts", selectedUtility?.id], () => (!selectedUtility?.id ? getAllDistricts() : getAllDistricts({ utility_id: selectedUtility?.id })), {
        onSuccess: (data) => {
            if (!data || data.length === 0 || data.data.data.length === 0) {
                toastRef.current.show({ severity: "info", summary: "Info", detail: "Seems the Utility has no districts attached to it", life: 3000 });
            }
        },
        onError: (error) => {
            console.log("Error fetching districts : ", error);
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
        },
        staleTime: 5 * 60 * 60 * 1000, // 5 hours in milliseconds
    });

    console.log("getListOfDistricts xxxx : ", getListOfDistricts);

    // ================= Counties ========================
    const [selectedCounty, setSelectedCounty] = useState();
    const [filteredCounties, setFilteredCounties] = useState();

    const getListOfCounties = useQuery(["counties", selectedDistrict?.id], () => getAllCounty({ district_id: selectedDistrict?.id }), {
        enabled: !!selectedDistrict?.id, // Only run the query if a district is selected
        onSuccess: (data) => {
            // Handle successful data fetching
        },
        onError: (error) => {
            // Log and display error messages
            const errorMessage = error?.response?.data?.message || "An Error Occurred. Please Contact Admin";
            toast.error(errorMessage);
            console.error("Error fetching counties: ", error);
        },
        staleTime: 5 * 60 * 60 * 1000, // 5 hours in milliseconds
    });

    //================ Sub County =================================================================

    const [selectedSubCounty, setSelectedSubCounty] = useState();
    const [filteredSubCountys, setFilteredSubCountys] = useState();

    const getListOfSubCounties = useQuery(["subcounties", selectedCounty?.id], () => getAllSubcounty({ county_id: selectedCounty?.id }), {
        enabled: !!selectedCounty?.id, // Only run the query if a county is selected
        onSuccess: (data) => {
            // Handle successful data fetching
        },
        onError: (error) => {
            // Log and display error messages
            const errorMessage = error?.response?.data?.message || "An Error Occurred. Please Contact Admin";
            toast.error(errorMessage);
            console.error("Error fetching sub counties: ", error);
        },
        staleTime: 5 * 60 * 60 * 1000, // 5 hours in milliseconds
    });

    //

    const [selectedParish, setSelectedParish] = useState();
    const [filteredParishs, setFilteredParishs] = useState();

    const getListOfParishes = useQuery(["parishes", selectedSubCounty?.id], () => getAllParish({ sub_county_id: selectedSubCounty?.id }), {
        enabled: !!selectedSubCounty?.id, // Only run the query if a sub-county is selected
        onSuccess: (data) => {
            // Handle successful data fetching
        },
        onError: (error) => {
            // Log and display error messages
            const errorMessage = error?.response?.data?.message || "An Error Occurred. Please Contact Admin";
            toast.error(errorMessage);
            console.error("Error fetching parishes: ", error);
        },
        staleTime: 5 * 60 * 60 * 1000, // 5 hours in milliseconds
    });

    //

    const [selectedVillage, setSelectedVillage] = useState();
    const [filteredVillages, setFilteredVillages] = useState();

    const getListOfVillages = useQuery(["villages", selectedParish?.id], () => getAllVillage({ parish_id: selectedParish?.id }), {
        enabled: !!selectedParish?.id, // Only run the query if a parish is selected
        onSuccess: (data) => {
            // Handle successful data fetching
        },
        onError: (error) => {
            // Log and display error messages
            const errorMessage = error?.response?.data?.message || "An Error Occurred. Please Contact Admin";
            toast.error(errorMessage);
            console.error("Error fetching villages: ", error);
        },
        staleTime: 5 * 60 * 60 * 1000, // 5 hours in milliseconds
    });

    //==================== years ============

    const [selectedYear, setSelectedYear] = useState();
    const [filteredYear, setFilteredYear] = useState();
    // year_id;
    const getListOfYears = useQuery(["years"], () => getAllYears(), {
        onSuccess: (data) => {
            if (!data || data.length === 0 || data.data.data.length === 0) {
                toastRef.current.show({ severity: "info", summary: "Info", detail: "Seems the there are no years yet please contact admin", life: 3000 });
            }
        },
        onError: (error) => {
            console.log("Error fetching Years : ", error);
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
        },
        staleTime: 5 * 60 * 60 * 1000, // 5 hours in milliseconds
    });

    console.log("getListOfYears xxxx : ", getListOfYears);

    //
    console.log("ggggsgh initialData : ", initialData);

    //==================== projects filter mutation ==============
    const filterToGetDeptAdDistrictsForMapMutation = useMutation(filterToGetDepartmentAdministrationDistrictsForMap, {
        onSuccess: (data) => {
            console.log("data filterToGetDeptAdDistrictsForMapMutation : ", data);
            // setProjectDistricts(data?.data);
            setFilteredDataWithDistricts(data?.data);
            // queryClient.invalidateQueries(["projects"]);
            // queryClient.invalidateQueries(["programs", "programs-hireachy"]);

            // Check for department administration districts
            if (data?.data?.department_administration_districts && data?.data?.department_administration_districts.length < 1) {
                toast.warning("Department Administration wasn't assigned a district");
            }
            // Check for project districts
            else if (data?.data?.project_districts && data?.data?.project_districts.length < 1) {
                toast.warning("Project wasn't assigned a district");
            }
            // Check for subproject locations
            else if (data?.data?.subproject_locations && data?.data?.subproject_locations.length < 1) {
                toast.warning("The Subproject has no activites yet");
            } else {
                toast.success("Data Fetched Successfully");
            }
            // props.onHide();
        },
        onError: (error) => {
            toast.error(error?.response?.data?.message || "An Error Occurred. Please Contact Admin");
        },
    });

    return (
        <div className="col-12 md:col-12">
            <PrimeReactToast ref={toastRef} />
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    mutators={{ setFieldTouched }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                console.log("values hhh : ", values);
                                console.log("event fffff : ", event);
                                onSubmitForm(values, form);
                                // handleSubmit(event, values);
                            }}
                        >
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                                <Field name="department_id">
                                    {({ input, meta }) => (
                                        <div className="m-2" style={{ maxWidth: "300px" }}>
                                            <label htmlFor="department_id">Department</label>
                                            <AutoComplete
                                                value={selectedDepartment?.name || ""}
                                                suggestions={filteredDepartment}
                                                disabled={getListOfDepartments.isLoading}
                                                completeMethod={(e) => {
                                                    const results = getListOfDepartments.data?.data?.data.filter((department) => {
                                                        return department.name.toLowerCase().includes(e.query.toLowerCase());
                                                    });
                                                    setFilteredDepartment(results);
                                                }}
                                                field="name"
                                                dropdown={true}
                                                onChange={(e) => {
                                                    if (typeof e.value === "string") {
                                                        // Update the display value to the typed string and reset the selected department
                                                        setSelectedDepartment({ name: e.value });
                                                        input.onChange("");
                                                    } else if (typeof e.value === "object" && e.value !== null) {
                                                        // Update the selected department and set the form state with the selected department's ID
                                                        setSelectedDepartment(e.value);
                                                        input.onChange(e.value.id);
                                                        // Reset the child fields
                                                        setSelectedFinancialYear(null);
                                                        setSelectedQuarter(null);
                                                        setSelectedUtility(null);
                                                        setSelectedUtilityType(null);
                                                        setSelectedDepartmentAdministration(null);
                                                        setSelectedProject(null);
                                                        setSelectedDistrict(null);
                                                        setSelectedCounty(null);
                                                        setSelectedSubCounty(null);
                                                        setSelectedParish(null);
                                                        setSelectedVillage(null);
                                                        setSelectedSubProject(null);

                                                        form.change("utility_id", undefined);
                                                        form.change("utility_type_id", undefined);
                                                        form.change("department_administration_id", undefined);
                                                        form.change("project_id", undefined);
                                                        form.change("subproject_id", undefined);
                                                        form.change("fyear_id", undefined);
                                                        form.change("quarter_id", undefined);
                                                        form.change("district_id", undefined);
                                                        form.change("county_id", undefined);
                                                        form.change("sub_county_id", undefined);
                                                        form.change("parish_id", undefined);
                                                        form.change("village_id", undefined);
                                                    }
                                                }}
                                                id="department_id"
                                                selectedItemTemplate={(value) => <div>{value ? value.value : "Select a Department"}</div>}
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                            {getListOfDepartments.isLoading && getListOfDepartments.isFetching && getListOfDepartments.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        </div>
                                    )}
                                </Field>

                                {selectedDepartment?.code == "03" && !!selectedDepartment && (
                                    <>
                                        {" "}
                                        <Field name="utility_type_id">
                                            {({ input, meta }) => (
                                                <div className="m-2" style={{ maxWidth: "300px" }}>
                                                    <label htmlFor="utility_type_id">Utility Type</label>
                                                    <AutoComplete
                                                        value={selectedUtilityType?.name || ""}
                                                        key={selectedUtilityType?.name || "department-utility-default-key"}
                                                        suggestions={filteredUtilityType}
                                                        disabled={fetchAllDepartmentUtilityTypes.isLoading}
                                                        completeMethod={(e) => {
                                                            const results = fetchAllDepartmentUtilityTypes.data?.data?.data?.filter((department) => {
                                                                return department.name.toLowerCase().includes(e.query.toLowerCase());
                                                            });
                                                            setFilteredUtilityType(results);
                                                        }}
                                                        field="name"
                                                        dropdown={true}
                                                        onChange={(e) => {
                                                            if (typeof e.value === "string") {
                                                                // Update the display value to the typed string and reset the selected department
                                                                setSelectedUtilityType({ name: e.value });
                                                                input.onChange("");
                                                            } else if (typeof e.value === "object" && e.value !== null) {
                                                                // Update the selected department and set the form state with the selected department's ID
                                                                setSelectedUtilityType(e.value);
                                                                input.onChange(e.value.id);
                                                                // Clear the values of the child locations
                                                                // Reset the child fields
                                                                setSelectedDepartmentAdministration(null);
                                                                setSelectedProject(null);
                                                                // setSelectedSubproject(null);
                                                                // setSelectedFinancialYear(null);
                                                                // setSelectedQuarter(null);
                                                                setSelectedUtility(null);
                                                                setSelectedDistrict(null);
                                                                setSelectedCounty(null);
                                                                setSelectedSubCounty(null);
                                                                setSelectedParish(null);
                                                                setSelectedVillage(null);

                                                                // // Conditional form changes
                                                                if (form) {
                                                                    form.change("utility_id", undefined);

                                                                    form.change("department_administration_id", undefined);
                                                                    form.change("project_id", undefined);
                                                                    form.change("subproject_id", undefined);
                                                                    // form.change("fyear_id", undefined);
                                                                    // form.change("quarter_id", undefined);

                                                                    form.change("district_id", undefined);
                                                                    form.change("county_id", undefined);
                                                                    form.change("sub_county_id", undefined);
                                                                    form.change("parish_id", undefined);
                                                                    form.change("village_id", undefined);
                                                                }
                                                            }
                                                        }}
                                                        id="utility_type_id"
                                                        selectedItemTemplate={(value) => <div>{value ? value.value : "Select Utility Type"}</div>}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                    {fetchAllDepartmentUtilityTypes.isLoading && fetchAllDepartmentUtilityTypes.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            )}
                                        </Field>
                                        <Field name="utility_id">
                                            {({ input, meta }) => (
                                                <div className="m-2" style={{ maxWidth: "300px" }}>
                                                    <label htmlFor="utility_id">Utility</label>
                                                    <AutoComplete
                                                        value={selectedUtility?.name || ""}
                                                        key={selectedUtility?.name || "department-utility-default-key"}
                                                        suggestions={filteredUtility}
                                                        disabled={fetchAllDepartmentUtilities.isLoading}
                                                        completeMethod={(e) => {
                                                            const results = fetchAllDepartmentUtilities.data?.data?.data?.filter((department) => {
                                                                return department.name.toLowerCase().includes(e.query.toLowerCase());
                                                            });
                                                            setFilteredUtility(results);
                                                        }}
                                                        field="name"
                                                        dropdown={true}
                                                        onChange={(e) => {
                                                            if (typeof e.value === "string") {
                                                                // Update the display value to the typed string and reset the selected department
                                                                setSelectedUtility({ name: e.value });
                                                                input.onChange("");
                                                            } else if (typeof e.value === "object" && e.value !== null) {
                                                                // Update the selected department and set the form state with the selected department's ID
                                                                setSelectedUtility(e.value);
                                                                input.onChange(e.value.id);
                                                                // Clear the values of the child locations
                                                                // Reset the child fields
                                                                setSelectedDepartmentAdministration(null);
                                                                setSelectedProject(null);
                                                                // setSelectedSubproject(null);
                                                                // setSelectedFinancialYear(null);
                                                                // setSelectedQuarter(null);
                                                                setSelectedDistrict(null);
                                                                setSelectedCounty(null);
                                                                setSelectedSubCounty(null);
                                                                setSelectedParish(null);
                                                                setSelectedVillage(null);

                                                                // // Conditional form changes
                                                                if (form) {
                                                                    form.change("department_administration_id", undefined);
                                                                    form.change("project_id", undefined);
                                                                    form.change("subproject_id", undefined);
                                                                    // form.change("fyear_id", undefined);
                                                                    // form.change("quarter_id", undefined);

                                                                    form.change("district_id", undefined);
                                                                    form.change("county_id", undefined);
                                                                    form.change("sub_county_id", undefined);
                                                                    form.change("parish_id", undefined);
                                                                    form.change("village_id", undefined);
                                                                }
                                                            }
                                                        }}
                                                        id="utility_id"
                                                        selectedItemTemplate={(value) => <div>{value ? value.value : "Select a Utility"}</div>}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                    {fetchAllDepartmentUtilities.isLoading && fetchAllDepartmentUtilities.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            )}
                                        </Field>
                                        <Field name="district_id">
                                            {({ input, meta }) => (
                                                <div className="m-2" style={{ maxWidth: "300px" }}>
                                                    <label htmlFor="district_id">District</label>
                                                    <AutoComplete
                                                        value={selectedDistrict?.name || ""}
                                                        suggestions={filteredDistricts}
                                                        disabled={getListOfDistricts.isLoading}
                                                        completeMethod={(e) => {
                                                            const results = getListOfDistricts.data?.data?.data.filter((department) => {
                                                                return department.name.toLowerCase().includes(e.query.toLowerCase());
                                                            });
                                                            setFilteredDistricts(results);
                                                        }}
                                                        field="name"
                                                        dropdown={true}
                                                        onChange={(e) => {
                                                            if (typeof e.value === "string") {
                                                                // Update the display value to the typed string and reset the selected department
                                                                setSelectedDistrict({ name: e.value });
                                                                input.onChange("");
                                                            } else if (typeof e.value === "object" && e.value !== null) {
                                                                // Update the selected department and set the form state with the selected department's ID
                                                                setSelectedDistrict(e.value);
                                                                input.onChange(e.value.id);
                                                                // Clear the values of the child locations
                                                                setSelectedCounty(null);
                                                                setSelectedSubCounty(null);
                                                                setSelectedParish(null);
                                                                setSelectedVillage(null);

                                                                if (form) {
                                                                    // form.change("fyear_id", undefined);
                                                                    // form.change("quarter_id", undefined);

                                                                    form.change("county_id", undefined);
                                                                    form.change("sub_county_id", undefined);
                                                                    form.change("parish_id", undefined);
                                                                    form.change("village_id", undefined);
                                                                }
                                                            }
                                                        }}
                                                        id="district"
                                                        selectedItemTemplate={(value) => <div>{value ? value.name : "Select a District"}</div>}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                    {getListOfDistricts.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            )}
                                        </Field>
                                        <Field name="county_id">
                                            {({ input, meta }) => (
                                                <div className="m-2" style={{ maxWidth: "300px" }}>
                                                    <label htmlFor="county_id">County</label>
                                                    <AutoComplete
                                                        value={selectedCounty?.name || ""}
                                                        suggestions={filteredCounties}
                                                        completeMethod={(e) => {
                                                            if (getListOfCounties.data?.data?.data) {
                                                                const results = getListOfCounties.data?.data?.data.filter((county) => {
                                                                    return county.name.toLowerCase().includes(e.query.toLowerCase());
                                                                });
                                                                setFilteredCounties(results);
                                                            }
                                                        }}
                                                        field="name"
                                                        dropdown={true}
                                                        onChange={(e) => {
                                                            if (typeof e.value === "string") {
                                                                setSelectedCounty({ name: e.value });
                                                                input.onChange("");
                                                            } else if (typeof e.value === "object" && e.value !== null) {
                                                                setSelectedCounty(e.value);
                                                                input.onChange(e.value.id);
                                                                // Clear the values of the child locations
                                                                setSelectedSubCounty(null);
                                                                setSelectedParish(null);
                                                                setSelectedVillage(null);

                                                                if (form) {
                                                                    // form.change("fyear_id", undefined);
                                                                    // form.change("quarter_id", undefined);

                                                                    form.change("sub_county_id", undefined);
                                                                    form.change("parish_id", undefined);
                                                                    form.change("village_id", undefined);
                                                                }
                                                            }
                                                        }}
                                                        id="county"
                                                        selectedItemTemplate={(value) => <div>{value ? value.name : "Select a County"}</div>}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                    {getListOfCounties.isLoading && getListOfCounties.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            )}
                                        </Field>
                                        <Field name="sub_county_id">
                                            {({ input, meta }) => (
                                                <div className="m-2" style={{ maxWidth: "300px" }}>
                                                    <label htmlFor="sub_county_id">Sub-County</label>
                                                    <AutoComplete
                                                        value={selectedSubCounty?.name || ""}
                                                        suggestions={filteredSubCountys}
                                                        completeMethod={(e) => {
                                                            if (getListOfSubCounties.data?.data?.data) {
                                                                const results = getListOfSubCounties.data?.data?.data.filter((subCounty) => {
                                                                    return subCounty.name.toLowerCase().includes(e.query.toLowerCase());
                                                                });
                                                                setFilteredSubCountys(results);
                                                            }
                                                        }}
                                                        field="name"
                                                        dropdown={true}
                                                        onChange={(e) => {
                                                            if (typeof e.value === "string") {
                                                                setSelectedSubCounty({ name: e.value });
                                                                input.onChange("");
                                                            } else if (typeof e.value === "object" && e.value !== null) {
                                                                setSelectedSubCounty(e.value);
                                                                input.onChange(e.value.id);
                                                                // Clear the values of the child locations
                                                                setSelectedParish(null);
                                                                setSelectedVillage(null);

                                                                if (form) {
                                                                    // form.change("fyear_id", undefined);
                                                                    // form.change("quarter_id", undefined);

                                                                    form.change("parish_id", undefined);
                                                                    form.change("village_id", undefined);
                                                                }
                                                            }
                                                        }}
                                                        id="sub_county"
                                                        selectedItemTemplate={(value) => <div>{value ? value.name : "Select a Sub-County"}</div>}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                    {getListOfSubCounties.isLoading && getListOfSubCounties.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            )}
                                        </Field>
                                        <Field name="parish_id">
                                            {({ input, meta }) => (
                                                <div className="m-2" style={{ maxWidth: "300px" }}>
                                                    <label htmlFor="parish_id">Parish</label>
                                                    <AutoComplete
                                                        value={selectedParish?.name || ""}
                                                        suggestions={filteredParishs}
                                                        completeMethod={(e) => {
                                                            if (getListOfParishes.data?.data?.data) {
                                                                const results = getListOfParishes.data?.data?.data.filter((parish) => {
                                                                    return parish.name.toLowerCase().includes(e.query.toLowerCase());
                                                                });
                                                                setFilteredParishs(results);
                                                            }
                                                        }}
                                                        field="name"
                                                        dropdown={true}
                                                        onChange={(e) => {
                                                            if (typeof e.value === "string") {
                                                                setSelectedParish({ name: e.value });
                                                                input.onChange("");
                                                            } else if (typeof e.value === "object" && e.value !== null) {
                                                                setSelectedParish(e.value);
                                                                input.onChange(e.value.id);
                                                                // Clear the value of the child location
                                                                setSelectedVillage(null);

                                                                if (form) {
                                                                    // form.change("fyear_id", undefined);
                                                                    // form.change("quarter_id", undefined);

                                                                    form.change("village_id", undefined);
                                                                }
                                                            }
                                                        }}
                                                        id="parish"
                                                        selectedItemTemplate={(value) => <div>{value ? value.name : "Select a Parish"}</div>}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                    {getListOfParishes.isLoading && getListOfParishes.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            )}
                                        </Field>
                                        <Field name="village_id">
                                            {({ input, meta }) => (
                                                <div className="m-2" style={{ maxWidth: "300px" }}>
                                                    <label htmlFor="village_id">Village</label>
                                                    <AutoComplete
                                                        value={selectedVillage?.name || ""}
                                                        suggestions={filteredVillages}
                                                        completeMethod={(e) => {
                                                            if (getListOfVillages.data?.data?.data) {
                                                                const results = getListOfVillages.data?.data?.data.filter((village) => {
                                                                    return village.name.toLowerCase().includes(e.query.toLowerCase());
                                                                });
                                                                setFilteredVillages(results);
                                                            }
                                                        }}
                                                        field="name"
                                                        dropdown={true}
                                                        onChange={(e) => {
                                                            if (typeof e.value === "string") {
                                                                setSelectedVillage({ name: e.value });
                                                                input.onChange("");
                                                            } else if (typeof e.value === "object" && e.value !== null) {
                                                                setSelectedVillage(e.value);
                                                                input.onChange(e.value.id);
                                                                // No child locations to clear for village

                                                                if (form) {
                                                                    // form.change("fyear_id", undefined);
                                                                    // form.change("quarter_id", undefined);
                                                                    // form.change("village_id", undefined);
                                                                }
                                                            }
                                                        }}
                                                        id="village"
                                                        selectedItemTemplate={(value) => <div>{value ? value.name : "Select a Village"}</div>}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                    {getListOfVillages.isLoading && getListOfVillages.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            )}
                                        </Field>
                                        <Field name="year_id">
                                            {({ input, meta }) => (
                                                <div className="m-2" style={{ maxWidth: "300px" }}>
                                                    <label htmlFor="year_id">Year</label>
                                                    <AutoComplete
                                                        value={selectedYear?.value || ""}
                                                        suggestions={filteredYear}
                                                        disabled={getListOfYears.isLoading}
                                                        completeMethod={(e) => {
                                                            const results = getListOfYears.data?.data?.data.filter((department) => {
                                                                return department.value.toLowerCase().includes(e.query.toLowerCase());
                                                            });
                                                            setFilteredYear(results);
                                                        }}
                                                        field="value"
                                                        dropdown={true}
                                                        onChange={(e) => {
                                                            if (typeof e.value === "string") {
                                                                // Update the display value to the typed string and reset the selected department
                                                                setSelectedYear({ value: e.value });
                                                                input.onChange("");
                                                            } else if (typeof e.value === "object" && e.value !== null) {
                                                                // Update the selected department and set the form state with the selected department's ID
                                                                setSelectedYear(e.value);
                                                                input.onChange(e.value.id);
                                                                // Clear the values of the child locations
                                                                // setSelectedCounty(null);
                                                                // setSelectedSubCounty(null);
                                                                // setSelectedParish(null);
                                                                // setSelectedVillage(null);
                                                            }
                                                        }}
                                                        id="year_id"
                                                        selectedItemTemplate={(value) => <div>{value ? value.value : "Select a Year"}</div>}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                    {getListOfYears.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            )}
                                        </Field>
                                    </>
                                )}

                                {selectedDepartment?.code !== "03" && !!selectedDepartment && (
                                    <>
                                        {" "}
                                        <Field name="department_administration_id">
                                            {({ input, meta }) => (
                                                <div className="m-2" style={{ maxWidth: "300px" }}>
                                                    <label htmlFor="department_administration_id">Department Administration</label>
                                                    <AutoComplete
                                                        value={selectedDepartmentAdministration?.name || ""}
                                                        key={selectedDepartmentAdministration?.name || "default-department-admin-unit-key"}
                                                        suggestions={filteredDepartmentAdministration}
                                                        disabled={getListOfDepartmentAdministrations.isLoading}
                                                        completeMethod={(e) => {
                                                            const results = getListOfDepartmentAdministrations.data?.data?.data.filter((department) => {
                                                                return department.name.toLowerCase().includes(e.query.toLowerCase());
                                                            });
                                                            setFilteredDepartmentAdministration(results);
                                                        }}
                                                        field="name"
                                                        dropdown={true}
                                                        onChange={(e) => {
                                                            if (typeof e.value === "string") {
                                                                // Update the display value to the typed string and reset the selected department
                                                                setSelectedDepartmentAdministration({ name: e.value });
                                                                input.onChange("");
                                                            } else if (typeof e.value === "object" && e.value !== null) {
                                                                // Update the selected department and set the form state with the selected department's ID
                                                                setSelectedDepartmentAdministration(e.value);
                                                                input.onChange(e.value.id);
                                                                // Clear the values of the child locations
                                                                // Reset the child fields

                                                                setSelectedProject(null);
                                                                form.change("project_id", undefined);
                                                                form.change("subproject_id", undefined);
                                                            }
                                                        }}
                                                        id="department_administration_id"
                                                        selectedItemTemplate={(value) => <div>{value ? value.value : "Select a Department Administration"}</div>}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                    {getListOfDepartmentAdministrations.isLoading && getListOfDepartmentAdministrations.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            )}
                                        </Field>
                                        <Field name="project_id">
                                            {({ input, meta }) => (
                                                <div className="m-2" style={{ maxWidth: "300px" }}>
                                                    <label htmlFor="project_id">Projects</label>
                                                    <AutoComplete
                                                        value={selectedProject?.project_title || ""}
                                                        key={selectedProject?.project_title || "default-project-key"}
                                                        suggestions={filteredProjects}
                                                        disabled={getAllProjectsQuery.isLoading}
                                                        completeMethod={(e) => {
                                                            const results = getAllProjectsQuery.data?.data?.data.filter((project) => {
                                                                return project.project_title.toLowerCase().includes(e.query.toLowerCase());
                                                            });
                                                            setFilteredProjects(results);
                                                        }}
                                                        field="project_title"
                                                        dropdown={true}
                                                        onChange={(e) => {
                                                            if (typeof e.value === "string") {
                                                                // Update the display value to the typed string and reset the selected department
                                                                setSelectedProject({ project_title: e.value });
                                                                input.onChange("");
                                                            } else if (typeof e.value === "object" && e.value !== null) {
                                                                // Update the selected department and set the form state with the selected department's ID
                                                                setSelectedProject(e.value);
                                                                input.onChange(e.value.id);

                                                                form.change("subproject_id", undefined);
                                                                // Add this line to reset subproject_id in the form state
                                                            }
                                                        }}
                                                        id="project"
                                                        selectedItemTemplate={(value) => <div>{value ? value.project_title : "Select a Project"}</div>}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                    {getAllProjectsQuery.isLoading && getAllProjectsQuery.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            )}
                                        </Field>
                                        {/* <div style={{ marginBottom: "1rem" }}>
                                            <Field name="subproject_id">
                                                {({ input, meta }) => (
                                                    <div className="m-2" style={{ maxWidth: "300px" }}>
                                                        <label>Sub Project</label>
                                                        <Dropdown
                                                            value={input.value}
                                                            options={fetchAllSubProjects?.data?.data?.data.map((item) => ({
                                                                label: item.name,
                                                                value: item.id,
                                                            }))}
                                                            onChange={(e) => input.onChange(e.value)}
                                                            placeholder="Select Sub Project"
                                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                            disabled={fetchAllSubProjects.isLoading}
                                                        />
                                                        {fetchAllSubProjects.isLoading && fetchAllSubProjects.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                                    </div>
                                                )}
                                            </Field>
                                        </div> */}
                                        <Field name="subproject_id">
                                            {({ input, meta }) => (
                                                <div className="m-2" style={{ maxWidth: "300px" }}>
                                                    <label htmlFor="subproject_id">Sub Project</label>
                                                    <AutoComplete
                                                        value={selectedSubProject?.name || ""}
                                                        key={selectedSubProject?.name || "default-subproject-key"}
                                                        suggestions={filteredSubProjects}
                                                        disabled={getAllProjectsQuery.isLoading}
                                                        completeMethod={(e) => {
                                                            const results = fetchAllSubProjects.data?.data?.data.filter((project) => {
                                                                return project.name.toLowerCase().includes(e.query.toLowerCase());
                                                            });
                                                            setFilteredSubProjects(results);
                                                        }}
                                                        field="name"
                                                        dropdown={true}
                                                        onChange={(e) => {
                                                            if (typeof e.value === "string") {
                                                                // Update the display value to the typed string and reset the selected department
                                                                setSelectedSubProject({ name: e.value });
                                                                input.onChange("");
                                                            } else if (typeof e.value === "object" && e.value !== null) {
                                                                // Update the selected department and set the form state with the selected department's ID
                                                                setSelectedSubProject(e.value);
                                                                input.onChange(e.value.id);

                                                                // form.change("subproject_id", undefined);
                                                                // Add this line to reset subproject_id in the form state
                                                            }
                                                        }}
                                                        id="subproject_id"
                                                        selectedItemTemplate={(value) => <div>{value ? value.name : "Select a Subproject"}</div>}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                    {fetchAllSubProjects.isLoading && fetchAllSubProjects.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            )}
                                        </Field>
                                        <Field name="fyear_id">
                                            {({ input, meta }) => (
                                                <div className="m-2" style={{ maxWidth: "300px" }}>
                                                    <label htmlFor="financialYear">Financial Year</label>
                                                    <AutoComplete
                                                        value={selectedFinancialYear?.name || ""}
                                                        key={selectedFinancialYear?.name || "finacial-year-key"}
                                                        suggestions={filteredFinancialYears}
                                                        completeMethod={(e) => {
                                                            const results = getAllFinancialYearsQuery?.data?.data?.data.filter((year) => {
                                                                return year.name.toLowerCase().includes(e.query.toLowerCase());
                                                            });
                                                            setFilteredFinancialYears(results);
                                                        }}
                                                        field="name"
                                                        dropdown
                                                        onChange={(e) => {
                                                            if (typeof e.value === "string") {
                                                                setSelectedFinancialYear({ name: e.value });
                                                                input.onChange("");
                                                            } else if (typeof e.value === "object" && e.value !== null) {
                                                                setSelectedFinancialYear(e.value);
                                                                input.onChange(e.value.id);
                                                            }
                                                        }}
                                                        id="fyear_id"
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                </div>
                                            )}
                                        </Field>
                                        <Field name="quarter_id">
                                            {({ input, meta }) => (
                                                <div className="m-2" style={{ maxWidth: "300px" }}>
                                                    <label htmlFor="quarter">Quarter</label>
                                                    <AutoComplete
                                                        value={selectedQuarter?.name || ""}
                                                        key={selectedQuarter?.name || "quarter-key"}
                                                        suggestions={filteredQuarters}
                                                        completeMethod={(e) => {
                                                            const results = getAllQuartersQuery?.data?.data?.data.filter((quarter) => {
                                                                return quarter.name.toLowerCase().includes(e.query.toLowerCase());
                                                            });
                                                            setFilteredQuarters(results);
                                                        }}
                                                        field="name"
                                                        dropdown
                                                        onChange={(e) => {
                                                            if (typeof e.value === "string") {
                                                                setSelectedQuarter({ name: e.value });
                                                                input.onChange("");
                                                            } else if (typeof e.value === "object" && e.value !== null) {
                                                                setSelectedQuarter(e.value);
                                                                input.onChange(e.value.id);
                                                            }
                                                        }}
                                                        id="quarter_id"
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                </div>
                                            )}
                                        </Field>
                                    </>
                                )}

                                <div className="fluid m-4">
                                    <Button
                                        type="submit"
                                        label="Filter"
                                        disabled={filterToGetDeptAdDistrictsForMapMutation.isLoading || !selectedDepartment || (!selectedDepartmentAdministration && selectedDepartment?.code !== "03") || (!selectedUtility && selectedDepartment?.code == "03")}
                                        className="p-button-primary"
                                        icon="pi pi-check"
                                    />
                                </div>

                                {filterToGetDeptAdDistrictsForMapMutation.isLoading && (
                                    <center>
                                        <ProgressSpinner
                                            style={{
                                                width: "50px",
                                                height: "50px",
                                                borderWidth: "8px", // Border thickness
                                                borderColor: "blue", // Border color
                                                animationDuration: "1s",
                                            }}
                                            strokeWidth="8"
                                            animationDuration="1s"
                                        />
                                    </center>
                                )}
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    maximizable
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to Filter ?
                </Dialog>
            </div>
        </div>
    );
}

export default ProjectFilters;
