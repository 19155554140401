import React, { useRef } from "react";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Image } from "primereact/image";
import { Dialog } from "primereact/dialog";
import moment from "moment"; // Import moment
import { Tag } from "primereact/tag";

import AggregationPrint from "./AggregationPrint";
import { useReactToPrint } from "react-to-print";
import { Button } from "primereact/button";
import PrinterOutlined from "@mui/icons-material/PrintOutlined";
import { ProgressBar } from "primereact/progressbar";

function AggregationModal({ aggregatedData, ...props }) {
    console.log("selected item in aggregatedData: ", aggregatedData);

    const componentRef = useRef();
    const formatCurrency = (value) => {
        // Format your currency here
        return new Intl.NumberFormat("en-US", { style: "currency", currency: "UGX" }).format(value);
    };

    const imageBodyTemplate = (rowData) => {
        return <Image src={`${process.env.REACT_APP_API_BASE_URL}${rowData.photo_url}`} alt={rowData.caption} width="100" preview />;
    };

    // Function to format date using moment
    const formatDate = (dateString) => {
        return moment(dateString).format("MMMM Do YYYY, h:mm:ss a"); // Example format
    };

    // Function to display status with color
    const statusWithColor = (status) => {
        let color;
        switch (status) {
            case "pending approval":
                color = "blue";
                break;
            case "approved":
                color = "green";
                break;
            case "pending":
                color = "orange";
                break;
            case "rejected":
                color = "red";
                break;
            default:
                color = "grey";
        }
        return <span style={{ color }}>{status?.charAt(0)?.toUpperCase() + status?.slice(1)}</span>;
    };

    // Function to display status with color using Tag
    const tagStatus = (status) => {
        let severity;
        switch (status) {
            case "pending approval":
                severity = "info"; // blue
                break;
            case "approved":
                severity = "success"; // green
                break;
            case "pending":
                severity = "warning"; // orange
                break;
            case "rejected":
                severity = "danger"; // red
                break;
            default:
                severity = "secondary"; // grey or default
        }
        return <Tag severity={severity} value={status?.charAt(0)?.toUpperCase() + status?.slice(1)} />;
    };

    const gridStyle = {
        display: "grid",
        gridTemplateColumns: "1fr 1fr", // This creates two columns of equal width
        gridGap: "1rem", // Space between the columns
    };

    const riskTypeBodyTemplate = (rowData) => {
        let color;
        switch (rowData.risk_type) {
            case "high":
                color = "red";
                break;
            case "moderate":
                color = "orange";
                break;
            case "low":
                color = "grey";
                break;
            default:
                color = "black";
        }

        return (
            <div>
                <span className="p-mr-2" style={{ fontSize: "1.5rem", color, fontWeight: "bold" }}>
                    •
                </span>
                {rowData.risk_type.charAt(0).toUpperCase() + rowData.risk_type.slice(1)}
            </div>
        );
    };

    // const handlePrint = useReactToPrint({
    //     content: () => componentRef.current,
    //     documentTitle: `${(
    //         <div style={{ display: "flex", gap: "1rem", textAlign: "center", alignItems: "center", justifyContent: "center", margin: "20px 0" }}>
    //             <img src="/Coat_of_arms_of_Uganda.svg" alt="Coat of Arms of Uganda" style={{ width: "20px", height: "auto" }} /> <p>Ministry of Water and Environment</p>
    //         </div>
    //     )}`,
    //     pageStyle: `
    //     @page { size: A4; margin: 1cm; }
    //     @media print {
    //       body { -webkit-print-color-adjust: exact; }
    //       div#print-content { height: 100%; }
    //     }
    //   `,
    // });

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Report", // Set the document title as a string
        pageStyle: `
        @page { size: A4; margin: 1cm; }
        @media print {
          body { -webkit-print-color-adjust: exact; }
          div#print-content { height: 100%; }
          .header, .footer { background-color: transparent !important; } // Assuming .header and .footer are the classes for your header and footer
          img { display: block; margin: 0 auto; } // Center the image, if necessary
        }
      `,
    });
    // const handlePrint = useReactToPrint({
    //     content: () => componentRef.current,
    //     documentTitle: "Ministry of Water and Environment Performance Report", // This sets the PDF file name
    //     pageStyle: `
    //     @page {
    //         size: A4;
    //         margin: 1; // Set all page margins to 0
    //     }
    //     @media print {
    //         body { -webkit-print-color-adjust: exact; }
    //         div#print-content { height: 100%; }
    //         .header, .footer { display: none !important; } // Make sure .header and .footer elements do not display
    //         img { display: block; margin: 0 auto; } // Center the image, if necessary
    //         h4.class-name { /* Custom styles for h4 elements with class-name, if any */ }
    //     }
    // `,
    // });

    // const handlePrint = useReactToPrint({
    //     content: () => componentRef.current,
    //     documentTitle: "Ministry of Water and Environment Performance Report", // This sets the PDF file name
    //     pageStyle: `
    //     @page {
    //         size: A4;
    //         margin: 1cm; // Adjusted margin; you can also set individual margins (top, right, bottom, left) if needed
    //     }
    //     @media print {
    //         body, html {
    //             -webkit-print-color-adjust: exact;
    //             background: white;
    //             color: black;
    //         }
    //         div#print-content {
    //             height: 100%;
    //             background: white;
    //         }
    //         .header, .footer {
    //             display: none !important; // Make sure .header and .footer elements do not display
    //         }
    //         img {
    //             display: block;
    //             margin: 0 auto; // Center the image, if necessary
    //         }
    //     }
    // `,
    // });

    // const handlePrint = useReactToPrint({
    //     content: () => componentRef.current,
    //     documentTitle: "", // Set the default file name here
    //     pageStyle: `
    // @page {  size: A4; margin: 1cm; }
    // @media print {
    //   body { -webkit-print-color-adjust: exact; background: white; }
    //   div#print-content { height: 100%; background: white; }
    // }
    // `,
    //     onBeforeGetContent: () => {
    //         document.title = "Subproject Performance Report"; // Set the title here
    //     },
    //     onAfterPrint: () => {
    //         document.title = originalTitle; // Reset the title after printing
    //     },
    // });

    // Remember to store the original title to reset it later
    const originalTitle = document.title;

    return (
        <Dialog
            header={
                <div>
                    <div style={{ color: "green" }}>Aggregated Report.</div>

                    {/* <div>
                        <strong>Subproject Name : </strong>
                        {selectedItem?.subproject?.name}
                    </div> */}
                </div>
            }
            visible={props.show}
            style={{ width: "80vw" }}
            onHide={props.onHide}
            maximizable
        >
            {/* Status Information */}
            <Card title="Aggregated Report Details" style={{ marginBottom: "1rem" }}>
                <div className="flex flex-wrap" style={{ justifyContent: "space-between" }}>
                    {/* Displaying Department Details from the new structure */}
                    {aggregatedData.departments.map((department, index) => (
                        <div key={index} className="md:w-1/2 px-2 m-1">
                            <p>
                                <strong>Department Name:</strong> {department.name || "N/A"}
                            </p>
                            <p>
                                <strong>Department Details:</strong> {department.details || "N/A"}
                            </p>
                        </div>
                    ))}

                    {/* Displaying Financial Year and Quarters Details from the new structure */}
                    {aggregatedData.periods.map((period, index) => (
                        <div key={index} className="md:w-1/2 px-2 m-1">
                            <p>
                                <strong>Financial Year:</strong> {period.financial_year.name || "N/A"}
                            </p>
                            {period.quarters.map((quarter, qIndex) => (
                                <ul key={qIndex}>
                                    <li>{quarter.name}</li>
                                </ul>
                            ))}
                        </div>
                    ))}
                </div>
            </Card>

            <div>
                {aggregatedData?.outcomes.map((outcome, index) => (
                    <Card key={index} title={`Outcome: ${outcome.details.name}`} style={{ marginBottom: "1rem" }}>
                        <DataTable value={outcome.outcome_indicator_targets}>
                            <Column field="details.name" header="Outcome Indicator Name"></Column>
                            <Column
                                header="Total Value"
                                body={(rowData) => {
                                    const valueToShow = rowData?.details?.show_manual_value ? rowData?.target_summations?.total_uncomputed_value : rowData?.target_summations?.total_value;
                                    return `${parseInt(valueToShow)} ${rowData?.details.unit}`;
                                }}
                            />
                            {/* <Column field="target_summations.total_uncomputed_numerator" header="Total Uncomputed Numerator" body={(rowData) => `${rowData.target_summations.total_uncomputed_numerator} ${rowData.details.unit}`}></Column>
                            <Column field="target_summations.total_uncomputed_denominator" header="Total Uncomputed Denominator" body={(rowData) => `${rowData.target_summations.total_uncomputed_denominator} ${rowData.details.unit}`}></Column> */}
                            {/* Uncomment and adjust the following if assessments need to be displayed with units or additional formatting
                <Column
                    header="Assessments"
                    body={(rowData) => (
                        <>
                            {rowData.assessments.map((assessment, idx) => (
                                <div key={idx}>
                                    <div>Assessment: {assessment.uncomputed_assessment}</div>
                                    <div>Reason of Variance: {assessment.uncomputed_reason_of_variance}</div>
                                </div>
                            ))}
                        </>
                    )}
                ></Column>
                */}
                        </DataTable>
                    </Card>
                ))}
            </div>

            {/* Themes and Indicators */}
            <div>
                {aggregatedData?.indicator_groups?.map((group, groupIndex) => (
                    <Card key={group.utility_theme.id} title={group.utility_theme?.name || "N/A"}>
                        <DataTable value={group.utility_theme_report_indicators} responsiveLayout="scroll">
                            <Column field="output_indicator.name" header="Indicator Name"></Column>
                            <Column field="total_targets" header="Target"></Column>
                            <Column field="total_values" header="Value"></Column>
                            <Column header="Reason of Variance" body={(rowData) => rowData.assessments.map((assessment, index) => <div key={index}>{assessment.reasonOfVariance}</div>)}></Column>
                            <Column header="Assessment" body={(rowData) => rowData.assessments.map((assessment, index) => <div key={index}>{assessment.assessment}</div>)}></Column>
                        </DataTable>
                    </Card>
                ))}
            </div>

            {/* Risks and Mitigations */}
            {aggregatedData?.risks && (
                <Card title="Risks and Mitigations" style={{ marginBottom: "1rem" }}>
                    <DataTable value={aggregatedData.risks} responsiveLayout="scroll">
                        <Column field="risk" header="Risk"></Column>
                        <Column field="risk_type" header="Risk Type"></Column>
                        <Column field="mitigation" header="Mitigation"></Column>
                    </DataTable>
                </Card>
            )}

            {/* Created By and Updated By Information */}
            {/* <p>
                <strong>Created By:</strong> {selectedItem?.created_by_user?.name} at {formatDate(selectedItem?.created_at)}
            </p> */}
            {/* <p>
                <strong>Updated By:</strong> {selectedItem?.updated_by_user?.name} at {formatDate(selectedItem?.updated_at)}
            </p> */}

            {/* Print Button */}
            {/* {selectedItem?.current_status?.name == "approved" && ( */}

            <Button label="Print " icon={<PrinterOutlined />} onClick={handlePrint} className="p-button-primary" />
            {/* Invisible component for printing */}
            <div style={{ display: "none" }}>
                <div ref={componentRef}>
                    <AggregationPrint aggregatedData={aggregatedData} />
                </div>
            </div>

            {/* )} */}
        </Dialog>
    );
}

export default AggregationModal;
