import React, { useState, useEffect } from "react";
// import { Card } from "primereact/card";
import { Tree } from "primereact/tree";
import { Divider } from "primereact/divider";
import { useLocation } from "react-router-dom";
import { Panel } from "primereact/panel";
import BreadcrumbNav from "../../../../components/mweComponents/BreadcrumbNav";
import { Button } from "primereact/button";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { Fieldset } from "primereact/fieldset";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { TabView, TabPanel } from "primereact/tabview";
// import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primereact/resources/themes/lara-light-blue/theme.css";
// import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

import { getAllDepartmentOutcomeIndicators, getDepartmentOutcomeIndicatorById, postDepartmentOutcomeIndicator, updateDepartmentOutcomeIndicator, deleteDepartmentOutcomeIndicatorById } from "../../../../services/departments/departmentOutcomeIndicator-service.js";
import { getAlldepartmentOutcomes, getdepartmentOutcomesById, postdepartmentOutcomes, updatedepartmentOutcomes, deletedepartmentOutcomesById } from "../../../../services/departments/departmentOutcome-service.js";

import DepartmentOutcomeIndicatorsPage from "./department-outcome-indicators/DepartmentOutcomeIndicatorsPage";

const DepartmentOutcomesViewPage = ({ loggedInUserData }) => {
    const queryClient = useQueryClient();
    //
    let { state } = useLocation();
    let selectedDepartmentOutcomeData = state ? state?.selectedDepartmentOutcomeData : null;
    console.log("selectedDepartmentOutcomeData data got from state is : ", selectedDepartmentOutcomeData);

    //===================== getDepartmentById by id =================

    const fetchDepartmentOutcomeIndicatorById = useQuery(["departments", "department-outcomes", "outcomeById", selectedDepartmentOutcomeData?.id], () => getdepartmentOutcomesById(selectedDepartmentOutcomeData?.id), {
        onSuccess: (data) => {
            console.log("getDepartmentOutcomeIndicator onsuccess fetching : ", data);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
            console.log("get list of DepartmentOutcomeIndicator : ", error);
        },
    });

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showBudjetOutPutAddForm, setShowBudjetOutPutAddForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    return (
        <div style={{ width: "100%" }}>
            <BreadcrumbNav />
            <div className="projects-view-page">
                <Panel
                    header={
                        <span>
                            Department : {selectedDepartmentOutcomeData?.department?.name}
                            <br />
                            Outcome : {selectedDepartmentOutcomeData?.outcome?.name}
                        </span>
                    }
                    style={{ marginBottom: "20px" }}
                >
                    <TabView>
                        <TabPanel header="Details">
                            <div className="flex flex-wrap">
                                {/* Column 1 */}
                                <div className="w-full md:w-1/2 px-2 mb-4">
                                    <p>
                                        <strong>Outcome Name:</strong> {selectedDepartmentOutcomeData?.outcome?.name}
                                    </p>
                                </div>

                                {/* Column 2 */}
                                <div className="w-full md:w-1/2 px-2 mb-4">
                                    <p>
                                        <strong>Outcome Definition:</strong> {selectedDepartmentOutcomeData?.outcome?.definition}
                                    </p>

                                    {/* <p>
                                        <strong>Outcome Status:</strong> {selectedDepartmentOutcomeData?.outcome?.status}
                                    </p> */}

                                    {/* <p>
                                        <strong>Updated By:</strong> {selectedDepartmentOutcomeData?.updated_by?.email || "N/A"}
                                    </p> */}
                                </div>
                            </div>

                            <Divider />
                        </TabPanel>

                        {/* Out Put indicator was previously called metrics */}
                        <TabPanel header="Outcome Indicators">
                            <DepartmentOutcomeIndicatorsPage
                                loggedInUserData={loggedInUserData}
                                selectedDepartmentOutcomeItem={selectedDepartmentOutcomeData || fetchDepartmentOutcomeIndicatorById?.data?.data?.data}
                                departmentOutcomeId={selectedDepartmentOutcomeData?.id || fetchDepartmentOutcomeIndicatorById?.data?.data?.data?.id}
                            />
                        </TabPanel>

                        {/* <TabPanel header="Directorate Reports"></TabPanel> */}
                    </TabView>
                </Panel>
            </div>
        </div>
    );
};

export default DepartmentOutcomesViewPage;

// Sample usage:
// <ProjectsViewPage project={{
//     name: 'Urban Development Project',
//     code: 'UD123',
//     department: 'Urban Planning',
//     type: 'Development',
//     responsibleOfficer: 'John Doe',
//     details: 'This project focuses on...',
//     districts: [{ id: '1', name: 'District 1' }, { id: '2', name: 'District 2' }]
// }} />
