import axiosAPI from "../axiosApi";

export async function getAllDepartmentIndicators(params = {}) {
    const response = await axiosAPI.get("departments-indicators", { params: params });
    return response;
}

export async function getDepartmentIndicatorById(id) {
    const response = await axiosAPI.get(`departments-indicators/${id}`);
    return response;
}

export async function postDepartmentIndicator(data) {
    const response = await axiosAPI.post(`departments-indicators`, data);
    return response;
}

export async function updateDepartmentIndicator(id, data) {
    const response = await axiosAPI.put(`departments-indicators/${id}`, data);
    return response;
}

export async function deleteDepartmentIndicatorById(id) {
    const response = await axiosAPI.delete(`departments-indicators/${id}`);
    return response;
}
