import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import DepartmentsCreateForm from "./DepartmentsCreateForm";

import DepartmentsEditForm from "./DepartmentsEditForm";

import { deleteDepartmentById, getAllDepartments, getDepartmentByProgramId } from "../../../services/departments/department-service";

import { getProgramById } from "../../../services/programs/program-service";
import { Panel } from "primereact/panel";

import moment from "moment";

import { Link } from "react-router-dom";

import WaterIsLoading from "../../../components/mweComponents/WaterIsLoading";
import MuiTable from "../../../components/mweComponents/MuiTable";
import { toast } from "react-toastify";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";

import { TabView, TabPanel } from "primereact/tabview";

import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

function DepartmentList({ selectedDirectorateItem, ...props }) {
    console.log("selectedDirectorateItem sdjnkdja : ", selectedDirectorateItem);
    const navigate = useNavigate();
    // let previouslinks = props?.oldlinks.names;
    let prevPage = props?.prevPage;
    const directorateId = props.directorateId;
    console.log("directorateId is : ", directorateId);
    const [departmentData, setDepartmentData] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const queryClient = useQueryClient();

    // get current userdepartment
    const user = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;

    const currentUserDepartment = user.department;

    // console.log("curent user ddd is : ", user);

    // ======== start getting all departments =========

    // const {
    //     data,
    //     isError,
    //     error,
    //     status,
    //     isLoading: departmentByIdLoading,
    // } = useQuery(["departments", programId], () => getDepartmentByProgramId(programId), {
    //     enabled: programId ? true : false,
    //     onSuccess: () => {
    //         setIsLoading(false);
    //     },
    // });
    // console.log(data);
    // isError && toast.error("There was an error while fetching data");

    const fetchAllDepartments = useQuery(["departments", directorateId], () => (!directorateId ? getAllDepartments() : getAllDepartments({ directorate_id: directorateId })), {
        onSuccess: (data) => {
            setDepartmentData(data.data);
            setIsLoading(false);
            console.log("dfdsvjkv fetchAllDepartments :", data);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
            console.log("get list of departments : ", error);
        },
    });

    // console.log("departments by program id data is : ", data);
    // isError && toast.error("There was an error while fetching data");

    // ======== end getting all departments =========

    const departmentsDeleteMutation = useMutation((variables) => deleteDepartmentById(variables), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(["departments", directorateId]);
            queryClient.invalidateQueries(["programs", "programs-hireachy"]);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
            console.log("error delete list of departments : ", error);
        },
    });

    // const handleDelete = async (event, id) => {
    //     var result = window.confirm("Are you sure you want to delete?");
    //     if (result === true) {
    //         departmentsDeleteMutation.mutate(id);
    //     }
    // };

    const handleDelete = (event, id) => {
        let deleteDeprtmentsId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(deleteDeprtmentsId),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (deleteDeprtmentsId) => {
        if (deleteDeprtmentsId !== null) {
            departmentsDeleteMutation.mutate(deleteDeprtmentsId);
        }
    };

    const cancelDelete = () => {
        // setDeleteDepartmentsId(null);
    };

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;

    const onFormClose = () => {
        setShowAddForm(false);
    };
    let tableId = 0;
    const columns = [
        {
            title: "#",
            field: "id",
            width: "5%",
            hidden: true,
        },
        {
            title: "Name",
            cellStyle: {
                whiteSpace: "nowrap",
                padding: "8px",
            },
            field: "name",
            // render: (rowData) => {
            //     return (
            //         <Link
            //             // to={"/programs/" + item.id}
            //             to="projects"
            //             // linkProgramId
            //             // state={
            //             //     programId
            //             //         ? {
            //             //               linkProgramId: programId,
            //             //               linkDepartmentId: rowData.id,
            //             //               name: rowData.name,
            //             //           }
            //             //         : {
            //             //               id: rowData.id,
            //             //               name: rowData.name,
            //             //               linkDepartmentId: rowData.id,
            //             //           }
            //             // }
            //             className="text-decoration-none"
            //         >
            //             {rowData.name}
            //         </Link>
            //     );
            // },
        },
        // {
        //     title: "Directorate",
        //     field: "program_directorate_department.program_directorate.directorate.name",
        // },
        // {
        //     title: "Program",
        //     field: "program_directorate_department.program_directorate.program.name",
        // },
        {
            title: "Code",
            hidden: true,
            field: "code",
        },
        {
            title: "Date",
            hidden: true,
            field: "created_at",
            render: (rowData) => {
                return moment(rowData.created_at).format("lll");
            },
        },
    ];

    console.log("props.loggedInUserData fgsdfvdsgdfgsdads : ", props.loggedInUserData);

    return (
        <div style={{ width: "100%" }}>
            {/* <TabView>
                <TabPanel header="Departments"> */}{" "}
            <Panel header="Departments" style={{ marginBottom: "20px" }}>
                <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                    {props.loggedInUserData?.permissions?.includes("add departments") && (
                        <>
                            <Button onClick={() => setShowAddForm(true)}>Add Department</Button>

                            <DepartmentsCreateForm selectedDirectorateItem={selectedDirectorateItem} directorateId={directorateId} show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} />
                        </>
                    )}
                </div>

                <MuiTable
                    tableTitle={prevPage ? `Departments under ${prevPage}` : "Departments"}
                    tableData={fetchAllDepartments?.data?.data?.data}
                    tableColumns={columns}
                    handleShowEditForm={handleShowEditForm}
                    handleDelete={(e, item_id) => handleDelete(e, item_id)}
                    showEdit={props.loggedInUserData?.permissions?.includes("edit departments")}
                    showDelete={props.loggedInUserData?.permissions?.includes("delete departments")}
                    // handleViewPage={(rowData) => {
                    //     navigate("/departments/view", { state: { departmentData: rowData } });
                    // }}
                    handleViewPage={(rowData) => {
                        navigate("department", { state: { departmentData: rowData } });
                    }}
                    showViewPage={true}
                    hideRowViewPage={false}
                    // hideRowEdit={(rowData) => (props.loggedInUserData?.permissions?.includes("edit departments") && props.loggedInUserData?.department_id == rowData.id ? false : true)}
                    // hideRowDelete={(rowData) => (props.loggedInUserData?.permissions?.includes("edit departments") && props.loggedInUserData?.department_id == rowData.id ? false : true)}
                    hideRowEdit={(rowData) => (props.loggedInUserData?.permissions?.includes("edit departments") ? false : true)}
                    hideRowDelete={(rowData) => (props.loggedInUserData?.permissions?.includes("edit departments") ? false : true)}
                    loading={fetchAllDepartments.status == "loading" || fetchAllDepartments.isLoading}
                    exportButton={true}
                    pdfExportTitle="Departments"
                    csvExportTitle="Departments"
                />
                {selectedItem && <DepartmentsEditForm departmentdata={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} directorateId={directorateId} />}
                {/* {loading && <WaterIsLoading />} */}
                {/* <ConfirmDialog /> */}
            </Panel>
            {/* </TabPanel> */}
            {/* <TabPanel header="Department Indicators">
                    <DepartmentIndicatorsPage />
                </TabPanel>
                <TabPanel header="Budget Outputs">
                    <DepartmentBudgetOutPutsPage />
                </TabPanel> */}
            {/* <TabPanel header="Department Reports"></TabPanel> */}
            {/* </TabView> */}
        </div>
    );
}

export default DepartmentList;
