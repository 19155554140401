import axiosAPI from "../axiosApi";

export async function getAllProjectOutputIndicatorAnnualTargets(params = {}) {
    const response = await axiosAPI.get("pjtOutputIndicatorAnnualTargets", { params: params });
    return response;
}

export async function getProjectOutputIndicatorAnnualTargetById(id) {
    const response = await axiosAPI.get(`pjtOutputIndicatorAnnualTargets/${id}`);
    return response;
}

export async function postProjectOutputIndicatorAnnualTarget(data) {
    const response = await axiosAPI.post(`pjtOutputIndicatorAnnualTargets`, data);
    return response;
}

export async function updateProjectOutputIndicatorAnnualTarget(id, data) {
    const response = await axiosAPI.put(`pjtOutputIndicatorAnnualTargets/${id}`, data);
    return response;
}

export async function deleteProjectOutputIndicatorAnnualTargetById(id) {
    const response = await axiosAPI.delete(`pjtOutputIndicatorAnnualTargets/${id}`);
    return response;
}
