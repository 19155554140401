import React from "react";
import moment from "moment";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Image } from "primereact/image";
import { Dialog } from "primereact/dialog";
import { ProgressBar } from "primereact/progressbar";
import { Tag } from "primereact/tag";

function UtilityDeptReportPrint({ selectedItem, selectedDepartmentItem, ...props }) {
    // Helper function to format currency
    const formatCurrency = (value) => {
        return new Intl.NumberFormat("en-US", { style: "currency", currency: "UGX" }).format(value);
    };

    // Helper function to format date
    const formatDate = (dateString) => {
        return moment(dateString).format("MMMM Do YYYY, h:mm:ss a");
    };

    // Inline styles
    const tableHeaderStyle = {
        backgroundColor: "#00B9E8",
        color: "white",
        padding: "10px",
        textAlign: "left",
    };

    const tableCellStyle = {
        padding: "10px",
    };

    const cardStyle = {
        marginBottom: "20px",
        padding: "15px",
        border: "1px solid #ddd",
        borderRadius: "5px",
    };

    const cardTitleStyle = {
        color: "#00B9E8",
        fontWeight: "bold",
        marginBottom: "10px",
    };

    // Inline styles
    const tableStyle = {
        width: "100%",
        marginBottom: "20px",
        borderCollapse: "collapse",
    };

    const thStyle = {
        backgroundColor: "#00B9E8",
        color: "white",
        padding: "10px",
        textAlign: "left",
        border: "1px solid #ddd",
    };

    const tdStyle = {
        padding: "10px",
        border: "1px solid #ddd",
    };

    const gridStyle = {
        display: "grid",
        gridTemplateColumns: "1fr 1fr", // This creates two columns of equal width
        gridGap: "1rem", // Space between the columns
    };

    // Function to display status with color using Tag
    const tagStatus = (status) => {
        let severity;
        switch (status) {
            case "pending approval":
                severity = "info"; // blue
                break;
            case "approved":
                severity = "success"; // green
                break;
            case "pending":
                severity = "warning"; // orange
                break;
            case "rejected":
                severity = "danger"; // red
                break;
            default:
                severity = "secondary"; // grey or default
        }
        return <Tag severity={severity} value={status?.charAt(0)?.toUpperCase() + status?.slice(1)} />;
    };

    // Function to display status with color
    const statusWithColor = (status) => {
        let color;
        switch (status) {
            case "pending approval":
                color = "blue";
                break;
            case "approved":
                color = "green";
                break;
            case "pending":
                color = "orange";
                break;
            case "rejected":
                color = "red";
                break;
            default:
                color = "grey";
        }
        return <span style={{ color }}>{status?.charAt(0)?.toUpperCase() + status?.slice(1)}</span>;
    };

    const riskTypeBodyTemplate = (rowData) => {
        let color;
        switch (rowData.risk_type) {
            case "high":
                color = "red";
                break;
            case "moderate":
                color = "orange";
                break;
            case "low":
                color = "grey";
                break;
            default:
                color = "black";
        }

        return (
            <div>
                <span className="p-mr-2" style={{ fontSize: "1.5rem", color, fontWeight: "bold" }}>
                    •
                </span>
                {rowData.risk_type.charAt(0).toUpperCase() + rowData.risk_type.slice(1)}
            </div>
        );
    };
    return (
        <div style={{ backgroundColor: "white" }}>
            {/* Ministry Logo */}
            <div style={{ display: "flex", gap: "1rem", textAlign: "center", alignItems: "center", justifyContent: "center", margin: "1rem", width: "100%" }}>
                <img src="/images/coatOfArms.png" alt="Coat of Arms of Uganda" style={{ width: "20px", height: "auto" }} />{" "}
                <span className="font-medium ml-2">
                    <span>Ministry of Water and Environment</span> <br /> <span>M & E Portal Water Utility Regulation Department Report</span>
                </span>
            </div>

            {/* Budget Details */}
            <div style={cardStyle}>
                <div style={cardTitleStyle}>Report Details</div>
                <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "1rem" }}>
                    <div className="flex flex-wrap" style={{ justifyContent: "space-between", width: "100%" }}>
                        {/* Column 1 */}
                        <div style={{ width: "50%", padding: "0.5rem" }}>
                            <p>
                                <strong>Department Name:</strong> {selectedItem?.department?.name || "N/A"}
                            </p>
                            <p>
                                <strong>Report Name:</strong> {selectedItem?.name || "N/A"}
                            </p>
                            <p>
                                <strong>Approval Status:</strong> {tagStatus(selectedItem?.current_status?.name)}
                            </p>
                            <p>
                                <strong>Approval Comment:</strong> {statusWithColor(selectedItem?.current_status?.reason)}
                            </p>
                        </div>

                        {/* Column 2 */}
                        <div style={{ width: "50%", padding: "0.5rem" }}>
                            <p>
                                <strong>Financial Year:</strong> {selectedItem?.financial_year?.name}
                            </p>
                            <p>
                                <strong>Quarter:</strong> {selectedItem?.quarter?.name}
                            </p>
                        </div>

                        {/* Full Width Row for Description */}
                        <div style={{ width: "100%", padding: "0.5rem" }}>
                            <strong>Description:</strong>
                            <p>{selectedItem?.description}</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Department Outcomes */}
            {selectedItem?.utility_theme_report_department_outcomes.map((outcome, index) => (
                <div key={index} style={cardStyle}>
                    <div style={cardTitleStyle}>Department Outcome: {outcome.department_outcome.outcome.name}</div>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={thStyle}>Outcome Indicator Name</th>
                                {/* <th style={thStyle}>Outcome Indicator Code</th> */}
                                <th style={thStyle}>Base Year</th>
                                <th style={thStyle}>Base Value</th>
                                <th style={thStyle}>Target Year</th>
                                <th style={thStyle}>Target Value</th>
                                <th style={thStyle}>Financial Year</th>
                                <th style={thStyle}>Financial Year Target</th>
                                <th style={thStyle}>Value</th>
                                {/* <th style={thStyle}>Census</th> */}
                                {/* Additional headers as needed */}
                            </tr>
                        </thead>
                        <tbody>
                            {outcome?.utility_theme_reportindicator_target_actuals.map((indicator, idx) => {
                                let unit = indicator.dept_outcomes_indicator_target.department_outcome_indicator.outcome_indicator.unit;
                                return (
                                    <tr key={idx}>
                                        <td style={tdStyle}>{indicator.dept_outcomes_indicator_target.department_outcome_indicator.outcome_indicator.name}</td>
                                        {/* <td style={tdStyle}>{indicator.dept_outcomes_indicator_target.department_outcome_indicator.outcome_indicator.code}</td> */}
                                        <td style={tdStyle}>{indicator.dept_outcomes_indicator_target.base_year}</td>
                                        <td style={tdStyle}>{`${parseInt(indicator.dept_outcomes_indicator_target.base_value) || 0} ${unit}`}</td>
                                        <td style={tdStyle}>{indicator.dept_outcomes_indicator_target.target_year}</td>
                                        <td style={tdStyle}>{`${parseInt(indicator.dept_outcomes_indicator_target.target_value) || 0} ${unit}`}</td>
                                        <td style={tdStyle}>{indicator.dept_outcomes_indicator_target.financial_year.name}</td>
                                        <td style={tdStyle}>{`${parseInt(indicator.dept_outcomes_indicator_target.financial_year_target) || 0} ${unit}`}</td>
                                        {/* <td style={tdStyle}>{`${parseInt(indicator.value) || 0}${unit}`}</td> */}
                                        <td style={tdStyle}>
                                            {(() => {
                                                // Initialize a variable to store the final value
                                                let finalValue;

                                                // Check if manual value should be used
                                                if (indicator?.dept_outcomes_indicator_target?.show_manual_value) {
                                                    // Use the manual value and store in finalValue
                                                    finalValue = parseInt(indicator?.uncomputed_value) || 0;
                                                } else {
                                                    // Otherwise, use the target value and store in finalValue
                                                    finalValue = parseInt(indicator.value) || 0;
                                                }

                                                // Return the finalValue with a percentage sign
                                                return `${finalValue} ${unit}`;
                                            })()}
                                        </td>
                                        {/* <td style={tdStyle}>{indicator.census_name}</td> */}
                                        {/* Additional cells as needed */}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            ))}

            {/* Themes and Indicators using divs */}
            {/* Themes and Indicators */}
            {selectedItem?.utility_theme_reports_themes?.map((theme, themeIndex) => (
                <div key={themeIndex} style={cardStyle}>
                    <div style={cardTitleStyle}>Theme: {theme.utility_theme?.name || "N/A"}</div>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={thStyle}>Indicator</th>
                                <th style={thStyle}>Target</th>
                                <th style={thStyle}>Value</th>
                                <th style={thStyle}>Assessment</th>
                                <th style={thStyle}>Reason of Variance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {theme.utility_theme_report_indicators?.map((indicator, indicatorIndex) => (
                                <tr key={indicatorIndex}>
                                    <td style={tdStyle}>{indicator.utility_theme_indicator_target.utility_theme_indicator.output_indicator.name || "N/A"}</td>
                                    <td style={tdStyle}>{indicator.utility_theme_indicator_target?.target || "N/A"}</td>
                                    <td style={tdStyle}>{indicator.value || "N/A"}</td>
                                    <td style={tdStyle}>{indicator.assessment || "N/A"}</td>
                                    <td style={tdStyle}>{indicator.reasonOfVariance || "N/A"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ))}

            {/* Risks and Mitigations */}
            {selectedItem?.risks && selectedItem.risks.length > 0 && (
                <div style={cardStyle}>
                    <div style={cardTitleStyle}>Risks and Mitigations</div>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={thStyle}>Risk</th>
                                <th style={thStyle}>Risk Type</th>
                                <th style={thStyle}>Mitigation</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedItem.risks.map((risk, riskIndex) => (
                                <tr key={riskIndex}>
                                    <td style={tdStyle}>{risk.risk}</td>
                                    <td style={tdStyle}>{risk.risk_type}</td>
                                    <td style={tdStyle}>{risk.mitigation}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {/* Created By and Updated By Information using divs */}
            <div style={cardStyle}>
                <p>
                    <strong>Created By:</strong> {selectedItem?.created_by?.name} at {formatDate(selectedItem?.created_at)}
                </p>
                <p>
                    <strong>Updated By:</strong> {selectedItem?.updated_by?.name} at {formatDate(selectedItem?.updated_at)}
                </p>
            </div>
        </div>
    );
}

export default UtilityDeptReportPrint;
