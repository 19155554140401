import React, { useState, useRef, useEffect } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RecommendationsComponent from "./report-components/recomemdations/Recommendations";
import RiskMitigationComponent from "./report-components/risks/RiskMitigation";
import { toast as reactToastifyToast } from "react-toastify";
import { ProgressSpinner } from "primereact/progressspinner";
import i from "icepick";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

//
import { getProjectDeptOutcomeIndicatorsData } from "../../../../../../../services/projects/project-service";

//
import { FileUpload } from "primereact/fileupload";
import { Image } from "primereact/image";
import { Toast } from "primereact/toast";
import BudgetHistory from "./BudgetHistory";
import PhotosHistory from "./PhotosHistory";
import { getAllProjectReports, getProjectReportById, postProjectReport, updateProjectReport, deleteProjectReportById } from "../../../../../../../services/projects/project-reports-service.js";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
let handleProjectReasonOfVarianceChangeCache = {};
let handleTotalValueChangeCache = {};
let handleProjectAssessmentChangeCache = {};

const ProjectReportForm = ({ subProjectReports, selectedprojectItem }) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    console.log("subProjectReports hdhdhdhd : ", subProjectReports);
    console.log("selectedprojectItem creating project report hdhdhdhd : ", selectedprojectItem);
    const [fieldDataIds, setFieldDataIds] = useState([]);
    const [budgetOutputs, setBudgetOutputs] = useState([]);
    const [totalApprovedBudget, setTotalApprovedBudget] = useState(0);
    const [details, setDetails] = useState();
    const [totalSpentFunds, setTotalSpentFunds] = useState(0);
    const [absorptionRate, setAbsorptionRate] = useState(0);
    const [errors, setErrors] = useState({});
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [finacialYear, setFinacialYear] = useState(subProjectReports?.length > 0 ? subProjectReports[0]?.financial_year : null);
    const [quarter, setQuarter] = useState(subProjectReports?.length > 0 ? subProjectReports[0]?.quarter : null);
    const [groupedBudgetOutputs, setGroupedBudgetOutputs] = useState([]);
    const [risks, setRisks] = useState([]);
    const [recommendations, setRecommendations] = useState([]);
    const [deptOutcomeIndicatorTargets, setDeptOutcomeIndicatorTargets] = useState([]);

    //
    const [imageCaptions, setImageCaptions] = useState({});
    console.log("imageCaptions fgdfd : ", imageCaptions);
    console.log("errors jajsjas : ", errors);

    console.log("groupedBudgetOutputs llll : ", groupedBudgetOutputs);

    // get project department outcome indicators
    // const getProjectDeptOutcomeIndicatorsDataMutation = useMutation(getProjectDeptOutcomeIndicatorsData, {
    //     onSuccess: (data) => {
    //         //   queryClient.invalidateQueries(["projects"]);
    //         //   toast.success("Project Created Successfully");
    //         console.log("getProjectDeptOutcomeIndicatorsDataMutation data : ", data);
    //         setDeptOutcomeIndicatorTargets(data?.data?.data);
    //     },
    //     onError: (error) => {
    //         error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
    //     },
    // });

    // useEffect(() => {
    //     if (selectedprojectItem?.id && subProjectReports[0]?.financial_year) {
    //         getProjectDeptOutcomeIndicatorsDataMutation.mutate({ project_id: selectedprojectItem?.id, fyear_id: subProjectReports[0]?.financial_year });
    //     }
    // }, []);

    // Define the query outside of the useEffect
    const { data, error, isLoading } = useQuery(
        ["deptOutcomeIndicatorTargets", selectedprojectItem?.id, subProjectReports[0]?.financial_year],
        () =>
            getProjectDeptOutcomeIndicatorsData({
                project_id: selectedprojectItem?.id,
                fyear_id: subProjectReports[0]?.financial_year,
            }),
        {
            onSuccess: (data) => {
                // You can still handle success here if needed
                console.log("getProjectDeptOutcomeIndicatorsData Data fetched successfully: ", data);
                // setDeptOutcomeIndicatorTargets(data?.data?.data);
                // Only update if deptOutcomeIndicatorTargets is an empty array
                setDeptOutcomeIndicatorTargets((currentTargets) => {
                    if (Array.isArray(currentTargets) && currentTargets.length === 0) {
                        return data?.data?.data; // Assume data?.data?.data is the new targets array
                    }
                    return currentTargets; // Otherwise, return the existing state without change
                });
            },
            onError: (error) => {
                // Error handling
                console.error("Error fetching data: ", error);
            },
            enabled: !!selectedprojectItem?.id && !!subProjectReports[0]?.financial_year, // This ensures the query only runs when these conditions are met
        }
    );

    // useEffect(() => {
    //     let totalApproved = 0;
    //     let totalSpent = 0;
    //     let ids = [];

    //     let budgetOutputGroups = {};

    //     subProjectReports.forEach((report) => {
    //         totalApproved += parseInt(report.approved_budget, 10);
    //         totalSpent += parseInt(report.spent_funds, 10);
    //         ids.push(report.subproject_field_data_id);

    //         report.budget_outputs.forEach((output) => {
    //             const projectDeptBudgetOutputId = output.subproject_budget_output.project_department_budget_outputs_id;

    //             if (!budgetOutputGroups[projectDeptBudgetOutputId]) {
    //                 budgetOutputGroups[projectDeptBudgetOutputId] = {
    //                     projectdepartmentBudgetOutput: output.subproject_budget_output.project_department_budget_output,
    //                     indicatorsGroups: {},
    //                 };
    //             }

    //             output.indicators.forEach((indicator) => {
    //                 const indicatorGroupId = indicator.subproject_bgt_output_indicator_target.pjt_dept_bdgt_output_indicator_targets_id;

    //                 if (!budgetOutputGroups[projectDeptBudgetOutputId].indicatorsGroups[indicatorGroupId]) {
    //                     budgetOutputGroups[projectDeptBudgetOutputId].indicatorsGroups[indicatorGroupId] = {
    //                         subprojectBgtOutputIndicatorTarget: indicator.subproject_bgt_output_indicator_target,
    //                         totalValue: 0,
    //                         indicators: [],
    //                     };
    //                 }

    //                 budgetOutputGroups[projectDeptBudgetOutputId].indicatorsGroups[indicatorGroupId].indicators.push(indicator);
    //                 budgetOutputGroups[projectDeptBudgetOutputId].indicatorsGroups[indicatorGroupId].totalValue += parseInt(indicator.value, 10);
    //             });
    //         });
    //     });

    //     setFieldDataIds([...new Set(ids)]);
    //     setTotalApprovedBudget(totalApproved);
    //     setTotalSpentFunds(totalSpent);
    //     setAbsorptionRate((totalSpent / totalApproved) * 100);

    //     // Convert grouped data to array, and also convert each indicatorsGroups to array
    //     setGroupedBudgetOutputs(
    //         Object.values(budgetOutputGroups).map((group) => ({
    //             ...group,
    //             indicatorsGroups: Object.values(group.indicatorsGroups),
    //         }))
    //     );
    // }, [subProjectReports]);

    useEffect(() => {
        let totalApproved = 0;
        let totalSpent = 0;
        let ids = [];

        let budgetOutputGroups = {};
        // Check if subProjectReports is not set or not an array
        if (!subProjectReports || !Array.isArray(subProjectReports)) {
            navigate("/"); // Navigate to the desired path
        } else {
            subProjectReports.forEach((report) => {
                totalApproved += parseInt(report.approved_budget, 10);
                totalSpent += parseInt(report.spent_funds, 10);
                ids.push(report.id);

                report.budget_outputs.forEach((output) => {
                    const projectDeptBudgetOutputId = output.subproject_budget_output.project_department_budget_outputs_id;

                    if (!budgetOutputGroups[projectDeptBudgetOutputId]) {
                        budgetOutputGroups[projectDeptBudgetOutputId] = {
                            projectdepartmentBudgetOutput: output.subproject_budget_output.project_department_budget_output,
                            indicatorsGroups: {},
                        };
                    }

                    output.indicators.forEach((indicator) => {
                        const indicatorGroupId = indicator.subproject_bgt_output_indicator_target.pjt_dept_bdgt_output_indicator_targets_id;
                        const isQuantitative = indicator.subproject_bgt_output_indicator_target.project_dept_bgt_output_indicator_target.department_budget_output_indicator.output_indicator.measure?.toLowerCase() == "quantitative";

                        if (!budgetOutputGroups[projectDeptBudgetOutputId].indicatorsGroups[indicatorGroupId]) {
                            budgetOutputGroups[projectDeptBudgetOutputId].indicatorsGroups[indicatorGroupId] = {
                                subprojectBgtOutputIndicatorTarget: indicator.subproject_bgt_output_indicator_target,
                                totalValue: isQuantitative ? 0 : "",
                                indicators: [],
                            };
                        }

                        if (isQuantitative) {
                            budgetOutputGroups[projectDeptBudgetOutputId].indicatorsGroups[indicatorGroupId].totalValue += parseInt(indicator.value, 10);
                        } else {
                            budgetOutputGroups[projectDeptBudgetOutputId].indicatorsGroups[indicatorGroupId].totalValue += (budgetOutputGroups[projectDeptBudgetOutputId].indicatorsGroups[indicatorGroupId].totalValue ? ", " : "") + indicator.value;
                        }
                        budgetOutputGroups[projectDeptBudgetOutputId].indicatorsGroups[indicatorGroupId].indicators.push(indicator);
                    });
                });
            });

            setFieldDataIds(ids);
            setTotalApprovedBudget(totalApproved);
            setTotalSpentFunds(totalSpent);
            setAbsorptionRate((totalSpent / totalApproved) * 100);

            // Convert grouped data to array, and also convert each indicatorsGroups to array
            setGroupedBudgetOutputs(
                Object.values(budgetOutputGroups).map((group) => ({
                    ...group,
                    indicatorsGroups: Object.values(group.indicatorsGroups),
                }))
            );
        }
    }, [subProjectReports]);

    const validateForm = () => {
        let newErrors = {};

        // Validate totalApprovedBudget and totalSpentFunds
        if (totalApprovedBudget <= 0) {
            newErrors.totalApprovedBudget = "Total Released Funds must be greater than zero";
        }

        if (totalSpentFunds < 0) {
            newErrors.totalSpentFunds = "Total spent funds cannot be negative";
        }

        if (!details) newErrors.details = "Details are required";

        // Validate groupedBudgetOutputs
        groupedBudgetOutputs.forEach((output, outputIndex) => {
            output.indicatorsGroups.forEach((group, groupIndex) => {
                // Validate totalValue based on indicator type
                // const indicatorType = group.subprojectBgtOutputIndicatorTarget.project_dept_bgt_output_indicator_target.department_budget_output_indicator.output_indicator.measure?.toLowerCase();
                // const isQuantitative = indicatorType === "quantitative";
                // if (isQuantitative) {
                //     if (!group.totalValue || group.totalValue < 0) {
                //         newErrors[`output_${outputIndex}_group_${groupIndex}_totalValue`] = "Total value must be non-negative";
                //     }
                // } else {
                //     if (group.totalValue === undefined || group.totalValue === null || group.totalValue.trim() === "") {
                //         newErrors[`output_${outputIndex}_group_${groupIndex}_totalValue`] = "Total value is required";
                //     }
                // }

                // if (!group.totalValue) {
                //     newErrors[`output_${outputIndex}_group_${groupIndex}_totalValue`] = "A value is required";
                // }
                // new way
                if (group.totalValue === null || group.totalValue === undefined || group.totalValue === "") {
                    newErrors[`output_${outputIndex}_group_${groupIndex}_totalValue`] = "A value is required";
                }

                // Validate reasonOfVariance only if it's applicable
                const targetString = (group.subprojectBgtOutputIndicatorTarget.project_dept_bgt_output_indicator_target.target || "").toString().toLowerCase();
                const totalValueString = (group.totalValue || "").toString().toLowerCase();
                if (totalValueString !== targetString) {
                    if (!group.reasonOfVariance) {
                        newErrors[`output_${outputIndex}_group_${groupIndex}_projectReasonOfVariance`] = "Reason of Variance is required";
                    }
                }

                // Validate assessment
                if (!group.assessment) {
                    newErrors[`output_${outputIndex}_group_${groupIndex}_projectAssessment`] = "Assessment is required";
                }

                // Add similar validation for other fields as needed
            });
        });

        //
        // Validate deptOutcomeIndicatorTargets
        deptOutcomeIndicatorTargets.forEach((item, deptIndex) => {
            item.targets.forEach((target, targetIndex) => {
                // Ensure fields are not just whitespace and are defined
                // if (!target.Uncomputed_value || target.Uncomputed_value.toString().trim() === "") {
                //     newErrors[`Uncomputed_value_${deptIndex}_${targetIndex}`] = "Ouctome Indicator value is required";
                // }

                //new
                if (target.Uncomputed_value === null || target.Uncomputed_value === undefined || target.Uncomputed_value.toString().trim() === "") {
                    newErrors[`Uncomputed_value_${deptIndex}_${targetIndex}`] = "Outcome Indicator value is required";
                }

                // if (!target.Uncomputed_reason_of_variance || target.Uncomputed_reason_of_variance.trim() === "") {
                //     newErrors[`Uncomputed_reason_of_variance_${deptIndex}_${targetIndex}`] = "Ouctome Indicator Reason of Variance is required";
                // }
                if (!target.Uncomputed_assessment || target.Uncomputed_assessment.trim() === "") {
                    newErrors[`Uncomputed_assessment_${deptIndex}_${targetIndex}`] = "Ouctome Indicator Assessment is required";
                }

                // Add new validations
                // if (!target.Uncomputed_numerator || target.Uncomputed_numerator.toString().trim() === "") {
                //     newErrors[`Uncomputed_numerator_${deptIndex}_${targetIndex}`] = "Outcome Indicator Numerator is required";
                // }
                // if (!target.Uncomputed_denominator || target.Uncomputed_denominator.toString().trim() === "") {
                //     newErrors[`Uncomputed_denominator_${deptIndex}_${targetIndex}`] = "Outcome Indicator Denominator is required";
                // }

                // Conditionally validate Numerator and Denominator based on the unit
                if (target.department_outcome_indicator.outcome_indicator.unit === "%") {
                    if (!target.Uncomputed_numerator || target.Uncomputed_numerator.toString().trim() === "") {
                        newErrors[`Uncomputed_numerator_${deptIndex}_${targetIndex}`] = "Outcome Indicator Numerator is required";
                    }
                    if (!target.Uncomputed_denominator || target.Uncomputed_denominator.toString().trim() === "") {
                        newErrors[`Uncomputed_denominator_${deptIndex}_${targetIndex}`] = "Outcome Indicator Denominator is required";
                    }
                    if (!target.Uncomputed_reason_of_variance || target.Uncomputed_reason_of_variance.trim() === "") {
                        newErrors[`Uncomputed_reason_of_variance_${deptIndex}_${targetIndex}`] = "Ouctome Indicator Reason of Variance is required";
                    }
                }
            });
        });

        // Validate captions for each uploaded image
        uploadedImages.forEach((image, index) => {
            if (!imageCaptions[index] || imageCaptions[index].trim() === "") {
                const key = `caption_${index}`;
                newErrors[key] = "A caption is required for each image.";
            }
        });

        return newErrors;
    };

    const validateCaptions = () => {
        const captionErrors = {};
        Object.keys(imageCaptions).forEach((key) => {
            if (!imageCaptions[key].trim()) {
                captionErrors[key] = "Caption is required";
            }
        });
        return captionErrors;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // const formErrors = validateForm();

        // First, validate other form fields
        const validationErrors = validateForm();
        const captionErrors = validateCaptions();
        const allErrors = { ...validationErrors, ...captionErrors };

        // Check if there are any validation errors

        if (Object.keys(allErrors).length === 0) {
            // Validate that there is at least one risk and one recommendation
            if (risks.length === 0) {
                toast.current.show({ severity: "warn", summary: "Add Risk", detail: "Please add at least one risk.", life: 3000 });
                return;
            }

            if (recommendations.length === 0) {
                toast.current.show({ severity: "warn", summary: "Add Recommendation", detail: "Please add at least one recommendation.", life: 3000 });
                return;
            }

            // Then, check for photo upload
            if (uploadedImages.length === 0) {
                toast.current.show({ severity: "warn", summary: "Photo Required", detail: "At least one photo is required to submit.", life: 3000 });
                return;
            }

            // If there are no validation errors and at least one photo is uploaded
            const imagesWithCaptions = uploadedImages.map((image, index) => ({
                imageName: image.name,
                caption: imageCaptions[index] || "",
            }));

            setShowConfirmDialog(true);
        } else {
            // Validation errors exist
            toast.current.show({ severity: "error", summary: "Validation Error", detail: "You still have some invalid fields. Please rectify them.", life: 3000 });
            setErrors(allErrors);
        }
    };

    const handleFieldChange = (value, field) => {
        // Directly updating state for fields in the "Budget Summary" tab
        switch (field) {
            case "totalApprovedBudget":
                setTotalApprovedBudget(value);
                break;
            case "totalSpentFunds":
                setTotalSpentFunds(value);
                break;
            case "absorptionRate":
                setAbsorptionRate(value);
                break;
            default:
                break;
        }

        // Clear error for the updated field
        const newErrors = { ...errors };
        delete newErrors[field];
        setErrors(newErrors);
    };

    //================== post data mutation ======================
    const createProjectReportMutation = useMutation(postProjectReport, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(["project-reports"]);
            reactToastifyToast.success("Project report Created Successfully");
            console.log("create project report success : ", data);
            //  props.onHide();
        },
        onError: (error) => {
            reactToastifyToast.error(error?.response?.data?.message || "An Error Occurred. Please Contact Admin");
            console.log("create project report error : ", error);
        },
    });

    const handleConfirm = () => {
        // Prepare the data to be submitted

        const completeFormData = {
            subproject_field_data_ids: fieldDataIds,
            project_id: selectedprojectItem?.id,
            fyear_id: finacialYear?.id,
            quarter_id: quarter?.id,
            approved_budget: totalApprovedBudget,
            spent_funds: totalSpentFunds,
            absorptionRate,
            status: "submitted",
            released_funds: "null",
            description: details,
            // budgetOutputs, // Already included
            groupedBudgetOutputs, // Including grouped budget outputs
            deptOutcomeIndicatorTargets,
            financialYear: finacialYear?.name, // Including financial year name
            quarter: quarter?.name, // Including quarter name
            risks, // Including risks
            recommendations, // Including recommendations
            imagesWithCaptions: uploadedImages.map((image, index) => ({
                image: image instanceof File ? image.name : image, // Adjust based on how you handle images
                caption: imageCaptions[index] || "",
            })),
        };

        console.log("Form confirmed for submission:", completeFormData);

        // Create FormData instance
        const formData = new FormData();

        // Append non-file fields

        formData.append("subproject_field_data_ids", JSON.stringify(fieldDataIds));
        formData.append("project_id", selectedprojectItem?.id);
        formData.append("fyear_id", finacialYear?.id);
        formData.append("quarter_id", quarter?.id);
        formData.append("approved_budget", totalApprovedBudget);
        formData.append("spent_funds", totalSpentFunds);
        formData.append("absorptionRate", absorptionRate);
        formData.append("status", "submitted");
        formData.append("released_funds", 0);
        formData.append("description", details);
        formData.append("financialYear", finacialYear?.name);
        formData.append("quarter", quarter?.name);

        // Append budget outputs as JSON string
        formData.append("budgetOutputs", JSON.stringify(budgetOutputs));
        formData.append("groupedBudgetOutputs", JSON.stringify(groupedBudgetOutputs));
        formData.append("deptOutcomeIndicatorTargets", JSON.stringify(deptOutcomeIndicatorTargets));

        // Append risks and recommendations as JSON strings
        formData.append("risks", JSON.stringify(risks));
        formData.append("recommendations", JSON.stringify(recommendations));

        // Append images as file array
        // uploadedImages.forEach((image, index) => {
        //     formData.append(`images[${index}]`, image); // Append image
        //     formData.append(`imagesWithCaptions[${index}][caption]`, imageCaptions[index] || ""); // Append caption
        // });

        let imagesWithCaptions = uploadedImages.map((image, index) => ({
            image: image, // Including the whole image object
            caption: imageCaptions[index] || "",
        }));
        imagesWithCaptions.forEach((item, index) => {
            formData.append(`file[]`, item.image); // Append image
            formData.append(`imagesWithCaptions[${index}][caption]`, item.caption); // Append caption
        });

        console.log("Project Report Form data for submission final:", formData);
        createProjectReportMutation.mutate(formData);

        // Implement further submission logic here (e.g., send data to server)
        // ...

        setShowConfirmDialog(false); // Close the dialog after confirmation
    };

    const handleCancel = () => {
        // Handle any cleanup or state resetting necessary when the user cancels the submission
        setShowConfirmDialog(false); // Simply close the dialog on cancel
    };

    const [detailModalData, setDetailModalData] = useState({});
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [selectedTableRow, setSelectedTableRow] = useState(false);

    const inputClassName = (fieldName) => {
        return errors[fieldName] ? "p-invalid" : "";
    };
    //
    const handleTotalValueChange = (outputIndex, groupIndex, newValue) => {
        let updatedValue = parseInt(newValue, 10);

        // Check if newValue is an empty string and set updatedValue to it
        if (newValue === "") {
            updatedValue = "";
        } else if (isNaN(updatedValue) || updatedValue < 0) {
            // If newValue is not a number or less than 0, set error and return
            setErrors({ ...errors, [`output_${outputIndex}_group_${groupIndex}_totalValue`]: "Invalid total value" });
            return;
        }

        const updatedGroupedOutputs = groupedBudgetOutputs.map((output, oIndex) => {
            if (oIndex === outputIndex) {
                return {
                    ...output,
                    indicatorsGroups: output.indicatorsGroups.map((group, gIndex) => {
                        if (gIndex === groupIndex) {
                            return {
                                ...group,
                                totalValue: updatedValue,
                            };
                        }
                        return group;
                    }),
                };
            }
            return output;
        });

        setGroupedBudgetOutputs(updatedGroupedOutputs);
        setErrors({ ...errors, [`output_${outputIndex}_group_${groupIndex}_totalValue`]: undefined });
    };

    //-
    const handleProjectReasonOfVarianceChange = (outputIndex, groupIndex, newValue) => {
        const updatedGroupedOutputs = groupedBudgetOutputs.map((output, oIndex) => {
            if (oIndex === outputIndex) {
                return {
                    ...output,
                    indicatorsGroups: output.indicatorsGroups.map((group, gIndex) => {
                        if (gIndex === groupIndex) {
                            return {
                                ...group,
                                reasonOfVariance: newValue,
                            };
                        }
                        return group;
                    }),
                };
            }
            return output;
        });

        setGroupedBudgetOutputs(updatedGroupedOutputs);
        setErrors({ ...errors, [`output_${outputIndex}_group_${groupIndex}_projectReasonOfVariance`]: undefined });
    };

    //----------------------------------------------------------------
    const handleProjectAssessmentChange = (outputIndex, groupIndex, newValue) => {
        const updatedGroupedOutputs = groupedBudgetOutputs.map((output, oIndex) => {
            if (oIndex === outputIndex) {
                return {
                    ...output,
                    indicatorsGroups: output.indicatorsGroups.map((group, gIndex) => {
                        if (gIndex === groupIndex) {
                            return {
                                ...group,
                                assessment: newValue,
                            };
                        }
                        return group;
                    }),
                };
            }
            return output;
        });

        setGroupedBudgetOutputs(updatedGroupedOutputs);
        setErrors({ ...errors, [`output_${outputIndex}_group_${groupIndex}_projectAssessment`]: undefined });
    };

    // Update absorption rate whenever totalApprovedBudget or totalSpentFunds change
    useEffect(() => {
        if (totalApprovedBudget > 0) {
            const newAbsorptionRate = (totalSpentFunds / totalApprovedBudget) * 100;
            setAbsorptionRate(newAbsorptionRate);
        }
    }, [totalApprovedBudget, totalSpentFunds]);

    //=========================== image upload =======================
    const toast = useRef(null);
    // Ref for the file upload component
    const fileUploadRef = useRef(null);
    const [uploadedImages, setUploadedImages] = useState([]);
    const onImageUpload = (e) => {
        console.log("Uploaded files:", e.files);
        // Assuming you want to store the uploaded images in state
        // setUploadedImages([...uploadedImages, ...e.files]);
        setUploadedImages([...e.files]);
        // Additional logic for handling the upload can go here

        // If using a ref to a file input or PrimeReact's FileUpload component,
        // use the clear method to reset it
        if (fileUploadRef.current) {
            fileUploadRef.current.clear();
        }
    };

    // Function to remove a single image
    const removeImage = (indexToRemove) => {
        setUploadedImages(uploadedImages.filter((_, index) => index !== indexToRemove));
        // Also remove the caption
        const { [indexToRemove]: _, ...newCaptions } = imageCaptions;
        setImageCaptions(newCaptions);
    };

    // Function to remove all images
    const removeAllImages = () => {
        setUploadedImages([]);
        setImageCaptions({});
    };

    const handleUploadSuccess = () => {
        // Clear the list of uploaded files
        setUploadedImages([]);
        // Additional success logic
    };

    const onImageSelect = (e) => {
        // Check if the number of files selected exceeds 5
        if (e.files.length > 5) {
            toast.current.show({ severity: "warn", summary: "Warning", detail: "You can only upload up to 5 images.", life: 3000 });
            e.files = []; // Clear out the files
            if (fileUploadRef.current) {
                fileUploadRef.current.clear();
            }
            return;
        }

        // Define allowed image types
        const allowedImageTypes = [".jpg", ".jpeg", ".png", ".gif"];
        const isImageFile = e.files.every((file) => allowedImageTypes.some((type) => file.name.toLowerCase().endsWith(type)));

        if (!isImageFile) {
            // Show warning toast if non-image files are uploaded
            toast.current.show({ severity: "warn", summary: "Warning", detail: "Only image files are allowed.", life: 3000 });
            e.files = []; // Clear out the non-image files
            if (fileUploadRef.current) {
                fileUploadRef.current.clear();
            }
        }

        // Set the selected files to state
        // setUploadedImages(e.files);
    };

    const [showBudgetHistory, setShowBudgetHistory] = useState(false);
    const [showPhotosHistory, setShowPhotosHistory] = useState(false);

    //details
    const handleDetailsChange = (e) => {
        setDetails(e.target.value);
        // Optionally reset errors for this field
        if (errors.details) {
            setErrors({ ...errors, details: null });
        }
    };

    //================ handle output indicator ====================

    const handleValueChange = (deptIndex, targetIndex, newValue) => {
        setDeptOutcomeIndicatorTargets((currentTargets) => currentTargets.map((item, index) => (index === deptIndex ? { ...item, targets: item.targets.map((target, tIndex) => (tIndex === targetIndex ? { ...target, Uncomputed_value: newValue } : target)) } : item)));
    };

    const handleReasonChange = (deptIndex, targetIndex, newReason) => {
        setDeptOutcomeIndicatorTargets((currentTargets) => currentTargets.map((item, index) => (index === deptIndex ? { ...item, targets: item.targets.map((target, tIndex) => (tIndex === targetIndex ? { ...target, Uncomputed_reason_of_variance: newReason } : target)) } : item)));
    };

    const handleAssessmentChange = (deptIndex, targetIndex, newAssessment) => {
        setDeptOutcomeIndicatorTargets((currentTargets) => currentTargets.map((item, index) => (index === deptIndex ? { ...item, targets: item.targets.map((target, tIndex) => (tIndex === targetIndex ? { ...target, Uncomputed_assessment: newAssessment } : target)) } : item)));
    };

    // const handleDenominatorChange = (deptIndex, targetIndex, newDenominator) => {
    //     setDeptOutcomeIndicatorTargets((currentTargets) =>
    //         currentTargets.map((item, index) =>
    //             index === deptIndex
    //                 ? {
    //                       ...item,
    //                       targets: item.targets.map((target, tIndex) => (tIndex === targetIndex ? { ...target, Uncomputed_denominator: newDenominator } : target)),
    //                   }
    //                 : item
    //         )
    //     );
    // };

    // const handleNumeratorChange = (deptIndex, targetIndex, newNumerator) => {
    //     setDeptOutcomeIndicatorTargets((currentTargets) =>
    //         currentTargets.map((item, index) =>
    //             index === deptIndex
    //                 ? {
    //                       ...item,
    //                       targets: item.targets.map((target, tIndex) => (tIndex === targetIndex ? { ...target, Uncomputed_numerator: newNumerator } : target)),
    //                   }
    //                 : item
    //         )
    //     );
    // };

    const handleNumeratorChange = (deptIndex, targetIndex, newNumerator) => {
        setDeptOutcomeIndicatorTargets((currentTargets) =>
            currentTargets.map((item, index) =>
                index === deptIndex
                    ? {
                          ...item,
                          targets: item.targets.map((target, tIndex) =>
                              tIndex === targetIndex
                                  ? {
                                        ...target,
                                        Uncomputed_numerator: newNumerator,
                                        Uncomputed_value:
                                            target.department_outcome_indicator.outcome_indicator.unit === "%"
                                                ? target.Uncomputed_denominator && parseFloat(target.Uncomputed_denominator) !== 0
                                                    ? ((parseFloat(newNumerator) / parseFloat(target.Uncomputed_denominator)) * 100).toFixed(2)
                                                    : 0
                                                : parseFloat(target.Uncomputed_value),
                                    }
                                  : target
                          ),
                      }
                    : item
            )
        );
    };

    const handleDenominatorChange = (deptIndex, targetIndex, newDenominator) => {
        setDeptOutcomeIndicatorTargets((currentTargets) =>
            currentTargets.map((item, index) =>
                index === deptIndex
                    ? {
                          ...item,
                          targets: item.targets.map((target, tIndex) =>
                              tIndex === targetIndex
                                  ? {
                                        ...target,
                                        Uncomputed_denominator: newDenominator,
                                        Uncomputed_value:
                                            target.department_outcome_indicator.outcome_indicator.unit === "%"
                                                ? newDenominator && parseFloat(newDenominator) !== 0
                                                    ? ((parseFloat(target.Uncomputed_numerator) / parseFloat(newDenominator)) * 100).toFixed(2)
                                                    : 0
                                                : parseFloat(target.Uncomputed_value ?? 0),
                                    }
                                  : target
                          ),
                      }
                    : item
            )
        );
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="col-12 md:col-12">
                <div className="card p-fluid">
                    <div>
                        {/* <strong>Project Name :</strong>  */}
                        <h4>{selectedprojectItem?.project_title}</h4>
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center", padding: "1rem" }}>
                        <div>
                            <strong>Financial Year : </strong>
                            {finacialYear?.name}
                        </div>
                        <div>
                            <strong>Quarter : </strong>
                            {quarter?.name}
                        </div>
                    </div>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <TabView scrollable={true}>
                    <TabPanel header="Budget Summary">
                        <div style={{ height: "3rem", margin: "1rem", gap: "1rem", display: "flex", justifyContent: "flex-end" }}>
                            <Button
                                label="View Budget History"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowBudgetHistory(true);
                                }}
                                className="p-mt-2"
                            />
                            <Dialog header="Budget History" visible={showBudgetHistory} style={{ minWidth: "70vw" }} maximizable onHide={() => setShowBudgetHistory(false)}>
                                <BudgetHistory selectedSubProjectReports={subProjectReports} />
                            </Dialog>
                        </div>

                        <div className="p-field m-4">
                            <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                <label htmlFor="totalApprovedBudget">Total Released Funds</label>
                                <div>{parseFloat(totalApprovedBudget).toLocaleString("en-US")} </div>
                                <InputText id="totalApprovedBudget" value={totalApprovedBudget} onChange={(e) => handleFieldChange(e.target.value, "totalApprovedBudget")} className={inputClassName("totalApprovedBudget")} />
                                {errors.totalApprovedBudget && <small className="p-error">{errors.totalApprovedBudget}</small>}
                            </div>
                        </div>

                        <div className="p-field m-4">
                            <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                <label htmlFor="totalSpentFunds">Total Spent Funds</label>
                                <div>{parseFloat(totalSpentFunds).toLocaleString("en-US")} </div>
                                <InputText id="totalSpentFunds" value={totalSpentFunds} onChange={(e) => handleFieldChange(e.target.value, "totalSpentFunds")} className={inputClassName("totalSpentFunds")} />
                                {errors.totalSpentFunds && <small className="p-error">{errors.totalSpentFunds}</small>}
                            </div>
                        </div>

                        <div className="p-field m-4">
                            <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                <label htmlFor="absorptionRate">Absorption Rate (%)</label>
                                <InputText id="absorptionRate" value={absorptionRate} onChange={(e) => handleFieldChange(e.target.value, "absorptionRate")} className={inputClassName("absorptionRate")} disabled />
                            </div>
                        </div>
                        {/* Details */}
                        <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                            <label htmlFor="details">Details</label>
                            <InputTextarea value={details} onChange={handleDetailsChange} id="details" rows={5} placeholder="Details" className={inputClassName("details")} />
                            {errors.details && <small className="p-error">{errors.details}</small>}
                        </div>
                    </TabPanel>
                    <TabPanel header="Outcome Indicators">
                        <div>
                            {deptOutcomeIndicatorTargets.map((data, deptIndex) => (
                                <Card title={`Department Outcome: ${data.outcome.name}`} key={deptIndex}>
                                    <DataTable value={data.targets}>
                                        <Column field="department_outcome_indicator.outcome_indicator.name" header="Indicator Name" />
                                        <Column field="base_year" header="Base Year" />
                                        <Column
                                            field="base_value"
                                            header="Base Value"
                                            body={(rowData) => {
                                                let unit = rowData.department_outcome_indicator.outcome_indicator.unit; // Extract the unit for consistent usage
                                                return `${parseInt(rowData.base_value) || 0} ${unit}`; // Display the base value with unit
                                            }}
                                        />
                                        <Column field="target_year" header="Target Year" />
                                        <Column
                                            field="target_value"
                                            header="Target Value"
                                            body={(rowData) => {
                                                let unit = rowData.department_outcome_indicator.outcome_indicator.unit; // Adding unit from the rowData
                                                return `${parseInt(rowData.target_value) || 0} ${unit}`; // Display the target value with unit
                                            }}
                                        />
                                        <Column field="financial_year.name" header="Financial Year" />
                                        <Column
                                            field="financial_year_target"
                                            header="Financial Year Target"
                                            body={(rowData) => {
                                                let unit = rowData.department_outcome_indicator.outcome_indicator.unit; // Adding unit from the rowData
                                                return `${parseInt(rowData.financial_year_target) || 0} ${unit}`; // Display the financial year target with unit
                                            }}
                                        />
                                        <Column
                                            header="Value"
                                            body={(rowData, { rowIndex }) => (
                                                <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                                    <InputText value={rowData.Uncomputed_value} onChange={(e) => handleValueChange(deptIndex, rowIndex, e.target.value)} disabled={rowData.department_outcome_indicator.outcome_indicator.unit === "%"} />
                                                    {errors[`Uncomputed_value_${deptIndex}_${rowIndex}`] && <small className="p-error">{errors[`Uncomputed_value_${deptIndex}_${rowIndex}`]}</small>}
                                                </div>
                                            )}
                                        />
                                        <Column
                                            header="Numerator"
                                            body={(rowData, { rowIndex }) => {
                                                if (rowData.department_outcome_indicator.outcome_indicator.unit !== "%") {
                                                    return <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>N/A</div>;
                                                } else {
                                                    return (
                                                        <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                                            <div>
                                                                <small>{rowData.department_outcome_indicator.outcome_indicator.numerator}</small>
                                                            </div>
                                                            <InputText value={rowData.Uncomputed_numerator} onChange={(e) => handleNumeratorChange(deptIndex, rowIndex, e.target.value)} />
                                                            {errors[`Uncomputed_numerator_${deptIndex}_${rowIndex}`] && <small className="p-error">{errors[`Uncomputed_numerator_${deptIndex}_${rowIndex}`]}</small>}
                                                        </div>
                                                    );
                                                }
                                            }}
                                        />
                                        <Column
                                            header="Denominator"
                                            body={(rowData, { rowIndex }) => {
                                                if (rowData.department_outcome_indicator.outcome_indicator.unit !== "%") {
                                                    return <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>N/A</div>;
                                                } else {
                                                    return (
                                                        <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                                            <div>
                                                                <small>{rowData.department_outcome_indicator.outcome_indicator.denominator}</small>
                                                            </div>
                                                            <InputText value={rowData.Uncomputed_denominator} onChange={(e) => handleDenominatorChange(deptIndex, rowIndex, e.target.value)} />
                                                            {errors[`Uncomputed_denominator_${deptIndex}_${rowIndex}`] && <small className="p-error">{errors[`Uncomputed_denominator_${deptIndex}_${rowIndex}`]}</small>}
                                                        </div>
                                                    );
                                                }
                                            }}
                                        />

                                        {/* <Column
                                            header="Value"
                                            body={(rowData, { rowIndex }) => (
                                                <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                                    <InputText value={rowData.Uncomputed_value} onChange={(e) => handleValueChange(deptIndex, rowIndex, e.target.value)} disabled={true} />
                                                    {errors[`Uncomputed_value_${deptIndex}_${rowIndex}`] && <small className="p-error">{errors[`Uncomputed_value_${deptIndex}_${rowIndex}`]}</small>}
                                                </div>
                                            )}
                                        /> */}
                                        <Column
                                            header="Reason of Variance"
                                            body={(rowData, { rowIndex }) => {
                                                if (rowData.department_outcome_indicator.outcome_indicator.unit !== "%") {
                                                    return <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>N/A</div>;
                                                } else {
                                                    return (
                                                        <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                                            <InputTextarea value={rowData.Uncomputed_reason_of_variance} onChange={(e) => handleReasonChange(deptIndex, rowIndex, e.target.value)} rows={1} />
                                                            {errors[`Uncomputed_reason_of_variance_${deptIndex}_${rowIndex}`] && <small className="p-error">{errors[`Uncomputed_reason_of_variance_${deptIndex}_${rowIndex}`]}</small>}
                                                        </div>
                                                    );
                                                }
                                            }}
                                        />
                                        <Column
                                            header="Assessment"
                                            body={(rowData, { rowIndex }) => (
                                                <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                                    <InputTextarea value={rowData.Uncomputed_assessment} onChange={(e) => handleAssessmentChange(deptIndex, rowIndex, e.target.value)} rows={1} />
                                                    {errors[`Uncomputed_assessment_${deptIndex}_${rowIndex}`] && <small className="p-error">{errors[`Uncomputed_assessment_${deptIndex}_${rowIndex}`]}</small>}
                                                </div>
                                            )}
                                        />
                                    </DataTable>
                                </Card>
                            ))}
                        </div>
                    </TabPanel>
                    <TabPanel header="Indicators">
                        {groupedBudgetOutputs.map((output, outputIndex) => (
                            <div style={{ marginBottom: "2rem" }} key={outputIndex}>
                                <div style={{ display: "flex", gap: "1rem" }}>
                                    <span>Budget Output : </span>
                                    <strong>{output?.projectdepartmentBudgetOutput?.department_budget_output?.name}</strong>
                                </div>
                                <br />
                                <DataTable value={output?.indicatorsGroups}>
                                    <Column field="subprojectBgtOutputIndicatorTarget.project_dept_bgt_output_indicator_target.department_budget_output_indicator.output_indicator.name" header="Budget Output indicator" />
                                    <Column field="subprojectBgtOutputIndicatorTarget.project_dept_bgt_output_indicator_target.target" header="Project Target" />
                                    <Column
                                        field="subprojectReasonOfVariance"
                                        header="History"
                                        body={(rowData) => (
                                            <Tooltip title="Click to view the reports that created this row">
                                                <IconButton
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setSelectedTableRow(rowData);
                                                        setShowDetailModal(true);
                                                    }}
                                                >
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    />

                                    <Column field="subprojectBgtOutputIndicatorTarget.project_dept_bgt_output_indicator_target.department_budget_output_indicator.output_indicator.question" header="Question"></Column>

                                    {/* Conditional rendering based on type */}
                                    <Column
                                        field="totalValue"
                                        header="Total Value"
                                        body={(rowData, { rowIndex: groupIndex }) => {
                                            const indicatorType = rowData.subprojectBgtOutputIndicatorTarget.project_dept_bgt_output_indicator_target.department_budget_output_indicator.output_indicator.measure?.toLowerCase();
                                            const isQuantitative = indicatorType == "quantitative";
                                            return (
                                                <>
                                                    {isQuantitative ? (
                                                        <InputText value={rowData.totalValue} onChange={(e) => handleTotalValueChange(outputIndex, groupIndex, e.target.value)} className={inputClassName(`output_${outputIndex}_group_${groupIndex}_totalValue`)} placeholder="value" />
                                                    ) : (
                                                        <InputTextarea value={rowData.totalValue} onChange={(e) => handleTotalValueChange(outputIndex, groupIndex, e.target.value)} className={inputClassName(`output_${outputIndex}_group_${groupIndex}_totalValue`)} placeholder="value" />
                                                    )}
                                                    {errors[`output_${outputIndex}_group_${groupIndex}_totalValue`] && <small className="p-error">{errors[`output_${outputIndex}_group_${groupIndex}_totalValue`]}</small>}
                                                </>
                                            );
                                        }}
                                    />

                                    {/* Conditional rendering based on target vs totalValue */}
                                    <Column
                                        field="reasonOfVariance"
                                        header="Project Reason of Variance"
                                        body={(rowData, { rowIndex: groupIndex }) => {
                                            const target = rowData.subprojectBgtOutputIndicatorTarget.project_dept_bgt_output_indicator_target.target;
                                            console.log("rowData.totalValue : ", rowData.totalValue);
                                            const targetString = (rowData.subprojectBgtOutputIndicatorTarget.project_dept_bgt_output_indicator_target.target || "").toString().toLowerCase();
                                            const totalValueString = (rowData.totalValue || "").toString().toLowerCase();

                                            if (totalValueString !== targetString) {
                                                return (
                                                    <>
                                                        <InputTextarea
                                                            value={rowData.reasonOfVariance}
                                                            onChange={(e) => handleProjectReasonOfVarianceChange(outputIndex, groupIndex, e.target.value)}
                                                            className={inputClassName(`output_${outputIndex}_group_${groupIndex}_projectReasonOfVariance`)}
                                                            placeholder="Reason of Variance"
                                                        />
                                                        {errors[`output_${outputIndex}_group_${groupIndex}_projectReasonOfVariance`] && <small className="p-error">{errors[`output_${outputIndex}_group_${groupIndex}_projectReasonOfVariance`]}</small>}
                                                    </>
                                                );
                                            }
                                            return "N/A";
                                        }}
                                    />

                                    <Column
                                        field="assessment"
                                        header="Project Assessment"
                                        body={(rowData, { rowIndex: groupIndex }) => (
                                            <>
                                                <InputTextarea value={rowData.assessment} onChange={(e) => handleProjectAssessmentChange(outputIndex, groupIndex, e.target.value)} className={inputClassName(`output_${outputIndex}_group_${groupIndex}_projectAssessment`)} placeholder="Assessment" />
                                                {errors[`output_${outputIndex}_group_${groupIndex}_projectAssessment`] && <small className="p-error">{errors[`output_${outputIndex}_group_${groupIndex}_projectAssessment`]}</small>}
                                            </>
                                        )}
                                    />
                                </DataTable>
                            </div>
                        ))}
                    </TabPanel>
                    <TabPanel header="Risks And Mitigations">
                        <RiskMitigationComponent selectedSubProjectReports={subProjectReports} risks={risks} setRisks={setRisks} />
                    </TabPanel>
                    <TabPanel header="Challanges And Recomendations">
                        <RecommendationsComponent selectedSubProjectReports={subProjectReports} recommendations={recommendations} setRecommendations={setRecommendations} />
                    </TabPanel>
                    <TabPanel header="Photos">
                        <div style={{ height: "3rem", margin: "1rem", gap: "1rem", display: "flex", justifyContent: "flex-end" }}>
                            <Button
                                label="View Photos History"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowPhotosHistory(true);
                                }}
                                className="p-mt-2"
                            />
                            <Dialog header="Photos History" visible={showPhotosHistory} maximizable style={{ minWidth: "70vw" }} onHide={() => setShowPhotosHistory(false)}>
                                <PhotosHistory selectedSubProjectReports={subProjectReports} />
                            </Dialog>{" "}
                        </div>

                        {/* Image Upload*/}
                        <FileUpload ref={fileUploadRef} name="images" accept="image/*" customUpload={true} onSelect={onImageSelect} uploadHandler={onImageUpload} multiple emptyTemplate={<p className="m-0">Drag and drop images here to upload , maximum is 5.</p>} />
                        <div className="flex flex-wrap">
                            {uploadedImages.map((image, index) => (
                                <div key={index} className="p-2">
                                    <Image
                                        // src={URL.createObjectURL(image)}
                                        src={image instanceof File ? URL.createObjectURL(image) : `${process.env.REACT_APP_API_BASE_URL}${image}`}
                                        alt={`Image ${index}`}
                                        preview
                                        width="100"
                                    />
                                    <InputTextarea
                                        value={imageCaptions[index] || ""}
                                        onChange={(e) => {
                                            const newCaptions = { ...imageCaptions, [index]: e.target.value };
                                            setImageCaptions(newCaptions);
                                            // Optionally reset caption error
                                            if (errors[`caption_${index}`]) {
                                                setErrors({ ...errors, [`caption_${index}`]: null });
                                            }
                                        }}
                                        rows={3}
                                        cols={30}
                                        placeholder="Add a caption..."
                                        className={inputClassName(`caption_${index}`)}
                                    />
                                    {errors[`caption_${index}`] && <small className="p-error">{errors[`caption_${index}`]}</small>}
                                    <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-outlined" onClick={() => removeImage(index)} aria-label="Delete" />
                                </div>
                            ))}
                        </div>
                        {uploadedImages.length > 0 && <Button label="Remove All Photos" icon="pi pi-times" onClick={removeAllImages} className="p-button-text p-button-danger" />}
                        {/* Gallery Component (if applicable) */}
                        {/* <GalleryComponent /> */}
                    </TabPanel>
                </TabView>
                <Button label="Submit Report" disabled={createProjectReportMutation.isLoading} type="submit" className="mt-4" />

                {createProjectReportMutation.isLoading && (
                    <center>
                        <ProgressSpinner
                            style={{
                                width: "50px",
                                height: "50px",
                                borderWidth: "8px", // Border thickness
                                borderColor: "blue", // Border color
                                animationDuration: "1s",
                            }}
                            strokeWidth="8"
                            animationDuration="1s"
                        />
                    </center>
                )}
            </form>

            <Dialog
                header="Confirm Submission"
                maximizable
                visible={showConfirmDialog}
                onHide={() => setShowConfirmDialog(false)}
                footer={
                    <div>
                        <Button label="Yes" onClick={handleConfirm} />
                        <Button label="No" onClick={handleCancel} className="p-button-secondary" />
                    </div>
                }
            >
                Are you sure you want to submit the summarized report?
            </Dialog>

            <Dialog header="sub project field data History" style={{ minWidth: "50vw" }} maximizable visible={showDetailModal} onHide={() => setShowDetailModal(false)}>
                {selectedTableRow && (
                    <div className="col-12 md:col-12">
                        <div className="card p-fluid">
                            <DataTable value={selectedTableRow?.indicators}>
                                <Column field="id" header="ID" />
                                <Column field="subproject_bgt_output_indicator_target.subproject.name" header="Subproject" />
                                <Column field="subproject_bgt_output_indicator_target.project_dept_bgt_output_indicator_target.department_budget_output_indicator.output_indicator.name" header="Budget Output indicator" />
                                <Column field="value" header="Value" />
                                <Column field="reasonOfVariance" header="Reason of Variance" />
                                <Column field="assessment" header="Assessment" />
                            </DataTable>
                        </div>
                    </div>
                )}
            </Dialog>
        </>
    );
};

export default ProjectReportForm;
