import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import { toast } from "react-toastify";
import { AutoComplete } from "primereact/autocomplete";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DeleteIcon from "@mui/icons-material/Delete";
import { ProgressSpinner } from "primereact/progressspinner";

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteProgramById, getAllPrograms } from "../../../../services/programs/program-service";

function DirectorateRowForm({ handleSubmit, DirectorateData = { name: "", details: "" }, currentProgramId, programDetail }) {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);
    const [filteredPrograms, setFilteredPrograms] = useState([]);

    const queryClient = useQueryClient();
    const getListOfPrograms = useQuery(["programs"], getAllPrograms, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching programs is : ", error);
        },
    });

    console.log("getListOfPrograms lll ", getListOfPrograms);

    const validate = (values) => {
        const errors = {};
        if (!values.name) errors.name = "Name is required";
        if (!values.code) errors.code = "Code is required";
        if (!values.details) errors.details = "Details are required";
        if (!values.programsIds || values.programsIds.length === 0) errors.programsIds = "At least one program is required";
        return errors;
    };

    const onSubmitForm = (data) => {
        console.log("form submit data : ", data);
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            // No validation errors
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            toast.warning("First Fill In All Required Fields");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    const [dropdownPrograms, setDropdownPrograms] = useState([]);
    // const [selectedPrograms, setSelectedPrograms] = useState(DirectorateData.programs ? DirectorateData.programs : programDetail ? [programDetail] : []);
    const [selectedPrograms, setSelectedPrograms] = useState(DirectorateData.programs || (programDetail ? [programDetail] : []));

    // console.log("kkkkkkk selectedPrograms xxxxx  : ", selectedPrograms);
    // console.log("mmmmmmmmmmrrreee  programDetail : ", programDetail);
    // console.log("mmmmmmmmmmrrreee DirectorateData.programs : ", DirectorateData.programs);
    let directorateWithProgramIds = {
        ...DirectorateData,
        programsIds: DirectorateData?.programs?.map((program) => program.id),
    };

    if (currentProgramId && !DirectorateData?.programs) {
        directorateWithProgramIds = {
            ...DirectorateData,
            programsIds: [currentProgramId],
        };
    }
    console.log("directorateWithProgramIds : ", directorateWithProgramIds);

    //
    const fetchSuggestions = (query) => {
        const filteredPrograms = getListOfPrograms.data?.data?.data.filter((program) => !selectedPrograms.map((sp) => sp.id).includes(program.id) && program.name.toLowerCase().startsWith(query.toLowerCase()));
        setDropdownPrograms(filteredPrograms);
    };

    return (
        <div className="p-fluid">
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={directorateWithProgramIds}
                    initialValuesEqual={() => true}
                    // initialValuesEqual with a function returning true prevents the form from
                    // reinitializing when the initialValues prop changes. This is useful when you
                    // want to avoid re-rendering or reinitializing the form due to changes in initial values.
                    // Be cautious using this if your initial values are meant to be dynamic and responsive
                    // to changes in your application's state.
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                onSubmitForm(values);
                            }}
                        >
                            <Field name="name">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="name">Name</label>
                                        <InputText {...input} id="name" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="programsIds">
                                {({ input, meta }) => {
                                    console.log("kkkkkkk first main ffffff input.value : ", input.value);
                                    return (
                                        <div className="p-field m-4">
                                            <label htmlFor="programsIds">Programs</label>
                                            <AutoComplete
                                                {...input}
                                                suggestions={dropdownPrograms}
                                                completeMethod={(e) => fetchSuggestions(e.query)}
                                                disabled={getListOfPrograms.isLoading}
                                                multiple={true}
                                                value={selectedPrograms} // Display the program names
                                                field="name"
                                                dropdown={true}
                                                onChange={(e) => {
                                                    const newPrograms = e.value;

                                                    if (newPrograms.length > selectedPrograms.length) {
                                                        // Program added
                                                        const addedProgram = newPrograms[newPrograms.length - 1];
                                                        const existingProgram = selectedPrograms.find((sp) => sp.id === addedProgram.id);
                                                        if (existingProgram) {
                                                            toast.warning("This program is already selected.");
                                                        } else {
                                                            setSelectedPrograms([...selectedPrograms, addedProgram]);
                                                            input.onChange([...input.value, addedProgram.id]);
                                                        }
                                                    } else {
                                                        // Program removed

                                                        const removedProgram = selectedPrograms.find((prog) => !newPrograms.map((np) => np.id).includes(prog.id));
                                                        const updatedSelectedPrograms = selectedPrograms.filter((program) => program.id !== removedProgram.id);
                                                        setSelectedPrograms(updatedSelectedPrograms);

                                                        input.onChange([...updatedSelectedPrograms.map((prog) => prog.id)]);
                                                        fetchSuggestions("");
                                                    }
                                                }}
                                                id="programsIds"
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                            {/* DataTable to display selected programs */}
                                            {input.value && input.value.length > 0 && (
                                                <div className="p-field m-4">
                                                    <DataTable value={selectedPrograms}>
                                                        <Column field="id" header="Program ID"></Column>
                                                        <Column field="name" header="Program Name"></Column>
                                                        <Column
                                                            body={(rowData) => (
                                                                <DeleteIcon
                                                                    style={{ color: "tomato", cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        const updatedSelectedPrograms = selectedPrograms.filter((program) => program.id !== rowData.id);
                                                                        setSelectedPrograms(updatedSelectedPrograms);
                                                                        input.onChange(updatedSelectedPrograms.map((prog) => prog.id));
                                                                        fetchSuggestions(""); // Refresh the dropdown suggestions
                                                                    }}
                                                                />
                                                            )}
                                                            header="Actions"
                                                        ></Column>
                                                    </DataTable>
                                                </div>
                                            )}
                                            {getListOfPrograms.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        </div>
                                    );
                                }}
                            </Field>

                            <Field name="code">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="code">Code</label>
                                        <InputText {...input} id="code" type="text" />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="details">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="details">Brief details</label>
                                        <InputTextarea {...input} rows={5} cols={30} id="details" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <div className="d-grid gap-2">
                                <Button type="submit" label="Save" className="p-button-primary" icon="pi pi-check" />
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    onHide={onCancel}
                    style={{ width: "30vw" }}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to submit?
                </Dialog>
            </div>
        </div>
    );
}

export default DirectorateRowForm;
