import React, { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";

import GeoJsonMapViewPage from "./geojson-map/DistrictsMap";
import { Card } from "primereact/card";
import BreadcrumbNav from "../../../components/mweComponents/BreadcrumbNav";

const MapsView = ({ loggedInUserData }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <div>
            <BreadcrumbNav />
            <Card>
                {/* <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>

                <TabPanel header="Map 2">
                    <GeoJsonMapViewPage />
                </TabPanel>
            </TabView> */}
                <GeoJsonMapViewPage loggedInUserData={loggedInUserData} />
            </Card>
        </div>
    );
};

export default MapsView;
