import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { useQuery, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { getAssignableRoles } from "../../../../services/auth/user-service";
import { getAllDepartments } from "../../../../services/departments/department-service";
import { addUser } from "../../../../services/auth/auth-api";
import WaterIsLoading from "../../../../components/mweComponents/WaterIsLoading";
import setFieldTouched from "final-form-set-field-touched";
import { deleteProjectById, getAllProjects, getProjectsByProjectIdOrRole, getProjectsByDepartmentIdAndRole } from "../../../../services/projects/project-service";
import { deleteSubProjectById, getAllSubProjects, getAllSubProjectsUnderUserProjectsByProjectIdOrRole } from "../../../../services/sub-projects/sub-project-service";
import { classNames } from "primereact/utils";
import { ProgressSpinner } from "primereact/progressspinner";
import { getAllDirectorates } from "../../../../services/departments/directorate-service";
import { deleteProgramById, getAllPrograms } from "../../../../services/programs/program-service";
import { Password } from "primereact/password";
import { getAllDepartmentAdministrations, getDepartmentAdministrationById, postDepartmentAdministration, updateDepartmentAdministration, deleteDepartmentAdministrationById } from "../../../../services/departments/department-administrations-service";
import { getAllDepartmentUtilities, getDepartmentUtilitieById, postDepartmentUtilities, updateDepartmentUtilities, deleteDepartmentUtilitieById } from "../../../../services/departments/utility-dept/department-utilities-service.js";

function RowForm({ loggedInUserData, handleSubmit, initialData, ...props }) {
    const [showProjectField, setShowProjectField] = useState(false);
    console.log("loggedInUserData on user list page : ", loggedInUserData);

    console.log("testing lll fdgdsgsdf : ", initialData);

    useEffect(() => {
        // Reset project field when the form is closed
        if (!props.show) {
            setShowProjectField(false);
        }
    }, [props.show]);

    // const onSubmit = (values) => {
    //     createUserMutation.mutate(values);
    // };

    const validate = (values) => {
        const errors = {};

        if (!values.name) {
            errors.name = "Name is required";
        }
        if (!values.email) {
            errors.email = "Email is required";
        }
        if (!values.status) {
            errors.status = "Status is required";
        }
        // Improved Password Validation
        if (!values.password) {
            errors.password = "Password is required";
        } else {
            if (values.password.length < 8) {
                errors.password = "Password must be at least 8 characters long";
            }
            if (!/[A-Z]/.test(values.password)) {
                errors.password = errors.password ? errors.password + " Must include an uppercase letter" : "Must include an uppercase letter";
            }
            if (!/[a-z]/.test(values.password)) {
                errors.password = errors.password ? errors.password + " Must include a lowercase letter" : "Must include a lowercase letter";
            }
            if (!/[0-9]/.test(values.password)) {
                errors.password = errors.password ? errors.password + " Must include a number" : "Must include a number";
            }
            if (!/[@$!%*#?&]/.test(values.password)) {
                errors.password = errors.password ? errors.password + " Must include a special character (@, $, !, %, *, #, ?, &)" : "Must include a special character (@, $, !, %, *, #, ?, &)";
            }
        }
        if (!values.role) {
            errors.role = "Role is required";
        }
        if (!values.directorate_id) {
            errors.directorate_id = "Directorate is required";
        }

        // Role specific validations
        // Role specific validations
        // Role specific validations
        // Role specific validations
        if (!values.role) {
            errors.role = "Role is required";
        } else {
            if (["Admin", "Department Commissioner", "Assistant Commissioner", "District Water Engineer", "Project Manager", "Engineer", "Utility Manager"].includes(values.role) && !values.directorate_id) {
                errors.directorate_id = "Directorate is required";
            }
            if (["Department Commissioner", "Assistant Commissioner", "District Water Engineer", "Project Manager", "Engineer", "Utility Manager"].includes(values.role) && !values.department_id) {
                errors.department_id = "Department is required";
            }
            // New validation for department_administrations_id
            if (["District Water Engineer", "Project Manager", "Engineer"].includes(values.role) && !values.department_administrations_id) {
                errors.department_administrations_id = "Department Administration is required";
            }
            if (["Project Manager", "Engineer"].includes(values.role) && !values.project_id) {
                errors.project_id = "Project is required";
            }
            if (values.role === "Engineer" && !values.subproject_id) {
                errors.subproject_id = "Subproject is required";
            }

            if (values.role === "Utility Manager" && !values.utilities_id) {
                errors.utilities_id = "Utility is required";
            }
        }
        // if (!values.department_id) {
        //     errors.department_id = "Department is required";
        // }
        // if (!values.project_id) {
        //     errors.project_id = "Project is required";
        // }
        // if (!values.subproject_id) {
        //     errors.subproject_id = "Subproject is required";
        // }

        // You can add more validation logic as needed

        return errors;
    };

    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);

    const onSubmit = (data, form) => {
        // Add 'form' as an argument
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            // Mark all fields as touched to show validation errors
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("First fill in all required fields.");
        }
    };

    const onConfirmSubmit = (values) => {
        handleSubmit(pendingData);
        setShowConfirmDialog(false);
    };

    const onCancelSubmit = () => {
        setShowConfirmDialog(false);
    };

    const [selectedDirectorate, setSelectedDirectorate] = useState(initialData && initialData?.user_directorate?.directorate?.id);
    const [selectedDepartment, setSelectedDepartment] = useState(initialData && initialData?.user_department?.department?.id);
    const [selectedDepartmentAdministration, setSelectedDepartmentAdministration] = useState(initialData && initialData?.department_administration?.department_administration?.id);
    const [selectedProject, setSelectedProject] = useState(initialData && initialData?.user_project?.project?.id);
    const [selectedSubProject, setSelectedSubProject] = useState(initialData && initialData?.user_sub_project?.sub_project?.id);
    const [selectedUtility, setSelectedUtility] = useState(initialData && initialData?.user_utility?.utility?.id);

    //==================== get all Roles ====================
    const getAllRoles = useQuery(["roles"], getAssignableRoles, {
        onSuccess: (data) => {
            console.log("fetching roles xxx : ", data);
        },
        onError: (error) => {},
    });

    if (initialData) {
        initialData = { role: initialData?.role, ...initialData };
    }

    //==================== directorates =========================
    const directorates = useQuery(["directorates"], getAllDirectorates, { onError: (error) => {} });

    let dropdownDirectorates = directorates?.data?.data?.data?.map((item) => ({ label: item.name, value: item.id }));

    if (initialData) {
        initialData = { ...initialData, directorate_id: initialData?.user_directorate?.directorate?.id };
    }
    //================== departments ===============
    const fetchAllDepartments = useQuery(["departments", "by-directorate", selectedDirectorate], () => (!selectedDirectorate ? getAllDepartments() : getAllDepartments({ directorate_id: selectedDirectorate })), {
        enabled: !!selectedDirectorate,
        onSuccess: (data) => {
            // setDepartments(data.data);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
            console.log("get list of departments : ", error);
        },
    });

    if (initialData) {
        initialData = { ...initialData, department_id: initialData?.user_department?.department?.id };
    }

    // ====================== department utility ==============================
    const fetchAllDepartmentUtilities = useQuery(["utilities", selectedDepartment], () => getAllDepartmentUtilities({ department_id: selectedDepartment }), {
        enabled: !!selectedDepartment, // Only run the query if a department is selected
        onSuccess: (data) => {
            console.log("success department fetching utilities is : ", data);
        },
        onError: (error) => {
            console.log("Error fetching utilities is : ", error);
        },
    });

    if (initialData) {
        initialData = { ...initialData, utilities_id: initialData?.user_utility?.utility?.id };
    }

    //============================ department administration ==========================
    const fetchAllDepartmentAdministrationsQuery = useQuery(["department-administration", "departments", selectedDepartment], () => getAllDepartmentAdministrations({ department_id: selectedDepartment }), {
        enabled: !!selectedDepartment, // Only run the query if a department is selected
        onSuccess: (data) => {
            console.log("fetching department-administration data is : ", data);
        },
        onError: (error) => {
            console.log("Error fetching department-administration is : ", error);
        },
    });

    if (initialData) {
        initialData = { ...initialData, department_administrations_id: initialData?.department_administration?.department_administration?.id };
    }

    //=============== project ==========
    const fetchAllProjects = useQuery(["projects", "by-department", selectedDepartment, selectedDepartmentAdministration], () => getAllProjects({ department_id: selectedDepartment, department_administrations_id: selectedDepartmentAdministration }), {
        enabled: !!selectedDepartmentAdministration, // Only run the query if a department is selected
        onSuccess: (data) => {
            console.log("getAllDepartments onsuccess fetching projects : ", data);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
            console.log("get list of projects : ", error);
        },
    });

    if (initialData) {
        initialData = { ...initialData, project_id: initialData?.user_project?.project?.id };
    }
    // ========================== fetch all sub projects =======================
    const fetchAllSubProjects = useQuery(["subprojects", "by-project", selectedProject], () => getAllSubProjects({ project_id: selectedProject }), {
        enabled: !!selectedProject,
        onSuccess: (data) => {
            console.log("getAllProjects onsuccess fetching projects dsfsd : ", data);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
            console.log("get list of projects : ", error);
        },
    });

    if (initialData) {
        initialData = { ...initialData, subproject_id: initialData?.user_sub_project?.sub_project?.id };
    }

    return (
        <div>
            <div className="col-12 md:col-12">
                <div className="card p-fluid">
                    <Form
                        onSubmit={onSubmit}
                        initialValues={initialData}
                        validate={validate}
                        initialValuesEqual={() => true}
                        // initialValuesEqual with a function returning true prevents the form from
                        // reinitializing when the initialValues prop changes. This is useful when you
                        // want to avoid re-rendering or reinitializing the form due to changes in initial values.
                        // Be cautious using this if your initial values are meant to be dynamic and responsive
                        // to changes in your application's state.
                        mutators={{ setFieldTouched }}
                        render={({ handleSubmit, form, submitting, values }) => (
                            <form onSubmit={handleSubmit}>
                                <Field name="name">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="name">Name</label>
                                            <InputText {...input} id="name" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                                <Field name="email">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="email">Email</label>
                                            <InputText {...input} id="email" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                                <Field name="status">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="status">Status</label>
                                            <Dropdown
                                                {...input}
                                                options={[
                                                    { id: "active", name: "Active" },
                                                    { id: "deactive", name: "Deactive" },
                                                ].map((role) => ({ label: role.name, value: role.id }))}
                                                placeholder="Select a Status"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                                <Field name="password">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="password">Password</label>
                                            <Password
                                                {...input}
                                                id="password"
                                                toggleMask
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                feedback={true} // Set to true if you want password strength indicator
                                                promptLabel="Choose a password"
                                                weakLabel="Too simple"
                                                mediumLabel="Average complexity"
                                                strongLabel="Complex password"
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>

                                <Field name="role">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="role">Role</label>
                                            <Dropdown {...input} options={getAllRoles?.data?.data?.map((role) => ({ label: role, value: role }))} placeholder="Select a Role" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>

                                {["Admin", "Department Commissioner", "Assistant Commissioner", "District Water Engineer", "Project Manager", "Engineer", "Utility Manager"].includes(values.role) && (
                                    <Field name="directorate_id">
                                        {({ input, meta }) => (
                                            <div className="p-field m-4">
                                                <label htmlFor="directorate_id">Directorate</label>
                                                <Dropdown
                                                    {...input}
                                                    value={selectedDirectorate || ""}
                                                    options={dropdownDirectorates}
                                                    id="directorate_id"
                                                    placeholder="Select Directorate"
                                                    className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    onChange={(e) => {
                                                        input.onChange(e.value);

                                                        setSelectedDirectorate(e.value);
                                                        // Reset children dropdowns

                                                        // Reset and update form state for dependent fields
                                                        setSelectedDepartment(null);
                                                        form.change("department_id", null);

                                                        setSelectedProject(null);
                                                        form.change("project_id", null);

                                                        setSelectedSubProject(null);
                                                        form.change("subproject_id", null);
                                                    }}
                                                />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                            </div>
                                        )}
                                    </Field>
                                )}

                                {["Department Commissioner", "Assistant Commissioner", "District Water Engineer", "Project Manager", "Engineer", "Utility Manager"].includes(values.role) && (
                                    <div style={{ marginBottom: "1rem" }}>
                                        <Field name="department_id">
                                            {({ input, meta }) => (
                                                <div className="p-field m-4">
                                                    <label>Department</label>
                                                    <Dropdown
                                                        {...input}
                                                        value={selectedDepartment || ""}
                                                        options={fetchAllDepartments?.data?.data?.data.map((item) => ({
                                                            label: item.name,
                                                            value: item.id,
                                                        }))}
                                                        onChange={(e) => {
                                                            input.onChange(e.value);

                                                            setSelectedDepartment(e.value);
                                                            // Reset children dropdowns

                                                            setSelectedUtility(null);
                                                            form.change("utilities_id", null);

                                                            setSelectedDepartmentAdministration(null);
                                                            form.change("department_administrations_id", null);

                                                            setSelectedProject(null);
                                                            form.change("project_id", null);

                                                            setSelectedSubProject(null);
                                                            form.change("subproject_id", null);
                                                        }}
                                                        placeholder="Select Department"
                                                        disabled={fetchAllDepartments.isLoading}
                                                        className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    />
                                                    {fetchAllDepartments.isLoading && fetchAllDepartments.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                    {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                )}

                                {["Utility Manager"].includes(values.role) && (
                                    <Field name="utilities_id">
                                        {({ input, meta }) => (
                                            <div className="p-field m-4">
                                                <label htmlFor="utilities_id">Utility</label>
                                                <Dropdown
                                                    {...input}
                                                    value={selectedUtility || ""}
                                                    options={fetchAllDepartmentUtilities?.data?.data?.data.map((item) => ({
                                                        label: item.name,
                                                        value: item.id,
                                                    }))}
                                                    id="utilities_id"
                                                    placeholder="Select Utility"
                                                    className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    disabled={fetchAllDepartmentUtilities.isLoading}
                                                    onChange={(e) => {
                                                        input.onChange(e.value);

                                                        setSelectedUtility(e.value);
                                                        // Reset children dropdowns
                                                    }}
                                                />
                                                {fetchAllDepartmentUtilities.isLoading && fetchAllDepartmentUtilities.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                            </div>
                                        )}
                                    </Field>
                                )}

                                {["District Water Engineer", "Project Manager", "Engineer"].includes(values.role) && (
                                    <div style={{ marginBottom: "1rem" }}>
                                        <Field name="department_administrations_id">
                                            {({ input, meta }) => (
                                                <div className="p-field m-4">
                                                    <label>Department Administration</label>
                                                    <Dropdown
                                                        {...input}
                                                        value={selectedDepartmentAdministration || ""}
                                                        options={fetchAllDepartmentAdministrationsQuery?.data?.data?.data.map((item) => ({
                                                            label: item.name,
                                                            value: item.id,
                                                        }))}
                                                        onChange={(e) => {
                                                            input.onChange(e.value);

                                                            setSelectedDepartmentAdministration(e.value);
                                                            // Reset children dropdown
                                                            setSelectedProject(null);
                                                            form.change("project_id", null);

                                                            setSelectedSubProject(null);
                                                            form.change("subproject_id", null);
                                                        }}
                                                        placeholder="Select Department Administration"
                                                        className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                        disabled={fetchAllDepartmentAdministrationsQuery.isLoading}
                                                    />
                                                    {fetchAllDepartmentAdministrationsQuery.isLoading && fetchAllDepartmentAdministrationsQuery.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                    {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                )}

                                {["Project Manager", "Engineer"].includes(values.role) && (
                                    <div style={{ marginBottom: "1rem" }}>
                                        <Field name="project_id">
                                            {({ input, meta }) => (
                                                <div className="p-field m-4">
                                                    <label>Project</label>
                                                    <Dropdown
                                                        {...input}
                                                        value={selectedProject || ""}
                                                        options={fetchAllProjects?.data?.data?.data.map((item) => ({
                                                            label: item.project_title,
                                                            value: item.id,
                                                        }))}
                                                        onChange={(e) => {
                                                            input.onChange(e.value);

                                                            setSelectedProject(e.value);
                                                            // Reset children dropdown
                                                            setSelectedSubProject(null);
                                                            form.change("subproject_id", null);
                                                        }}
                                                        placeholder="Select Project"
                                                        className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                        disabled={fetchAllProjects.isLoading}
                                                    />
                                                    {fetchAllProjects.isLoading && fetchAllProjects.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                    {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                )}

                                {/* Conditional Field Rendering based on the role */}
                                {values.role == "Engineer" && (
                                    <div style={{ marginBottom: "1rem" }}>
                                        <Field name="subproject_id">
                                            {({ input, meta }) => (
                                                <div className="p-field m-4">
                                                    <label>Sub Project</label>
                                                    <Dropdown
                                                        {...input}
                                                        value={selectedSubProject || ""}
                                                        options={fetchAllSubProjects?.data?.data?.data.map((item) => ({
                                                            label: item.name,
                                                            value: item.id,
                                                        }))}
                                                        onChange={(e) => {
                                                            input.onChange(e.value);

                                                            setSelectedSubProject(e.value);
                                                        }}
                                                        placeholder="Select Sub Project"
                                                        className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                        disabled={fetchAllSubProjects.isLoading}
                                                    />
                                                    {fetchAllSubProjects.isLoading && fetchAllSubProjects.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                    {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                )}

                                {/* Add more fields as needed */}
                                <div className="p-field m-4">
                                    <Button type="submit" label="Save" className="p-button-primary" />
                                </div>
                            </form>
                        )}
                    />
                </div>
            </div>
            <Dialog
                header="Confirm Submission"
                visible={showConfirmDialog}
                style={{ width: "30vw" }}
                onHide={onCancelSubmit}
                footer={
                    <div>
                        <Button label="Yes" onClick={onConfirmSubmit} />
                        <Button label="No" onClick={onCancelSubmit} className="p-button-secondary" />
                    </div>
                }
            >
                Are you sure you want to add this user?
            </Dialog>
        </div>
    );
}

export default RowForm;
