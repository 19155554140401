import axiosAPI from "../axiosApi";

export async function getAllSubprojectBudgetOutputs(params = {}) {
    const response = await axiosAPI.get("subproject-budget-output", { params: params });
    return response;
}

export async function getSubprojectBudgetOutputById(id) {
    const response = await axiosAPI.get(`subproject-budget-output/${id}`);
    return response;
}

export async function postSubprojectBudgetOutput(data) {
    const response = await axiosAPI.post(`subproject-budget-output`, data);
    return response;
}

export async function updateSubprojectBudgetOutput(id, data) {
    const response = await axiosAPI.put(`subproject-budget-output/${id}`, data);
    return response;
}

export async function deleteSubprojectBudgetOutputById(id) {
    const response = await axiosAPI.delete(`subproject-budget-output/${id}`);
    return response;
}
