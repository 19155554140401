import React, { useEffect, useState, useRef } from "react";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Chart } from "primereact/chart";

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import moment from "moment";

import { getAllFinancialYears, getFinancialYearsById, postFinancialYears, updateFinancialYears, deleteFinancialYears } from "../../../../../services/financial-years/financial-years-service";
import { getAllQuarters, getQuartersById, postQuarters, updateQuarters, deleteQuarters } from "../../../../../services/financial-years/quarters";
import { getAllOutcomeIndicatorTypes, getOutcomeIndicatorTypeById, postOutcomeIndicatorType, updateOutcomeIndicatorType, deleteOutcomeIndicatorTypeById } from "../../../../../services/directorates/outcome-indicator-types-service.js";
import { getDeptOutcomeIndicatorTargetsGroupedByIndicatorTypes } from "../../../../../services/departments/departmentOutcomeIndicatorTarget-service";
import { getDeptReportDashboardStats } from "../../../../../services/departments/department-reports-service.js";

import { toast } from "react-toastify";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useNavigate } from "react-router-dom";

import { Panel } from "primereact/panel";

import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";

import { Accordion, AccordionTab } from "primereact/accordion";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ProgressBar } from "primereact/progressbar";
import { Toast as PrimeReactToast } from "primereact/toast";
import DataDisplayComponent from "./widgets/DataDisplayComponent";
import Lottie from "lottie-react";
import NoData from "../../assets/Animation - nodata lottie 1702085133252.json";

const NumeratorStats = ({ department, financialYear, setFinancialYear, quarter, setQuarter }) => {
    console.log("quarter fdgfdgvsdf : ", quarter);
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const primeReactToast = useRef(null);
    // Sample data

    const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;
    //department_id;

    //==================== department budget outputs for dashboad =======
    const getDeptReportDashboardStatsQuery = useQuery(["department-report-dashboard-stats", "departments", financialYear, quarter, department?.id], () => getDeptReportDashboardStats({ financial_year_id: financialYear, quarter_id: quarter, department_id: department?.id }), {
        enabled: financialYear && quarter ? true : false,
        onSuccess: (data) => {
            console.log("fetching department-report-dashboard-stats data is : ", data);
        },
        onError: (error) => {
            console.log("Error fetching department-report-dashboard-stats is : ", error);
        },
        // staleTime: 3 * 60 * 60 * 1000, // 3 hours in milliseconds
    });

    //
    console.log("getDeptReportDashboardStatsQuery?.data?.data : ", getDeptReportDashboardStatsQuery?.data?.data);

    // State to manage the active index of the accordion
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <div>
            <PrimeReactToast ref={primeReactToast} />
            {getDeptReportDashboardStatsQuery.isLoading && getDeptReportDashboardStatsQuery.isFetching ? (
                <div className="p-field m-4">
                    <ProgressBar mode="indeterminate" style={{ height: "6px" }} />
                </div>
            ) : Array.isArray(getDeptReportDashboardStatsQuery?.data?.data?.deptReports) && getDeptReportDashboardStatsQuery?.data?.data?.deptReports.length > 0 ? (
                <DataDisplayComponent data={getDeptReportDashboardStatsQuery?.data?.data?.deptReports} />
            ) : (
                <center>
                    {" "}
                    <Lottie
                        animationData={NoData}
                        loop={true}
                        autoplay={true}
                        style={{
                            width: "300px", // Increase width to cover the area
                            // height: "100%", // Increase height to cover the area
                        }}
                    />
                </center>
            )}
        </div>
    );
};

export default NumeratorStats;
