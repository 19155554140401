import axiosAPI from "../../axiosApi";

export async function getAllUtilityThemeReports(params = {}) {
    const response = await axiosAPI.get("utility-theme-reports", { params: params });
    return response;
}

export async function getUtilityThemeReportById(id) {
    const response = await axiosAPI.get(`utility-theme-reports/${id}`);
    return response;
}

export async function postUtilityThemeReport(data) {
    const response = await axiosAPI.post(`utility-theme-reports`, data);
    return response;
}

export async function updateUtilityThemeReport(id, data) {
    const response = await axiosAPI.put(`utility-theme-reports/${id}`, data);
    return response;
}

export async function deleteUtilityThemeReportById(id) {
    const response = await axiosAPI.delete(`utility-theme-reports/${id}`);
    return response;
}

export async function updateUtilityThemeReportDataStatus(data) {
    const response = await axiosAPI.post("utility-theme-report-status", data);
    return response;
}

export async function postToAggregateUtilityThemeReports(data) {
    const response = await axiosAPI.post(`aggregateUtilityThemeReports`, data);
    return response;
}

export async function postToAggregateUtilityReportsForDasboard(data) {
    const response = await axiosAPI.post(`aggregate-utility-for-dashboard`, data);
    return response;
}
