import React, { useState, useEffect } from "react";

import { getAllDepartmentBudjetOutputs, getDepartmentBudjetOutputById, postDepartmentBudjetOutput, updateDepartmentBudjetOutput, deleteDepartmentBudjetOutputById } from "../../../../../services/departments/departments-budget-outputs-service.js";

import RowForm from "./widgets/RowForm";
import { useQueryClient, useMutation } from "@tanstack/react-query";

import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";

// import DepartmentBudgetOutPutIndicatorsPage from "./department-budget-outputs-indicators/DepartmentBudgetOutPutIndicatorsPage";

function DepartmentsUtilitiesModal(props) {
    const queryClient = useQueryClient();

    const editMutation = useMutation({
        mutationFn: (variables) => updateDepartmentBudjetOutput(props?.rowData?.id, variables),
        onSuccess: () => {
            props.onClose();
            toast.success("Edited Successfully");
            queryClient.invalidateQueries(["departments", "departmentBudgetOutputs"]);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
            console.log("create programs error : ", error);
        },
    });

    const handleSubmit = (data) => {
        console.log(data);

        editMutation.mutate(data);
    };

    return (
        <Dialog header={`Budget Output Indicators For ${props?.rowData?.name}`} maximizable visible={props.show} style={{ width: "75vw" }} onHide={() => props.onHide()}>
            {/* <h3>Programs Edit Form</h3> */}
            {/* <DepartmentBudgetOutPutIndicatorsPage selectedItem={props?.rowData} /> */}
            {/* <h4>{creactProgramsMutation.status}</h4> */}
        </Dialog>
    );
}

export default DepartmentsUtilitiesModal;
