import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { Dropdown } from "primereact/dropdown";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteProjectById, getAllProjects, getProjectsByProjectIdOrRole, getProjectsByDepartmentIdAndRole } from "../../../../../services/projects/project-service";
import { classNames } from "primereact/utils";
import { getAllDepartments } from "../../../../../services/departments/department-service";
import { getAllDepartmentBudjetOutputs, getDepartmentBudjetOutputById, postDepartmentBudjetOutput, updateDepartmentBudjetOutput, deleteDepartmentBudjetOutputById } from "../../../../../services/departments/departments-budget-outputs-service.js";
import { deleteProgramById, getAllPrograms } from "../../../../../services/programs/program-service";

import moment from "moment";
import { Calendar } from "primereact/calendar";

//
import { toast } from "react-toastify";
import { AutoComplete } from "primereact/autocomplete";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DeleteIcon from "@mui/icons-material/Delete";

function RowForm({ handleSubmit, initialData = { name: "", details: "", description: "" }, DirectorateData = { name: "", details: "" }, ...props }) {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);
    const queryClient = useQueryClient();

    const validate = (values) => {
        const errors = {};

        if (!values.name) errors.name = "Period is required";

        if (!values.start_date) errors.start_date = "Start Date is required";
        if (!values.end_date) errors.end_date = "End Date is required";

        return errors;
    };

    const onSubmitForm = (data) => {
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            // No validation errors
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            toast.warning("First Fill In All Required Fields");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    // initialData = { department_id: props?.department_id, ...initialData };

    return (
        <div className="col-12 md:col-12">
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                console.log("values hhh : ", values);
                                console.log("event fffff : ", event);
                                onSubmitForm(values);
                                // handleSubmit(event, values);
                            }}
                        >
                            <Field name="name">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="name">Period</label>
                                        <InputText {...input} id="name" type="text" />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="start_date">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="start_date">Start Date</label>
                                        <Calendar
                                            {...input}
                                            value={input.value ? new Date(input.value) : null} // Set the Calendar value
                                            showIcon
                                            showTime
                                            dateFormat="yy-mm-dd"
                                            hourFormat="24"
                                            onSelect={(e) => {
                                                // Format the date when selected and update the input value
                                                const formattedDate = moment(e.value).format("YYYY-MM-DD HH:mm:ss");
                                                input.onChange(formattedDate);
                                            }}
                                            onChange={(e) => {
                                                // Update the input field only if the value is a valid date
                                                if (e.value instanceof Date) {
                                                    const formattedDate = moment(e.value).format("YYYY-MM-DD HH:mm:ss");
                                                    input.onChange(formattedDate);
                                                }
                                            }}
                                        />
                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>
                            <Field name="end_date">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label>End Date</label>
                                        <Calendar
                                            {...input}
                                            value={input.value ? new Date(input.value) : null}
                                            showIcon
                                            showTime
                                            dateFormat="yy-mm-dd"
                                            hourFormat="24"
                                            onChange={(e) => {
                                                // Update the input field only if the value is a valid date
                                                if (e.value instanceof Date) {
                                                    const formattedDate = moment(e.value).format("YYYY-MM-DD HH:mm:ss");
                                                    input.onChange(formattedDate);
                                                }
                                            }}
                                            onSelect={(e) => {
                                                // Format the date when selected and update the input value
                                                const formattedDate = moment(e.value).format("YYYY-MM-DD HH:mm:ss");
                                                input.onChange(formattedDate);
                                            }}
                                        />
                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>
                            <div className="d-grid gap-2">
                                <Button type="submit" label="Save" className="p-button-primary" icon="pi pi-check" />
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    style={{ width: "30vw" }}
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to submit?
                </Dialog>
            </div>
        </div>
    );
}

export default RowForm;
