import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const DepartmentOutcomesDisplayComponent = ({ departmentOutcomes = [] }) => {
    const [departmentOutcomesData, setDepartmentOutcomesData] = useState(departmentOutcomes);
    console.log("departmentOutcomes ffdfdd : ", departmentOutcomes);
    // useEffect(() => {

    //     setDepartmentOutcomesData(departmentOutcomes);
    // }, [departmentOutcomes]);
    return (
        <div>
            <Card key="Outcomes And Outcome Indicators Targets" style={{ marginBottom: "1rem" }}>
                <h5
                    style={{
                        textDecoration: "underline",
                        marginBottom: "10px",
                        fontWeight: "bold",
                    }}
                >
                    Outcomes And Outcome Indicator Targets
                </h5>
                {departmentOutcomes.map((item, index) => (
                    <div key={index} title={`Department Outcome ${index + 1}`}>
                        <p>
                            <strong>Outcome : </strong>
                            {item.departmentOutcome.details.outcome.name}
                        </p>
                        <DataTable value={item.departmentOutcome.departmentOutcomeIndicatorTargets}>
                            <Column field="department_outcome_indicator.outcome_indicator.name" header="Outcome Indicator"></Column>
                            <Column field="base_year" header="Base Year"></Column>
                            <Column
                                field="base_value"
                                header="Base Value"
                                body={(rowData, column) => {
                                    return rowData[column.field] + "%";
                                }}
                            ></Column>
                            <Column field="target_year" header="Target Year"></Column>
                            <Column
                                field="target_value"
                                header="Target Value"
                                body={(rowData, column) => {
                                    return rowData[column.field] + "%";
                                }}
                            ></Column>
                            <Column field="financial_year.name" header="Financial Year"></Column>
                            <Column
                                field="financial_year_target"
                                header="Financial Year Target"
                                body={(rowData, column) => {
                                    return rowData[column.field] + "%";
                                }}
                            ></Column>

                            {/* Add more columns as needed */}
                        </DataTable>
                    </div>
                ))}
            </Card>
        </div>
    );
};

export default DepartmentOutcomesDisplayComponent;
