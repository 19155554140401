import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import LocationFilesPage from "./location-migrations/LocationFilesPage";
import { Panel } from "primereact/panel";
import BreadcrumbNav from "../../../components/mweComponents/BreadcrumbNav";

const DataMigrationsPage = () => {
    return (
        <div style={{ width: "100%" }}>
            <BreadcrumbNav />
            <div className="col-12 xl:col-12">
                <div className="card">
                    <p>Data Migration</p>
                </div>
            </div>
            <Panel header="Data Migration" style={{ marginBottom: "20px" }}>
                <div>
                    <TabView>
                        <TabPanel header="Location">
                            <div>
                                <LocationFilesPage />
                            </div>
                        </TabPanel>
                        <TabPanel header="Other Files">
                            <div>
                                <h2>Other Files Migration</h2>
                                {/* Put your other files migration inputs and functionality here */}
                                <p>Form and inputs for other files data migration.</p>
                                <Button label="Migrate Other Files" icon="pi pi-check" />
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
            </Panel>
        </div>
    );
};

export default DataMigrationsPage;
