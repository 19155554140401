import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";

const SubProjectBudjetOutputsIndicatorsModal = ({ visible, onHide, indicatorData }) => {
    // const [globalFilter, setGlobalFilter] = useState(null);
    const [filteredData, setFilteredData] = useState(indicatorData);
    console.log("indicator data nnnnn : ", indicatorData);
    // useEffect(() => {
    //     setFilteredData(indicatorData);
    // }, [indicatorData]);

    // const renderHeader = () => {
    //     return (
    //         <div className="table-header">
    //             <span className="p-input-icon-left">
    //                 <i className="pi pi-search" />
    //                 <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
    //             </span>
    //         </div>
    //     );
    // };

    // const header = renderHeader();

    return (
        <Dialog header="SubProject Budget Output Indicators" visible={visible} style={{ width: "50vw" }} onHide={onHide} maximizable>
            <DataTable
                value={indicatorData}
                paginator
                rows={10}
                //  globalFilter={globalFilter} header={header}
            >
                <Column field="id" header="ID"></Column>
                <Column field="output_indicator.name" header="Name"></Column>
                {/* <Column field="department_id" header="Department ID"></Column>
                <Column field="department_budget_outputs_id" header="Budget Outputs ID"></Column> */}
                <Column field="output_indicator.measure" header="Measure"></Column>
                {/* Add more columns as necessary */}
            </DataTable>
        </Dialog>
    );
};

export default SubProjectBudjetOutputsIndicatorsModal;
