import React, { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import CreateDepartmentReports from "./widgets/create-department-reports/CreateDepartmentReports.jsx";
import DepartmentReportsList from "./widgets/department-reports/DepartmentReportsList";

function DepartmentReportsViewPage({ loggedInUserData, selectedParentDetail, departmentId, departmentViewPageActiveIndex, departmentViewPageChildActiveIndex, ...props }) {
    // State for active tab index
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <div style={{ width: "100%" }}>
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header="Reports">
                    {/* Content for Project Reports Tab */}
                    <DepartmentReportsList loggedInUserData={loggedInUserData} selectedParentDetail={selectedParentDetail} departmentId={departmentId} />
                </TabPanel>
                <TabPanel header="Create Reports">
                    {/* Content for Create Reports Tab */}
                    <CreateDepartmentReports loggedInUserData={loggedInUserData} selectedParentDetail={selectedParentDetail} departmentId={departmentId} />
                </TabPanel>
            </TabView>
        </div>
    );
}

export default DepartmentReportsViewPage;
