import React, { useState, useRef, useEffect, useMemo } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { Image } from "primereact/image";
import { getAOutComesIndicatorsAndBudgetOutputIndicatorsFromSubProject, postSubprojectOutComesAndBudgetOutputIndicators } from "../../../../services/sub-projects/sub-project-service.js";
import { getAllFinancialYears, getFinancialYearsById, postFinancialYears, updateFinancialYears, deleteFinancialYears } from "../../../../services/financial-years/financial-years-service";
import { getAllQuarters, getQuartersById, postQuarters, updateQuarters, deleteQuarters } from "../../../../services/financial-years/quarters";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { ProgressBar } from "primereact/progressbar";
import { getAllSubprojectFieldData, getSubprojectFieldDataById, postSubprojectFieldData, storeSubprojectFieldData, customUpdateSubProjectFieldData, updateSubprojectFieldData, deleteSubprojectFieldDataById } from "../../../../services/sub-projects/subproject-field-data-service.js";
import { ProgressSpinner } from "primereact/progressspinner";
import { useLocation } from "react-router-dom";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import BreadcrumbNav from "../../../../components/mweComponents/BreadcrumbNav";
import DepartmentOutcomesDisplayComponent from "./widgets/DepartmentOutcomesDisplayComponent";
import { Panel } from "primereact/panel";
import { TabView, TabPanel } from "primereact/tabview";
import { Accordion, AccordionTab } from "primereact/accordion";
import RecommendationsComponent from "./widgets/report-components/recomemdations/Recommendations";
import RiskMitigationComponent from "./widgets/report-components/risks/RiskMitigation";
import { useNavigate } from "react-router-dom";
import { toast as reactToastifyToast } from "react-toastify";

import { getSubprojectLocationWithVillages } from "../../../../services/sub-projects/subprojects-location-service.js";

import i from "icepick";
import _ from "lodash";

let handleOutputIndicatorChangeCache = {};
let handleReasonOfVarianceChangeCache = {};
let handleAssessmentChangeCache = {};

const SubProjectDataCollectionPage = () => {
    const queryClient = useQueryClient();
    const toast = useRef(null);
    const navigate = useNavigate();

    let { state } = useLocation();
    let subprojectDetail = state ? state?.subprojectDetailData : null;
    let subprojectViewPageActiveIndex = state?.subprojectViewPageActiveIndex ? state?.subprojectViewPageActiveIndex : 0;
    let subprojectViewPageChildActiveIndex = state?.subprojectViewPageChildActiveIndex ? state?.subprojectViewPageChildActiveIndex : 0;
    console.log("Subprojects data got from state is : ", subprojectDetail);

    let selectedItemForEdit = state ? state?.selectedItemForEdit : null;
    console.log("Subprojects selectedItemForEdit : ", selectedItemForEdit);

    //================= get subproject locations ===================
    const SubprojectLocationWithVillagesQuery = useQuery(["subproject-with-villages", "subproject-locations", subprojectDetail?.id], () => getSubprojectLocationWithVillages({ subproject_id: subprojectDetail?.id }), {
        enabled: !!subprojectDetail?.id,
        onSuccess: (data) => {
            console.log("fetching subproject-locations data on perfomance reports is : ", data);
        },
        onError: (error) => {
            console.log("Error fetching subproject-locations data on perfomance reports is : ", error);
        },
    });

    // State for each form field
    // State for each form field

    const [financialYear, setFinancialYear] = useState();
    const [quarter, setQuarter] = useState("");
    const [budget, setBudget] = useState(selectedItemForEdit?.approved_budget);
    const [spentBudget, setSpentBudget] = useState(selectedItemForEdit?.spent_funds);
    const [details, setDetails] = useState(selectedItemForEdit?.description);
    const [formSubmissionStatus, setFormSubmissionStatus] = useState("draft");
    const [village, setVillage] = useState();

    const [outputs, setOutputs] = useState([]);
    console.log("Outputs choosen : ", outputs);
    const [clonedOutputs, setClonedOutputs] = useState([]);
    const [outcomes, setOutcomes] = useState([]);
    const [outputAtVillageLevel, setOutputAtVillageLevel] = useState([]);
    console.log("Outputs choosen at Village level : ", outputAtVillageLevel);
    // Additional states
    const [errors, setErrors] = useState({});
    const [pendingData, setPendingData] = useState(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [risks, setRisks] = useState([]);
    const [recommendations, setRecommendations] = useState([]);

    //================ update ==================
    useEffect(() => {
        if (selectedItemForEdit) {
            setBudget(selectedItemForEdit?.approved_budget || "");
            setSpentBudget(selectedItemForEdit?.spent_funds || "");
            setDetails(selectedItemForEdit?.description || "");
            setFinancialYear(selectedItemForEdit?.fyear_id || "");
            setQuarter(selectedItemForEdit?.quarter_id || "");

            // Assuming outputs are an array of objects
            setOutputs(selectedItemForEdit?.budget_outputs || []);

            // Assuming photos is an array of objects with 'photo_url' and 'caption'
            const editedPhotos = selectedItemForEdit?.photos?.map((photo) => {
                return {
                    url: photo.photo_url, // or create a URL object if needed
                    caption: photo.caption,
                };
            });
            setUploadedImages(editedPhotos.map((photo) => photo.url));
            setImageCaptions(
                editedPhotos.reduce((captions, photo, index) => {
                    captions[index] = photo.caption;
                    return captions;
                }, {})
            );
        }
    }, [selectedItemForEdit]);

    // ================== get finacial years =================

    const getAllFinancialYearsQuery = useQuery(["financialYear"], getAllFinancialYears, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching getAllFinancialYears is : ", error);
        },
    });

    // ================= quarters ================

    const getAllQuartersQuery = useQuery(["quarters"], getAllQuarters, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching quarters is : ", error);
        },
    });

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    //===================== post to get getListOftOutComesAndBudgetOutput ====================

    const postToGetListOftOutComesAndBudgetOutputMutation = useMutation(postSubprojectOutComesAndBudgetOutputIndicators, {
        onSuccess: (data) => {
            console.log("data on success getListOftOutComesAndBudgetOutput is : ", data);
            // Check if both outcomes and outputs are empty
            if (data?.data?.departmentOutcomes?.length === 0 && data?.data?.subprojectBudgetOutputs?.length === 0) {
                toast.current.show({ severity: "warn", summary: "No Targets", detail: "The chosen financial year has no targets set. You can't add data for it.", life: 3000 });
                setIsSubmitDisabled(true);
                setOutputs([]);

                return;
            }

            setIsSubmitDisabled(false);
            // Check if only outputs are empty
            if (data?.data?.subprojectBudgetOutputs?.length === 0) {
                toast.current.show({ severity: "warn", summary: "No Outputs", detail: "Outputs have no targets set.", life: 3000 });
                setIsSubmitDisabled(true);
            }

            // Merge new data with existing user inputs
            // if (data?.data?.subprojectBudgetOutputs?.length > 0) {
            //     const newOutputs = data?.data?.subprojectBudgetOutputs?.map((output) => {
            //         const existingOutput = outputs.find((o) => o.subproject_budget_outputs_id === output.subprojectBudgetOutput.id);
            //         return {
            //             ...output,
            //             indicators: output.targets.map((ind) => ({
            //                 ...ind,
            //                 value: existingOutput?.indicators.find((i) => i.id === ind.id)?.value || "",
            //                 reasonOfVariance: existingOutput?.indicators.find((i) => i.id === ind.id)?.reasonOfVariance || "",
            //                 assessment: existingOutput?.indicators.find((i) => i.id === ind.id)?.assessment || "",
            //             })),
            //         };
            //     });
            //     setOutputs(newOutputs);
            // } else {
            //     setOutputs([]);
            // }

            if (data?.data?.subprojectBudgetOutputs?.length > 0) {
                // Step 1: Modify projectDepartmentBudgetOutputs
                let modifiedsubprojectBudgetOutputs = data.data.subprojectBudgetOutputs.map((output) => {
                    const matchingOutput = selectedItemForEdit?.budget_outputs?.find((o) => o.subproject_budget_outputs_id === output.subprojectBudgetOutput.id);

                    return {
                        ...output,
                        targets: output.targets.map((target) => {
                            const matchingIndicator = matchingOutput?.indicators?.find((ind) => ind.subproject_bgt_output_indicator_targets_id === target.id);

                            return {
                                ...target,
                                value: matchingIndicator?.value || target.value || "",
                                reasonOfVariance: matchingIndicator?.reasonOfVariance || target.reasonOfVariance || "",
                                assessment: matchingIndicator?.assessment || target.assessment || "",
                            };
                        }),
                    };
                });

                console.log("modifiedprojectDepartmentBudgetOutputs : ", modifiedsubprojectBudgetOutputs);

                // Step 2: Use the modified data to set newOutputs

                // const newOutputs = data?.data?.projectDepartmentBudgetOutputs.map((output) => {
                const newOutputs = modifiedsubprojectBudgetOutputs.map((output) => {
                    // Find the corresponding output in the existing state
                    const existingOutput = outputs.find((o) => o.subprojectBudgetOutput?.id === output.subprojectBudgetOutput.id || o.subproject_budget_output?.id === output.subprojectBudgetOutput.id);

                    return {
                        ...output,
                        indicators: output.targets.map((ind) => {
                            // Find the corresponding indicator in the existing output
                            const existingIndicator = existingOutput?.indicators.find((i) => i.id === ind.id);

                            return {
                                ...ind,
                                value: existingIndicator?.value || ind?.value || "",
                                reasonOfVariance: existingIndicator?.reasonOfVariance || ind?.reasonOfVariance || "",
                                assessment: existingIndicator?.assessment || ind?.assessment || "",
                            };
                        }),
                    };
                });

                console.log("newOutputs : ", newOutputs);
                setOutputs(newOutputs);
                setOutputAtVillageLevel(newOutputs);
                setClonedOutputs(newOutputs);
            } else {
                setOutputs([]);
                setOutputAtVillageLevel([]);
                setClonedOutputs([]);
            }
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
            console.log("create programs error : ", error);
        },
    });

    console.log("postToGetListOftOutComesAndBudgetOutputMutation kkkk : ", postToGetListOftOutComesAndBudgetOutputMutation);

    const handlePressFilter = async (event) => {
        event.preventDefault();

        postToGetListOftOutComesAndBudgetOutputMutation.mutate({ subproject_id: subprojectDetail?.id, quarter_id: quarter, fyear_id: financialYear });
    };

    const validateForm = () => {
        const validationErrors = {};

        if (!financialYear) validationErrors.financialYear = "Financial Year is required";
        if (!quarter) validationErrors.quarter = "Quarter is required";
        if (!village) validationErrors.village = "Village is required";
        // if (!budget) validationErrors.budget = "Budget is required";
        // if (!spentBudget) validationErrors.spentBudget = "Spent Budget is required";
        if (!budget) {
            validationErrors.budget = "Released Funds is required";
        } else if (budget.toString().replace(/,/g, "").length > 15) {
            validationErrors.budget = "Released Funds cannot exceed 15 digits";
        }

        if (!spentBudget) {
            validationErrors.spentBudget = "Spent Budget is required";
        } else if (spentBudget.toString().replace(/,/g, "").length > 15) {
            validationErrors.spentBudget = "Spent Budget cannot exceed 15 digits";
        }
        if (!details) validationErrors.details = "Details are required";

        // Validate each indicator in outputs
        outputs.forEach((output, outputIndex) => {
            output.indicators.forEach((indicator, indicatorIndex) => {
                if (!indicator.value) {
                    const key = `output_${outputIndex}_indicator_${indicatorIndex}`;
                    // validationErrors[key] = `A value for ${indicator.name} is required`;
                    validationErrors[key] = `A value is required`;
                }
                console.log("xcxcxcxcxcxx  indicator.value : ", indicator);
                console.log("xcxcxcxcxcxx  indicator..target : ", indicator.target);

                if (indicator.value !== indicator.target && !indicator.reasonOfVariance) {
                    const key = `output_${outputIndex}_indicator_${indicatorIndex}_reasonOfVariance`;
                    validationErrors[key] = "Reason of Variance is required when value differs from target";
                }

                // Validation for assessment
                if (!indicator.assessment) {
                    const key = `output_${outputIndex}_indicator_${indicatorIndex}_assessment`;
                    validationErrors[key] = "A value is required";
                }
            });
        });

        // Validate each indicator in budgetOutputsAtVillageLevel for the selected village
        budgetOutputsAtVillageLevel.forEach((villageData) => {
            villageData.outputs.forEach((output, outputIndex) => {
                output.indicators.forEach((indicator, indicatorIndex) => {
                    // Validate value
                    if (!indicator.value) {
                        const key = `village_${villageData.villageId}_output_${outputIndex}_indicator_${indicatorIndex}`;
                        validationErrors[key] = "Value is required";
                    }

                    // Validate each coordinate if villageCoordinates is an array
                    if (Array.isArray(indicator.villageCoordinates)) {
                        indicator.villageCoordinates.forEach((coordinate, coordIndex) => {
                            // if (!coordinate.identifier) {
                            //     validationErrors[`${villageData.villageId}_output_${outputIndex}_indicator_${indicatorIndex}_identifier_${coordIndex}`] = "Identifier is required";
                            // }

                            // if (!coordinate.latitude) {
                            //     validationErrors[`${villageData.villageId}_output_${outputIndex}_indicator_${indicatorIndex}_latitude_${coordIndex}`] = "Latitude is required";
                            // } else if (!isValidLatitude(coordinate.latitude)) {
                            //     validationErrors[`${villageData.villageId}_output_${outputIndex}_indicator_${indicatorIndex}_latitude_${coordIndex}`] = "Invalid Latitude. Uganda coordinates must be between 1.5°S and 4.0°N.";
                            // }

                            if (!isValidLatitude(coordinate.latitude) && coordinate?.longitude) {
                                validationErrors[`${villageData.villageId}_output_${outputIndex}_indicator_${indicatorIndex}_latitude_${coordIndex}`] = "Invalid Latitude. Uganda coordinates must be between 1.5°S and 4.0°N.";
                            }

                            // if (!coordinate.longitude) {
                            //     validationErrors[`${villageData.villageId}_output_${outputIndex}_indicator_${indicatorIndex}_longitude_${coordIndex}`] = "Longitude is required";
                            // } else if (!isValidLongitude(coordinate.longitude)) {
                            //     validationErrors[`${villageData.villageId}_output_${outputIndex}_indicator_${indicatorIndex}_longitude_${coordIndex}`] = "Invalid Longitude. Uganda coordinates must be between 29.5°E and 35.0°E.";
                            // }

                            if (!isValidLongitude(coordinate.longitude) && coordinate?.longitude) {
                                validationErrors[`${villageData.villageId}_output_${outputIndex}_indicator_${indicatorIndex}_longitude_${coordIndex}`] = "Invalid Longitude. Uganda coordinates must be between 29.5°E and 35.0°E.";
                            }
                        });
                    }
                });
            });
        });

        // Validate captions for each uploaded image
        uploadedImages.forEach((image, index) => {
            if (!imageCaptions[index] || imageCaptions[index].trim() === "") {
                const key = `caption_${index}`;
                validationErrors[key] = "A caption is required for each image.";
            }
        });

        return validationErrors;
    };

    //
    // Helper function to validate latitude
    const isValidLatitude = (lat) => {
        const numLat = parseFloat(lat);
        return numLat >= -1.5 && numLat <= 4.0;
    };

    // Helper function to validate longitude
    const isValidLongitude = (lng) => {
        const numLng = parseFloat(lng);
        return numLng >= 29.5 && numLng <= 35.0;
    };

    const handleFinancialYearChange = (e) => {
        setFinancialYear(e.value);
        // Optionally reset errors for this field
        if (errors.financialYear) {
            setErrors({ ...errors, financialYear: null });
        }
    };

    // const handleVillageChange = (e) => {
    //     setVillage(e.value);
    //     // Optionally reset errors for this field
    //     if (errors.village) {
    //         setErrors({ ...errors, village: null });
    //     }
    // };

    console.log("selected village is : ", village);

    const [budgetOutputsAtVillageLevel, setBudgetOutputsAtVillageLevel] = useState([]);
    console.log("budgetOutputsAtVillageLevel : ", budgetOutputsAtVillageLevel);

    const handleVillageChange = (e) => {
        const selectedVillageId = e.value;
        setVillage(selectedVillageId);

        const villageExists = budgetOutputsAtVillageLevel.some((villageData) => villageData.villageId === selectedVillageId);

        if (!villageExists) {
            // Clone the outputs and set each indicator's value to null for the new village
            const newVillageOutputs = outputs.map((output) => {
                return {
                    ...output, // Spread the properties of each output
                    indicators: output.indicators.map((indicator) => {
                        return { ...indicator, value: "" }; // Set value to null for each indicator no putting null was causing issues
                    }),
                };
            });

            // const newVillageOutputs = clonedOutputs.map((output) => {
            //     return {
            //         ...output, // Spread the properties of each output
            //         indicators: output.indicators.map((indicator) => {
            //             return { ...indicator, value: "" }; // Set value to null for each indicator
            //         }),
            //     };
            // });

            const newVillageData = {
                villageId: selectedVillageId,
                outputs: newVillageOutputs,
            };

            setBudgetOutputsAtVillageLevel([...budgetOutputsAtVillageLevel, newVillageData]);
        }
    };

    const handleQuarterChange = (e) => {
        setQuarter(e.value);
        // Optionally reset errors for this field
        if (errors.quarter) {
            setErrors({ ...errors, quarter: null });
        }
    };

    const handleBudgetChange = (e) => {
        setBudget(e.target.value);
        // Optionally reset errors for this field
        if (errors.budget) {
            setErrors({ ...errors, budget: null });
        }
    };

    const handleSpentBudgetChange = (e) => {
        setSpentBudget(e.target.value);
        // Optionally reset errors for this field
        if (errors.spentBudget) {
            setErrors({ ...errors, spentBudget: null });
        }
    };

    const handleDetailsChange = (e) => {
        setDetails(e.target.value);
        // Optionally reset errors for this field
        if (errors.details) {
            setErrors({ ...errors, details: null });
        }
    };

    //===================
    // const handleOutputIndicatorAtVillageLevelChange = (villageId, outputIndex, indicatorIndex, newValue) => {
    //     // Clone the budgetOutputsAtVillageLevel array to create a deep copy
    //     let updatedBudgetOutputs = JSON.parse(JSON.stringify(budgetOutputsAtVillageLevel));

    //     // Find the village object
    //     let villageData = updatedBudgetOutputs.find((v) => v.villageId === villageId);

    //     console.log("villageData : ", villageData);

    //     // Check and update the indicator value
    //     if (villageData && villageData.outputs[outputIndex] && villageData.outputs[outputIndex].indicators[indicatorIndex]) {
    //         villageData.outputs[outputIndex].indicators[indicatorIndex].value = newValue;
    //     }

    //     // Update the state
    //     setBudgetOutputsAtVillageLevel(updatedBudgetOutputs);

    //     // Clear the error related to this output indicator
    //     const errorKey = `village_${villageId}_output_${outputIndex}_indicator_${indicatorIndex}`;
    //     if (errors[errorKey]) {
    //         setErrors({ ...errors, [errorKey]: null });
    //     }
    // };

    // this state helps to handle latitudes , longitudes and identifiers
    const [dynamicFieldData, setDynamicFieldData] = useState({});

    console.log("dynamicFieldData data : ", dynamicFieldData);

    const handleOutputIndicatorAtVillageLevelChange = (villageId, outputIndex, indicatorIndex, newValue, rowData) => {
        // Clone the budgetOutputsAtVillageLevel array to create a deep copy
        let updatedBudgetOutputs = JSON.parse(JSON.stringify(budgetOutputsAtVillageLevel));

        // Update the value for the current village
        let villageData = updatedBudgetOutputs.find((v) => v.villageId === villageId);
        if (villageData && villageData.outputs[outputIndex] && villageData.outputs[outputIndex].indicators[indicatorIndex]) {
            villageData.outputs[outputIndex].indicators[indicatorIndex].value = newValue;

            // Validation
            if (!newValue) {
                setErrors({ ...errors, [`village_${villageId}_output_${outputIndex}_indicator_${indicatorIndex}`]: "Value is required" });
            } else {
                setErrors({ ...errors, [`village_${villageId}_output_${outputIndex}_indicator_${indicatorIndex}`]: null });
            }

            // Update or initialize villageCoordinates array based on newValue
            if (rowData?.project_dept_bgt_output_indicator_target?.department_budget_output_indicator.output_indicator?.tracks_coordinates) {
                const numberOfFields = parseInt(newValue, 10);
                if (!isNaN(numberOfFields) && numberOfFields > 0) {
                    villageData.outputs[outputIndex].indicators[indicatorIndex].villageCoordinates = Array(numberOfFields).fill({ identifier: "", latitude: "", longitude: "" });
                } else {
                    villageData.outputs[outputIndex].indicators[indicatorIndex].villageCoordinates = [];
                }
            }
        }

        // Update the budgetOutputsAtVillageLevel state
        setBudgetOutputsAtVillageLevel([...updatedBudgetOutputs]);

        // Recalculate the total value for this indicator across all villages
        const totalValue = updatedBudgetOutputs.reduce((sum, village) => {
            const output = village.outputs[outputIndex];
            if (output && output.indicators[indicatorIndex]) {
                return sum + parseFloat(output.indicators[indicatorIndex].value || 0);
            }
            return sum;
        }, 0);

        // Update the corresponding indicator's total value in the outputs state
        updateOutputsIndicatorValue(outputIndex, indicatorIndex, totalValue);
    };

    const handleVillageCoordinatesChange = (villageId, outputIndex, indicatorIndex, coordinateIndex, field, value) => {
        let updatedBudgetOutputs = JSON.parse(JSON.stringify(budgetOutputsAtVillageLevel));

        let villageData = updatedBudgetOutputs.find((v) => v.villageId === villageId);
        if (villageData && villageData.outputs[outputIndex] && villageData.outputs[outputIndex].indicators[indicatorIndex]) {
            villageData.outputs[outputIndex].indicators[indicatorIndex].villageCoordinates[coordinateIndex][field] = value;

            // Validation
            if (!value) {
                setErrors({ ...errors, [`${villageId}_output_${outputIndex}_indicator_${indicatorIndex}_${field}_${coordinateIndex}`]: `${field} is required` });
            } else {
                setErrors({ ...errors, [`${villageId}_output_${outputIndex}_indicator_${indicatorIndex}_${field}_${coordinateIndex}`]: null });
            }
        }

        setBudgetOutputsAtVillageLevel([...updatedBudgetOutputs]);
    };

    //

    const updateOutputsIndicatorValue = (outputIndex, indicatorIndex, totalValue) => {
        // Clone the outputs state to create a deep copy
        let updatedOutputs = JSON.parse(JSON.stringify(outputs));

        // Update the total value for the specific indicator in outputs
        if (updatedOutputs && updatedOutputs[outputIndex] && updatedOutputs[outputIndex].indicators && updatedOutputs[outputIndex].indicators[indicatorIndex]) {
            updatedOutputs[outputIndex].indicators[indicatorIndex].value = totalValue.toString();
        }

        // Update the outputs state
        setOutputs(updatedOutputs);
        // setOutputs([]);
    };

    // Function to handle changes in Output indicators
    const handleOutputIndicatorChange = (outputIndex, indicatorIndex, newValue) => {
        let updatedOutputs = [...outputs];
        updatedOutputs[outputIndex].indicators[indicatorIndex].value = newValue;
        setOutputs(updatedOutputs);

        // Clear the error related to this output indicator
        const errorKey = `output_${outputIndex}_indicator_${indicatorIndex}`;
        if (errors[errorKey]) {
            setErrors({ ...errors, [errorKey]: null });
        }
    };

    const handleReasonOfVarianceChange = (outputIndex, indicatorIndex, newValue) => {
        let updatedOutputs = [...outputs];
        updatedOutputs[outputIndex].indicators[indicatorIndex].reasonOfVariance = newValue;
        setOutputs(updatedOutputs);

        // Clear the error related to this field
        const errorKey = `output_${outputIndex}_indicator_${indicatorIndex}_reasonOfVariance`;
        if (errors[errorKey]) {
            setErrors({ ...errors, [errorKey]: null });
        }
    };

    const handleAssessmentChange = (outputIndex, indicatorIndex, newValue) => {
        let updatedOutputs = [...outputs];
        updatedOutputs[outputIndex].indicators[indicatorIndex].assessment = newValue;
        setOutputs(updatedOutputs);

        // Clear the error related to this field
        const errorKey = `output_${outputIndex}_indicator_${indicatorIndex}_assessment`;
        if (errors[errorKey]) {
            setErrors({ ...errors, [errorKey]: null });
        }
    };

    //======================= submit mutate ====================
    const storeSubprojectFieldDataMutation = useMutation(storeSubprojectFieldData, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(["subprojects", "subproject-field-data"]);
            toast.current.show({ severity: "success", summary: "Success", detail: "Subproject field data created successfully", life: 3000 });
            reactToastifyToast.success("Subproject Perfomance report created successfully");
            navigate("/subproject", { state: { subProjectsData: subprojectDetail, subprojectViewPageActiveIndex: subprojectViewPageActiveIndex, subprojectViewPageChildActiveIndex: subprojectViewPageChildActiveIndex } });

            // props.onClose();
            console.log("Create SubprojectFieldData success:", data);
        },
        onError: (error) => {
            // props.onClose();
            let errorMessage = "An error occurred, please contact admin";
            if (error?.response?.data?.message) {
                errorMessage = error.response.data.message;
            }
            toast.current.show({ severity: "error", summary: "Error", detail: errorMessage, life: 3000 });
            console.log("Create SubprojectFieldData error:", error);
        },
    });

    //======================= submit mutate ====================
    const updateSubprojectFieldDataMutation = useMutation(customUpdateSubProjectFieldData, {
        onSuccess: () => {
            queryClient.invalidateQueries(["subprojects", "subproject-field-data"]);
            toast.current.show({ severity: "success", summary: "Success", detail: "Subproject field data Updates successfully", life: 3000 });
            // props.onClose();
        },
        onError: (error) => {
            // props.onClose();
            let errorMessage = "An error occurred, please contact admin";
            if (error?.response?.data?.message) {
                errorMessage = error.response.data.message;
            }
            toast.current.show({ severity: "error", summary: "Error", detail: errorMessage, life: 3000 });
            console.log("update subprojects field error:", error);
        },
    });

    // Function to handle confirmation of form submission
    const onConfirm = () => {
        console.log("Confirmed Data xxxxx ", pendingData);
        //   storeSubprojectFieldDataMutation.mutate(pendingData);
        // Close the dialog
        //   setShowConfirmDialog(false);

        // Create FormData instance
        const formData = new FormData();

        // Append non-file fields
        formData.append("subproject_id", pendingData.subproject_id);
        formData.append("fyear_id", pendingData.fyear_id);
        formData.append("quarter_id", pendingData.quarter_id);
        formData.append("approved_budget", pendingData.approved_budget);
        formData.append("status", pendingData.status);
        formData.append("released_funds", pendingData.released_funds);
        formData.append("spent_funds", pendingData.spent_funds);
        formData.append("description", pendingData.description);
        // Append other non-file fields as needed...

        // Append outcomes and budget outputs as JSON strings

        formData.append("budget_outputs", JSON.stringify(pendingData.budget_outputs));
        formData.append("budget_outputs_at_village", JSON.stringify(pendingData.budget_outputs_at_village));
        // formData.append("outcomes", pendingData.outcomes);
        // formData.append("budget_outputs", pendingData.budget_outputs);

        // Append risks and recommendations as JSON strings
        formData.append("risks", JSON.stringify(risks));
        formData.append("recommendations", JSON.stringify(recommendations));

        // Append images as file array
        // Frontend code snippet for reference
        pendingData.imagesWithCaptions.forEach((item, index) => {
            formData.append(`file[]`, item.image); // Append image
            formData.append(`imagesWithCaptions[${index}][caption]`, item.caption); // Append caption
        });

        console.log("final form data lllllkkkkk : ", formData);
        if (selectedItemForEdit) {
            updateSubprojectFieldDataMutation.mutate(formData);
        } else {
            // Mutate using the FormData
            storeSubprojectFieldDataMutation.mutate(formData);
        }

        // Close the dialog
        setShowConfirmDialog(false);
    };

    // Function to handle cancellation of the submission
    const onCancel = () => {
        // Logic to handle the cancellation
        // For example, you might clear the pending data or provide feedback
        setPendingData(null);
        // Close the dialog
        setShowConfirmDialog(false);
    };

    const validateCaptions = () => {
        const captionErrors = {};
        Object.keys(imageCaptions).forEach((key) => {
            if (!imageCaptions[key].trim()) {
                captionErrors[key] = "Caption is required";
            }
        });
        return captionErrors;
    };

    // ====================================== Function to handle form submission ============================================
    const handleSubmit = (event) => {
        event.preventDefault();

        // First, validate other form fields
        const validationErrors = validateForm();

        const captionErrors = validateCaptions();
        const allErrors = { ...validationErrors, ...captionErrors };

        // Check if there are any validation errors
        if (Object.keys(allErrors).length > 0) {
            // Validation errors exist
            toast.current.show({ severity: "error", summary: "Validation Error", detail: "You still have some invalid fields. Please rectify them.", life: 3000 });
            setErrors(allErrors);
            return; // Return early if there are validation errors
        }

        // Validate that there is at least one risk and one recommendation
        if (risks.length === 0) {
            toast.current.show({ severity: "warn", summary: "Add Risk", detail: "Please add at least one risk.", life: 3000 });
            return;
        }

        if (recommendations.length === 0) {
            toast.current.show({ severity: "warn", summary: "Add Recommendation", detail: "Please add at least one recommendation.", life: 3000 });
            return;
        }

        // Then, check for photo upload
        if (uploadedImages.length === 0) {
            toast.current.show({ severity: "warn", summary: "Photo Required", detail: "At least one photo is required to submit.", life: 3000 });
            return;
        }

        // If there are no validation errors and at least one photo is uploaded
        const imagesWithCaptions = uploadedImages.map((image, index) => ({
            imageName: image.name,
            caption: imageCaptions[index] || "",
        }));

        // const finalData = {
        //     financialYear,
        //     quarter,
        //     budget,
        //     spentBudget,
        //     details,
        //     outputs: outputs.map((output) => ({
        //         ...output,
        //         indicators: output.indicators.map((indicator) => ({
        //             ...indicator,
        //             value: indicator.value,
        //             reasonOfVariance: indicator.reasonOfVariance,
        //             assessment: indicator.assessment,
        //         })),
        //     })),
        //     outcomes: outcomes.map((outcome) => ({
        //         ...outcome,
        //         indicators: outcome.indicators.map((indicator) => ({
        //             ...indicator,
        //             value: indicator.value,
        //         })),
        //     })),
        //     imagesWithCaptions, // Add paired images and captions here
        // };

        // console.log("outcomes : ", outcomes);
        // console.log("outputs : ", outputs);
        // Organize data in the specified structure

        const finalData = {
            subproject_id: subprojectDetail?.id,
            status: formSubmissionStatus,
            fyear_id: financialYear,
            quarter_id: quarter,
            approved_budget: parseFloat(budget),
            released_funds: 0,
            spent_funds: spentBudget,
            description: details,
            risks, // Add the risks array
            recommendations,

            budget_outputs: outputs.map((output) => ({
                subproject_budget_outputs_id: output?.subprojectBudgetOutput?.id,
                subproject_budget_outputs_name: output?.subprojectBudgetOutput?.project_department_budget_output?.department_budget_output?.name,
                budget_outputs_indicators: output.indicators.map((ind) => ({
                    subproject_bgt_output_indicator_targets_id: ind?.id,
                    value: ind?.value,
                    // comment: ind?.comment,
                    assessment: ind?.assessment,
                    reasonOfVariance: ind?.reasonOfVariance,
                })),
            })),

            budget_outputs_at_village: budgetOutputsAtVillageLevel,
            // budget_outputs_at_village: budgetOutputsAtVillageLevel.map((village) => ({
            //     village_id: village?.villageId,
            //     budget_outputs_at_village: village?.outputs.map((output) => ({
            //         subproject_budget_outputs_id: output?.subprojectBudgetOutput?.id,
            //         subproject_budget_outputs_name: output?.subprojectBudgetOutput?.project_department_budget_output?.department_budget_output?.name,
            //         budget_outputs_indicators: output.indicators.map((ind) => ({
            //             subproject_bgt_output_indicator_targets_id: ind?.id,
            //             value: ind?.value,
            //         })),
            //     })),
            // })),

            imagesWithCaptions: uploadedImages.map((image, index) => ({
                image: image, // Including the whole image object
                caption: imageCaptions[index] || "",
            })),
        };

        console.log("Form Data mmmmmm", finalData);
        // Process the form data here (e.g., sending to an API)
        setPendingData(finalData);
        setShowConfirmDialog(true); // If you have a confirmation dialog
    };

    //=========================== image upload =======================
    // Ref for the file upload component
    const fileUploadRef = useRef(null);
    const [uploadedImages, setUploadedImages] = useState([]);
    const onImageUpload = (e) => {
        console.log("Uploaded files:", e.files);
        // Assuming you want to store the uploaded images in state
        // setUploadedImages([...uploadedImages, ...e.files]);
        setUploadedImages([...e.files]);
        // Additional logic for handling the upload can go here

        // If using a ref to a file input or PrimeReact's FileUpload component,
        // use the clear method to reset it
        if (fileUploadRef.current) {
            fileUploadRef.current.clear();
        }
    };

    // Function to remove a single image
    const removeImage = (indexToRemove) => {
        setUploadedImages(uploadedImages.filter((_, index) => index !== indexToRemove));
        // Also remove the caption
        const { [indexToRemove]: _, ...newCaptions } = imageCaptions;
        setImageCaptions(newCaptions);
    };

    // Function to remove all images
    const removeAllImages = () => {
        setUploadedImages([]);
        setImageCaptions({});
    };

    const handleUploadSuccess = () => {
        // Clear the list of uploaded files
        setUploadedImages([]);
        // Additional success logic
    };

    const onImageSelect = (e) => {
        // Check if the number of files selected exceeds 5
        if (e.files.length > 5) {
            toast.current.show({ severity: "warn", summary: "Warning", detail: "You can only upload up to 5 images.", life: 3000 });
            e.files = []; // Clear out the files
            if (fileUploadRef.current) {
                fileUploadRef.current.clear();
            }
            return;
        }

        // Define allowed image types
        const allowedImageTypes = [".jpg", ".jpeg", ".png", ".gif"];
        const isImageFile = e.files.every((file) => allowedImageTypes.some((type) => file.name.toLowerCase().endsWith(type)));

        if (!isImageFile) {
            // Show warning toast if non-image files are uploaded
            toast.current.show({ severity: "warn", summary: "Warning", detail: "Only image files are allowed.", life: 3000 });
            e.files = []; // Clear out the non-image files
            if (fileUploadRef.current) {
                fileUploadRef.current.clear();
            }
        }

        // Set the selected files to state
        // setUploadedImages(e.files);
    };

    const [imageCaptions, setImageCaptions] = useState({});

    console.log("outputs ffff : ", outputs);

    const formatAmount = (amount) => {
        const amountString = amount ? amount.toString() : "";
        const amountNumber = parseFloat(amountString.replace(/,/g, ""));
        return isNaN(amountNumber) ? amount : amountNumber.toLocaleString(undefined, { maximumFractionDigits: 2 });
    };

    // console.log(
    //     "ahdbhfdajch : ",
    //     budgetOutputsAtVillageLevel.find((villageData) => villageData.villageId === village)
    // );

    // Memoize the selected village outputs
    const selectedVillageOutputs = useMemo(() => {
        return budgetOutputsAtVillageLevel.find((villageData) => villageData.villageId === village)?.outputs;
    }, [village, budgetOutputsAtVillageLevel]);

    console.log("selectedVillageOutputs subproject report : ", selectedVillageOutputs);

    return (
        <>
            <BreadcrumbNav />
            <Toast ref={toast} />
            <form onSubmit={handleSubmit}>
                <Panel
                    header={
                        <div>
                            <div>Data Collection Module</div>

                            <div>
                                <strong>Project Name : </strong>
                                {subprojectDetail?.project?.project_title}
                            </div>
                            <div>
                                <strong>Subproject Name : </strong>
                                {subprojectDetail?.name}
                            </div>
                        </div>
                    }
                    style={{ marginBottom: "20px" }}
                >
                    {/* Financial Year and Quarter in their own card */}
                    {!selectedItemForEdit && (
                        <Accordion style={{ marginBottom: "1rem" }}>
                            <AccordionTab header="Period Filters">
                                {/* <Card title="Period" style={{ marginBottom: "1rem" }}> */}
                                <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                    <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column", marginBottom: "1rem", gap: "2rem" }}>
                                        <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                            <label htmlFor="financialYear">Financial Year</label>
                                            <Dropdown
                                                value={financialYear}
                                                onChange={handleFinancialYearChange}
                                                options={getAllFinancialYearsQuery?.data?.data?.data.map((item) => ({
                                                    label: item.name,
                                                    value: item.id,
                                                }))}
                                                placeholder="Select a Financial Year"
                                                disabled={getAllFinancialYearsQuery.isLoading}
                                            />
                                            {getAllFinancialYearsQuery.isLoading && <ProgressSpinner style={{ width: "20px", height: "20px" }} />}
                                            {errors.financialYear && <small className="p-error">{errors.financialYear}</small>}
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                            <label>Quarter</label>
                                            <Dropdown
                                                value={quarter}
                                                onChange={handleQuarterChange}
                                                options={getAllQuartersQuery?.data?.data?.data.map((item) => ({
                                                    label: item.name,
                                                    value: item.id,
                                                }))}
                                                placeholder="Select a Quarter"
                                                disabled={getAllQuartersQuery.isLoading}
                                            />
                                            {getAllQuartersQuery.isLoading && <ProgressSpinner style={{ width: "20px", height: "20px" }} />}
                                            {errors.quarter && <small className="p-error">{errors.quarter}</small>}
                                        </div>

                                        <Button
                                            label="Filter"
                                            icon="pi pi-filter"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                console.log("This Button Saving village level Budget Outputs ");
                                                handlePressFilter(e);
                                            }}
                                            disabled={postToGetListOftOutComesAndBudgetOutputMutation.isLoading || !quarter || !financialYear}
                                        />

                                        {postToGetListOftOutComesAndBudgetOutputMutation.isLoading && (
                                            <div className="m-2">
                                                <ProgressBar mode="indeterminate" />
                                            </div>
                                        )}
                                    </div>{" "}
                                </div>
                                {/* </Card> */}
                            </AccordionTab>
                        </Accordion>
                    )}

                    {selectedItemForEdit && postToGetListOftOutComesAndBudgetOutputMutation.isLoading && postToGetListOftOutComesAndBudgetOutputMutation.isFetching && (
                        <div className="m-2">
                            <ProgressBar mode="indeterminate" style={{ height: "4px" }} />
                        </div>
                    )}

                    <TabView scrollable>
                        <TabPanel header="Budget Details">
                            {/* Budget Details */}
                            <Card title="Budget Details" style={{ marginBottom: "1rem" }}>
                                <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "1rem", width: "100%" }}>
                                        {/* Approved Budget */}
                                        {/* We have changed Approved Budget to released funds */}
                                        <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                            <label htmlFor="budget">Released Funds</label>
                                            <div>{formatAmount(budget)}</div>
                                            <InputText type="number" disabled={isSubmitDisabled} value={budget} onChange={handleBudgetChange} id="budget" placeholder="Released Funds" />
                                            {errors.budget && <small className="p-error">{errors.budget}</small>}
                                        </div>
                                        {/* Spent Budget */}
                                        <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                            <label htmlFor="spentBudget">Spent Budget</label>
                                            <div>{formatAmount(spentBudget)}</div>
                                            <InputText type="number" disabled={isSubmitDisabled} value={spentBudget} onChange={handleSpentBudgetChange} id="spentBudget" placeholder="Spent Budget" />
                                            {errors.spentBudget && <small className="p-error">{errors.spentBudget}</small>}
                                        </div>
                                    </div>
                                    {/* Details */}
                                    <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                        <label htmlFor="details">Details</label>
                                        <InputTextarea disabled={isSubmitDisabled} value={details} onChange={handleDetailsChange} id="details" rows={5} placeholder="Details" />
                                        {errors.details && <small className="p-error">{errors.details}</small>}
                                    </div>
                                </div>
                            </Card>
                        </TabPanel>
                        <TabPanel header="Budget Outputs and Indicators">
                            {/* DepartmentOutcomes */}
                            {/* <DepartmentOutcomesDisplayComponent departmentOutcomes={getListOftOutComesAndBudgetOutput?.data?.data?.departmentOutcomes} /> */}
                            {/* // Outputs and Indicators */}

                            <TabView scrollable>
                                <TabPanel header="Village Level">
                                    <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem", margin: "1rem" }}>
                                        <label htmlFor="village">Village</label>
                                        <Dropdown
                                            value={village}
                                            onChange={handleVillageChange}
                                            disabled={isSubmitDisabled || SubprojectLocationWithVillagesQuery.isLoading}
                                            options={SubprojectLocationWithVillagesQuery?.data?.data?.data.map((item) => ({
                                                label: item.village.name,
                                                value: item?.village?.id,
                                            }))}
                                            placeholder="Select a Village"
                                        />
                                        {SubprojectLocationWithVillagesQuery.isLoading && <ProgressSpinner style={{ width: "20px", height: "20px" }} />}
                                        {errors.village && <small className="p-error">{errors.village}</small>}
                                    </div>

                                    <Card key="Budget Outputs and Indicators" style={{ marginBottom: "1rem" }}>
                                        <h5
                                            style={{
                                                marginBottom: "10px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Budget Outputs And Output Indicators
                                        </h5>

                                        {/* get the outputs of the selected filter and use them to show the input fields */}
                                        {village &&
                                            selectedVillageOutputs &&
                                            selectedVillageOutputs.map((output, outputIndex) => (
                                                <div style={{ marginBottom: "2rem" }} key={outputIndex}>
                                                    <div style={{ display: "flex", gap: "1rem" }}>
                                                        <span>Budget Output : </span>
                                                        <strong> {output?.subprojectBudgetOutput?.project_department_budget_output?.department_budget_output?.name}</strong>
                                                    </div>
                                                    <br />
                                                    <DataTable value={output.indicators}>
                                                        <Column field="project_dept_bgt_output_indicator_target.department_budget_output_indicator.output_indicator.name" header="Indicator" headerStyle={{ backgroundColor: "orange", color: "white" }}></Column>
                                                        <Column field="project_dept_bgt_output_indicator_target.department_budget_output_indicator.output_indicator.measure" header="Measure" headerStyle={{ backgroundColor: "orange", color: "white" }}></Column>
                                                        <Column field="project_dept_bgt_output_indicator_target.department_budget_output_indicator.output_indicator.unit" header="Unit" headerStyle={{ backgroundColor: "orange", color: "white" }}></Column>
                                                        {/* <Column field="financial_year.name" header="Financial Year" headerStyle={{ backgroundColor: "orange", color: "white" }} style={{ whiteSpace: "nowrap" }}></Column>
                                                        <Column field="quarter.name" header="Quarter" headerStyle={{ backgroundColor: "orange", color: "white" }}></Column> */}
                                                        <Column field="target" header="Target" headerStyle={{ backgroundColor: "orange", color: "white" }}></Column>
                                                        <Column field="project_dept_bgt_output_indicator_target.department_budget_output_indicator.output_indicator.question" header="Question" headerStyle={{ backgroundColor: "orange", color: "white" }}></Column>
                                                        {/* <Column
                                                            field="value"
                                                            header="Value"
                                                            headerStyle={{ backgroundColor: "orange", color: "white" }}
                                                            body={(rowData, { rowIndex }) => {
                                                                console.log("testing to see the rowData in the report : ", rowData);
                                                                let value = rowData.value;
                                                                return (
                                                                    <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                                                        <InputText
                                                                            type={rowData?.project_dept_bgt_output_indicator_target?.department_budget_output_indicator?.output_indicator?.measure === "quantitative" ? "number" : "text"}
                                                                            value={value}
                                                                            onChange={(e) => handleOutputIndicatorAtVillageLevelChange(village, outputIndex, rowIndex, e.target.value)}
                                                                            placeholder="Value"
                                                                        />
                                                                        {errors[`village_${village}_output_${outputIndex}_indicator_${rowIndex}`] && <small className="p-error">{errors[`village_${village}_output_${outputIndex}_indicator_${rowIndex}`]}</small>}
                                                                    </div>
                                                                );
                                                            }}
                                                        /> */}

                                                        <Column
                                                            field="value"
                                                            header="Value"
                                                            headerStyle={{ backgroundColor: "orange", color: "white" }}
                                                            body={(rowData, { rowIndex }) => {
                                                                let value = rowData.value;
                                                                const villageData = budgetOutputsAtVillageLevel.find((v) => v.villageId === village);
                                                                const indicator = villageData ? villageData.outputs[outputIndex].indicators[rowIndex] : null;

                                                                return (
                                                                    <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                                                        <InputText
                                                                            type={rowData?.project_dept_bgt_output_indicator_target?.department_budget_output_indicator?.output_indicator?.measure === "quantitative" ? "number" : "text"}
                                                                            value={value}
                                                                            onChange={(e) => handleOutputIndicatorAtVillageLevelChange(village, outputIndex, rowIndex, e.target.value, rowData)}
                                                                            placeholder="Value"
                                                                        />
                                                                        {errors[`village_${village}_output_${outputIndex}_indicator_${rowIndex}`] && <small className="p-error">{errors[`village_${village}_output_${outputIndex}_indicator_${rowIndex}`]}</small>}

                                                                        {indicator &&
                                                                            indicator.villageCoordinates &&
                                                                            indicator.villageCoordinates.map((coordinate, coordIndex) => (
                                                                                <div key={coordIndex} style={{ display: "flex", gap: "1rem" }}>
                                                                                    <div>
                                                                                        <InputText value={coordinate.identifier} onChange={(e) => handleVillageCoordinatesChange(village, outputIndex, rowIndex, coordIndex, "identifier", e.target.value)} placeholder="Identifier" />
                                                                                        {errors[`${village}_output_${outputIndex}_indicator_${rowIndex}_identifier_${coordIndex}`] && (
                                                                                            <small className="p-error">{errors[`${village}_output_${outputIndex}_indicator_${rowIndex}_identifier_${coordIndex}`]}</small>
                                                                                        )}
                                                                                    </div>
                                                                                    <div>
                                                                                        <InputText value={coordinate.latitude} onChange={(e) => handleVillageCoordinatesChange(village, outputIndex, rowIndex, coordIndex, "latitude", e.target.value)} placeholder="Latitude" />
                                                                                        {errors[`${village}_output_${outputIndex}_indicator_${rowIndex}_latitude_${coordIndex}`] && (
                                                                                            <small className="p-error">{errors[`${village}_output_${outputIndex}_indicator_${rowIndex}_latitude_${coordIndex}`]}</small>
                                                                                        )}
                                                                                    </div>
                                                                                    <div>
                                                                                        <InputText value={coordinate.longitude} onChange={(e) => handleVillageCoordinatesChange(village, outputIndex, rowIndex, coordIndex, "longitude", e.target.value)} placeholder="Longitude" />
                                                                                        {errors[`${village}_output_${outputIndex}_indicator_${rowIndex}_longitude_${coordIndex}`] && (
                                                                                            <small className="p-error">{errors[`${village}_output_${outputIndex}_indicator_${rowIndex}_longitude_${coordIndex}`]}</small>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                );
                                                            }}
                                                        />
                                                    </DataTable>
                                                </div>
                                            ))}
                                        {/*
                                        <Button
                                            label="Add Village Budget Output Data"
                                            icon="pi pi-plus"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                console.log("This Button Saving village level Budget Outputs ");
                                            }}
                                            disabled={isSubmitDisabled}
                                        /> */}
                                    </Card>
                                </TabPanel>
                                <TabPanel header="Final">
                                    <Card key="Budget Outputs and Indicators" style={{ marginBottom: "1rem" }}>
                                        <h5
                                            style={{
                                                marginBottom: "10px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Budget Outputs And Output Indicators
                                        </h5>
                                        {outputs.map((output, outputIndex) => (
                                            <div style={{ marginBottom: "2rem" }} key={outputIndex}>
                                                <div style={{ display: "flex", gap: "1rem" }}>
                                                    <span>Budget Output : </span>
                                                    <strong> {output?.subprojectBudgetOutput?.project_department_budget_output?.department_budget_output?.name}</strong>
                                                </div>
                                                <br />
                                                <DataTable value={output.indicators}>
                                                    <Column field="project_dept_bgt_output_indicator_target.department_budget_output_indicator.output_indicator.name" header="Indicator" headerStyle={{ backgroundColor: "orange", color: "white" }}></Column>
                                                    <Column field="project_dept_bgt_output_indicator_target.department_budget_output_indicator.output_indicator.measure" header="Measure" headerStyle={{ backgroundColor: "orange", color: "white" }}></Column>
                                                    <Column field="project_dept_bgt_output_indicator_target.department_budget_output_indicator.output_indicator.unit" header="Unit" headerStyle={{ backgroundColor: "orange", color: "white" }}></Column>
                                                    <Column field="financial_year.name" header="Financial Year" headerStyle={{ backgroundColor: "orange", color: "white" }} style={{ whiteSpace: "nowrap" }}></Column>
                                                    <Column field="quarter.name" header="Quarter" headerStyle={{ backgroundColor: "orange", color: "white" }}></Column>
                                                    <Column field="target" header="Target" headerStyle={{ backgroundColor: "orange", color: "white" }}></Column>
                                                    <Column field="project_dept_bgt_output_indicator_target.department_budget_output_indicator.output_indicator.question" header="Question" headerStyle={{ backgroundColor: "orange", color: "white" }}></Column>
                                                    <Column
                                                        field="value"
                                                        header="Value"
                                                        headerStyle={{ backgroundColor: "orange", color: "white" }}
                                                        body={(rowData, { rowIndex }) => (
                                                            <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                                                <InputText
                                                                    disabled={true}
                                                                    type={rowData?.project_dept_bgt_output_indicator_target?.department_budget_output_indicator?.output_indicator?.measure === "quantitative" ? "number" : "text"}
                                                                    value={rowData.value}
                                                                    onChange={(e) => handleOutputIndicatorChange(outputIndex, rowIndex, e.target.value)}
                                                                    placeholder="Value"
                                                                />
                                                                {errors[`output_${outputIndex}_indicator_${rowIndex}`] && <small className="p-error">{errors[`output_${outputIndex}_indicator_${rowIndex}`]}</small>}
                                                            </div>
                                                        )}
                                                    />
                                                    <Column
                                                        field="reasonOfVariance"
                                                        header="Reason of Variance"
                                                        headerStyle={{ backgroundColor: "orange", color: "white" }}
                                                        body={(rowData, { rowIndex }) =>
                                                            rowData?.value !== rowData?.target ? (
                                                                <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                                                    <InputTextarea value={rowData?.reasonOfVariance} onChange={(e) => handleReasonOfVarianceChange(outputIndex, rowIndex, e.target.value)} rows={1} placeholder="Reason of Variance" />
                                                                    {errors[`output_${outputIndex}_indicator_${rowIndex}_reasonOfVariance`] && <small className="p-error">{errors[`output_${outputIndex}_indicator_${rowIndex}_reasonOfVariance`]}</small>}
                                                                </div>
                                                            ) : (
                                                                "N/A"
                                                            )
                                                        }
                                                    />
                                                    <Column
                                                        field="assessment"
                                                        header="Progress to Date"
                                                        headerStyle={{ backgroundColor: "orange", color: "white" }}
                                                        body={(rowData, { rowIndex }) => (
                                                            <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                                                <InputTextarea value={rowData?.assessment} onChange={(e) => handleAssessmentChange(outputIndex, rowIndex, e.target.value)} rows={1} placeholder="Progress to Date" />
                                                                {errors[`output_${outputIndex}_indicator_${rowIndex}_assessment`] && <small className="p-error">{errors[`output_${outputIndex}_indicator_${rowIndex}_assessment`]}</small>}
                                                            </div>
                                                        )}
                                                    />
                                                    {/* <Column
                                                headerStyle={{ backgroundColor: "orange", color: "white" }}
                                                body={(rowData, { rowIndex }) => {
                                                    // console.log("row data is : ", rowData);
                                                    return (
                                                        <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
                                                            <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                                                <InputText
                                                                    type={rowData?.project_dept_bgt_output_indicator_target?.department_budget_output_indicator?.output_indicator?.measure == "quantitative" ? "number" : "text"}
                                                                    value={rowData.value}
                                                                    onChange={(e) => handleOutputIndicatorChange(outputIndex, rowIndex, e.target.value)}
                                                                    placeholder="Value"
                                                                />
                                                                {errors[`output_${outputIndex}_indicator_${rowIndex}`] && <small className="p-error">{errors[`output_${outputIndex}_indicator_${rowIndex}`]}</small>}
                                                            </div>
                                                            {rowData?.value !== rowData?.target && (
                                                                <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                                                    <InputTextarea value={rowData?.reasonOfVariance} onChange={(e) => handleReasonOfVarianceChange(outputIndex, rowIndex, e.target.value)} rows={1} placeholder="Reason of Variance" />
                                                                    {errors[`output_${outputIndex}_indicator_${rowIndex}_reasonOfVariance`] && <small className="p-error">{errors[`output_${outputIndex}_indicator_${rowIndex}_reasonOfVariance`]}</small>}
                                                                </div>
                                                            )}
                                                            <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                                                <InputTextarea value={rowData?.assessment} onChange={(e) => handleAssessmentChange(outputIndex, rowIndex, e.target.value)} rows={1} placeholder="Progress to Date" />
                                                                {errors[`output_${outputIndex}_indicator_${rowIndex}_assessment`] && <small className="p-error">{errors[`output_${outputIndex}_indicator_${rowIndex}_assessment`]}</small>}
                                                            </div>
                                                        </div>
                                                    );
                                                }}
                                                header="Data"
                                            ></Column> */}
                                                </DataTable>
                                            </div>
                                        ))}
                                    </Card>
                                </TabPanel>
                            </TabView>
                        </TabPanel>
                        <TabPanel header="Risks And Mitigations">
                            <RiskMitigationComponent isSubmitDisabled={isSubmitDisabled} risks={risks} setRisks={setRisks} />
                        </TabPanel>
                        <TabPanel header="Challanges And Recomendations">
                            <RecommendationsComponent isSubmitDisabled={isSubmitDisabled} recommendations={recommendations} setRecommendations={setRecommendations} />
                        </TabPanel>
                        <TabPanel header="Photos">
                            {/* Image Upload*/}
                            <FileUpload disabled={isSubmitDisabled} ref={fileUploadRef} name="images" accept="image/*" customUpload={true} onSelect={onImageSelect} uploadHandler={onImageUpload} multiple emptyTemplate={<p className="m-0">Drag and drop images here to upload , maximum is 5.</p>} />
                            <div className="flex flex-wrap">
                                {uploadedImages.map((image, index) => (
                                    <div key={index} className="p-2">
                                        <Image
                                            // src={URL.createObjectURL(image)}
                                            src={image instanceof File ? URL.createObjectURL(image) : `${process.env.REACT_APP_API_BASE_URL}${image}`}
                                            alt={`Image ${index}`}
                                            preview
                                            width="100"
                                        />
                                        <InputTextarea
                                            value={imageCaptions[index] || ""}
                                            onChange={(e) => {
                                                const newCaptions = { ...imageCaptions, [index]: e.target.value };
                                                setImageCaptions(newCaptions);
                                                // Optionally reset caption error
                                                if (errors[`caption_${index}`]) {
                                                    setErrors({ ...errors, [`caption_${index}`]: null });
                                                }
                                            }}
                                            rows={3}
                                            cols={30}
                                            placeholder="Add a caption..."
                                        />
                                        {errors[`caption_${index}`] && <small className="p-error">{errors[`caption_${index}`]}</small>}
                                        <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-outlined" onClick={() => removeImage(index)} aria-label="Delete" />
                                    </div>
                                ))}
                            </div>
                            {uploadedImages.length > 0 && <Button label="Remove All Photos" icon="pi pi-times" onClick={removeAllImages} className="p-button-text p-button-danger" />}
                            {/* Gallery Component (if applicable) */}
                            {/* <GalleryComponent /> */}
                        </TabPanel>
                        {/* // Outcomes and Indicators */}
                    </TabView>
                </Panel>

                {/* Submit Button */}
                <div style={{ width: "100%", display: "flex", gap: "1rem" }}>
                    {/* <Button type="submit" onClick={() => setFormSubmissionStatus("draft")} label="Save" style={{ marginTop: "2rem" }} disabled={isSubmitDisabled || storeSubprojectFieldDataMutation.isLoading} /> */}
                    <Button type="submit" onClick={() => setFormSubmissionStatus("submitted")} label="Submit" style={{ marginTop: "2rem" }} disabled={isSubmitDisabled || storeSubprojectFieldDataMutation.isLoading} />
                </div>
            </form>

            {(storeSubprojectFieldDataMutation.isLoading || updateSubprojectFieldDataMutation.isLoading) && (
                <center>
                    {" "}
                    <div className="spinner-container">
                        <ProgressSpinner
                            style={{
                                width: "50px",
                                height: "50px",
                                borderWidth: "50px", // Border thickness
                                borderColor: "blue", // Border color
                                animationDuration: "1s",
                            }}
                            strokeWidth="8"
                            animationDuration="1s"
                        />
                    </div>
                </center>
            )}

            {/* Confirmation Dialog */}
            <Dialog
                header="Confirmation"
                visible={showConfirmDialog}
                maximizable
                onHide={onCancel}
                footer={
                    <div>
                        <Button label="Yes" onClick={onConfirm} />
                        <Button label="No" onClick={onCancel} className="p-button-secondary" />
                    </div>
                }
            >
                Are you sure you want to submit this form?
            </Dialog>
        </>
    );
};
export default SubProjectDataCollectionPage;
