import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import setFieldTouched from "final-form-set-field-touched";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { Checkbox } from "primereact/checkbox";

import { Dropdown } from "primereact/dropdown";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { classNames } from "primereact/utils";

import { getAllFinancialYears, getFinancialYearsById, postFinancialYears, updateFinancialYears, deleteFinancialYears } from "../../../../../../services/financial-years/financial-years-service";
import { getAllQuarters, getQuartersById, postQuarters, updateQuarters, deleteQuarters } from "../../../../../../services/financial-years/quarters";

import { getAllDepartmentOutcomeIndicators, getDepartmentOutcomeIndicatorById, postDepartmentOutcomeIndicator, updateDepartmentOutcomeIndicator, deleteDepartmentOutcomeIndicatorById } from "../../../../../../services/departments/departmentOutcomeIndicator-service.js";
import { getAllDepartmentBudjetOutputs, getDepartmentBudjetOutputById, postDepartmentBudjetOutput, updateDepartmentBudjetOutput, deleteDepartmentBudjetOutputById } from "../../../../../../services/departments/departments-budget-outputs-service.js";
import { getAllProjectDepartmentBudgetOutputs, getProjectDepartmentBudgetOutputById, postProjectDepartmentBudgetOutput, updateProjectDepartmentBudgetOutput, deleteProjectDepartmentBudgetOutputById } from "../../../../../../services/projects/project-department-budget-outputs-service.js";
import { deleteProjectById, getAllProjects, getProjectsByProjectIdOrRole, getProjectsByDepartmentIdAndRole } from "../../../../../../services/projects/project-service";
import { deleteSubProjectById, getAllSubProjects, getAllSubProjectsUnderUserProjectsByProjectIdOrRole } from "../../../../../../services/sub-projects/sub-project-service";
import { getAllSubprojectBudgetOutputs, getSubprojectBudgetOutputById, postSubprojectBudgetOutput, updateSubprojectBudgetOutput, deleteSubprojectBudgetOutputById } from "../../../../../../services/sub-projects/subproject-budget-outputs-service";
import {
    getAllProjectBudgetOutputIndicatorTargets,
    getProjectBudgetOutputIndicatorTargetById,
    postProjectBudgetOutputIndicatorTarget,
    updateProjectBudgetOutputIndicatorTarget,
    deleteProjectBudgetOutputIndicatorTargetById,
} from "../../../../../../services/projects/project-budget-outputs-targets-service.js";

import { ProgressSpinner } from "primereact/progressspinner";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressBar } from "primereact/progressbar";

function RowForm({ handleSubmit, initialData, selectedParentItem, project_id, selectedprojectItem, subprojectDetail, ...props }) {
    console.log("initial data xxxxxx  : ", initialData);
    console.log("selectedParentItem zzxxxxcccc : ", selectedParentItem);
    console.log("vvbsbsb selectedprojectItem : ", selectedprojectItem);
    console.log("vvbsbsb subprojectDetail : ", subprojectDetail);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);
    const queryClient = useQueryClient();
    const [financialYear, setFinancialYear] = useState(initialData?.fyear_id);
    const [quarter, setQuarter] = useState(initialData?.quarter_id);
    // const [subprojectId, setSubprojectId] = useState(initialData.subproject_id || null);
    const [projectId, setProjectId] = useState(selectedParentItem?.subproject?.project_id);

    // ================== get finacial years =================

    const getAllFinancialYearsQuery = useQuery(["financialYear"], getAllFinancialYears, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching getAllFinancialYears is : ", error);
        },
    });

    // ================= quarters ================

    const getAllQuartersQuery = useQuery(["quarters"], getAllQuarters, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching quarters is : ", error);
        },
    });

    // 'department_indicator_id',
    //     'fyear_id',
    //     'quarter_id',
    //     'baseline',
    //     'actual',
    //     'target',
    //     'reason_of_variance',
    //     'created_by',
    //     'updated_by'

    //  /* // 'base_year', // 'base_value', // 'target_year', // 'target_value', // 'financial_year_target', */

    const validate = (values) => {
        const errors = {};

        if (!values.subproject_budget_outputs_id) errors.subproject_budget_outputs_id = "Department Budget Output is required";
        if (!values.pjt_dept_bdgt_output_indicator_targets_id) errors.pjt_dept_bdgt_output_indicator_targets_id = "Budget Output Indicator is required";
        if (!values.fyear_id) errors.fyear_id = "financial year is required";
        if (!values.quarter_id) errors.quarter_id = "Quarter is required";
        if (!values.target) errors.target = "Target is required";
        if (!values.subproject_id) errors.subproject_id = "A Sub Project is required";

        // Conditional validation for manual_value
        // if (values.show_manual_value && !values.manual_value) {
        //     errors.manual_value = "Manual value is required when Show Manual Value is checked";
        // }

        return errors;
    };

    // const onSubmitForm = (data) => {
    //     const errors = validate(data);
    //     if (Object.keys(errors).length === 0) {
    //         // No validation errors
    //         setPendingData(data);
    //         setShowConfirmDialog(true);
    //     } else {
    //         toast.warning("First Fill In All Required Fields");
    //     }
    // };

    const onSubmitForm = (data, form) => {
        // Add 'form' as an argument
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            // Mark all fields as touched to show validation errors
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("First fill in all required fields.");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    //======================Subproject budget Outputs =====================

    const getAllSubprojectBudgetOutputsQuery = useQuery(["subprojects", "budgetOutputs", subprojectDetail?.id], () => (!subprojectDetail?.id ? getAllSubprojectBudgetOutputs() : getAllSubprojectBudgetOutputs({ subproject_id: subprojectDetail?.id })), {
        onSuccess: (data) => {
            console.log("fetchAllProjectDepartmentBudjetOutputs : ", data);
        },
        onError: (error) => {
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
            console.log("Error fetching projectSubprojectMetricsIndicator is : ", error);
        },
    });

    if (selectedParentItem) {
        initialData = { subproject_budget_outputs_id: selectedParentItem?.id, ...initialData };
    }

    // ========================== fetch all sub projects =======================
    const fetchAllSubProjects = useQuery(["subprojects", subprojectDetail?.project_id], () => getAllSubProjects({ project_id: subprojectDetail?.project_id }), {
        onSuccess: (data) => {
            console.log("getAllProjects onsuccess fetching projects dsfsd : ", data);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
            console.log("get list of projects : ", error);
        },
    });

    if (selectedParentItem) {
        initialData = { subproject_id: selectedParentItem?.subproject_id, ...initialData };
    }

    console.log("hhhhhhhh1 department_budget_outputs_id", selectedParentItem?.project_department_budget_output?.department_budget_output_id);
    console.log("hhhhhhhh1 project_id", selectedParentItem?.project_department_budget_output?.project_id);
    console.log("hhhhhhhh1 fyear_id", financialYear);
    console.log("hhhhhhhh1 quarter_id", quarter);

    //===================== project budget output indicator targets ===================
    const fetchAllProjectBudgetOutputIndicatorTargetsQuery = useQuery(
        ["projects", "project-budget-outputs", "project-budget-outputs-indicator-target", selectedParentItem?.project_department_budget_output?.department_budget_output_id, selectedParentItem?.project_department_budget_output?.project_id, financialYear, quarter],
        () => {
            return getAllProjectBudgetOutputIndicatorTargets({
                project_department_budget_outputs_id: selectedParentItem?.project_department_budget_outputs_id,
                project_id: selectedParentItem?.project_department_budget_output?.project_id,
                fyear_id: financialYear,
                quarter_id: quarter,
            });
        },
        {
            enabled: !!financialYear && !!quarter && !!projectId,
            onSuccess: (data) => {
                console.log("fetching subprojects project-budget-outputs-indicator-target data is : ", data);
                // Check if the data is empty
                if (!data || data.length === 0 || data.data.data.length === 0) {
                    // Adjust this condition based on your data structure
                    toast.info("No project targets found for that period.", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 5000,
                    });
                }
            },
            onError: (error) => {
                console.log("Error fetching project-budget-outputs-indicator-target is : ", error);
                toast.error("An error occurred while fetching data.", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                });
            },
        }
    );

    // Define columns for your DataTable
    // const columns = [
    //     { field: "department_indicator_id", header: "Indicator ID" },
    //     { field: "fyear_id", header: "Financial Year" },
    //     { field: "quarter_id", header: "Quarter" },
    //     { field: "target", header: "Target" },
    //     // Add more columns as needed
    // ];

    // // Function to render the body of each column
    // const dynamicColumns = columns.map((col, i) => {
    //     return <Column key={col.field} field={col.field} header={col.header} />;
    // });

    //================ Table pagination =====================
    const [first, setFirst] = useState(0); // First record in the current page
    const [rows, setRows] = useState(10); // Number of rows per page
    // Update this function according to how your data is structured
    const totalRecords = fetchAllProjectBudgetOutputIndicatorTargetsQuery?.data?.data?.data?.length || 0;

    return (
        <div className="col-12 md:col-12">
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    initialValuesEqual={() => true}
                    // initialValuesEqual with a function returning true prevents the form from
                    // reinitializing when the initialValues prop changes. This is useful when you
                    // want to avoid re-rendering or reinitializing the form due to changes in initial values.
                    // Be cautious using this if your initial values are meant to be dynamic and responsive
                    // to changes in your application's state.
                    mutators={{ setFieldTouched }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                console.log("values hhh : ", values);
                                console.log("event fffff : ", event);
                                onSubmitForm(values, form);
                                // handleSubmit(event, values);
                            }}
                        >
                            <div style={{ marginBottom: "1rem" }}>
                                <Field name="subproject_id">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Sub Project</label>
                                            <Dropdown
                                                value={input.value}
                                                options={fetchAllSubProjects?.data?.data?.data.map((item) => ({
                                                    label: item?.name,
                                                    value: item?.id,
                                                }))}
                                                onChange={(e) => {
                                                    input.onChange(e.value); // Update the field value
                                                    // Find the selected subproject
                                                    const selectedSubProject = fetchAllSubProjects?.data?.data?.data.find((item) => item.id === e.value);

                                                    // Update the project ID state
                                                    setProjectId(selectedSubProject?.project_id);

                                                    form.change("pjt_dept_bdgt_output_indicator_targets_id", undefined); // Clear the dependent field value
                                                }}
                                                placeholder="Select Project"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                disabled={fetchAllSubProjects.isLoading}
                                            />
                                            {fetchAllSubProjects.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div style={{ marginBottom: "1rem" }}>
                                <Field name="subproject_budget_outputs_id">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Budget Output</label>
                                            <Dropdown
                                                value={input.value}
                                                options={getAllSubprojectBudgetOutputsQuery?.data?.data?.data.map((item) => ({
                                                    label: item?.project_department_budget_output?.department_budget_output?.name,
                                                    value: item?.id,
                                                }))}
                                                onChange={(e) => input.onChange(e.value)}
                                                disabled={getAllSubprojectBudgetOutputsQuery?.isLoading}
                                                // disabled={props?.loggedInUserData?.role !== "Admin"}
                                                placeholder="Select Budget Output"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                            {getAllSubprojectBudgetOutputsQuery.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            {/* filter() iterates over the array and returns a new array containing only those elements
                             that satisfy the provided testing function. findIndex() is used to find the index of
                             the first element in the array that satisfies the provided testing function.
                             This helps in identifying the unique combination of project_id, project_department_budget_outputs_id,
                             and department_budget_output_indicators_id. The combination of these methods ensures that each distinct
                             combination of the three fields is included only once in the resulting array. */}

                            <Field name="fyear_id">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label>Financial Year</label>
                                        <Dropdown
                                            value={input.value}
                                            options={getAllFinancialYearsQuery?.data?.data?.data.map((item) => ({
                                                label: item.name,
                                                value: item.id,
                                            }))}
                                            onChange={(e) => {
                                                input.onChange(e.value); // Update the field value
                                                setFinancialYear(e.value); // Update the state
                                                form.change("pjt_dept_bdgt_output_indicator_targets_id", undefined); // Clear the dependent field value
                                            }}
                                            placeholder="Select Financial Year"
                                            disabled={getAllFinancialYearsQuery.isLoading}
                                        />
                                        {getAllFinancialYearsQuery.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>
                            <Field name="quarter_id">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label>Quarter</label>
                                        <Dropdown
                                            value={input.value}
                                            options={getAllQuartersQuery?.data?.data?.data.map((item) => ({
                                                label: item.name,
                                                value: item.id,
                                            }))}
                                            onChange={(e) => {
                                                input.onChange(e.value);
                                                setQuarter(e.value); // Update the state here
                                                form.change("pjt_dept_bdgt_output_indicator_targets_id", undefined);
                                            }}
                                            placeholder="Select Quarter"
                                            disabled={getAllQuartersQuery.isLoading}
                                        />
                                        {getAllQuartersQuery.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>
                            {fetchAllProjectBudgetOutputIndicatorTargetsQuery.isLoading && fetchAllProjectBudgetOutputIndicatorTargetsQuery.isFetching && (
                                <div className="m-2">
                                    <ProgressBar mode="indeterminate" style={{ height: "4px" }} />
                                </div>
                            )}
                            {/* DataTable component */}
                            {financialYear && quarter && projectId && !(fetchAllProjectBudgetOutputIndicatorTargetsQuery.isLoading && fetchAllProjectBudgetOutputIndicatorTargetsQuery.isFetching) && (
                                <div>
                                    {/* <div className="card"> */}
                                    <DataTable
                                        value={fetchAllProjectBudgetOutputIndicatorTargetsQuery?.data?.data?.data}
                                        // value={[{ code: "01", name: "sweater", category: "clothing", quantity: 23 }]}
                                        responsiveLayout="scroll"
                                        header={<h6>Project Targets</h6>}
                                        paginator
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                        first={first}
                                        rows={rows}
                                        totalRecords={totalRecords}
                                        onPage={(e) => {
                                            setFirst(e.first);
                                            setRows(e.rows);
                                        }}
                                    >
                                        {/* <Column field="code" header="Code"></Column>
                                        <Column field="name" header="Name"></Column>
                                        <Column field="category" header="Category"></Column>
                                        <Column field="quantity" header="Quantity"></Column> */}
                                        <Column key="project_department_budget_output.department_budget_output.name" field="project_department_budget_output.department_budget_output.name" header="Budget Output" />
                                        <Column key="department_budget_output_indicator.output_indicator.name" field="department_budget_output_indicator.output_indicator.name" header="Output Indicator" />
                                        <Column key="fyear_id" field="financial_year.name" header="Financial Year" />
                                        <Column key="quarter_id" field="quarter.name" header="Quarter" />
                                        <Column key="target" field="target" header="Target" />
                                    </DataTable>
                                    {/* </div> */}
                                </div>
                            )}

                            <Field name="pjt_dept_bdgt_output_indicator_targets_id">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label>Output indicator</label>
                                        <Dropdown
                                            value={input.value}
                                            options={fetchAllProjectBudgetOutputIndicatorTargetsQuery?.data?.data?.data?.map((item) => ({
                                                label: item?.department_budget_output_indicator?.output_indicator?.name,
                                                value: item?.id,
                                            }))}
                                            onChange={(e) => input.onChange(e.value)}
                                            // disabled={getAllDepartmentBudgetOutputsIndicatorsQuery?.isLoading}
                                            // disabled={props?.loggedInUserData?.role !== "Admin"}
                                            placeholder="Select Budget Output Indicator"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                        />
                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>
                            <Field name="target">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label>Target</label>
                                        <InputText
                                            {...input}
                                            id="target"
                                            type="number"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                // Allow only non-negative numbers
                                                if (!value || parseFloat(value) >= 0) {
                                                    input.onChange(value);
                                                }
                                            }}
                                        />
                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            {/* <Field name="show_manual_value">
                                {({ input, meta }) => (
                                    <div className="p-field-checkbox m-4">
                                        <Checkbox inputId="show_manual_value" checked={input.value} onChange={(e) => input.onChange(e.checked)} />
                                        <label htmlFor="show_manual_value">Show Manual Value</label>
                                    </div>
                                )}
                            </Field> */}

                            {/* Conditionally render the manual_value field based on show_manual_value */}
                            {/* <Field name="manual_value">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="manual_value">Manual Value</label>
                                            <InputTextarea {...input} id="manual_value" type="text" rows={4} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field> */}
                            {/* {values.show_manual_value && (
                                <Field name="manual_value">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="manual_value">Manual Value</label>
                                            <InputText {...input} id="name" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                            {meta.touched && (meta.error || form.submitSuccess || form.submitError) && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            )} */}
                            <div className="d-grid gap-2">
                                <Button type="submit" label="Save" className="p-button-primary" icon="pi pi-check" />
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    style={{ width: "30vw" }}
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to submit?
                </Dialog>
            </div>
        </div>
    );
}

export default RowForm;
