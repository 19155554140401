// RiskMitigation.js
import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import RowFormModal from "./RowFormModal";

const RiskMitigation = ({ risks, setRisks, isSubmitDisabled }) => {
    // const [risks, setRisks] = useState([]);
    const [showForm, setShowForm] = useState(false);

    const addRisk = (riskData) => {
        // Generate a unique ID for each risk
        setRisks([...risks, { id: risks.length + 1, ...riskData }]);
    };

    const deleteRisk = (id) => {
        setRisks(risks.filter((risk) => risk.id !== id));
    };

    const actionBodyTemplate = (rowData, index) => {
        return (
            <Button
                label="Delete"
                icon="pi pi-times"
                className="p-button-danger"
                onClick={(e) => {
                    e.preventDefault();
                    deleteRisk(rowData.id);
                }}
            />
        );
    };

    const riskTypeBodyTemplate = (rowData) => {
        let color;
        switch (rowData.risk_type) {
            case "high":
                color = "red";
                break;
            case "moderate":
                color = "orange";
                break;
            case "low":
                color = "grey";
                break;
            default:
                color = "black";
        }

        return (
            <div>
                <span className="p-mr-2" style={{ fontSize: "1.5rem", color, fontWeight: "bold" }}>
                    •
                </span>
                {rowData.risk_type.charAt(0).toUpperCase() + rowData.risk_type.slice(1)}
            </div>
        );
    };

    return (
        <div>
            <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end" }}>
                <Button
                    label="Add Risk"
                    icon="pi pi-plus"
                    onClick={(e) => {
                        e.preventDefault();
                        setShowForm(true);
                    }}
                    disabled={isSubmitDisabled}
                />
            </div>

            <DataTable value={risks}>
                <Column field="risk" header="Risk"></Column>
                <Column field="risk_type" header="Risk Type" body={riskTypeBodyTemplate}></Column>
                <Column field="mitigation" header="Mitigation"></Column>
                <Column body={actionBodyTemplate}></Column>
            </DataTable>

            {showForm && <RowFormModal onHide={() => setShowForm(false)} onSubmit={addRisk} />}
        </div>
    );
};

export default RiskMitigation;
