import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import CreateForm from "./CreateForm";

import EditForm from "./EditForm";

import { deleteProgramById, getAllPrograms } from "../../../../../../services/programs/program-service";

import moment from "moment";

import { Link } from "react-router-dom";

import { getAllDepartmentUtilityGazettes, getDepartmentUtilityGazetteById, postDepartmentUtilityGazette, updateDepartmentUtilityGazette, deleteDepartmentUtilityGazetteById } from "../../../../../../services/departments/utility-dept/departments-utility-gazette-service";

import WaterIsLoading from "../../../../../../components/mweComponents/WaterIsLoading";
import MuiTable from "../../../../../../components/mweComponents/MuiTable";
import { toast } from "react-toastify";
import { Button } from "primereact/button";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

import { Panel } from "primereact/panel";
//
import * as XLSX from "xlsx";

function ListPage({ loggedInUserData, selectedParentItem, ...props }) {
    const queryClient = useQueryClient();

    console.log("selectedParentItem subproject .... : ", selectedParentItem);

    console.log("selectedParentItem ggggg : ", selectedParentItem);
    const { data, isLoading, isError, error, status } = useQuery(["gazettes", selectedParentItem?.id], () => (!selectedParentItem?.id ? getAllDepartmentUtilityGazettes() : getAllDepartmentUtilityGazettes({ utility_id: selectedParentItem?.id })), {
        onSuccess: (data) => {
            console.log("fetching gazettes data is : ", data);
        },
        onError: (error) => {
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
            console.log("Error fetching gazettes is : ", error);
        },
    });

    const DeleteSelectedItemMutation = useMutation((variables) => deleteDepartmentUtilityGazetteById(variables), {
        onSuccess: (data) => {
            toast.success("Gazette Location Deleted Successfully");
            queryClient.invalidateQueries(["gazettes"]);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
            console.log("create gazettes error : ", error);
        },
    });

    // const handleDelete = async (event, id) => {
    //     var result = window.confirm("Are you sure you want to delete?");
    //     if (result === true) {
    //         ProgramDeleteMutation.mutate(id);
    //     }
    // };

    const handleDelete = (event, id) => {
        let selectedDeleteId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(selectedDeleteId),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (selectedDeleteId) => {
        if (selectedDeleteId !== null) {
            DeleteSelectedItemMutation.mutate(selectedDeleteId);
        }
    };

    const cancelDelete = () => {
        // setDeleteProgramId(null);
    };

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showBudjetOutPutAddForm, setShowBudjetOutPutAddForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const activeUser = loggedInUserData ?? localStorage.getItem("profile") ?? undefined;
    console.log("loggedInUserData on sublocations : ", loggedInUserData);

    const onFormClose = () => {
        setShowAddForm(false);
    };

    const onBudjetOutPutFormClose = () => {
        setShowBudjetOutPutAddForm(false);
    };

    // =========================== download to excell ===================
    let excelldata = data?.data?.data;
    const downloadExcel = () => {
        const newData = excelldata.map((row) => {
            delete row.tableData;
            return row;
        });
        const workSheet = XLSX.utils.json_to_sheet(newData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "Locations");
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
        //Download
        XLSX.writeFile(workBook, "GazetteData.xlsx");
    };

    // 'department_indicator_id',
    //     'fyear_id',
    //     'quarter_id',
    //     'baseline',
    //     'actual',
    //     'target',
    //     'reason_of_variance',
    //     'created_by',
    //     'updated_by'

    // departmentIndicator;
    // financialYear;
    // quarter;
    // createdBy;
    // updatedBy;
    //  /* // 'base_year', // 'base_value', // 'target_year', // 'target_value', // 'financial_year_target', */

    let tableId = 0;
    let columns = [
        {
            title: "#",
            width: "5%",
            field: "id",
            render: (rowData) => {
                // tableId = rowData.tableData.id;
                tableId = tableId++;
                return <div>{rowData.tableData.index + 1}</div>;
                // return <div>{rowData.tableData.id}</div>;
            },
        },
        {
            title: "System Name",
            field: "name",
            cellStyle: {
                whiteSpace: "nowrap",
                padding: "8px",
            },
        },

        {
            title: "District Name",
            field: "district.name",
            cellStyle: {
                whiteSpace: "nowrap",
                padding: "8px",
            },
        },
        {
            title: "County Name",
            field: "county.name",
        },
        {
            title: "Sub County Name",
            field: "sub_county.name",
        },
        {
            title: "Parish Name",
            field: "parish.name",
        },
        {
            title: "Village Name",
            field: "village.name",
        },

        {
            title: "Latitude",
            field: "latitude",
            render: (rowData) => {
                return <div>{rowData?.latitude ? rowData?.latitude : "N/A"}</div>;
            },
        },
        {
            title: "Longitude",
            field: "longitude",
            render: (rowData) => {
                return <div>{rowData?.longitude ? rowData?.longitude : "N/A"}</div>;
            },
        },
        {
            title: "Year of Construction",
            field: "year_of_construction",
            hidden: true, // Set to false to show the column
            render: (rowData) => {
                // Check if rowData.year_of_construction exists and is not null
                if (rowData.year_of_construction) {
                    // Extract and return only the year part from the date
                    const year = new Date(rowData.year_of_construction).getFullYear();
                    return <div>{year}</div>;
                } else {
                    // Return 'N/A' if there is no date provided
                    return <div>N/A</div>;
                }
            },
        },
        {
            title: "Assesment",
            field: "assessment",
            hidden: true,
        },
        {
            title: "Utility",
            field: "utility.name",
            hidden: true,
        },
        {
            title: "Date",
            field: "created_at",
            hidden: true,
            render: (rowData) => moment(rowData.created_at).format("lll"),
        },
    ];

    //
    // Conditionally add new columns if department code is "03"
    if (selectedParentItem?.code === "NSWC") {
        columns = columns.concat([
            {
                title: "Region",
                field: "region",
                cellStyle: {
                    whiteSpace: "nowrap",
                    padding: "8px",
                },
            },
            {
                title: "Administrative Area",
                field: "administrative_area",
                cellStyle: {
                    whiteSpace: "nowrap",
                    padding: "8px",
                },
            },
            {
                title: "Town",
                field: "town",
                cellStyle: {
                    whiteSpace: "nowrap",
                    padding: "8px",
                },
            },
        ]);
    }

    console.log("selectedParentItem : ", selectedParentItem);
    return (
        <div style={{ width: "100%" }}>
            {/* <div className="col-12 xl:col-12">
                <div className="card">
                    <p>Gazette</p>
                </div>
            </div> */}
            <Panel header="Gazette" style={{ marginBottom: "20px" }}>
                {/* <div className="d-flex justify-content-end mb-3" style={{ width: "100%" }}>
                    {activeUser?.permissions.includes("add programs") && <Button label="Add Program" className="p-button-primary right-0" onClick={() => setShowAddForm(true)} />}
                    <CreateForm show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} />
                </div> */}

                <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                    {/* {activeUser?.permissions.includes("add programs") && (
                        <Button
                            label="Add Budget Outputs"
                            className="p-button-primary"
                            onClick={() => {
                                setShowBudjetOutPutAddForm(true);
                                console.log("clicked add outcomes");
                            }}
                        />
                    )} */}
                    {activeUser?.permissions?.includes("add reports") && <Button label="Add Location" className="p-button-primary" onClick={() => setShowAddForm(true)} />}
                    <CreateForm show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} projectId={props?.projectId} selectedParentItem={selectedParentItem} />
                    {/* <BudgetOutPutsCreateForm show={showBudjetOutPutAddForm} onHide={() => setShowBudjetOutPutAddForm(false)} onClose={onBudjetOutPutFormClose} /> */}
                </div>

                <MuiTable
                    tableTitle="Water Supply Schemes"
                    tableData={data?.data?.data}
                    tableColumns={columns}
                    handleShowEditForm={handleShowEditForm}
                    handleDelete={(e, item_id) => handleDelete(e, item_id)}
                    showEdit={activeUser?.permissions.includes("edit reports") || activeUser?.permissions.includes("add reports")}
                    showDelete={activeUser?.permissions.includes("edit reports")}
                    loading={isLoading || status === "loading" || DeleteSelectedItemMutation.isLoading}
                    excelexporting={true}
                    downloadExcel={downloadExcel}
                    exportButton={true}
                    pdfExportTitle={"Gazette Data"}
                    csvExportTitle={"Gazette Data"}
                />

                {selectedItem && <EditForm rowData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} selectedParentItem={selectedParentItem} />}

                {DeleteSelectedItemMutation.isLoading && <WaterIsLoading />}
                {/* <ConfirmDialog /> */}
            </Panel>
        </div>
    );
}

export default ListPage;
