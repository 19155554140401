import axiosAPI from "../axiosApi";

export async function getAllDepartmentBudjetOutputIndicators(params = {}) {
    const response = await axiosAPI.get("departs-budget-outputIndictors", { params: params });
    return response;
}

export async function getDepartmentBudjetOutputIndicatorById(id) {
    const response = await axiosAPI.get(`departs-budget-outputIndictors/${id}`);
    return response;
}

export async function postDepartmentBudjetOutputIndicator(data) {
    const response = await axiosAPI.post(`departs-budget-outputIndictors`, data);
    return response;
}

export async function updateDepartmentBudjetOutputIndicator(id, data) {
    const response = await axiosAPI.put(`departs-budget-outputIndictors/${id}`, data);
    return response;
}

export async function deleteDepartmentBudjetOutputIndicatorById(id) {
    const response = await axiosAPI.delete(`departs-budget-outputIndictors/${id}`);
    return response;
}
