import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import setFieldTouched from "final-form-set-field-touched";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { ProgressSpinner } from "primereact/progressspinner";

import { Dropdown } from "primereact/dropdown";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteProjectById, getAllProjects, getProjectsByProjectIdOrRole, getProjectsByDepartmentIdAndRole } from "../../../../../../../../services/projects/project-service";
import { getAllDepartments } from "../../../../../../../../services/departments/department-service";
import { classNames } from "primereact/utils";
import { getAllDepartmentBudjetOutputs, getDepartmentBudjetOutputById, postDepartmentBudjetOutput, updateDepartmentBudjetOutput, deleteDepartmentBudjetOutputById } from "../../../../../../../../services/departments/departments-budget-outputs-service.js";
import { getAllFinancialYears, getFinancialYearsById, postFinancialYears, updateFinancialYears, deleteFinancialYears } from "../../../../../../../../services/financial-years/financial-years-service";
import { getAllQuarters, getQuartersById, postQuarters, updateQuarters, deleteQuarters } from "../../../../../../../../services/financial-years/quarters";
import { getAllDepartmentIndicators, getDepartmentIndicatorById, postDepartmentIndicator, updateDepartmentIndicator, deleteDepartmentIndicatorById } from "../../../../../../../../services/departments/departments-indicators-service";
import { getAllDepartmentOutcomeIndicators, getDepartmentOutcomeIndicatorById, postDepartmentOutcomeIndicator, updateDepartmentOutcomeIndicator, deleteDepartmentOutcomeIndicatorById } from "../../../../../../../../services/departments/departmentOutcomeIndicator-service.js";

//
import { Checkbox } from "primereact/checkbox";
import { getAllDepartmentThemeIndicators, getDepartmentThemeIndicatorById, postDepartmentThemeIndicator, updateDepartmentThemeIndicator, deleteDepartmentThemeIndicatorById } from "../../../../../../../../services/departments/utility-dept/departments-themes-indicators-service.js";

function RowForm({ handleSubmit, initialData, selectedParentItem, ...props }) {
    console.log("selected parent item them indicator : ", selectedParentItem);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);
    const queryClient = useQueryClient();

    // Setting up custom state for storing selected utility indicator
    const [selectedUtilityIndicator, setSelectedUtilityIndicator] = useState(selectedParentItem?.id);

    // ================== get finacial years =================

    const getAllFinancialYearsQuery = useQuery(["financialYear"], getAllFinancialYears, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching getAllFinancialYears is : ", error);
        },
    });

    // ================= quarters ================

    const getAllQuartersQuery = useQuery(["quarters"], getAllQuarters, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching quarters is : ", error);
        },
    });

    // 'department_indicator_id',
    //     'fyear_id',
    //     'quarter_id',
    //     'baseline',
    //     'actual',
    //     'target',
    //     'reason_of_variance',
    //     'created_by',
    //     'updated_by'

    //  /* // 'base_year', // 'base_value', // 'target_year', // 'target_value', // 'financial_year_target', */

    const validate = (values) => {
        const errors = {};

        if (!values.utility_theme_indicators_id) errors.utility_theme_indicators_id = "Indicator is required";
        if (!values.target) errors.target = "Target  is required";
        if (!values.fyear_id) errors.fyear_id = "Financial year is required";
        if (!values.quarter_id) errors.quarter_id = "Quarter is required";

        // Conditional validation for manual_value
        // if (values.show_manual_value && !values.manual_value) {
        //     errors.manual_value = "Manual value is required when Show Manual Value is checked";
        // }
        return errors;
    };

    // const onSubmitForm = (data) => {
    //     const errors = validate(data);
    //     if (Object.keys(errors).length === 0) {
    //         // No validation errors
    //         setPendingData(data);
    //         setShowConfirmDialog(true);
    //     } else {
    //         toast.warning("First Fill In All Required Fields");
    //     }
    // };

    const onSubmitForm = (data, form) => {
        // Add 'form' as an argument
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            // Mark all fields as touched to show validation errors
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("First fill in all required fields.");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    //================== department outcome indicator ==================

    const getAllDepartmentThemeIndicatorsQuery = useQuery(
        ["department-outcome-indicator", "by_utility_themes_id", selectedParentItem?.utility_themes_id],
        () => (!selectedParentItem?.utility_themes_id ? getAllDepartmentThemeIndicators() : getAllDepartmentThemeIndicators({ utility_themes_id: selectedParentItem?.utility_themes_id })),
        {
            onSuccess: (data) => {
                console.log("success fetching department-outcome-indicator kkkkk is : ", data);
            },
            onError: (error) => {
                console.log("Error fetching department-outcome-indicator is : ", error);
            },
        }
    );

    // initialData = initialData ? { department_id: props?.department_id, ...initialData } : { department_id: selectedParentItem?.department_id, department_budget_outputs_id: selectedParentItem?.id };

    if (selectedParentItem) {
        initialData = { utility_theme_indicators_id: selectedParentItem?.id, ...initialData };
    }

    //================== departmengt budget outputs =============
    // const fetchAllDepartmentBudjetOutputs = useQuery(["departments", "departmentBudgetOutputs"], getAllDepartmentBudjetOutputs, {
    //     onSuccess: (data) => {},
    //     onError: (error) => {
    //         console.log("Error fetching projectSubprojectMetricsIndicator is : ", error);
    //     },
    // });

    // This will find the object that matches the selected ID
    const matchingIndicatorObject = getAllDepartmentThemeIndicatorsQuery?.data?.data?.data?.find((item) => item?.id === selectedUtilityIndicator);
    console.log("matchingIndicatorObject is : ", matchingIndicatorObject);

    return (
        <div className="col-12 md:col-12">
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    mutators={{ setFieldTouched }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                console.log("values hhh : ", values);
                                console.log("event fffff : ", event);
                                onSubmitForm(values, form);
                                // handleSubmit(event, values);
                            }}
                        >
                            <div style={{ marginBottom: "1rem" }}>
                                <Field name="utility_theme_indicators_id">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Indicator</label>
                                            <Dropdown
                                                value={input.value}
                                                options={getAllDepartmentThemeIndicatorsQuery?.data?.data?.data?.map((item) => ({
                                                    label: item?.output_indicator?.name,
                                                    value: item?.id,
                                                }))}
                                                // onChange={(e) => input.onChange(e.value)}
                                                onChange={(e) => {
                                                    input.onChange(e.value);
                                                    setSelectedUtilityIndicator(e.value); // Update the custom state when the dropdown changes
                                                }}
                                                disabled={getAllDepartmentThemeIndicatorsQuery?.isLoading}
                                                // disabled={props?.loggedInUserData?.role !== "Admin"}

                                                placeholder="Select Department Outcome Indicator"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />

                                            {getAllDepartmentThemeIndicatorsQuery.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            {/* Column 2 */}

                            <Field name="fyear_id">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label>Financial Year</label>
                                        <Dropdown
                                            value={input.value}
                                            options={getAllFinancialYearsQuery?.data?.data?.data.map((item) => ({
                                                label: item.name,
                                                value: item.id,
                                            }))}
                                            onChange={(e) => input.onChange(e.value)}
                                            // disabled={props?.loggedInUserData?.role !== "Admin"}
                                            placeholder="Select Financial Year"
                                        />
                                    </div>
                                )}
                            </Field>

                            <div style={{ marginBottom: "1rem" }}>
                                <Field name="quarter_id">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Quarter</label>
                                            <Dropdown
                                                value={input.value}
                                                options={getAllQuartersQuery?.data?.data?.data.map((item) => ({
                                                    label: item.name,
                                                    value: item.id,
                                                }))}
                                                onChange={(e) => input.onChange(e.value)}
                                                // disabled={props?.loggedInUserData?.role !== "Admin"}
                                                placeholder="Select Quarter"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <Field name="target">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label>Target {matchingIndicatorObject?.output_indicator?.unit ? matchingIndicatorObject?.output_indicator?.unit : ""}</label>
                                        <InputText
                                            {...input}
                                            id="target"
                                            type="number"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                // Allow only non-negative numbers
                                                if (!value || parseFloat(value) >= 0) {
                                                    input.onChange(value);
                                                }
                                            }}
                                        />
                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            {/* <Field name="show_manual_value">
                                {({ input, meta }) => (
                                    <div className="p-field-checkbox m-4">
                                        <Checkbox inputId="show_manual_value" checked={input.value} onChange={(e) => input.onChange(e.checked)} />
                                        <label>Show Manual Value</label>
                                    </div>
                                )}
                            </Field> */}

                            {/* Conditionally render the manual_value field based on show_manual_value */}
                            {/* <Field name="manual_value">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="manual_value">Manual Value</label>
                                            <InputTextarea {...input} id="manual_value" type="text" rows={4} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field> */}
                            {/* {values?.show_manual_value ? (
                                <Field name="manual_value">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Manual Value</label>
                                            <InputText {...input} id="name" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                            {meta.touched && (meta.error || form.submitSuccess || form.submitError) && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            ) : (
                                <></>
                            )} */}

                            <div className="d-grid gap-2">
                                <Button type="submit" label="Save" className="p-button-primary" icon="pi pi-check" />
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    style={{ width: "30vw" }}
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to submit?
                </Dialog>
            </div>
        </div>
    );
}

export default RowForm;
