import React from "react";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";

import SubProjectsRowForm from "./widgets/SubProjectsRowForm";
import { ToastContainer } from "react-toastify";

function SubProjectsEditForm({ selectedprojectItem, ...props }) {
    return (
        <>
            <Dialog header="Sup Projects Form" visible={props.show} onHide={props.onHide} maximizable>
                <SubProjectsRowForm selectedprojectItem={selectedprojectItem} show={props?.show} onHide={props?.onHide} onClose={props?.onClose} subprojectdata={props?.subprojectdata} loggedInUserData={props?.loggedInUserData} />
                <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />{" "}
            </Dialog>
        </>
    );
}

export default SubProjectsEditForm;
