import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { TabView, TabPanel } from "primereact/tabview";

import { getAllDirectorates } from "../../../services/departments/directorate-service";
import { getApproverRoles } from "../../../services/auth/user-service";
import { updateDepartments, getDepartmentApprovalDetails, postDepartmentApprovalDetails } from "../../../services/departments/department-service";
import { getAllPrograms } from "../../../services/programs/program-service";
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";

import DepartmentsRowForm from "./widgets/DepartmentsRowForm";

import { toast } from "react-toastify";

function DepartmentsEditForm(props) {
    console.log("props?.departmentdata?.id jjjkjjkhjh : ", props?.departmentdata?.id);
    const queryClient = useQueryClient();
    const directorateId = props.directorateId;
    const editDepartmentsMutation = useMutation({
        mutationFn: (variables) => updateDepartments(props?.departmentdata?.id, variables),
        onSuccess: () => {
            queryClient.invalidateQueries(["departments", directorateId]);
            queryClient.invalidateQueries(["programs-hireachy"]);
            toast.success("Departments updated successfully");
            props.onClose();
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
            console.log("create departments error : ", error);
        },
    });
    // console.log("props?.departmentsData?.id", props?.departmentsData?.id);
    // console.log("department props", props);
    // console.log("props?.departmentdata is : ", props?.departmentdata);
    const handleSubmitDepartment = (data) => {
        console.log("departments data on submit is : ", data);
        // event.preventDefault();
        editDepartmentsMutation.mutate(data);
    };

    const footer = (
        <>
            {editDepartmentsMutation.isLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px", // Border thickness
                            borderColor: "blue", // Border color
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={props.onHide} />
        </>
    );

    return (
        <Dialog header="Department Edit Form" visible={props.show} modal footer={footer} onHide={props.onHide} maximizable>
            <TabView className="tabview-custom">
                <TabPanel header="Details">
                    <p>Fill in the form below</p>
                    <DepartmentsRowForm handleSubmit={handleSubmitDepartment} departmentsData={props?.departmentdata} />
                    {editDepartmentsMutation.isLoading && (
                        <center>
                            <ProgressSpinner
                                style={{
                                    width: "50px",
                                    height: "50px",
                                    borderWidth: "8px", // Border thickness
                                    borderColor: "blue", // Border color
                                    animationDuration: "1s",
                                }}
                                strokeWidth="8"
                                animationDuration="1s"
                            />
                        </center>
                    )}
                </TabPanel>
            </TabView>
        </Dialog>
    );
}

export default DepartmentsEditForm;
