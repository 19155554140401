import React from "react";
import ProjectsList from "./ProjectsList";
import { useLocation } from "react-router-dom";
import { useParams, Link } from "react-router-dom";

import BreadcrumbNav from "../../../components/mweComponents/BreadcrumbNav";

const createBreadCrump = () => {};
//
function ProjectsPage({ loggedInUserData }) {
    let { state } = useLocation();
    let linkProgramId = state ? state.linkProgramId : null;
    let linkDepartmentId = state ? state.linkDepartmentId : null;
    let prevPage = state?.name ? state.name : null;
    return (
        <div>
            <BreadcrumbNav />

            <ProjectsList loggedInUserData={loggedInUserData} departmentId={linkDepartmentId} prevPage={prevPage} />
        </div>
    );
}

export default ProjectsPage;
