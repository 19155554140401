import React, { useEffect, useState, useRef } from "react";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Chart } from "primereact/chart";

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import moment from "moment";

import { getAllFinancialYears, getFinancialYearsById, postFinancialYears, updateFinancialYears, deleteFinancialYears } from "../../../../../services/financial-years/financial-years-service";
import { getAllQuarters, getQuartersById, postQuarters, updateQuarters, deleteQuarters } from "../../../../../services/financial-years/quarters";

import { toast } from "react-toastify";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useNavigate } from "react-router-dom";

import { Panel } from "primereact/panel";

import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";

import { Accordion, AccordionTab } from "primereact/accordion";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ProgressBar } from "primereact/progressbar";
import { Toast as PrimeReactToast } from "primereact/toast";

const FiltersPage = ({ loggedInUserData, financialYear, setFinancialYear, quarter, setQuarter }) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const primeReactToast = useRef(null);
    // Sample data

    const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;
    //department_id;

    // ================== get finacial years =================

    const getAllFinancialYearsQuery = useQuery(["financialYear"], getAllFinancialYears, {
        onSuccess: (data) => {
            // Assuming the latest financial year is the last item in the array
            if (data?.data?.data?.length > 0) {
                const latestFinancialYear = data?.data?.data[data?.data?.data?.length - 1]; // Get the last item
                !financialYear && setFinancialYear(latestFinancialYear?.id); // Update the financialYear state
            }
        },
        onError: (error) => {
            console.log("Error fetching getAllFinancialYears is : ", error);
        },
        staleTime: 3 * 60 * 60 * 1000, // 3 hours in milliseconds
    });

    const handleFinancialYearChange = (e) => {
        console.log("handleFinancialYearChange : ", e);
        setFinancialYear(e.value);
        setErrors({ ...errors, financialYear: null });
    };

    // ==================== quarters =====================

    const getAllQuartersQuery = useQuery(["quarters"], getAllQuarters, {
        onSuccess: (data) => {
            // Assuming the latest financial year is the last item in the array
            if (data?.data?.data?.length > 0) {
                const latestQuarter = data?.data?.data[0];
                !quarter && setQuarter(latestQuarter?.id); // Update the financialYear state
            }
        },
        onError: (error) => {
            console.log("Error fetching quarters is : ", error);
        },
        staleTime: 3 * 60 * 60 * 1000, // 3 hours in milliseconds
    });

    const handleQuarterChange = (e) => {
        console.log("handleQuarterChange : ", e);
        setQuarter(e.value);
        setErrors({ ...errors, quarter: null });
    };

    //

    useEffect(() => {
        if (getAllFinancialYearsQuery?.data?.data?.data?.length > 0) {
            const latestFinancialYear = getAllFinancialYearsQuery?.data?.data?.data[getAllFinancialYearsQuery?.data?.data?.data?.length - 1]; // Get the last item
            !financialYear && setFinancialYear(latestFinancialYear?.id); // Update the financialYear state
        }

        if (getAllQuartersQuery?.data?.data?.data?.length > 0) {
            const latestQuarter = getAllQuartersQuery?.data?.data?.data[0];
            !quarter && setQuarter(latestQuarter?.id); // Update the financialYear state
        }
    }, []);

    const [errors, setErrors] = useState({});
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    const validate = () => {
        let newErrors = {};
        if (!financialYear) newErrors.financialYear = "Financial year is required";

        if (!quarter) newErrors.quarter = "Quarter year is required";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleFilter = () => {
        if (validate()) {
            setShowConfirmDialog(true);
        }
    };

    const onConfirmFilter = () => {
        console.log("Selected Financial Year:", financialYear);

        setShowConfirmDialog(false);
        primeReactToast.current.show({
            severity: "info",
            summary: "Report Filtration",
            detail: "Hang on there reports are being filtered for you!",
            life: 3000,
        });
        // Additional logic to fetch data based on filters
    };

    // State to manage the active index of the accordion
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <div className="col-12 xl:col-12">
            <PrimeReactToast ref={primeReactToast} />
            {/* Financial Year and Quarter in their own card */}
            <Accordion activeIndex={activeIndex} style={{ marginBottom: "1rem" }}>
                <AccordionTab header="Filters">
                    {/* <Card title="Period" style={{ marginBottom: "1rem" }}> */}
                    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                        <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column", marginBottom: "1rem", gap: "2rem" }}>
                            <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                <label htmlFor="financialYear">Financial Year</label>
                                <Dropdown
                                    value={financialYear}
                                    onChange={handleFinancialYearChange}
                                    options={getAllFinancialYearsQuery?.data?.data?.data.map((item) => ({
                                        label: item.name,
                                        value: item.id,
                                    }))}
                                    placeholder="Select a Year"
                                    disabled={getAllFinancialYearsQuery.isLoading}
                                />
                                {errors.financialYear && <small className="p-error">{errors.financialYear}</small>}
                            </div>

                            <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                <label>Quarter</label>
                                <Dropdown
                                    value={quarter}
                                    onChange={handleQuarterChange}
                                    options={getAllQuartersQuery?.data?.data?.data.map((item) => ({
                                        label: item.name,
                                        value: item.id,
                                    }))}
                                    placeholder="Select a Quarter"
                                    disabled={getAllQuartersQuery.isLoading}
                                />
                                {getAllQuartersQuery.isLoading && <ProgressSpinner style={{ width: "20px", height: "20px" }} />}
                                {errors.quarter && <small className="p-error">{errors.quarter}</small>}
                            </div>

                            {(getAllFinancialYearsQuery.isLoading || getAllQuartersQuery.isLoading) && (
                                <div className="m-2">
                                    <ProgressBar mode="indeterminate" style={{ height: "6px" }} />
                                </div>
                            )}
                        </div>{" "}
                    </div>
                    {/* </Card> */}

                    {/* <Button label="Apply Filters" onClick={handleFilter} /> */}
                    <Dialog
                        visible={showConfirmDialog}
                        onHide={() => setShowConfirmDialog(false)}
                        header="Confirm Filter"
                        maximizable
                        footer={
                            <div>
                                <Button label="Yes" onClick={onConfirmFilter} />
                                <Button label="No" onClick={() => setShowConfirmDialog(false)} />
                            </div>
                        }
                    >
                        Are you sure you want to apply these filters?
                    </Dialog>
                </AccordionTab>
            </Accordion>
        </div>
    );
};

export default FiltersPage;
