import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import DepartmentReportForm from "./DepartmentReportForm";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import BreadcrumbNav from "../../../../../../../components/mweComponents/BreadcrumbNav";

function DepartmentReportFormViewPage() {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    let { state } = useLocation();
    let projectReports = state?.projectReports ? state?.projectReports : null;
    let selectedDepartmentItem = state?.selectedDepartmentItem ? state?.selectedDepartmentItem : null;
    let departmentViewPageActiveIndex = state?.departmentViewPageActiveIndex ? state?.departmentViewPageActiveIndex : null;
    let departmentViewPageChildActiveIndex = state?.departmentViewPageChildActiveIndex ? state?.departmentViewPageChildActiveIndex : null;

    return (
        <div style={{ width: "100%" }}>
            <BreadcrumbNav />
            <DepartmentReportForm projectReports={projectReports} departmentViewPageActiveIndex={departmentViewPageActiveIndex} departmentViewPageChildActiveIndex={departmentViewPageChildActiveIndex} selectedDepartmentItem={selectedDepartmentItem} />
        </div>
    );
}

export default DepartmentReportFormViewPage;
