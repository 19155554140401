import React from "react";
import ListPage from "./ListPage";
import { Link } from "react-router-dom";

import BreadcrumbNav from "../../../../../components/mweComponents/BreadcrumbNav";

const createBreadCrump = () => {};
//
function DepartmentBudgetOutPutIndicatorsPage({ projectId, selectedItem, selectedBudgetOutputItem }) {
    let linksData = [{ name: "programs" }];
    return (
        <div>
            {/* <BreadcrumbNav /> */}

            <ListPage selectedBudgetOutputItem={selectedBudgetOutputItem} projectId={projectId} />
        </div>
    );
}

export default DepartmentBudgetOutPutIndicatorsPage;
