import React from "react";
import ListPage from "./ListPage";
import { Link } from "react-router-dom";

import BreadcrumbNav from "../../../../../../../components/mweComponents/BreadcrumbNav";

const createBreadCrump = () => {};
//
function UtilityAnnualIndicatorTargetsPage({ loggedInUserData, selectedIndicatorData }) {
    let linksData = [{ name: "programs" }];
    return (
        <div>
            {/* <BreadcrumbNav /> */}

            <ListPage loggedInUserData={loggedInUserData} selectedParentItem={selectedIndicatorData} />
        </div>
    );
}

export default UtilityAnnualIndicatorTargetsPage;
