import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";

const RiskMitigationHistory = ({ selectedSubProjectReports }) => {
    const riskTypeBodyTemplate = (rowData) => {
        let color;
        switch (rowData.risk_type) {
            case "high":
                color = "red";
                break;
            case "moderate":
                color = "orange";
                break;
            case "low":
                color = "grey";
                break;
            default:
                color = "black";
        }

        return (
            <div>
                <span className="p-mr-2" style={{ fontSize: "1.5rem", color, fontWeight: "bold" }}>
                    •
                </span>
                {rowData.risk_type.charAt(0).toUpperCase() + rowData.risk_type.slice(1)}
            </div>
        );
    };
    return (
        <div>
            {selectedSubProjectReports.map((report, index) => (
                <Card key={index} title={report.subproject.name} subTitle={`Financial Year: ${report.financial_year.name}`}>
                    {/* <p>Description: {report.description}</p>
                    <p>Approved Budget: {report.approved_budget}</p>
                    <p>Spent Funds: {report.spent_funds}</p>
                    <p>Absorption Rate: {report.absorption_rate}</p>
                    <p>Current Status: {report.current_status.name}</p> */}

                    {/* <h3>Risks and Mitigations History</h3> */}
                    <DataTable value={report.risks_mitigation}>
                        <Column field="risk" header="Risk"></Column>
                        <Column field="risk_type" header="Risk Type" body={riskTypeBodyTemplate}></Column>
                        <Column field="mitigation" header="Mitigation"></Column>
                    </DataTable>
                </Card>
            ))}
        </div>
    );
};

export default RiskMitigationHistory;
