// Recommendations.js
import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import RecommendationsFormModal from "./RecommendationsFormModal";

const Recommendations = ({ recommendations, setRecommendations, isSubmitDisabled }) => {
    // const [recommendations, setRecommendations] = useState([]);
    const [showForm, setShowForm] = useState(false);

    const addRecommendation = (recommendationData) => {
        // Assuming each recommendation has a unique 'id' field
        setRecommendations([...recommendations, { id: recommendations.length + 1, ...recommendationData }]);
    };

    const deleteRecommendation = (id) => {
        setRecommendations(recommendations.filter((item) => item.id !== id));
    };

    const actionBodyTemplate = (rowData, index) => {
        return (
            <Button
                label="Delete"
                icon="pi pi-times"
                className="p-button-danger"
                onClick={(e) => {
                    e.preventDefault();
                    deleteRecommendation(rowData.id);
                }}
            />
        );
    };

    return (
        <div>
            <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end" }}>
                <Button
                    label="Add Challange"
                    icon="pi pi-plus"
                    onClick={(e) => {
                        e.preventDefault();
                        setShowForm(true);
                    }}
                    disabled={isSubmitDisabled}
                />
            </div>

            <DataTable value={recommendations}>
                <Column field="challenges" header="Challenges"></Column>
                <Column field="recommendations" header="Recommendations"></Column>
                <Column body={actionBodyTemplate}></Column>
            </DataTable>

            {showForm && <RecommendationsFormModal onHide={() => setShowForm(false)} onSubmit={addRecommendation} />}
        </div>
    );
};

export default Recommendations;
