import React, { useState, useRef } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import setFieldTouched from "final-form-set-field-touched";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { AutoComplete } from "primereact/autocomplete";
import { ProgressSpinner } from "primereact/progressspinner";

import { Dropdown } from "primereact/dropdown";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { getAllDepartments } from "../../../../../services/departments/department-service";
import { classNames } from "primereact/utils";

import { getAllFinancialYears, getFinancialYearsById, postFinancialYears, updateFinancialYears, deleteFinancialYears } from "../../../../../services/financial-years/financial-years-service";
import { getAllQuarters, getQuartersById, postQuarters, updateQuarters, deleteQuarters } from "../../../../../services/financial-years/quarters";
import { deleteSubProjectById, getAllSubProjects, getAllSubProjectsUnderUserProjectsByProjectIdOrRole } from "../../../../../services/sub-projects/sub-project-service";

//
import { deleteDistrictById, getAllDistricts } from "../../../../../services/districts/districts-service";
import { deleteCountyById, getAllCounty } from "../../../../../services/county/county-service";
import { deleteSubcountyById, getAllSubcounty } from "../../../../../services/subcounty/subcounty-service";
import { deleteParishById, getAllParish } from "../../../../../services/parish/parish-service";
import { deleteVillageById, getAllVillage } from "../../../../../services/village/village-service";
import { Toast as PrimeReactToast } from "primereact/toast";

function RowForm({ handleSubmit, initialData, selectedParentItem, ...props }) {
    const toastRef = useRef(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);
    const queryClient = useQueryClient();

    // ================== get finacial years =================

    const getAllFinancialYearsQuery = useQuery(["financialYear"], getAllFinancialYears, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching getAllFinancialYears is : ", error);
        },
    });

    // ================= quarters ================

    const getAllQuartersQuery = useQuery(["quarters"], getAllQuarters, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching quarters is : ", error);
        },
    });

    // 'department_indicator_id',
    //     'fyear_id',
    //     'quarter_id',
    //     'baseline',
    //     'actual',
    //     'target',
    //     'reason_of_variance',
    //     'created_by',
    //     'updated_by'

    //  /* // 'base_year', // 'base_value', // 'target_year', // 'target_value', // 'financial_year_target', */

    const validate = (values) => {
        const errors = {};

        if (!values.subproject_id) {
            errors.subproject_id = "Subproject is required.";
        }
        if (!values.district_id) {
            errors.district_id = "District is required.";
        }
        if (!values.county_id) {
            errors.county_id = "County is required.";
        }
        if (!values.sub_county_id) {
            errors.sub_county_id = "Sub-county is required.";
        }
        if (!values.parish_id) {
            errors.parish_id = "Parish is required.";
        }
        if (!values.village_id) {
            errors.village_id = "Village is required.";
        }
        // if (values.latitude && (values.latitude < -90 || values.latitude > 90)) {
        //     errors.latitude = "Latitude must be between -90 and 90.";
        // } else if (!values.latitude) {
        //     errors.latitude = "Latitude is required.";
        // }
        // if (values.longitude && (values.longitude < -180 || values.longitude > 180)) {
        //     errors.longitude = "Longitude must be between -180 and 180.";
        // } else if (!values.longitude) {
        //     errors.longitude = "Longitude is required.";
        // }

        // Perform any other validation checks that you might need

        return errors;
    };

    // const onSubmitForm = (data) => {
    //     const errors = validate(data);
    //     if (Object.keys(errors).length === 0) {
    //         // No validation errors
    //         setPendingData(data);
    //         setShowConfirmDialog(true);
    //     } else {
    //         toast.warning("First Fill In All Required Fields");
    //     }
    // };

    const onSubmitForm = (data, form) => {
        // Add 'form' as an argument
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            // Mark all fields as touched to show validation errors
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("First fill in all required fields.");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    console.log("selectedParentItem shsjfbdsj : ", selectedParentItem);

    if (selectedParentItem) {
        initialData = { subproject_id: selectedParentItem?.id, ...initialData };
    }

    // ========================== fetch all sub projects =======================
    const fetchAllSubProjects = useQuery(["subprojects", selectedParentItem?.project_id], () => getAllSubProjects({ project_id: selectedParentItem?.project_id }), {
        onSuccess: (data) => {
            console.log("getAllProjects onsuccess fetching projects dsfsd : ", data);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
            console.log("get list of projects : ", error);
        },
    });

    // district_id'
    // county_id
    // sub_county_id
    // parish_id

    //==================== districts ============
    // districts are fetched from those attached to the project so if the project has non also the subproject wont have
    // subproject_id;
    const getListOfDistricts = useQuery(["districts"], () => (!selectedParentItem?.id ? getAllDistricts() : getAllDistricts({ subproject_id: selectedParentItem?.id })), {
        onSuccess: (data) => {
            if (!data || data.length === 0 || data.data.data.length === 0) {
                toastRef.current.show({ severity: "info", summary: "Info", detail: "Seems the parent project has no districts attached to it", life: 3000 });
            }
        },
        onError: (error) => {
            console.log("Error fetching districts : ", error);
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
        },
    });

    console.log("getListOfDistricts xxxx : ", getListOfDistricts);

    const [selectedDistrict, setSelectedDistrict] = useState(initialData?.district);
    const [filteredDistricts, setFilteredDistricts] = useState();

    // ================= Counties ========================
    const [selectedCounty, setSelectedCounty] = useState(initialData?.county);
    const [filteredCounties, setFilteredCounties] = useState();

    const getListOfCounties = useQuery(["counties", selectedDistrict?.id], () => getAllCounty({ district_id: selectedDistrict?.id }), {
        enabled: !!selectedDistrict?.id, // Only run the query if a district is selected
        onSuccess: (data) => {
            // Handle successful data fetching
        },
        onError: (error) => {
            // Log and display error messages
            const errorMessage = error?.response?.data?.message || "An Error Occurred. Please Contact Admin";
            toast.error(errorMessage);
            console.error("Error fetching counties: ", error);
        },
    });

    //================ Sub County =================================================================

    const [selectedSubCounty, setSelectedSubCounty] = useState(initialData?.sub_county);
    const [filteredSubCountys, setFilteredSubCountys] = useState();

    const getListOfSubCounties = useQuery(["subcounties", selectedCounty?.id], () => getAllSubcounty({ county_id: selectedCounty?.id }), {
        enabled: !!selectedCounty?.id, // Only run the query if a county is selected
        onSuccess: (data) => {
            // Handle successful data fetching
        },
        onError: (error) => {
            // Log and display error messages
            const errorMessage = error?.response?.data?.message || "An Error Occurred. Please Contact Admin";
            toast.error(errorMessage);
            console.error("Error fetching sub counties: ", error);
        },
    });

    //

    const [selectedParish, setSelectedParish] = useState(initialData?.parish);
    const [filteredParishs, setFilteredParishs] = useState();

    const getListOfParishes = useQuery(["parishes", selectedSubCounty?.id], () => getAllParish({ sub_county_id: selectedSubCounty?.id }), {
        enabled: !!selectedSubCounty?.id, // Only run the query if a sub-county is selected
        onSuccess: (data) => {
            // Handle successful data fetching
        },
        onError: (error) => {
            // Log and display error messages
            const errorMessage = error?.response?.data?.message || "An Error Occurred. Please Contact Admin";
            toast.error(errorMessage);
            console.error("Error fetching parishes: ", error);
        },
    });

    //

    const [selectedVillage, setSelectedVillage] = useState(initialData?.village);
    const [filteredVillages, setFilteredVillages] = useState();

    const getListOfVillages = useQuery(["villages", selectedParish?.id], () => getAllVillage({ parish_id: selectedParish?.id }), {
        enabled: !!selectedParish?.id, // Only run the query if a parish is selected
        onSuccess: (data) => {
            // Handle successful data fetching
        },
        onError: (error) => {
            // Log and display error messages
            const errorMessage = error?.response?.data?.message || "An Error Occurred. Please Contact Admin";
            toast.error(errorMessage);
            console.error("Error fetching villages: ", error);
        },
    });

    console.log("ggggsgh initialData : ", initialData);

    return (
        <div className="col-12 md:col-12">
            <PrimeReactToast ref={toastRef} />
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    mutators={{ setFieldTouched }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                console.log("values hhh : ", values);
                                console.log("event fffff : ", event);
                                onSubmitForm(values, form);
                                // handleSubmit(event, values);
                            }}
                        >
                            <div style={{ marginBottom: "1rem" }}>
                                <Field name="subproject_id">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Sub Project</label>
                                            <Dropdown
                                                value={input.value}
                                                options={fetchAllSubProjects?.data?.data?.data.map((item) => ({
                                                    label: item.name,
                                                    value: item.id,
                                                }))}
                                                onChange={(e) => input.onChange(e.value)}
                                                placeholder="Select Sub Project"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                disabled={fetchAllSubProjects.isLoading}
                                            />
                                            {fetchAllSubProjects.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <Field name="district_id">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="district_id">District</label>
                                        <AutoComplete
                                            value={selectedDistrict?.name || ""}
                                            suggestions={filteredDistricts}
                                            disabled={getListOfDistricts.isLoading}
                                            completeMethod={(e) => {
                                                const results = getListOfDistricts.data?.data?.data.filter((department) => {
                                                    return department.name.toLowerCase().startsWith(e.query.toLowerCase());
                                                });
                                                setFilteredDistricts(results);
                                            }}
                                            field="name"
                                            dropdown={true}
                                            onChange={(e) => {
                                                if (typeof e.value === "string") {
                                                    // Update the display value to the typed string and reset the selected department
                                                    setSelectedDistrict({ name: e.value });
                                                    input.onChange("");
                                                } else if (typeof e.value === "object" && e.value !== null) {
                                                    // Update the selected department and set the form state with the selected department's ID
                                                    setSelectedDistrict(e.value);
                                                    input.onChange(e.value.id);
                                                    // Clear the values of the child locations
                                                    setSelectedCounty(null);
                                                    setSelectedSubCounty(null);
                                                    setSelectedParish(null);
                                                    setSelectedVillage(null);
                                                }
                                            }}
                                            id="district"
                                            selectedItemTemplate={(value) => <div>{value ? value.name : "Select a District"}</div>}
                                        />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        {getListOfDistricts.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                    </div>
                                )}
                            </Field>

                            <Field name="county_id">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="county_id">County</label>
                                        <AutoComplete
                                            value={selectedCounty?.name || ""}
                                            suggestions={filteredCounties}
                                            completeMethod={(e) => {
                                                if (getListOfCounties.data?.data?.data) {
                                                    const results = getListOfCounties.data?.data?.data.filter((county) => {
                                                        return county.name.toLowerCase().startsWith(e.query.toLowerCase());
                                                    });
                                                    setFilteredCounties(results);
                                                }
                                            }}
                                            field="name"
                                            dropdown={true}
                                            onChange={(e) => {
                                                if (typeof e.value === "string") {
                                                    setSelectedCounty({ name: e.value });
                                                    input.onChange("");
                                                } else if (typeof e.value === "object" && e.value !== null) {
                                                    setSelectedCounty(e.value);
                                                    input.onChange(e.value.id);
                                                    // Clear the values of the child locations
                                                    setSelectedSubCounty(null);
                                                    setSelectedParish(null);
                                                    setSelectedVillage(null);
                                                }
                                            }}
                                            id="county"
                                            selectedItemTemplate={(value) => <div>{value ? value.name : "Select a County"}</div>}
                                        />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        {getListOfCounties.isLoading && getListOfCounties.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                    </div>
                                )}
                            </Field>

                            <Field name="sub_county_id">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="sub_county_id">Sub-County</label>
                                        <AutoComplete
                                            value={selectedSubCounty?.name || ""}
                                            suggestions={filteredSubCountys}
                                            completeMethod={(e) => {
                                                if (getListOfSubCounties.data?.data?.data) {
                                                    const results = getListOfSubCounties.data?.data?.data.filter((subCounty) => {
                                                        return subCounty.name.toLowerCase().startsWith(e.query.toLowerCase());
                                                    });
                                                    setFilteredSubCountys(results);
                                                }
                                            }}
                                            field="name"
                                            dropdown={true}
                                            onChange={(e) => {
                                                if (typeof e.value === "string") {
                                                    setSelectedSubCounty({ name: e.value });
                                                    input.onChange("");
                                                } else if (typeof e.value === "object" && e.value !== null) {
                                                    setSelectedSubCounty(e.value);
                                                    input.onChange(e.value.id);
                                                    // Clear the values of the child locations
                                                    setSelectedParish(null);
                                                    setSelectedVillage(null);
                                                }
                                            }}
                                            id="sub_county"
                                            selectedItemTemplate={(value) => <div>{value ? value.name : "Select a Sub-County"}</div>}
                                        />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        {getListOfSubCounties.isLoading && getListOfSubCounties.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                    </div>
                                )}
                            </Field>

                            <Field name="parish_id">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="parish_id">Parish</label>
                                        <AutoComplete
                                            value={selectedParish?.name || ""}
                                            suggestions={filteredParishs}
                                            completeMethod={(e) => {
                                                if (getListOfParishes.data?.data?.data) {
                                                    const results = getListOfParishes.data?.data?.data.filter((parish) => {
                                                        return parish.name.toLowerCase().startsWith(e.query.toLowerCase());
                                                    });
                                                    setFilteredParishs(results);
                                                }
                                            }}
                                            field="name"
                                            dropdown={true}
                                            onChange={(e) => {
                                                if (typeof e.value === "string") {
                                                    setSelectedParish({ name: e.value });
                                                    input.onChange("");
                                                } else if (typeof e.value === "object" && e.value !== null) {
                                                    setSelectedParish(e.value);
                                                    input.onChange(e.value.id);
                                                    // Clear the value of the child location
                                                    setSelectedVillage(null);
                                                }
                                            }}
                                            id="parish"
                                            selectedItemTemplate={(value) => <div>{value ? value.name : "Select a Parish"}</div>}
                                        />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        {getListOfParishes.isLoading && getListOfParishes.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                    </div>
                                )}
                            </Field>

                            <Field name="village_id">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="village_id">Village</label>
                                        <AutoComplete
                                            value={selectedVillage?.name || ""}
                                            suggestions={filteredVillages}
                                            completeMethod={(e) => {
                                                if (getListOfVillages.data?.data?.data) {
                                                    const results = getListOfVillages.data?.data?.data.filter((village) => {
                                                        return village.name.toLowerCase().startsWith(e.query.toLowerCase());
                                                    });
                                                    setFilteredVillages(results);
                                                }
                                            }}
                                            field="name"
                                            dropdown={true}
                                            onChange={(e) => {
                                                if (typeof e.value === "string") {
                                                    setSelectedVillage({ name: e.value });
                                                    input.onChange("");
                                                } else if (typeof e.value === "object" && e.value !== null) {
                                                    setSelectedVillage(e.value);
                                                    input.onChange(e.value.id);
                                                    // No child locations to clear for village
                                                }
                                            }}
                                            id="village"
                                            selectedItemTemplate={(value) => <div>{value ? value.name : "Select a Village"}</div>}
                                        />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        {getListOfVillages.isLoading && getListOfVillages.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                    </div>
                                )}
                            </Field>

                            {/* <Field name="latitude">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="latitude">Latitude</label>
                                        <InputText
                                            {...input}
                                            id="latitude"
                                            type="number"
                                            step="any" // Allows for decimal points to be entered.
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                // Optionally, you can add validation to ensure the value is within latitude range (-90 to 90).
                                                input.onChange(value);
                                            }}
                                        />
                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field> */}

                            <div className="d-grid gap-2">
                                <Button type="submit" label="Save" className="p-button-primary" icon="pi pi-check" />
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    style={{ width: "30vw" }}
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to submit?
                </Dialog>
            </div>
        </div>
    );
}

export default RowForm;
