import axiosAPI from "../../axiosApi";

export async function getAllDepartmentThemeIndicators(params = {}) {
    const response = await axiosAPI.get("utility-theme-indicators", { params: params });
    return response;
}

export async function getDepartmentThemeIndicatorById(id) {
    const response = await axiosAPI.get(`utility-theme-indicators/${id}`);
    return response;
}

export async function postDepartmentThemeIndicator(data) {
    const response = await axiosAPI.post(`utility-theme-indicators`, data);
    return response;
}

export async function updateDepartmentThemeIndicator(id, data) {
    const response = await axiosAPI.put(`utility-theme-indicators/${id}`, data);
    return response;
}

export async function deleteDepartmentThemeIndicatorById(id) {
    const response = await axiosAPI.delete(`utility-theme-indicators/${id}`);
    return response;
}

//
export async function postFilterThemeIndicatorTargets(data) {
    const response = await axiosAPI.post(`filter-theme-indicator-targets`, data);
    return response;
}
