import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import setFieldTouched from "final-form-set-field-touched";
import { Calendar } from "primereact/calendar";
import moment from "moment";

import { Dropdown } from "primereact/dropdown";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getAllDepartments } from "../../../../../services/departments/department-service";
import { classNames } from "primereact/utils";

//

import { AutoComplete } from "primereact/autocomplete";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DeleteIcon from "@mui/icons-material/Delete";

import { ProgressSpinner } from "primereact/progressspinner";
import { deleteDistrictById, getAllDistricts } from "../../../../../services/districts/districts-service";

function RowForm({ handleSubmit, initialData, selectedParentItem, ...props }) {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);
    const queryClient = useQueryClient();

    // 'department_indicator_id',
    //     'fyear_id',
    //     'quarter_id',
    //     'baseline',
    //     'actual',
    //     'target',
    //     'reason_of_variance',
    //     'created_by',
    //     'updated_by'

    const validate = (values) => {
        const errors = {};

        if (!values.name) errors.name = "Name is required";
        if (!values.details) errors.details = "details is required";
        // if (!values.description) errors.description = "description is required";
        if (!values.status) errors.status = "status is required";
        if (!values.department_id) errors.department_id = "Department is required";
        // Districts validation
        if (!values.districtIds || values.districtIds.length === 0) errors.districtIds = "At least one district is required";

        return errors;
    };

    // const onSubmitForm = (data) => {
    //     const errors = validate(data);
    //     if (Object.keys(errors).length === 0) {
    //         // No validation errors
    //         setPendingData(data);
    //         setShowConfirmDialog(true);
    //     } else {
    //         toast.warning("First Fill In All Required Fields");
    //     }
    // };

    const onSubmitForm = (data, form) => {
        // Add 'form' as an argument
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            // Mark all fields as touched to show validation errors
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("First fill in all required fields.");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    if (selectedParentItem) {
        initialData = { department_id: selectedParentItem?.id, ...initialData };
    }

    //================ departments ==============
    const fetchAllDepartments = useQuery(["departments"], getAllDepartments, {
        onSuccess: (data) => {
            // setDepartments(data.data);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
            console.log("get list of departments : ", error);
        },
    });

    //==================== districts ====================
    const getListOfDistricts = useQuery(["districts"], getAllDistricts, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching districts : ", error);
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
        },
    });

    console.log("department admin initial data initialData : ", initialData);

    const [dropdownDistricts, setDropdownDistricts] = useState([]);
    const [selectedDistrict, setSelectedDistrict] = useState(initialData?.districts || []);
    console.log("kkkkkkk selectedDistrict xxxxx  : ", selectedDistrict);

    initialData = {
        districtIds: initialData?.districts?.map((district) => district.id),
        ...initialData,
    };

    // Special option for selecting all districts
    const selectAllOption = { id: "all", name: "Select All Districts" };

    //
    const fetchSuggestions = (query) => {
        let filteredDistricts = getListOfDistricts.data?.data?.data.filter((district) => {
            return !selectedDistrict.map((sp) => sp.id).includes(district.id) && district.name.toLowerCase().includes(query.toLowerCase());
        });

        // Add 'Select All' option only if no districts are selected
        if (selectedDistrict.length === 0 && (query.toLowerCase() === "select all" || query === "")) {
            filteredDistricts = [selectAllOption, ...filteredDistricts];
        }

        setDropdownDistricts(filteredDistricts);
    };

    //=========================== data table pagination ======================
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(5); // Convert to state variable

    // Function to handle page changes
    const onPaging = (event) => {
        setPage(event.first / event.rows);
        setRows(event.rows); // Update number of rows per page
    };

    return (
        <div className="col-12 md:col-12">
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    initialValuesEqual={() => true}
                    // initialValuesEqual with a function returning true prevents the form from
                    // reinitializing when the initialValues prop changes. This is useful when you
                    // want to avoid re-rendering or reinitializing the form due to changes in initial values.
                    // Be cautious using this if your initial values are meant to be dynamic and responsive
                    // to changes in your application's state.
                    mutators={{ setFieldTouched }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                console.log("values hhh : ", values);
                                console.log("event fffff : ", event);
                                onSubmitForm(values, form);
                                // handleSubmit(event, values);
                            }}
                        >
                            <Field name="name">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="name">Name</label>
                                        <InputText {...input} id="name" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && (meta.error || form.submitSuccess || form.submitError) && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            {/* Status Dropdown */}
                            <Field name="status">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="status">Status</label>
                                        <Dropdown
                                            {...input}
                                            options={[
                                                { label: "Active", value: "active" },
                                                { label: "Inactive", value: "deactive" },
                                            ]}
                                            placeholder="Select Status"
                                        />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            {/* Details Field */}
                            <Field name="details">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="details">Details</label>
                                        <InputTextarea {...input} id="details" />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            {/* Description Field */}
                            {/* <Field name="description">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="description">Description</label>
                                        <InputTextarea {...input} id="description" />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field> */}

                            <div style={{ marginBottom: "1rem" }}>
                                <Field name="department_id">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Department</label>
                                            <Dropdown
                                                value={input.value}
                                                options={fetchAllDepartments?.data?.data?.data.map((item) => ({
                                                    label: item.name,
                                                    value: item.id,
                                                }))}
                                                onChange={(e) => input.onChange(e.value)}
                                                // disabled={props?.loggedInUserData?.role !== "Admin"}
                                                placeholder="Select Department"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <Field name="districtIds">
                                {({ input, meta }) => {
                                    console.log("kkkkkkk first main ffffff input.value : ", input.value);
                                    return (
                                        <div className="p-field m-4">
                                            <label htmlFor="districtIds">Districts</label>
                                            <AutoComplete
                                                {...input}
                                                suggestions={dropdownDistricts}
                                                completeMethod={(e) => fetchSuggestions(e.query)}
                                                multiple={true}
                                                disabled={getListOfDistricts.isLoading}
                                                value={selectedDistrict}
                                                field="name"
                                                dropdown={true}
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                onChange={(e) => {
                                                    const newSelected = e.value;

                                                    // Handle 'Select All Districts'
                                                    if (newSelected.some((district) => district.id === "all")) {
                                                        // Add all districts to the selection
                                                        const allDistricts = getListOfDistricts.data?.data?.data || [];
                                                        setSelectedDistrict(allDistricts);
                                                        input.onChange(allDistricts.map((district) => district.id));
                                                    } else {
                                                        if (newSelected.length < selectedDistrict.length) {
                                                            // District removed
                                                            const removedDistrict = selectedDistrict.find((district) => !newSelected.includes(district));
                                                            if (removedDistrict) {
                                                                const updatedSelectedDistricts = selectedDistrict.filter((district) => district.id !== removedDistrict.id);
                                                                setSelectedDistrict(updatedSelectedDistricts);
                                                                input.onChange(updatedSelectedDistricts.map((district) => district.id));
                                                            }
                                                        } else {
                                                            // District added
                                                            const addedDistrict = newSelected[newSelected.length - 1];
                                                            setSelectedDistrict([...selectedDistrict, addedDistrict]);
                                                            input.onChange([...input.value, addedDistrict.id]);
                                                        }
                                                    }

                                                    fetchSuggestions("");
                                                }}
                                                id="districtIds"
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                            {/* DataTable to display selected programs */}
                                            {input.value && input.value.length > 0 && (
                                                <div className="p-field m-4">
                                                    <DataTable value={selectedDistrict} paginator rows={rows} first={page * rows} onPage={onPaging} paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" rowsPerPageOptions={[5, 10, 20]}>
                                                        <Column field="id" header="District ID"></Column>
                                                        <Column field="name" header="District Name"></Column>
                                                        <Column
                                                            body={(rowData) => (
                                                                <DeleteIcon
                                                                    style={{ color: "tomato", cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        const updatedSelectedDistrict = selectedDistrict.filter((district) => district.id !== rowData.id);
                                                                        setSelectedDistrict(updatedSelectedDistrict);
                                                                        input.onChange(updatedSelectedDistrict.map((prog) => prog.id));
                                                                        fetchSuggestions(""); // Refresh the dropdown suggestions
                                                                    }}
                                                                />
                                                            )}
                                                            header="Actions"
                                                        ></Column>
                                                    </DataTable>
                                                </div>
                                            )}
                                            {getListOfDistricts.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        </div>
                                    );
                                }}
                            </Field>

                            <div className="d-grid gap-2">
                                <Button type="submit" label="Save" className="p-button-primary" icon="pi pi-check" />
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    style={{ width: "30vw" }}
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to submit?
                </Dialog>
            </div>
        </div>
    );
}

export default RowForm;
