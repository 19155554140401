import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import setFieldTouched from "final-form-set-field-touched";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";

const RowFormModal = ({ onHide, onSubmit }) => {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);

    const validate = (data) => {
        const errors = {};
        if (!data.risk_type) errors.risk_type = "Risk is required";
        if (!data.mitigation) errors.mitigation = "Mitigation is required";
        if (!data.risk) errors.risk = "Risk is required";
        return errors;
    };

    const onConfirm = (e) => {
        e.preventDefault();
        if (pendingData) {
            onSubmit(pendingData);
        }
        setShowConfirmDialog(false);
        onHide();
    };

    const onCancel = (e) => {
        e.preventDefault();
        setShowConfirmDialog(false);
    };

    const onSubmitForm = (data, form) => {
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
        }
    };

    const handleManualSubmit = (form) => {
        const values = form.getState().values;
        onSubmitForm(values, form);
    };

    return (
        <>
            <Dialog header="Risk & Mitigation" visible onHide={onHide} maximizable>
                <div className="p-fluid">
                    <div className="card p-fluid">
                        <Form
                            onSubmit={onSubmitForm}
                            validate={validate}
                            mutators={{ setFieldTouched }}
                            render={({ handleSubmit, form, submitting, pristine }) => (
                                <form
                                    onSubmit={(event) => {
                                        event.preventDefault();
                                        onSubmitForm(form.getState().values, form);
                                    }}
                                >
                                    <Field name="risk">
                                        {({ input, meta }) => (
                                            <div className="field">
                                                <label>Risk</label>
                                                <InputTextarea {...input} rows={5} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="risk_type">
                                        {({ input, meta }) => (
                                            <div className="field">
                                                <label>Risk Type</label>
                                                <Dropdown
                                                    {...input}
                                                    options={[
                                                        { label: "High", value: "high" },
                                                        { label: "Moderate", value: "moderate" },
                                                        { label: "Low", value: "low" },
                                                    ]}
                                                    className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="mitigation">
                                        {({ input, meta }) => (
                                            <div className="field">
                                                <label>Mitigation</label>
                                                <InputTextarea {...input} rows={5} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                            </div>
                                        )}
                                    </Field>
                                    <Button type="button" label="Submit" disabled={submitting || pristine} onClick={() => handleManualSubmit(form)} />
                                </form>
                            )}
                        />
                    </div>
                </div>
            </Dialog>

            <Dialog
                header="Confirmation"
                visible={showConfirmDialog}
                onHide={onCancel}
                footer={
                    <div>
                        <Button label="Yes" onClick={onConfirm} />
                        <Button label="No" onClick={onCancel} className="p-button-secondary" />
                    </div>
                }
            >
                Are you sure you want to submit?
            </Dialog>
        </>
    );
};

export default RowFormModal;
