import React, { useState, useEffect } from "react";

import { updatePrograms } from "../../../services/programs/program-service";
import ProgramsRowForm from "./widgets/ProgramsRowForm";
import { useQueryClient, useMutation } from "@tanstack/react-query";

import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";

function ProgramsEditForm(props) {
    const queryClient = useQueryClient();

    const editProgramsMutation = useMutation({
        mutationFn: (variables) => updatePrograms(props?.programsData?.id, variables),
        onSuccess: () => {
            props.onClose();
            queryClient.invalidateQueries(["programs"]);
            queryClient.invalidateQueries(["programs-hireachy"]);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
            console.log("create programs error : ", error);
        },
    });

    const handleSubmit = (data) => {
        console.log(data);

        editProgramsMutation.mutate(data);
    };

    return (
        <Dialog header="Program Form" visible={props.show} style={{ width: "50vw" }} onHide={() => props.onHide()} maximizable>
            {/* <h3>Programs Edit Form</h3> */}
            <p>Edit Data Below</p>
            <ProgramsRowForm ProgramsData={props.programsData} handleSubmit={handleSubmit} />
            {/* <h4>{creactProgramsMutation.status}</h4> */}

            {editProgramsMutation.isLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px", // Border thickness
                            borderColor: "blue", // Border color
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
        </Dialog>
    );
}

export default ProgramsEditForm;
