import axiosAPI from "../axiosApi";

export async function getAllQuarters(params = {}) {
    const response = await axiosAPI.get("quarters", { params: params });
    return response;
}

export async function getQuartersById(id) {
    const response = await axiosAPI.get(`quarters/` + id);
    return response;
}

export async function postQuarters(data) {
    const response = await axiosAPI.post(`quarters`, data);
    return response;
}

export async function updateQuarters(id, data) {
    const response = await axiosAPI.put(`quarters/${id}`, data);
    return response;
}

export async function deleteQuarters(id) {
    const response = await axiosAPI.delete(`quarters/${id}`);
    return response;
}
