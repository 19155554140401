import React, { useState, useEffect } from "react";

import { Dialog } from "primereact/dialog";

import { getAllPointWaterSources, filterPointWaterSources, getPointWaterSourceById, postPointWaterSources, updatePointWaterSources, deletePointWaterSourceById } from "../../../../../../services/national-dashboard/point-water-sources-service.js";

import RowForm from "./widgets/RowForm";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";

function FiltersForm({ filterQueryIsLoading, setFilterQueryIsLoading, filterParams, filterSelectedObjects, setFilterSelectedObjects, setFilterParams, loggedInUserData, waterPointsData, setWaterPointsData, ...props }) {
    const [name, setName] = useState();
    const [details, setDetails] = useState();
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(true);
    const [validated, setValidated] = useState(true);

    const queryClient = useQueryClient();

    const filterMutation = useMutation(filterPointWaterSources, {
        onSuccess: (data) => {
            // queryClient.invalidateQueries(["subprojects", "subproject-locations"]);
            handleOnSuccess(data);
            // props.onClose();
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
            console.log("create filtering water pont distribution sources error : ", error);
        },
    });

    const handleOnSuccess = async (data) => {
        setFilterQueryIsLoading(false);
        toast.success("Filtered Succesfully");
        console.log("map databdsfbdshjds : ", data);
        setWaterPointsData(data?.data);
    };

    const handleSubmit = async (data) => {
        setFilterQueryIsLoading(true);
        // event.preventDefault();
        console.log("national db data we are sub locations submitting : ", data);
        setWaterPointsData(null);
        // setFilterParams(data);
        setFilterParams(data);
        filterMutation.mutate(data);
    };

    return (
        <>
            <RowForm
                loggedInUserData={loggedInUserData}
                handleSubmit={handleSubmit}
                project_id={props?.projectId}
                selectedParentItem={props?.selectedParentItem}
                setFilterParams={setFilterParams}
                initialData={filterParams}
                filterSelectedObjects={filterSelectedObjects}
                setFilterSelectedObjects={setFilterSelectedObjects}
            />
            {/* <h4>{creactProgramsMutation.status}</h4> */}
            {filterMutation.isLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px", // Border thickness
                            borderColor: "blue", // Border color
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
        </>
    );
}

export default FiltersForm;
