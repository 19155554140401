import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import CreateForm from "./CreateForm";

import EditForm from "./EditForm";

import { deleteProgramById, getAllPrograms } from "../../../../../services/programs/program-service";

import moment from "moment";

import { Link } from "react-router-dom";

import { getAllDepartmentThemes, getDepartmentThemeById, postDepartmentTheme, updateDepartmentTheme, deleteDepartmentThemeById } from "../../../../../services/departments/utility-dept/departments-themes-service.js";

import WaterIsLoading from "../../../../../components/mweComponents/WaterIsLoading";
import MuiTable from "../../../../../components/mweComponents/MuiTable";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import { Panel } from "primereact/panel";
import { Tooltip } from "primereact/tooltip";

function ListPage({ selectedDepartmentItem, ...props }) {
    const queryClient = useQueryClient();

    const { data, isLoading, isError, error, status } = useQuery(["departments-themes", props?.departmentId], () => (!props?.departmentId ? getAllDepartmentThemes() : getAllDepartmentThemes({ department_id: props?.departmentId })), {
        onSuccess: (data) => {
            console.log("success fetching departments-themes is : ", data);
        },
        onError: (error) => {
            console.log("Error fetching departments-themes is : ", error);
        },
    });
    console.log(data);
    isError && toast.error("There was an error while fetching data");

    const DeleteSelectedItemMutation = useMutation((variables) => deleteDepartmentThemeById(variables), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(["departments-themes"]);
        },
    });

    // const handleDelete = async (event, id) => {
    //     var result = window.confirm("Are you sure you want to delete?");
    //     if (result === true) {
    //         ProgramDeleteMutation.mutate(id);
    //     }
    // };

    const handleDelete = (event, id) => {
        let selectedDeleteId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(selectedDeleteId),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (selectedDeleteId) => {
        if (selectedDeleteId !== null) {
            DeleteSelectedItemMutation.mutate(selectedDeleteId);
        }
    };

    const cancelDelete = () => {
        // setDeleteProgramId(null);
    };

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showBudjetOutPutAddForm, setShowBudjetOutPutAddForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    const onBudjetOutPutFormClose = () => {
        setShowBudjetOutPutAddForm(false);
    };

    let tableId = 0;
    const columns = [
        {
            title: "#",
            width: "5%",
            field: "name",
            render: (rowData) => {
                // tableId = rowData.tableData.id;
                tableId = tableId++;
                return <div>{rowData.tableData.index + 1}</div>;
                // return <div>{rowData.tableData.id}</div>;
            },
        },
        {
            title: "Name",
            // cellStyle: {
            //     whiteSpace: "nowrap",
            //     padding: "8px",
            // },
            field: "name",
            render: (rowData) => {
                return (
                    <Link
                        //programs/directorates/directorate/department/outcome
                        to="theme"
                        state={{ selectedThemeData: rowData }}
                        className="text-decoration-none"
                    >
                        <strong
                            onClick={(e) => {
                                // e.preventDefault();
                                ///programs/directorates/directorate/department/outcome
                                // setSelectedDepartmentIndicatorItem(rowData);
                                // setShowDepartmentIndicatorView(true);
                            }}
                            className="custom-target-icon text-decoration-none"
                            style={{
                                // display: "inline-block",
                                display: "inline-block",
                                padding: "5px 10px",

                                color: "blue",
                                borderRadius: "4px",
                                fontSize: "1em",
                                cursor: "pointer",
                            }}
                            data-pr-tooltip="Click to view indicators"
                            data-pr-position="top"
                        >
                            {rowData?.name}
                            <Tooltip target=".custom-target-icon" />
                        </strong>
                    </Link>
                );
            },
        },

        {
            title: "Code",
            field: "code",
            hidden: false,
        },
        {
            title: "Status",
            field: "status",
            hidden: true,
        },

        {
            title: "Date",
            hidden: true,
            field: "created_at",
            render: (rowData) => {
                return moment(rowData.created_at).format("lll");
            },
        },
    ];

    //================== budget outputs indicators ==================
    const [selectedDepartmentIndicatorItem, setSelectedDepartmentIndicatorItem] = useState();

    const [showDepartmentIndicatorView, setShowDepartmentIndicatorView] = useState(false);

    const handleCloseDepartmentIndicatorView = () => {
        setSelectedDepartmentIndicatorItem(null);
        setShowDepartmentIndicatorView(false);
    };

    return (
        <div style={{ width: "100%" }}>
            <div className="col-12 xl:col-12">
                <div className="card">
                    <p>Department Themes</p>
                </div>
            </div>
            <Panel header="Department Themes" style={{ marginBottom: "20px" }}>
                {/* <div className="d-flex justify-content-end mb-3" style={{ width: "100%" }}>
                    {activeUser?.permissions.includes("add programs") && <Button label="Add Program" className="p-button-primary right-0" onClick={() => setShowAddForm(true)} />}
                    <CreateForm show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} />
                </div> */}
                <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                    {/* {activeUser?.permissions.includes("add programs") && (
                        <Button
                            label="Add Budget Outputs"
                            className="p-button-primary"
                            onClick={() => {
                                setShowBudjetOutPutAddForm(true);
                                console.log("clicked add outcomes");
                            }}
                        />
                    )} */}
                    {activeUser?.permissions.includes("edit departments") && <Button label="Add Theme" className="p-button-primary" onClick={() => setShowAddForm(true)} />}
                    <CreateForm selectedDepartmentItem={selectedDepartmentItem} departmentId={props?.departmentId} show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} projectId={props?.projectId} />
                    {/* <BudgetOutPutsCreateForm show={showBudjetOutPutAddForm} onHide={() => setShowBudjetOutPutAddForm(false)} onClose={onBudjetOutPutFormClose} /> */}
                </div>
                <MuiTable
                    tableTitle="Department Themes"
                    tableData={data?.data?.data}
                    tableColumns={columns}
                    handleShowEditForm={handleShowEditForm}
                    handleDelete={(e, item_id) => handleDelete(e, item_id)}
                    showEdit={activeUser?.permissions.includes("edit departments")}
                    showDelete={activeUser?.permissions.includes("edit departments")}
                    loading={isLoading || status === "loading" || DeleteSelectedItemMutation.isLoading}
                    exportButton={true}
                    pdfExportTitle="Departments Themes"
                    csvExportTitle="Department Themes"
                />

                {selectedItem && <EditForm rowData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} />}

                {DeleteSelectedItemMutation.isLoading && <WaterIsLoading />}
            </Panel>
        </div>
    );
}

export default ListPage;
