import React, { useState, useMemo } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Tree } from "primereact/tree";

import { Dropdown } from "primereact/dropdown";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { classNames } from "primereact/utils";

import { getAllProjectDepartmentBudgetOutputs, getProjectDepartmentBudgetOutputById, postProjectDepartmentBudgetOutput, updateProjectDepartmentBudgetOutput, deleteProjectDepartmentBudgetOutputById } from "../../../../../services/projects/project-department-budget-outputs-service.js";

import { ProgressSpinner } from "primereact/progressspinner";
import setFieldTouched from "final-form-set-field-touched";
import { deleteSubProjectById, getAllSubProjects, getAllSubProjectsUnderUserProjectsByProjectIdOrRole } from "../../../../../services/sub-projects/sub-project-service";

//
import { toast } from "react-toastify";
import { AutoComplete } from "primereact/autocomplete";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DeleteIcon from "@mui/icons-material/Delete";

function RowForm({ handleSubmit, initialData, selectedprojectItem, subprojectDetail, ProjectsData = {}, ...props }) {
    console.log("subprojectDetail hdhdh : ", subprojectDetail);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);
    const queryClient = useQueryClient();

    console.log("initialData dfgsdv : ", initialData);
    const validate = (values) => {
        const errors = {};
        if (!values.subproject_id) errors.subproject_id = "A Sub Project is required";
        if (!values.project_department_budget_outputs_ids) errors.project_department_budget_outputs_ids = "Atleast Choose a budget Output";

        return errors;
    };

    const onSubmitForm = (data, form) => {
        // Add 'form' as an argument
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            // Mark all fields as touched to show validation errors
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("First fill in all required fields.");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    // ========================== fetch all sub projects =======================
    const fetchAllSubProjects = useQuery(["subprojects", subprojectDetail?.project_id], () => getAllSubProjects({ project_id: subprojectDetail?.project_id }), {
        onSuccess: (data) => {
            console.log("getAllProjects onsuccess fetching projects dsfsd : ", data);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
            console.log("get list of projects : ", error);
        },
    });

    initialData = { subproject_id: subprojectDetail?.id, ...initialData };

    //===================================== budget outputs ====================================

    const fetchAllProjectBudgetOutputs = useQuery(["project", "budgetOutputs", subprojectDetail?.project_id], () => (!subprojectDetail?.project_id ? getAllProjectDepartmentBudgetOutputs() : getAllProjectDepartmentBudgetOutputs({ project_id: subprojectDetail?.project_id })), {
        onSuccess: (data) => {
            console.log("getAllProjectDepartmentBudgetOutputs is : ", data);
        },
        onError: (error) => {
            console.log("Error fetching budget outputs is : ", error);
        },
    });

    //  const [dropdownItem, setDropdownItem] = useState([]);

    //  const [selectedAutoCompleteDropdownItem, setSelectedAutoCompleteDropdownItem] = useState(DirectorateData.programs || []);

    // const [dropdownDistricts, setDropdownDistricts] = useState([]);
    const [dropdownItem, setDropdownItem] = useState([]);
    // const [selectedDistrict, setSelectedDistrict] = useState(ProjectsData.districts || []);
    const [selectedAutoCompleteDropdownItem, setSelectedAutoCompleteDropdownItem] = useState(ProjectsData.districts || []);

    console.log("kkkkkkk selectedDistrict xxxxx  : ", selectedAutoCompleteDropdownItem);

    const projectWithDistrictIds = {
        department_id: ProjectsData?.department_id,
        department: ProjectsData?.department,
        ...ProjectsData,
        districtIds: ProjectsData?.districts?.map((district) => district.id),
    };

    console.log("ProjectDistrictIds : ", projectWithDistrictIds);

    // Special option for selecting all districts
    const selectAllOption = { id: "all", name: "Select All", department_budget_output: { name: "Select All", code: "Select All" } };

    //
    const fetchSuggestions = (query) => {
        let filteredItems = fetchAllProjectBudgetOutputs.data?.data?.data.filter((item) => {
            return !selectedAutoCompleteDropdownItem.map((sp) => sp.id).includes(item.id) && item?.department_budget_output?.name.toLowerCase().includes(query.toLowerCase());
        });

        // Add 'Select All' option only if no districts are selected
        if (selectedAutoCompleteDropdownItem.length > 0 && (query.toLowerCase() === "select all" || query === "")) {
            filteredItems = [selectAllOption, ...filteredItems];
        }

        setDropdownItem(filteredItems);
    };

    console.log("dropdownItem sdsfsd : ", dropdownItem);

    //=========================== data table pagination ======================
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(5); // Convert to state variable

    // Function to handle page changes
    const onPaging = (event) => {
        setPage(event.first / event.rows);
        setRows(event.rows); // Update number of rows per page
    };

    // if (initialData) {
    //     initialData = { project_department_budget_outputs_ids: initialData, ...initialData };
    // }
    return (
        <div className="col-12 md:col-12">
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    mutators={{ setFieldTouched }}
                    initialValuesEqual={() => true}
                    // initialValuesEqual with a function returning true prevents the form from
                    // reinitializing when the initialValues prop changes. This is useful when you
                    // want to avoid re-rendering or reinitializing the form due to changes in initial values.
                    // Be cautious using this if your initial values are meant to be dynamic and responsive
                    // to changes in your application's state.
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            // onSubmit={(event) => {
                            //     event.preventDefault();
                            //     console.log("values hhh : ", values);
                            //     console.log("event fffff : ", event);
                            //     onSubmitForm(values);
                            //     // handleSubmit(event, values);
                            // }}
                            onSubmit={handleSubmit}
                        >
                            <div style={{ marginBottom: "1rem" }}>
                                <Field name="subproject_id">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Sub Project</label>
                                            <Dropdown
                                                value={input.value}
                                                options={fetchAllSubProjects?.data?.data?.data.map((item) => ({
                                                    label: item?.name,
                                                    value: item?.id,
                                                }))}
                                                onChange={(e) => input.onChange(e.value)}
                                                placeholder="Select Project"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                disabled={fetchAllSubProjects.isLoading}
                                            />
                                            {fetchAllSubProjects.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <Field name="project_department_budget_outputs_ids">
                                {({ input, meta }) => {
                                    console.log("kkkkkkk first main ffffff input.value : ", input.value);
                                    return (
                                        <div className="p-field m-4">
                                            <label htmlFor="project_department_budget_outputs_ids">Budget ouputs</label>
                                            <AutoComplete
                                                {...input}
                                                suggestions={dropdownItem}
                                                completeMethod={(e) => fetchSuggestions(e.query)}
                                                multiple={true}
                                                value={selectedAutoCompleteDropdownItem}
                                                disabled={fetchAllProjectBudgetOutputs.isLoading} // Disable component if data is still loading
                                                field="department_budget_output.name"
                                                dropdown={true}
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                onChange={(e) => {
                                                    const newSelected = e.value;

                                                    // Handle 'Select All Districts'
                                                    if (newSelected.some((item) => item.id === "all")) {
                                                        // Add all districts to the selection
                                                        const allItems = fetchAllProjectBudgetOutputs.data?.data?.data || [];
                                                        setSelectedAutoCompleteDropdownItem(allItems);
                                                        input.onChange(allItems.map((district) => district.id));
                                                    } else {
                                                        if (newSelected.length < selectedAutoCompleteDropdownItem.length) {
                                                            // District removed
                                                            const removedDistrict = selectedAutoCompleteDropdownItem.find((district) => !newSelected.includes(district));
                                                            if (removedDistrict) {
                                                                const updatedSelectedDistricts = selectedAutoCompleteDropdownItem.filter((district) => district.id !== removedDistrict.id);
                                                                setSelectedAutoCompleteDropdownItem(updatedSelectedDistricts);
                                                                input.onChange(updatedSelectedDistricts.map((district) => district.id));
                                                            }
                                                        } else {
                                                            // District added
                                                            const addedDistrict = newSelected[newSelected.length - 1];
                                                            setSelectedAutoCompleteDropdownItem([...selectedAutoCompleteDropdownItem, addedDistrict]);
                                                            input.onChange([...input.value, addedDistrict.id]);
                                                        }
                                                    }

                                                    fetchSuggestions("");
                                                }}
                                                id="project_department_budget_outputs_ids"
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                            {/* DataTable to display selected programs */}
                                            {input.value && input.value.length > 0 && (
                                                <div className="p-field m-4">
                                                    <DataTable value={selectedAutoCompleteDropdownItem} paginator rows={rows} first={page * rows} onPage={onPaging} paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" rowsPerPageOptions={[5, 10, 20]}>
                                                        <Column field="id" header="ID"></Column>
                                                        <Column field="department_budget_output.name" header="Name"></Column>
                                                        <Column field="department_budget_output.code" header="Code"></Column>
                                                        <Column field="project.project_title" header="Project Name"></Column>
                                                        <Column
                                                            body={(rowData) => (
                                                                <DeleteIcon
                                                                    style={{ color: "tomato", cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        const updatedSelectedDistrict = selectedAutoCompleteDropdownItem.filter((district) => district.id !== rowData.id);
                                                                        setSelectedAutoCompleteDropdownItem(updatedSelectedDistrict);
                                                                        input.onChange(updatedSelectedDistrict.map((prog) => prog.id));
                                                                        fetchSuggestions(""); // Refresh the dropdown suggestions
                                                                    }}
                                                                />
                                                            )}
                                                            header="Actions"
                                                        ></Column>
                                                    </DataTable>
                                                </div>
                                            )}

                                            {fetchAllProjectBudgetOutputs.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        </div>
                                    );
                                }}
                            </Field>

                            <div className="d-grid gap-2">
                                <Button type="submit" label="Save" className="p-button-primary" icon="pi pi-check" />
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    style={{ width: "30vw" }}
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to submit?
                </Dialog>
            </div>
        </div>
    );
}

export default RowForm;
