import React, { useState, useMemo, useRef } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Tree } from "primereact/tree";

import { Dropdown } from "primereact/dropdown";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { classNames } from "primereact/utils";
import { getAllDepartments } from "../../../../../../services/departments/department-service";

import { deleteProgramById, getAllPrograms } from "../../../../../../services/programs/program-service";
import { deleteDistrictById, getAllDistricts } from "../../../../../../services/districts/districts-service";

import setFieldTouched from "final-form-set-field-touched";
import { getAllFinancialYears, getFinancialYearsById, postFinancialYears, updateFinancialYears, deleteFinancialYears } from "../../../../../../services/financial-years/financial-years-service";

//
import { toast } from "react-toastify";
import { AutoComplete } from "primereact/autocomplete";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DeleteIcon from "@mui/icons-material/Delete";
import { ProgressSpinner } from "primereact/progressspinner";
import { FileUpload } from "primereact/fileupload";
import { Toast as PrimeReactToast } from "primereact/toast";

function RowForm({ selectedDepartmentItem, handleSubmit, initialData, ProjectsData = {}, ...props }) {
    const primeReeacttoastRef = useRef(null);

    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);
    const queryClient = useQueryClient();

    console.log("initialData dfgsdv : ", initialData);
    const validate = (values) => {
        const errors = {};
        if (!values.department_id) errors.department_id = "A Department is required";
        if (!values.name) errors.name = "Name is required";
        if (!values.fyear_id) errors.fyear_id = "Financial Year is required";
        if (!values.description) errors.description = "Description is required";
        // if (!values.support_document) errors.support_document = "Support Document is required";
        return errors;
    };

    const onSubmitForm = (data, form) => {
        console.log("here is the data");
        data.support_document = uploadedPdfs;
        console.log(data);
        console.log(typeof data);
        console.log(uploadedPdfs[0]);
        console.log(typeof uploadedPdfs[0]);
        // Add 'form' as an argument
        const errors = validate(data);
        if (Object.keys(errors).length === 0 && !(uploadedPdfs.length === 0)) {
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            // Mark all fields as touched to show validation errors
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("First fill in all required fields.");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    //================ departments ==============
    const fetchAllDepartments = useQuery(["departments", "by-directorate", selectedDepartmentItem?.directorate_id], () => (!selectedDepartmentItem?.directorate_id ? getAllDepartments() : getAllDepartments({ directorate_id: selectedDepartmentItem?.directorate_id })), {
        onSuccess: (data) => {
            // setDepartments(data.data);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
            console.log("get list of departments : ", error);
        },
    });

    if (props?.departmentId) {
        initialData = { department_id: props?.departmentId, ...initialData };
    }

    const getAllFinancialYearsQuery = useQuery(["financialYear"], getAllFinancialYears, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching getAllFinancialYears is : ", error);
        },
    });

    //file upload code

    const fileUploadRef = useRef(null);

    const [uploadedPdfs, setUploadedPdfs] = useState([]);
    const onPdfUpload = (e) => {
        console.log("Uploaded files:", e.files);
        // Assuming you want to store the uploaded Pdfs in state
        // setUploadedPdfs([...uploadedPdfs, ...e.files]);
        setUploadedPdfs([...e.files]);
        // Additional logic for handling the upload can go here

        // If using a ref to a file input or PrimeReact's FileUpload component,
        // use the clear method to reset it
        if (fileUploadRef.current) {
            fileUploadRef.current.clear();
        }
    };

    // Function to remove a single Pdf
    const removePdf = (indexToRemove) => {
        setUploadedPdfs(uploadedPdfs.filter((_, index) => index !== indexToRemove));
        // Also remove the caption
        // const { [indexToRemove]: _, ...newCaptions } = PdfCaptions;
        // setPdfCaptions(newCaptions);
    };

    // Function to remove all Pdfs
    const removeAllPdfs = () => {
        setUploadedPdfs([]);
        // setPdfCaptions({});
    };

    const handleUploadSuccess = () => {
        // Clear the list of uploaded files
        setUploadedPdfs([]);
        // Additional success logic
    };
    const onPdfSelect = (e) => {
        // Limit the file selection to only one PDF file
        if (e.files.length > 1) {
            primeReeacttoastRef.current.show({ severity: "warn", summary: "Warning", detail: "You can only upload one PDF file.", life: 3000 });
            // Clear out the files if more than one is selected
            e.files = [];
            if (fileUploadRef.current) {
                fileUploadRef.current.clear();
            }
            return; // Exit the function to prevent further processing
        }

        // Check if the selected file is a PDF
        const isPdfFile = e.files.every((file) => file.name.toLowerCase().endsWith(".pdf"));
        if (!isPdfFile) {
            // Show warning if the file is not a PDF
            primeReeacttoastRef.current.show({ severity: "warn", summary: "Warning", detail: "Only PDF files are allowed.", life: 3000 });
            e.files = []; // Clear out the non-PDF files
            if (fileUploadRef.current) {
                fileUploadRef.current.clear();
            }
        } else {
            // If a PDF is selected, update the state
            setUploadedPdfs(e.files);
        }
    };

    //file upload code end

    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    // initialData = { project_id: props?.project_id, ...initialData };
    // console.log("props?.selectedDepartmentItem ooo :", selectedDepartmentItem);

    // if (initialData) {
    //     initialData = { budgetOutputsIds: initialData, ...initialData };
    // }
    return (
        <div className="col-12 md:col-12">
            <div className="card p-fluid">
                <PrimeReactToast ref={primeReeacttoastRef} />

                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    mutators={{ setFieldTouched }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            // onSubmit={(event) => {
                            //     event.preventDefault();
                            //     console.log("values hhh : ", values);
                            //     console.log("event fffff : ", event);
                            //     onSubmitForm(values);
                            //     // handleSubmit(event, values);
                            // }}
                            onSubmit={handleSubmit}
                        >
                            <div style={{ marginBottom: "1rem" }}>
                                <Field name="department_id">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Department</label>
                                            <Dropdown
                                                value={input.value}
                                                options={fetchAllDepartments?.data?.data?.data.map((item) => ({
                                                    label: item.name,
                                                    value: item.id,
                                                }))}
                                                onChange={(e) => input.onChange(e.value)}
                                                placeholder="Select Department"
                                                // disabled={fetchAllDepartments.isLoading}
                                                disabled={true}
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                            {fetchAllDepartments.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <Field name="name">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="name">Name</label>
                                        <InputText {...input} id="name" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && (meta.error || form.submitSuccess || form.submitError) && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="fyear_id">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label>Financial Year</label>
                                        <Dropdown
                                            value={input.value}
                                            options={getAllFinancialYearsQuery?.data?.data?.data.map((item) => ({
                                                label: item.name,
                                                value: item.id,
                                            }))}
                                            onChange={(e) => input.onChange(e.value)}
                                            // disabled={props?.loggedInUserData?.role !== "Admin"}
                                            placeholder="Select Financial Year"
                                            disabled={getAllFinancialYearsQuery.isLoading}
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                        />
                                        {getAllFinancialYearsQuery.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="description">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="description">Description</label>
                                        <InputText {...input} id="description" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            {/* <Field name="support_document">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="support_document">Upload PDF</label>
                                        <InputText {...input} id="support_document" type="file" accept=".pdf" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        {file && <p>Selected File: {file.name}</p>}
                                    </div>
                                )}
                            </Field> */}

                            <Field name="support_document">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="description">Support Document</label>
                                        <FileUpload {...input} ref={fileUploadRef} id="support_document" name="support_document" accept=".pdf" customUpload={true} onSelect={onPdfSelect} uploadHandler={onPdfUpload} emptyTemplate={<p className="m-0">Drag and drop a PDF here to upload.</p>} />

                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            {/* <Field name="status">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="status">Status</label>
                                        <Dropdown
                                            value={input.value}
                                            options={[
                                                { label: "Active", value: "active" },
                                                { label: "Deactivate", value: "deactivate" },
                                            ]}
                                            onChange={(e) => input.onChange(e.value)}
                                            placeholder="Select Status"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                        />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field> */}

                            <div className="d-grid gap-2">
                                <Button type="submit" label="Save" className="p-button-primary" icon="pi pi-check" />
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    style={{ width: "30vw" }}
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to submit?
                </Dialog>
            </div>
        </div>
    );
}

export default RowForm;
