import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Lottie from "lottie-react";
import NoData from "../../../assets/Animation - nodata lottie 1702085133252.json";

const AbsorptionRateChart = ({ data }) => {
    console.log("data absorption : ", data);
    // Check if data is empty
    if (!Array.isArray(data) || data.length === 0) {
        return (
            <center>
                <Lottie animationData={NoData} loop={true} autoplay={true} style={{ width: "300px" }} />
            </center>
        );
    }

    // Prepare the chart data
    const approvedBudgetData = data.map((item) => ({
        name: item.department.name,
        y: parseFloat(item.approved_budget),
    }));

    const spentFundsData = data.map((item) => ({
        name: item.department.name,
        y: parseFloat(item.spent_funds),
    }));

    // Chart options
    const options = {
        chart: {
            type: "column",
        },
        title: {
            text: "Budget Absorption",
        },
        xAxis: {
            type: "category",
            title: {
                text: "Department",
            },
        },
        yAxis: {
            min: 0,
            title: {
                text: "Amount (UGX)",
            },
        },
        credits: {
            enabled: false,
        },
        series: [
            {
                name: "Released Funds",
                data: approvedBudgetData,
                color: "#4CAF50", // Example color
            },
            {
                name: "Spent Funds",
                data: spentFundsData,
                color: "#F44336", // Example color
            },
        ],
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default AbsorptionRateChart;
