import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";

import { updateDirectorate } from "../../../services/departments/directorate-service";
import DirectorateRowForm from "./widgets/DirectorateRowForm";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

function DirectorateEditForm(props) {
    const queryClient = useQueryClient();

    const editDirectorateMutation = useMutation({
        mutationFn: (variables) => updateDirectorate(props?.directoratedata?.id, variables),
        onSuccess: () => {
            queryClient.invalidateQueries(["directorate"]);
            queryClient.invalidateQueries(["programs", "programs-hireachy"]);
            toast.success("Directorate updated successfully");
            props.onHide();
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
            console.log("edit programs error : ", error);
        },
    });

    const handleSubmit = (data) => {
        // event.preventDefault();
        // console.log("Directorate data on edit : ", data);
        editDirectorateMutation.mutate(data);
    };

    console.log("selected props directorates data is : ", props.directoratedata);

    const footer = (
        <>
            {editDirectorateMutation.isLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px",
                            borderColor: "blue",
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={() => props.onHide()} />
        </>
    );

    return (
        <Dialog maximizable header="Directorate Edit Form" visible={props.show} modal footer={footer} onHide={() => props.onHide()}>
            <p>Fill in the form below</p>
            <DirectorateRowForm DirectorateData={props.directoratedata} handleSubmit={handleSubmit} />
        </Dialog>
    );
}

export default DirectorateEditForm;
