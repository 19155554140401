import React from "react";
import { Card } from "primereact/card";

const BudgetHistory = ({ selectedSubProjectReports }) => {
    // Function to format numbers with commas
    const formatCurrency = (value) => {
        // Assuming value is a string that represents a number
        // Convert it to a number and then format
        const number = parseFloat(value);
        return number.toLocaleString("en-US"); // Adjust locale as needed
    };

    return (
        <div>
            {selectedSubProjectReports.map((report, index) => (
                <Card key={index} title={report.subproject.name} subTitle={`Financial Year: ${report.financial_year.name}`}>
                    <p>Released Funds: {formatCurrency(report.approved_budget)}</p>
                    <p>Spent Funds: {formatCurrency(report.spent_funds)}</p>
                    <p>Absorption Rate: {report.absorption_rate}%</p>
                    <p>Details: {report.description}</p>
                </Card>
            ))}
        </div>
    );
};

export default BudgetHistory;
