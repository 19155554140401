import axiosAPI from "../axiosApi";

export async function getAllDepartmentBudgets(params = {}) {
    const response = await axiosAPI.get("departmentBudget", { params: params });
    return response;
}

export async function getDepartmentBudgetById(id) {
    const response = await axiosAPI.get(`departmentBudget/${id}`);
    return response;
}

export async function postDepartmentBudget(data) {
    const response = await axiosAPI.post(`departmentBudget`, data);
    return response;
}

export async function updateDepartmentBudget(id, data) {
    const response = await axiosAPI.put(`departmentBudget/${id}`, data);
    return response;
}

export async function deleteDepartmentBudgetById(id) {
    const response = await axiosAPI.delete(`departmentBudget/${id}`);
    return response;
}
