import axiosAPI from "../axiosApi";

export async function getAllOperationAndMaintenanceStructures(params = {}) {
    const response = await axiosAPI.get("o-m-Structure", { params: params });
    return response;
}

export async function getOperationAndMaintenanceStructuresById(id) {
    const response = await axiosAPI.get(`o-m-Structure/${id}`);
    return response;
}

export async function postOperationAndMaintenanceStructures(data) {
    const response = await axiosAPI.post(`o-m-Structure`, data);
    return response;
}

export async function updateOperationAndMaintenanceStructures(id, data) {
    const response = await axiosAPI.put(`o-m-Structure/${id}`, data);
    return response;
}

export async function deleteOperationAndMaintenanceStructuresById(id) {
    const response = await axiosAPI.delete(`o-m-Structure/${id}`);
    return response;
}
