import React from "react";
import ListPage from "./ListPage";
import { Link } from "react-router-dom";

import BreadcrumbNav from "../../../../../../components/mweComponents/BreadcrumbNav";

const createBreadCrump = () => {};
//
function DepartmentThemeIndicatorsPage({ selectedThemeData, departmentOutcomeId }) {
    let linksData = [{ name: "programs" }];
    return (
        <div>
            {/* <BreadcrumbNav /> */}

            <ListPage selectedThemeData={selectedThemeData} departmentOutcomeId={departmentOutcomeId} />
        </div>
    );
}

export default DepartmentThemeIndicatorsPage;
