import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import CreateForm from "./CreateForm";

import EditForm from "./EditForm";

import moment from "moment";

import { Link } from "react-router-dom";

import { getAllOutputIndicators, getOutputIndicatorById, postOutputIndicator, updateOutputIndicator, deleteOutputIndicatorById } from "../../../../services/directorates/output-indicator-service.js";

import WaterIsLoading from "../../../../components/mweComponents/WaterIsLoading";
import MuiTable from "../../../../components/mweComponents/MuiTable";
import { toast } from "react-toastify";
import { Button } from "primereact/button";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

import { Panel } from "primereact/panel";

function ListPage({ selectedDirectorateItem, ...props }) {
    console.log("selectedDirectorateItem hhhh : ", selectedDirectorateItem);
    const queryClient = useQueryClient();
    const { data, isLoading, isError, error, status } = useQuery(["directorate", "output-indicator", selectedDirectorateItem?.id], () => (!selectedDirectorateItem?.id ? getAllOutputIndicators() : getAllOutputIndicators({ directorate_id: selectedDirectorateItem?.id })), {
        onSuccess: (data) => {
            console.log("fetching departmentBudgetOutputsIndicators data is : ", data);
        },
        onError: (error) => {
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
            console.log("Error fetching departmentBudgetOutputsIndicators is : ", error);
        },
    });

    const DeleteSelectedItemMutation = useMutation((variables) => deleteOutputIndicatorById(variables), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(["directorate", "output-indicator"]);
        },
        onError: (error) => {
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
            console.log("Error fetching departmentBudgetOutputsIndicators is : ", error);
        },
    });

    // const handleDelete = async (event, id) => {
    //     var result = window.confirm("Are you sure you want to delete?");
    //     if (result === true) {
    //         ProgramDeleteMutation.mutate(id);
    //     }
    // };

    const handleDelete = (event, id) => {
        let selectedDeleteId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(selectedDeleteId),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (selectedDeleteId) => {
        if (selectedDeleteId !== null) {
            DeleteSelectedItemMutation.mutate(selectedDeleteId);
        }
    };

    const cancelDelete = () => {
        // setDeleteProgramId(null);
    };

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showBudjetOutPutAddForm, setShowBudjetOutPutAddForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    const onBudjetOutPutFormClose = () => {
        setShowBudjetOutPutAddForm(false);
    };

    let tableId = 0;
    const columns = [
        {
            title: "#",
            width: "5%",
            field: "name",
            render: (rowData) => {
                // tableId = rowData.tableData.id;
                tableId = tableId++;
                return <div>{rowData.tableData.index + 1}</div>;
                // return <div>{rowData.tableData.id}</div>;
            },
        },
        {
            title: "Name",
            field: "name",
        },
        {
            title: "Code",
            field: "code",
        },
        { title: "measure", field: "measure", hidden: true },
        { title: "Unit", field: "unit", hidden: true },
        { title: "multiplier", field: "multiplier", hidden: true },

        { title: "Directorate", field: "directorate.name" },
        {
            title: "Type",
            field: "output_indicator_type.name",
            hidden: true,
            render: (rowData) => {
                return rowData?.output_indicator_type ? rowData?.output_indicator_type?.name : "N/A";
            },
        },
        {
            title: "Defination",
            field: "definition",
            hidden: true,
        },
        {
            title: "Question",
            field: "question",
            hidden: true,
        },
        {
            title: "Tracks Cordinates",
            field: "tracks_coordinates",
            render: (rowData) => (rowData?.tracks_coordinates ? "True" : "False"),
            hidden: true,
        },

        {
            title: "Date",
            hidden: true,
            field: "created_at",
            render: (rowData) => {
                return moment(rowData.created_at).format("lll");
            },
        },
    ];

    return (
        <div style={{ width: "100%" }}>
            <div className="col-12 xl:col-12">
                <div className="card">
                    <p>Outputs Indicators</p>
                </div>
            </div>
            <Panel header="Output Indicators" style={{ marginBottom: "20px" }}>
                {/* <div className="d-flex justify-content-end mb-3" style={{ width: "100%" }}>
                    {activeUser?.permissions.includes("add programs") && <Button label="Add Program" className="p-button-primary right-0" onClick={() => setShowAddForm(true)} />}
                    <CreateForm show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} />
                </div> */}

                <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                    {/* {activeUser?.permissions.includes("add programs") && (
                        <Button
                            label="Add Budget Outputs"
                            className="p-button-primary"
                            onClick={() => {
                                setShowBudjetOutPutAddForm(true);
                                console.log("clicked add outcomes");
                            }}
                        />
                    )} */}
                    {activeUser?.permissions.includes("add projects") && <Button label="Add Output Indicator" className="p-button-primary" onClick={() => setShowAddForm(true)} />}
                    <CreateForm show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} projectId={props?.projectId} selectedDirectorateItem={selectedDirectorateItem} />
                    {/* <BudgetOutPutsCreateForm show={showBudjetOutPutAddForm} onHide={() => setShowBudjetOutPutAddForm(false)} onClose={onBudjetOutPutFormClose} /> */}
                </div>

                <MuiTable
                    tableTitle="Output Indicators"
                    tableData={data?.data?.data}
                    tableColumns={columns}
                    handleShowEditForm={handleShowEditForm}
                    handleDelete={(e, item_id) => handleDelete(e, item_id)}
                    showEdit={activeUser?.permissions.includes("edit programs") || activeUser?.permissions.includes("delete programs")}
                    showDelete={activeUser?.permissions.includes("delete programs")}
                    loading={isLoading || status === "loading" || DeleteSelectedItemMutation.isLoading}
                    exportButton={true}
                    pdfExportTitle={"Directorate Output Indicators"}
                    csvExportTitle={"Directorate Output Indicators"}
                />

                {selectedItem && <EditForm rowData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} />}

                {DeleteSelectedItemMutation.isLoading && <WaterIsLoading />}
                {/* <ConfirmDialog /> */}
            </Panel>
        </div>
    );
}

export default ListPage;
