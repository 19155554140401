// RecommendationsFormModal.js
import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import setFieldTouched from "final-form-set-field-touched";
import { classNames } from "primereact/utils";

const RecommendationsFormModal = ({ onHide, onSubmit }) => {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);

    const validate = (data) => {
        const errors = {};
        if (!data.challenges) errors.challenges = "Challenges are required";
        if (!data.recommendations) errors.recommendations = "Recommendations are required";
        return errors;
    };

    const onConfirm = (e) => {
        // e.preventDefault();
        // Prevent default behavior only if the event object exists
        if (e) e.preventDefault();
        if (pendingData) {
            onSubmit(pendingData);
        }
        setShowConfirmDialog(false);
        onHide();
    };

    const onCancel = (e) => {
        // Prevent default behavior only if the event object exists
        if (e) e.preventDefault();
        // e.preventDefault();
        setShowConfirmDialog(false);
    };

    const onSubmitForm = (data, form) => {
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
        }
    };

    const handleManualSubmit = (form) => {
        const values = form.getState().values;
        onSubmitForm(values, form);
    };

    return (
        <>
            <Dialog header="Challenges & Recommendations" visible onHide={onHide} style={{ minWidth: "60vw" }} maximizable>
                <div className="p-fluid">
                    <div className="card p-fluid">
                        <Form
                            onSubmit={onSubmitForm}
                            validate={validate}
                            mutators={{ setFieldTouched }}
                            render={({ handleSubmit, form, submitting, pristine }) => (
                                <form
                                    onSubmit={(event) => {
                                        event.preventDefault();
                                        onSubmitForm(form.getState().values, form);
                                    }}
                                >
                                    <Field name="challenges">
                                        {({ input, meta }) => (
                                            <div className="field">
                                                <label>Challenges</label>
                                                <InputTextarea {...input} rows={5} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="recommendations">
                                        {({ input, meta }) => (
                                            <div className="field">
                                                <label>Recommendations</label>
                                                <InputTextarea {...input} rows={5} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                            </div>
                                        )}
                                    </Field>
                                    <Button type="button" label="Submit" disabled={submitting || pristine} onClick={() => handleManualSubmit(form)} />
                                </form>
                            )}
                        />
                    </div>
                </div>
            </Dialog>

            <Dialog
                header="Confirmation"
                visible={showConfirmDialog}
                onHide={onCancel}
                footer={
                    <div>
                        <Button label="Yes" onClick={onConfirm} />
                        <Button label="No" onClick={onCancel} className="p-button-secondary" />
                    </div>
                }
            >
                Are you sure you want to submit?
            </Dialog>
        </>
    );
};

export default RecommendationsFormModal;
