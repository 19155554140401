import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";

const RecomendationsHistory = ({ selectedSubProjectReports }) => {
    return (
        <div>
            {selectedSubProjectReports.map((report, index) => (
                <Card key={index} title={report.subproject.name} subTitle={`Financial Year: ${report.financial_year.name}`}>
                    {/* <p>Description: {report.description}</p>
                    <p>Approved Budget: {report.approved_budget}</p>
                    <p>Spent Funds: {report.spent_funds}</p>
                    <p>Absorption Rate: {report.absorption_rate}</p>
                    <p>Current Status: {report.current_status.name}</p> */}

                    {/* <h3>Challenges And Recommendations History</h3> */}
                    <DataTable value={report.recommendations}>
                        <Column field="challenges" header="Challenges"></Column>
                        <Column field="recommendations" header="Recommendations"></Column>
                    </DataTable>
                </Card>
            ))}
        </div>
    );
};

export default RecomendationsHistory;
