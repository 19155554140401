import React, { Suspense, useState, useEffect, useRef } from "react";
import { Route, useLocation, useParams, Routes, Link } from "react-router-dom";
import ProgramsList from "./ProgramsList";

import BreadcrumbNav from "../../../components/mweComponents/BreadcrumbNav";

const createBreadCrump = () => {};
//
function ProgramsPage() {
    let linksData = [{ name: "programs" }];
    return (
        <div>
            <BreadcrumbNav />

            <ProgramsList />
            {/* <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route exact path=":programId" element={<route.element location={location} loggedInUserData={getUserQuery?.data?.data} />} />;
                </Routes>
            </Suspense> */}
        </div>
    );
}

export default ProgramsPage;
