import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import { Checkbox } from "primereact/checkbox";

import { Dropdown } from "primereact/dropdown";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getAllOutputIndicatorTypes, getOutputIndicatorTypesById, postOutputIndicatorTypes, updateOutputIndicatorTypes, deleteOutputIndicatorTypesById } from "../../../../../services/settings/output-indicator-types-service.jsx";

import { classNames } from "primereact/utils";

import { getAllDirectorates } from "../../../../../services/departments/directorate-service";
import setFieldTouched from "final-form-set-field-touched";

function RowForm({ handleSubmit, initialData, selectedDirectorateItem, ...props }) {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);
    const queryClient = useQueryClient();

    const validate = (values) => {
        const errors = {};
        if (!values.code) errors.code = "Code is required";
        if (!values.name) errors.name = "Name is required";
        if (!values.definition) errors.definition = "definition is required";
        if (!values.measure) errors.measure = "measure is required";
        // if (!values.multiplier) errors.multiplier = "Multiplier Total is required";
        if (!values.directorate_id) errors.directorate_id = "directorate is required";
        if (!values.unit) errors.unit = "Unit is required";
        if (!values.question) errors.question = "Question is required";

        return errors;
    };

    // const onSubmitForm = (data) => {
    //     const errors = validate(data);
    //     if (Object.keys(errors).length === 0) {
    //         // No validation errors
    //         setPendingData(data);
    //         setShowConfirmDialog(true);
    //     } else {
    //         toast.warning("First Fill In All Required Fields");
    //     }
    // };

    const onSubmitForm = (data, form) => {
        // Add 'form' as an argument
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            // Mark all fields as touched to show validation errors
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("First fill in all required fields.");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    if (selectedDirectorateItem) {
        initialData = { directorate_id: selectedDirectorateItem?.id, ...initialData };
    }

    //==================== directorates =========================
    const directorates = useQuery(["directorates"], getAllDirectorates);

    let dropdownDirectorates = directorates?.data?.data?.data?.map((item) => ({ label: item.name, value: item.id }));

    //================ OUTPUT INDICATOR tyoes ==============
    const fetchAllOutputIndicatorTypes = useQuery(["output-indicator-types"], () => getAllOutputIndicatorTypes(), {
        onSuccess: (data) => {
            // setDepartments(data.data);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
            console.log("get list of departments : ", error);
        },
    });

    console.log("initial data when editing output indicators : ", initialData);

    return (
        <div className="col-12 md:col-12">
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    mutators={{ setFieldTouched }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                console.log("values hhh : ", values);
                                console.log("event fffff : ", event);
                                onSubmitForm(values, form);
                                // handleSubmit(event, values);
                            }}
                        >
                            <Field name="name">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="name">Name</label>
                                        <InputText {...input} id="name" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="code">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="code">Code</label>
                                        <InputText {...input} id="code" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <div style={{ marginBottom: "1rem" }}>
                                <Field name="measure">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Measure</label>
                                            <Dropdown
                                                value={input.value}
                                                options={[
                                                    { label: "Qualitative", value: "qualitative" },
                                                    { label: "Quantitative", value: "quantitative" },
                                                ]}
                                                onChange={(e) => input.onChange(e.value)}
                                                placeholder="Select Measure"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <Field name="unit">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="unit">Unit</label>
                                        <InputText {...input} id="unit" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="multiplier">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="multiplier">Multiplier</label>
                                        <InputText
                                            {...input}
                                            id="multiplier"
                                            type="number"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                // Allow only non-negative numbers
                                                if (!value || parseFloat(value) >= 0) {
                                                    input.onChange(value);
                                                }
                                            }}
                                        />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="output_indicator_types_id">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="output_indicator_types_id">Type</label>
                                        <Dropdown {...input} options={fetchAllOutputIndicatorTypes?.data?.data?.data?.map((item) => ({ label: item.name, value: item.id }))} id="output_indicator_types_id" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="directorate_id">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="directorate_id">Directorate</label>
                                        <Dropdown {...input} options={dropdownDirectorates} id="directorate_id" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="definition">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="definition">Brief definition</label>
                                        <InputTextarea {...input} rows={5} cols={30} id="definition" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="question">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="question">Question</label>
                                        <InputTextarea {...input} rows={5} cols={30} id="question" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="tracks_coordinates">
                                {({ input, meta }) => (
                                    <div className="p-field-checkbox m-4">
                                        <Checkbox inputId="tracks_coordinates" checked={!!input.value} onChange={(e) => input.onChange(e.checked)} />
                                        <label htmlFor="tracks_coordinates">Tracks Coordinates</label>
                                    </div>
                                )}
                            </Field>

                            <Field name="frequency">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="frequency">Frequency</label>
                                        <InputText {...input} id="frequency" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="data_source">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="data_source">Data Source</label>
                                        <InputTextarea {...input} id="data_source" rows={5} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>
                            <Field name="numerator">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="numerator">Numerator</label>
                                        <InputTextarea {...input} id="numerator" rows={5} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>
                            <Field name="denominator">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="denominator">Denominator</label>
                                        <InputTextarea {...input} id="denominator" rows={5} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <div className="d-grid gap-2">
                                <Button type="submit" label="Save" className="p-button-primary" icon="pi pi-check" />
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    style={{ width: "30vw" }}
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to submit?
                </Dialog>
            </div>
        </div>
    );
}

export default RowForm;
