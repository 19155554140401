import axiosAPI from "../axiosApi";

// export async function getAllProjectFieldData(params = {}) {
//     const response = await axiosAPI.get("project-field-data", { params: params });
//     return response;
// }

// export async function getProjectFieldDataById(id) {
//     const response = await axiosAPI.get(`project-field-data/${id}`);
//     return response;
// }

// export async function postProjectFieldData(data) {
//     const response = await axiosAPI.post(`project-field-data`, data);
//     return response;
// }

// export async function storeProjectFieldData(data) {
//     const response = await axiosAPI.post(`storeProjectFieldData`, data, {
//         headers: { "Content-Type": "multipart/form-data" },
//     });
//     return response;
// }

// export async function customUpdateProjectFieldData(data) {
//     const response = await axiosAPI.post(`updateProjectFieldData`, data, {
//         headers: { "Content-Type": "multipart/form-data" },
//     });
//     return response;
// }

// export async function updateProjectFieldData(id, data) {
//     const response = await axiosAPI.put(`project-field-data/${id}`, data);
//     return response;
// }

// export async function deleteProjectFieldDataById(id) {
//     const response = await axiosAPI.delete(`project-field-data/${id}`);
//     return response;
// }

// export async function updateProjectDataStatus(data) {
//     const response = await axiosAPI.post("projectfielddata-update-status", data);
//     return response;
// }

export async function postToAggregateDepartmentReports(data) {
    const response = await axiosAPI.post(`aggregateDepartmentReports`, data);
    return response;
}
