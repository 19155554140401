import axiosAPI from "../../axiosApi";

export async function getAllDepartmentUtilityIndicators(params = {}) {
    const response = await axiosAPI.get("utility-indicators", { params: params });
    return response;
}

export async function getDepartmentUtilityIndicatorById(id) {
    const response = await axiosAPI.get(`utility-indicators/${id}`);
    return response;
}

export async function postDepartmentUtilityIndicators(data) {
    const response = await axiosAPI.post(`utility-indicators`, data);
    return response;
}
export async function postToFilterKPITargets(data) {
    const response = await axiosAPI.post(`utility-kpi-targets`, data);
    return response;
}

export async function updateDepartmentUtilityIndicators(id, data) {
    const response = await axiosAPI.put(`utility-indicators/${id}`, data);
    return response;
}

export async function deleteDepartmentUtilityIndicatorById(id) {
    const response = await axiosAPI.delete(`utility-indicators/${id}`);
    return response;
}
