import axiosAPI from "../axiosApi";

export async function filterReports(data) {
    const response = await axiosAPI.post(`filter-reports`, data);
    return response;
}

export async function reportsTracker(data) {
    const response = await axiosAPI.post(`reports-tracker`, data);
    return response;
}
