import React from "react";
import moment from "moment";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Image } from "primereact/image";
import { Dialog } from "primereact/dialog";
import { ProgressBar } from "primereact/progressbar";
import { Tag } from "primereact/tag";

function SubprojectPerfomaceReportPrint({ subprojectDetail, selectedItem, ...props }) {
    // Helper function to format currency
    const formatCurrency = (value) => {
        return new Intl.NumberFormat("en-US", { style: "currency", currency: "UGX" }).format(value);
    };

    // Helper function to format date
    const formatDate = (dateString) => {
        return moment(dateString).format("MMMM Do YYYY, h:mm:ss a");
    };

    // Inline styles
    const tableHeaderStyle = {
        backgroundColor: "#00B9E8",
        color: "white",
        padding: "10px",
        textAlign: "left",
    };

    const tableCellStyle = {
        padding: "10px",
    };

    const cardStyle = {
        marginBottom: "20px",
        padding: "15px",
        border: "1px solid #ddd",
        borderRadius: "5px",
    };

    const cardTitleStyle = {
        color: "#00B9E8",
        fontWeight: "bold",
        marginBottom: "10px",
    };

    // Inline styles
    const tableStyle = {
        width: "100%",
        marginBottom: "20px",
        borderCollapse: "collapse",
    };

    const thStyle = {
        backgroundColor: "#00B9E8",
        color: "white",
        padding: "10px",
        textAlign: "left",
        border: "1px solid #ddd",
    };

    const tdStyle = {
        padding: "10px",
        border: "1px solid #ddd",
    };

    const riskTypeBodyTemplate = (rowData) => {
        let color;
        switch (rowData.risk_type) {
            case "high":
                color = "red";
                break;
            case "moderate":
                color = "orange";
                break;
            case "low":
                color = "grey";
                break;
            default:
                color = "black";
        }

        return (
            <div>
                <span className="p-mr-2" style={{ fontSize: "1.5rem", color, fontWeight: "bold" }}>
                    •
                </span>
                {rowData.risk_type.charAt(0).toUpperCase() + rowData.risk_type.slice(1)}
            </div>
        );
    };

    const gridStyle = {
        display: "grid",
        gridTemplateColumns: "1fr 1fr", // This creates two columns of equal width
        gridGap: "1rem", // Space between the columns
    };

    // Function to display status with color using Tag
    const tagStatus = (status) => {
        let severity;
        switch (status) {
            case "pending approval":
                severity = "info"; // blue
                break;
            case "approved":
                severity = "success"; // green
                break;
            case "pending":
                severity = "warning"; // orange
                break;
            case "rejected":
                severity = "danger"; // red
                break;
            default:
                severity = "secondary"; // grey or default
        }
        return <Tag severity={severity} value={status?.charAt(0)?.toUpperCase() + status?.slice(1)} />;
    };

    return (
        <div style={{ backgroundColor: "white" }}>
            {/* Ministry Logo */}
            <div style={{ display: "flex", gap: "1rem", textAlign: "center", alignItems: "center", justifyContent: "center", margin: "1rem", width: "100%" }}>
                <img src="/images/coatOfArms.png" alt="Coat of Arms of Uganda" style={{ width: "20px", height: "auto" }} />{" "}
                <span className="font-medium ml-2">
                    <span>Ministry of Water and Environment</span> <br /> <span>M & E Portal Perfomance Report</span>
                </span>
            </div>

            <div style={cardStyle}>
                <div style={cardTitleStyle}>Report Details</div>
                <div className="flex flex-wrap">
                    {/* Column 1 */}
                    <div className="md:w-1/2 px-2 m-1">
                        {/* Display Program and Directorate Details */}
                        {selectedItem?.subproject?.project?.department_administration.department?.program_directorate_department?.map((pdd, index) => {
                            const programName = pdd?.program_directorate?.program?.name || "N/A";
                            const directorateName = pdd?.program_directorate?.directorate?.name || "N/A";
                            const uniqueKey = `pdd-${pdd?.program_directorate?.program?.id}-${pdd?.program_directorate?.directorate?.id}-${index}`;

                            return (
                                <div key={uniqueKey}>
                                    <p>
                                        <strong>Program:</strong> {programName}
                                    </p>
                                    <p>
                                        <strong>Directorate:</strong> {directorateName}
                                    </p>
                                </div>
                            );
                        })}

                        <p>
                            <strong>Department Name:</strong> {selectedItem?.subproject?.project?.department_administration?.department?.name || "N/A"}
                        </p>

                        <p>
                            <strong>Department Administration Name:</strong> {selectedItem?.subproject?.project?.department_administration?.name || "N/A"}
                        </p>

                        <p>
                            <strong>Project Title:</strong> {subprojectDetail?.project?.project_title || "N/A"}
                        </p>
                        <p>
                            <strong>Project Code:</strong> {subprojectDetail?.project?.project_code}
                        </p>
                    </div>

                    {/* Column 2 */}
                    <div className="md:w-1/2 px-2 m-1">
                        <p>
                            <strong>Subproject Name:</strong> {selectedItem?.subproject?.name || "N/A"}
                        </p>
                        <p>
                            <strong>Subproject Code:</strong> {selectedItem?.subproject?.code}
                        </p>
                        <p>
                            <strong>Approval Status:</strong> {tagStatus(selectedItem?.current_status?.name)}
                        </p>
                        <p>
                            <strong>Approval Comment:</strong> {selectedItem?.current_status?.reason}
                        </p>
                        <p>
                            <strong>Financial Year:</strong> {selectedItem?.financial_year?.name}
                        </p>
                        <p>
                            <strong>Quarter:</strong> {selectedItem?.quarter?.name}
                        </p>
                    </div>

                    {/* Full Width Row for Details */}
                    <div className="w-full px-2 m-2">
                        <strong>Description:</strong>
                        <p>{selectedItem?.description}</p>
                    </div>
                </div>
            </div>

            {/* Budget Details */}
            <div style={cardStyle}>
                <div style={cardTitleStyle}>Budget Details</div>
                <div style={gridStyle}>
                    <p>
                        <strong>Released Funds:</strong> {formatCurrency(selectedItem?.approved_budget)}
                    </p>
                    <p>
                        <strong>Spent Funds:</strong> {formatCurrency(selectedItem?.spent_funds)}
                    </p>
                    <p>
                        <strong>Absorption Rate:</strong> {selectedItem?.absorption_rate}%
                    </p>
                    {/* <p>
                        <strong>Description:</strong> {selectedItem?.description}
                    </p> */}
                </div>
            </div>

            {/* Budget Outputs */}
            {selectedItem?.budget_outputs.map((output, index) => (
                <div key={index} style={cardStyle}>
                    <div style={cardTitleStyle}>Budget Output: {output?.subproject_budget_output?.project_department_budget_output?.department_budget_output?.name}</div>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={thStyle}>Indicator</th>
                                <th style={thStyle}>Unit</th>
                                {/* <th style={thStyle}>Financial Year</th>
                                <th style={thStyle}>Quarter</th> */}
                                <th style={thStyle}>Target</th>
                                <th style={thStyle}>Value</th>
                                <th style={thStyle}>Reason of Variance</th>
                                <th style={thStyle}>Progress to Date</th>
                                <th style={thStyle}>Village Details</th> {/* Add this header for Village Details */}
                            </tr>
                        </thead>
                        <tbody>
                            {output?.indicators.map((indicator, idx) => {
                                let tracks_coordinates = indicator?.subproject_bgt_output_indicator_target?.project_dept_bgt_output_indicator_target?.department_budget_output_indicator?.output_indicator?.tracks_coordinates;
                                return (
                                    <tr key={idx}>
                                        <td style={tdStyle}>{indicator.subproject_bgt_output_indicator_target.project_dept_bgt_output_indicator_target.department_budget_output_indicator.output_indicator.name}</td>
                                        <td style={tdStyle}>{indicator.subproject_bgt_output_indicator_target.project_dept_bgt_output_indicator_target.department_budget_output_indicator.output_indicator.unit}</td>
                                        {/* <td style={tdStyle}>{indicator.subproject_bgt_output_indicator_target.financial_year.name}</td>
                                        <td style={tdStyle}>{indicator.subproject_bgt_output_indicator_target.quarter.name}</td> */}
                                        <td style={tdStyle}>{indicator.subproject_bgt_output_indicator_target.target}</td>
                                        <td style={tdStyle}>{parseInt(indicator.value).toString()}</td>
                                        <td style={tdStyle}>{indicator.reasonOfVariance || "N/A"}</td>
                                        <td style={tdStyle}>{indicator.assessment}</td>
                                        {/* Add Village Details for each indicator */}
                                        <td style={tdStyle}>
                                            {indicator.budget_outputs_villages?.map((villageData, villageIndex) => (
                                                <div key={villageIndex} style={{ marginBottom: "10px" }}>
                                                    <strong>Village Name:</strong> {villageData.village.name}
                                                    <br />
                                                    {villageData.budget_output_indicators_at_village?.map((item, itemIndex) => (
                                                        <div key={itemIndex} style={{ marginTop: "5px" }}>
                                                            <strong>Value:</strong> {item.value}
                                                            {tracks_coordinates ? (
                                                                <>
                                                                    <br />
                                                                    <strong>Coordinates:</strong>
                                                                    {item.subpjct_field_data_coordinates?.map((coord, coordIndex) => (
                                                                        <div key={coordIndex} style={{ cursor: "pointer", textDecoration: "underline", marginTop: "5px" }} onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${coord.latitude},${coord.longitude}`, "_blank")}>
                                                                            <div>Identifier: {coord?.identifier}</div>
                                                                            Lat: {coord.latitude}, Lng: {coord.longitude}
                                                                        </div>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            ))}

            {/* Risks and Mitigations */}
            {selectedItem?.risks_mitigation && (
                <div style={cardStyle}>
                    <div style={cardTitleStyle}>Risks and Mitigations</div>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={thStyle}>Risk</th>
                                <th style={thStyle}>Risk Type</th>
                                <th style={thStyle}>Mitigation</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedItem.risks_mitigation.map((risk, index) => (
                                <tr key={index}>
                                    <td style={tdStyle}>{risk.risk}</td>
                                    <td style={tdStyle}>{riskTypeBodyTemplate(risk)}</td>
                                    <td style={tdStyle}>{risk.mitigation}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {selectedItem?.recommendations && (
                <div style={cardStyle}>
                    <div style={cardTitleStyle}>Challenges and Recommendations</div>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={thStyle}>Challenges</th>
                                <th style={thStyle}>Recommendations</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedItem.recommendations.map((item, index) => (
                                <tr key={index}>
                                    <td style={tdStyle}>{item.challenges}</td>
                                    <td style={tdStyle}>{item.recommendations}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            <div style={cardStyle}>
                <div style={cardTitleStyle}>Photos</div>
                {selectedItem?.photos?.map((photo, index) => (
                    <div key={index} style={{ marginBottom: "10px" }}>
                        <img src={`${process.env.REACT_APP_API_BASE_URL}${photo.photo_url}`} alt={photo.caption} style={{ maxWidth: "100%", display: "block", marginBottom: "5px" }} />
                        <p style={{ margin: "5px 0" }}>
                            <strong>Caption:</strong> {photo.caption}
                        </p>
                    </div>
                ))}
            </div>

            <div style={cardStyle}>
                <p>
                    <strong>Created By:</strong> {selectedItem?.created_by_user?.name} at {formatDate(selectedItem?.created_at)}
                </p>
                {/* <p>
                    <strong>Updated By:</strong> {selectedItem?.updated_by_user?.name} at {formatDate(selectedItem?.updated_at)}
                </p> */}
            </div>
        </div>
    );
}

export default SubprojectPerfomaceReportPrint;
