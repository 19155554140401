import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import CreateForm from "./CreateForm";

import EditForm from "./EditForm";

import { deleteProgramById, getAllPrograms } from "../../../../../services/programs/program-service";

import moment from "moment";

import { Link } from "react-router-dom";

import { getAllDepartmentUtilities, getDepartmentUtilitieById, postDepartmentUtilities, updateDepartmentUtilities, deleteDepartmentUtilitieById } from "../../../../../services/departments/utility-dept/department-utilities-service.js";
import { getAllUtilityTypes, getUtilityTypesById, postUtilityTypes, updateUtilityTypes, deleteUtilityTypesById } from "../../../../../services/settings/utility-types-service.jsx";

import WaterIsLoading from "../../../../../components/mweComponents/WaterIsLoading";
import MuiTable from "../../../../../components/mweComponents/MuiTable";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import { Panel } from "primereact/panel";
import { Tooltip } from "primereact/tooltip";

import DepartmentsUtilitiesModal from "./DepartmentsUtilitiesModal.jsx";
import { useNavigate } from "react-router-dom";

//
import { Dropdown } from "primereact/dropdown";

function ListPage({ selectedDepartmentItem, ...props }) {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [selectedUtilityType, setSelectedUtilityType] = useState(null);

    console.log("props?.departmentId dsfsd  : ", props?.departmentId);

    const { data, isLoading, isError, error, status } = useQuery(
        ["utilities", props?.departmentId, selectedUtilityType],
        () => (!props?.departmentId ? getAllDepartmentUtilities({ utility_type_id: selectedUtilityType }) : getAllDepartmentUtilities({ department_id: props?.departmentId, utility_type_id: selectedUtilityType })),
        {
            onSuccess: (data) => {
                console.log("fetchAllAllDepartmentUtilities : ", data);
            },
            onError: (error) => {
                error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
                console.log("Error fetching AllDepartmentUtilities is : ", error);
            },
        }
    );

    const DeleteSelectedItemMutation = useMutation((variables) => deleteDepartmentUtilitieById(variables), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(["utilities"]);
        },
        onError: (error) => {
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
            console.log("Error deleting AllDepartmentUtilities is : ", error);
        },
    });

    // const handleDelete = async (event, id) => {
    //     var result = window.confirm("Are you sure you want to delete?");
    //     if (result === true) {
    //         ProgramDeleteMutation.mutate(id);
    //     }
    // };

    //================ utility types ==============
    const fetchAllUtilityTypes = useQuery(["utility-types"], () => getAllUtilityTypes(), {
        onSuccess: (data) => {
            // setDepartments(data.data);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
            console.log("get list of departments : ", error);
        },
    });

    const handleDelete = (event, id) => {
        let selectedDeleteId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(selectedDeleteId),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (selectedDeleteId) => {
        if (selectedDeleteId !== null) {
            DeleteSelectedItemMutation.mutate(selectedDeleteId);
        }
    };

    const cancelDelete = () => {
        // setDeleteProgramId(null);
    };

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showBudjetOutPutAddForm, setShowBudjetOutPutAddForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    const onBudjetOutPutFormClose = () => {
        setShowBudjetOutPutAddForm(false);
    };

    //================== budget outputs indicators ==================
    const [selectedBudgetOutputItem, setSelectedBudgetOutputItem] = useState();

    const [showBudgetOutputIndicatorAddForm, setShowBudgetOutputIndicatorAddForm] = useState(false);
    const [showBudgetOutputIndicatorView, setShowBudgetOutputIndicatorView] = useState(false);

    const handleCloseBudgetOutputIndicatorView = () => {
        setSelectedBudgetOutputItem(null);
        setShowBudgetOutputIndicatorView(false);
    };

    let tableId = 0;
    const columns = [
        {
            title: "#",
            width: "5%",
            field: "name",
            render: (rowData) => {
                // tableId = rowData.tableData.id;
                tableId = tableId++;
                return <div>{rowData.tableData.index + 1}</div>;
                // return <div>{rowData.tableData.id}</div>;
            },
        },
        {
            title: "Name",
            field: "name",
        },
        {
            title: "Code",
            field: "code",
            // render: (rowData) => {
            //     return (
            //         <Link
            //             //programs/directorates/directorate/department/budgetOutput
            //             to="utility"
            //             state={{ selectedBudjetOutputData: rowData }}
            //             className="text-decoration-none"
            //         >
            //             <strong
            //                 onClick={(e) => {
            //                     // e.preventDefault();
            //                     // setSelectedBudgetOutputItem(rowData);
            //                     // setShowBudgetOutputIndicatorView(true);
            //                 }}
            //                 className="custom-target-icon text-decoration-none"
            //                 style={{
            //                     // display: "inline-block",
            //                     display: "inline-block",
            //                     padding: "5px 10px",
            //                     backgroundColor: "#f0f0f0",
            //                     color: "blue",
            //                     borderRadius: "4px",
            //                     fontSize: "1em",
            //                     cursor: "pointer",
            //                 }}
            //                 data-pr-tooltip="Click to view the budget output indicators"
            //                 data-pr-position="top"
            //             >
            //                 {rowData.code}
            //                 <Tooltip target=".custom-target-icon" />
            //             </strong>
            //         </Link>
            //     );
            // },
        },
        {
            title: "Type",
            field: "utility_type.name",
        },
        { title: "Department", field: "department.name", hidden: true },

        {
            title: "Date",
            field: "created_at",
            hidden: true,
            render: (rowData) => {
                return moment(rowData.created_at).format("lll");
            },
        },
    ];

    //
    const utilityOptions = [
        { label: "Utilities", value: "utilities" },
        { label: "Core Utilities", value: "core_utility" },
        { label: "Umbrellas", value: "umbrella" },
    ];

    return (
        <div style={{ width: "100%" }}>
            <div className="col-12 xl:col-12">
                <div className="card">
                    <p>Utilities are classified into different classifications eg umbrellas</p>
                    {/* <Dropdown
                        value={selectedUtilityType}
                        options={utilityOptions}
                        onChange={(e) => {
                            setSelectedUtilityType(e.value);
                            console.log("selectedParentItem : ", e.value);
                        }}
                        placeholder="Select a Utility"
                        style={{ width: "100%" }}
                    /> */}

                    {/* <Dropdown
                        value={selectedUtilityType}
                        options={[
                            { label: "All", value: null },
                            ...(fetchAllUtilityTypes?.data?.data?.data.map((item) => ({
                                label: item.name,
                                value: item.id,
                            })) || []),
                        ]}
                        onChange={(e) => {
                            setSelectedUtilityType(e.value);
                        }}
                        disabled={fetchAllUtilityTypes.isLoading}
                        placeholder="Select Utility Type"
                    /> */}

                    <Dropdown
                        value={selectedUtilityType}
                        options={fetchAllUtilityTypes?.data?.data?.data.map((item) => ({
                            label: item.name,
                            value: item.id,
                        }))}
                        onChange={(e) => {
                            setSelectedUtilityType(e.value);
                        }}
                        disabled={fetchAllUtilityTypes.isLoading}
                        style={{ width: "100%" }}
                        placeholder="Select Utility Type"
                    />
                </div>
            </div>
            <Panel
                header={
                    selectedUtilityType === "utilities" ? "Utilities" : selectedUtilityType === "core_utility" ? "Core Utilities" : selectedUtilityType === "umbrella" ? "Umbrellas" : "Utilities" // Fallback title
                }
                style={{ marginBottom: "20px" }}
            >
                {/* <div className="d-flex justify-content-end mb-3" style={{ width: "100%" }}>
                    {activeUser?.permissions.includes("add programs") && <Button label="Add Program" className="p-button-primary right-0" onClick={() => setShowAddForm(true)} />}
                    <CreateForm show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} />
                </div> */}
                <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                    {/* {activeUser?.permissions.includes("add programs") && (
                        <Button
                            label="Add Budget Outputs"
                            className="p-button-primary"
                            onClick={() => {
                                setShowBudjetOutPutAddForm(true);
                                console.log("clicked add outcomes");
                            }}
                        />
                    )} */}
                    {activeUser?.permissions.includes("edit departments") && <Button label="Add Utility" className="p-button-primary" onClick={() => setShowAddForm(true)} />}
                    <CreateForm selectedDepartmentItem={selectedDepartmentItem} departmentId={props?.departmentId} show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} projectId={props?.projectId} />
                    {/* <BudgetOutPutsCreateForm show={showBudjetOutPutAddForm} onHide={() => setShowBudjetOutPutAddForm(false)} onClose={onBudjetOutPutFormClose} /> */}
                </div>
                <MuiTable
                    tableTitle={
                        selectedUtilityType === "utilities" ? "Utilities" : selectedUtilityType === "core_utility" ? "Core Utilities" : selectedUtilityType === "umbrella" ? "Umbrellas" : "Utilities" // Fallback title
                    }
                    tableData={data?.data?.data}
                    tableColumns={columns}
                    handleShowEditForm={handleShowEditForm}
                    handleDelete={(e, item_id) => handleDelete(e, item_id)}
                    showEdit={activeUser?.permissions.includes("edit departments") || activeUser?.permissions.includes("edit departments")}
                    showDelete={activeUser?.permissions.includes("edit departments")}
                    showViewPage={true}
                    hideRowViewPage={false}
                    handleViewPage={(rowData) => {
                        navigate("utility", { state: { selectedUtilityData: rowData } });
                    }}
                    loading={isLoading || status === "loading" || DeleteSelectedItemMutation.isLoading}
                    exportButton={true}
                    pdfExportTitle="Department Utilities"
                    csvExportTitle="Department Utilities"
                />
                {selectedItem && <EditForm selectedDepartmentItem={selectedDepartmentItem} rowData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} />}
                <DepartmentsUtilitiesModal rowData={selectedBudgetOutputItem} show={showBudgetOutputIndicatorView} onHide={handleCloseBudgetOutputIndicatorView} onClose={handleCloseBudgetOutputIndicatorView} />

                {DeleteSelectedItemMutation.isLoading && <WaterIsLoading />}
                {/* <ConfirmDialog /> */}
            </Panel>
        </div>
    );
}

export default ListPage;
