import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
// import CreateForm from "./CreateForm";

// import EditForm from "./EditForm";

import moment from "moment";

import { getAllDepartmentReports, postDepartmentReport, deleteDepartmentReportById } from "../../../../../../services/departments/department-reports-service.js";

import WaterIsLoading from "../../../../../../components/mweComponents/WaterIsLoading";
import MuiTable from "../../../../../../components/mweComponents/MuiTable";
import { toast } from "react-toastify";
import { Button } from "primereact/button";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useNavigate } from "react-router-dom";

import { Panel } from "primereact/panel";
import SubProjectPerfomanceReportPdfModal from "./SubProjectPerfomanceReportPdfModal";
import DepartmentReportPdfModal from "./DepartmentReportPdfModal";
import { TabView, TabPanel } from "primereact/tabview";

//
function DepartmentReportsList({ loggedInUserData, selectedParentDetail, departmentId, ...props }) {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const { data, isLoading, isError, error, status } = useQuery(["department-reports", selectedParentDetail?.id], () => (!selectedParentDetail?.id ? getAllDepartmentReports() : getAllDepartmentReports({ department_id: selectedParentDetail?.id, reason: "approved" })), {
        onSuccess: (data) => {
            console.log("fetching department-reports data onsuccess is : ", data);
        },
        onError: (error) => {
            console.log("fetching department-reports data onerror is : ", error);
        },
    });
    console.log(data);
    isError && toast.error("There was an error while fetching data");

    const DeleteSelectedItemMutation = useMutation((variables) => deleteDepartmentReportById(variables), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(["department-reports"]);
            // if (toast.current) {
            //     toast.current.show({ severity: "success", summary: "Success", detail: "Item deleted successfully", life: 3000 });
            // }

            toast.success("deleted successfully");
        },
        onError: (error) => {
            // Handle error case
            // if (toast.current) {
            //     toast.current.show({ severity: "error", summary: "Error", detail: "Error deleting item", life: 3000 });
            // }

            let errorMessage = "An error occurred, please contact admin";
            if (error?.response?.data?.message) {
                errorMessage = error.response.data.message;
            }
            toast.error(errorMessage);

            console.log("error deleting Department report error:", error);
        },
    });

    // const handleDelete = async (event, id) => {
    //     var result = window.confirm("Are you sure you want to delete?");
    //     if (result === true) {
    //         ProgramDeleteMutation.mutate(id);
    //     }
    // };

    const handleDelete = (event, id) => {
        let selectedDeleteId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(selectedDeleteId),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (selectedDeleteId) => {
        if (selectedDeleteId !== null) {
            DeleteSelectedItemMutation.mutate(selectedDeleteId);
        }
    };

    const cancelDelete = () => {
        // setDeleteProgramId(null);
    };

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showBudjetOutPutAddForm, setShowBudjetOutPutAddForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    const onBudjetOutPutFormClose = () => {
        setShowBudjetOutPutAddForm(false);
    };

    let tableId = 0;

    const columns = [
        {
            title: "#",
            width: "5%",
            field: "name",
            render: (rowData) => {
                // tableId = rowData.tableData.id;
                tableId = tableId++;
                return <div>{rowData.tableData.index + 1}</div>;
                // return <div>{rowData.tableData.id}</div>;
            },
        },
        {
            title: "Department Name",
            field: "department.name",
        },
        {
            title: "Financial Year",
            field: "financial_year.name",
        },
        {
            title: "Quarter",
            field: "quarter.name",
        },
        {
            title: "Released Funds",
            hidden: true,
            field: "approved_budget",
            render: (rowData) => {
                // Ensure approved_budget is a string before replacing
                const amountString = rowData.approved_budget ? rowData.approved_budget.toString() : "";
                const amount = parseFloat(amountString.replace(/,/g, ""));
                return <div>{isNaN(amount) ? rowData.approved_budget : amount.toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>;
            },
        },
        {
            title: "Spent Funds",
            field: "spent_funds",
            hidden: true,
            render: (rowData) => {
                // Ensure spent_funds is a string before replacing
                const amountString = rowData.spent_funds ? rowData.spent_funds.toString() : "";
                const amount = parseFloat(amountString.replace(/,/g, ""));
                return <div>{isNaN(amount) ? rowData.spent_funds : amount.toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>;
            },
        },
        // {
        //     title: "Released Funds",
        //     field: "released_funds",
        //     hidden: true,
        // },

        {
            title: "Absorption rate",
            hidden: true,
            field: "absorption_rate",
            render: (rowData) => {
                return <div>{rowData?.absorption_rate} %</div>;
            },
        },
        {
            title: "Approval Status",
            hidden: true,
            field: "current_status.name",
            render: (rowData) => {
                let labelStyle = { padding: "3px", color: "#fff", borderRadius: "4px" };
                let labelClass = "";

                switch (rowData?.current_status?.name) {
                    case "draft":
                        labelStyle = { ...labelStyle, backgroundColor: "#f0ad4e" }; // Bootstrap warning color
                        labelClass = "label label-default";
                        break;
                    case "submitted":
                        labelStyle = { ...labelStyle, backgroundColor: "#5bc0de" }; // Bootstrap info color
                        labelClass = "label label-info";
                        break;
                    case "approved":
                        labelStyle = { ...labelStyle, backgroundColor: "#5cb85c" }; // Bootstrap success color
                        labelClass = "label label-success";
                        break;
                    case "rejected":
                        labelStyle = { ...labelStyle, backgroundColor: "#d9534f" }; // Bootstrap danger color
                        labelClass = "label label-danger";
                        break;
                    default:
                        labelStyle = { ...labelStyle, backgroundColor: "#5bc0de" }; // Bootstrap info color
                        labelClass = "label label-info";
                }

                return (
                    <strong style={labelStyle} className={labelClass}>
                        {rowData?.current_status?.name.charAt(0).toUpperCase() + rowData?.current_status?.name.slice(1)}
                    </strong>
                );
            },
        },

        {
            title: "Status",
            field: "status",
            hidden: true,
            render: (rowData) => {
                let labelStyle = { padding: "3px", color: "#fff", borderRadius: "4px" };
                let labelClass = "";

                switch (rowData.status) {
                    case "draft":
                        labelStyle = { ...labelStyle, backgroundColor: "#f0ad4e" }; // Bootstrap warning color
                        labelClass = "label label-default";
                        break;
                    case "submitted":
                        labelStyle = { ...labelStyle, backgroundColor: "#5bc0de" }; // Bootstrap info color
                        labelClass = "label label-info";
                        break;
                    case "approved":
                        labelStyle = { ...labelStyle, backgroundColor: "#5cb85c" }; // Bootstrap success color
                        labelClass = "label label-success";
                        break;
                    case "rejected":
                        labelStyle = { ...labelStyle, backgroundColor: "#d9534f" }; // Bootstrap danger color
                        labelClass = "label label-danger";
                        break;
                    default:
                        labelStyle = { ...labelStyle, display: "none" }; // Hide label if status is unknown
                }

                return (
                    <strong style={labelStyle} className={labelClass}>
                        {rowData.status.charAt(0).toUpperCase() + rowData.status.slice(1)}
                    </strong>
                );
            },
        },

        {
            title: "Updated By",
            hidden: true,
            field: "updated_by_user.email",
        },

        {
            title: "Created By",
            hidden: true,
            field: "created_by_user.email",
        },
        {
            title: "Date",
            hidden: true,
            field: "created_at",
            render: (rowData) => {
                return moment(rowData.created_at).format("lll");
            },
        },
    ];

    //======================= pdf ====================
    //  const [selectedItem, setSelectedItem] = useState({ id: null });
    const [showPDF, setShowPDF] = useState(false);
    const handleShowPDF = (e, item) => {
        setSelectedItem(item);
        setShowPDF(true);
    };
    const handleClosePDF = () => {
        setSelectedItem({ id: null });
        setShowPDF(false);
    };

    const [tableSelection, setTableSelection] = useState(false);

    //
    return (
        <div style={{ width: "100%" }}>
            <Panel header="Department Reports" style={{ marginBottom: "20px" }}>
                {/* <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                    {activeUser?.permissions.includes("add reports") && (
                        <Button
                            label={tableSelection ? "Single Create" : "Bulk Create"}
                            className="p-button-primary"
                            onClick={() => {
                                setTableSelection(!tableSelection);
                            }}
                        />
                    )}
                </div> */}
                <MuiTable
                    tableTitle="Department Reports"
                    tableData={data?.data?.data}
                    tableColumns={columns}
                    // handleShowEditForm={(rowData) => navigate("datacollection", { state: { subprojectDetailData: subprojectDetail, selectedItemForEdit: rowData } })}
                    // showEdit={activeUser?.permissions.includes("edit reports") || activeUser?.permissions.includes("add reports")}
                    // hideRowEdit={(rowData) => {
                    //     return rowData.status === "submitted" || rowData.status === "approved" ? true : false;
                    // }}
                    handleDelete={(e, item_id) => handleDelete(e, item_id)}
                    showDelete={true}
                    // handleViewPage={(rowData) => {
                    //   return handleShowPDF(rowData);
                    // }}
                    // showViewPage={true}
                    // hideRowViewPage={false}
                    //view reports pdf
                    handleViewPdf={(e, item_id) => handleShowPDF(e, item_id)}
                    //
                    exportButton={false}
                    hideViewPdfRow={(rowData) => {
                        console.log("rowdata llllvvccd : ", rowData);
                        return rowData.status === "submitted" || rowData.status === "approved" ? false : true;
                    }}
                    showViewPdf={true}
                    loading={isLoading || status === "loading" || DeleteSelectedItemMutation.isLoading}
                    //approvals
                    selection={tableSelection}
                    showSelectAllCheckbox={false}
                    showTextRowsSelected={true}
                    selectionChange={(selectedRows) => {}}
                    handleDataApproval={() => {}}

                    // showDataApproval={true}
                    // handleDataApproval={(e, rowData) => {
                    //     return handleShowApprovalModal(e, rowData);
                    // }}
                    // hideDataApprovalRow={(rowData) => {
                    //     console.log("rowdata llllvvccd : ", rowData);
                    //     return rowData.status === "submitted" || rowData.status === "approved" ? false : true;
                    // }}
                    // //rejection
                    // showRejectionAction={true}
                    // handleRejectionAction={(e, rowData) => {
                    //     return handleRejection(e, rowData);
                    // }}
                    // hideRejectionActionRow={(rowData) => {
                    //     console.log("rowdata llllvvccd : ", rowData);
                    //     return rowData.status === "submitted" || rowData.status === "approved" ? false : true;
                    // }}
                />
                {selectedItem && <DepartmentReportPdfModal selectedParentDetail={selectedParentDetail} departmentReport={selectedItem} loggedInUserData={props?.loggedInUserData} show={showPDF} onHide={() => setShowPDF(false)} onClose={onFormClose} />}

                {/* {selectedItem && <EditForm rowData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} subprojectDetail={subprojectDetail} />} */}

                {DeleteSelectedItemMutation.isLoading && <WaterIsLoading />}
                {/* <ConfirmDialog /> */}
            </Panel>
        </div>
    );
}

export default DepartmentReportsList;
