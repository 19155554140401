import React, { useRef, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
// import CreateForm from "./CreateForm";

// import EditForm from "./EditForm";

import moment from "moment";

import { getAllProjectReports, getProjectReportById, postProjectReport, updateProjectReport, deleteProjectReportById } from "../../../../../../services/projects/project-reports-service.js";

import WaterIsLoading from "../../../../../../components/mweComponents/WaterIsLoading";
import MuiTable from "../../../../../../components/mweComponents/MuiTable";
import { toast } from "react-toastify";
import { Button } from "primereact/button";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

import { Panel } from "primereact/panel";
import SubProjectPerfomanceReportPdfModal from "./SubProjectPerfomanceReportPdfModal";
import ProjectReportPdfModal from "./ProjectReportPdfModal";
import ApprovalModal from "./ApprovalModal.jsx";
import { TabView, TabPanel } from "primereact/tabview";
import { Toast as PrimeToast } from "primereact/toast";

//
function CreateDepartmentReports({ selectedParentDetail, departmentViewPageActiveIndex, departmentViewPageChildActiveIndex, loggedInUserData, ...props }) {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    console.log("Department Detail ggggg : ", selectedParentDetail);

    const { data, isLoading, isError, error, status } = useQuery(["project-approvals", "project-reports", selectedParentDetail?.id, "approved"], () => (!selectedParentDetail?.id ? getAllProjectReports() : getAllProjectReports({ department_id: selectedParentDetail?.id, reason: "approved" })), {
        onSuccess: (data) => {
            console.log("fetching project data for approval and create report is : ", data);
        },
        onError: (error) => {
            console.log("Error fetching project for approval is : ", error);
        },
    });
    console.log(data);
    isError && toast.error("There was an error while fetching data");

    const DeleteSelectedItemMutation = useMutation((variables) => deleteProjectReportById(variables), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(["project-approvals", "project-reports"]);
            // if (toast.current) {
            //     toast.current.show({ severity: "success", summary: "Success", detail: "Item deleted successfully", life: 3000 });
            // }

            toast.success("deleted successfully");
        },
        onError: (error) => {
            // Handle error case
            // if (toast.current) {
            //     toast.current.show({ severity: "error", summary: "Error", detail: "Error deleting item", life: 3000 });
            // }
            toast.error("An Error Occured");
        },
    });

    // const handleDelete = async (event, id) => {
    //     var result = window.confirm("Are you sure you want to delete?");
    //     if (result === true) {
    //         ProgramDeleteMutation.mutate(id);
    //     }
    // };

    const handleDelete = (event, id) => {
        let selectedDeleteId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(selectedDeleteId),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (selectedDeleteId) => {
        if (selectedDeleteId !== null) {
            DeleteSelectedItemMutation.mutate(selectedDeleteId);
        }
    };

    const cancelDelete = () => {
        // setDeleteProgramId(null);
    };

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showBudjetOutPutAddForm, setShowBudjetOutPutAddForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const activeUser = loggedInUserData ? loggedInUserData : localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    const onBudjetOutPutFormClose = () => {
        setShowBudjetOutPutAddForm(false);
    };

    let tableId = 0;

    const columns = [
        {
            title: "#",
            width: "5%",
            field: "name",
            render: (rowData) => {
                // tableId = rowData.tableData.id;
                tableId = tableId++;
                return <div>{rowData.tableData.index + 1}</div>;
                // return <div>{rowData.tableData.id}</div>;
            },
        },
        {
            title: "Project Name",
            field: "project.project_title",
            render: (rowData) => {
                return (
                    <div style={{ color: "blue", cursor: "pointer" }} onClick={(e) => handleShowPDF(e, rowData)}>
                        <Tooltip title="Click to view report">
                            <span>{rowData?.project?.project_title}</span>
                        </Tooltip>
                    </div>
                );
            },
        },
        {
            title: "Financial Year",
            field: "financial_year.name",
        },
        {
            title: "Quarter",
            field: "quarter.name",
        },
        {
            title: "Approved Budget",
            hidden: true,
            field: "approved_budget",
            render: (rowData) => {
                // Ensure approved_budget is a string before replacing
                const amountString = rowData.approved_budget ? rowData.approved_budget.toString() : "";
                const amount = parseFloat(amountString.replace(/,/g, ""));
                return <div>{isNaN(amount) ? rowData.approved_budget : amount.toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>;
            },
        },
        {
            title: "Spent Funds",
            field: "spent_funds",
            hidden: true,
            render: (rowData) => {
                // Ensure spent_funds is a string before replacing
                const amountString = rowData.spent_funds ? rowData.spent_funds.toString() : "";
                const amount = parseFloat(amountString.replace(/,/g, ""));
                return <div>{isNaN(amount) ? rowData.spent_funds : amount.toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>;
            },
        },
        {
            title: "Released Funds",
            field: "released_funds",
            hidden: true,
        },

        {
            title: "Absorption rate",
            hidden: true,
            field: "absorption_rate",
            render: (rowData) => {
                return <div>{rowData?.absorption_rate} %</div>;
            },
        },
        {
            title: "Approval Status",
            field: "current_status.name",
            render: (rowData) => {
                let labelStyle = { padding: "3px", color: "#fff", borderRadius: "4px" };
                let labelClass = "";

                switch (rowData?.current_status?.name) {
                    case "draft":
                        labelStyle = { ...labelStyle, backgroundColor: "#f0ad4e" }; // Bootstrap warning color
                        labelClass = "label label-default";
                        break;
                    case "submitted":
                        labelStyle = { ...labelStyle, backgroundColor: "#5bc0de" }; // Bootstrap info color
                        labelClass = "label label-info";
                        break;
                    case "approved":
                        labelStyle = { ...labelStyle, backgroundColor: "#5cb85c" }; // Bootstrap success color
                        labelClass = "label label-success";
                        break;
                    case "rejected":
                        labelStyle = { ...labelStyle, backgroundColor: "#d9534f" }; // Bootstrap danger color
                        labelClass = "label label-danger";
                        break;
                    default:
                        labelStyle = { ...labelStyle, backgroundColor: "#5bc0de" }; // Bootstrap info color
                        labelClass = "label label-info";
                }

                return (
                    <strong style={labelStyle} className={labelClass}>
                        {rowData?.current_status?.name.charAt(0).toUpperCase() + rowData?.current_status?.name.slice(1)}
                    </strong>
                );
            },
        },

        {
            title: "Status",
            field: "status",
            hidden: true,
            render: (rowData) => {
                let labelStyle = { padding: "3px", color: "#fff", borderRadius: "4px" };
                let labelClass = "";

                switch (rowData.status) {
                    case "draft":
                        labelStyle = { ...labelStyle, backgroundColor: "#f0ad4e" }; // Bootstrap warning color
                        labelClass = "label label-default";
                        break;
                    case "submitted":
                        labelStyle = { ...labelStyle, backgroundColor: "#5bc0de" }; // Bootstrap info color
                        labelClass = "label label-info";
                        break;
                    case "approved":
                        labelStyle = { ...labelStyle, backgroundColor: "#5cb85c" }; // Bootstrap success color
                        labelClass = "label label-success";
                        break;
                    case "rejected":
                        labelStyle = { ...labelStyle, backgroundColor: "#d9534f" }; // Bootstrap danger color
                        labelClass = "label label-danger";
                        break;
                    default:
                        labelStyle = { ...labelStyle, display: "none" }; // Hide label if status is unknown
                }

                return (
                    <strong style={labelStyle} className={labelClass}>
                        {rowData.status.charAt(0).toUpperCase() + rowData.status.slice(1)}
                    </strong>
                );
            },
        },

        {
            title: "Updated By",
            hidden: true,
            field: "updated_by_user.email",
        },

        {
            title: "Created By",
            hidden: true,
            field: "created_by_user.email",
        },
        {
            title: "Date",
            hidden: true,
            field: "created_at",
            render: (rowData) => {
                return moment(rowData.created_at).format("lll");
            },
        },
    ];

    //======================= pdf ====================
    //  const [selectedItem, setSelectedItem] = useState({ id: null });
    const [showPDF, setShowPDF] = useState(false);
    const handleShowPDF = (e, item) => {
        e.preventDefault();
        setSelectedItem(item);
        setShowPDF(true);
    };
    const handleClosePDF = () => {
        setSelectedItem({ id: null });
        setShowPDF(false);
    };

    const [tableSelection, setTableSelection] = useState(false);

    //
    const [showApprovalModalStatus, setShowApprovalModalStatus] = useState(false);
    //======================= Approve ====================
    //  const [selectedItem, setSelectedItem] = useState({ id: null });
    const [showApprovalModal, setShowApprovalModal] = useState(false);
    const handleShowApprovalModal = (e, item) => {
        setSelectedItem(item);
        setShowApprovalModalStatus("approved");
        setShowApprovalModal(true);
    };
    const handleCloseApprovalModal = () => {
        setSelectedItem({ id: null });
        setShowApprovalModal(false);
    };

    //======================= Reject ====================
    //  const [selectedItem, setSelectedItem] = useState({ id: null });

    const handleRejection = (e, item) => {
        setSelectedItem(item);
        setShowApprovalModalStatus("rejected");
        setShowApprovalModal(true);
    };

    //table selection
    const primeToast = useRef(null);
    const [tableSelectedRows, setTableSelectedRows] = useState([]);
    const [isCreateConfirmDialogVisible, setIsCreateConfirmDialogVisible] = useState(false);

    const validateSelectedRows = () => {
        if (tableSelectedRows.length === 0) {
            primeToast.current.show({ severity: "warn", summary: "Warning", detail: "No rows selected", life: 3000 });
            return false;
        }

        const firstRow = tableSelectedRows[0];
        const isSameQuarterAndYear = tableSelectedRows.every((row) => row.financial_year.name === firstRow.financial_year.name && row.quarter.name === firstRow.quarter.name);

        if (!isSameQuarterAndYear) {
            primeToast.current.show({ severity: "warn", summary: "Warning", detail: "All selected rows must have the same quarter and financial year", life: 3000 });
            return false;
        }

        return true;
    };

    const handleCreateProjectReportConfirm = () => {
        setIsCreateConfirmDialogVisible(false);
        // Logic for creating the project report goes here
        navigate("report", { state: { projectReports: tableSelectedRows, selectedDepartmentItem: selectedParentDetail, departmentViewPageActiveIndex: departmentViewPageActiveIndex, departmentViewPageChildActiveIndex: departmentViewPageChildActiveIndex } });
    };

    const handleCreateProjectReportReject = () => {
        setIsCreateConfirmDialogVisible(false);
        // Logic for rejection (if any) goes here
    };

    const handleCreateProjectReportClick = () => {
        if (validateSelectedRows()) {
            setIsCreateConfirmDialogVisible(true);
        }
    };

    //
    return (
        <div style={{ width: "100%" }}>
            <PrimeToast ref={primeToast} />
            <Panel header="Project Approved Reports" style={{ marginBottom: "20px" }}>
                <div className="col-12 xl:col-12">
                    <div className="card">
                        <p>Select approved project reports to create a department report</p>
                    </div>
                </div>

                {activeUser?.permissions.includes("add reports") && tableSelectedRows.length > 0 && (
                    <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                        <Button
                            label="Create Department Report"
                            className="p-button-primary"
                            onClick={() => {
                                handleCreateProjectReportClick();
                            }}
                        />
                    </div>
                )}

                <MuiTable
                    tableTitle="Project Approved Reports"
                    tableData={data?.data?.data}
                    tableColumns={columns}
                    // handleShowEditForm={(rowData) => navigate("datacollection", { state: { subprojectDetailData: subprojectDetail, selectedItemForEdit: rowData } })}
                    // showEdit={activeUser?.permissions.includes("edit reports") || activeUser?.permissions.includes("add reports")}
                    // hideRowEdit={(rowData) => {
                    //     return rowData.status === "submitted" || rowData.status === "approved" ? true : false;
                    // }}
                    handleDelete={(e, item_id) => handleDelete(e, item_id)}
                    showDelete={false}
                    //
                    exportButton={false}
                    loading={isLoading || status === "loading" || DeleteSelectedItemMutation.isLoading}
                    //approvals
                    selection={true}
                    showSelectAllCheckbox={true}
                    showTextRowsSelected={true}
                    selectionChange={(selectedRows) => {
                        console.log("selected rows on sselection change : ", selectedRows);
                        setTableSelectedRows(selectedRows);
                    }}
                    handleDataApproval={() => {}}

                    // showDataApproval={true}
                    // handleDataApproval={(e, rowData) => {
                    //     return handleShowApprovalModal(e, rowData);
                    // }}
                    // hideDataApprovalRow={(rowData) => {
                    //     console.log("rowdata llllvvccd : ", rowData);
                    //     return rowData.status === "submitted" || rowData.status === "approved" ? false : true;
                    // }}
                    // //rejection
                    // showRejectionAction={true}
                    // handleRejectionAction={(e, rowData) => {
                    //     return handleRejection(e, rowData);
                    // }}
                    // hideRejectionActionRow={(rowData) => {
                    //     console.log("rowdata llllvvccd : ", rowData);
                    //     return rowData.status === "submitted" || rowData.status === "approved" ? false : true;
                    // }}
                />

                {selectedItem && <ProjectReportPdfModal projectReport={selectedItem} loggedInUserData={loggedInUserData} show={showPDF} onHide={() => setShowPDF(false)} onClose={onFormClose} />}
                <ApprovalModal approvalStatus={showApprovalModalStatus} selectedItem={selectedItem} loggedInUserData={loggedInUserData} show={showApprovalModal} onHide={() => setShowApprovalModal(false)} onClose={handleCloseApprovalModal} />
                {/* {selectedItem && <EditForm rowData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} subprojectDetail={subprojectDetail} />} */}

                {DeleteSelectedItemMutation.isLoading && <WaterIsLoading />}
                {/* <ConfirmDialog /> */}
                <ConfirmDialog
                    visible={isCreateConfirmDialogVisible}
                    onHide={() => setIsCreateConfirmDialogVisible(false)}
                    message="Are you sure you want to create the department report?"
                    header="Create Department Report Confirmation"
                    icon="pi pi-exclamation-triangle"
                    accept={handleCreateProjectReportConfirm}
                    reject={handleCreateProjectReportReject}
                />
            </Panel>
        </div>
    );
}

export default CreateDepartmentReports;
