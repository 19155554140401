import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { TabView, TabPanel } from "primereact/tabview";
import { Card } from "primereact/card";
import Lottie from "lottie-react";
import NoData from "../../../assets/Animation - nodata lottie 1702085133252.json";

const BudgetOutputsAndOutputIndicatorsGraph = ({ data }) => {
    // Function to create chart options for each activity
    // const createChartOptions = (activity) => {
    //     const activityName = activity.name;

    //     const colors = Highcharts.getOptions().colors; // Get the default colors

    //     const series = activity.department_budget_output_indicator.map((output, index) => ({
    //         name: output.output_indicator.name,
    //         data: [parseInt(output.final_actual_value) || 0],
    //         color: colors[index % colors.length], // Cycle through the colors array
    //     }));

    //     return {
    //         chart: {
    //             type: "column",
    //         },
    //         title: {
    //             text: activityName,
    //         },
    //         xAxis: {
    //             categories: [activityName],
    //             crosshair: true,
    //         },
    //         yAxis: {
    //             min: 0,
    //             title: {
    //                 text: "Output Values",
    //             },
    //         },
    //         tooltip: {
    //             headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    //             pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y}</b></td></tr>',
    //             footerFormat: "</table>",
    //             shared: true,
    //             useHTML: true,
    //         },
    //         plotOptions: {
    //             column: {
    //                 pointPadding: 0.2,
    //                 borderWidth: 0,
    //             },
    //         },
    //         credits: {
    //             enabled: false,
    //         },
    //         series: series,
    //     };
    // };

    const createChartOptions = (activity) => {
        const activityName = activity.name;
        const colors = Highcharts.getOptions().colors; // Default colors

        // Creating series for each output indicator with its own unit
        const series = activity.department_budget_output_indicator.map((output, index) => ({
            name: output.output_indicator.name,
            type: "column",
            data: [
                {
                    y: parseInt(output.final_actual_value) || 0,
                    unit: output.output_indicator.unit, // Store the unit for use in the tooltip
                },
            ],
            color: colors[index % colors.length], // Cycle through the colors array
        }));

        return {
            chart: { type: "column" },
            title: { text: activityName },
            xAxis: {
                categories: ["Indicators"],
                crosshair: true,
            },
            yAxis: {
                min: 0,
                title: { text: "Output Values" },
            },
            tooltip: {
                // Set shared to false to show tooltip for individual points
                shared: false,
                useHTML: true,
                formatter: function () {
                    return `<table>
                        <tr><td style="color:${this.color};padding:0">${this.series.name}: </td>
                        <td style="padding:0"><b>${this.y} ${this.point.unit}</b></td></tr>
                        </table>`;
                },
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    // Enable dataLabels if you want to show data on the chart
                    dataLabels: {
                        enabled: false,
                        format: "{point.y} {point.unit}", // Include the unit directly in the data label
                    },
                },
            },
            credits: { enabled: false },
            series: series,
        };
    };

    // Check if data is empty
    if (!Array.isArray(data) || data.length === 0) {
        return (
            <center>
                {" "}
                <Lottie
                    animationData={NoData}
                    loop={true}
                    autoplay={true}
                    style={{
                        width: "300px", // Increase width to cover the area
                        // height: "100%", // Increase height to cover the area
                    }}
                />
            </center>
        );
    }

    return (
        <TabView scrollable={true}>
            {data.map((activity, index) => (
                <TabPanel key={activity.id} header={activity.name}>
                    <Card>
                        <HighchartsReact highcharts={Highcharts} options={createChartOptions(activity)} />
                    </Card>
                </TabPanel>
            ))}
        </TabView>
    );
};

export default BudgetOutputsAndOutputIndicatorsGraph;
