import axiosAPI from "../axiosApi";

export async function getAllDepartmentBudjetOutputs(params = {}) {
    const response = await axiosAPI.get("departments-budget-outputs", { params: params });
    return response;
}

export async function getAllDepartmentBudjetOutputsForDashboard(params = {}) {
    const response = await axiosAPI.get("depart-budget-outputs-for-db", { params: params });
    return response;
}

export async function getDepartmentBudjetOutputById(id) {
    const response = await axiosAPI.get(`departments-budget-outputs/${id}`);
    return response;
}

export async function postDepartmentBudjetOutput(data) {
    const response = await axiosAPI.post(`departments-budget-outputs`, data);
    return response;
}

export async function updateDepartmentBudjetOutput(id, data) {
    const response = await axiosAPI.put(`departments-budget-outputs/${id}`, data);
    return response;
}

export async function deleteDepartmentBudjetOutputById(id) {
    const response = await axiosAPI.delete(`departments-budget-outputs/${id}`);
    return response;
}
