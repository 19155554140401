import React, { useState, useMemo } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Tree } from "primereact/tree";

import { Dropdown } from "primereact/dropdown";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { classNames } from "primereact/utils";
import { getAllDepartments } from "../../../../../../services/departments/department-service";

import { deleteProgramById, getAllPrograms } from "../../../../../../services/programs/program-service";
import { deleteDistrictById, getAllDistricts } from "../../../../../../services/districts/districts-service";

import setFieldTouched from "final-form-set-field-touched";

//
import { toast } from "react-toastify";
import { AutoComplete } from "primereact/autocomplete";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DeleteIcon from "@mui/icons-material/Delete";
import { ProgressSpinner } from "primereact/progressspinner";

function RowForm({ selectedDepartmentItem, handleSubmit, initialData, ProjectsData = {}, ...props }) {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);
    const queryClient = useQueryClient();

    console.log("initialData dfgsdv : ", initialData);
    const validate = (values) => {
        const errors = {};
        if (!values.department_id) errors.department_id = "A Department is required";
        if (!values.name) errors.name = "Name is required";
        if (!values.code) errors.code = "Code is required";
        if (!values.status) errors.status = "Status is required";
        return errors;
    };

    const onSubmitForm = (data, form) => {
        // Add 'form' as an argument
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            // Mark all fields as touched to show validation errors
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("First fill in all required fields.");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    //================ departments ==============
    const fetchAllDepartments = useQuery(["departments", "by-directorate", selectedDepartmentItem?.directorate_id], () => (!selectedDepartmentItem?.directorate_id ? getAllDepartments() : getAllDepartments({ directorate_id: selectedDepartmentItem?.directorate_id })), {
        onSuccess: (data) => {
            // setDepartments(data.data);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
            console.log("get list of departments : ", error);
        },
    });

    if (props?.departmentId) {
        initialData = { department_id: props?.departmentId, ...initialData };
    }

    // initialData = { project_id: props?.project_id, ...initialData };
    // console.log("props?.selectedDepartmentItem ooo :", selectedDepartmentItem);

    // if (initialData) {
    //     initialData = { budgetOutputsIds: initialData, ...initialData };
    // }
    return (
        <div className="col-12 md:col-12">
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    mutators={{ setFieldTouched }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            // onSubmit={(event) => {
                            //     event.preventDefault();
                            //     console.log("values hhh : ", values);
                            //     console.log("event fffff : ", event);
                            //     onSubmitForm(values);
                            //     // handleSubmit(event, values);
                            // }}
                            onSubmit={handleSubmit}
                        >
                            <div style={{ marginBottom: "1rem" }}>
                                <Field name="department_id">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Department</label>
                                            <Dropdown
                                                value={input.value}
                                                options={fetchAllDepartments?.data?.data?.data.map((item) => ({
                                                    label: item.name,
                                                    value: item.id,
                                                }))}
                                                onChange={(e) => input.onChange(e.value)}
                                                placeholder="Select Department"
                                                // disabled={fetchAllDepartments.isLoading}
                                                disabled={true}
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                            {fetchAllDepartments.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <Field name="name">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="name">Name</label>
                                        <InputText {...input} id="name" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && (meta.error || form.submitSuccess || form.submitError) && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>
                            <Field name="code">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="code">Code</label>
                                        <InputText {...input} id="code" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="status">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="status">Status</label>
                                        <Dropdown
                                            value={input.value}
                                            options={[
                                                { label: "Active", value: "active" },
                                                { label: "Deactivate", value: "deactivate" },
                                            ]}
                                            onChange={(e) => input.onChange(e.value)}
                                            placeholder="Select Status"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                        />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <div className="d-grid gap-2">
                                <Button type="submit" label="Save" className="p-button-primary" icon="pi pi-check" />
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    style={{ width: "30vw" }}
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to submit?
                </Dialog>
            </div>
        </div>
    );
}

export default RowForm;
