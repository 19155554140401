import React from "react";
import moment from "moment";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Image } from "primereact/image";
import { Dialog } from "primereact/dialog";
import { ProgressBar } from "primereact/progressbar";
import { Tag } from "primereact/tag";

function SubprojectAggregationPrint({ aggregatedReportDetail, ...props }) {
    // Helper function to format currency
    const formatCurrency = (value) => {
        return new Intl.NumberFormat("en-US", { style: "currency", currency: "UGX" }).format(value);
    };

    // Helper function to format date
    const formatDate = (dateString) => {
        return moment(dateString).format("MMMM Do YYYY, h:mm:ss a");
    };

    // Inline styles
    const tableHeaderStyle = {
        backgroundColor: "#00B9E8",
        color: "white",
        padding: "10px",
        textAlign: "left",
    };

    const tableCellStyle = {
        padding: "10px",
    };

    const cardStyle = {
        marginBottom: "20px",
        padding: "15px",
        border: "1px solid #ddd",
        borderRadius: "5px",
    };

    const cardTitleStyle = {
        color: "#00B9E8",
        fontWeight: "bold",
        marginBottom: "10px",
    };

    // Inline styles
    const tableStyle = {
        width: "100%",
        marginBottom: "20px",
        borderCollapse: "collapse",
    };

    const thStyle = {
        backgroundColor: "#00B9E8",
        color: "white",
        padding: "10px",
        textAlign: "left",
        border: "1px solid #ddd",
    };

    const tdStyle = {
        padding: "10px",
        border: "1px solid #ddd",
    };

    const riskTypeBodyTemplate = (rowData) => {
        let color;
        switch (rowData.risk_type) {
            case "high":
                color = "red";
                break;
            case "moderate":
                color = "orange";
                break;
            case "low":
                color = "grey";
                break;
            default:
                color = "black";
        }

        return (
            <div>
                <span className="p-mr-2" style={{ fontSize: "1.5rem", color, fontWeight: "bold" }}>
                    •
                </span>
                {rowData.risk_type.charAt(0).toUpperCase() + rowData.risk_type.slice(1)}
            </div>
        );
    };

    const gridStyle = {
        display: "grid",
        gridTemplateColumns: "1fr 1fr", // This creates two columns of equal width
        gridGap: "1rem", // Space between the columns
    };

    // Function to display status with color using Tag
    const tagStatus = (status) => {
        let severity;
        switch (status) {
            case "pending approval":
                severity = "info"; // blue
                break;
            case "approved":
                severity = "success"; // green
                break;
            case "pending":
                severity = "warning"; // orange
                break;
            case "rejected":
                severity = "danger"; // red
                break;
            default:
                severity = "secondary"; // grey or default
        }
        return <Tag severity={severity} value={status?.charAt(0)?.toUpperCase() + status?.slice(1)} />;
    };

    return (
        <div style={{ backgroundColor: "white" }}>
            {/* Ministry Logo */}
            <div style={{ display: "flex", gap: "1rem", textAlign: "center", alignItems: "center", justifyContent: "center", margin: "1rem", width: "100%" }}>
                <img src="/images/coatOfArms.png" alt="Coat of Arms of Uganda" style={{ width: "20px", height: "auto" }} />{" "}
                <span className="font-medium ml-2">
                    <span>Ministry of Water and Environment</span> <br /> <span>M & E Portal Aggregated Perfomance Report</span>
                </span>
            </div>

            <div style={cardStyle}>
                <div style={cardTitleStyle}>Report Details</div>
                <div className="flex flex-wrap">
                    {/* Assuming there's only one subproject for simplicity, adjust if it's multiple */}
                    {aggregatedReportDetail?.subprojects?.map((subproject, index) => (
                        <React.Fragment key={index}>
                            {/* Display Program and Directorate Details */}
                            {subproject?.project?.department_administration?.department?.program_directorate_department?.map((pdd, pddIndex) => {
                                const programName = pdd?.program_directorate?.program?.name || "N/A";
                                const directorateName = pdd?.program_directorate?.directorate?.name || "N/A";
                                return (
                                    <div key={`pdd-${pddIndex}`} className="md:w-1/2 px-2 m-1">
                                        <p>
                                            <strong>Program:</strong> {programName}
                                        </p>
                                        <p>
                                            <strong>Directorate:</strong> {directorateName}
                                        </p>
                                        <p>
                                            <strong>Department Name:</strong> {subproject?.project?.department_administration?.department?.name || "N/A"}
                                        </p>
                                        <p>
                                            <strong>Department Administration Name:</strong> {subproject?.project?.department_administration?.name || "N/A"}
                                        </p>
                                        <p>
                                            <strong>Project Title:</strong> {subproject?.project?.project_title || "N/A"}
                                        </p>
                                        <p>
                                            <strong>Project Code:</strong> {subproject?.project?.project_code || "N/A"}
                                        </p>
                                        <p>
                                            <strong>Subproject Name:</strong> {subproject?.name || "N/A"}
                                        </p>
                                        <p>
                                            <strong>Subproject Code:</strong> {subproject?.code || "N/A"}
                                        </p>
                                        <p>
                                            <strong>Subproject Status:</strong> {subproject?.status || "N/A"}
                                        </p>
                                        <p>
                                            <strong>Start Date:</strong> {subproject?.start_date ? moment(subproject.start_date).format("MMMM Do, YYYY") : "N/A"}
                                        </p>
                                        <p>
                                            <strong>End Date:</strong> {subproject?.actual_end_date ? moment(subproject.actual_end_date).format("MMMM Do, YYYY") : "N/A"}
                                        </p>
                                    </div>
                                );
                            })}
                            {/*
                            <div className="md:w-1/2 px-2 m-1">

                            </div> */}
                        </React.Fragment>
                    ))}
                </div>
            </div>

            {/* Financial and Quarterly Details converted to the new display style */}
            <div style={cardStyle}>
                <div style={cardTitleStyle}>Financial Details</div>
                <div style={gridStyle}>
                    <p>
                        <strong>Total Approved Budget:</strong> {formatCurrency(aggregatedReportDetail?.total_approved_budget)}
                    </p>
                    <p>
                        <strong>Total Spent Funds:</strong> {formatCurrency(aggregatedReportDetail?.total_spent_funds)}
                    </p>
                    <p>
                        <strong>Absorption Rate:</strong> {aggregatedReportDetail?.absorption_rate ? `${aggregatedReportDetail.absorption_rate}%` : "N/A"}
                    </p>
                </div>
            </div>

            {/* <div style={cardStyle}>
                <div style={cardTitleStyle}>Financial Year and Quarter Details</div>
                <div style={gridStyle}>
                    {aggregatedReportDetail?.financial_years?.map((year, index) => (
                        <p key={index}>
                            <strong>Financial Year:</strong> {year.name}
                        </p>
                    ))}
                    <div style={{ paddingTop: "1rem" }}>
                        {aggregatedReportDetail?.quarters?.map((quarter, index) => (
                            <p key={index} style={{ margin: 0 }}>
                                <strong>Quarter:</strong> {quarter.name}
                            </p>
                        ))}
                    </div>
                </div>
            </div> */}

            {/* Periods Information */}
            <div style={cardStyle}>
                <div style={cardTitleStyle}>Periods Details</div>
                <div className="flex flex-wrap">
                    {/* Iterate over periods */}
                    {aggregatedReportDetail?.periods.map((period, index) => (
                        <React.Fragment key={index}>
                            <div className="md:w-full px-2 m-2">
                                <p>
                                    <strong>Financial Year:</strong> {period.financial_year?.name}
                                </p>
                                <div>
                                    <strong>Quarters:</strong>
                                    <ul>
                                        {period.quarters.map((quarter, qIndex) => (
                                            <li key={qIndex}>{quarter.name}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>

            {/* Budget Outputs */}
            {aggregatedReportDetail?.budget_outputs?.map((output, index) => (
                <div key={index} style={cardStyle}>
                    <div style={cardTitleStyle}>Budget Output: {output.project_department_budget_output.department_budget_output.name}</div>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={thStyle}>Indicator</th>
                                <th style={thStyle}>Unit</th>
                                <th style={thStyle}>Total Value</th>
                                <th style={thStyle}>Village Details</th>
                                <th style={thStyle}>Assessment</th>
                                <th style={thStyle}>Reason of Variance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {output.indicators.map((indicator, idx) => (
                                <tr key={idx}>
                                    <td style={tdStyle}>{indicator.details.name}</td>
                                    <td style={tdStyle}>{indicator.details.unit}</td>
                                    <td style={tdStyle}>{indicator.total_value}</td>
                                    <td style={tdStyle}>
                                        {indicator.village_details.map((villageData, villageIndex) => (
                                            <div key={villageIndex} style={{ marginBottom: "10px" }}>
                                                <strong>Village Name:</strong> {villageData.village_info.name}
                                                <br />
                                                <strong>Total Value:</strong> {villageData.total_value}
                                                <br />
                                                {villageData.coordinates.map((coord, coordIndex) => (
                                                    <div key={coordIndex} style={{ cursor: "pointer", textDecoration: "underline", marginTop: "5px" }} onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${coord.latitude},${coord.longitude}`, "_blank")}>
                                                        <div style={{ textDecoration: "none" }}>Identifier: {coord.identifier}</div>
                                                        Lat: {coord.latitude}, Lng: {coord.longitude}
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </td>

                                    <td style={tdStyle}>
                                        {indicator.assessments.map((assessment, assessIndex) => (
                                            <div key={assessIndex}>{assessment.assessment}</div>
                                        ))}
                                    </td>
                                    <td style={tdStyle}>
                                        {indicator.assessments.map((assessment, assessIndex) => (
                                            <div key={assessIndex}>{assessment.reasonOfVariance}</div>
                                        ))}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ))}

            {/* Risks and Mitigations */}
            {aggregatedReportDetail?.risks_mitigations && (
                <div style={cardStyle}>
                    <div style={cardTitleStyle}>Risks and Mitigations</div>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={thStyle}>Risk</th>
                                <th style={thStyle}>Risk Type</th>
                                <th style={thStyle}>Mitigation</th>
                            </tr>
                        </thead>
                        <tbody>
                            {aggregatedReportDetail?.risks_mitigations.map((risk, index) => (
                                <tr key={index}>
                                    <td style={tdStyle}>{risk.risk}</td>
                                    <td style={tdStyle}>{risk.risk_type}</td>
                                    <td style={tdStyle}>{risk.mitigation}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {/* Challenges and Recommendations */}
            {aggregatedReportDetail?.recommendations && (
                <div style={cardStyle}>
                    <div style={cardTitleStyle}>Challenges and Recommendations</div>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={thStyle}>Challenges</th>
                                <th style={thStyle}>Recommendations</th>
                            </tr>
                        </thead>
                        <tbody>
                            {aggregatedReportDetail.recommendations.map((item, index) => (
                                <tr key={index}>
                                    <td style={tdStyle}>{item.challenges}</td>
                                    <td style={tdStyle}>{item.recommendations}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {/* Photos */}
            <div style={cardStyle}>
                <div style={cardTitleStyle}>Photos</div>
                {aggregatedReportDetail?.photos?.map((photo, index) => (
                    <div key={index} style={{ marginBottom: "10px" }}>
                        <img src={`${process.env.REACT_APP_API_BASE_URL}${photo.photo_url}`} alt={photo.caption} style={{ maxWidth: "100%", display: "block", marginBottom: "5px" }} />
                        <p style={{ margin: "5px 0" }}>
                            <strong>Caption:</strong> {photo.caption}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SubprojectAggregationPrint;
