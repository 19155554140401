import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { getAllDepartmentReports, postDepartmentReport, deleteDepartmentReportById } from "../../../../../services/departments/department-reports-service.js";
import AbsorptionRateChart from "./widgets/AbsorptionRateChart";
import { ProgressBar } from "primereact/progressbar";

//
function ArbsorptionRatePage({ department, financialYear, setFinancialYear, quarter, setQuarter }) {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const getAllDepartmentReportsQuery = useQuery(["department-reports", department?.id, financialYear, quarter], () => getAllDepartmentReports({ fyear_id: financialYear, quarter_id: quarter, department_id: department?.id }), {
        enabled: financialYear && quarter ? true : false,
        onSuccess: (data) => {
            console.log("fetching department-reports data for dasboard is : ", data);
        },
        onError: (error) => {
            console.log("fetching department-reports data for dashboard onerror is : ", error);
        },
        staleTime: 3 * 60 * 60 * 1000, // 3 hours in milliseconds
    });
    return (
        <>
            {getAllDepartmentReportsQuery.isLoading && getAllDepartmentReportsQuery.isFetching ? (
                <div className="p-field m-4">
                    <ProgressBar mode="indeterminate" style={{ height: "6px" }} />
                </div>
            ) : (
                <div>
                    <AbsorptionRateChart data={getAllDepartmentReportsQuery?.data?.data?.data} />
                </div>
            )}
        </>
    );
}

export default ArbsorptionRatePage;
