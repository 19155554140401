import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
// import CreateForm from "./CreateForm";

// import EditForm from "./EditForm";

import moment from "moment";

import { getAllUtilityThemeReports, getUtilityThemeReportById, postUtilityThemeReport, updateUtilityThemeReport, deleteUtilityThemeReportById, updateUtilityThemeReportDataStatus } from "../../../../../services/departments/utility-dept/departments-utility-theme-report-service.js";

import WaterIsLoading from "../../../../../components/mweComponents/WaterIsLoading";
import MuiTable from "../../../../../components/mweComponents/MuiTable";
import { toast } from "react-toastify";
import { Button } from "primereact/button";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useNavigate } from "react-router-dom";

import { Panel } from "primereact/panel";
import UtilityDeptReportPdfModal from "./UtilityDeptReportPdfModal";

function ListPage({ selectedDepartmentItem, subprojectDetail, subprojectViewPageActiveIndex, subprojectViewPageChildActiveIndex, ...props }) {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    console.log("selectedDepartmentItem utility reports : ", selectedDepartmentItem);

    const { data, isLoading, isError, error, status } = useQuery(["utility-theme-reports", selectedDepartmentItem?.id], () => (!selectedDepartmentItem?.id ? getAllUtilityThemeReports() : getAllUtilityThemeReports({ utility_id: selectedDepartmentItem?.id })), {
        onSuccess: (data) => {
            console.log("fetching utility-theme-reports data is : ", data);
        },
        onError: (error) => {
            console.log("Error fetching utility-theme-reports is : ", error);
        },
    });
    console.log(data);
    isError && toast.error("There was an error while fetching data");

    const DeleteSelectedItemMutation = useMutation((variables) => deleteUtilityThemeReportById(variables), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(["utility-theme-reports"]);
            // if (toast.current) {
            //     toast.current.show({ severity: "success", summary: "Success", detail: "Item deleted successfully", life: 3000 });
            // }

            toast.success("deleted successfully");
        },
        onError: (error) => {
            // Handle error case
            // if (toast.current) {
            //     toast.current.show({ severity: "error", summary: "Error", detail: "Error deleting item", life: 3000 });
            // }

            let errorMessage = "An error occurred, please contact admin";
            if (error?.response?.data?.message) {
                errorMessage = error.response.data.message;
            }
            toast.error(errorMessage);

            console.log("error deleting deleteUtilityReportById error:", error);
        },
    });

    // const handleDelete = async (event, id) => {
    //     var result = window.confirm("Are you sure you want to delete?");
    //     if (result === true) {
    //         ProgramDeleteMutation.mutate(id);
    //     }
    // };

    const handleDelete = (event, id) => {
        let selectedDeleteId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(selectedDeleteId),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (selectedDeleteId) => {
        if (selectedDeleteId !== null) {
            DeleteSelectedItemMutation.mutate(selectedDeleteId);
        }
    };

    const cancelDelete = () => {
        // setDeleteProgramId(null);
    };

    const [selectedItem, setSelectedItem] = useState();

    console.log("selected departmengt item theme indicator  : ", selectedItem);

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showBudjetOutPutAddForm, setShowBudjetOutPutAddForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    const onBudjetOutPutFormClose = () => {
        setShowBudjetOutPutAddForm(false);
    };

    // 'department_indicator_id',
    //     'fyear_id',
    //     'quarter_id',
    //     'baseline',
    //     'actual',
    //     'target',
    //     'reason_of_variance',
    //     'created_by',
    //     'updated_by'

    // departmentIndicator;
    // financialYear;
    // quarter;
    // createdBy;
    // updatedBy;
    //  /* // 'base_year', // 'base_value', // 'target_year', // 'target_value', // 'financial_year_target', */
    let tableId = 0;

    const columns = [
        {
            title: "#",
            width: "5%",
            field: "id",
            render: (rowData) => {
                // tableId = rowData.tableData.id;
                tableId = tableId++;
                return <div>{rowData.tableData.index + 1}</div>;
                // return <div>{rowData.tableData.id}</div>;
            },
        },
        {
            title: "Name",
            field: "name",
        },
        {
            title: "Financial Year",
            field: "financial_year.name",
        },
        {
            title: "Quarter",
            field: "quarter.name",
        },

        // {
        //     title: "Released Funds",
        //     field: "released_funds",
        //     hidden: true,
        // },

        {
            title: "Approval Status",
            field: "current_status.name",
            render: (rowData) => {
                let labelStyle = { padding: "3px", color: "#fff", borderRadius: "4px" };
                let labelClass = "";

                switch (rowData?.current_status?.name) {
                    case "draft":
                        labelStyle = { ...labelStyle, backgroundColor: "#f0ad4e" }; // Bootstrap warning color
                        labelClass = "label label-default";
                        break;
                    case "submitted":
                        labelStyle = { ...labelStyle, backgroundColor: "#5bc0de" }; // Bootstrap info color
                        labelClass = "label label-info";
                        break;
                    case "approved":
                        labelStyle = { ...labelStyle, backgroundColor: "#5cb85c" }; // Bootstrap success color
                        labelClass = "label label-success";
                        break;
                    case "rejected":
                        labelStyle = { ...labelStyle, backgroundColor: "#d9534f" }; // Bootstrap danger color
                        labelClass = "label label-danger";
                        break;
                    default:
                        labelStyle = { ...labelStyle, backgroundColor: "#5bc0de" }; // Bootstrap info color
                        labelClass = "label label-info";
                }

                return (
                    <strong style={labelStyle} className={labelClass}>
                        {rowData?.current_status?.name.charAt(0).toUpperCase() + rowData?.current_status?.name.slice(1)}
                    </strong>
                );
            },
        },

        {
            title: "Status",
            field: "status",
            hidden: true,
            render: (rowData) => {
                let labelStyle = { padding: "3px", color: "#fff", borderRadius: "4px" };
                let labelClass = "";

                switch (rowData.status) {
                    case "draft":
                        labelStyle = { ...labelStyle, backgroundColor: "#f0ad4e" }; // Bootstrap warning color
                        labelClass = "label label-default";
                        break;
                    case "submitted":
                        labelStyle = { ...labelStyle, backgroundColor: "#5bc0de" }; // Bootstrap info color
                        labelClass = "label label-info";
                        break;
                    case "approved":
                        labelStyle = { ...labelStyle, backgroundColor: "#5cb85c" }; // Bootstrap success color
                        labelClass = "label label-success";
                        break;
                    case "rejected":
                        labelStyle = { ...labelStyle, backgroundColor: "#d9534f" }; // Bootstrap danger color
                        labelClass = "label label-danger";
                        break;
                    default:
                        labelStyle = { ...labelStyle, display: "none" }; // Hide label if status is unknown
                }

                return (
                    <strong style={labelStyle} className={labelClass}>
                        {rowData.status.charAt(0).toUpperCase() + rowData.status.slice(1)}
                    </strong>
                );
            },
        },

        {
            title: "Updated By",
            hidden: true,
            field: "updated_by_user.email",
        },

        {
            title: "Created By",
            hidden: true,
            field: "created_by_user.email",
        },
        {
            title: "Date",
            hidden: true,
            field: "created_at",
            render: (rowData) => {
                return moment(rowData.created_at).format("lll");
            },
        },
    ];

    //======================= pdf ====================
    //  const [selectedItem, setSelectedItem] = useState({ id: null });
    const [showPDF, setShowPDF] = useState(false);
    const handleShowPDF = (e, item) => {
        setSelectedItem(item);
        setShowPDF(true);
    };
    const handleClosePDF = () => {
        setSelectedItem({ id: null });
        setShowPDF(false);
    };

    return (
        <div style={{ width: "100%" }}>
            <Panel header="Department Reports" style={{ marginBottom: "20px" }}>
                {/* <div className="d-flex justify-content-end mb-3" style={{ width: "100%" }}>
                    {activeUser?.permissions.includes("add programs") && <Button label="Add Program" className="p-button-primary right-0" onClick={() => setShowAddForm(true)} />}
                    <CreateForm show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} />
                </div> */}

                <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                    {/* {activeUser?.permissions.includes("add programs") && (
                        <Button
                            label="Add Budget Outputs"
                            className="p-button-primary"
                            onClick={() => {
                                setShowBudjetOutPutAddForm(true);
                                console.log("clicked add outcomes");
                            }}
                        />
                    )} */}
                    {activeUser?.permissions.includes("add reports") && (
                        <Button
                            label="Create Report"
                            className="p-button-primary"
                            onClick={() => {
                                setShowAddForm(true);
                                navigate("reporting", { state: { selectedParentData: selectedDepartmentItem, subprojectViewPageActiveIndex: subprojectViewPageActiveIndex, subprojectViewPageChildActiveIndex: subprojectViewPageChildActiveIndex } });
                            }}
                        />
                    )}
                    {/* <CreateForm show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} projectId={project_id} subprojectDetail={subprojectDetail} /> */}
                    {/* <BudgetOutPutsCreateForm show={showBudjetOutPutAddForm} onHide={() => setShowBudjetOutPutAddForm(false)} onClose={onBudjetOutPutFormClose} /> */}
                </div>

                <MuiTable
                    tableTitle="Department Reports"
                    tableData={data?.data?.data}
                    tableColumns={columns}
                    handleDelete={(e, item_id) => handleDelete(e, item_id)}
                    hideRowEdit={(rowData) => {
                        return rowData.status === "submitted" || rowData.status === "approved" ? true : false;
                    }}
                    showDelete={activeUser?.permissions.includes("edit reports")}
                    // handleViewPage={(rowData) => {
                    //   return handleShowPDF(rowData);
                    // }}
                    // showViewPage={true}
                    // hideRowViewPage={false}
                    //view reports pdf
                    handleViewPdf={(e, item_id) => handleShowPDF(e, item_id)}
                    //
                    hideViewPdfRow={(rowData) => {
                        console.log("rowdata llllvvccd : ", rowData);
                        return rowData.status === "submitted" || rowData.status === "approved" ? false : true;
                    }}
                    showViewPdf={true}
                    exportButton={true}
                    loading={isLoading || status === "loading" || DeleteSelectedItemMutation.isLoading}
                />

                <UtilityDeptReportPdfModal selectedDepartmentItem={selectedDepartmentItem} selectedItem={selectedItem} loggedInUserData={props?.loggedInUserData} show={showPDF} onHide={() => setShowPDF(false)} onClose={onFormClose} />

                {/* {selectedItem && <EditForm rowData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} subprojectDetail={subprojectDetail} />} */}

                {DeleteSelectedItemMutation.isLoading && <WaterIsLoading />}
                {/* <ConfirmDialog /> */}
            </Panel>
        </div>
    );
}

export default ListPage;
