import axiosAPI from "../axiosApi";

export async function getAlldepartmentOutcomes(params = {}) {
    const response = await axiosAPI.get("departmentOutcomes", { params: params });
    return response;
}

export async function getdepartmentOutcomesById(id) {
    const response = await axiosAPI.get(`departmentOutcomes/${id}`);
    return response;
}

export async function postdepartmentOutcomes(data) {
    const response = await axiosAPI.post(`departmentOutcomes`, data);
    return response;
}

export async function updatedepartmentOutcomes(id, data) {
    const response = await axiosAPI.put(`departmentOutcomes/${id}`, data);
    return response;
}

export async function deletedepartmentOutcomesById(id) {
    const response = await axiosAPI.delete(`departmentOutcomes/${id}`);
    return response;
}
