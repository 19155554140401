import React from "react";
import moment from "moment";
import { ProgressBar } from "primereact/progressbar";

function ProjectReportPrint({ projectReport, ...props }) {
    console.log("projectReport fdgdfgfd: ", projectReport);
    // Helper function to format currency
    const formatCurrency = (value) => {
        return new Intl.NumberFormat("en-US", { style: "currency", currency: "UGX" }).format(value);
    };

    // Helper function to format date
    const formatDate = (dateString) => {
        return moment(dateString).format("MMMM Do YYYY, h:mm:ss a");
    };

    // Inline styles...
    const cardStyle = {
        marginBottom: "20px",
        padding: "15px",
        border: "1px solid #ddd",
        borderRadius: "5px",
    };

    const cardTitleStyle = {
        color: "#00B9E8",
        fontWeight: "bold",
        marginBottom: "10px",
    };

    const tableStyle = {
        width: "100%",
        marginBottom: "20px",
        borderCollapse: "collapse",
    };

    const thStyle = {
        backgroundColor: "#00B9E8",
        color: "white",
        padding: "10px",
        textAlign: "left",
        border: "1px solid #ddd",
    };

    const tdStyle = {
        padding: "10px",
        border: "1px solid #ddd",
    };

    // Component JSX...
    return (
        <div style={{ backgroundColor: "white" }}>
            {/* Ministry Logo */}
            <div style={{ display: "flex", gap: "1rem", textAlign: "center", alignItems: "center", justifyContent: "center", margin: "1rem", width: "100%" }}>
                <img src="/images/coatOfArms.png" alt="Coat of Arms of Uganda" style={{ width: "20px", height: "auto" }} />{" "}
                <span className="font-medium ml-2">
                    <span>Ministry of Water and Environment</span> <br /> <span>M & E Portal Project Report</span>
                </span>
            </div>
            {/* Project Details */}
            <div style={cardStyle}>
                <div style={cardTitleStyle}>Project Details</div>
                <div className="flex flex-wrap" style={{ justifyContent: "space-between" }}>
                    {/* Column 1 */}
                    <div className="md:w-1/2 px-2 m-1">
                        {" "}
                        {projectReport?.project?.department_administration.department?.program_directorate_department?.map((pdd, index) => {
                            const programName = pdd?.program_directorate?.program?.name || "N/A";
                            const directorateName = pdd?.program_directorate?.directorate?.name || "N/A";
                            const uniqueKey = `pdd-${pdd?.program_directorate?.program?.id}-${pdd?.program_directorate?.directorate?.id}-${index}`;

                            return (
                                <div key={uniqueKey}>
                                    <p>
                                        <strong>Program:</strong> {programName}
                                    </p>
                                    <p>
                                        <strong>Directorate:</strong> {directorateName}
                                    </p>
                                </div>
                            );
                        })}
                        <p>
                            <strong>Department Name:</strong> {projectReport?.project?.department_administration?.department?.name || "N/A"}
                        </p>
                        <p>
                            <strong>Department Administration Name:</strong> {projectReport?.project?.department_administration?.name || "N/A"}
                        </p>
                        <p>
                            <strong>Project Title:</strong> {projectReport?.project?.project_title}
                        </p>
                        <p>
                            <strong>Project Code:</strong> {projectReport?.project?.project_code}
                        </p>{" "}
                    </div>
                    {/* Column 2 */}
                    <div className="md:w-1/2 px-2 m-1">
                        {" "}
                        {/* <p>
                            <strong>Periods:</strong> {projectReport?.financial_year?.name}
                        </p> */}
                        {/* <p>
                            <strong>Quarter:</strong> {projectReport?.quarter?.name}
                        </p>
                        <p>
                            <strong>Approval Status:</strong> {projectReport?.current_status?.name}
                        </p>
                        <p>
                            <strong>Approval Comment:</strong> {projectReport?.current_status?.reason}
                        </p> */}
                    </div>{" "}
                    {/* Full Width Row for Details */}
                    <div className="w-full px-2 m-2">
                        {/* <strong>Description:</strong>
                        <p>{projectReport?.description}</p> */}
                        <p>
                            <strong>Periods:</strong> {projectReport?.financial_year?.name}
                        </p>
                    </div>
                </div>
            </div>

            {/* Budget Details */}
            <div style={cardStyle}>
                <div style={cardTitleStyle}>Budget Details</div>
                <p>
                    <strong>Released Funds:</strong> {formatCurrency(projectReport?.approved_budget)}
                </p>
                <p>
                    <strong>Spent Funds:</strong> {formatCurrency(projectReport?.spent_funds)}
                </p>
                <p>
                    <strong>Absorption Rate:</strong> {projectReport?.absorption_rate}%
                </p>
            </div>

            {/* Department Outcomes */}
            {projectReport.department_outcomes?.map((outcome, index) => (
                <div key={index} style={cardStyle}>
                    <div style={cardTitleStyle}>Department Outcome: {outcome.department_outcome.outcome.name}</div>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={thStyle}>Outcome Indicator Name</th>
                                {/* <th style={thStyle}>Outcome Indicator Code</th> */}
                                <th style={thStyle}>Base Year</th>
                                <th style={thStyle}>Base Value</th>
                                <th style={thStyle}>Target Year</th>
                                <th style={thStyle}>Target Value</th>
                                {/* <th style={thStyle}>Financial Year</th>
                                <th style={thStyle}>Financial Year Target</th> */}
                                <th style={thStyle}>Value</th>
                                {/* <th style={thStyle}>Census</th> */}
                                {/* Additional headers as needed */}
                            </tr>
                        </thead>
                        <tbody>
                            {outcome.project_report_dept_outcome_indicator_target_actual?.map((indicator, idx) => {
                                // we add () after this function inorder to imediatelly invoke it
                                // Move the variable declaration outside the JSX return structure.
                                let unit = indicator.department_outcome_indicator_target.department_outcome_indicator.outcome_indicator.unit;

                                // Return the JSX element for each mapped item.
                                return (
                                    <tr key={idx}>
                                        {/* <td style={tdStyle}>{unit}</td> */}
                                        <td style={tdStyle}>{indicator.department_outcome_indicator_target.department_outcome_indicator.outcome_indicator.name}</td>
                                        <td style={tdStyle}>{indicator.department_outcome_indicator_target.base_year}</td>
                                        <td style={tdStyle}>{`${parseInt(indicator.department_outcome_indicator_target.base_value) || 0} ${unit}`}</td>
                                        <td style={tdStyle}>{indicator.department_outcome_indicator_target.target_year}</td>
                                        <td style={tdStyle}>{`${parseInt(indicator.department_outcome_indicator_target.target_value) || 0} ${unit}`}</td>
                                        {/* <td style={tdStyle}>{indicator.department_outcome_indicator_target.financial_year.name}</td> */}
                                        {/* <td style={tdStyle}>{`${parseInt(indicator.department_outcome_indicator_target.financial_year_target) || 0}${unit}`}</td> */}
                                        {/* <td style={tdStyle}>{`${parseInt(indicator.value) || 0}%`}</td> */}
                                        <td style={{ ...tdStyle, width: "60px" }}>
                                            {(() => {
                                                // Initialize a variable to store the final value
                                                let finalValue;

                                                // Check if manual value should be used
                                                if (indicator?.department_outcome_indicator_target?.department_outcome_indicator?.outcome_indicator?.show_manual_value) {
                                                    // Use the manual value and store in finalValue
                                                    finalValue = indicator?.uncomputed_value;
                                                } else {
                                                    // Otherwise, use the target value and store in finalValue
                                                    finalValue = indicator.value;
                                                }

                                                // Return the finalValue with a percentage sign
                                                return `${finalValue} ${unit}`;
                                            })()}
                                        </td>

                                        {/* <td style={tdStyle}>{indicator.census_name}</td> */}
                                        {/* Additional cells as needed */}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            ))}

            {/* Budget Outputs */}
            {projectReport.budget_outputs?.map((output, index) => (
                <div key={index} style={cardStyle}>
                    <div style={cardTitleStyle}>Budget Output: {output.project_department_budget_output.department_budget_output.name}</div>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                {/* <th style={thStyle}>Indicator Code</th> */}
                                <th style={thStyle}>Indicator Name</th>
                                <th style={thStyle}>Unit</th>
                                {/* <th style={thStyle}>Indicator Multiplier</th> */}
                                {/* <th style={thStyle}>Financial Year</th>
                                <th style={thStyle}>Quarter</th> */}
                                <th style={thStyle}>Target</th>
                                <th style={thStyle}>Value</th>
                                {/* <th style={thStyle}>Assessment</th>
                                <th style={thStyle}>Reason of Variance</th> */}
                                {/* Additional headers as needed */}
                            </tr>
                        </thead>
                        <tbody>
                            {output.project_report_output_indicator_actual?.map((indicator, idx) => (
                                <tr key={idx}>
                                    {/* <td style={tdStyle}>{indicator.project_department_budget_output_indicator_target.department_budget_output_indicator.output_indicator.code}</td> */}
                                    <td style={tdStyle}>{indicator.project_department_budget_output_indicator_target.department_budget_output_indicator.output_indicator.name}</td>
                                    <td style={tdStyle}>{indicator.project_department_budget_output_indicator_target.department_budget_output_indicator.output_indicator.unit}</td>
                                    {/* <td style={tdStyle}>{indicator.project_department_budget_output_indicator_target.department_budget_output_indicator.output_indicator.multiplier}</td> */}
                                    {/* <td style={tdStyle}>{indicator.project_department_budget_output_indicator_target.financial_year.name}</td>
                                    <td style={tdStyle}>{indicator.project_department_budget_output_indicator_target.quarter.name}</td> */}
                                    <td style={tdStyle}>{indicator.project_department_budget_output_indicator_target.target}</td>
                                    <td style={tdStyle}>{indicator.value}</td>
                                    {/* <td style={tdStyle}>{indicator.assessment}</td>
                                    <td style={tdStyle}>{indicator.reasonOfVariance}</td> */}
                                    {/* Additional cells as needed */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ))}

            {/* Subprojects */}
            {projectReport.subproject_field_data && (
                <div style={cardStyle}>
                    <div style={cardTitleStyle}>Subprojects</div>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={thStyle}>Subproject Name</th>
                                <th style={thStyle}>Code</th>
                                <th style={thStyle}>Start Date</th>
                                <th style={thStyle}>Proposed End Date</th>
                                <th style={thStyle}>Completion Status</th>
                                <th style={thStyle}>Completion</th>
                                <th style={thStyle}>Approved Budget</th>
                                <th style={thStyle}>Spent Funds</th>
                                <th style={thStyle}>Absorption Rate</th>
                                {/* Additional headers as needed */}
                            </tr>
                        </thead>
                        <tbody>
                            {projectReport.subproject_field_data.map((subproject, index) => (
                                <tr key={index}>
                                    <td style={tdStyle}>{subproject.subproject_field_data.subproject.name}</td>
                                    <td style={tdStyle}>{subproject.subproject_field_data.subproject.code}</td>
                                    <td style={tdStyle}>{formatDate(subproject.subproject_field_data.subproject.start_date)}</td>
                                    <td style={tdStyle}>{formatDate(subproject.subproject_field_data.subproject.proposed_end_date)}</td>
                                    <td style={tdStyle}>{subproject.subproject_field_data.subproject.completion_status}</td>
                                    <td style={tdStyle}>
                                        {subproject.subproject_field_data.subproject.completion}%
                                        <ProgressBar value={subproject.subproject_field_data.subproject.completion} showValue={true} />
                                    </td>
                                    <td style={tdStyle}>{formatCurrency(subproject.subproject_field_data.approved_budget)}</td>
                                    <td style={tdStyle}>{formatCurrency(subproject.subproject_field_data.spent_funds)}</td>
                                    <td style={tdStyle}>{subproject.subproject_field_data.absorption_rate}%</td>
                                    {/* Additional cells as needed */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {/* Risks and Mitigations */}
            {projectReport?.risks_mitigation && (
                <div style={cardStyle}>
                    <div style={cardTitleStyle}>Risks and Mitigations</div>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={thStyle}>Risk</th>
                                <th style={thStyle}>Risk Type</th>
                                <th style={thStyle}>Mitigation</th>
                                {/* Additional headers as needed */}
                            </tr>
                        </thead>
                        <tbody>
                            {projectReport.risks_mitigation.map((risk, index) => (
                                <tr key={index}>
                                    <td style={tdStyle}>{risk.risk}</td>
                                    <td style={tdStyle}>{risk.risk_type}</td>
                                    <td style={tdStyle}>{risk.mitigation}</td>
                                    {/* Additional cells as needed */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {/* Challenges and Recommendations */}
            {projectReport?.challenge_recommendations && (
                <div style={cardStyle}>
                    <div style={cardTitleStyle}>Challenges and Recommendations</div>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={thStyle}>Challenges</th>
                                <th style={thStyle}>Recommendations</th>
                                {/* Additional headers as needed */}
                            </tr>
                        </thead>
                        <tbody>
                            {projectReport.challenge_recommendations.map((challenge, index) => (
                                <tr key={index}>
                                    <td style={tdStyle}>{challenge.challenges}</td>
                                    <td style={tdStyle}>{challenge.recommendations}</td>
                                    {/* Additional cells as needed */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {/* Display Photos */}
            {projectReport?.photos && (
                <div style={cardStyle}>
                    <div style={cardTitleStyle}>Photos</div>
                    {projectReport.photos.map((photo, index) => (
                        <div key={index} style={{ marginBottom: "20px" }}>
                            <img src={`${process.env.REACT_APP_API_BASE_URL}${photo.photo_url}`} alt={photo.caption} style={{ maxWidth: "100%", display: "block", marginBottom: "10px" }} />
                            <div style={{ textAlign: "center" }}>{photo.caption}</div>
                        </div>
                    ))}
                </div>
            )}

            {/* Created By and Updated By Information */}
            {/* <div style={cardStyle}>
                <p>
                    <strong>Created By:</strong> {projectReport?.created_by_user?.name} at {formatDate(projectReport?.created_at)}
                </p> */}
            {/* <p>
                    <strong>Updated By:</strong> {projectReport?.updated_by_user?.name} at {formatDate(projectReport?.updated_at)}
                </p> */}
            {/* </div> */}
        </div>
    );
}

export default ProjectReportPrint;
