import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";

import { Dropdown } from "primereact/dropdown";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteProjectById, getAllProjects, getProjectsByProjectIdOrRole, getProjectsByDepartmentIdAndRole } from "../../../../../../services/projects/project-service";
import { getAllDepartments } from "../../../../../../services/departments/department-service";
import { classNames } from "primereact/utils";
import { getAllDepartmentBudjetOutputs, getDepartmentBudjetOutputById, postDepartmentBudjetOutput, updateDepartmentBudjetOutput, deleteDepartmentBudjetOutputById } from "../../../../../../services/departments/departments-budget-outputs-service.js";
import { getAllOutputIndicators, getOutputIndicatorById, postOutputIndicator, updateOutputIndicator, deleteOutputIndicatorById } from "../../../../../../services/directorates/output-indicator-service.js";

//

import { AutoComplete } from "primereact/autocomplete";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DeleteIcon from "@mui/icons-material/Delete";
import { ProgressSpinner } from "primereact/progressspinner";

function RowForm({ handleSubmit, initialData, selectedBudgetOutputItem, ...props }) {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);
    const queryClient = useQueryClient();

    const validate = (values) => {
        const errors = {};

        if (!values.department_id) errors.department_id = "Department is required";
        if (!values.output_indicators_ids) errors.output_indicators_ids = "Atleast Choose an output indicator";

        if (!values.department_budget_outputs_id) errors.department_budget_outputs_id = "Department Budget Output is required";

        return errors;
    };

    const onSubmitForm = (data) => {
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            // No validation errors
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            toast.warning("First Fill In All Required Fields");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    //================ departments ==============
    console.log("selectedBudgetOutputItem tttt : ", selectedBudgetOutputItem);
    const fetchAllDepartments = useQuery(
        ["departments", "by-directorate", selectedBudgetOutputItem?.department?.directorate_id],
        () => (!selectedBudgetOutputItem?.department?.directorate_id ? getAllDepartments() : getAllDepartments({ directorate_id: selectedBudgetOutputItem?.department?.directorate_id })),
        {
            onSuccess: (data) => {
                // setDepartments(data.data);
            },
            onError: (error) => {
                // props.onClose();
                error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
                console.log("get list of departments : ", error);
            },
        }
    );

    // initialData = initialData ? { department_id: props?.department_id, ...initialData } : { department_id: selectedBudgetOutputItem?.department_id, department_budget_outputs_id: selectedBudgetOutputItem?.id };

    if (selectedBudgetOutputItem) {
        initialData = { department_id: selectedBudgetOutputItem?.department_id, department_budget_outputs_id: selectedBudgetOutputItem?.id, ...initialData };
    }

    //================== departmengt budget outputs =============
    const fetchAllDepartmentBudjetOutputs = useQuery(["departments", "departmentBudgetOutputs", selectedBudgetOutputItem?.department_id], () => getAllDepartmentBudjetOutputs({ department_id: selectedBudgetOutputItem?.department_id }), {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching projectSubprojectMetricsIndicator is : ", error);
        },
    });

    //================ outcomes ===============
    // ====================== department outcome ==============================

    if (selectedBudgetOutputItem) {
        initialData = { department_outcomes_id: selectedBudgetOutputItem?.id, ...initialData };
    }

    const GetAllOutPutIndicatorsQuery = useQuery(
        ["directorate", "output-indicator", selectedBudgetOutputItem?.department?.directorate_id, selectedBudgetOutputItem?.department_id],
        () =>
            !selectedBudgetOutputItem?.department?.directorate_id
                ? getAllOutputIndicators({ department_id: selectedBudgetOutputItem?.department_id })
                : getAllOutputIndicators({ department_id: selectedBudgetOutputItem?.department_id, directorate_id: selectedBudgetOutputItem?.department?.directorate_id }),
        {
            onSuccess: (data) => {
                console.log("fetching departmentBudgetOutputsIndicators data is : ", data);
            },
            onError: (error) => {
                console.log("Error fetching departmentBudgetOutputsIndicators is : ", error);
            },
        }
    );

    //  const [dropdownItem, setDropdownItem] = useState([]);

    //  const [selectedAutoCompleteDropdownItem, setSelectedAutoCompleteDropdownItem] = useState(DirectorateData.programs || []);

    // const [dropdownDistricts, setDropdownDistricts] = useState([]);
    const [dropdownItem, setDropdownItem] = useState([]);
    // const [selectedDistrict, setSelectedDistrict] = useState(ProjectsData.districts || []);
    const [selectedAutoCompleteDropdownItem, setSelectedAutoCompleteDropdownItem] = useState([]);

    console.log("kkkkkkk selectedDistrict xxxxx  : ", selectedAutoCompleteDropdownItem);

    // Special option for selecting all districts
    const selectAllOption = { id: "all", name: "Select All" };

    //
    const fetchSuggestions = (query) => {
        let filteredItems = GetAllOutPutIndicatorsQuery.data?.data?.data.filter((district) => {
            return !selectedAutoCompleteDropdownItem.map((sp) => sp.id).includes(district.id) && district.name.toLowerCase().includes(query.toLowerCase());
        });

        // Add 'Select All' option only if no districts are selected
        if (selectedAutoCompleteDropdownItem.length > 0 && (query.toLowerCase() === "select all" || query === "")) {
            filteredItems = [selectAllOption, ...filteredItems];
        }

        setDropdownItem(filteredItems);
    };

    //=========================== data table pagination ======================
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(5); // Convert to state variable

    // Function to handle page changes
    const onPaging = (event) => {
        setPage(event.first / event.rows);
        setRows(event.rows); // Update number of rows per page
    };

    return (
        <div className="col-12 md:col-12">
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                console.log("values hhh : ", values);
                                console.log("event fffff : ", event);
                                onSubmitForm(values);
                                // handleSubmit(event, values);
                            }}
                        >
                            {/* <div style={{ marginBottom: "1rem" }}>
                                <Field name="output_indicators_id">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Output Indicator</label>
                                            <Dropdown
                                                value={input.value}
                                                options={GetAllOutPutIndicatorsQuery?.data?.data?.data.map((item) => ({
                                                    label: item.name,
                                                    value: item.id,
                                                }))}
                                                onChange={(e) => input.onChange(e.value)}
                                                placeholder="Select Output Indicator"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                disabled={GetAllOutPutIndicatorsQuery?.isLoading}
                                            />
                                            {GetAllOutPutIndicatorsQuery.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            </div> */}

                            <Field name="output_indicators_ids">
                                {({ input, meta }) => {
                                    console.log("kkkkkkk first main ffffff input.value : ", input.value);
                                    return (
                                        <div className="p-field m-4">
                                            <label htmlFor="output_indicators_ids">Output indicators </label>
                                            <AutoComplete
                                                {...input}
                                                suggestions={dropdownItem}
                                                completeMethod={(e) => fetchSuggestions(e.query)}
                                                multiple={true}
                                                value={selectedAutoCompleteDropdownItem}
                                                disabled={GetAllOutPutIndicatorsQuery.isLoading} // Disable component if data is still loading
                                                field="name"
                                                dropdown={true}
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                onChange={(e) => {
                                                    const newSelected = e.value;

                                                    // Handle 'Select All Districts'
                                                    if (newSelected.some((district) => district.id === "all")) {
                                                        // Add all districts to the selection
                                                        const allDistricts = GetAllOutPutIndicatorsQuery.data?.data?.data || [];
                                                        setSelectedAutoCompleteDropdownItem(allDistricts);
                                                        input.onChange(allDistricts.map((district) => district.id));
                                                    } else {
                                                        if (newSelected.length < selectedAutoCompleteDropdownItem.length) {
                                                            // District removed
                                                            const removedDistrict = selectedAutoCompleteDropdownItem.find((district) => !newSelected.includes(district));
                                                            if (removedDistrict) {
                                                                const updatedSelectedDistricts = selectedAutoCompleteDropdownItem.filter((district) => district.id !== removedDistrict.id);
                                                                setSelectedAutoCompleteDropdownItem(updatedSelectedDistricts);
                                                                input.onChange(updatedSelectedDistricts.map((district) => district.id));
                                                            }
                                                        } else {
                                                            // District added
                                                            const addedDistrict = newSelected[newSelected.length - 1];
                                                            setSelectedAutoCompleteDropdownItem([...selectedAutoCompleteDropdownItem, addedDistrict]);
                                                            input.onChange([...input.value, addedDistrict.id]);
                                                        }
                                                    }

                                                    fetchSuggestions("");
                                                }}
                                                id="output_indicators_ids"
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                            {/* DataTable to display selected programs */}
                                            {input.value && input.value.length > 0 && (
                                                <div className="p-field m-4">
                                                    <DataTable value={selectedAutoCompleteDropdownItem} paginator rows={rows} first={page * rows} onPage={onPaging} paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" rowsPerPageOptions={[5, 10, 20]}>
                                                        <Column field="id" header="ID"></Column>
                                                        <Column field="name" header="Name"></Column>
                                                        <Column field="type" header="Type"></Column>
                                                        <Column field="directorate.name" header="Directorate Name"></Column>
                                                        <Column
                                                            body={(rowData) => (
                                                                <DeleteIcon
                                                                    style={{ color: "tomato", cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        const updatedSelectedDistrict = selectedAutoCompleteDropdownItem.filter((district) => district.id !== rowData.id);
                                                                        setSelectedAutoCompleteDropdownItem(updatedSelectedDistrict);
                                                                        input.onChange(updatedSelectedDistrict.map((prog) => prog.id));
                                                                        fetchSuggestions(""); // Refresh the dropdown suggestions
                                                                    }}
                                                                />
                                                            )}
                                                            header="Actions"
                                                        ></Column>
                                                    </DataTable>
                                                </div>
                                            )}

                                            {GetAllOutPutIndicatorsQuery.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        </div>
                                    );
                                }}
                            </Field>

                            <div style={{ marginBottom: "1rem" }}>
                                <Field name="department_id">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Department</label>
                                            <Dropdown
                                                value={input.value}
                                                options={fetchAllDepartments?.data?.data?.data.map((item) => ({
                                                    label: item.name,
                                                    value: item.id,
                                                }))}
                                                onChange={(e) => input.onChange(e.value)}
                                                // disabled={props?.loggedInUserData?.role !== "Admin"}
                                                placeholder="Select Department"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                disabled={fetchAllDepartments.isLoading}
                                            />
                                            {fetchAllDepartments.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <div style={{ marginBottom: "1rem" }}>
                                <Field name="department_budget_outputs_id">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Department Budget Output</label>
                                            <Dropdown
                                                value={input.value}
                                                options={fetchAllDepartmentBudjetOutputs?.data?.data?.data.map((item) => ({
                                                    label: item.name,
                                                    value: item.id,
                                                }))}
                                                onChange={(e) => input.onChange(e.value)}
                                                // disabled={props?.loggedInUserData?.role !== "Admin"}
                                                placeholder="Select Department Budget Output"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                disabled={fetchAllDepartmentBudjetOutputs.isLoading}
                                            />
                                            {fetchAllDepartmentBudjetOutputs.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <div className="d-grid gap-2">
                                <Button type="submit" label="Save" className="p-button-primary" icon="pi pi-check" />
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    style={{ width: "30vw" }}
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to submit?
                </Dialog>
            </div>
        </div>
    );
}

export default RowForm;
