import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";

import { Dropdown } from "primereact/dropdown";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { classNames } from "primereact/utils";
import { deleteProjectById, getAllProjects, getProjectsByProjectIdOrRole, getProjectsByDepartmentIdAndRole } from "../../../../../services/projects/project-service";
import { ProgressSpinner } from "primereact/progressspinner";

import setFieldTouched from "final-form-set-field-touched";
import { getAllFinancialYears, getFinancialYearsById, postFinancialYears, updateFinancialYears, deleteFinancialYears } from "../../../../../services/financial-years/financial-years-service";

function RowForm({ handleSubmit, initialData, selectedParentItem, ...props }) {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);
    const queryClient = useQueryClient();

    const validate = (values) => {
        const errors = {};

        // Assuming department_id and fyear_id validations remain the same
        if (!values.project_id) errors.project_id = "Project is required";
        if (!values.fyear_id) errors.fyear_id = "Financial year is required";

        if (!values.budget_amount || values.budget_amount <= 0) {
            errors.budget_amount = "Please provide a valid value.";
        } else {
            // Remove commas and check if the number is too large
            const numericValue = values.budget_amount.replace(/,/g, "");
            if (numericValue.split(".")[0].length > 15) {
                errors.budget_amount = "Budget amount must be less than or equal to 15 digits.";
            }
        }

        return errors;
    };

    // const onSubmitForm = (data) => {
    //     const errors = validate(data);
    //     if (Object.keys(errors).length === 0) {
    //         // No validation errors
    //         setPendingData(data);
    //         setShowConfirmDialog(true);
    //     } else {
    //         toast.warning("First Fill In All Required Fields");
    //     }
    // };

    const onSubmitForm = (data, form) => {
        // Add 'form' as an argument
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            const budgetAmount = parseFloat(data?.budget_amount.replace(/,/g, ""));
            const submissionData = {
                ...data,
                budget_amount: budgetAmount,
            };
            setPendingData(submissionData);
            setShowConfirmDialog(true);
        } else {
            // Mark all fields as touched to show validation errors
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("First fill in all required fields.");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    if (selectedParentItem) {
        initialData = { project_id: selectedParentItem?.id, ...initialData };
    }

    // ========================== fetch all projects =======================
    const fetchAllProjects = useQuery(["projects", "department_administrations_id", selectedParentItem?.department_administrations_id], () => getAllProjects({ department_administrations_id: selectedParentItem?.department_administrations_id }), {
        onSuccess: (data) => {
            console.log("getAllProjects onsuccess fetching projects dsfsd : ", data);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
            console.log("get list of projects : ", error);
        },
    });

    // ================== get finacial years =================

    const getAllFinancialYearsQuery = useQuery(["financialYear"], getAllFinancialYears, {
        onSuccess: (data) => {},
        onError: (error) => {
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
            console.log("Error fetching getAllFinancialYears is : ", error);
        },
    });

    return (
        <div className="col-12 md:col-12">
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    mutators={{ setFieldTouched }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                console.log("values hhh : ", values);
                                console.log("event fffff : ", event);
                                onSubmitForm(values, form);
                                // handleSubmit(event, values);
                            }}
                        >
                            <div style={{ marginBottom: "1rem" }}>
                                <Field name="project_id">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Project</label>
                                            <Dropdown
                                                value={input.value}
                                                options={fetchAllProjects?.data?.data?.data.map((item) => ({
                                                    label: item.project_title,
                                                    value: item.id,
                                                }))}
                                                onChange={(e) => input.onChange(e.value)}
                                                placeholder="Select Project"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                // disabled={fetchAllProjects.isLoading}
                                                disabled={true}
                                            />

                                            {fetchAllProjects.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <div style={{ marginBottom: "1rem" }}>
                                <Field name="fyear_id">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Financial Year</label>
                                            <Dropdown
                                                value={input.value}
                                                options={getAllFinancialYearsQuery?.data?.data?.data.map((item) => ({
                                                    label: item.name,
                                                    value: item.id,
                                                }))}
                                                onChange={(e) => input.onChange(e.value)}
                                                // disabled={props?.loggedInUserData?.role !== "Admin"}
                                                placeholder="Select Financial Year"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <div style={{ marginBottom: "1rem" }}>
                                <Field name="budget_amount">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="budget_amount">Budget Amount</label>
                                            <InputText
                                                {...input}
                                                id="budget_amount"
                                                type="text" // Use text type to allow formatting
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                value={input.value}
                                                onChange={(e) => {
                                                    const handleNumberChange = (input, value) => {
                                                        // Strip out non-numeric characters (except decimal point)
                                                        const numericValue = value.replace(/[^\d.]/g, "");

                                                        // Check the length of digits before the decimal point does not exceed 15
                                                        if (numericValue.split(".")[0].length > 15) {
                                                            // If it does, do not update the input value and potentially show a warning message
                                                            return;
                                                        }

                                                        // Convert to a number and then back to a string to remove leading zeroes
                                                        const number = numericValue ? parseFloat(numericValue).toString() : "";

                                                        // Format the number with commas for display purposes
                                                        const formattedValue = number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                                                        // Update the input's displayed value with formattedValue
                                                        input.onChange(formattedValue);
                                                    };
                                                    return handleNumberChange(input, e.target.value);
                                                }}
                                            />
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            {/* <div style={{ marginBottom: "1rem" }}>
                                <Field name="budget_amount">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Budget Amount</label>
                                            <InputText
                                                {...input}
                                                id="budget_amount"
                                                type="number"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    // Allow only non-negative numbers
                                                    if (!value || parseFloat(value) >= 0) {
                                                        input.onChange(value);
                                                    }
                                                }}
                                            />
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            </div> */}

                            <div className="d-grid gap-2">
                                <Button type="submit" label="Save" className="p-button-primary" icon="pi pi-check" />
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    style={{ width: "30vw" }}
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to submit?
                </Dialog>
            </div>
        </div>
    );
}

export default RowForm;
