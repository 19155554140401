import React, { useState, useRef } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import setFieldTouched from "final-form-set-field-touched";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { AutoComplete } from "primereact/autocomplete";
import { ProgressSpinner } from "primereact/progressspinner";

import { Dropdown } from "primereact/dropdown";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { classNames } from "primereact/utils";

//

import { deleteDistrictById, getAllDistricts } from "../../../../../../services/districts/districts-service";
import { deleteCountyById, getAllCounty } from "../../../../../../services/county/county-service";
import { deleteSubcountyById, getAllSubcounty } from "../../../../../../services/subcounty/subcounty-service";
import { deleteParishById, getAllParish } from "../../../../../../services/parish/parish-service";
import { deleteVillageById, getAllVillage } from "../../../../../../services/village/village-service";
import { deleteDepartmentById, getAllDepartments, getDepartmentByProgramId } from "../../../../../../services/departments/department-service";
import {
    getAllDepartmentAdministrations,
    getDepartmentAdministrationById,
    postDepartmentAdministration,
    filterToGetDepartmentAdministrationDistrictsForMap,
    updateDepartmentAdministration,
    deleteDepartmentAdministrationById,
} from "../../../../../../services/departments/department-administrations-service";
import { Toast as PrimeReactToast } from "primereact/toast";
import { getAllFinancialYears, getFinancialYearsById, postFinancialYears, updateFinancialYears, deleteFinancialYears } from "../../../../../../services/financial-years/financial-years-service";
import { getAllQuarters, getQuartersById, postQuarters, updateQuarters, deleteQuarters } from "../../../../../../services/financial-years/quarters";

import { deleteSubProjectById, getAllSubProjects, getAllSubProjectsUnderUserProjectsByProjectIdOrRole } from "../../../../../../services/sub-projects/sub-project-service";
import { deleteProjectById, getAllProjects, filterProjectToGetItsDistricts, getProjectsByProjectIdOrRole, getProjectsByDepartmentIdAndRole } from "../../../../../../services/projects/project-service";
import { getAllDepartmentUtilities, getDepartmentUtilitieById, postDepartmentUtilities, updateDepartmentUtilities, deleteDepartmentUtilitieById } from "../../../../../../services/departments/utility-dept/department-utilities-service.js";
import { getAllUtilityTypes, getUtilityTypesById, postUtilityTypes, updateUtilityTypes, deleteUtilityTypesById } from "../../../../../../services/settings/utility-types-service.jsx";

function RowForm({ loggedInUserData, setFilterParams, filterSelectedObjects, setFilterSelectedObjects, handleSubmit, initialData, ...props }) {
    console.log("filterParams hhhh initialData : ", initialData);
    const toastRef = useRef(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(initialData);
    const queryClient = useQueryClient();

    const [selectedDepartment, setSelectedDepartment] = useState(filterSelectedObjects?.selectedDepartment);

    const validate = (values) => {
        const errors = {};

        // if (!values.report_type) {
        //     errors.report_type = "Report Type is required.";
        // }

        // Check if the report type includes 'department_reports', 'project_reports', or 'subproject_reports'
        if (["department_reports", "project_reports", "subproject_reports", "utility_reports"].includes(values.report_type)) {
            if (!values.department_id) {
                errors.department_id = "Department is required.";
            }
        }

        if (["utility_reports"].includes(values.report_type)) {
            if (!values.utility_id) {
                errors.utility_id = "A Utility is required.";
            }

            if (!values.utility_type_id) {
                errors.utility_type_id = "A Utility Type is required.";
            }
        }

        // Check if the report type includes 'project_reports' or 'subproject_reports'
        if (["project_reports", "subproject_reports"].includes(values.report_type)) {
            if (!values.department_administration_id) {
                errors.department_administration_id = "Department Administration is required.";
            }

            if (!values.project_id) {
                errors.project_id = "Project is required.";
            }
        }

        // Check if the report type is 'subproject_reports'
        if (values.report_type === "subproject_reports") {
            if (!values.subproject_id) {
                errors.subproject_id = "Subproject is required.";
            }
        }

        // Check for  fyear_id, and quarter_id

        if (!values.fyear_id) {
            errors.fyear_id = "Financial Year is required.";
        }
        if (!values.quarter_id) {
            errors.quarter_id = "Quarter is required.";
        }

        // Perform any other validation checks that you might need

        return errors;
    };

    // const onSubmitForm = (data) => {
    //     const errors = validate(data);
    //     if (Object.keys(errors).length === 0) {
    //         // No validation errors
    //         setPendingData(data);
    //         setShowConfirmDialog(true);
    //     } else {
    //         toast.warning("First Fill In All Required Fields");
    //     }
    // };

    const onSubmitForm = (data, form) => {
        // Add 'form' as an argument
        console.log("data for submission report filters : ", data);
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            let finalData = { ...data, department_code: selectedDepartment?.code };
            setPendingData(finalData);
            setShowConfirmDialog(true);
        } else {
            // Mark all fields as touched to show validation errors
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("First fill in all required fields.");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            let dataObjects = {
                selectedDepartment,
                selectedDepartmentAdministration,
                selectedReportType,
                selectedFinancialYear,
                selectedQuarter,
                selectedProject,
                selectedSubproject,
                selectedUtility,
                selectedUtilityType,
            };
            setFilterSelectedObjects(dataObjects);
            // setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    // Add reset function
    const resetForm = (form) => {
        setFilterSelectedObjects(null);
        setFilterParams({});
        setPendingData(null);

        setSelectedReportType(null);
        setSelectedDepartment(null);
        setSelectedDepartmentAdministration(null);
        setSelectedProject(null);
        setSelectedSubproject(null);
        setSelectedFinancialYear(null);
        setSelectedQuarter(null);
        setSelectedUtility(null);
        setSelectedUtilityType(null);

        // Reset the form values
        // form.restart();
        form.change("report_type", undefined);
        form.change("department_id", undefined);
        form.change("department_administration_id", undefined);
        form.change("project_id", undefined);
        form.change("subproject_id", undefined);
        form.change("fyear_id", undefined);
        form.change("quarter_id", undefined);
        form.change("utility_id", undefined);
        form.change("utility_type_id", undefined);
    };

    //================== report type ====================
    const reportTypeOptions = [
        { label: "Department Report", value: "department_reports" },
        { label: "Project Report", value: "project_reports" },
        { label: "Subproject Report", value: "subproject_reports" },
        { label: "Utility Report", value: "utility_reports" },
    ];
    const [selectedReportType, setSelectedReportType] = useState(filterSelectedObjects?.selectedReportType);
    const [filteredReportTypes, setFilteredReportTypes] = useState([]);

    //==================== Departments ============

    const [filteredDepartment, setFilteredDepartment] = useState();

    console.log("The selected department is jhhjhhj : ", selectedDepartment);

    // output_id;
    //loggedInUserData
    console.log("selectedReportType is : ", selectedReportType);
    const getListOfDepartments = useQuery(
        ["departments", loggedInUserData?.user_directorate_id, selectedReportType?.value],
        () => (!loggedInUserData?.user_directorate_id ? getAllDepartments() : getAllDepartments(selectedReportType?.value == "utility_reports" ? { directorate_id: loggedInUserData?.user_directorate_id, department_code: "03" } : { directorate_id: loggedInUserData?.user_directorate_id })),
        {
            enabled: !!loggedInUserData?.user_directorate_id,
            onSuccess: (data) => {
                if (!data || data.length === 0 || data.data.data.length === 0) {
                    toastRef.current.show({ severity: "info", summary: "Info", detail: "Seems the there are no departments yet please contact admin", life: 3000 });
                }
            },
            onError: (error) => {
                console.log("Error fetching Years : ", error);
                error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
            },
        }
    );

    console.log("getListOfDepartments xxxx : ", getListOfDepartments);

    //==================== Departments Administration ============

    const [selectedDepartmentAdministration, setSelectedDepartmentAdministration] = useState(filterSelectedObjects?.selectedDepartmentAdministration);
    const [filteredDepartmentAdministration, setFilteredDepartmentAdministration] = useState();
    //loggedInUserData
    const getListOfDepartmentAdministrations = useQuery(["department-administration", "departments", selectedDepartment?.id], () => (!selectedDepartment?.id ? getAllDepartmentAdministrations() : getAllDepartmentAdministrations({ department_id: selectedDepartment?.id })), {
        enabled: !!(selectedDepartment?.id && selectedDepartment?.code !== "03"), // Fixed the missing quotation mark here
        onSuccess: (data) => {
            if (!data || data.length === 0 || data.data.data.length === 0) {
                toastRef.current.show({ severity: "info", summary: "Info", detail: "Seems there are no department administrations yet, please contact admin", life: 3000 });
            }
        },
        onError: (error) => {
            console.log("Error fetching getListOfDepartmentAdministrations: ", error);
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("An Error Occurred Please Contact Admin");
        },
    });

    console.log("getListOfDepartmentAdministrations xxxx : ", getListOfDepartmentAdministrations);

    // ======================  utility type ==============================
    const [selectedUtilityType, setSelectedUtilityType] = useState(filterSelectedObjects?.selectedUtility);
    const [filteredUtilityType, setFilteredUtilityType] = useState();
    const fetchAllDepartmentUtilityTypes = useQuery(["utility-types"], () => getAllUtilityTypes(), {
        enabled: selectedDepartment?.code == "03",
        onSuccess: (data) => {
            console.log("success department fetching utility-types is : ", data);
        },
        onError: (error) => {
            console.log("Error fetching utility-types is : ", error);
        },
    });

    // ====================== department utility ==============================
    const [selectedUtility, setSelectedUtility] = useState(filterSelectedObjects?.selectedUtility);
    const [filteredUtility, setFilteredUtility] = useState();
    const fetchAllDepartmentUtilities = useQuery(["utilities", selectedDepartment?.id, selectedUtilityType?.id], () => (!selectedDepartment?.id ? getAllDepartmentUtilities() : getAllDepartmentUtilities({ department_id: selectedDepartment?.id, utility_type_id: selectedUtilityType?.id })), {
        enabled: selectedDepartment?.code == "03" && !!selectedUtilityType?.id,
        onSuccess: (data) => {
            console.log("success department fetching utilities is : ", data);
        },
        onError: (error) => {
            console.log("Error fetching utilities is : ", error);
        },
    });

    // district_id'
    // county_id
    // sub_county_id
    // parish_id

    //
    // ================== get finacial years =================
    const [selectedFinancialYear, setSelectedFinancialYear] = useState(filterSelectedObjects?.selectedFinancialYear);
    const [filteredFinancialYears, setFilteredFinancialYears] = useState([]);

    const [selectedQuarter, setSelectedQuarter] = useState(filterSelectedObjects?.setSelectedQuarter);
    const [filteredQuarters, setFilteredQuarters] = useState([]);

    const getAllFinancialYearsQuery = useQuery(["financialYear"], getAllFinancialYears, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching getAllFinancialYears is : ", error);
        },
    });

    // ================= quarters ================

    const getAllQuartersQuery = useQuery(["quarters"], getAllQuarters, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching quarters is : ", error);
        },
    });

    //================= projects ===============================
    const [selectedProject, setSelectedProject] = useState(filterSelectedObjects?.selectedProject);
    const [filteredProjects, setFilteredProjects] = useState();

    const getAllProjectsQuery = useQuery(["projects", selectedDepartmentAdministration?.id], () => (!selectedDepartmentAdministration?.id ? getAllProjects() : getAllProjects({ department_administrations_id: selectedDepartmentAdministration?.id })), {
        enabled: !!selectedDepartmentAdministration?.id,
        onSuccess: (data) => {
            console.log("getAllProjectsQuery fetching projects : ", data);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
            console.log("get list of getAllProjectsQuery : ", error);
        },
    });

    // ========================== fetch all sub projects =======================
    const [selectedSubproject, setSelectedSubproject] = useState(filterSelectedObjects?.selectedSubproject);
    const [filteredSubprojects, setFilteredSubprojects] = useState([]);

    const fetchAllSubProjects = useQuery(["subprojects", selectedProject?.id], () => getAllSubProjects({ project_id: selectedProject?.id }), {
        enabled: !!selectedProject?.id, // Only run the query if a project is selected
        onSuccess: (data) => {
            console.log("getAllProjects onsuccess fetching projects dsfsd : ", data);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
            console.log("get list of projects : ", error);
        },
    });

    console.log("selectedProject : ", selectedProject);

    console.log("ggggsgh initialData : ", initialData);

    return (
        <div className="col-12 md:col-12">
            <PrimeReactToast ref={toastRef} />
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    mutators={{ setFieldTouched }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                console.log("values hhh : ", values);
                                console.log("event fffff : ", event);
                                onSubmitForm(values, form);
                                // handleSubmit(event, values);
                            }}
                        >
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                                <Field name="report_type">
                                    {({ input, meta }) => (
                                        <div className="m-2" style={{ maxWidth: "300px" }}>
                                            <label htmlFor="report_type">Report Type</label>
                                            <AutoComplete
                                                value={selectedReportType?.label || ""}
                                                key={selectedDepartment?.name || "default-key"}
                                                suggestions={filteredReportTypes}
                                                completeMethod={(e) => {
                                                    const results = reportTypeOptions.filter((type) => {
                                                        return type.label.toLowerCase().includes(e.query.toLowerCase());
                                                    });
                                                    setFilteredReportTypes(results);
                                                }}
                                                field="label"
                                                dropdown={true}
                                                onChange={(e) => {
                                                    setSelectedReportType(e.value);
                                                    input.onChange(e.value ? e.value.value : null);

                                                    //
                                                    // setFilterSelectedObjects(null);
                                                    // setFilterParams(null);
                                                    setPendingData(null);

                                                    // Reset other selected fields and states
                                                    setSelectedDepartment(null);
                                                    setSelectedDepartmentAdministration(null);
                                                    setSelectedProject(null);
                                                    setSelectedSubproject(null);
                                                    setSelectedFinancialYear(null);
                                                    setSelectedQuarter(null);
                                                    setSelectedUtility(null);
                                                    setSelectedUtilityType(null);

                                                    // // Conditional form changes
                                                    if (form) {
                                                        form.change("department_id", undefined);
                                                        form.change("utility_id", undefined);
                                                        form.change("utility_type_id", undefined);
                                                        form.change("department_administration_id", undefined);
                                                        form.change("project_id", undefined);
                                                        form.change("subproject_id", undefined);
                                                        form.change("fyear_id", undefined);
                                                        form.change("quarter_id", undefined);
                                                    }
                                                }}
                                                id="report_type"
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>

                                {["department_reports", "project_reports", "subproject_reports", "utility_reports"].includes(selectedReportType?.value) && (
                                    <Field name="department_id">
                                        {({ input, meta }) => (
                                            <div className="m-2" style={{ maxWidth: "300px" }}>
                                                <label htmlFor="department_id">Department</label>
                                                <AutoComplete
                                                    value={selectedDepartment?.name || ""}
                                                    key={selectedDepartment?.name || "default-key"}
                                                    suggestions={filteredDepartment}
                                                    disabled={getListOfDepartments.isLoading}
                                                    completeMethod={(e) => {
                                                        const results = getListOfDepartments.data?.data?.data.filter((department) => {
                                                            return department.name.toLowerCase().includes(e.query.toLowerCase());
                                                        });
                                                        setFilteredDepartment(results);
                                                    }}
                                                    field="name"
                                                    dropdown={true}
                                                    onChange={(e) => {
                                                        console.log("let me check : ", e.value);
                                                        if (typeof e.value === "string") {
                                                            // Update the display value to the typed string and reset the selected department
                                                            setSelectedDepartment({ name: e.value });
                                                            input.onChange("");
                                                        } else if (typeof e.value === "object" && e.value !== null) {
                                                            // Update the selected department and set the form state with the selected department's ID
                                                            setSelectedDepartment(e.value);
                                                            input.onChange(e.value.id);
                                                            // Clear the values of the child locations
                                                            setPendingData(null);

                                                            // Reset other selected fields and states

                                                            setSelectedDepartmentAdministration(null);
                                                            setSelectedProject(null);
                                                            setSelectedSubproject(null);
                                                            // setSelectedFinancialYear(null);
                                                            // setSelectedQuarter(null);
                                                            setSelectedUtility(null);
                                                            setSelectedUtilityType(null);

                                                            // // Conditional form changes
                                                            if (form) {
                                                                form.change("utility_id", undefined);
                                                                form.change("utility_type_id", undefined);
                                                                form.change("department_administration_id", undefined);
                                                                form.change("project_id", undefined);
                                                                form.change("subproject_id", undefined);
                                                                // form.change("fyear_id", undefined);
                                                                // form.change("quarter_id", undefined);
                                                            }
                                                        }
                                                    }}
                                                    id="department_id"
                                                    selectedItemTemplate={(value) => <div>{value ? value.value : "Select a Department"}</div>}
                                                />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                {getListOfDepartments.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            </div>
                                        )}
                                    </Field>
                                )}

                                {["utility_reports"].includes(selectedReportType?.value) && selectedDepartment?.code == "03" && (
                                    <Field name="utility_type_id">
                                        {({ input, meta }) => (
                                            <div className="m-2" style={{ maxWidth: "300px" }}>
                                                <label htmlFor="utility_type_id">Utility Type</label>
                                                <AutoComplete
                                                    value={selectedUtilityType?.name || ""}
                                                    key={selectedUtilityType?.name || "department-utility-default-key"}
                                                    suggestions={filteredUtilityType}
                                                    disabled={fetchAllDepartmentUtilityTypes.isLoading}
                                                    completeMethod={(e) => {
                                                        const results = fetchAllDepartmentUtilityTypes.data?.data?.data?.filter((department) => {
                                                            return department.name.toLowerCase().includes(e.query.toLowerCase());
                                                        });
                                                        setFilteredUtilityType(results);
                                                    }}
                                                    field="name"
                                                    dropdown={true}
                                                    onChange={(e) => {
                                                        if (typeof e.value === "string") {
                                                            // Update the display value to the typed string and reset the selected department
                                                            setSelectedUtilityType({ name: e.value });
                                                            input.onChange("");
                                                        } else if (typeof e.value === "object" && e.value !== null) {
                                                            // Update the selected department and set the form state with the selected department's ID
                                                            setSelectedUtilityType(e.value);
                                                            input.onChange(e.value.id);
                                                            // Clear the values of the child locations
                                                            // Reset the child fields
                                                            setSelectedDepartmentAdministration(null);
                                                            setSelectedProject(null);
                                                            setSelectedSubproject(null);
                                                            // setSelectedFinancialYear(null);
                                                            // setSelectedQuarter(null);
                                                            setSelectedUtility(null);

                                                            // // Conditional form changes
                                                            if (form) {
                                                                form.change("utility_id", undefined);

                                                                form.change("department_administration_id", undefined);
                                                                form.change("project_id", undefined);
                                                                form.change("subproject_id", undefined);
                                                                // form.change("fyear_id", undefined);
                                                                // form.change("quarter_id", undefined);
                                                            }
                                                        }
                                                    }}
                                                    id="utility_type_id"
                                                    selectedItemTemplate={(value) => <div>{value ? value.value : "Select Utility Type"}</div>}
                                                />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                {fetchAllDepartmentUtilityTypes.isLoading && fetchAllDepartmentUtilityTypes.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            </div>
                                        )}
                                    </Field>
                                )}

                                {["utility_reports"].includes(selectedReportType?.value) && selectedDepartment?.code == "03" && (
                                    <Field name="utility_id">
                                        {({ input, meta }) => (
                                            <div className="m-2" style={{ maxWidth: "300px" }}>
                                                <label htmlFor="utility_id">Utility</label>
                                                <AutoComplete
                                                    value={selectedUtility?.name || ""}
                                                    key={selectedUtility?.name || "department-utility-default-key"}
                                                    suggestions={filteredUtility}
                                                    disabled={fetchAllDepartmentUtilities.isLoading}
                                                    completeMethod={(e) => {
                                                        const results = fetchAllDepartmentUtilities.data?.data?.data?.filter((department) => {
                                                            return department.name.toLowerCase().includes(e.query.toLowerCase());
                                                        });
                                                        setFilteredUtility(results);
                                                    }}
                                                    field="name"
                                                    dropdown={true}
                                                    onChange={(e) => {
                                                        if (typeof e.value === "string") {
                                                            // Update the display value to the typed string and reset the selected department
                                                            setSelectedUtility({ name: e.value });
                                                            input.onChange("");
                                                        } else if (typeof e.value === "object" && e.value !== null) {
                                                            // Update the selected department and set the form state with the selected department's ID
                                                            setSelectedUtility(e.value);
                                                            input.onChange(e.value.id);
                                                            // Clear the values of the child locations
                                                            // Reset the child fields
                                                            setSelectedDepartmentAdministration(null);
                                                            setSelectedProject(null);
                                                            setSelectedSubproject(null);
                                                            // setSelectedFinancialYear(null);
                                                            // setSelectedQuarter(null);

                                                            // // Conditional form changes
                                                            if (form) {
                                                                form.change("department_administration_id", undefined);
                                                                form.change("project_id", undefined);
                                                                form.change("subproject_id", undefined);
                                                                // form.change("fyear_id", undefined);
                                                                // form.change("quarter_id", undefined);
                                                            }
                                                        }
                                                    }}
                                                    id="utility_id"
                                                    selectedItemTemplate={(value) => <div>{value ? value.value : "Select a Utility"}</div>}
                                                />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                {fetchAllDepartmentUtilities.isLoading && fetchAllDepartmentUtilities.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            </div>
                                        )}
                                    </Field>
                                )}
                                {["project_reports", "subproject_reports"].includes(selectedReportType?.value) && selectedDepartment?.code !== "03" && (
                                    <Field name="department_administration_id">
                                        {({ input, meta }) => (
                                            <div className="m-2" style={{ maxWidth: "300px" }}>
                                                <label htmlFor="department_administration_id">Department Administration</label>
                                                <AutoComplete
                                                    value={selectedDepartmentAdministration?.name || ""}
                                                    key={selectedDepartmentAdministration?.name || "department-admin-default-key"}
                                                    suggestions={filteredDepartmentAdministration}
                                                    disabled={getListOfDepartmentAdministrations.isLoading}
                                                    completeMethod={(e) => {
                                                        const results = getListOfDepartmentAdministrations.data?.data?.data.filter((department) => {
                                                            return department.name.toLowerCase().includes(e.query.toLowerCase());
                                                        });
                                                        setFilteredDepartmentAdministration(results);
                                                    }}
                                                    field="name"
                                                    dropdown={true}
                                                    onChange={(e) => {
                                                        if (typeof e.value === "string") {
                                                            // Update the display value to the typed string and reset the selected department
                                                            setSelectedDepartmentAdministration({ name: e.value });
                                                            input.onChange("");
                                                        } else if (typeof e.value === "object" && e.value !== null) {
                                                            // Update the selected department and set the form state with the selected department's ID
                                                            setSelectedDepartmentAdministration(e.value);
                                                            input.onChange(e.value.id);
                                                            // Clear the values of the child locations
                                                            // Reset the child fields

                                                            setSelectedProject(null);
                                                            setSelectedSubproject(null);
                                                            setSelectedFinancialYear(null);
                                                            setSelectedQuarter(null);

                                                            // // Conditional form changes
                                                            if (form) {
                                                                form.change("project_id", undefined);
                                                                form.change("subproject_id", undefined);
                                                                form.change("fyear_id", undefined);
                                                                form.change("quarter_id", undefined);
                                                            }
                                                        }
                                                    }}
                                                    id="department_administration_id"
                                                    selectedItemTemplate={(value) => <div>{value ? value.value : "Select a Department Administration"}</div>}
                                                />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                {getListOfDepartmentAdministrations.isLoading && getListOfDepartmentAdministrations.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            </div>
                                        )}
                                    </Field>
                                )}

                                {["project_reports", "subproject_reports"].includes(selectedReportType?.value) && selectedDepartment?.code !== "03" && (
                                    <Field name="project_id">
                                        {({ input, meta }) => (
                                            <div className="m-2" style={{ maxWidth: "300px" }}>
                                                <label htmlFor="project_id">Projects</label>
                                                <AutoComplete
                                                    value={selectedProject?.project_title || ""}
                                                    key={selectedProject?.project_title || "project-default-key"}
                                                    suggestions={filteredProjects}
                                                    disabled={getAllProjectsQuery.isLoading}
                                                    completeMethod={(e) => {
                                                        const results = getAllProjectsQuery.data?.data?.data.filter((project) => {
                                                            return project.project_title.toLowerCase().includes(e.query.toLowerCase());
                                                        });
                                                        setFilteredProjects(results);
                                                    }}
                                                    field="project_title"
                                                    dropdown={true}
                                                    onChange={(e) => {
                                                        if (typeof e.value === "string") {
                                                            // Update the display value to the typed string and reset the selected department
                                                            setSelectedProject({ project_title: e.value });
                                                            input.onChange("");
                                                        } else if (typeof e.value === "object" && e.value !== null) {
                                                            // Update the selected department and set the form state with the selected department's ID
                                                            setSelectedProject(e.value);
                                                            input.onChange(e.value.id);

                                                            // Add this line to reset subproject_id in the form state

                                                            setSelectedSubproject(null);
                                                            // setSelectedFinancialYear(null);
                                                            // setSelectedQuarter(null);

                                                            // // Conditional form changes
                                                            if (form) {
                                                                form.change("subproject_id", undefined);
                                                                // form.change("fyear_id", undefined);
                                                                // form.change("quarter_id", undefined);
                                                            }
                                                        }
                                                    }}
                                                    id="project"
                                                    selectedItemTemplate={(value) => <div>{value ? value.project_title : "Select a Project"}</div>}
                                                />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                {getAllProjectsQuery.isLoading && getAllProjectsQuery.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            </div>
                                        )}
                                    </Field>
                                )}

                                {/* {["subproject_reports"].includes(selectedReportType?.value) && (
                                    <div style={{ marginBottom: "1rem" }}>
                                        <Field name="subproject_id">
                                            {({ input, meta }) => (
                                                <div className="m-2" style={{ maxWidth: "300px" }}>
                                                    <label>Sub Project</label>
                                                    <Dropdown
                                                        value={input.value}
                                                        options={fetchAllSubProjects?.data?.data?.data.map((item) => ({
                                                            label: item.name,
                                                            value: item.id,
                                                        }))}
                                                        onChange={(e) => input.onChange(e.value)}
                                                        placeholder="Select Sub Project"
                                                        className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                        disabled={fetchAllSubProjects.isLoading}
                                                    />
                                                    {fetchAllSubProjects.isLoading && fetchAllSubProjects.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                    {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                )} */}

                                {["subproject_reports"].includes(selectedReportType?.value) && selectedDepartment?.code !== "03" && (
                                    <div style={{ marginBottom: "1rem" }}>
                                        <Field name="subproject_id">
                                            {({ input, meta }) => (
                                                <div className="m-2" style={{ maxWidth: "300px" }}>
                                                    <label htmlFor="subproject_id">Sub Projects</label>
                                                    <AutoComplete
                                                        value={selectedSubproject?.name || ""}
                                                        key={selectedSubproject?.name || "subproject-default-key"}
                                                        suggestions={filteredSubprojects}
                                                        disabled={fetchAllSubProjects.isLoading}
                                                        completeMethod={(e) => {
                                                            const results = fetchAllSubProjects.data?.data?.data.filter((subproject) => {
                                                                return subproject.name.toLowerCase().includes(e.query.toLowerCase());
                                                            });
                                                            setFilteredSubprojects(results);
                                                        }}
                                                        field="name"
                                                        dropdown={true}
                                                        onChange={(e) => {
                                                            if (typeof e.value === "string") {
                                                                setSelectedSubproject({ name: e.value });
                                                                input.onChange("");
                                                            } else if (typeof e.value === "object" && e.value !== null) {
                                                                setSelectedSubproject(e.value);
                                                                input.onChange(e.value.id);
                                                            }
                                                        }}
                                                        id="subproject_id"
                                                        selectedItemTemplate={(value) => <div>{value ? value.name : "Select a Sub Project"}</div>}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                    {fetchAllSubProjects.isLoading && fetchAllSubProjects.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                )}

                                <Field name="fyear_id">
                                    {({ input, meta }) => (
                                        <div className="m-2" style={{ maxWidth: "300px" }}>
                                            <label htmlFor="financialYear">Financial Year</label>
                                            <AutoComplete
                                                value={selectedFinancialYear?.name || ""}
                                                key={selectedFinancialYear?.name || "finacial-year-key"}
                                                suggestions={filteredFinancialYears}
                                                completeMethod={(e) => {
                                                    const results = getAllFinancialYearsQuery?.data?.data?.data.filter((year) => {
                                                        return year.name.toLowerCase().includes(e.query.toLowerCase());
                                                    });
                                                    setFilteredFinancialYears(results);
                                                }}
                                                field="name"
                                                dropdown
                                                onChange={(e) => {
                                                    if (typeof e.value === "string") {
                                                        setSelectedFinancialYear({ name: e.value });
                                                        input.onChange("");
                                                    } else if (typeof e.value === "object" && e.value !== null) {
                                                        setSelectedFinancialYear(e.value);
                                                        input.onChange(e.value.id);
                                                    }
                                                }}
                                                id="fyear_id"
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>

                                <Field name="quarter_id">
                                    {({ input, meta }) => (
                                        <div className="m-2" style={{ maxWidth: "300px" }}>
                                            <label htmlFor="quarter">Quarter</label>
                                            <AutoComplete
                                                value={selectedQuarter?.name || ""}
                                                key={selectedQuarter?.name || "quarter-key"}
                                                suggestions={filteredQuarters}
                                                completeMethod={(e) => {
                                                    const results = getAllQuartersQuery?.data?.data?.data.filter((quarter) => {
                                                        return quarter.name.toLowerCase().includes(e.query.toLowerCase());
                                                    });
                                                    setFilteredQuarters(results);
                                                }}
                                                field="name"
                                                dropdown
                                                onChange={(e) => {
                                                    if (typeof e.value === "string") {
                                                        setSelectedQuarter({ name: e.value });
                                                        input.onChange("");
                                                    } else if (typeof e.value === "object" && e.value !== null) {
                                                        setSelectedQuarter(e.value);
                                                        input.onChange(e.value.id);
                                                    }
                                                }}
                                                id="quarter_id"
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>

                                <div className="d-grid gap-2" style={{ marginTop: "1rem", maxWidth: "300px" }}>
                                    <div className="m-2 fluid">
                                        <Button
                                            type="submit"
                                            label="Filter"
                                            //  disabled={!selectedYear}
                                            className="p-button-primary"
                                            icon="pi pi-check"
                                        />
                                    </div>
                                </div>

                                {filterSelectedObjects && (
                                    <div className="d-grid gap-2" style={{ marginTop: "1rem", maxWidth: "300px" }}>
                                        <div className="m-2">
                                            <Button type="button" label="Clear" className="p-button-secondary" icon="pi pi-times" onClick={() => resetForm(form)} />
                                        </div>{" "}
                                    </div>
                                )}
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    maximizable
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to Filter?
                </Dialog>
            </div>
        </div>
    );
}

export default RowForm;
