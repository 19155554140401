import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";

import { Dropdown } from "primereact/dropdown";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteProjectById, getAllProjects, getProjectsByProjectIdOrRole, getProjectsByDepartmentIdAndRole } from "../../../../../services/projects/project-service";
import { getAllDepartments } from "../../../../../services/departments/department-service";
import { classNames } from "primereact/utils";
import { ProgressSpinner } from "primereact/progressspinner";

function RowForm({ selectedDepartmentItem, handleSubmit, initialData, ...props }) {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);
    const queryClient = useQueryClient();

    const validate = (values) => {
        const errors = {};

        if (!values.name) errors.name = "Name is required";

        if (!values.department_id) errors.department_id = "Department is required";

        if (!values.code) errors.code = "Code is required";

        return errors;
    };

    const onSubmitForm = (data) => {
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            // No validation errors
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            toast.warning("First Fill In All Required Fields");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    //================ departments ==============
    const fetchAllDepartments = useQuery(["departments", selectedDepartmentItem?.directorate_id], () => (!selectedDepartmentItem?.directorate_id ? getAllDepartments() : getAllDepartments({ directorate_id: selectedDepartmentItem?.directorate_id })), {
        onSuccess: (data) => {
            // setDepartments(data.data);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
            console.log("get list of departments : ", error);
        },
    });

    // initialData = { department_id: props?.department_id, ...initialData };
    if (props?.departmentId) {
        initialData = { department_id: props?.departmentId, ...initialData };
    }
    return (
        <div className="col-12 md:col-12">
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                console.log("values hhh : ", values);
                                console.log("event fffff : ", event);
                                onSubmitForm(values);
                                // handleSubmit(event, values);
                            }}
                        >
                            <Field name="name">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="name">Name</label>
                                        <InputText {...input} id="name" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="code">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="code">Code</label>
                                        <InputText {...input} id="code" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <div style={{ marginBottom: "1rem" }}>
                                <Field name="department_id">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Department</label>
                                            <Dropdown
                                                value={input.value}
                                                options={fetchAllDepartments?.data?.data?.data.map((item) => ({
                                                    label: item.name,
                                                    value: item.id,
                                                }))}
                                                onChange={(e) => input.onChange(e.value)}
                                                // disabled={props?.loggedInUserData?.role !== "Admin"}
                                                disabled={fetchAllDepartments.isLoading}
                                                placeholder="Select Department"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                            {fetchAllDepartments.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <div className="d-grid gap-2">
                                <Button type="submit" label="Save" className="p-button-primary" icon="pi pi-check" />
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    style={{ width: "30vw" }}
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to submit?
                </Dialog>
            </div>
        </div>
    );
}

export default RowForm;
