import React, { useState, useEffect } from "react";
// import { Card } from "primereact/card";
import { Tree } from "primereact/tree";
import { Divider } from "primereact/divider";
import { useLocation } from "react-router-dom";
import { Panel } from "primereact/panel";
import BreadcrumbNav from "../../../components/mweComponents/BreadcrumbNav";
import { Button } from "primereact/button";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { Fieldset } from "primereact/fieldset";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { TabView, TabPanel } from "primereact/tabview";
// import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primereact/resources/themes/lara-light-blue/theme.css";
// import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import SubProjectsList from "../subprojects/SubProjectsList";
import DepartmentList from "../departments/DepartmentList";
import OutcomeIndicatorsPage from "./outcome-indicators/OutcomeIndicatorsPage.js";
// import MetricsPage from "./metrics/MetricsPage";
import OutcomesPage from "./outcomes/OutcomesPage";
import OutputIndicatorsPage from "./output-indicators/OutputIndicatorsPage.js";
import { ProgressBar } from "primereact/progressbar";

import { deleteProjectById, getAllProjects, getAllProjectsById, getProjectsByProjectIdOrRole, getProjectsByDepartmentIdAndRole } from "../../../services/projects/project-service";
import { getAllDirectorates, deleteDirectorateById, getDirectorateById } from "../../../services/departments/directorate-service";

import DashboardPage from "./dashboard/DashboardPage";

const DirectorateViewPage = ({
    loggedInUserData,
    project = {
        name: "Urban Development Project",
        code: "UD123",
        department: "Urban Planning",
        type: "Development",
        responsibleOfficer: "John Doe",
        details: "This project focuses on...",
        districts: [
            { id: "1", name: "District 1" },
            { id: "2", name: "District 2" },
        ],
    },
}) => {
    const queryClient = useQueryClient();
    //
    let { state } = useLocation();
    let directorateData = state ? state?.DirectorateData : null;
    let componentDetailFromNavLink = state?.componentDetailFromNavLink ? state?.componentDetailFromNavLink : null;

    console.log("directorateData data got from state is : ", directorateData);

    //===================== feytch projects by id =================
    const fetchDirectorateById = useQuery(
        ["directorate", "getById", directorateData?.id ?? componentDetailFromNavLink?.details?.id ?? loggedInUserData?.user_directorate_id],
        () => getDirectorateById(directorateData?.id ?? componentDetailFromNavLink?.details?.id ?? loggedInUserData?.user_directorate_id),
        {
            onSuccess: (data) => {
                console.log("getDirectorateById onsuccess fetching projects dsfvdsvd : ", data);
            },
            onError: (error) => {
                // props.onClose();
                error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
                console.log("get list of projects : ", error);
            },
        }
    );

    directorateData = directorateData ?? fetchDirectorateById?.data?.data?.data;
    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showBudjetOutPutAddForm, setShowBudjetOutPutAddForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    // ================= treeee =========================

    const programTreeNodes = fetchDirectorateById?.data?.data?.data?.programs?.map((program) => ({
        key: program.id,
        label: program.name,
    }));

    const programsTreeData = [
        {
            key: "root",
            label: "Programs",
            children: programTreeNodes,
        },
    ];

    return (
        <div style={{ width: "100%" }}>
            <BreadcrumbNav />
            {fetchDirectorateById.isLoading && fetchDirectorateById.isFetching && !directorateData && (
                <div className="m-2">
                    <ProgressBar mode="indeterminate" style={{ height: "4px" }} />
                </div>
            )}
            <div className="projects-view-page">
                <Panel header={`Directorate ${directorateData?.name} Details`} style={{ marginBottom: "20px" }}>
                    <TabView scrollable={true}>
                        <TabPanel header="Details">
                            <div className="flex flex-wrap">
                                {/* Column 1 */}
                                <div className="w-full md:w-1/2 px-2 mb-4">
                                    <p>
                                        <strong>Directorate Name:</strong> {directorateData?.name}
                                    </p>
                                </div>

                                {/* Column 2 */}
                                <div className="w-full md:w-1/2 px-2 mb-4">
                                    <p>
                                        <strong>Directorate Code:</strong> {directorateData?.code}
                                    </p>
                                    {/*
                                    <p>
                                        <strong>Updated By:</strong> {directorateData?.updated_by?.email || "N/A"}
                                    </p> */}
                                </div>

                                {/* Full Width Row for Details */}
                                <div className="w-full px-2">
                                    <strong>Details</strong>
                                    <p>{directorateData?.details}</p>
                                </div>
                            </div>

                            <Divider />
                            <div className="m-4">
                                <Fieldset legend="Programs">
                                    <Tree value={programsTreeData} />
                                </Fieldset>
                            </div>
                        </TabPanel>
                        <TabPanel header="Outcomes">
                            {" "}
                            <OutcomesPage selectedDirectorateItem={directorateData || fetchDirectorateById?.data?.data?.data} loggedInUserData={loggedInUserData} directorateId={directorateData?.id || fetchDirectorateById?.data?.data?.data?.id} />
                        </TabPanel>
                        <TabPanel header="Outcome Indicators">
                            {" "}
                            <OutcomeIndicatorsPage selectedDirectorateItem={directorateData || fetchDirectorateById?.data?.data?.data} loggedInUserData={loggedInUserData} directorateId={directorateData?.id || fetchDirectorateById?.data?.data?.data?.id} />
                            {/* <SubProjectsList loggedInUserData={loggedInUserData} projectid={projectsData?.id || fetchProjectById?.data?.data?.data?.id} /> */}
                        </TabPanel>

                        {/* Changed The naming from metrics to output indicators */}
                        <TabPanel header="Output Indicators">
                            {" "}
                            <OutputIndicatorsPage loggedInUserData={loggedInUserData} selectedDirectorateItem={directorateData || fetchDirectorateById?.data?.data?.data} directorateId={directorateData?.id || fetchDirectorateById?.data?.data?.data?.id} />
                            {/* <SubProjectsList loggedInUserData={loggedInUserData} projectid={projectsData?.id || fetchProjectById?.data?.data?.data?.id} /> */}
                        </TabPanel>
                        <TabPanel header="Departments">
                            {" "}
                            <DepartmentList loggedInUserData={loggedInUserData} selectedDirectorateItem={directorateData || fetchDirectorateById?.data?.data?.data} directorateId={directorateData?.id || fetchDirectorateById?.data?.data?.data?.id} />
                            {/* <SubProjectsList loggedInUserData={loggedInUserData} projectid={projectsData?.id || fetchProjectById?.data?.data?.data?.id} /> */}
                        </TabPanel>
                        {/* <TabPanel header="Directorate Reports"></TabPanel> */}
                    </TabView>
                </Panel>
            </div>
        </div>
    );
};

export default DirectorateViewPage;

// Sample usage:
// <ProjectsViewPage project={{
//     name: 'Urban Development Project',
//     code: 'UD123',
//     department: 'Urban Planning',
//     type: 'Development',
//     responsibleOfficer: 'John Doe',
//     details: 'This project focuses on...',
//     districts: [{ id: '1', name: 'District 1' }, { id: '2', name: 'District 2' }]
// }} />
