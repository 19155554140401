import React, { useState, useRef } from "react";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import moment from "moment";
import { Image } from "primereact/image";
import { Tag } from "primereact/tag";
import ProjectReportPrint from "./ProjectReportPrint";
import { useReactToPrint } from "react-to-print";
import { Button } from "primereact/button";
import PrinterOutlined from "@mui/icons-material/PrintOutlined";
import { ProgressBar } from "primereact/progressbar";

function SubprojectAggregationModal({ aggregatedReportDetail, ...props }) {
    const [projectReport, setProjectReport] = useState(aggregatedReportDetail.mergedReport);
    console.log("project reportbnvmdncvds : ", projectReport);

    // Pagination state for each table
    const [budgetOutputPage, setBudgetOutputPage] = useState(0);
    const [departmentOutcomePage, setDepartmentOutcomePage] = useState(0);
    const [subprojectPage, setSubprojectPage] = useState(0);

    const rowsPerPage = 5; // Number of rows per page
    // Paginator template
    const paginatorTemplate = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown";
    const currentPageReportTemplate = "Showing {first} to {last} of {totalRecords} entries";

    // Helper function to format currency
    const formatCurrency = (value) => {
        return new Intl.NumberFormat("en-US", { style: "currency", currency: "UGX" }).format(value);
    };

    // Helper function to format date
    const formatDate = (dateString) => {
        return moment(dateString).format("MMMM Do YYYY, h:mm:ss a");
    };

    const imageBodyTemplate = (rowData) => {
        return <Image src={`${process.env.REACT_APP_API_BASE_URL}${rowData.photo_url}`} alt={rowData.caption} width="100" preview />;
    };

    // Function to display status with color
    const statusWithColor = (status) => {
        let color;
        switch (status) {
            case "pending approval":
                color = "blue";
                break;
            case "approved":
                color = "green";
                break;
            case "pending":
                color = "orange";
                break;
            case "rejected":
                color = "red";
                break;
            default:
                color = "grey";
        }
        // return <span style={{ color }}>{status?.charAt(0)?.toUpperCase() + status?.slice(1)}</span>;
        return <span style={{ color }}>{status}</span>;
    };

    // Function to display status with color using Tag
    const tagStatus = (status) => {
        let severity;
        switch (status) {
            case "pending approval":
                severity = "info"; // blue
                break;
            case "approved":
                severity = "success"; // green
                break;
            case "pending":
                severity = "warning"; // orange
                break;
            case "rejected":
                severity = "danger"; // red
                break;
            default:
                severity = "secondary"; // grey or default
        }
        return <Tag severity={severity} value={status?.charAt(0)?.toUpperCase() + status?.slice(1)} />;
    };

    const gridStyle = {
        display: "grid",
        gridTemplateColumns: "1fr 1fr", // This creates two columns of equal width
        gridGap: "1rem", // Space between the columns
    };

    const riskTypeBodyTemplate = (rowData) => {
        let color;
        switch (rowData.risk_type) {
            case "high":
                color = "red";
                break;
            case "moderate":
                color = "orange";
                break;
            case "low":
                color = "grey";
                break;
            default:
                color = "black";
        }

        return (
            <div>
                <span className="p-mr-2" style={{ fontSize: "1.5rem", color, fontWeight: "bold" }}>
                    •
                </span>
                {/* {rowData.risk_type.charAt(0).toUpperCase() + rowData.risk_type.slice(1)} */}
                {rowData.risk_type}
            </div>
        );
    };

    //============ printing ================
    const componentRef = useRef();
    //     const handlePrint = useReactToPrint({
    //         content: () => componentRef.current,
    //         pageStyle: `
    //     @page { size: A4; margin: 1cm; }
    //     @media print {
    //       body { -webkit-print-color-adjust: exact; }
    //       div#print-content { height: 100%; }
    //     }
    //   `,
    //     });

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Report", // Set the document title as a string
        pageStyle: `
    @page { size: A4; margin: 1cm; }
    @media print {
      body { -webkit-print-color-adjust: exact; }
      div#print-content { height: 100%; }
      .header, .footer { background-color: transparent !important; } // Assuming .header and .footer are the classes for your header and footer
      img { display: block; margin: 0 auto; } // Center the image, if necessary
    }
  `,
    });

    return (
        <Dialog header={<div style={{ color: "green" }}>Project Report.</div>} visible={props.show} style={{ width: "80vw" }} onHide={props.onHide} maximizable>
            {/* Project Details */}
            <Card title="Project Details" style={{ marginBottom: "1rem" }}>
                <div style={gridStyle}>
                    {/* Display Program and Directorate Details */}
                    {projectReport?.project?.department_administration.department?.program_directorate_department?.map((pdd, index) => {
                        const programName = pdd?.program_directorate?.program?.name || "N/A";
                        const directorateName = pdd?.program_directorate?.directorate?.name || "N/A";
                        const uniqueKey = `pdd-${pdd?.program_directorate.program?.id}-${pdd?.program_directorate.directorate?.id}-${index}`;

                        return (
                            <p key={uniqueKey}>
                                <p>
                                    <strong>Program:</strong> {programName}
                                </p>
                                <p>
                                    <strong>Directorate:</strong> {directorateName}
                                </p>
                            </p>
                        );
                    })}
                    <p>
                        <p>
                            <strong>Department Name:</strong> {projectReport?.project?.department_administration?.department?.name || "N/A"}
                        </p>
                        <p>
                            <strong>Department Administration Name:</strong> {projectReport?.project?.department_administration?.name || "N/A"}
                        </p>
                    </p>{" "}
                    <p>
                        <strong>Project Title:</strong> {projectReport.project.project_title}
                    </p>
                    <p>
                        <strong>Project Code:</strong> {projectReport.project.project_code}
                    </p>
                    {/* <p>
                        <strong>Released Funds:</strong> {formatCurrency(projectReport?.released_funds)}
                    </p> */}
                    {/* <p>
                        <strong>Quarter:</strong> {projectReport?.quarter?.name}
                    </p> */}
                    {/* <p>
                        <strong>Approval Status:</strong> {statusWithColor(projectReport?.current_status?.name)}
                    </p> */}
                    {/* <p>
                        <strong>Approval Status:</strong> {tagStatus(projectReport?.current_status?.name)}
                    </p>
                    <p>
                        <strong>Approval Comment:</strong> {statusWithColor(projectReport?.current_status?.reason)}
                    </p>
                    <p>
                        <strong>Description:</strong> {projectReport?.description}
                    </p> */}
                </div>
                <div>
                    <p>
                        <strong>Periods:</strong> {projectReport?.financial_year?.name}
                    </p>
                </div>
            </Card>
            <Card title="Budget Details" style={{ marginBottom: "1rem" }}>
                <div style={gridStyle}>
                    <p>
                        <strong>Released Funds:</strong> {formatCurrency(projectReport?.approved_budget)}
                    </p>
                    <p>
                        <strong>Spent Funds:</strong> {formatCurrency(projectReport?.spent_funds)}
                    </p>
                    {/* <p>
                        <strong>Released Funds:</strong> {formatCurrency(projectReport?.released_funds)}
                    </p> */}
                    <p>
                        <strong>Absorption Rate:</strong> {projectReport?.absorption_rate}%
                    </p>
                </div>
            </Card>
            {/* Department Outcomes */}
            {projectReport.department_outcomes.map((outcome, index) => (
                <Card key={index} title={`Department Outcome: ${outcome.department_outcome.outcome.name}`} style={{ marginBottom: "1rem" }}>
                    <DataTable value={outcome.project_report_dept_outcome_indicator_target_actual} paginator paginatorTemplate={paginatorTemplate} currentPageReportTemplate={currentPageReportTemplate} rows={rowsPerPage} first={departmentOutcomePage} onPage={(e) => setDepartmentOutcomePage(e.first)}>
                        <Column field="department_outcome_indicator_target.department_outcome_indicator.outcome_indicator.name" header="Outcome Indicator Name"></Column>
                        {/* <Column field="department_outcome_indicator_target.department_outcome_indicator.outcome_indicator.code" header="Outcome Indicator Code"></Column> */}

                        <Column field="department_outcome_indicator_target.base_year" header="Base Year"></Column>
                        <Column
                            field="department_outcome_indicator_target.base_value"
                            header="Base Value"
                            body={(rowData, column) => {
                                // Extract the unit for consistent usage
                                let unit = rowData.department_outcome_indicator_target.department_outcome_indicator.outcome_indicator.unit;
                                return `${parseInt(rowData.department_outcome_indicator_target.base_value) || 0} ${unit}`;
                            }}
                        ></Column>
                        <Column field="department_outcome_indicator_target.target_year" header="Target Year"></Column>
                        <Column
                            field="department_outcome_indicator_target.target_value"
                            header="Target Value"
                            body={(rowData, column) => {
                                let unit = rowData.department_outcome_indicator_target.department_outcome_indicator.outcome_indicator.unit; // Adding unit from the rowData
                                return `${parseInt(rowData.department_outcome_indicator_target.target_value) || 0} ${unit}`;
                            }}
                        ></Column>
                        {/* <Column field="department_outcome_indicator_target.financial_year.name" header="Financial Year"></Column>
                        <Column
                            field="department_outcome_indicator_target.financial_year_target"
                            header="Financial Year Target"
                            body={(rowData, column) => {
                                let unit = rowData.department_outcome_indicator_target.department_outcome_indicator.outcome_indicator.unit; // Adding unit from the rowData
                                return `${parseInt(rowData.department_outcome_indicator_target.financial_year_target) || 0}${unit}`;
                            }}
                        ></Column> */}

                        <Column
                            field="value"
                            header="Value"
                            body={(rowData, column) => {
                                // Initialize a variable to store the final value
                                let finalValue;
                                let unit = rowData.department_outcome_indicator_target.department_outcome_indicator.outcome_indicator.unit; // Extract the unit for use in this column as well

                                // Check if manual value should be used
                                if (rowData?.department_outcome_indicator_target.department_outcome_indicator.outcome_indicator.show_manual_value) {
                                    // Use the manual value and store in finalValue
                                    finalValue = `${rowData?.uncomputed_value} ${unit}`;
                                } else {
                                    // Otherwise, use the target value and store in finalValue
                                    finalValue = `${rowData[column.field]} ${unit}`;
                                }

                                // Return the finalValue
                                return finalValue;
                            }}
                        ></Column>
                        {/* Additional columns as needed */}
                    </DataTable>
                </Card>
            ))}

            {/* Budget Outputs */}
            {projectReport.budget_outputs.map((output, index) => (
                <Card key={index} title={`Budget Output: ${output.project_department_budget_output.department_budget_output.name}`} style={{ marginBottom: "1rem" }}>
                    <DataTable value={output.project_report_output_indicator_actual} paginator paginatorTemplate={paginatorTemplate} currentPageReportTemplate={currentPageReportTemplate} rows={rowsPerPage} first={budgetOutputPage} onPage={(e) => setBudgetOutputPage(e.first)}>
                        {/* <Column field="project_department_budget_output_indicator_target.department_budget_output_indicator.output_indicator.code" header="Indicator Code"></Column> */}
                        <Column field="project_department_budget_output_indicator_target.department_budget_output_indicator.output_indicator.name" header="Indicator Name"></Column>
                        <Column field="project_department_budget_output_indicator_target.department_budget_output_indicator.output_indicator.unit" header="Units"></Column>

                        {/* <Column field="project_department_budget_output_indicator_target.department_budget_output_indicator.output_indicator.multiplier" header="Indicator Multiplier"></Column> */}
                        {/* <Column
                            field="project_department_budget_output_indicator_target.financial_year.name"
                            header="Financial Year"
                            // headerStyle={{ backgroundColor: "orange", color: "white" }}
                            // style={{ whiteSpace: "nowrap" }} // Add this line
                        ></Column>
                        <Column field="project_department_budget_output_indicator_target.quarter.name" header="Quarter"></Column> */}
                        <Column field="project_department_budget_output_indicator_target.target" header="Target"></Column>

                        <Column
                            field="value"
                            header="Value"
                            body={(rowData) => {
                                // Assuming 'value' is directly under 'rowData'
                                return parseInt(rowData.value).toString();
                                // If you want to round to the nearest whole number instead, use Math.round(rowData.value)
                            }}
                        />
                        {/* <Column field="assessment" header="Assessment"></Column>
                        <Column field="reasonOfVariance" header="Reason of Variance"></Column> */}
                        {/* Other columns as necessary */}
                    </DataTable>
                </Card>
            ))}
            {/* Subprojects */}
            <Card title="Subprojects" style={{ marginBottom: "1rem" }}>
                <DataTable value={projectReport.subproject_field_data} paginator paginatorTemplate={paginatorTemplate} currentPageReportTemplate={currentPageReportTemplate} rows={rowsPerPage} first={subprojectPage} onPage={(e) => setSubprojectPage(e.first)}>
                    <Column field="subproject_field_data.subproject.name" header="Subproject Name"></Column>
                    <Column field="subproject_field_data.subproject.code" header="Subproject Code"></Column>
                    <Column field="subproject_field_data.subproject.start_date" header="Start Date" body={(rowData) => formatDate(rowData.subproject_field_data.subproject.start_date)} />
                    <Column field="subproject_field_data.subproject.proposed_end_date" header="Proposed End Date" body={(rowData) => formatDate(rowData.subproject_field_data.subproject.proposed_end_date)} />
                    <Column field="subproject_field_data.subproject.completion_status" header="Completion Status"></Column>
                    <Column
                        field="subproject_field_data.subproject.completion"
                        header="Completion"
                        body={(rowData) => (
                            <div>
                                {rowData.subproject_field_data.subproject.completion}% <ProgressBar value={rowData.subproject_field_data.subproject.completion} showValue={true} />
                            </div>
                        )}
                    />
                    <Column
                        field="subproject_field_data.approved_budget"
                        header="Approved Budget"
                        body={(rowData) => {
                            // console.log("tesbjbc dshvjsdv : ", rowData);
                            return formatCurrency(rowData.subproject_field_data.approved_budget);
                        }}
                    />
                    <Column field="subproject_field_data.spent_funds" header="Spent Funds" body={(rowData) => formatCurrency(rowData.subproject_field_data.spent_funds)} />
                    <Column field="subproject_field_data.absorption_rate" header="Arbsorption Rate" body={(rowData) => <div>{rowData.subproject_field_data.absorption_rate}%</div>}></Column>
                    {/* Other columns as necessary */}
                </DataTable>
            </Card>
            {projectReport?.risks_mitigation && (
                <Card title="Risks and Mitigations" style={{ marginBottom: "1rem" }}>
                    <DataTable value={projectReport.risks_mitigation}>
                        <Column field="risk" header="Risk"></Column>
                        <Column field="risk_type" header="Risk Type" body={riskTypeBodyTemplate}></Column>
                        <Column field="mitigation" header="Mitigation"></Column>
                        {/* <Column field="created_at" header="Created At" body={formatDate}></Column> */}
                    </DataTable>
                </Card>
            )}
            {projectReport?.challenge_recommendations && (
                <Card title="Challenges and Recommendations" style={{ marginBottom: "1rem" }}>
                    <DataTable value={projectReport.challenge_recommendations}>
                        <Column field="challenges" header="Challenges"></Column>
                        <Column field="recommendations" header="Recommendations"></Column>
                        {/* <Column field="created_at" header="Created At" body={formatDate}></Column> */}
                    </DataTable>
                </Card>
            )}
            {/* Display Photos */}
            <Card title="Photos">
                <DataTable value={projectReport?.photos}>
                    <Column header="Image" body={imageBodyTemplate}></Column>
                    <Column field="caption" header="Caption"></Column>
                </DataTable>
            </Card>
            {/* Created By and Updated By Information */}
            {/* <p>
                <strong>Created By:</strong> {projectReport?.created_by_user?.name} at {formatDate(projectReport?.created_at)}
            </p> */}
            {/* <p>
                <strong>Updated By:</strong> {projectReport?.updated_by_user?.name} at {formatDate(projectReport?.updated_at)}
            </p> */}
            {/* Print Button */}
            <Button label="Print " icon={<PrinterOutlined />} onClick={handlePrint} className="p-button-primary" />
            {/* Invisible component for printing */}
            <div style={{ display: "none" }}>
                <div ref={componentRef}>
                    <ProjectReportPrint projectReport={projectReport} />
                </div>
            </div>
        </Dialog>
    );
}

export default SubprojectAggregationModal;
