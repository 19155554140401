import React from "react";
import { Card } from "primereact/card";
import CountUp from "react-countup";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Lottie from "lottie-react";
//
import Sanitation from "../../../assets/sanitationbubble.json";
import WaterAccess from "../../../assets/wateraccess.json";
import WaterCoverage from "../../../assets/watercoverage.json";
import DrinkingWater from "../../../assets/drinkingwater.json";
import PipedWater from "../../../assets/pipedwater.json";
import Villages from "../../../assets/villages.json";

const StatisticsDisplay = ({ data = [] }) => {
    console.log("databdnsbds : ", data);
    const prepareData = (data) => {
        const preparedData = [];

        data.forEach((deptReport) => {
            deptReport.dept_report_department_outcomes.forEach((deptOutcome) => {
                deptOutcome.dept_report_dept_outcome_indicator_target_actuals.forEach((actual) => {
                    let show_manual_value = actual.dept_outcomes_indicator_target.show_manual_value;

                    preparedData.push({
                        name: actual.dept_outcomes_indicator_target.department_outcome_indicator.outcome_indicator.numerator,
                        value: show_manual_value ? parseInt(actual?.uncomputed_numerator, 10) : parseInt(actual?.numerator_value, 10),
                    });
                });
            });
        });

        return preparedData;
    };

    const cardColors = [
        "#FF6384",
        "#36A2EB",
        "#FFCE56",
        "#4BC0C0",
        "#9966FF", // Existing colors
        "#FF9F40",
        "#FFCD56",
        "#4D5360",
        "#23CBAF",
        "#C0392B", // New colors
        "#8E44AD",
        "#3498DB",
        "#1ABC9C",
        "#2ECC71",
        "#F1C40F", // More colors
        "#E74C3C",
        "#34495E",
        "#9B59B6",
        "#16A085",
        "#27AE60", // Additional colors
    ];

    const lottieAnimations = [Sanitation, WaterAccess, WaterCoverage, DrinkingWater, PipedWater, Villages];

    const getRandomColor = () => {
        const randomIndex = Math.floor(Math.random() * cardColors.length);
        return cardColors[randomIndex];
    };

    const getRandomLottie = () => {
        const randomIndex = Math.floor(Math.random() * lottieAnimations.length);
        return lottieAnimations[randomIndex];
    };

    const getTextColor = (backgroundColor) => {
        const color = backgroundColor.substring(1); // Remove '#'
        const rgb = parseInt(color, 16); // Convert hex to integer
        const r = (rgb >> 16) & 0xff; // Extract red
        const g = (rgb >> 8) & 0xff; // Extract green
        const b = (rgb >> 0) & 0xff; // Extract blue

        const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // Calculate luma
        return luma < 140 ? "white" : "black"; // Return text color based on brightness
    };

    const displayData = prepareData(data);

    return (
        <div className="col-12 xl:col-12" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap", gap: "1rem" }}>
            {displayData.map((item, index) => {
                const bgColor = getRandomColor();
                const textColor = getTextColor(bgColor);

                return (
                    <Card
                        key={index}
                        style={{
                            textAlign: "center",
                            boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                            backgroundColor: bgColor,
                            width: "300px",
                            color: textColor, // Set text color based on background
                        }}
                    >
                        <strong style={{ wordWrap: "break-word" }}>{item.name}</strong>
                        <Lottie animationData={getRandomLottie()} loop={true} autoplay={true} style={{ width: "80px", height: "80px", margin: "auto" }} />
                        <div style={{ fontSize: "2em", fontWeight: "bold" }}>
                            <CountUp end={item.value} duration={2.75} />
                        </div>
                    </Card>
                );
            })}
        </div>
    );
};
export default StatisticsDisplay;
