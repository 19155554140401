import React, { useState, useEffect } from "react";
// import { Card } from "primereact/card";
import { Tree } from "primereact/tree";
import { Divider } from "primereact/divider";
import { useLocation } from "react-router-dom";
import { Panel } from "primereact/panel";
import BreadcrumbNav from "../../../../../components/mweComponents/BreadcrumbNav";
import { Button } from "primereact/button";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { Fieldset } from "primereact/fieldset";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { TabView, TabPanel } from "primereact/tabview";
// import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primereact/resources/themes/lara-light-blue/theme.css";
// import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

import { getAllDepartmentOutcomeIndicators, getDepartmentOutcomeIndicatorById, postDepartmentOutcomeIndicator, updateDepartmentOutcomeIndicator, deleteDepartmentOutcomeIndicatorById } from "../../../../../services/departments/departmentOutcomeIndicator-service.js";
import { getAllDepartmentBudjetOutputs, getDepartmentBudjetOutputById, postDepartmentBudjetOutput, updateDepartmentBudjetOutput, deleteDepartmentBudjetOutputById } from "../../../../../services/departments/departments-budget-outputs-service.js";

import UtilityIndicatorsPage from "./utility-indicators/UtilityIndicatorsPage";
import { getAllDepartmentUtilities, getDepartmentUtilitieById, postDepartmentUtilities, updateDepartmentUtilities, deleteDepartmentUtilitieById } from "../../../../../services/departments/utility-dept/department-utilities-service.js";
import GazettePage from "./gazette/GazettePage";
import UtilitiesReportPage from "./utility-reports/UtilitiesReportPage.js";
import UtilitiesApprovalPage from "./approvals/UtilitiesApprovalPage";

const DepartmentUtilitiesViewPage = ({ loggedInUserData }) => {
    const queryClient = useQueryClient();
    //
    let { state } = useLocation();
    let selectedUtilityData = state?.selectedUtilityData ? state?.selectedUtilityData : null;
    let componentDetailFromNavLink = state?.componentDetailFromNavLink ? state?.componentDetailFromNavLink : null;
    selectedUtilityData = !selectedUtilityData ? componentDetailFromNavLink?.details : selectedUtilityData;
    console.log("selectedUtilityData data got from state is : ", componentDetailFromNavLink);

    //===================== getDepartmentById by id =================

    const fetchDepartmentUtilitieById = useQuery(["utilities", "byId", selectedUtilityData?.id], () => getDepartmentUtilitieById(selectedUtilityData?.id), {
        onSuccess: (data) => {
            console.log("departmentUtilitieById onsuccess fetching : ", data);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("An Error Occured Please Contact Admin");
            console.log("get list of departmentUtilitieById : ", error);
        },
    });

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showBudjetOutPutAddForm, setShowBudjetOutPutAddForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    // Convert districts array to tree structure
    const districtTreeNodes = fetchDepartmentUtilitieById?.data?.data?.data?.districts?.map((district) => ({
        key: district.id,
        label: district.name,
    }));

    const districtsTreeData = [
        {
            key: "root",
            label: "Districts",
            children: districtTreeNodes,
        },
    ];

    return (
        <div style={{ width: "100%" }}>
            <BreadcrumbNav />
            <div className="projects-view-page">
                <Panel
                    header={
                        <span>
                            Department : {selectedUtilityData?.department?.name}
                            <br />
                            Utility : {selectedUtilityData?.name}
                        </span>
                    }
                    style={{ marginBottom: "20px" }}
                >
                    <TabView>
                        <TabPanel header="Details">
                            <div className="flex flex-wrap">
                                {/* Column 1 */}
                                <div className="w-full md:w-1/2 px-2 mb-4">
                                    <p>
                                        <strong>Utility Name:</strong> {selectedUtilityData?.name}
                                    </p>
                                </div>

                                {/* Column 2 */}
                                <div className="w-full md:w-1/2 px-2 mb-4">
                                    <p>
                                        <strong>Utility Code:</strong> {selectedUtilityData?.code}
                                    </p>
                                    <p>
                                        <strong>Utility Type:</strong> {selectedUtilityData?.utility_type?.name}
                                    </p>

                                    {/* <p>
                                        <strong>Updated By:</strong> {selectedUtilityData?.updated_by?.email || "N/A"}
                                    </p> */}
                                </div>
                                {/* Full Width Row for Details */}
                                <div className="w-full py-2 my-4">
                                    <strong>Details</strong>
                                    <p>{selectedUtilityData?.details || "N/A"}</p>
                                </div>
                            </div>

                            <Divider />

                            <div>
                                <div className="m-4">
                                    <Fieldset legend="Districts">
                                        <Tree value={districtsTreeData} />
                                    </Fieldset>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Indicators">
                            <UtilityIndicatorsPage
                                loggedInUserData={loggedInUserData}
                                selectedUtilityData={selectedUtilityData || fetchDepartmentUtilitieById?.data?.data?.data}
                                departmentOutcomeIndicatorId={selectedUtilityData?.id || fetchDepartmentUtilitieById?.data?.data?.data?.id}
                                // selectedItem={props?.rowData}
                            />
                        </TabPanel>
                        <TabPanel header="Gazetted Schemes">
                            <GazettePage
                                loggedInUserData={loggedInUserData}
                                selectedUtilityData={selectedUtilityData || fetchDepartmentUtilitieById?.data?.data?.data}

                                // selectedItem={props?.rowData}
                            />
                        </TabPanel>
                        {(loggedInUserData?.role?.toLowerCase() === "department commissioner" || loggedInUserData?.role?.toLowerCase() === "assistant commissioner" || loggedInUserData?.role?.toLowerCase() === "utilityManager") && loggedInUserData?.user_department?.code == "03" && (
                            <TabPanel header="Approvals">
                                <UtilitiesApprovalPage loggedInUserData={loggedInUserData} selectedUtilityData={selectedUtilityData || fetchDepartmentUtilitieById?.data?.data?.data} />
                            </TabPanel>
                        )}
                        <TabPanel header="Reports">
                            <UtilitiesReportPage
                                loggedInUserData={loggedInUserData}
                                selectedUtilityData={selectedUtilityData || fetchDepartmentUtilitieById?.data?.data?.data}

                                // selectedItem={props?.rowData}
                            />
                        </TabPanel>
                    </TabView>
                </Panel>
            </div>
        </div>
    );
};

export default DepartmentUtilitiesViewPage;

// Sample usage:
// <ProjectsViewPage project={{
//     name: 'Urban Development Project',
//     code: 'UD123',
//     department: 'Urban Planning',
//     type: 'Development',
//     responsibleOfficer: 'John Doe',
//     details: 'This project focuses on...',
//     districts: [{ id: '1', name: 'District 1' }, { id: '2', name: 'District 2' }]
// }} />
