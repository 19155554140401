import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import ProgramsCreateForm from "./ProgramsCreateForm";
import BudgetOutPutsCreateForm from "./BudgetOutPutsCreateForm";

import ProgramsEditForm from "./ProgramsEditForm";

import { deleteProgramById, getAllPrograms } from "../../../services/programs/program-service";

import moment from "moment";

import { Link } from "react-router-dom";

import WaterIsLoading from "../../../components/mweComponents/WaterIsLoading";
import MuiTable from "../../../components/mweComponents/MuiTable";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import { Panel } from "primereact/panel";
import { TabView, TabPanel } from "primereact/tabview";
import { Tooltip } from "primereact/tooltip";

function ProgramsList(props) {
    const queryClient = useQueryClient();
    const { data, isLoading, isError, error, status } = useQuery(["programs"], getAllPrograms, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching programs is : ", error);
        },
    });
    console.log(data);
    isError && toast.error("There was an error while fetching data");

    const ProgramDeleteMutation = useMutation((variables) => deleteProgramById(variables), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(["programs"]);
        },
    });

    // const handleDelete = async (event, id) => {
    //     var result = window.confirm("Are you sure you want to delete?");
    //     if (result === true) {
    //         ProgramDeleteMutation.mutate(id);
    //     }
    // };

    const handleDelete = (event, id) => {
        let deleteProgramId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(deleteProgramId),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (deleteProgramId) => {
        if (deleteProgramId !== null) {
            ProgramDeleteMutation.mutate(deleteProgramId);
        }
    };

    const cancelDelete = () => {
        // setDeleteProgramId(null);
    };

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showBudjetOutPutAddForm, setShowBudjetOutPutAddForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    const onBudjetOutPutFormClose = () => {
        setShowBudjetOutPutAddForm(false);
    };

    let tableId = 0;
    const columns = [
        {
            title: "#",
            width: "5%",
            field: "name",
            render: (rowData) => {
                // tableId = rowData.tableData.id;
                tableId = tableId++;
                return <div>{rowData.tableData.index + 1}</div>;
                // return <div>{rowData.tableData.id}</div>;
            },
        },
        {
            title: "Name",
            field: "name",
            render: (rowData) => {
                return (
                    <Link
                        // to={"/programs/" + item.id}
                        to="directorates"
                        state={{ programDetail: rowData, linkProgramId: rowData.id, name: rowData.name }}
                        className="text-decoration-none"
                    >
                        <strong
                            onClick={(e) => {
                                // e.preventDefault();
                            }}
                            className="custom-target-icon text-decoration-none"
                            style={{
                                display: "inline-block",
                                padding: "5px 10px",
                                color: "blue",
                                borderRadius: "4px",
                                fontSize: "1em",
                                cursor: "pointer",
                            }}
                            data-pr-tooltip="Click to see directorates"
                            data-pr-position="top"
                        >
                            {rowData.name}
                            <Tooltip target=".custom-target-icon" />
                        </strong>
                    </Link>
                );
            },
        },
        { title: "Details", field: "details", hidden: true },
        {
            title: "Date",
            hidden: true,
            field: "created_at",
            render: (rowData) => {
                return moment(rowData.created_at).format("lll");
            },
        },
    ];

    return (
        <div style={{ width: "100%" }}>
            {/* <TabView>
                <TabPanel header="Programs"> */}
            <Panel header="Programs" style={{ marginBottom: "20px" }}>
                {/* <div className="d-flex justify-content-end mb-3" style={{ width: "100%" }}>
                    {activeUser?.permissions.includes("add programs") && <Button label="Add Program" className="p-button-primary right-0" onClick={() => setShowAddForm(true)} />}
                    <ProgramsCreateForm show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} />
                </div> */}

                <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                    {/* {activeUser?.permissions.includes("add programs") && (
                        <Button
                            label="Add Budget Outputs"
                            className="p-button-primary"
                            onClick={() => {
                                setShowBudjetOutPutAddForm(true);
                                console.log("clicked add outcomes");
                            }}
                        />
                    )} */}
                    {activeUser?.permissions.includes("add programs") && <Button label="Add Program" className="p-button-primary" onClick={() => setShowAddForm(true)} />}
                    <ProgramsCreateForm show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} />
                    {/* <BudgetOutPutsCreateForm show={showBudjetOutPutAddForm} onHide={() => setShowBudjetOutPutAddForm(false)} onClose={onBudjetOutPutFormClose} /> */}
                </div>

                <MuiTable
                    tableTitle="Programs"
                    tableData={data?.data?.data}
                    tableColumns={columns}
                    handleShowEditForm={handleShowEditForm}
                    handleDelete={(e, item_id) => handleDelete(e, item_id)}
                    showEdit={activeUser?.permissions.includes("edit programs") || activeUser?.permissions.includes("delete programs")}
                    showDelete={activeUser?.permissions.includes("delete programs")}
                    loading={isLoading || status === "loading" || ProgramDeleteMutation.isLoading}
                />

                {selectedItem && <ProgramsEditForm programsData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} />}
                {/* <ConfirmDialog /> */}
                {ProgramDeleteMutation.isLoading && <WaterIsLoading />}
            </Panel>
            {/* </TabPanel>
                <TabPanel header="Program Reports"></TabPanel>
            </TabView> */}
        </div>
    );
}

export default ProgramsList;
