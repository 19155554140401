import React from "react";
import sampleData from "./sampleData.json";
import ReportsTrackerChart from "./ReportsTrackerChart";
import { Card } from "primereact/card";

function ReportsTrackerPage({ loggedInUserData }) {
    return (
        <div className="card overflow-x-auto">
            <ReportsTrackerChart loggedInUserData={loggedInUserData} data={sampleData} />
        </div>
    );
}

export default ReportsTrackerPage;
