import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";
import { Card } from "primereact/card";
import Lottie from "lottie-react";
import NoData from "../../../assets/Animation - nodata lottie 1702085133252.json";

// Initialize the required Highcharts modules
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);

const IndicatorTypesGraphs = ({ data }) => {
    console.log("sample outcome inicator data we are presenting on the dashboard is : ", data);
    const [graphContainerWidth, setGraphContainerWidth] = useState("100%");
    useEffect(() => {
        const handleResize = () => {
            setGraphContainerWidth(window.innerWidth >= 768 ? "420px" : "100%");
        };

        // Set initial value
        handleResize();

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Clean up
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    // Define a method to format date strings
    const formatDate = (dateString) => {
        return moment(dateString).format("YYYY-MM-DD HH:mm:ss");
    };

    // Function to generate Highcharts options
    // const getChartOptions = (indicator) => {
    //     //indicator.final_actual_value

    //     let final_value = 0;
    //     if (indicator?.show_manual_value) {
    //         final_value = parseFloat(indicator.manual_value ?? 0);
    //     } else {
    //         final_value = parseFloat(indicator.final_actual_value ?? 0);
    //     }
    //     const unit = indicator.department_outcome_indicator.outcome_indicator.unit || "";
    //     return {
    //         chart: {
    //             type: "column",
    //         },
    //         title: {
    //             text: `${indicator.department_outcome_indicator.outcome_indicator.name} (${unit})`,
    //         },
    //         // subtitle: {
    //         //     text: `${indicator.department_outcome_indicator.outcome_indicator.definition}`,
    //         // },
    //         xAxis: {
    //             categories: ["Baseline", "Actual", "Target"],
    //             title: {
    //                 text: null,
    //             },
    //         },
    //         yAxis: {
    //             // min: 0,
    //             // max: 100, // Ensure yAxis doesn't exceed 100%
    //             title: {
    //                 text: `Values (${unit})`,
    //                 align: "high",
    //             },
    //             labels: {
    //                 overflow: "justify",
    //                 format: `{value} ${unit}`, // Add percentage sign to yAxis labels
    //             },
    //         },
    //         // tooltip: {
    //         //     valueSuffix: "%", // Add percentage sign to tooltip values
    //         // },

    //         tooltip: {
    //             // Update the tooltip formatter to show the indicator definition
    //             formatter: function () {
    //                 return `<b>${this.series.name}</b><br/>${this.x}: ${this.y}${unit}<br/><b>Definition:</b> ${indicator.department_outcome_indicator.outcome_indicator.definition}`;
    //             },
    //         },
    //         plotOptions: {
    //             column: {
    //                 // Corrected from 'bar' to 'column' to match the chart type
    //                 dataLabels: {
    //                     enabled: true,
    //                     format: `{y} ${unit}`, // Add percentage sign to data labels
    //                 },
    //             },
    //         },
    //         credits: {
    //             enabled: false,
    //         },
    //         series: [
    //             {
    //                 name: "Values",
    //                 data: [parseFloat(indicator.base_value), parseFloat(final_value ?? 0), parseFloat(indicator.target_value)],
    //                 colorByPoint: true, // This will apply different colors to each column
    //             },
    //         ],
    //     };
    // };

    // const getChartOptions = (indicator) => {
    //     let final_value = 0;
    //     if (indicator?.show_manual_value) {
    //         final_value = parseFloat(indicator.manual_value ?? 0);
    //     } else {
    //         final_value = parseFloat(indicator.final_actual_value ?? 0);
    //     }
    //     const unit = indicator.department_outcome_indicator.outcome_indicator.unit || "";

    //     return {
    //         chart: {
    //             type: "column",
    //         },
    //         title: {
    //             text: `${indicator.department_outcome_indicator.outcome_indicator.name} (${unit})`,
    //         },
    //         xAxis: {
    //             categories: ["Baseline", "Actual", "Target"],
    //             title: {
    //                 text: null,
    //             },
    //         },
    //         yAxis: {
    //             title: {
    //                 text: `Values (${unit})`,
    //                 align: "high",
    //             },
    //             labels: {
    //                 overflow: "justify",
    //                 format: `{value} ${unit}`,
    //             },
    //         },
    //         tooltip: {
    //             formatter: function () {
    //                 return `<b>${this.series.name}</b><br/>${this.x}: ${this.y}${unit}<br/><b>Definition:</b> ${indicator.department_outcome_indicator.outcome_indicator.definition}`;
    //             },
    //         },
    //         plotOptions: {
    //             column: {
    //                 dataLabels: {
    //                     enabled: true,
    //                     format: `{y} ${unit}`,
    //                 },
    //             },
    //         },
    //         credits: {
    //             enabled: false,
    //         },
    //         series: [
    //             {
    //                 name: "Values",
    //                 data: [parseFloat(indicator.base_value ?? 0), final_value, parseFloat(indicator.target_value ?? 0)],
    //             },
    //         ],
    //     };
    // };

    const getChartOptions = (indicator) => {
        let final_value = 0;
        if (indicator?.show_manual_value) {
            final_value = parseFloat(indicator.manual_value ?? 0);
        } else {
            final_value = parseFloat(indicator.final_actual_value ?? 0);
        }
        const unit = indicator.department_outcome_indicator.outcome_indicator.unit || "";

        return {
            chart: {
                type: "column",
            },
            title: {
                text: `${indicator.department_outcome_indicator.outcome_indicator.name} (${unit})`,
            },
            xAxis: {
                categories: ["Baseline", "Actual", "Target"],
                title: {
                    text: null,
                },
            },
            yAxis: {
                title: {
                    text: `Values (${unit})`,
                    align: "high",
                },
                labels: {
                    overflow: "justify",
                    format: `{value} ${unit}`,
                },
            },
            tooltip: {
                formatter: function () {
                    return `<b>${this.series.name}</b><br/>${this.x}: ${this.y}${unit}<br/><b>Definition:</b> ${indicator.department_outcome_indicator.outcome_indicator.definition}`;
                },
            },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true,
                        format: `{y} ${unit}`,
                    },
                },
            },
            credits: {
                enabled: false,
            },
            series: [
                {
                    name: "Baseline",
                    data: [
                        {
                            y: parseFloat(indicator.base_value ?? 0),
                            color: Highcharts.getOptions().colors[0], // Blue
                        },
                    ],
                },
                {
                    name: "Actual",
                    data: [
                        {
                            y: final_value,
                            color: Highcharts.getOptions().colors[1], // Red
                        },
                    ],
                },
                {
                    name: "Target",
                    data: [
                        {
                            y: parseFloat(indicator.target_value ?? 0),
                            color: Highcharts.getOptions().colors[2], // Green
                        },
                    ],
                },
            ],
        };
    };

    // Function to generate semi-circle doughnut chart options
    const getProgressiveDoughnutChartOptions = (indicator) => {
        const baselineValue = parseFloat(indicator.base_value);
        const actualValue = parseFloat(indicator.final_actual_value ?? 0); // Use the actual value here
        const targetValue = parseFloat(indicator.target_value);
        const remainingValue = targetValue - (actualValue + baselineValue);

        // Ensure the values are greater than zero to avoid negative values or NaN issues
        // const safeActualValue = Math.max(actualValue - baselineValue, 0);
        const safeActualValue = actualValue;
        const safeRemainingValue = Math.max(remainingValue, 0);

        return {
            chart: {
                type: "pie",
                plotBackgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false,
            },
            title: {
                text: `${indicator.department_outcome_indicator.outcome_indicator.name}<br>Actual: ${safeActualValue + baselineValue} % / Target: ${targetValue} %`,
                align: "center",
                verticalAlign: "bottom", // Move title to the bottom
                style: {
                    fontSize: "10px", // Smaller font size
                },
                y: 5, // Adjust the vertical position to fit within the plot area
            },
            tooltip: {
                pointFormat: "{series.name}: <b>{point.y}%</b>",
            },

            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        format: "<b>{point.name}</b>: {point.y}%",
                        distance: -30,
                        style: {
                            fontWeight: "bold",
                        },
                    },
                    startAngle: 0,
                    endAngle: 360,
                    center: ["50%", "50%"],
                    size: "100%",
                },
            },
            credits: {
                enabled: false,
            },
            series: [
                {
                    name: "Value",
                    innerSize: "50%",
                    data: [
                        {
                            name: "Actual",
                            y: parseFloat(safeActualValue.toFixed(2)),
                            color: Highcharts.getOptions().colors[2], // Use the actual value
                        },
                        {
                            name: "Remaining",
                            y: parseFloat(safeRemainingValue.toFixed(2)),
                            color: "#e7eaeb", // Remaining to reach the target
                        },
                        {
                            name: "Baseline %",
                            y: parseFloat(baselineValue.toFixed(2)),
                            color: Highcharts.getOptions().colors[3], // Baseline value
                        },
                    ],
                },
            ],
        };
    };

    // Check if data is empty
    if (Array.isArray(data) && data?.length === 0) {
        return (
            <center>
                {" "}
                <Lottie
                    animationData={NoData}
                    loop={true}
                    autoplay={true}
                    style={{
                        width: "300px", // Increase width to cover the area
                        // height: "100%", // Increase height to cover the area
                    }}
                />
            </center>
        );
    }

    return (
        <TabView scrollable={true}>
            {data?.map((item, index) => (
                <TabPanel key={index} header={item.type}>
                    <div style={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap" }}>
                        {item.data.map((indicator, idx) => (
                            <div key={idx} style={{ width: graphContainerWidth, margin: "0.5rem" }}>
                                <Card>
                                    <HighchartsReact highcharts={Highcharts} options={getChartOptions(indicator)} />
                                </Card>
                            </div>
                        ))}
                    </div>
                </TabPanel>
            ))}
        </TabView>
    );
};

export default IndicatorTypesGraphs;
