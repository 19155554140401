import React, { Suspense, useState, useEffect, useRef } from "react";
import { Route, useLocation, useParams, Routes } from "react-router-dom";
import DirectorateList from "./DirectorateList";
import { Link } from "react-router-dom";

import BreadcrumbNav from "../../../components/mweComponents/BreadcrumbNav";
import "primereact/resources/themes/lara-light-indigo/theme.css";

const createBreadCrump = () => {};
//
function DirectoratePage({ loggedInUserData }) {
    // let { id } = useParams();
    let { state } = useLocation();
    const location = useLocation();
    let linkProgramId = state?.linkProgramId ? state.linkProgramId : null;
    let programDetail = state?.programDetail ? state.programDetail : null;
    let name = state?.name ? state?.name : null;
    let linksData = [{ name: "programs" }, { name: "Departments" }];

    //
    return (
        <div>
            <BreadcrumbNav />

            <DirectorateList loggedInUserData={loggedInUserData} programDetail={programDetail} programId={linkProgramId} oldlinks={{ names: { name } }} prevPage={name} />
        </div>
    );
}

export default DirectoratePage;
