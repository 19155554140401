import React from "react";
import ListPage from "./ListPage";
import { Link } from "react-router-dom";

import BreadcrumbNav from "../../../../../components/mweComponents/BreadcrumbNav";

const createBreadCrump = () => {};
//
function DepartmentTarrifsPage({ loggedInUserData, projectId, departmentId, selectedDepartmentItem }) {
    let linksData = [{ name: "programs" }];
    return (
        <div>
            {/* <BreadcrumbNav /> */}

            <ListPage loggedInUserData={loggedInUserData} selectedDepartmentItem={selectedDepartmentItem} departmentId={departmentId} projectId={projectId} />
        </div>
    );
}

export default DepartmentTarrifsPage;
