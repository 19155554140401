import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ProgressBar } from "primereact/progressbar";
import { TabView, TabPanel } from "primereact/tabview";
import Lottie from "lottie-react";
import NoData from "../../../assets/Animation - nodata lottie 1702085133252.json";

const UtilityThemeChartCards = ({ filterQueryIsLoading, utilityIndicatorsData }) => {
    console.log("UtilityThemeChartCards utilityIndicatorsData : ", utilityIndicatorsData);
    console.log("filterQueryIsLoading on theme graph : ", filterQueryIsLoading);
    const [graphContainerWidth, setGraphContainerWidth] = useState("100%");

    useEffect(() => {
        const handleResize = () => {
            setGraphContainerWidth(window.innerWidth >= 768 ? "420px" : "100%");
        };

        // Set initial value
        handleResize();

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Clean up
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Function to generate options for Highcharts for each indicator
    const generateChartOptions = (indicatorName, totalTargets, totalValues, unit) => {
        const format = unit ? `{point.y} ${unit}` : "{point.y}";
        return {
            chart: {
                type: "column",
            },
            title: {
                text: indicatorName,
            },
            xAxis: {
                categories: ["Metrics"],
            },
            yAxis: {
                min: 0,
                title: {
                    text: `Counts (${unit})`,
                },
            },
            credits: {
                enabled: false,
            },
            series: [
                {
                    name: "Target",
                    data: [
                        {
                            y: totalTargets,
                            color: Highcharts.getOptions().colors[0], // Assign specific color to the 'Target'
                        },
                    ],
                },
                {
                    name: "Actual",
                    data: [
                        {
                            y: totalValues,
                            color: Highcharts.getOptions().colors[1], // Assign specific color to the 'Actual'
                        },
                    ],
                },
            ],
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true,
                        format: format, // Displays the value on top of each column for clarity
                    },
                },
            },
        };
    };

    // Check if data is empty
    if (!filterQueryIsLoading && (!Array.isArray(utilityIndicatorsData?.aggregated_theme_reports?.indicator_groups) || utilityIndicatorsData?.aggregated_theme_reports?.indicator_groups?.length === 0)) {
        return (
            <center>
                {" "}
                <Lottie
                    animationData={NoData}
                    loop={true}
                    autoplay={true}
                    style={{
                        width: "300px", // Increase width to cover the area
                        // height: "100%", // Increase height to cover the area
                    }}
                />
            </center>
        );
    }

    return (
        <div>
            {/* Loader */}
            {filterQueryIsLoading && (
                <div className="p-field m-4">
                    <ProgressBar mode="indeterminate" style={{ height: "6px" }} />
                </div>
            )}

            {/* Tabs for each utility theme */}
            {!filterQueryIsLoading && utilityIndicatorsData?.aggregated_theme_reports?.indicator_groups && (
                <TabView className="m-2">
                    {utilityIndicatorsData.aggregated_theme_reports.indicator_groups.map((group, index) => (
                        <TabPanel key={index} header={group.utility_theme.name}>
                            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around" }}>
                                {group.utility_theme_report_indicators.map((indicator, idx) => {
                                    console.log("current theme indicator indicator is : ", indicator);
                                    return (
                                        <div key={idx} style={{ width: graphContainerWidth, margin: "0.5rem" }}>
                                            <Card>
                                                <HighchartsReact highcharts={Highcharts} options={generateChartOptions(indicator?.output_indicator?.name, indicator.total_targets, indicator.total_values, indicator.output_indicator.unit)} />
                                                {/* Statistics below each chart */}
                                                <div className="p-mt-3">
                                                    <h5>
                                                        {indicator.output_indicator.name} ({indicator.output_indicator.unit})
                                                    </h5>
                                                    <p>
                                                        Target: {indicator.total_targets} {indicator.output_indicator.unit}
                                                    </p>
                                                    <p>
                                                        Actual: {indicator.total_values} {indicator.output_indicator.unit}
                                                    </p>
                                                </div>
                                            </Card>
                                        </div>
                                    );
                                })}
                            </div>
                        </TabPanel>
                    ))}
                </TabView>
            )}
        </div>
    );
};

export default UtilityThemeChartCards;
