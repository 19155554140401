import axiosAPI from "../axiosApi";

export async function getAllPointWaterSources(params = {}) {
    const response = await axiosAPI.get("point-water-sources", { params: params });
    return response;
}

export async function getVillagesServedStats(params = {}) {
    const response = await axiosAPI.get("getVillagesServedStats", { params: params });
    return response;
}

export async function getPointWaterSourceById(id) {
    const response = await axiosAPI.get(`point-water-sources/` + id);
    return response;
}

export async function postPointWaterSources(data) {
    const response = await axiosAPI.post(`point-water-sources`, data);
    return response;
}
export async function filterPointWaterSources(data) {
    const response = await axiosAPI.post(`filterPointWaterSources`, data);
    return response;
}

export async function updatePointWaterSources(id, data) {
    const response = await axiosAPI.put(`point-water-sources/${id}`, data);
    return response;
}

export async function deletePointWaterSourceById(id) {
    const response = await axiosAPI.delete(`point-water-sources/${id}`);
    return response;
}
