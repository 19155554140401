import React, { useState, useEffect } from "react";
// import { Card } from "primereact/card";
import { Tree } from "primereact/tree";
import { Divider } from "primereact/divider";
import { useLocation } from "react-router-dom";
import { Panel } from "primereact/panel";
import BreadcrumbNav from "../../../components/mweComponents/BreadcrumbNav";
import { Button } from "primereact/button";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { Fieldset } from "primereact/fieldset";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { TabView, TabPanel } from "primereact/tabview";
// import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primereact/resources/themes/lara-light-blue/theme.css";
// import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import SubProjectsList from "../subprojects/SubProjectsList";

import CreateForm from "./projects-budgetOutputs/CreateForm";

import ProjectBudgetOutputsPage from "./projects-budgetOutputs/ProjectBudgetOutputsPage.js";
import ProjectBudgetPage from "./project-budget/ProjectBudgetPage";
import moment from "moment";
import DashboardPage from "./dashboard/DashboardPage";

import { deleteProjectById, getAllProjects, getAllProjectsById, getProjectsByProjectIdOrRole, getProjectsByDepartmentIdAndRole } from "../../../services/projects/project-service";
import { ProgressBar } from "primereact/progressbar";
import EditForm from "./projects-budgetOutputs/EditForm";
import SubprojectFieldDataList from "./approvals/ListPage";
import ProjectsReportsViewPage from "./projects-reports/ProjectsReportsViewPage";

const ProjectsViewPage = ({
    loggedInUserData,
    project = {
        name: "Urban Development Project",
        code: "UD123",
        department: "Urban Planning",
        type: "Development",
        responsibleOfficer: "John Doe",
        details: "This project focuses on...",
        districts: [
            { id: "1", name: "District 1" },
            { id: "2", name: "District 2" },
        ],
    },
}) => {
    const queryClient = useQueryClient();
    //
    let { state } = useLocation();
    let projectsData = state ? state.projectData : null;
    let componentDetailFromNavLink = state?.componentDetailFromNavLink ? state?.componentDetailFromNavLink : null;

    console.log("projects data got from state is : ", projectsData);

    console.log("logged in user on project : ", loggedInUserData);

    //===================== feytch projects by id =================
    const fetchProjectById = useQuery(["projects", "getById", projectsData?.id ?? componentDetailFromNavLink?.details?.id ?? loggedInUserData?.user_project_id], () => getAllProjectsById(projectsData?.id ?? componentDetailFromNavLink?.details?.id ?? loggedInUserData?.user_project_id), {
        onSuccess: (data) => {
            console.log("getAllProjectsById onsuccess fetching projects dsfvdsvd : ", data);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
            console.log("get list of projects : ", error);
        },
    });

    //
    projectsData = projectsData ?? fetchProjectById?.data?.data?.data;

    // Convert districts array to tree structure
    const districtTreeNodes = fetchProjectById?.data?.data?.data?.districts?.map((district) => ({
        key: district.id,
        label: district.name,
    }));

    const districtsTreeData = [
        {
            key: "root",
            label: "Districts",
            children: districtTreeNodes,
        },
    ];

    // Convert Funders array to tree structure
    const funderTreeNodes = fetchProjectById?.data?.data?.data?.funders?.map((funder) => ({
        key: funder.id,
        label: funder.name,
    }));

    const funderTreeData = [
        {
            key: "root",
            label: "Funders",
            children: funderTreeNodes,
        },
    ];

    // Convert Currencies array to tree structure
    const currencyTreeNodes = fetchProjectById?.data?.data?.data?.currencies?.map((currency) => ({
        key: currency.id,
        label: currency.name,
    }));

    const currencyTreeData = [
        {
            key: "root",
            label: "Currencies",
            children: currencyTreeNodes,
        },
    ];
    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showBudjetOutPutAddForm, setShowBudjetOutPutAddForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    return (
        <div style={{ width: "100%" }}>
            <BreadcrumbNav />
            <div className="projects-view-page">
                {fetchProjectById.isLoading && fetchProjectById.isFetching && !projectsData && (
                    <div className="m-2">
                        <ProgressBar mode="indeterminate" style={{ height: "4px" }} />
                    </div>
                )}
                <Panel header={`Project ${projectsData?.project_title} Details`} style={{ marginBottom: "20px" }}>
                    <TabView scrollable={true}>
                        <TabPanel header="Details">
                            <div className="flex flex-wrap">
                                {/* Column 1 */}
                                <div className="md:w-1/2 px-2 m-4">
                                    <p>
                                        <strong>Project Title:</strong> {projectsData?.project_title || "N/A"}
                                    </p>
                                    <p>
                                        <strong>Vote:</strong> {projectsData?.vote || "N/A"}
                                    </p>
                                    <p>
                                        <strong>Vote Code:</strong> {projectsData?.vote_code || "N/A"}
                                    </p>
                                    <p>
                                        <strong>Start Date:</strong>
                                        {projectsData?.start_date ? moment(projectsData.start_date).format("DD/MM/YY") : "N/A"}
                                    </p>
                                    <p>
                                        <strong>Proposed End Date:</strong>
                                        {projectsData?.proposed_end_date ? moment(projectsData.proposed_end_date).format("DD/MM/YY") : "N/A"}
                                    </p>

                                    <p>
                                        <strong>Actual End Date:</strong>
                                        {projectsData?.completion && projectsData.completion === 100 ? (projectsData?.actual_end_date ? moment(projectsData.actual_end_date).format("DD/MM/YYYY") : "Please provide an actual end date") : "N/A"}
                                    </p>

                                    <p>
                                        <strong>Actual Cost:</strong>
                                        {projectsData?.completion && projectsData.completion === 100 ? (projectsData?.actual_cost ? `${projectsData.actual_cost}` : "Please provide an actual cost") : "N/A"}
                                    </p>

                                    <p>
                                        <strong>Capital-Current Ratio:</strong> {projectsData?.capital_current_ratio || "N/A"}
                                    </p>

                                    <p>
                                        <strong>Completion Status:</strong> {projectsData?.completion_status || "N/A"}
                                    </p>

                                    <p>
                                        {/* <strong>Completion:</strong> {projectsData?.completion ? `${projectsData.completion}%` : "N/A"} */}
                                        <strong>Completion:</strong> <ProgressBar value={projectsData?.completion} label={`${projectsData?.completion}% Complete`} showValue={true} />
                                    </p>
                                    <p>
                                        <strong>Physical perfomence:</strong> {projectsData?.status || "N/A"}
                                    </p>
                                </div>

                                {/* Column 2 */}
                                <div className="md:w-1/2 px-2 m-4">
                                    <p>
                                        <strong>Project Code:</strong> {projectsData?.project_code}
                                    </p>
                                    <p>
                                        <strong>Department:</strong> {projectsData?.department?.name || "N/A"}
                                    </p>
                                    <p>
                                        <strong>Department Administration:</strong> {projectsData?.department_administration?.name || "N/A"}
                                    </p>
                                    {/* <p>
                                        <strong>Department Project Cluster:</strong> {projectsData?.department_project_cluster?.name || "N/A"}
                                    </p> */}
                                    <p>
                                        <strong>Department Function:</strong> {projectsData?.department_function?.name || "N/A"}
                                    </p>
                                    <p>
                                        <strong>Responsible Officer Name:</strong> {projectsData?.responsible_officer_name || "N/A"}
                                    </p>
                                    <p>
                                        <strong>Responsible Officer Title:</strong> {projectsData?.responsible_officer_title || "N/A"}
                                    </p>
                                    <p>
                                        <strong>Responsible Officer Phone:</strong> {projectsData?.responsible_officer_phonenumber || "N/A"}
                                    </p>
                                    <p>
                                        <strong>Responsible Officer Email:</strong> {projectsData?.responsible_officer_email || "N/A"}
                                    </p>
                                    <p>
                                        <strong>Target Beneficiaries:</strong> {projectsData?.target_beneficiaries || "N/A"}
                                    </p>
                                    <p>
                                        <strong>Total Number of Beneficiaries:</strong> {projectsData?.target_beneficiaries_total ? parseInt(projectsData?.target_beneficiaries_total).toLocaleString() : "N/A"}
                                    </p>

                                    {/* If created_by and updated_by are user IDs, you'll need to load the users to display their information */}
                                    {/* <p>
                                        <strong>Created By:</strong> {projectsData?.created_by?.email || "N/A"}
                                    </p>
                                    <p>
                                        <strong>Updated By:</strong> {projectsData?.updated_by?.email || "N/A"}
                                    </p> */}
                                </div>

                                {/* Full Width Row for Details */}
                                <div className="w-full px-2 m-4">
                                    <strong>Project Details</strong>
                                    <p>{projectsData?.project_details || "N/A"}</p>
                                </div>
                            </div>

                            <Divider />
                            <div>
                                <div className="m-4">
                                    <Fieldset legend="Districts">
                                        <Tree value={districtsTreeData} />
                                    </Fieldset>
                                </div>
                                <div className="m-4">
                                    <Fieldset legend="Funders">
                                        <Tree value={funderTreeData} />
                                    </Fieldset>
                                </div>

                                <div className="m-4">
                                    <Fieldset legend="Currencies">
                                        <Tree value={currencyTreeData} />
                                    </Fieldset>
                                </div>

                                {/* <div>
                                    <Fieldset legend="Budget Outputs & indicators">
                                        <div className="m-4">
                                            <TreeTable value={nodes}>
                                                <Column field="name" header="Name" expander></Column>
                                                <Column field="code" header="Code"></Column>
                                                <Column field="type" header="Type"></Column>
                                            </TreeTable>
                                        </div>
                                    </Fieldset>
                                </div> */}
                            </div>
                        </TabPanel>
                        <TabPanel header="Budget">
                            <ProjectBudgetPage selectedprojectItem={projectsData || fetchProjectById?.data?.data?.data} projectId={projectsData?.id || fetchProjectById?.data?.data?.data?.id} loggedInUserData={loggedInUserData} />
                        </TabPanel>
                        <TabPanel header="Budget Outputs">
                            <ProjectBudgetOutputsPage selectedprojectItem={projectsData || fetchProjectById?.data?.data?.data} projectId={projectsData?.id || fetchProjectById?.data?.data?.data?.id} loggedInUserData={loggedInUserData} />
                        </TabPanel>
                        <TabPanel header="(Subprojects / Activities)">
                            <SubProjectsList selectedprojectItem={projectsData || fetchProjectById?.data?.data?.data} loggedInUserData={loggedInUserData} projectid={projectsData?.id || fetchProjectById?.data?.data?.data?.id} />
                        </TabPanel>
                        {loggedInUserData?.role?.toLowerCase() === "project manager" && (
                            <TabPanel header="Approvals">
                                <SubprojectFieldDataList selectedprojectItem={projectsData || fetchProjectById?.data?.data?.data} loggedInUserData={loggedInUserData} />
                            </TabPanel>
                        )}

                        <TabPanel header="Projects Reports">
                            <ProjectsReportsViewPage selectedprojectItem={projectsData || fetchProjectById?.data?.data?.data} loggedInUserData={loggedInUserData} />
                        </TabPanel>
                    </TabView>
                </Panel>
            </div>
        </div>
    );
};

export default ProjectsViewPage;

// Sample usage:
// <ProjectsViewPage project={{
//     name: 'Urban Development Project',
//     code: 'UD123',
//     department: 'Urban Planning',
//     type: 'Development',
//     responsibleOfficer: 'John Doe',
//     details: 'This project focuses on...',
//     districts: [{ id: '1', name: 'District 1' }, { id: '2', name: 'District 2' }]
// }} />
