import axiosAPI from "../../axiosApi";

export async function getAllDepartmentUtilityIndicatorTargets(params = {}) {
    const response = await axiosAPI.get("utility-indicator-targets", { params: params });
    return response;
}

export async function getDepartmentUtilityIndicatorTargetById(id) {
    const response = await axiosAPI.get(`utility-indicator-targets/${id}`);
    return response;
}

export async function postDepartmentUtilityIndicatorTargets(data) {
    const response = await axiosAPI.post(`utility-indicator-targets`, data);
    return response;
}

export async function updateDepartmentUtilityIndicatorTargets(id, data) {
    const response = await axiosAPI.put(`utility-indicator-targets/${id}`, data);
    return response;
}

export async function deleteDepartmentUtilityIndicatorTargetById(id) {
    const response = await axiosAPI.delete(`utility-indicator-targets/${id}`);
    return response;
}
