import React, { useState, useEffect } from "react";

import { Dialog } from "primereact/dialog";

import { getAllPointWaterSources, filterPointWaterSources, getPointWaterSourceById, postPointWaterSources, updatePointWaterSources, deletePointWaterSourceById } from "../../../../../services/national-dashboard/point-water-sources-service.js";
import { filterReports, reportsTracker } from "../../../../../services/reports/reports-service.js";

import RowForm from "./widgets/RowForm";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";

function FiltersForm({ filterQueryIsLoading, setFilterQueryIsLoading, filterParams, filterSelectedObjects, setFilterSelectedObjects, setFilterParams, loggedInUserData, reportsData, setReportsData, ...props }) {
    const [name, setName] = useState();
    const [details, setDetails] = useState();
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(true);
    const [validated, setValidated] = useState(true);

    const queryClient = useQueryClient();

    const filterMutation = useMutation(reportsTracker, {
        onSuccess: (data) => {
            // queryClient.invalidateQueries(["subprojects", "subproject-locations"]);
            setFilterQueryIsLoading(false);
            toast.success("Filtered Succesfully");
            console.log("reports filtered data is  : ", data);
            setReportsData(data?.data);
            // props.onClose();
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("An Error Occured Please Contact Admin");
            console.log("create filtering report during report tracking : ", error);
        },
    });

    const handleSubmit = async (data) => {
        setFilterQueryIsLoading(true);
        // event.preventDefault();
        console.log("data we are sending on report filtering submitting : ", data);
        // setReportsData(null);
        setFilterParams(data);
        filterMutation.mutate(data);
    };

    return (
        <>
            <RowForm loggedInUserData={loggedInUserData} handleSubmit={handleSubmit} setFilterParams={setFilterParams} initialData={filterParams} filterSelectedObjects={filterSelectedObjects} setFilterSelectedObjects={setFilterSelectedObjects} />
            {/* <h4>{creactProgramsMutation.status}</h4> */}
            {filterMutation.isLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px", // Border thickness
                            borderColor: "blue", // Border color
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
        </>
    );
}

export default FiltersForm;
