import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import CreateForm from "./CreateForm";

import EditForm from "./EditForm";

import moment from "moment";

import {
    getAllSubprojectBgtOutputIndicatorTargets,
    getSubprojectBgtOutputIndicatorTargetById,
    postSubprojectBgtOutputIndicatorTarget,
    updateSubprojectBgtOutputIndicatorTarget,
    deleteSubprojectBgtOutputIndicatorTargetById,
} from "../../../../../services/sub-projects/subproject-budget-output-indicator-targets-service.js";

import WaterIsLoading from "../../../../../components/mweComponents/WaterIsLoading";
import MuiTable from "../../../../../components/mweComponents/MuiTable";
import { toast } from "react-toastify";
import { Button } from "primereact/button";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

import { Panel } from "primereact/panel";

function ListPage({ selectedParentItem, project_id, selectedprojectItem, subprojectDetail, ...props }) {
    const queryClient = useQueryClient();
    //department_budget_outputs_id
    // project_id;  project_id
    console.log("selectedParentItem ggggg : ", selectedParentItem);
    const { data, isLoading, isError, error, status } = useQuery(
        ["subprojects", "budgetOutputs", "subsubproject-budget-outputs-indicator-target", selectedParentItem?.id, selectedParentItem?.subproject_id],
        () => (!selectedParentItem?.id ? getAllSubprojectBgtOutputIndicatorTargets() : getAllSubprojectBgtOutputIndicatorTargets({ subproject_budget_outputs_id: selectedParentItem?.id, subproject_id: selectedParentItem?.subproject_id })),
        {
            onSuccess: (data) => {
                console.log("fetching subproject-budget-outputs-indicator-target data is : ", data);
            },
            onError: (error) => {
                error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
                console.log("Error fetching subproject-budget-outputs-indicator-target is : ", error);
            },
        }
    );
    console.log(data);
    isError && toast.error("There was an error while fetching data");

    const DeleteSelectedItemMutation = useMutation((variables) => deleteSubprojectBgtOutputIndicatorTargetById(variables), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(["subprojects", "budgetOutputs", "subsubproject-budget-outputs-indicator-target"]);
        },
        onError: (error) => {
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
        },
    });

    // const handleDelete = async (event, id) => {
    //     var result = window.confirm("Are you sure you want to delete?");
    //     if (result === true) {
    //         ProgramDeleteMutation.mutate(id);
    //     }
    // };

    const handleDelete = (event, id) => {
        let selectedDeleteId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(selectedDeleteId),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (selectedDeleteId) => {
        if (selectedDeleteId !== null) {
            DeleteSelectedItemMutation.mutate(selectedDeleteId);
        }
    };

    const cancelDelete = () => {
        // setDeleteProgramId(null);
    };

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showBudjetOutPutAddForm, setShowBudjetOutPutAddForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    const onBudjetOutPutFormClose = () => {
        setShowBudjetOutPutAddForm(false);
    };

    // 'department_indicator_id',
    //     'fyear_id',
    //     'quarter_id',
    //     'baseline',
    //     'actual',
    //     'target',
    //     'reason_of_variance',
    //     'created_by',
    //     'updated_by'

    // departmentIndicator;
    // financialYear;
    // quarter;
    // createdBy;
    // updatedBy;
    //  /* // 'base_year', // 'base_value', // 'target_year', // 'target_value', // 'financial_year_target', */
    let tableId = 0;

    const columns = [
        {
            title: "#",
            width: "5%",
            field: "name",
            render: (rowData) => {
                // tableId = rowData.tableData.id;
                tableId = tableId++;
                return <div>{rowData.tableData.index + 1}</div>;
                // return <div>{rowData.tableData.id}</div>;
            },
        },
        {
            title: "Subproject",
            field: "subproject.name",
        },
        {
            title: "Budget Output",
            field: "subproject_budget_output.project_department_budget_output.department_budget_output.name",
        },

        {
            title: "Budget Output Indicator",
            field: "project_dept_bgt_output_indicator_target.department_budget_output_indicator.output_indicator.name",
        },

        {
            title: "Financial Year",
            field: "financial_year.name",
        },
        {
            title: "Quarter",
            field: "quarter.name",
        },
        {
            title: "Target",
            field: "target",
        },
        {
            title: "Show Manual Value",
            field: "show_manual_value",
            render: (rowData) => (rowData?.show_manual_value ? "True" : "False"),
            hidden: true,
        },
        {
            title: "Manual Value",
            field: "manual_value",
            hidden: true,
        },
        {
            title: "Date",
            hidden: true,
            field: "created_at",
            render: (rowData) => {
                return moment(rowData.created_at).format("lll");
            },
        },
    ];

    console.log("selectedParentItem : ", selectedParentItem);
    return (
        <div style={{ width: "100%" }}>
            <div className="col-12 xl:col-12">
                <div className="card">
                    <p>subproject Budget Output Indicator Targets</p>
                </div>
            </div>
            <Panel header="Budget Output Indicator Targets" style={{ marginBottom: "20px" }}>
                {/* <div className="d-flex justify-content-end mb-3" style={{ width: "100%" }}>
                    {activeUser?.permissions.includes("add programs") && <Button label="Add Program" className="p-button-primary right-0" onClick={() => setShowAddForm(true)} />}
                    <CreateForm show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} />
                </div> */}

                <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                    {/* {activeUser?.permissions.includes("add programs") && (
                        <Button
                            label="Add Budget Outputs"
                            className="p-button-primary"
                            onClick={() => {
                                setShowBudjetOutPutAddForm(true);
                                console.log("clicked add outcomes");
                            }}
                        />
                    )} */}
                    {activeUser?.permissions.includes("add reports") && <Button label="Add indicator target" className="p-button-primary" onClick={() => setShowAddForm(true)} />}
                    <CreateForm selectedprojectItem={selectedprojectItem} subprojectDetail={subprojectDetail} show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} projectId={project_id} selectedParentItem={selectedParentItem} />
                    {/* <BudgetOutPutsCreateForm show={showBudjetOutPutAddForm} onHide={() => setShowBudjetOutPutAddForm(false)} onClose={onBudjetOutPutFormClose} /> */}
                </div>

                <MuiTable
                    tableTitle="Subproject Budget Outputs Indicator Targets"
                    tableData={data?.data?.data}
                    tableColumns={columns}
                    handleShowEditForm={handleShowEditForm}
                    handleDelete={(e, item_id) => handleDelete(e, item_id)}
                    showEdit={activeUser?.permissions.includes("add reports") || activeUser?.permissions.includes("edit reports")}
                    showDelete={activeUser?.permissions.includes("add reports")}
                    loading={isLoading || status === "loading" || DeleteSelectedItemMutation.isLoading}
                    exportButton={true}
                    pdfExportTitle="Subproject budget output indicators"
                    csvExportTitle="Subproject budget output indicators"
                />

                {selectedItem && <EditForm rowData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} selectedParentItem={selectedParentItem} />}

                {DeleteSelectedItemMutation.isLoading && <WaterIsLoading />}
                {/* <ConfirmDialog /> */}
            </Panel>
        </div>
    );
}

export default ListPage;
