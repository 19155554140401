import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import setFieldTouched from "final-form-set-field-touched";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { Checkbox } from "primereact/checkbox";

import { Dropdown } from "primereact/dropdown";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { classNames } from "primereact/utils";

import { getAllFinancialYears, getFinancialYearsById, postFinancialYears, updateFinancialYears, deleteFinancialYears } from "../../../../../../services/financial-years/financial-years-service";
import { getAllQuarters, getQuartersById, postQuarters, updateQuarters, deleteQuarters } from "../../../../../../services/financial-years/quarters";

import { getAllDepartmentOutcomeIndicators, getDepartmentOutcomeIndicatorById, postDepartmentOutcomeIndicator, updateDepartmentOutcomeIndicator, deleteDepartmentOutcomeIndicatorById } from "../../../../../../services/departments/departmentOutcomeIndicator-service.js";
import { getAllDepartmentBudjetOutputs, getDepartmentBudjetOutputById, postDepartmentBudjetOutput, updateDepartmentBudjetOutput, deleteDepartmentBudjetOutputById } from "../../../../../../services/departments/departments-budget-outputs-service.js";
import { getAllProjectDepartmentBudgetOutputs, getProjectDepartmentBudgetOutputById, postProjectDepartmentBudgetOutput, updateProjectDepartmentBudgetOutput, deleteProjectDepartmentBudgetOutputById } from "../../../../../../services/projects/project-department-budget-outputs-service.js";
import { deleteProjectById, getAllProjects, getProjectsByProjectIdOrRole, getProjectsByDepartmentIdAndRole } from "../../../../../../services/projects/project-service";

import { ProgressSpinner } from "primereact/progressspinner";

function RowForm({ handleSubmit, initialData, selectedParentItem, selectedprojectItem, project_id, ...props }) {
    console.log("selectedParentItem zzxxxxcccc : ", selectedParentItem);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);
    const queryClient = useQueryClient();

    // Setting up custom state for storing selected utility indicator
    const [selectedUtilityIndicator, setSelectedUtilityIndicator] = useState();

    // ================== get finacial years =================

    const getAllFinancialYearsQuery = useQuery(["financialYear"], getAllFinancialYears, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching getAllFinancialYears is : ", error);
        },
    });

    // ================= quarters ================

    const getAllQuartersQuery = useQuery(["quarters"], getAllQuarters, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching quarters is : ", error);
        },
    });

    // 'department_indicator_id',
    //     'fyear_id',
    //     'quarter_id',
    //     'baseline',
    //     'actual',
    //     'target',
    //     'reason_of_variance',
    //     'created_by',
    //     'updated_by'

    //  /* // 'base_year', // 'base_value', // 'target_year', // 'target_value', // 'financial_year_target', */

    const validate = (values) => {
        const errors = {};

        if (!values.project_department_budget_outputs_id) errors.project_department_budget_outputs_id = "Department Budget Output is required";
        if (!values.department_budget_output_indicators_id) errors.department_budget_output_indicators_id = "Department OutCome Indicator is required";
        if (!values.fyear_id) errors.fyear_id = "financial year is required";

        if (!values.target) errors.target = "Target is required";

        // Conditional validation for manual_value
        // if (values.show_manual_value && !values.manual_value) {
        //     errors.manual_value = "Not Computed value is required when Not Computed is checked";
        // }

        return errors;
    };

    // const onSubmitForm = (data) => {
    //     const errors = validate(data);
    //     if (Object.keys(errors).length === 0) {
    //         // No validation errors
    //         setPendingData(data);
    //         setShowConfirmDialog(true);
    //     } else {
    //         toast.warning("First Fill In All Required Fields");
    //     }
    // };

    const onSubmitForm = (data, form) => {
        // Add 'form' as an argument
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            // Mark all fields as touched to show validation errors
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("First fill in all required fields.");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    //======================Project department budget Outputs =====================

    const getAllProjectDepartmentBudgetOutputsIndicatorsQuery = useQuery(
        ["project", "departments", "departmentBudgetOutputs", selectedParentItem?.project_id],
        () => (!selectedParentItem?.project_id ? getAllProjectDepartmentBudgetOutputs() : getAllProjectDepartmentBudgetOutputs({ project_id: selectedParentItem?.project_id })),
        {
            onSuccess: (data) => {
                console.log("fetchAllProjectDepartmentBudjetOutputs : ", data);
            },
            onError: (error) => {
                error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("Án Error Occured Please Contact Admin");
                console.log("Error fetching projectSubprojectMetricsIndicator is : ", error);
            },
        }
    );

    if (selectedParentItem) {
        initialData = { project_department_budget_outputs_id: selectedParentItem?.id, ...initialData };
    }

    // ========================== fetch all projects =======================
    const fetchAllProjects = useQuery(["projects", "department_administrations_id", selectedParentItem?.project?.department_administrations_id], () => getAllProjects({ department_administrations_id: selectedParentItem?.project?.department_administrations_id }), {
        onSuccess: (data) => {
            console.log("getAllProjects onsuccess fetching projects dsfsd : ", data);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("Án Error Occured Please Contact Admin");
            console.log("get list of projects : ", error);
        },
    });

    if (project_id) {
        initialData = { project_id: project_id, ...initialData };
    }

    //
    // This will find the object that matches the selected ID
    const matchingIndicatorObject = selectedParentItem?.department_budget_output?.department_budget_output_indicator?.find((item) => item?.id === selectedUtilityIndicator);
    console.log("matchingIndicatorObject is : ", matchingIndicatorObject);

    return (
        <div className="col-12 md:col-12">
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    mutators={{ setFieldTouched }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                console.log("values hhh : ", values);
                                console.log("event fffff : ", event);
                                onSubmitForm(values, form);
                                // handleSubmit(event, values);
                            }}
                        >
                            <div style={{ marginBottom: "1rem" }}>
                                <Field name="project_department_budget_outputs_id">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Budget Output </label>
                                            <Dropdown
                                                value={input.value}
                                                options={getAllProjectDepartmentBudgetOutputsIndicatorsQuery?.data?.data?.data.map((item) => ({
                                                    label: item?.department_budget_output?.name,
                                                    value: item?.id,
                                                }))}
                                                onChange={(e) => input.onChange(e.value)}
                                                disabled={true}
                                                // disabled={getAllProjectDepartmentBudgetOutputsIndicatorsQuery?.isLoading}
                                                // disabled={props?.loggedInUserData?.role !== "Admin"}
                                                placeholder="Select Department Budget Output"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                            {getAllProjectDepartmentBudgetOutputsIndicatorsQuery.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div style={{ marginBottom: "1rem" }}>
                                <Field name="project_id">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Project</label>
                                            <Dropdown
                                                value={input.value}
                                                options={fetchAllProjects?.data?.data?.data.map((item) => ({
                                                    label: item.project_title,
                                                    value: item.id,
                                                }))}
                                                onChange={(e) => input.onChange(e.value)}
                                                placeholder="Select Project"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                disabled={true}
                                                // disabled={fetchAllProjects.isLoading}
                                            />
                                            {fetchAllProjects.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <Field name="department_budget_output_indicators_id">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label>Department Budget Output indicator</label>
                                        <Dropdown
                                            value={input.value}
                                            options={selectedParentItem?.department_budget_output?.department_budget_output_indicator?.map((item) => ({
                                                label: item?.output_indicator?.name,
                                                value: item?.id,
                                            }))}
                                            // onChange={(e) => input.onChange(e.value)}
                                            onChange={(e) => {
                                                input.onChange(e.value);
                                                setSelectedUtilityIndicator(e.value); // Update the custom state when the dropdown changes
                                            }}
                                            // disabled={getAllDepartmentBudgetOutputsIndicatorsQuery?.isLoading}
                                            // disabled={props?.loggedInUserData?.role !== "Admin"}
                                            placeholder="Select Department Budget Output Indicator"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                        />
                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="fyear_id">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label>Financial Year</label>
                                        <Dropdown
                                            value={input.value}
                                            options={getAllFinancialYearsQuery?.data?.data?.data.map((item) => ({
                                                label: item.name,
                                                value: item.id,
                                            }))}
                                            onChange={(e) => input.onChange(e.value)}
                                            // disabled={props?.loggedInUserData?.role !== "Admin"}
                                            placeholder="Select Financial Year"
                                            disabled={getAllFinancialYearsQuery.isLoading}
                                        />
                                        {getAllFinancialYearsQuery.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="target">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label>Target {matchingIndicatorObject?.output_indicator?.unit ? matchingIndicatorObject?.output_indicator?.unit : ""}</label>
                                        <InputText
                                            {...input}
                                            id="target"
                                            type="number"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                // Allow only non-negative numbers
                                                if (!value || parseFloat(value) >= 0) {
                                                    input.onChange(value);
                                                }
                                            }}
                                        />
                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            {/* <Field name="show_manual_value">
                                {({ input, meta }) => (
                                    <div className="p-field-checkbox m-4">
                                        <Checkbox inputId="show_manual_value" checked={!!input.value} onChange={(e) => input.onChange(e.checked)} />
                                        <label htmlFor="show_manual_value">Not Computed</label>
                                    </div>
                                )}
                            </Field> */}

                            {/* Conditionally render the manual_value field based on show_manual_value */}
                            {/* <Field name="manual_value">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="manual_value">Manual Value</label>
                                            <InputTextarea {...input} id="manual_value" type="text" rows={4} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field> */}
                            {/* {values.show_manual_value ? (
                                <Field name="manual_value">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="manual_value">Not Computed Value</label>
                                            <InputText {...input} id="name" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                            {meta.touched && (meta.error || form.submitSuccess || form.submitError) && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            ) : (
                                <></>
                            )} */}

                            <div className="d-grid gap-2">
                                <Button type="submit" label="Save" className="p-button-primary" icon="pi pi-check" />
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    style={{ width: "30vw" }}
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to submit?
                </Dialog>
            </div>
        </div>
    );
}

export default RowForm;
