import axiosAPI from "../../axiosApi";

export async function getAllUtilityReports(params = {}) {
    const response = await axiosAPI.get("utility-reports", { params: params });
    return response;
}

export async function getUtilityReportById(id) {
    const response = await axiosAPI.get(`utility-reports/${id}`);
    return response;
}

export async function postUtilityReport(data) {
    const response = await axiosAPI.post(`utility-reports`, data);
    return response;
}

export async function updateUtilityReport(id, data) {
    const response = await axiosAPI.put(`utility-reports/${id}`, data);
    return response;
}

export async function deleteUtilityReportById(id) {
    const response = await axiosAPI.delete(`utility-reports/${id}`);
    return response;
}

export async function updateUtilityReportDataStatus(data) {
    const response = await axiosAPI.post("utility-reports-status", data);
    return response;
}

export async function postToAggregateUtilityReports(data) {
    const response = await axiosAPI.post(`aggregateUtilityReports`, data);
    return response;
}
