import axiosAPI from "../axiosApi";

export async function getAllSubprojectBudgets(params = {}) {
    const response = await axiosAPI.get("subprojects-budget", { params: params });
    return response;
}

export async function getSubprojectBudgetById(id) {
    const response = await axiosAPI.get(`subprojects-budget/${id}`);
    return response;
}

export async function postSubprojectBudget(data) {
    const response = await axiosAPI.post(`subprojects-budget`, data);
    return response;
}

export async function updateSubprojectBudget(id, data) {
    const response = await axiosAPI.put(`subprojects-budget/${id}`, data);
    return response;
}

export async function deleteSubprojectBudgetById(id) {
    const response = await axiosAPI.delete(`subprojects-budget/${id}`);
    return response;
}
