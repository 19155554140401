import React from "react";
import SubProjectsList from "./SubProjectsList";
import { useLocation } from "react-router-dom";
import BreadcrumbNav from "../../../components/mweComponents/BreadcrumbNav";
// import "primereact/resources/themes/lara-light-indigo/theme.css";

function SubProjectsPage({ loggedInUserData }) {
    let { state } = useLocation();
    let id = state ? state.id : null;
    let prevPage = state?.name ? state.name : null;
    return (
        <div>
            <BreadcrumbNav />

            <SubProjectsList loggedInUserData={loggedInUserData} projectid={id} prevPage={prevPage} />
        </div>
    );
}

export default SubProjectsPage;
