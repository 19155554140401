import React, { useState, useEffect } from "react";
// import { Card } from "primereact/card";
import { Tree } from "primereact/tree";
import { Divider } from "primereact/divider";
import { useLocation } from "react-router-dom";
import { Panel } from "primereact/panel";
import BreadcrumbNav from "../../../../components/mweComponents/BreadcrumbNav";
import { Button } from "primereact/button";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { Fieldset } from "primereact/fieldset";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { TabView, TabPanel } from "primereact/tabview";
// import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primereact/resources/themes/lara-light-blue/theme.css";
// import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

import { getAllDepartmentAdministrations, getDepartmentAdministrationById, postDepartmentAdministration, updateDepartmentAdministration, deleteDepartmentAdministrationById } from "../../../../services/departments/department-administrations-service";

import ProjectsList from "../../projects/ProjectsList";
import { ProgressBar } from "primereact/progressbar";

const DepartmentAdministrationViewPage = ({ loggedInUserData }) => {
    const queryClient = useQueryClient();
    //
    let { state } = useLocation();
    let departmentAdministrationData = state?.departmentAdministrationData ? state?.departmentAdministrationData : null;
    let componentDetailFromNavLink = state?.componentDetailFromNavLink ? state?.componentDetailFromNavLink : null;

    console.log("componentDetailFromNavLink sgdg : ", componentDetailFromNavLink);
    console.log("departmentAdministrationData data got from state is : ", departmentAdministrationData);
    console.log("loggedInUserData on departments administration : ", loggedInUserData);

    //
    let departmentViewPageActiveIndexFromUrlState = state?.subprojectViewPageActiveIndex ? state?.departmentViewPageActiveIndex : 0;
    let departmentViewPageChildActiveIndexFromUrlState = state?.subprojectViewPageChildActiveIndex ? state?.departmentViewPageChildActiveIndex : 0;

    //
    const [departmentViewPageActiveIndex, setDepartmentViewPageActiveIndex] = useState(departmentViewPageActiveIndexFromUrlState);
    const [departmentViewPageChildActiveIndex, setDepartmentViewPageChildActiveIndex] = useState(departmentViewPageChildActiveIndexFromUrlState);

    //===================== getDepartmentById by id =================
    const fetchDepartmentAdminById = useQuery(
        ["department-administration", "departments", "getById", departmentAdministrationData?.id ?? componentDetailFromNavLink?.details?.id ?? loggedInUserData?.user_dept_administration_id],
        () => getDepartmentAdministrationById(departmentAdministrationData?.id ?? componentDetailFromNavLink?.details?.id ?? loggedInUserData?.user_dept_administration_id),
        {
            onSuccess: (data) => {
                console.log("department-administration getById onsuccess fetching : ", data);
            },
            onError: (error) => {
                // props.onClose();
                error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
                console.log("get list of projects : ", error);
            },
        }
    );

    //
    departmentAdministrationData = departmentAdministrationData ?? fetchDepartmentAdminById?.data?.data?.data;
    console.log("departmentAdministrationDatavvvvvv : ", departmentAdministrationData);

    // Convert districts array to tree structure
    // Convert districts array to tree structure
    const districtTreeNodes = departmentAdministrationData?.department_administration_districts?.map((districtData) => ({
        key: districtData.district.id.toString(),
        label: districtData.district.name,
    }));

    const districtsTreeData = [
        {
            key: "root",
            label: "Districts",
            children: districtTreeNodes,
        },
    ];

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showBudjetOutPutAddForm, setShowBudjetOutPutAddForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    const onTabChange = (e) => {
        setDepartmentViewPageActiveIndex(e.index); // Update the active tab index
    };

    const onChildTabChange = (e) => {
        setDepartmentViewPageChildActiveIndex(e.index); // Update the active tab index
    };

    return (
        <div style={{ width: "100%" }}>
            <BreadcrumbNav />
            <div className="projects-view-page">
                {fetchDepartmentAdminById.isLoading && fetchDepartmentAdminById.isFetching && !departmentAdministrationData && (
                    <div className="m-2">
                        <ProgressBar mode="indeterminate" style={{ height: "4px" }} />
                    </div>
                )}
                <Panel header={`Department Administration : ${departmentAdministrationData?.name} Details`} style={{ marginBottom: "20px" }}>
                    {/* Nested TabView inside Overview */}
                    <TabView scrollable={true} activeIndex={departmentViewPageChildActiveIndex} onTabChange={onChildTabChange}>
                        <TabPanel header="Details">
                            <div className="flex flex-wrap">
                                {/* Column 1 */}
                                <div className="w-full md:w-1/2 px-2 mb-4">
                                    <p>
                                        <strong>Department Administration Name:</strong> {departmentAdministrationData?.name}
                                    </p>
                                </div>

                                {/* Column 2 */}
                                <div className="w-full md:w-1/2 px-2 mb-4">
                                    <p>
                                        <strong>Department:</strong> {departmentAdministrationData?.department?.name}
                                    </p>

                                    {/* <p>
                                        <strong>Updated By:</strong> {departmentAdministrationData?.updated_by?.email || "N/A"}
                                    </p> */}
                                </div>

                                {/* Full Width Row for Details */}
                                {/* <div className="w-full px-2">
                                    <strong>Description</strong>
                                    <p>{departmentAdministrationData?.description}</p>
                                </div> */}

                                <div className="w-full px-2">
                                    <strong>Details</strong>
                                    <p>{departmentAdministrationData?.details}</p>
                                </div>

                                <div className="w-full px-2">
                                    <Fieldset legend="Districts">
                                        <Tree value={districtsTreeData} />
                                    </Fieldset>
                                </div>
                            </div>

                            {/* <Divider /> */}
                        </TabPanel>
                        {/* <TabPanel header="Budget">
                            <DepartmentBudgetPage loggedInUserData={loggedInUserData} selectedDepartmentItem={departmentAdministrationData || fetchDepartmentAdminById?.data?.data} departmentId={departmentAdministrationData?.id || fetchDepartmentAdminById?.data?.data?.id} />
                        </TabPanel> */}

                        <TabPanel header="Projects">
                            <ProjectsList loggedInUserData={loggedInUserData} selectedParentDetail={departmentAdministrationData || fetchDepartmentAdminById?.data?.data?.data} departmentId={departmentAdministrationData?.id || fetchDepartmentAdminById?.data?.data?.data?.id} />
                        </TabPanel>
                    </TabView>
                </Panel>
            </div>
        </div>
    );
};

export default DepartmentAdministrationViewPage;
