import React, { Suspense, useState, useEffect, useRef } from "react";

import classNames from "classnames";
import { Route, useLocation, Routes } from "react-router-dom";

import { CSSTransition } from "react-transition-group";

import { AppTopbar } from "../AppTopbar";
import { AppFooter } from "../AppFooter";
import { AppMenu } from "../AppMenu";
import { AppConfig } from "../AppConfig";

import { getProgramsHireachy } from "../services/programs/program-service.js";

import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

// import "primereact/resources/primereact.css";
// import "primeicons/primeicons.css";
// import "primeflex/primeflex.css";
// import "prismjs/themes/prism-coy.css";
// import "../assets/demo/flags/flags.css";
// import "../assets/demo/Demos.scss";
// import "../assets/layout/layout.scss";
// import "../App.scss";

//
import AppRoutes from "../AppRoutes";
import useAuthContext from "../context/AuthContext";

const AdminLayout = () => {
    const { user, getUserQuery, isLoading } = useAuthContext();
    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("light");
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();

    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };
    const isDesktop = () => {
        return window.innerWidth >= 992;
    };

    const queryClient = useQueryClient();

    //============================= get program hierach ========================
    const getProgramsHireachyQuery = useQuery(["programs-hireachy", "programs", "departments", "projects", "subprojects"], getProgramsHireachy, {
        onSuccess: (data) => {
            console.log("fetching programs-hireachy data is : ", data);
        },
        onError: (error) => {
            console.log("Error fetching programs-hireachy is : ", error);
        },
        // staleTime: 7 * 60 * 60 * 1000, // 7 hours in milliseconds
    });

    console.log("checking for logged in user : ", getUserQuery?.data?.data);

    const EngineerMenu = [
        ...(getProgramsHireachyQuery?.data?.data ?? [
            {
                label: "Loading...",
                icon: "pi pi-spin pi-spinner",
                to: "#",
            },
        ]),
    ];

    const UtilityManagerMenu = [
        ...(getProgramsHireachyQuery?.data?.data ?? [
            {
                label: "Loading...",
                icon: "pi pi-spin pi-spinner",
                to: "#",
            },
        ]),
    ];
    const supervisorMenu = [
        ...(getProgramsHireachyQuery?.data?.data ?? [
            {
                label: "Loading...",
                icon: "pi pi-spin pi-spinner",
                to: "#",
            },
        ]),
    ];

    const projectManagerMenu = [
        ...(getProgramsHireachyQuery?.data?.data ?? [
            {
                label: "Loading...",
                icon: "pi pi-spin pi-spinner",
                to: "#",
            },
        ]),
    ];

    const districtWaterEnginnerMenu = [
        ...(getProgramsHireachyQuery?.data?.data ?? [
            {
                label: "Loading...",
                icon: "pi pi-spin pi-spinner",
                to: "#",
            },
        ]),
    ];

    const assistantCommisionerMenu = [
        ...(getProgramsHireachyQuery?.data?.data ?? [
            {
                label: "Loading...",
                icon: "pi pi-spin pi-spinner",
                to: "#",
            },
        ]),
    ];

    const departmentCommisionerMenu = [
        ...(getProgramsHireachyQuery?.data?.data ?? [
            {
                label: "Loading",
                icon: "pi pi-spin pi-spinner",
                to: "#",
            },
        ]),
    ];

    const AdminMenu = [
        {
            label: "Home",
            items: [
                {
                    label: "Dashboard",
                    icon: "pi pi-fw pi-home",
                    to: "/dashboard",
                },
                {
                    label: "National",
                    icon: "pi pi-fw pi-flag",
                    to: "/national",
                },
                {
                    label: "Projects Map",
                    icon: "pi pi-fw pi-map",
                    to: "/map",
                },
                {
                    label: "Reports",
                    icon: "pi pi-fw pi-chart-bar", // Example icon for reports
                    to: "/reports",
                },
                {
                    label: "Tracker",
                    icon: "pi pi-fw pi-chart-line", // Icon representing data tracking or trend analysis
                    to: "/tracker",
                },

                // {
                //     label: "Sample Map",
                //     icon: "pi pi-fw pi-map",
                //     to: "/samplemap",
                // },
            ],
        },

        ...(getProgramsHireachyQuery?.data?.data ?? [
            {
                label: "Loading...",
                icon: "pi pi-spin pi-spinner",
                to: "#",
            },
        ]),
        {
            label: "User Management",
            items: [{ label: "Users", icon: "pi pi-fw pi-user-edit", to: "/users" }],
        },

        {
            label: "Configurations",

            items: [
                {
                    label: "Locations",
                    icon: "pi pi-fw pi-globe",
                    items: [
                        {
                            label: "Districts",
                            icon: "pi pi-fw pi-map-marker",
                            to: "/districts",
                        },
                        {
                            label: "Counties",
                            icon: "pi pi-fw pi-map-marker",
                            to: "/Counties",
                        },
                        {
                            label: "Sub Counties",
                            icon: "pi pi-fw pi-map-marker",
                            to: "/SubCounties",
                        },
                        {
                            label: "Parish",
                            icon: "pi pi-fw pi-map-marker",
                            to: "/parishes",
                        },
                        {
                            label: "Village",
                            icon: "pi pi-fw pi-map-marker",
                            to: "/villages",
                        },
                    ],
                },

                {
                    label: "Settings",
                    icon: "pi pi-fw pi-cog",
                    items: [
                        {
                            label: "Currencies",
                            icon: "pi pi-fw pi-dollar",
                            // icon: "pi pi-fw pi-handshake",
                            to: "/currencies",
                        },
                        {
                            label: "Utility Types",
                            icon: "pi pi-fw pi-folder-open",
                            // icon: "pi pi-fw pi-handshake",
                            to: "/utility-types",
                        },
                        {
                            label: "Output Indicator Types",
                            icon: "pi pi-fw pi-clone",
                            // icon: "pi pi-fw pi-handshake",
                            to: "/output-indicator-types",
                        },
                        {
                            label: "Funders",
                            icon: "pi pi-fw pi-wallet",
                            // icon: "pi pi-fw pi-handshake",
                            to: "/funders",
                        },
                        // {
                        //     label: "Indicators",
                        //     icon: "pi pi-fw pi-list",
                        //     // icon: "pi pi-fw pi-handshake",
                        //     to: "/indicators",
                        // },

                        {
                            label: "Outcome Indicator Types",
                            // icon: "pi pi-fw pi-list",
                            icon: "pi pi-fw pi-th-large",
                            to: "/indicatortypes",
                        },

                        {
                            label: "Operators",
                            // icon: "pi pi-fw pi-cog",
                            icon: "pi pi-fw pi-bolt",
                            to: "/OperationAndMaintenanceStructures",
                        },
                        {
                            label: "Periods",
                            icon: "pi pi-fw pi-clock",
                            items: [
                                { label: "Financial Years", icon: "pi pi-fw pi-calendar", to: "/financialyears" },
                                { label: "Years", icon: "pi pi-fw pi-calendar", to: "/years" },
                                // { label: "Months", icon: "pi pi-fw pi-calendar", to: "/months" },
                                { label: "Quarters", icon: "pi pi-fw pi-clock", to: "/quarters" },
                                { label: "Census", icon: "pi pi-fw pi-chart-bar", to: "/census" },
                            ],
                        },
                        {
                            label: "Data Migrations",
                            icon: "pi pi-fw pi-server",
                            // icon: "pi pi-fw pi-handshake",
                            to: "/DataMigrations",
                        },
                    ],
                },
            ],
        },

        // {
        //     label: "UI Components",
        //     icon: "pi pi-fw pi-sitemap",
        //     items: [
        //         {
        //             label: "Old Dashboard",
        //             icon: "pi pi-fw pi-home",
        //             to: "/olddashboard",
        //         },
        //         { label: "Form Layout", icon: "pi pi-fw pi-id-card", to: "/formlayout" },
        //         { label: "Input", icon: "pi pi-fw pi-check-square", to: "/input" },
        //         { label: "Float Label", icon: "pi pi-fw pi-bookmark", to: "/floatlabel" },
        //         { label: "Invalid State", icon: "pi pi-fw pi-exclamation-circle", to: "invalidstate" },
        //         { label: "Button", icon: "pi pi-fw pi-mobile", to: "/button" },
        //         { label: "Table", icon: "pi pi-fw pi-table", to: "/table" },
        //         { label: "List", icon: "pi pi-fw pi-list", to: "/list" },
        //         { label: "Tree", icon: "pi pi-fw pi-share-alt", to: "/tree" },
        //         { label: "Panel", icon: "pi pi-fw pi-tablet", to: "/panel" },
        //         { label: "Overlay", icon: "pi pi-fw pi-clone", to: "/overlay" },
        //         { label: "Media", icon: "pi pi-fw pi-image", to: "/media" },
        //         { label: "Menu", icon: "pi pi-fw pi-bars", to: "/menu" },
        //         { label: "Message", icon: "pi pi-fw pi-comment", to: "/messages" },
        //         { label: "File", icon: "pi pi-fw pi-file", to: "/file" },
        //         { label: "Chart", icon: "pi pi-fw pi-chart-bar", to: "/chart" },
        //         { label: "Misc", icon: "pi pi-fw pi-circle-off", to: "/misc" },
        //     ],
        // },
        // {
        //     label: "UI Blocks",
        //     items: [
        //         { label: "Free Blocks", icon: "pi pi-fw pi-eye", to: "/blocks", badge: "NEW" },
        //         { label: "All Blocks", icon: "pi pi-fw pi-globe", url: "https://www.primefaces.org/primeblocks-react" },
        //     ],
        // },
        // {
        //     label: "Icons",
        //     items: [{ label: "PrimeIcons", icon: "pi pi-fw pi-prime", to: "/icons" }],
        // },
        // {
        //     label: "Pages",
        //     icon: "pi pi-fw pi-clone",
        //     items: [
        //         { label: "Crud", icon: "pi pi-fw pi-user-edit", to: "/crud" },
        //         { label: "Timeline", icon: "pi pi-fw pi-calendar", to: "/timeline" },
        //         { label: "Empty", icon: "pi pi-fw pi-circle-off", to: "/empty" },
        //     ],
        // },
        // {
        //     label: "Menu Hierarchy",
        //     icon: "pi pi-fw pi-search",
        //     items: [
        //         {
        //             label: "Submenu 1",
        //             icon: "pi pi-fw pi-bookmark",
        //             items: [
        //                 {
        //                     label: "Submenu 1.1",
        //                     icon: "pi pi-fw pi-bookmark",
        //                     items: [
        //                         { label: "Submenu 1.1.1", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 1.1.2", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 1.1.3", icon: "pi pi-fw pi-bookmark" },
        //                     ],
        //                 },
        //                 {
        //                     label: "Submenu 1.2",
        //                     icon: "pi pi-fw pi-bookmark",
        //                     items: [
        //                         { label: "Submenu 1.2.1", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 1.2.2", icon: "pi pi-fw pi-bookmark" },
        //                     ],
        //                 },
        //             ],
        //         },
        //         {
        //             label: "Submenu 2",
        //             icon: "pi pi-fw pi-bookmark",
        //             items: [
        //                 {
        //                     label: "Submenu 2.1",
        //                     icon: "pi pi-fw pi-bookmark",
        //                     items: [
        //                         { label: "Submenu 2.1.1", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 2.1.2", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 2.1.3", icon: "pi pi-fw pi-bookmark" },
        //                     ],
        //                 },
        //                 {
        //                     label: "Submenu 2.2",
        //                     icon: "pi pi-fw pi-bookmark",
        //                     items: [
        //                         { label: "Submenu 2.2.1", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 2.2.2", icon: "pi pi-fw pi-bookmark" },
        //                     ],
        //                 },
        //             ],
        //         },
        //     ],
        // },
        // {
        //     label: "Get Started",
        //     items: [
        //         {
        //             label: "Documentation",
        //             icon: "pi pi-fw pi-question",
        //             command: () => {
        //                 window.location = "#/documentation";
        //             },
        //         },
        //         {
        //             label: "View Source",
        //             icon: "pi pi-fw pi-search",
        //             command: () => {
        //                 window.location = "https://github.com/primefaces/sakai-react";
        //             },
        //         },
        //     ],
        // },
    ];

    let menu = [
        {
            label: "Home",
            items: [
                {
                    label: "Dashboard",
                    icon: "pi pi-fw pi-home",
                    to: "/dashboard",
                },
                {
                    label: "National",
                    icon: "pi pi-fw pi-flag",
                    to: "/national",
                },
                {
                    label: "Map",
                    icon: "pi pi-fw pi-map",
                    to: "/map",
                },
                {
                    label: "Reports",
                    icon: "pi pi-fw pi-chart-bar", // Example icon for reports
                    to: "/reports",
                },
                // {
                //     label: "Sample Map",
                //     icon: "pi pi-fw pi-map",
                //     to: "/samplemap",
                // },
            ],
        },
    ];

    const role = getUserQuery?.data?.data?.role?.toLowerCase();

    if (role === "admin" || role === "super admin") {
        menu = AdminMenu;
    } else if (role === "department commissioner") {
        menu = [...menu, ...departmentCommisionerMenu];
    } else if (role === "assistant commissioner") {
        menu = [...menu, ...assistantCommisionerMenu];
    } else if (role === "district water engineer") {
        menu = [...menu, ...districtWaterEnginnerMenu];
    } else if (role === "project manager") {
        menu = [...menu, ...projectManagerMenu];
    } else if (role === "supervisor") {
        menu = [...menu, ...supervisorMenu];
    } else if (role === "engineer") {
        menu = [...menu, ...EngineerMenu];
    } else if (role === "utility manager") {
        menu = [...menu, ...UtilityManagerMenu];
    } else {
        // menu = DefaultMenu; // Default menu if the role doesn't match any of the above
    }

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });

    // ===========  App routes ===========
    let myroutes = AppRoutes(getUserQuery?.data?.data);
    const [defaultRoutes, setDefaultRoutes] = useState(myroutes);

    // useEffect(() => {
    //     setDefaultRoutes(myroutes);
    // }, [myroutes]);

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

            <div className="layout-sidebar" onClick={onSidebarClick}>
                <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
            </div>

            <div className="layout-main-container">
                <div className="layout-main">
                    {/* <Suspense fallback={<div>Loading...</div>}> */}
                    <Routes>
                        {defaultRoutes.map((route, index) => {
                            if (route?.name === "dashboard" || route?.name === "chart") {
                                return <Route exact path={route.path} key={index} element={<route.element colorMode={layoutColorMode} location={location} loggedInUserData={getUserQuery?.data?.data} />} />;
                            } else {
                                return <Route exact path={route.path} key={index} element={<route.element location={location} loggedInUserData={getUserQuery?.data?.data} />} />;
                            }
                        })}

                        {/* <Route path="*" element={ <Navigate to="/dashboard" />} /> */}

                        {/* <Route path="/login" element={<NewLoginPage getUserLoggedInUserDataQuery={getUserLoggedInUserDataQuery} setUserId={setUserId} setAuthUserProfile={setAuthUserProfile} authUserProfile={authUserProfile} />} />
                            <Route path="/signup" element={<RegistrationPage />} />
                            <Route path="403" element={<NotAuthorised />} />
                            <Route path="*" element={<PageNotFound />} /> */}
                    </Routes>
                    {/* </Suspense> */}
                </div>

                <AppFooter layoutColorMode={layoutColorMode} />
            </div>

            <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>
        </div>
    );
};

export default AdminLayout;
