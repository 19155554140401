import React, { useState, useRef } from "react";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import moment from "moment";
import { Image } from "primereact/image";
import DepartmentReportPrint from "./DepartmentReportPrint";
import { useReactToPrint } from "react-to-print";
import { Button } from "primereact/button";
import PrinterOutlined from "@mui/icons-material/PrintOutlined";
import { ProgressBar } from "primereact/progressbar";

function DepartmentReportPdfModal({ departmentReport, ...props }) {
    console.log("department reportbnvmdncvds : ", departmentReport);
    const componentRef = useRef();

    // Pagination state for each table
    const [budgetOutputPage, setBudgetOutputPage] = useState(0);
    const [departmentOutcomePage, setDepartmentOutcomePage] = useState(0);
    const [subprojectPage, setSubprojectPage] = useState(0);

    const rowsPerPage = 5; // Number of rows per page
    // Paginator template
    const paginatorTemplate = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown";
    const currentPageReportTemplate = "Showing {first} to {last} of {totalRecords} entries";

    // Helper function to format currency
    const formatCurrency = (value) => {
        return new Intl.NumberFormat("en-US", { style: "currency", currency: "UGX" }).format(value);
    };

    // Helper function to format date
    const formatDate = (dateString) => {
        return moment(dateString).format("MMMM Do YYYY, h:mm:ss a");
    };

    const imageBodyTemplate = (rowData) => {
        return <Image src={`${process.env.REACT_APP_API_BASE_URL}${rowData.photo_url}`} alt={rowData.caption} width="100" preview />;
    };

    // Function to display status with color
    const statusWithColor = (status) => {
        let color;
        switch (status) {
            case "pending approval":
                color = "blue";
                break;
            case "approved":
                color = "green";
                break;
            case "pending":
                color = "orange";
                break;
            case "rejected":
                color = "red";
                break;
            default:
                color = "grey";
        }
        // return <span style={{ color }}>{status?.charAt(0)?.toUpperCase() + status?.slice(1)}</span>;
        return <span style={{ color }}>{status}</span>;
    };

    const gridStyle = {
        display: "grid",
        gridTemplateColumns: "1fr 1fr", // This creates two columns of equal width
        gridGap: "1rem", // Space between the columns
    };

    const riskTypeBodyTemplate = (rowData) => {
        let color;
        switch (rowData.risk_type) {
            case "high":
                color = "red";
                break;
            case "moderate":
                color = "orange";
                break;
            case "low":
                color = "grey";
                break;
            default:
                color = "black";
        }

        return (
            <div>
                <span className="p-mr-2" style={{ fontSize: "1.5rem", color, fontWeight: "bold" }}>
                    •
                </span>
                {/* {rowData.risk_type.charAt(0).toUpperCase() + rowData.risk_type.slice(1)} */}
                {rowData.risk_type}
            </div>
        );
    };

    //     const handlePrint = useReactToPrint({
    //         content: () => componentRef.current,
    //         pageStyle: `
    //     @page { size: A4; margin: 1cm; }
    //     @media print {
    //       body { -webkit-print-color-adjust: exact; }
    //       div#print-content { height: 100%; }
    //     }
    //   `,
    //     });
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Report", // Set the document title as a string
        pageStyle: `
    @page { size: A4; margin: 1cm; }
    @media print {
      body { -webkit-print-color-adjust: exact; }
      div#print-content { height: 100%; }
      .header, .footer { background-color: transparent !important; } // Assuming .header and .footer are the classes for your header and footer
      img { display: block; margin: 0 auto; } // Center the image, if necessary
    }
  `,
    });

    //
    // const formatCurrency = (value) => {
    //     if (value === null || value === undefined) return "";
    //     return value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    // };

    return (
        <Dialog header={<div style={{ color: "green" }}>Department Report.</div>} visible={props.show} style={{ minWidth: "80vw" }} onHide={props.onHide} maximizable>
            {/* Project Details */}
            <Card title="Department Details" style={{ marginBottom: "1rem" }}>
                <div style={gridStyle}>
                    {/* Display Program and Directorate Details */}
                    {departmentReport?.department?.program_directorate_department?.map((pdd, index) => {
                        const programName = pdd?.program_directorate?.program?.name || "N/A";
                        const directorateName = pdd?.program_directorate?.directorate?.name || "N/A";
                        const uniqueKey = `pdd-${pdd?.program_directorate.program?.id}-${pdd?.program_directorate.directorate?.id}-${index}`;

                        return (
                            <p key={uniqueKey}>
                                <p>
                                    <strong>Program:</strong> {programName}
                                </p>
                                <p>
                                    <strong>Directorate:</strong> {directorateName}
                                </p>
                            </p>
                        );
                    })}
                    <p>
                        <p>
                            <strong>Department Name:</strong> {departmentReport.department.name}
                        </p>

                        <p>
                            <strong>Department Code:</strong> {departmentReport.department.code}
                        </p>
                    </p>

                    {/* <p>
                        <strong>Released Funds:</strong> {formatCurrency(departmentReport?.released_funds)}
                    </p> */}
                    <p>
                        <strong>Finacial Year:</strong> {departmentReport?.financial_year?.name}
                    </p>
                    <p>
                        <strong>Quarter:</strong> {departmentReport?.quarter?.name}
                    </p>
                    <p>
                        <strong>Description:</strong> {departmentReport?.description}
                    </p>
                </div>
            </Card>

            <Card title="Budget Details" style={{ marginBottom: "1rem" }}>
                <div style={gridStyle}>
                    <p>
                        <strong>Released Funds:</strong> {formatCurrency(departmentReport?.approved_budget)}
                    </p>
                    <p>
                        <strong>Spent Funds:</strong> {formatCurrency(departmentReport?.spent_funds)}
                    </p>
                    {/* <p>
                        <strong>Released Funds:</strong> {formatCurrency(departmentReport?.released_funds)}
                    </p> */}
                    <p>
                        <strong>Absorption Rate:</strong> {departmentReport?.absorption_rate}%
                    </p>
                </div>
            </Card>

            {/* Department Outcomes */}
            {departmentReport.dept_report_department_outcomes.map((outcome, index) => (
                <Card key={index} title={`Department Outcome: ${outcome.department_outcome.outcome.name}`} style={{ marginBottom: "1rem" }}>
                    <DataTable value={outcome.dept_report_dept_outcome_indicator_target_actuals} paginator paginatorTemplate={paginatorTemplate} currentPageReportTemplate={currentPageReportTemplate} rows={rowsPerPage} first={departmentOutcomePage} onPage={(e) => setDepartmentOutcomePage(e.first)}>
                        <Column field="dept_outcomes_indicator_target.department_outcome_indicator.outcome_indicator.name" header="Outcome Indicator Name"></Column>
                        {/* <Column field="dept_outcomes_indicator_target.department_outcome_indicator.outcome_indicator.code" header="Outcome Indicator Code"></Column> */}

                        <Column field="dept_outcomes_indicator_target.base_year" header="Base Year"></Column>
                        <Column
                            field="dept_outcomes_indicator_target.base_value"
                            header="Base Value"
                            body={(rowData, column) => {
                                console.log("Dept Table Row data : ", rowData);
                                // Extract the unit from rowData
                                let unit = rowData.dept_outcomes_indicator_target.department_outcome_indicator.outcome_indicator.unit;
                                return `${parseInt(rowData.dept_outcomes_indicator_target.base_value) || 0} ${unit}`;
                            }}
                        ></Column>
                        <Column field="dept_outcomes_indicator_target.target_year" header="Target Year"></Column>
                        <Column
                            field="dept_outcomes_indicator_target.target_value"
                            header="Target Value"
                            body={(rowData, column) => {
                                // Extract the unit from rowData
                                let unit = rowData.dept_outcomes_indicator_target.department_outcome_indicator.outcome_indicator.unit;
                                return `${parseInt(rowData.dept_outcomes_indicator_target.target_value) || 0} ${unit}`;
                            }}
                        ></Column>
                        <Column field="dept_outcomes_indicator_target.financial_year.name" header="Financial Year"></Column>
                        <Column
                            field="dept_outcomes_indicator_target.financial_year_target"
                            header="Financial Year Target"
                            body={(rowData, column) => {
                                // Extract the unit from rowData
                                let unit = rowData.dept_outcomes_indicator_target.department_outcome_indicator.outcome_indicator.unit;
                                return `${parseInt(rowData.dept_outcomes_indicator_target.financial_year_target) || 0} ${unit}`;
                            }}
                        ></Column>
                        {/* <Column
                            field="value"
                            header="Value"
                            body={(rowData, column) => {
                                // Extract the unit from rowData
                                let unit = rowData.dept_outcomes_indicator_target.department_outcome_indicator.outcome_indicator.unit;
                                return `${parseInt(rowData[column.field]) || 0}${unit}`;
                            }}
                        ></Column> */}

                        <Column
                            field="value"
                            header="Value"
                            body={(rowData, column) => {
                                // Initialize a variable to store the final value
                                let finalValue;
                                let unit = rowData.dept_outcomes_indicator_target.department_outcome_indicator.outcome_indicator.unit; // Extract the unit for use in this column as well

                                // Check if manual value should be used based on 'show_manual_value' field from 'department_outcome_indicator_target'
                                if (rowData?.dept_outcomes_indicator_target?.show_manual_value) {
                                    // Use the manual value and store in finalValue
                                    finalValue = `${parseInt(rowData?.uncomputed_value) || 0} ${unit}`;
                                } else {
                                    // Otherwise, use the target value and store in finalValue
                                    finalValue = `${parseInt(rowData[column.field]) || 0} ${unit}`;
                                }

                                // Return the finalValue
                                return finalValue;
                            }}
                        ></Column>

                        {/* <Column field="numerator_value" header="Numerator"></Column>
            <Column field="denominator_value" header="Denominator"></Column> */}
                        {/* <Column field="census_name" header="Census"></Column> */}
                        {/* <Column field="assessment" header="Assessment"></Column>
            <Column field="reasonOfVariance" header="Reason of Variance"></Column> */}
                        {/* Other columns as necessary */}
                    </DataTable>
                </Card>
            ))}

            {/* Budget Outputs */}
            {departmentReport.dept_reports_budget_outputs.map((output, index) => (
                <Card key={index} title={`Budget Output: ${output.department_budget_outputs.name}`} style={{ marginBottom: "1rem" }}>
                    <DataTable value={output.dept_report_output_indicator_actuals} paginator paginatorTemplate={paginatorTemplate} currentPageReportTemplate={currentPageReportTemplate} rows={rowsPerPage} first={budgetOutputPage} onPageChange={(e) => setBudgetOutputPage(e.first)}>
                        {/* <Column field="department_budget_output_indicator.output_indicator.code" header="Indicator Code"></Column> */}
                        <Column field="department_budget_output_indicator.output_indicator.name" header="Indicator Name"></Column>
                        <Column field="department_budget_output_indicator.output_indicator.unit" header="Units"></Column>

                        {/* <Column field="department_budget_output_indicator.output_indicator.multiplier" header="Indicator Multiplier"></Column> */}
                        {/* <Column
                            field="project_department_budget_output_indicator_target.financial_year.name"
                            header="Financial Year"
                            // headerStyle={{ backgroundColor: "orange", color: "white" }}
                            // style={{ whiteSpace: "nowrap" }} // Add this line
                        ></Column>
                        <Column field="project_department_budget_output_indicator_target.quarter.name" header="Quarter"></Column> */}
                        <Column
                            field="total_target"
                            header="Target"
                            body={(rowData, column) => {
                                return parseInt(rowData.total_target);
                            }}
                        ></Column>
                        <Column
                            field="value"
                            header="Value"
                            body={(rowData, column) => {
                                return parseInt(rowData.value);
                            }}
                        ></Column>
                        <Column field="assessment" header="Assessment"></Column>
                        <Column field="reasonOfVariance" header="Reason of Variance"></Column>
                        {/* Other columns as necessary */}
                    </DataTable>
                </Card>
            ))}

            {/* Subprojects */}
            <Card title="Projects" style={{ marginBottom: "1rem" }}>
                <DataTable value={departmentReport.project_reports_data} paginator paginatorTemplate={paginatorTemplate} currentPageReportTemplate={currentPageReportTemplate} rows={rowsPerPage} first={subprojectPage} onPageChange={(e) => setSubprojectPage(e.first)}>
                    <Column field="project_reports.project.project_title" header="Project Name"></Column>
                    <Column field="project_reports.project.project_code" header="Project Code"></Column>
                    <Column field="project_reports.project.start_date" header="Start Date" body={(rowData) => formatDate(rowData.project_reports.project.start_date)} />
                    <Column field="project_reports.project.proposed_end_date" header="Proposed End Date" body={(rowData) => formatDate(rowData.project_reports.project.proposed_end_date)} />
                    <Column field="project_reports.project.completion_status" header="Completion Status"></Column>
                    <Column
                        field="project_reports.project.completion"
                        header="Completion"
                        body={(rowData) => (
                            <div>
                                {rowData.project_reports.project.completion}% <ProgressBar value={rowData.project_reports.project.completion} showValue={true} />
                            </div>
                        )}
                    />
                    <Column field="project_reports.approved_budget" header="Approved Budget" body={(rowData) => formatCurrency(rowData.project_reports.approved_budget)} />
                    <Column field="project_reports.spent_funds" header="Spent Funds" body={(rowData) => formatCurrency(rowData.project_reports.spent_funds)} />
                    <Column field="project_reports.absorption_rate" header="Arbsorption Rate" body={(rowData) => <div>{rowData.project_reports.absorption_rate}%</div>}></Column>

                    {/* Other columns as necessary */}
                </DataTable>
            </Card>

            {departmentReport?.risks_mitigation && (
                <Card title="Risks and Mitigations" style={{ marginBottom: "1rem" }}>
                    <DataTable value={departmentReport.risks_mitigation}>
                        <Column field="risk" header="Risk"></Column>
                        <Column field="risk_type" header="Risk Type" body={riskTypeBodyTemplate}></Column>
                        <Column field="mitigation" header="Mitigation"></Column>
                        {/* <Column field="created_at" header="Created At" body={formatDate}></Column> */}
                    </DataTable>
                </Card>
            )}

            {departmentReport?.challenge_recommendations && (
                <Card title="Challenges and Recommendations" style={{ marginBottom: "1rem" }}>
                    <DataTable value={departmentReport.challenge_recommendations}>
                        <Column field="challenges" header="Challenges"></Column>
                        <Column field="recommendations" header="Recommendations"></Column>
                        {/* <Column field="created_at" header="Created At" body={formatDate}></Column> */}
                    </DataTable>
                </Card>
            )}

            {/* Display Photos */}
            <Card title="Photos">
                <DataTable value={departmentReport?.photos}>
                    <Column header="Image" body={imageBodyTemplate}></Column>
                    <Column field="caption" header="Caption"></Column>
                </DataTable>
            </Card>

            {/* Created By and Updated By Information */}
            <p>
                <strong>Created By:</strong> {departmentReport?.created_by_user?.name} at {formatDate(departmentReport?.created_at)}
            </p>
            {/* <p>
                <strong>Updated By:</strong> {departmentReport?.updated_by_user?.name} at {formatDate(departmentReport?.updated_at)}
            </p> */}

            {/* Print Button */}

            <Button label="Print " icon={<PrinterOutlined />} onClick={handlePrint} className="p-button-primary" />

            {/* Invisible component for printing */}
            <div style={{ display: "none" }}>
                <div ref={componentRef}>
                    <DepartmentReportPrint departmentReport={departmentReport} />
                </div>
            </div>
        </Dialog>
    );
}

export default DepartmentReportPdfModal;
