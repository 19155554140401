import axiosAPI from "../axiosApi";

export async function getAllProjectBudgetOutputIndicatorTargets(params = {}) {
    const response = await axiosAPI.get("projects-budget-output-targets", { params: params });
    return response;
}

export async function getProjectBudgetOutputIndicatorTargetById(id) {
    const response = await axiosAPI.get(`projects-budget-output-targets/${id}`);
    return response;
}

export async function postProjectBudgetOutputIndicatorTarget(data) {
    const response = await axiosAPI.post(`projects-budget-output-targets`, data);
    return response;
}

export async function updateProjectBudgetOutputIndicatorTarget(id, data) {
    const response = await axiosAPI.put(`projects-budget-output-targets/${id}`, data);
    return response;
}

export async function deleteProjectBudgetOutputIndicatorTargetById(id) {
    const response = await axiosAPI.delete(`projects-budget-output-targets/${id}`);
    return response;
}
