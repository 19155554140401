import axiosAPI from "../axiosApi";

export async function getAllSubProjects(params = {}) {
    const response = await axiosAPI.get("sub-projects", { params: params });
    return response;
}

export async function getAllSubProjectsUnderUserProjectsByProjectIdOrRole() {
    const response = await axiosAPI.get("getAllSubProjectsUnderUserProjectsByProjectIdOrRole");
    return response;
}

export async function countAllSubProjects() {
    const response = await axiosAPI.get("count-sub-projects");
    return response;
}
export async function subProjectTotalContractAmount() {
    const response = await axiosAPI.get("subprojects-total-contract-amount");
    return response;
}

export async function subProjectPercentageFundedByGovernment() {
    const response = await axiosAPI.get("subprojects-percentage-funded-by-govt");
    return response;
}

export async function getSubProjectById(id) {
    const response = await axiosAPI.get(`sub-projects/${id}`);
    return response;
}

export async function createSubProject(data) {
    const response = await axiosAPI.post(`sub-projects`, data);
    return response;
}

export async function updateSubProject(id, data) {
    const response = await axiosAPI.put(`sub-projects/${id}`, data);
    return response;
}

export async function deleteSubProjectById(id) {
    const response = await axiosAPI.delete(`sub-projects/${id}`);
    return response;
}
// filter-subprojects
export async function filterAndGetAllSubProjects(params) {
    const response = await axiosAPI.post("filter-subprojects", params);
    return response.data;
}

export async function uploadSubProjectReport(image) {
    const formData = new FormData();

    formData.append("image", image);
    const response = await axiosAPI.post(`/sub-project-reports`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function getSubProjectReports(id) {
    const response = await axiosAPI.get(`/sub-project-reports/${id}`);
    return response;
}

export async function getSubProjectWithAllDetailsByProjectId(id) {
    const response = await axiosAPI.get(`/sub-projects-with-all-details/${id}`);
    return response;
}

export async function getSubProjectWithAllDetailsByDepartmentId(id) {
    const response = await axiosAPI.get(`/sub-projects-with-all-details-by-departmentId/${id}`);
    return response;
}

export async function getApprovedSubProjectsBySubProjectFinancialReport(data) {
    const response = await axiosAPI.post(`getApprovedSubProjectsBySubProjectFinancialReport`, data);
    return response;
}

//dashboard filters

export async function getSubProjectWithinSpecificPeriod(data) {
    const response = await axiosAPI.post(`getSubProjectWithinSpecificPeriod`, data);
    return response;
}

export async function filtersubprojectsbylocation(data) {
    const response = await axiosAPI.post(`filtersubprojectsbylocation`, data);
    return response;
}

export async function getAOutComesIndicatorsAndBudgetOutputIndicatorsFromSubProject(params = {}) {
    const response = await axiosAPI.get("getSubprojectOutComesAndBudgetOutputIndicators", { params: params });
    return response;
}

export async function postSubprojectOutComesAndBudgetOutputIndicators(data) {
    const response = await axiosAPI.post(`postSubprojectOutComesAndBudgetOutputIndicators`, data);
    return response;
}
