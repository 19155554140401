import React, { useMemo, memo } from "react";
import Lottie from "lottie-react";

// Import dashboard pages
import RuralDashboardPage from "./rural-dashboard/RuralDashboardPage.jsx";
import UrbanDashboardPage from "./urban-dashboard/UrbanDashboardPage";
import AdminDasboardPage from "./admin-dasboard/AdminDasboardPage.jsx";
// import WaterUmbrellaUtilitiesDashboardPage from "./water-umbrella-utilities/WaterUmbrellaUtilitiesDashboardPage";
import UmbrellaUtilitiesDashboardPage from "./water-umbrella-utilities/UmbrellaUtilitiesDashboardPage.jsx";
import WaterForProductionDashboardPage from "./water-for-production/WaterForProductionDashboardPage";
import PlanningAndPolicyDasboardPage from "./planning-and-policy/PlanningAndPolicyDasboardPage";

// Import animations
import NoDataManSittingAnimation from "./assets/NoDataManSittingAnimation - 1702466708619.json";
import NoDataManStandingAnimation from "./assets/NoDataManStandingAnimation - 1702466565785.json";

// Memoize dashboard components
const MemoizedRuralDashboardPage = memo(RuralDashboardPage);
const MemoizedUrbanDashboardPage = memo(UrbanDashboardPage);
const MemoizedAdminDashboardPage = memo(AdminDasboardPage);
const MemoizedWaterUmbrellaUtilitiesDashboardPage = memo(UmbrellaUtilitiesDashboardPage);
const MemoizedWaterForProductionDashboardPage = memo(WaterForProductionDashboardPage);
const MemoizedPlanningAndPolicyDasboardPage = memo(PlanningAndPolicyDasboardPage);

function Dashboard({ loggedInUserData, ...props }) {
    const dashboardComponent = useMemo(() => {
        const adminRoles = ["admin", "superadmin"];
        const isAdminRole = loggedInUserData?.role && adminRoles.includes(String(loggedInUserData.role).toLowerCase());
        const departmentCode = String(loggedInUserData?.user_department?.code).toLowerCase();

        if (isAdminRole) {
            return <MemoizedAdminDashboardPage loggedInUserData={loggedInUserData} {...props} />;
        } else if (departmentCode === "01") {
            return <MemoizedRuralDashboardPage loggedInUserData={loggedInUserData} {...props} />;
        } else if (departmentCode === "02") {
            return <MemoizedUrbanDashboardPage loggedInUserData={loggedInUserData} {...props} />;
        } else if (departmentCode === "03") {
            return <MemoizedWaterUmbrellaUtilitiesDashboardPage loggedInUserData={loggedInUserData} {...props} />;
        } else if (departmentCode === "04") {
            return <MemoizedWaterForProductionDashboardPage loggedInUserData={loggedInUserData} {...props} />;
        } else if (departmentCode === "05") {
            return <MemoizedPlanningAndPolicyDasboardPage loggedInUserData={loggedInUserData} {...props} />;
        } else {
            return (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                    <Lottie animationData={NoDataManStandingAnimation} loop={true} autoplay={true} style={{ width: 300 }} />
                </div>
            );
        }
    }, [loggedInUserData, props]);

    return dashboardComponent;
}

export default Dashboard;
