import axiosAPI from "../axiosApi";

export async function getAllSubprojectBgtOutputIndicatorTargets(params = {}) {
    const response = await axiosAPI.get("subproject-output-targets", { params: params });
    return response;
}

export async function getSubprojectBgtOutputIndicatorTargetById(id) {
    const response = await axiosAPI.get(`subproject-output-targets/${id}`);
    return response;
}

export async function postSubprojectBgtOutputIndicatorTarget(data) {
    const response = await axiosAPI.post(`subproject-output-targets`, data);
    return response;
}

export async function updateSubprojectBgtOutputIndicatorTarget(id, data) {
    const response = await axiosAPI.put(`subproject-output-targets/${id}`, data);
    return response;
}

export async function deleteSubprojectBgtOutputIndicatorTargetById(id) {
    const response = await axiosAPI.delete(`subproject-output-targets/${id}`);
    return response;
}
