import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import { InputSwitch } from "primereact/inputswitch";

import { Dropdown } from "primereact/dropdown";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { classNames } from "primereact/utils";

import { getAllDirectorates } from "../../../../../services/departments/directorate-service";
import setFieldTouched from "final-form-set-field-touched";
import { getAllOutcomeIndicatorTypes, getOutcomeIndicatorTypeById, postOutcomeIndicatorType, updateOutcomeIndicatorType, deleteOutcomeIndicatorTypeById } from "../../../../../services/directorates/outcome-indicator-types-service.js";

function RowForm({ handleSubmit, initialData, selectedDirectorateItem, ...props }) {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);
    const queryClient = useQueryClient();

    const validate = (values) => {
        const errors = {};

        if (!values.name) errors.name = "Name is required";
        if (!values.code) errors.code = "Code is required";
        if (!values.outcome_indicator_types_id) errors.outcome_indicator_types_id = "Indicator Type is required";
        if (!values.measure) errors.measure = "measure is required";
        if (!values.definition) errors.definition = "Indicator definition is required";
        if (!values.directorate_id) errors.directorate_id = "directorate is required";
        if (!values.unit) errors.unit = "Unit is required";

        // Add validations for new fields if necessary
        // if (values.show_manual_value) {
        //     if (!values.manual_numerator) errors.manual_numerator = "Manual numerator is required";
        //     if (!values.manual_denominator) errors.manual_denominator = "Manual denominator is required";
        // }

        return errors;
    };

    // const onSubmitForm = (data) => {
    //     const errors = validate(data);
    //     if (Object.keys(errors).length === 0) {
    //         // No validation errors
    //         setPendingData(data);
    //         setShowConfirmDialog(true);
    //     } else {
    //         toast.warning("First Fill In All Required Fields");
    //     }
    // };

    const onSubmitForm = (data, form) => {
        // Add 'form' as an argument
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            // Mark all fields as touched to show validation errors
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("First fill in all required fields.");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    if (selectedDirectorateItem) {
        initialData = { directorate_id: selectedDirectorateItem?.id, ...initialData };
    }

    //==================== directorates =========================
    const directorates = useQuery(["directorates"], getAllDirectorates);

    let dropdownDirectorates = directorates?.data?.data?.data?.map((item) => ({ label: item.name, value: item.id }));

    //==================== ountcome indicator types =========================
    const outcomeIndicatorTypesQuery = useQuery(["outcome-indicator-types"], getAllOutcomeIndicatorTypes);

    let dropdownOutcomeIndicatorTypes = outcomeIndicatorTypesQuery?.data?.data?.data?.map((item) => ({ label: item.name, value: item.id }));

    if (!initialData?.show_manual_value) {
        initialData = { show_manual_value: false, ...initialData };
    }

    console.log("outcome inodicator inistial data : ", initialData);

    // initialData = { show_manual_value: true, ...initialData };
    return (
        <div className="col-12 md:col-12">
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    mutators={{ setFieldTouched }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                console.log("values hhh : ", values);
                                console.log("event fffff : ", event);
                                onSubmitForm(values, form);
                                // handleSubmit(event, values);
                            }}
                        >
                            <Field name="name">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="name">Name</label>
                                        <InputText {...input} id="name" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="code">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="code">Code</label>
                                        <InputText {...input} id="code" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="outcome_indicator_types_id">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="outcome_indicator_types_id">Type</label>
                                        <Dropdown {...input} options={dropdownOutcomeIndicatorTypes} id="outcome_indicator_types_id" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <div style={{ marginBottom: "1rem" }}>
                                <Field name="measure">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Measure</label>
                                            <Dropdown
                                                value={input.value}
                                                options={[
                                                    { label: "Qualitative", value: "qualitative" },
                                                    { label: "Quantitative", value: "quantitative" },
                                                ]}
                                                onChange={(e) => input.onChange(e.value)}
                                                placeholder="Select Type"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <Field name="unit">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="unit">Unit</label>
                                        <InputText {...input} id="unit" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="frequency">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="frequency">Frequency</label>
                                        <InputText {...input} id="frequency" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            {/* definition */}
                            <Field name="definition">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="definition">Indicator Definition</label>
                                        <InputTextarea {...input} id="definition" rows={5} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="computaion_method">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="computaion_method">Computation Method</label>
                                        <InputTextarea {...input} id="computaion_method" rows={5} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="data_source">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="data_source">Data Source</label>
                                        <InputTextarea {...input} id="data_source" rows={5} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>
                            <Field name="numerator">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="numerator">Numerator</label>
                                        <InputTextarea {...input} id="numerator" rows={5} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>
                            <Field name="denominator">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="denominator">Denominator</label>
                                        <InputTextarea {...input} id="denominator" rows={5} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            {/* show_manual_value switch */}

                            <Field name="show_manual_value">
                                {({ input }) => (
                                    <div className="p-field-checkbox m-4">
                                        <InputSwitch
                                            {...input}
                                            checked={!!input.value}
                                            onChange={(e) => {
                                                input.onChange(e.value);
                                                // if (!e.value) {
                                                //     // If the switch is turned off, reset the manual fields
                                                //     form.change("manual_numerator", null);
                                                //     form.change("manual_denominator", null);
                                                // }
                                            }}
                                        />
                                        <label htmlFor="Not Computed" style={{ marginLeft: "1rem" }}>
                                            Not Computed
                                        </label>
                                    </div>
                                )}
                            </Field>

                            {/* Conditionally render manual_numerator and manual_denominator */}
                            {/* {values.show_manual_value && (
                                <>
                                    <Field name="manual_numerator">
                                        {({ input, meta }) => (
                                            <div className="p-field m-4">
                                                <label htmlFor="manual_numerator">Manual Numerator</label>
                                                <InputText {...input} id="manual_numerator" />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                            </div>
                                        )}
                                    </Field>

                                    <Field name="manual_denominator">
                                        {({ input, meta }) => (
                                            <div className="p-field m-4">
                                                <label htmlFor="manual_denominator">Manual Denominator</label>
                                                <InputText {...input} id="manual_denominator" />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                            </div>
                                        )}
                                    </Field>
                                </>
                            )} */}

                            <Field name="directorate_id">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="directorate_id">Directorate</label>
                                        <Dropdown {...input} options={dropdownDirectorates} id="directorate_id" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <div className="d-grid gap-2">
                                <Button type="submit" label="Save" className="p-button-primary" icon="pi pi-check" />
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    style={{ width: "30vw" }}
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to submit?
                </Dialog>
            </div>
        </div>
    );
}

export default RowForm;
