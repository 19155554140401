import axiosAPI from "../axiosApi";

export async function getAllProjectBudgets(params = {}) {
    const response = await axiosAPI.get("projects-budget", { params: params });
    return response;
}

export async function getProjectBudgetById(id) {
    const response = await axiosAPI.get(`projects-budget/${id}`);
    return response;
}

export async function postProjectBudget(data) {
    const response = await axiosAPI.post(`projects-budget`, data);
    return response;
}

export async function updateProjectBudget(id, data) {
    const response = await axiosAPI.put(`projects-budget/${id}`, data);
    return response;
}

export async function deleteProjectBudgetById(id) {
    const response = await axiosAPI.delete(`projects-budget/${id}`);
    return response;
}
