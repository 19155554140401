// DepartmentReportPrint.js
import React from "react";
import moment from "moment";
import { ProgressBar } from "primereact/progressbar";

const DepartmentReportPrint = ({ departmentReport, ref }) => {
    // Helper function to format currency
    const formatCurrency = (value) => {
        return new Intl.NumberFormat("en-US", { style: "currency", currency: "UGX" }).format(value);
    };

    // Helper function to format date
    const formatDate = (dateString) => {
        return moment(dateString).format("MMMM Do YYYY, h:mm:ss a");
    };

    // Inline styles
    const tableHeaderStyle = {
        backgroundColor: "#00B9E8",
        color: "white",
        padding: "10px",
        textAlign: "left",
    };

    const tableCellStyle = {
        padding: "10px",
    };

    const cardStyle = {
        marginBottom: "20px",
        padding: "15px",
        border: "1px solid #ddd",
        borderRadius: "5px",
    };

    const cardTitleStyle = {
        color: "#00B9E8",
        fontWeight: "bold",
        marginBottom: "10px",
    };

    // Inline styles
    const tableStyle = {
        width: "100%",
        marginBottom: "20px",
        borderCollapse: "collapse",
    };

    const thStyle = {
        backgroundColor: "#00B9E8",
        color: "white",
        padding: "10px",
        textAlign: "left",
        border: "1px solid #ddd",
    };

    const tdStyle = {
        padding: "10px",
        border: "1px solid #ddd",
    };

    // Component JSX
    return (
        <div ref={ref} style={{ backgroundColor: "white" }}>
            {/* Ministry Logo */}
            <div style={{ display: "flex", gap: "1rem", textAlign: "center", alignItems: "center", justifyContent: "center", margin: "1rem", width: "100%" }}>
                <img src="/images/coatOfArms.png" alt="Coat of Arms of Uganda" style={{ width: "20px", height: "auto" }} />{" "}
                <span className="font-medium ml-2">
                    <span>Ministry of Water and Environment</span> <br /> <span>M & E Portal Department Report</span>
                </span>
            </div>
            {/* Department Details */}
            <div style={cardStyle}>
                <div style={cardTitleStyle}>Department Details</div>
                {departmentReport?.department?.program_directorate_department?.map((pdd, index) => {
                    const programName = pdd?.program_directorate?.program?.name || "N/A";
                    const directorateName = pdd?.program_directorate?.directorate?.name || "N/A";
                    const uniqueKey = `pdd-${pdd?.program_directorate?.program?.id}-${pdd?.program_directorate?.directorate?.id}-${index}`;

                    return (
                        <div key={uniqueKey}>
                            <p>
                                <strong>Program:</strong> {programName}
                            </p>
                            <p>
                                <strong>Directorate:</strong> {directorateName}
                            </p>
                        </div>
                    );
                })}
                <p>
                    <strong>Department Name:</strong> {departmentReport?.department?.name}
                </p>
                <p>
                    <strong>Department Code:</strong> {departmentReport?.department?.code}
                </p>
                <p>
                    <strong>Financial Year:</strong> {departmentReport?.financial_year?.name}
                </p>
                <p>
                    <strong>Quarter:</strong> {departmentReport?.quarter?.name}
                </p>
                <p>
                    <strong>Description:</strong> {departmentReport?.description}
                </p>
            </div>

            {/* Budget Details */}
            <div style={cardStyle}>
                <div style={cardTitleStyle}>Budget Details</div>
                <p>
                    <strong>Released Funds:</strong> {formatCurrency(departmentReport?.approved_budget)}
                </p>
                <p>
                    <strong>Spent Funds:</strong> {formatCurrency(departmentReport?.spent_funds)}
                </p>
                <p>
                    <strong>Absorption Rate:</strong> {departmentReport?.absorption_rate}%
                </p>
            </div>

            {/* Department Outcomes */}
            {departmentReport.dept_report_department_outcomes.map((outcome, index) => (
                <div key={index} style={cardStyle}>
                    <div style={cardTitleStyle}>Department Outcome: {outcome.department_outcome.outcome.name}</div>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={thStyle}>Outcome Indicator Name</th>
                                {/* <th style={thStyle}>Outcome Indicator Code</th> */}
                                <th style={thStyle}>Base Year</th>
                                <th style={thStyle}>Base Value</th>
                                <th style={thStyle}>Target Year</th>
                                <th style={thStyle}>Target Value</th>
                                <th style={thStyle}>Financial Year</th>
                                <th style={thStyle}>Financial Year Target</th>
                                <th style={thStyle}>Value</th>
                                {/* <th style={thStyle}>Census</th> */}
                                {/* Additional headers as needed */}
                            </tr>
                        </thead>
                        <tbody>
                            {outcome.dept_report_dept_outcome_indicator_target_actuals.map((indicator, idx) => {
                                let unit = indicator.dept_outcomes_indicator_target.department_outcome_indicator.outcome_indicator.unit;
                                return (
                                    <tr key={idx}>
                                        <td style={tdStyle}>{indicator.dept_outcomes_indicator_target.department_outcome_indicator.outcome_indicator.name}</td>
                                        {/* <td style={tdStyle}>{indicator.dept_outcomes_indicator_target.department_outcome_indicator.outcome_indicator.code}</td> */}
                                        <td style={tdStyle}>{indicator.dept_outcomes_indicator_target.base_year}</td>
                                        <td style={tdStyle}>{`${parseInt(indicator.dept_outcomes_indicator_target.base_value) || 0} ${unit}`}</td>
                                        <td style={tdStyle}>{indicator.dept_outcomes_indicator_target.target_year}</td>
                                        <td style={tdStyle}>{`${parseInt(indicator.dept_outcomes_indicator_target.target_value) || 0} ${unit}`}</td>
                                        <td style={tdStyle}>{indicator.dept_outcomes_indicator_target.financial_year.name}</td>
                                        <td style={tdStyle}>{`${parseInt(indicator.dept_outcomes_indicator_target.financial_year_target) || 0} ${unit}`}</td>
                                        {/* <td style={tdStyle}>{`${parseInt(indicator.value) || 0}${unit}`}</td> */}
                                        <td style={tdStyle}>
                                            {(() => {
                                                // Initialize a variable to store the final value
                                                let finalValue;

                                                // Check if manual value should be used
                                                if (indicator?.dept_outcomes_indicator_target?.show_manual_value) {
                                                    // Use the manual value and store in finalValue
                                                    finalValue = parseInt(indicator?.uncomputed_value) || 0;
                                                } else {
                                                    // Otherwise, use the target value and store in finalValue
                                                    finalValue = parseInt(indicator.value) || 0;
                                                }

                                                // Return the finalValue with a percentage sign
                                                return `${finalValue} ${unit}`;
                                            })()}
                                        </td>
                                        {/* <td style={tdStyle}>{indicator.census_name}</td> */}
                                        {/* Additional cells as needed */}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            ))}

            {/* Budget Outputs */}
            {/* Budget Outputs */}
            {departmentReport?.dept_reports_budget_outputs?.map((output, index) => (
                <div key={index} style={cardStyle}>
                    <div style={cardTitleStyle}>Budget Output: {output.department_budget_outputs?.name}</div>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                {/* <th style={thStyle}>Indicator Code</th> */}
                                <th style={thStyle}>Indicator Name</th>
                                <th style={thStyle}>Units</th>
                                {/* <th style={thStyle}>Indicator Multiplier</th> */}
                                <th style={thStyle}>Target</th>
                                <th style={thStyle}>Value</th>
                                <th style={thStyle}>Assessment</th>
                                <th style={thStyle}>Reason of Variance</th>
                                {/* Additional headers as needed */}
                            </tr>
                        </thead>
                        <tbody>
                            {output.dept_report_output_indicator_actuals?.map((indicator, indIndex) => (
                                <tr key={indIndex}>
                                    {/* <td style={tdStyle}>{indicator.department_budget_output_indicator?.output_indicator?.code}</td> */}
                                    <td style={tdStyle}>{indicator.department_budget_output_indicator?.output_indicator?.name}</td>
                                    <td style={tdStyle}>{indicator.department_budget_output_indicator?.output_indicator?.unit}</td>
                                    {/* <td style={tdStyle}>{indicator.department_budget_output_indicator?.output_indicator?.multiplier}</td> */}
                                    <td style={tdStyle}>{indicator.total_target ? `${parseInt(indicator.total_target)}` : "N/A"}</td>
                                    <td style={tdStyle}>{indicator.value ? `${parseInt(indicator.value)}` : "N/A"}</td>
                                    <td style={tdStyle}>{indicator.assessment || "N/A"}</td>
                                    <td style={tdStyle}>{indicator.reasonOfVariance || "N/A"}</td>
                                    {/* Additional cells as needed */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ))}

            {/* Projects */}
            {/* Projects */}
            {departmentReport?.project_reports_data?.length > 0 ? (
                <div style={cardStyle}>
                    <div style={cardTitleStyle}>Projects</div>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={thStyle}>Project Name</th>
                                <th style={thStyle}>Project Code</th>
                                <th style={thStyle}>Start Date</th>
                                <th style={thStyle}>Proposed End Date</th>
                                <th style={thStyle}>Completion Status</th>
                                <th style={thStyle}>Completion</th>
                                <th style={thStyle}>Approved Budget</th>
                                <th style={thStyle}>Spent Funds</th>
                                <th style={thStyle}>Absorption Rate</th>
                            </tr>
                        </thead>
                        <tbody>
                            {departmentReport.project_reports_data.map((project, index) => (
                                <tr key={index}>
                                    <td style={tdStyle}>{project.project_reports?.project?.project_title}</td>
                                    <td style={tdStyle}>{project.project_reports?.project?.project_code}</td>
                                    <td style={tdStyle}>{formatDate(project.project_reports?.project?.start_date)}</td>
                                    <td style={tdStyle}>{formatDate(project.project_reports?.project?.proposed_end_date)}</td>
                                    <td style={tdStyle}>{project.project_reports?.project?.completion_status}</td>
                                    <td style={tdStyle}>
                                        {project.project_reports?.project?.completion}%
                                        <ProgressBar value={project.project_reports?.project?.completion} showValue={true} />
                                    </td>
                                    <td style={tdStyle}>{formatCurrency(project.project_reports?.approved_budget)}</td>
                                    <td style={tdStyle}>{formatCurrency(project.project_reports?.spent_funds)}</td>
                                    <td style={tdStyle}>{project.project_reports?.absorption_rate}%</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div style={{ ...cardStyle, textAlign: "center" }}>
                    <p>No project reports available.</p>
                </div>
            )}

            {/* Photos */}
            {departmentReport?.photos?.map((photo, index) => (
                <div key={index} style={cardStyle}>
                    <div style={cardTitleStyle}>Photo</div>
                    <img src={`${process.env.REACT_APP_API_BASE_URL}${photo.photo_url}`} alt={photo.caption} style={{ maxWidth: "100%", marginBottom: "10px" }} />
                    <p>
                        <strong>Caption:</strong> {photo.caption}
                    </p>
                </div>
            ))}
            {/* Risks and Mitigations */}
            {/* Risks and Mitigations */}
            {departmentReport?.risks_mitigation?.length > 0 && (
                <div style={cardStyle}>
                    <div style={cardTitleStyle}>Risks and Mitigations</div>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={thStyle}>Risk</th>
                                <th style={thStyle}>Risk Type</th>
                                <th style={thStyle}>Mitigation</th>
                            </tr>
                        </thead>
                        <tbody>
                            {departmentReport.risks_mitigation.map((risk, index) => (
                                <tr key={index}>
                                    <td style={tdStyle}>{risk.risk}</td>
                                    <td style={tdStyle}>{risk.risk_type}</td>
                                    <td style={tdStyle}>{risk.mitigation}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {/* Challenges and Recommendations */}
            {/* Challenges and Recommendations */}
            {departmentReport?.challenges_recommendations?.length > 0 && (
                <div style={cardStyle}>
                    <div style={cardTitleStyle}>Challenges and Recommendations</div>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={thStyle}>Challenge</th>
                                <th style={thStyle}>Recommendation</th>
                            </tr>
                        </thead>
                        <tbody>
                            {departmentReport.challenges_recommendations.map((challenge, index) => (
                                <tr key={index}>
                                    <td style={tdStyle}>{challenge.challenges}</td>
                                    <td style={tdStyle}>{challenge.recommendations}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {/* Created By and Updated By Information */}
            {/* <div style={cardStyle}>
                <p>
                    <strong>Created By:</strong> {departmentReport?.created_by_user?.name} at {formatDate(departmentReport?.created_at)}
                </p> */}
            {/* <p>
                    <strong>Updated By:</strong> {departmentReport?.updated_by_user?.name} at {formatDate(departmentReport?.updated_at)}
                </p> */}
            {/* </div> */}
        </div>
    );
};

export default DepartmentReportPrint;
