import axiosAPI from "../axiosApi";

export async function getAllCurrencies(params = {}) {
    const response = await axiosAPI.get("currencies", { params: params });
    return response;
}

export async function getCurrenciesById(id) {
    const response = await axiosAPI.get(`currencies/${id}`);
    return response;
}

export async function postCurrencies(data) {
    const response = await axiosAPI.post(`currencies`, data);
    return response;
}

export async function updateCurrencies(id, data) {
    const response = await axiosAPI.put(`currencies/${id}`, data);
    return response;
}

export async function deleteCurrenciesById(id) {
    const response = await axiosAPI.delete(`currencies/${id}`);
    return response;
}
