import React, { useState, useEffect } from "react";

import { Dialog } from "primereact/dialog";

import { postToAggregateUtilityReportsForDasboard } from "../../../../../../services/departments/utility-dept/departments-utility-theme-report-service.js";

import RowForm from "./widgets/RowForm";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";

function FiltersForm({ currentDepartmentDetail, filterQueryIsLoading, setFilterQueryIsLoading, filterParams, filterSelectedObjects, setFilterSelectedObjects, setFilterParams, loggedInUserData, utilityIndicatorsData, setUtilityIndicatorsData, ...props }) {
    const [name, setName] = useState();
    const [details, setDetails] = useState();
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(true);
    const [validated, setValidated] = useState(true);

    const queryClient = useQueryClient();

    const filterMutation = useMutation(postToAggregateUtilityReportsForDasboard, {
        onSuccess: (data) => {
            // queryClient.invalidateQueries(["subprojects", "subproject-locations"]);
            handleOnSuccess(data);
            // props.onClose();
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
            console.log("create utility dashboard aggregaten sources error : ", error);
        },
    });

    //
    // Define the query
    const { isLoading, data, error } = useQuery(["aggregateUtilityReports"], () => postToAggregateUtilityReportsForDasboard(filterParams), {
        enabled: !!filterParams, // This line ensures the query won't run until filterParams are set
        onSuccess: (data) => {
            // toast.success("Filtered Successfully");
            console.log("utility dashboard aggregate data databdsfbdshjds : ", data?.data?.data);
            setUtilityIndicatorsData(data?.data?.data);
            setFilterQueryIsLoading(false);
        },
        onError: (error) => {
            error?.response?.data?.message ? toast.error(error.response.data.message) : toast.error("An Error Occurred Please Contact Admin");
        },
        keepPreviousData: false, // Optional: keeps previous data until new data is fetched
    });

    const handleOnSuccess = async (data) => {
        setFilterQueryIsLoading(false);
        toast.success("Filtered Succesfully");
        console.log("utility dashboard aggregate data databdsfbdshjds : ", data);
        setUtilityIndicatorsData(data?.data?.data);
    };

    // const handleSubmit = async (data) => {
    //     setFilterQueryIsLoading(true);
    //     // event.preventDefault();
    //     console.log("utility regulation db data we are submitting : ", data);
    //     setUtilityIndicatorsData(null);
    //     // setFilterParams(data);
    //     setFilterParams(data);
    //     filterMutation.mutate(data);
    // };

    const handleSubmit = (data) => {
        setFilterQueryIsLoading(true);
        setFilterParams(data); // This will trigger the useQuery to refetch with new parameters
    };

    return (
        <>
            <RowForm
                loggedInUserData={loggedInUserData}
                handleSubmit={handleSubmit}
                project_id={props?.projectId}
                selectedParentItem={props?.selectedParentItem}
                setFilterParams={setFilterParams}
                initialData={filterParams}
                filterSelectedObjects={filterSelectedObjects}
                setFilterSelectedObjects={setFilterSelectedObjects}
                currentDepartmentDetail={currentDepartmentDetail}
                filterQueryIsLaoding={isLoading}
            />
            {/* <h4>{creactProgramsMutation.status}</h4> */}
            {isLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px", // Border thickness
                            borderColor: "blue", // Border color
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
        </>
    );
}

export default FiltersForm;
