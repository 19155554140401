import React from "react";

export const AppFooter = (props) => {
    return (
        <div className="layout-footer">
            {/* <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/logo-dark.svg' : 'assets/layout/images/logo-white.svg'} alt="Logo" height="20" className="mr-2" /> */}
            <img src={props.layoutColorMode === "light" ? "/Coat_of_arms_of_Uganda.svg" : "/Coat_of_arms_of_Uganda.svg"} alt="Logo" height="20" className="mr-2" />

            {/* <span className="font-medium ml-2">Ministry of Water and Environment</span> */}

            <span className="font-medium ml-2">
                <span>Ministry of Water and Environment</span> <br /> <span>M & E Portal</span>
            </span>
        </div>
    );
};
