import axiosAPI from "../axiosApi";

export async function getAllProjectReports(params = {}) {
    const response = await axiosAPI.get("project-reports", { params: params });
    return response;
}

export async function getProjectReportById(id) {
    const response = await axiosAPI.get(`project-reports/${id}`);
    return response;
}

export async function postProjectReport(data) {
    const response = await axiosAPI.post(`storeProjectReports`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function updateProjectReport(id, data) {
    const response = await axiosAPI.put(`updateProjectReports/${id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function deleteProjectReportById(id) {
    const response = await axiosAPI.delete(`project-reports/${id}`);
    return response;
}

export async function updateProjectReportStatus(data) {
    const response = await axiosAPI.post("projectreport-update-status", data);
    return response;
}
