import axiosAPI from "../axiosApi";

export async function getAllDepartmentFunctions(params = {}) {
    const response = await axiosAPI.get("departmentFunctions", { params: params });
    return response;
}

export async function getDepartmentFunctionById(id) {
    const response = await axiosAPI.get(`departmentFunctions/${id}`);
    return response;
}

export async function postDepartmentFunction(data) {
    const response = await axiosAPI.post(`departmentFunctions`, data);
    return response;
}

export async function updateDepartmentFunction(id, data) {
    const response = await axiosAPI.put(`departmentFunctions/${id}`, data);
    return response;
}

export async function deleteDepartmentFunctionById(id) {
    const response = await axiosAPI.delete(`departmentFunctions/${id}`);
    return response;
}
