import React from "react";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import ProjectsRowForm from "./widgets/ProjectsRowForm";
import { createProject } from "../../../services/projects/project-service";

function ProjectsCreateForm(props) {
    const queryClient = useQueryClient();

    const createProjectsMutation = useMutation(createProject, {
        onSuccess: () => {
            queryClient.invalidateQueries(["projects"]);

            queryClient.invalidateQueries(["programs-hireachy"]);
            toast.success("Project Created Successfully");
            props.onHide();
        },
        onError: (error) => {
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
        },
    });

    const handleSubmit = (data) => {
        console.log("data we are submitting projects : ", data);
        createProjectsMutation.mutate(data);
    };

    return (
        <Dialog header="Projects Form" visible={props.show} onHide={props.onHide} maximizable>
            <p>Fill in the form below</p>
            <ProjectsRowForm selectedParentDetail={props?.selectedParentDetail} handleSubmit={handleSubmit} />
            {createProjectsMutation.isLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px", // Border thickness
                            borderColor: "blue", // Border color
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
        </Dialog>
    );
}

export default ProjectsCreateForm;
