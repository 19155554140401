import React, { useState, useEffect } from "react";
// import { Card } from "primereact/card";
import { Tree } from "primereact/tree";
import { Divider } from "primereact/divider";
import { useLocation } from "react-router-dom";
import { Panel } from "primereact/panel";
import BreadcrumbNav from "../../../components/mweComponents/BreadcrumbNav";
import { Button } from "primereact/button";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { Fieldset } from "primereact/fieldset";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { TabView, TabPanel } from "primereact/tabview";
// import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primereact/resources/themes/lara-light-blue/theme.css";
// import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import SubProjectsList from "../subprojects/SubProjectsList";
import DepartmentList from "../departments/DepartmentList";

import { deleteProjectById, getAllProjects, getAllProjectsById, getProjectsByProjectIdOrRole, getProjectsByDepartmentIdAndRole } from "../../../services/projects/project-service";
import { getAllDirectorates, deleteDirectorateById, getDirectorateById } from "../../../services/departments/directorate-service";
import { deleteDepartmentById, getAllDepartments, getDepartmentById } from "../../../services/departments/department-service";

// import DepartmentOutcomeIndicatorsPage from "./department-outcome-indicators/DepartmentOutcomeIndicatorsPage.js";
import DepartmentOutcomesPage from "./department-outcomes/DepartmentOutcomesPage";

import DepartmentBudgetOutPutsPage from "./department-budget-outputs/DepartmentBudgetOutPutsPage";
import ProjectsList from "../projects/ProjectsList";
import DepartmentBudgetPage from "./department-budget/DepartmentBudgetPage";
import DepartmentFunctionsPage from "./department-functions/DepartmentFunctionsPage";
import { ProgressBar } from "primereact/progressbar";
import DashboardPage from "./dashboard/DashboardPage";
import ProjectReportsList from "./approvals/ListPage";
import DepartmentReportsViewPage from "./department-reports/DepartmentReportsViewPage";
import DepartmentAdministrationPage from "./department-administration/DepartmentAdministrationPage.js";
import ProjectClustersPage from "./project-clusters/ProjectClustersPage.js";

//
import DepartmentThemesPage from "./utility-department/department-themes/DepartmentThemesPage";
import DepartmentTarrifsPage from "./utility-department/department-tarrifs/DepartmentTarrifsPage";
import DepartmentUtilitiesPage from "./utility-department/department-utilities/DepartmentUtilitiesPage";
import DeptReportsViewPage from "./utility-department/department-reports/DeptReportsViewPage";

//
import DepartmentIndicatorPage from "./department-indicators/DepartmentIndicatorPage";
import UtilitiesApprovalPage from "./utility-department/approvals/UtilitiesApprovalPage";

const DepartmentViewPage = ({ loggedInUserData }) => {
    const queryClient = useQueryClient();
    //
    let { state } = useLocation();
    let departmentData = state?.departmentData ? state?.departmentData : null;
    let componentDetailFromNavLink = state?.componentDetailFromNavLink ? state?.componentDetailFromNavLink : null;

    console.log("departmentData data got from state is : ", departmentData);
    console.log("loggedInUserData on departments : ", loggedInUserData);

    //
    let departmentViewPageActiveIndexFromUrlState = state?.subprojectViewPageActiveIndex ? state?.departmentViewPageActiveIndex : 0;
    let departmentViewPageChildActiveIndexFromUrlState = state?.subprojectViewPageChildActiveIndex ? state?.departmentViewPageChildActiveIndex : 0;

    //
    const [departmentViewPageActiveIndex, setDepartmentViewPageActiveIndex] = useState(departmentViewPageActiveIndexFromUrlState);
    const [departmentViewPageChildActiveIndex, setDepartmentViewPageChildActiveIndex] = useState(departmentViewPageChildActiveIndexFromUrlState);

    //===================== getDepartmentById by id =================
    const fetchDepartmentById = useQuery(
        ["departments", "getById", departmentData?.id ?? componentDetailFromNavLink?.details?.id ?? loggedInUserData?.user_department_id],
        () => getDepartmentById(departmentData?.id ?? componentDetailFromNavLink?.details?.id ?? loggedInUserData?.user_department_id),
        {
            onSuccess: (data) => {
                console.log("getDepartmentById onsuccess fetching : ", data);
            },
            onError: (error) => {
                // props.onClose();
                error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
                console.log("get list of projects : ", error);
            },
        }
    );

    //
    departmentData = departmentData ?? fetchDepartmentById?.data?.data;
    console.log("departmentDatavvvvvv : ", fetchDepartmentById?.data?.data);

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showBudjetOutPutAddForm, setShowBudjetOutPutAddForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    const onTabChange = (e) => {
        setDepartmentViewPageActiveIndex(e.index); // Update the active tab index
    };

    const onChildTabChange = (e) => {
        setDepartmentViewPageChildActiveIndex(e.index); // Update the active tab index
    };

    return (
        <div style={{ width: "100%" }}>
            <BreadcrumbNav />
            <div className="projects-view-page">
                {fetchDepartmentById.isLoading && fetchDepartmentById.isFetching && !departmentData && (
                    <div className="m-2">
                        <ProgressBar mode="indeterminate" style={{ height: "4px" }} />
                    </div>
                )}
                <Panel header={`Department ${departmentData?.name} Details`} style={{ marginBottom: "20px" }}>
                    {/* Nested TabView inside Overview */}
                    <TabView scrollable={true} activeIndex={departmentViewPageChildActiveIndex} onTabChange={onChildTabChange}>
                        <TabPanel header="Details">
                            <div className="flex flex-wrap">
                                {/* Column 1 */}
                                <div className="w-full md:w-1/2 px-2 mb-4">
                                    <p>
                                        <strong>Department Name:</strong> {departmentData?.name}
                                    </p>
                                </div>

                                {/* Column 2 */}
                                <div className="w-full md:w-1/2 px-2 mb-4">
                                    <p>
                                        <strong>Department Code:</strong> {departmentData?.code}
                                    </p>
                                    <div>
                                        <strong>Directorate(s):</strong>
                                        {departmentData?.program_directorate_department?.map((pdd, index) => (
                                            <p key={index}>{pdd.program_directorate.directorate.name}</p>
                                        ))}
                                    </div>
                                    <div>
                                        <strong>Program(s):</strong>
                                        {departmentData?.program_directorate_department?.map((pdd, index) => (
                                            <p key={index}>{pdd.program_directorate.program.name}</p>
                                        ))}
                                    </div>

                                    {/* <p>
                                        <strong>Updated By:</strong> {departmentData?.updated_by?.email || "N/A"}
                                    </p> */}
                                </div>

                                {/* Full Width Row for Details */}
                                {/* <div className="w-full px-2">
                                    <strong>Description</strong>
                                    <p>{departmentData?.description}</p>
                                </div> */}

                                <div className="w-full px-2">
                                    <strong>Details</strong>
                                    <p>{departmentData?.details}</p>
                                </div>
                            </div>

                            <Divider />
                        </TabPanel>
                        <TabPanel header="Budget">
                            <DepartmentBudgetPage loggedInUserData={loggedInUserData} selectedDepartmentItem={departmentData || fetchDepartmentById?.data?.data} departmentId={departmentData?.id || fetchDepartmentById?.data?.data?.id} />
                        </TabPanel>
                        <TabPanel header="Indicators">
                            <DepartmentIndicatorPage loggedInUserData={loggedInUserData} selectedDepartmentItem={departmentData || fetchDepartmentById?.data?.data} departmentId={departmentData?.id || fetchDepartmentById?.data?.data?.id} />
                        </TabPanel>
                        <TabPanel header="Outcomes">
                            <DepartmentOutcomesPage loggedInUserData={loggedInUserData} selectedDepartmentItem={departmentData || fetchDepartmentById?.data?.data} departmentId={departmentData?.id || fetchDepartmentById?.data?.data?.id} />
                        </TabPanel>
                        {/* <TabPanel header="Department Metrics"></TabPanel> */}
                        {departmentData?.code && departmentData?.code !== "03" && (
                            <TabPanel header="Budget Outputs">
                                <DepartmentBudgetOutPutsPage loggedInUserData={loggedInUserData} selectedDepartmentItem={departmentData || fetchDepartmentById?.data?.data} departmentId={departmentData?.id || fetchDepartmentById?.data?.data?.id} />
                            </TabPanel>
                        )}
                        {/* <TabPanel header="Project Clusters">
                            <ProjectClustersPage loggedInUserData={loggedInUserData} selectedParentDetail={departmentData || fetchDepartmentById?.data?.data} departmentId={departmentData?.id || fetchDepartmentById?.data?.data?.id} />
                        </TabPanel> */}
                        {departmentData?.code && departmentData?.code !== "03" && (
                            <TabPanel header="Department Functions">
                                <DepartmentFunctionsPage loggedInUserData={loggedInUserData} selectedParentDetail={departmentData || fetchDepartmentById?.data?.data} departmentId={departmentData?.id || fetchDepartmentById?.data?.data?.id} />
                            </TabPanel>
                        )}
                        {departmentData?.code && departmentData?.code !== "03" && (
                            <TabPanel header="Administrative Units">
                                <DepartmentAdministrationPage loggedInUserData={loggedInUserData} selectedParentDetail={departmentData || fetchDepartmentById?.data?.data} departmentId={departmentData?.id || fetchDepartmentById?.data?.data?.id} />
                            </TabPanel>
                        )}
                        {(loggedInUserData?.role?.toLowerCase() === "department commissioner" || loggedInUserData?.role?.toLowerCase() === "assistant commissioner") && departmentData?.code && departmentData?.code !== "03" && (
                            <TabPanel header="Approvals">
                                <ProjectReportsList loggedInUserData={loggedInUserData} selectedParentDetail={departmentData || fetchDepartmentById?.data?.data} departmentId={departmentData?.id || fetchDepartmentById?.data?.data?.id} />
                            </TabPanel>
                        )}
                        {departmentData?.code && departmentData?.code !== "03" && (
                            <TabPanel header="Department Reports">
                                <DepartmentReportsViewPage
                                    loggedInUserData={loggedInUserData}
                                    selectedParentDetail={departmentData || fetchDepartmentById?.data?.data}
                                    departmentId={departmentData?.id || fetchDepartmentById?.data?.data?.id}
                                    departmentViewPageActiveIndex={departmentViewPageActiveIndex}
                                    departmentViewPageChildActiveIndex={departmentViewPageChildActiveIndex}
                                />
                            </TabPanel>
                        )}
                        {/* =================================  Utility regulation Department ================================== */}
                        {departmentData?.code == "03" && (
                            <TabPanel header="Themes">
                                <DepartmentThemesPage loggedInUserData={loggedInUserData} selectedDepartmentItem={departmentData || fetchDepartmentById?.data?.data} departmentId={departmentData?.id || fetchDepartmentById?.data?.data?.id} />
                            </TabPanel>
                        )}

                        {departmentData?.code == "03" && (
                            <TabPanel header="Utilities">
                                <DepartmentUtilitiesPage loggedInUserData={loggedInUserData} selectedDepartmentItem={departmentData || fetchDepartmentById?.data?.data} departmentId={departmentData?.id || fetchDepartmentById?.data?.data?.id} />
                            </TabPanel>
                        )}

                        {(loggedInUserData?.role?.toLowerCase() === "department commissioner" || loggedInUserData?.role?.toLowerCase() === "assistant commissioner") && departmentData?.code == "03" && (
                            <TabPanel header="Approvals">
                                <UtilitiesApprovalPage loggedInUserData={loggedInUserData} selectedDepartmentItem={departmentData || fetchDepartmentById?.data?.data} departmentId={departmentData?.id || fetchDepartmentById?.data?.data?.id} />
                            </TabPanel>
                        )}

                        {departmentData?.code == "03" && (
                            <TabPanel header="Department Reports">
                                <DeptReportsViewPage loggedInUserData={loggedInUserData} selectedDepartmentItem={departmentData || fetchDepartmentById?.data?.data} departmentId={departmentData?.id || fetchDepartmentById?.data?.data?.id} />
                            </TabPanel>
                        )}

                        {departmentData?.code == "03" && (
                            <TabPanel header="Tarrifs">
                                <DepartmentTarrifsPage loggedInUserData={loggedInUserData} selectedDepartmentItem={departmentData || fetchDepartmentById?.data?.data} departmentId={departmentData?.id || fetchDepartmentById?.data?.data?.id} />
                            </TabPanel>
                        )}
                    </TabView>
                </Panel>
            </div>
        </div>
    );
};

export default DepartmentViewPage;

// Sample usage:
// <ProjectsViewPage project={{
//     name: 'Urban Development Project',
//     code: 'UD123',
//     department: 'Urban Planning',
//     type: 'Development',
//     responsibleOfficer: 'John Doe',
//     details: 'This project focuses on...',
//     districts: [{ id: '1', name: 'District 1' }, { id: '2', name: 'District 2' }]
// }} />
