import React from "react";
import ListPage from "./ListPage";
import { Link } from "react-router-dom";

import BreadcrumbNav from "../../../../../components/mweComponents/BreadcrumbNav";

const createBreadCrump = () => {};
//
function UtilitiesReportPage({ reportsData, filterQueryIsLoading, selectedUtilityData }) {
    let linksData = [{ name: "programs" }];
    return (
        <div>
            {/* <BreadcrumbNav /> */}

            <ListPage reportsData={reportsData} filterQueryIsLoading={filterQueryIsLoading} selectedUtilityData={selectedUtilityData} />
        </div>
    );
}

export default UtilitiesReportPage;
