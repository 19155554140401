import React, { useState, useEffect } from "react";
// import { Card } from "primereact/card";
import { Tree } from "primereact/tree";
import { Divider } from "primereact/divider";
import { useLocation } from "react-router-dom";
import { Panel } from "primereact/panel";
import BreadcrumbNav from "../../../../../../../components/mweComponents/BreadcrumbNav";
import { Button } from "primereact/button";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { Fieldset } from "primereact/fieldset";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { TabView, TabPanel } from "primereact/tabview";
// import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primereact/resources/themes/lara-light-blue/theme.css";
// import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

import UtilityQuarterIndicatorTargetsPage from "../utility-indicator-targets/UtilityIndicatorTargetsPage";

import moment from "moment";

import {
    getAllUtilityAnnualIndicatorTargets,
    getUtilityAnnualIndicatorTargetById,
    postUtilityAnnualIndicatorTargets,
    updateUtilityAnnualIndicatorTargets,
    deleteUtilityAnnualIndicatorTargetById,
} from "../../../../../../../services/departments/utility-dept/dept-utility-annual-indicator-targets-service.js";
import { ProgressBar } from "primereact/progressbar";

const UtilityAnnualIndicatorTargetsViewPage = ({ loggedInUserData }) => {
    const queryClient = useQueryClient();
    //
    let { state } = useLocation();
    let annualTargetsData = state ? state.annualTargetsData : null;
    let selectedParentItem = state ? state.selectedParentItem : null;

    let componentDetailFromNavLink = state?.componentDetailFromNavLink ? state?.componentDetailFromNavLink : null;

    console.log("utility annualTargetsData data got from state is : ", annualTargetsData);

    console.log("logged in user on project : ", loggedInUserData);

    //===================== feytch projects by id =================
    const fetchAnnualTargetsDataById = useQuery(["utility-annual-indicator-targets", "by_id", annualTargetsData?.id], () => getUtilityAnnualIndicatorTargetById(annualTargetsData?.id), {
        onSuccess: (data) => {
            console.log("fetching utility-annual-indicator-targets data is : ", data);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
            console.log("Error fetching utility-annual-indicator-targets is : ", error);
        },
    });

    //
    annualTargetsData = annualTargetsData ?? fetchAnnualTargetsDataById?.data?.data?.data;

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showBudjetOutPutAddForm, setShowBudjetOutPutAddForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    return (
        <div style={{ width: "100%" }}>
            <BreadcrumbNav />
            <div className="projects-view-page">
                {fetchAnnualTargetsDataById.isLoading && fetchAnnualTargetsDataById.isFetching && !annualTargetsData && (
                    <div className="m-2">
                        <ProgressBar mode="indeterminate" style={{ height: "4px" }} />
                    </div>
                )}
                <Panel header={`${annualTargetsData?.utility_indicator?.output_indicator.name} Annual Target  Details`} style={{ marginBottom: "20px" }}>
                    <TabView scrollable={true}>
                        <TabPanel header="Details">
                            <div className="flex flex-wrap">
                                {/* Column 1 */}
                                <div className="md:w-1/2 px-2 m-4">
                                    <p>
                                        <strong>Indicator Name:</strong> {annualTargetsData?.utility_indicator?.output_indicator.name || "N/A"}
                                    </p>
                                    <p>
                                        <strong>Indicator Code:</strong> {annualTargetsData?.utility_indicator?.output_indicator.code || "N/A"}
                                    </p>
                                    <p>
                                        <strong>weight:</strong> {annualTargetsData?.weight || "N/A"}
                                    </p>
                                    <p>
                                        <strong>Financial Year:</strong> {annualTargetsData?.financial_year?.name || "N/A"}
                                    </p>
                                    <p>
                                        <strong>Target:</strong> {annualTargetsData ? new Intl.NumberFormat().format(Number(annualTargetsData.target)) : "N/A"}
                                    </p>
                                    <p>
                                        <strong>Units:</strong> {annualTargetsData?.utility_indicator?.output_indicator.unit || "N/A"}
                                    </p>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Quarter Targets">
                            <UtilityQuarterIndicatorTargetsPage annualTargetsData={annualTargetsData || fetchAnnualTargetsDataById?.data?.data?.data} loggedInUserData={loggedInUserData} selectedParentItem={selectedParentItem} />
                        </TabPanel>

                        {/* {loggedInUserData?.role?.toLowerCase() === "project manager" && (
                            <TabPanel header="Approvals">
                                <SubprojectFieldDataList selectedprojectItem={projectsData || fetchProjectById?.data?.data?.data} loggedInUserData={loggedInUserData} />
                            </TabPanel>
                        )} */}
                    </TabView>
                </Panel>
            </div>
        </div>
    );
};

export default UtilityAnnualIndicatorTargetsViewPage;
