import axiosAPI from "../../axiosApi";

export async function getAllDepartmentThemeIndicatorTargets(params = {}) {
    const response = await axiosAPI.get("utility-theme-indicator-targets", { params: params });
    return response;
}

export async function getDepartmentThemeIndicatorTargetById(id) {
    const response = await axiosAPI.get(`utility-theme-indicator-targets/${id}`);
    return response;
}

export async function postDepartmentThemeIndicatorTarget(data) {
    const response = await axiosAPI.post(`utility-theme-indicator-targets`, data);
    return response;
}

export async function updateDepartmentThemeIndicatorTarget(id, data) {
    const response = await axiosAPI.put(`utility-theme-indicator-targets/${id}`, data);
    return response;
}

export async function deleteDepartmentThemeIndicatorTargetById(id) {
    const response = await axiosAPI.delete(`utility-theme-indicator-targets/${id}`);
    return response;
}
