import React, { useState, useEffect } from "react";

import { Dialog } from "primereact/dialog";

import { postPrograms } from "../../../services/programs/program-service";
import ProgramsRowForm from "./widgets/ProgramsRowForm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";

function ProgramsCreateForm(props) {
    const [name, setName] = useState();
    const [details, setDetails] = useState();
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(true);
    const [validated, setValidated] = useState(true);

    const queryClient = useQueryClient();

    const creactProgramsMutation = useMutation(postPrograms, {
        onSuccess: () => {
            queryClient.invalidateQueries(["programs"]);
            queryClient.invalidateQueries(["programs-hireachy"]);
            props.onClose();
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
            console.log("create programs error : ", error);
        },
    });

    const handleSubmit = async (data) => {
        // event.preventDefault();
        console.log("data we are submitting : ", data);
        creactProgramsMutation.mutate(data);
    };

    return (
        <Dialog header="Program Form" visible={props.show} style={{ width: "50vw" }} onHide={() => props.onHide()} maximizable>
            <p>Fill in the form below</p>
            <ProgramsRowForm handleSubmit={handleSubmit} />
            {/* <h4>{creactProgramsMutation.status}</h4> */}
            {creactProgramsMutation.isLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px", // Border thickness
                            borderColor: "blue", // Border color
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
            {/* <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Program Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            </Modal.Body>
        </Modal> */}
        </Dialog>
    );
}

export default ProgramsCreateForm;
