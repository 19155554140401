import React, { useState, useMemo } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Tree } from "primereact/tree";

import { Dropdown } from "primereact/dropdown";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteProjectById, getAllProjects, getProjectsByProjectIdOrRole, getProjectsByDepartmentIdAndRole } from "../../../../../services/projects/project-service";
import { classNames } from "primereact/utils";
import { getAllDepartments } from "../../../../../services/departments/department-service";
import { getAllDepartmentBudjetOutputs, getDepartmentBudjetOutputById, postDepartmentBudjetOutput, updateDepartmentBudjetOutput, deleteDepartmentBudjetOutputById } from "../../../../../services/departments/departments-budget-outputs-service.js";
import { deleteProgramById, getAllPrograms } from "../../../../../services/programs/program-service";
import { deleteDistrictById, getAllDistricts } from "../../../../../services/districts/districts-service";

import setFieldTouched from "final-form-set-field-touched";

import { getAllOutputIndicators, getOutputIndicatorById, postOutputIndicator, updateOutputIndicator, deleteOutputIndicatorById } from "../../../../../services/directorates/output-indicator-service.js";

//
import { toast } from "react-toastify";
import { AutoComplete } from "primereact/autocomplete";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DeleteIcon from "@mui/icons-material/Delete";
import { ProgressSpinner } from "primereact/progressspinner";

function RowForm({ selectedDepartmentItem, handleSubmit, initialData, ProjectsData = {}, ...props }) {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);
    const queryClient = useQueryClient();

    console.log("initialData dfgsdv : ", initialData);
    const validate = (values) => {
        const errors = {};
        if (!values.department_id) errors.department_id = "A Department is required";
        if (!values.output_indicator_ids) errors.output_indicator_ids = "Atleast Choose an output indicator";
        return errors;
    };

    const onSubmitForm = (data, form) => {
        // Add 'form' as an argument
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            // Mark all fields as touched to show validation errors
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("First fill in all required fields.");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    //================ departments ==============
    const fetchAllDepartments = useQuery(["departments", "by-directorate", selectedDepartmentItem?.directorate_id], () => (!selectedDepartmentItem?.directorate_id ? getAllDepartments() : getAllDepartments({ directorate_id: selectedDepartmentItem?.directorate_id })), {
        onSuccess: (data) => {
            // setDepartments(data.data);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
            console.log("get list of departments : ", error);
        },
    });

    if (props?.departmentId) {
        initialData = { department_id: props?.departmentId, ...initialData };
    }

    // initialData = { project_id: props?.project_id, ...initialData };
    // console.log("props?.selectedDepartmentItem ooo :", selectedDepartmentItem);
    //================ outcomes ===============
    const getAllOutputIndicatorsQuery = useQuery(["output-indicator", selectedDepartmentItem?.directorate_id], () => (!selectedDepartmentItem?.directorate_id ? getAllOutputIndicators() : getAllOutputIndicators({ directorate_id: selectedDepartmentItem?.directorate_id })), {
        onSuccess: (data) => {
            console.log("fetching getAllOutputIndicators data is : ", data);
        },
        onError: (error) => {
            console.log("Error fetching getAllOutputIndicators is : ", error);
        },
    });

    //  const [dropdownItem, setDropdownItem] = useState([]);

    //  const [selectedAutoCompleteDropdownItem, setSelectedAutoCompleteDropdownItem] = useState(DirectorateData.programs || []);

    // const [dropdownDistricts, setDropdownDistricts] = useState([]);
    const [dropdownItem, setDropdownItem] = useState([]);
    // const [selectedDistrict, setSelectedDistrict] = useState(ProjectsData.districts || []);
    const [selectedAutoCompleteDropdownItem, setSelectedAutoCompleteDropdownItem] = useState(ProjectsData.districts || []);

    console.log("kkkkkkk selectedDistrict xxxxx  : ", selectedAutoCompleteDropdownItem);

    // Special option for selecting all districts
    const selectAllOption = { id: "all", name: "Select All" };

    //
    const fetchSuggestions = (query) => {
        // let filteredItems = getAllOutputIndicatorsQuery.data?.data?.data.filter((district) => {
        //     return !selectedAutoCompleteDropdownItem.map((sp) => sp.id).includes(district.id) && district.name.toLowerCase().startsWith(query.toLowerCase());
        // });

        let filteredItems = getAllOutputIndicatorsQuery.data?.data?.data.filter((district) => {
            return !selectedAutoCompleteDropdownItem.map((sp) => sp.id).includes(district.id) && district.name.toLowerCase().includes(query.toLowerCase());
        });

        // Add 'Select All' option only if no districts are selected
        if (selectedAutoCompleteDropdownItem.length > 0 && (query.toLowerCase() === "select all" || query === "")) {
            filteredItems = [selectAllOption, ...filteredItems];
        }

        setDropdownItem(filteredItems);
    };

    //=========================== data table pagination ======================
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(5); // Convert to state variable

    // Function to handle page changes
    const onPaging = (event) => {
        setPage(event.first / event.rows);
        setRows(event.rows); // Update number of rows per page
    };

    // if (initialData) {
    //     initialData = { budgetOutputsIds: initialData, ...initialData };
    // }
    return (
        <div className="col-12 md:col-12">
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    mutators={{ setFieldTouched }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            // onSubmit={(event) => {
                            //     event.preventDefault();
                            //     console.log("values hhh : ", values);
                            //     console.log("event fffff : ", event);
                            //     onSubmitForm(values);
                            //     // handleSubmit(event, values);
                            // }}
                            onSubmit={handleSubmit}
                        >
                            <div style={{ marginBottom: "1rem" }}>
                                <Field name="department_id">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Department</label>
                                            <Dropdown
                                                value={input.value}
                                                options={fetchAllDepartments?.data?.data?.data.map((item) => ({
                                                    label: item.name,
                                                    value: item.id,
                                                }))}
                                                onChange={(e) => input.onChange(e.value)}
                                                placeholder="Select Department"
                                                disabled={fetchAllDepartments.isLoading}
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                            {fetchAllDepartments.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <Field name="output_indicator_ids">
                                {({ input, meta }) => {
                                    console.log("kkkkkkk first main ffffff input.value : ", input.value);
                                    return (
                                        <div className="p-field m-4">
                                            <label htmlFor="output_indicator_ids">Output Indicator</label>
                                            <AutoComplete
                                                {...input}
                                                suggestions={dropdownItem}
                                                completeMethod={(e) => fetchSuggestions(e.query)}
                                                multiple={true}
                                                value={selectedAutoCompleteDropdownItem}
                                                disabled={getAllOutputIndicatorsQuery.isLoading} // Disable component if data is still loading
                                                field="name"
                                                dropdown={true}
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                onChange={(e) => {
                                                    const newSelected = e.value;

                                                    // Handle 'Select All Districts'
                                                    if (newSelected.some((district) => district.id === "all")) {
                                                        // Add all districts to the selection
                                                        const allDistricts = getAllOutputIndicatorsQuery.data?.data?.data || [];
                                                        setSelectedAutoCompleteDropdownItem(allDistricts);
                                                        input.onChange(allDistricts.map((district) => district.id));
                                                    } else {
                                                        if (newSelected.length < selectedAutoCompleteDropdownItem.length) {
                                                            // District removed
                                                            const removedDistrict = selectedAutoCompleteDropdownItem.find((district) => !newSelected.includes(district));
                                                            if (removedDistrict) {
                                                                const updatedSelectedDistricts = selectedAutoCompleteDropdownItem.filter((district) => district.id !== removedDistrict.id);
                                                                setSelectedAutoCompleteDropdownItem(updatedSelectedDistricts);
                                                                input.onChange(updatedSelectedDistricts.map((district) => district.id));
                                                            }
                                                        } else {
                                                            // District added
                                                            const addedDistrict = newSelected[newSelected.length - 1];
                                                            setSelectedAutoCompleteDropdownItem([...selectedAutoCompleteDropdownItem, addedDistrict]);
                                                            input.onChange([...input.value, addedDistrict.id]);
                                                        }
                                                    }

                                                    fetchSuggestions("");
                                                }}
                                                id="output_indicator_ids"
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                            {/* DataTable to display selected programs */}
                                            {input.value && input.value.length > 0 && (
                                                <div className="p-field m-4">
                                                    <DataTable value={selectedAutoCompleteDropdownItem} paginator rows={rows} first={page * rows} onPage={onPaging} paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" rowsPerPageOptions={[5, 10, 20]}>
                                                        <Column field="id" header="ID"></Column>
                                                        <Column field="name" header="Name"></Column>
                                                        <Column field="type" header="Type"></Column>
                                                        <Column field="directorate.name" header="Directorate Name"></Column>
                                                        <Column
                                                            body={(rowData) => (
                                                                <DeleteIcon
                                                                    style={{ color: "tomato", cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        const updatedSelectedDistrict = selectedAutoCompleteDropdownItem.filter((district) => district.id !== rowData.id);
                                                                        setSelectedAutoCompleteDropdownItem(updatedSelectedDistrict);
                                                                        input.onChange(updatedSelectedDistrict.map((prog) => prog.id));
                                                                        fetchSuggestions(""); // Refresh the dropdown suggestions
                                                                    }}
                                                                />
                                                            )}
                                                            header="Actions"
                                                        ></Column>
                                                    </DataTable>
                                                </div>
                                            )}

                                            {getAllOutputIndicatorsQuery.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        </div>
                                    );
                                }}
                            </Field>

                            <div className="d-grid gap-2">
                                <Button type="submit" label="Save" className="p-button-primary" icon="pi pi-check" />
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    style={{ width: "30vw" }}
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to submit?
                </Dialog>
            </div>
        </div>
    );
}

export default RowForm;
