import React, { lazy, Suspense, useState } from "react";

// ================ mwe route imports =====================================

// const Login = React.lazy(() => import("./components/mweComponents/auth/NewLoginPage"));

// //  ==========================  sakai route imports ==============================

// const MapsView = lazy(() => import("./views/newViews/maps/MapsView"));

// //======= new app routes start ========================
// const NewDistrictsPage = lazy(() => import("./views/newViews/locations/Districts/DistrictPage"));
// const NewCountiesPage = lazy(() => import("./views/newViews/locations/county/CountyPage"));
// const NewSubCountiesPage = lazy(() => import("./views/newViews/locations/subcounty/SubcountyPage"));
// const NewParishesPage = lazy(() => import("./views/newViews/locations/parish/ParishPage"));
// const NewVillagesPage = lazy(() => import("./views/newViews/locations/village/VillagePage"));
// const NewUsersPage = lazy(() => import("./views/newViews/users/UserPage"));

// const NewDashboardPage = lazy(() => import("./views/newViews/dashboard/Dashboard.js"));
// const NationalDashboardPage = lazy(() => import("./views/newViews/dashboard/national-dashboard/NationalDashboardPage.jsx"));

// const NewProgramsPage = lazy(() => import("./views/newViews/programs/ProgramsPage"));
// const NewDirectoratesPage = lazy(() => import("./views/newViews/directorate/DirectoratePage"));
// const NewDepartmentsPage = lazy(() => import("./views/newViews/departments/DepartmentsPage"));

// const DepartmentBudgetOutputsViewPage = lazy(() => import("./views/newViews/departments/department-budget-outputs/DepartmentBudgetOutputsViewPage.jsx"));

// const NewProjectsPage = lazy(() => import("./views/newViews/projects/ProjectsPage"));
// const NewProjectsViewPage = lazy(() => import("./views/newViews/projects/ProjectsViewPage"));
// const NewProjectBudgetOutputsViewPage = lazy(() => import("./views/newViews/projects/projects-budgetOutputs/ProjectBudgetOutputsViewPage.jsx"));
// const SubProjectBudgetOutputsViewPage = lazy(() => import("./views/newViews/subprojects/subproject-budgetOutputs/SubProjectBudgetOutputsViewPage.jsx"));

// // ("/programs/directorates/directorate/department/project/budgetoutput");
// const NewSubProjectsPage = lazy(() => import("./views/newViews/subprojects/SubProjectsPage.js"));
// const SubProjectsViewPage = lazy(() => import("./views/newViews/subprojects/SubProjectsViewPage.jsx"));
// const VillageViewPage = lazy(() => import("./views/newViews/locations/village/VillageViewPage"));

// const NewDirectorateViewPage = lazy(() => import("./views/newViews/directorate/DirectorateViewPage"));
// const DepartmentViewPage = lazy(() => import("./views/newViews/departments/DepartmentViewPage.jsx"));
// const DepartmentOutcomesViewPage = lazy(() => import("./views/newViews/departments/department-outcomes/DepartmentOutcomesViewPage.jsx"));
// const DepartmentOutcomeIndicatorsViewPage = lazy(() => import("./views/newViews/departments/department-outcomes/department-outcome-indicators/DepartmentOutcomeIndicatorsViewPage.jsx"));

// const FinancialYearsPage = lazy(() => import("./views/newViews/settings/finacial-years/FinancialYearsPage"));
// const MonthsPage = lazy(() => import("./views/newViews/settings/months/MonthsPage"));
// const FundersPage = lazy(() => import("./views/newViews/settings/funders/FundersPage"));
// const OutcomeIndicatorTypesPage = lazy(() => import("./views/newViews/settings/outcome-indicator-types/OutcomeIndicatorTypesPage.js"));

// const OperationAndMaintenanceStructuresPage = lazy(() => import("./views/newViews/settings/operation-maintanence/OperationAndMaintenanceStructuresPage"));

// const QuartersPage = lazy(() => import("./views/newViews/settings/quarters/QuartersPage"));
// const CensusPage = lazy(() => import("./views/newViews/settings/census/CensusPage"));

// const DataMigrationsPage = lazy(() => import("./views/newViews/data-migrations/DataMigrationsPage"));
// const SubProjectDataCollectionPage = lazy(() => import("./views/newViews/subprojects/subproject-data-collection/SubProjectDataCollectionPage"));
// const ProjectReportFormViewPage = lazy(() => import("./views/newViews/projects/projects-reports/widgets/create-project-reports/widgets/ProjectReportFormViewPage"));
// const DepartmentReportFormViewPage = lazy(() => import("./views/newViews/departments/department-reports/widgets/create-department-reports/widgets/DepartmentReportFormViewPage"));

// const OutcomeIndicatorManualValuesPage = lazy(() => import("./views/newViews/settings/outcome-indicator-manual-values/OutcomeIndicatorManualValuesPage.js"));
//const DepartmentAdministrationViewPage = lazy(() => import('./views/newViews/departments/department-administration/DepartmentAdministrationViewPage'));

// =============================== removed eagar loading ============================================
import Login from "./components/mweComponents/auth/NewLoginPage";

//  ==========================  sakai route imports ==============================

import MapsView from "./views/newViews/maps/MapsView";

//======= new app routes start ========================
import NewDistrictsPage from "./views/newViews/locations/Districts/DistrictPage";
import NewCountiesPage from "./views/newViews/locations/county/CountyPage";
import NewSubCountiesPage from "./views/newViews/locations/subcounty/SubcountyPage";
import NewParishesPage from "./views/newViews/locations/parish/ParishPage";
import NewVillagesPage from "./views/newViews/locations/village/VillagePage";
import NewUsersPage from "./views/newViews/users/UserPage";

import NewDashboardPage from "./views/newViews/dashboard/Dashboard.js";
import NationalDashboardPage from "./views/newViews/dashboard/national-dashboard/NationalDashboardPage.jsx";

import NewProgramsPage from "./views/newViews/programs/ProgramsPage";
import NewDirectoratesPage from "./views/newViews/directorate/DirectoratePage";
import NewDepartmentsPage from "./views/newViews/departments/DepartmentsPage";

import DepartmentBudgetOutputsViewPage from "./views/newViews/departments/department-budget-outputs/DepartmentBudgetOutputsViewPage.jsx";

import NewProjectsPage from "./views/newViews/projects/ProjectsPage";
import ProjectsBudgetOutputsAnnualTargetViewPage from "./views/newViews/projects/projects-budgetOutputs/project-budget-output-annual-indicator-targets/ProjectsBudgetOutputsAnnualTargetViewPage.jsx";
import NewProjectsViewPage from "./views/newViews/projects/ProjectsViewPage";
import NewProjectBudgetOutputsViewPage from "./views/newViews/projects/projects-budgetOutputs/ProjectBudgetOutputsViewPage.jsx";
import SubProjectBudgetOutputsViewPage from "./views/newViews/subprojects/subproject-budgetOutputs/SubProjectBudgetOutputsViewPage.jsx";

import NewSubProjectsPage from "./views/newViews/subprojects/SubProjectsPage.js";
import SubProjectsViewPage from "./views/newViews/subprojects/SubProjectsViewPage.jsx";
import VillageViewPage from "./views/newViews/locations/village/VillageViewPage";

import NewDirectorateViewPage from "./views/newViews/directorate/DirectorateViewPage";
import DepartmentViewPage from "./views/newViews/departments/DepartmentViewPage.jsx";
import DepartmentOutcomesViewPage from "./views/newViews/departments/department-outcomes/DepartmentOutcomesViewPage.jsx";
import DepartmentOutcomeIndicatorsViewPage from "./views/newViews/departments/department-outcomes/department-outcome-indicators/DepartmentOutcomeIndicatorsViewPage.jsx";

import FinancialYearsPage from "./views/newViews/settings/finacial-years/FinancialYearsPage";
import YearsPage from "./views/newViews/settings/years/YearsPage";

import MonthsPage from "./views/newViews/settings/months/MonthsPage";
import FundersPage from "./views/newViews/settings/funders/FundersPage";
import OutcomeIndicatorTypesPage from "./views/newViews/settings/outcome-indicator-types/OutcomeIndicatorTypesPage.js";

import OperationAndMaintenanceStructuresPage from "./views/newViews/settings/operation-maintanence/OperationAndMaintenanceStructuresPage";

import QuartersPage from "./views/newViews/settings/quarters/QuartersPage";
import CensusPage from "./views/newViews/settings/census/CensusPage";
import CurrenciesPage from "./views/newViews/settings/currencies/CurrenciesPage";
import UtilityTypesPage from "./views/newViews/settings/utility-types/UtilityTypesPage";
import OutputIndicatorTypesPage from "./views/newViews/settings/output-indicator-types/OutputIndicatorTypesPage";

import DataMigrationsPage from "./views/newViews/data-migrations/DataMigrationsPage";
import SubProjectDataCollectionPage from "./views/newViews/subprojects/subproject-data-collection/SubProjectDataCollectionPage";
import ProjectReportFormViewPage from "./views/newViews/projects/projects-reports/widgets/create-project-reports/widgets/ProjectReportFormViewPage";
import DepartmentReportFormViewPage from "./views/newViews/departments/department-reports/widgets/create-department-reports/widgets/DepartmentReportFormViewPage";

import OutcomeIndicatorManualValuesPage from "./views/newViews/settings/outcome-indicator-manual-values/OutcomeIndicatorManualValuesPage.js";

import DepartmentAdministrationViewPage from "./views/newViews/departments/department-administration/DepartmentAdministrationViewPage";

//===================== reports =====================
import ReportsPage from "./views/newViews/reports/ReportsPage";

//================= utilities ===================]
import DepartmentThemesViewPage from "./views/newViews/departments/utility-department/department-themes/DepartmentThemesViewPage.jsx";
import DepartmentThemeIndicatorsViewPage from "./views/newViews/departments/utility-department/department-themes/department-theme-indicators/DepartmentThemeIndicatorsViewPage.jsx";

import DepartmentUtilitiesViewPage from "./views/newViews/departments/utility-department/department-utilities/DepartmentUtilitiesViewPage.jsx";
import UtilityIndicatorsViewPage from "./views/newViews/departments/utility-department/department-utilities/utility-indicators/UtilityIndicatorsViewPage.jsx";
import UtilityAnnualIndicatorTargetsViewPage from "./views/newViews/departments/utility-department/department-utilities/utility-indicators/utility-annual-indicator-targets/UtilityAnnualIndicatorTargetsViewPage.jsx";
import UtilityReportingPage from "./views/newViews/departments/utility-department/department-utilities/utility-reports/UtilityReportingPage";
import UtilityDeptReportingPage from "./views/newViews/departments/utility-department/department-reports/UtilityDeptReportingPage.jsx";

import ReportsTrackerPage from "./views/newViews/reports-tracker/ReportsTrackerPage";
//
function AppRoutes(loggedInUser) {
    console.log("logged in User in App Routes : ", loggedInUser);
    let privateDefaultRoutes = [
        {
            path: "/map",
            name: "map",
            element: MapsView,
            layout: "/private",
        },
        {
            path: "/national",
            name: "national",
            element: NationalDashboardPage,
            layout: "/private",
        },
        //==================== reports ========================
        {
            path: "/reports",
            name: "reports",
            element: ReportsPage,
            layout: "/private",
        },
        {
            path: "/tracker",
            name: "tracker",
            element: ReportsTrackerPage,
            layout: "/private",
        },
        // ======================= new mwe routes =====================
        {
            path: "/DataMigrations",
            name: "DataMigrations",
            element: DataMigrationsPage,
            layout: "/admin",
        },
        {
            path: "/financialyears",
            name: "financialyears",
            element: FinancialYearsPage,
            layout: "/admin",
        },
        {
            path: "/years",
            name: "years",
            element: YearsPage,
            layout: "/admin",
        },

        {
            path: "/months",
            name: "months",
            element: MonthsPage,
            layout: "/admin",
        },
        {
            path: "/funders",
            name: "funders",
            element: FundersPage,
            layout: "/admin",
        },

        {
            path: "/indicatortypes",
            name: "indicatortypes",
            element: OutcomeIndicatorTypesPage,
            layout: "/admin",
        },

        {
            path: "/OperationAndMaintenanceStructures",
            name: "OperationAndMaintenanceStructuresPage",
            element: OperationAndMaintenanceStructuresPage,
            layout: "/admin",
        },

        {
            path: "/quarters",
            name: "quarters",
            element: QuartersPage,
            layout: "/admin",
        },
        {
            path: "/census",
            name: "census",
            element: CensusPage,
            layout: "/admin",
        },
        {
            path: "/currencies",
            name: "currencies",
            element: CurrenciesPage,
            layout: "/admin",
        },
        {
            path: "/utility-types",
            name: "utility-types",
            element: UtilityTypesPage,
            layout: "/admin",
        },
        {
            path: "/output-indicator-types",
            name: "output-indicator-types",
            element: OutputIndicatorTypesPage,
            layout: "/admin",
        },

        // {
        //     path: "/indicators",
        //     name: "census",
        //     element: OutcomeIndicatorManualValuesPage,
        //     layout: "/admin",
        // },

        {
            path: "/dashboard",
            name: "dashboard",
            element: NewDashboardPage, // Replace with the actual component
            layout: "/private",
        },
        {
            path: "/",
            name: "dashboard",
            element: NewDashboardPage, // Replace with the actual component
            layout: "/private",
        },
        {
            path: "/programs",
            name: "programs",
            element: NewProgramsPage, // Replace with the actual component
            layout: "/private",
        },
        {
            path: "/departments",
            name: "departments",
            element: NewDepartmentsPage,
            layout: "/admin",
        },
        {
            path: "/directorates",
            name: "directorates",
            element: NewDirectoratesPage,
            layout: "/admin",
        },
        {
            path: "directorates/directorate",
            name: "directorates/directorate",
            element: NewDirectorateViewPage,
            layout: "/admin",
        },

        {
            path: "/programs/directorates/directorate/department",
            name: "/programs/directorates/directorate/department",
            element: DepartmentViewPage,
            layout: "/admin",
        },

        {
            path: "/programs/directorates/directorate/department/administration",
            name: "/programs/directorates/directorate/department/administration",
            element: DepartmentAdministrationViewPage,
            layout: "/admin",
        },
        {
            path: "/programs/directorates/directorate/department/outcome",
            name: "/programs/directorates/directorate/department/outcome",
            element: DepartmentOutcomesViewPage,
            layout: "/admin",
        },

        {
            path: "/programs/directorates/directorate/department/outcome/indicator",
            name: "/programs/directorates/directorate/department/outcome/indicator",
            element: DepartmentOutcomeIndicatorsViewPage,
            layout: "/admin",
        },

        {
            path: "/programs/directorates/directorate/department/theme",
            name: "/programs/directorates/directorate/department/theme",
            element: DepartmentThemesViewPage,
            layout: "/admin",
        },
        {
            path: "/programs/directorates/directorate/department/theme/indicator",
            name: "/programs/directorates/directorate/department/outcome/indicator",
            element: DepartmentThemeIndicatorsViewPage,
            layout: "/admin",
        },

        {
            path: "/programs/directorates/directorate/department/budgetOutput",
            name: "/programs/directorates/directorate/department/budgetOutput",
            element: DepartmentBudgetOutputsViewPage,
            layout: "/admin",
        },
        {
            path: "/programs/directorates/directorate/department/reporting",
            name: "/programs/directorates/directorate/department/reporting",
            element: UtilityDeptReportingPage,
            layout: "/admin",
        },

        {
            path: "/programs/directorates/directorate/department/utility",
            name: "/programs/directorates/directorate/department/utility",
            element: DepartmentUtilitiesViewPage,
            layout: "/admin",
        },

        {
            path: "/programs/directorates/directorate/department/utility/report",
            name: "/programs/directorates/directorate/department/utility/report",
            element: UtilityReportingPage,
            layout: "/admin",
        },

        {
            path: "/programs/directorates/directorate/department/utility/indicator",
            name: "/programs/directorates/directorate/department/utility/indicator",
            element: UtilityIndicatorsViewPage,
            layout: "/admin",
        },

        {
            path: "/programs/directorates/directorate/department/utility/indicator/view",
            name: "/programs/directorates/directorate/department/utility/indicator/view",
            element: UtilityAnnualIndicatorTargetsViewPage,
            layout: "/admin",
        },

        {
            path: "directorates/directorate/department",
            name: "directorates/directorate/department",
            element: DepartmentViewPage,
            layout: "/admin",
        },
        {
            path: "directorates/view/departments/view/projects",
            name: "directorates/view/departments",
            element: DepartmentViewPage,
            layout: "/admin",
        },

        {
            path: "/programs/directorates/directorate",
            name: "/programs/directorates/directorate",
            element: NewDirectorateViewPage,
            layout: "/admin",
        },

        {
            path: "/programs/directorates",
            name: "Program/directorates",
            element: NewDirectoratesPage,
            layout: "/admin",
        },

        {
            path: "/districts",
            name: "districts",
            element: NewDistrictsPage,
            layout: "/admin",
        },
        {
            path: "/districts/counties",
            name: "districts/counties",
            element: NewCountiesPage,
            layout: "/admin",
        },

        {
            path: "/counties",
            name: "counties",
            element: NewCountiesPage,
            layout: "/admin",
        },

        {
            path: "/districts/counties/subcounties",
            name: "districts/counties/subcounties",
            element: NewSubCountiesPage,
            layout: "/admin",
        },
        {
            path: "/subcounties",
            name: "subcounties",
            element: NewSubCountiesPage,
            layout: "/admin",
        },
        {
            path: "/districts/counties/subcounties/parishes",
            name: "districts/counties/subcounties/parishes",
            element: NewParishesPage,
            layout: "/admin",
        },
        {
            path: "/parishes",
            name: "parishes",
            element: NewParishesPage,
            layout: "/admin",
        },
        {
            path: "/districts/counties/subcounties/parishes/villages",
            name: "districts/counties/subcounties/parishes/villages",
            element: NewVillagesPage,
            layout: "/admin",
        },
        {
            path: "/villages",
            name: "villages",
            element: NewVillagesPage,
            layout: "/admin",
        },
        {
            path: "/districts/counties/subcounties/parishes/villages/village",
            name: "districts/counties/subcounties/parishes/villages/village",
            element: VillageViewPage,
            layout: "/admin",
        },
        {
            path: "/villages/village",
            name: "VillageViewPage",
            element: VillageViewPage,
            layout: "/admin",
        },

        {
            path: "/users",
            name: "users",
            element: NewUsersPage,
            layout: "/admin",
        },
        {
            path: "/projects",
            name: "projects",
            element: NewProjectsPage,
            layout: "/admin",
        },
        {
            path: "/programs/directorates/directorate/department/administration/project",
            name: "projectsView",
            element: NewProjectsViewPage,
            layout: "/admin",
        },

        {
            path: "/projects/view",
            name: "projectsView",
            element: NewProjectsViewPage,
            layout: "/admin",
        },
        {
            path: "/programs/directorates/directorate/department/administration/project/budgetoutput",
            name: "projectbudgetoutputView",
            element: NewProjectBudgetOutputsViewPage,
            layout: "/admin",
        },
        {
            path: "/programs/directorates/directorate/department/administration/project/budgetoutput/view",
            name: "ProjectsBudgetOutputsAnnualTargetViewPage",
            element: ProjectsBudgetOutputsAnnualTargetViewPage,
            layout: "/admin",
        },

        {
            path: "/subprojects",
            name: "subprojects",
            element: NewSubProjectsPage,
            layout: "/admin",
        },
        {
            path: "/programs/directorates/directorate/department/administration/project/subproject",
            name: "subprojectView",
            element: SubProjectsViewPage,
            layout: "/admin",
        },
        {
            path: "/subproject/:id",
            name: "subprojectView",
            element: SubProjectsViewPage,
            layout: "/admin",
        },
        {
            path: "/programs/directorates/directorate/department/administration/project/subproject/budgetoutput",
            name: "programs/directorates/directorate/department/project/subproject/budgetoutput",
            element: SubProjectBudgetOutputsViewPage,
            layout: "/admin",
        },

        {
            path: "/programs/directorates/directorate/department/administration/project/subproject/datacollection",
            name: "datacollection",
            element: SubProjectDataCollectionPage,
            layout: "/admin",
        },
    ];

    let engineerRoutes = [
        {
            path: "/subproject",
            name: "subprojectView",
            element: SubProjectsViewPage,
            layout: "/admin",
        },

        {
            path: "/subproject/budgetoutput",
            name: "/subproject/budgetoutput",
            element: SubProjectBudgetOutputsViewPage,
            layout: "/admin",
        },

        {
            path: "subproject/datacollection",
            name: "datacollection",
            element: SubProjectDataCollectionPage,
            layout: "/admin",
        },
    ];

    let projectManagerRoutes = [
        {
            path: "project",
            name: "projectsView",
            element: NewProjectsViewPage,
            layout: "/admin",
        },

        {
            path: "project/budgetoutput",
            name: "projectbudgetoutputView",
            element: NewProjectBudgetOutputsViewPage,
            layout: "/admin",
        },
        {
            path: "project/budgetoutput/view",
            name: "ProjectsBudgetOutputsAnnualTargetViewPage",
            element: ProjectsBudgetOutputsAnnualTargetViewPage,
            layout: "/admin",
        },
        {
            path: "project/createreport",
            name: "project/createreport",
            element: ProjectReportFormViewPage,
            layout: "/admin",
        },

        {
            path: "project/subproject",
            name: "subprojectView",
            element: SubProjectsViewPage,
            layout: "/admin",
        },

        {
            path: "/project/subproject/budgetoutput",
            name: "project/subproject/budgetoutput",
            element: SubProjectBudgetOutputsViewPage,
            layout: "/admin",
        },

        {
            path: "project/subproject/datacollection",
            name: "datacollection",
            element: SubProjectDataCollectionPage,
            layout: "/admin",
        },
    ];

    let departmentCommissionerRoutes = [
        {
            path: "/department",
            name: "/programs/directorates/directorate/department",
            element: DepartmentViewPage,
            layout: "/admin",
        },
        {
            path: "/department/outcome",
            name: "/department/outcome",
            element: DepartmentOutcomesViewPage,
            layout: "/admin",
        },

        {
            path: "/department/outcome/indicator",
            name: "/department/outcome/indicator",
            element: DepartmentOutcomeIndicatorsViewPage,
            layout: "/admin",
        },
        {
            path: "/department/theme",
            name: "/department/theme",
            element: DepartmentThemesViewPage,
            layout: "/admin",
        },

        {
            path: "/department/theme/indicator",
            name: "/department/theme/indicator",
            element: DepartmentThemeIndicatorsViewPage,
            layout: "/admin",
        },

        {
            path: "/department/budgetOutput",
            name: "/department/budgetOutput",
            element: DepartmentBudgetOutputsViewPage,
            layout: "/admin",
        },
        {
            path: "/department/reporting",
            name: "/department/reporting",
            element: UtilityDeptReportingPage,
            layout: "/admin",
        },
        {
            path: "/department/utility",
            name: "/department/utility",
            element: DepartmentUtilitiesViewPage,
            layout: "/admin",
        },
        {
            path: "/department/utility/report",
            name: "/department/utility/report",
            element: UtilityReportingPage,
            layout: "/admin",
        },
        {
            path: "/department/utility/indicator",
            name: "/department/utility/indicator",
            element: UtilityIndicatorsViewPage,
            layout: "/admin",
        },
        {
            path: "/department/utility/indicator/view",
            name: "/department/utility/indicator/view",
            element: UtilityAnnualIndicatorTargetsViewPage,
            layout: "/admin",
        },
        {
            path: "department/administration/project",
            name: "projectsView",
            element: NewProjectsViewPage,
            layout: "/admin",
        },

        {
            path: "/department/administration/project/budgetoutput",
            name: "projectbudgetoutputView",
            element: NewProjectBudgetOutputsViewPage,
            layout: "/admin",
        },
        {
            path: "/department/administration/project/budgetoutput/view",
            name: "ProjectsBudgetOutputsAnnualTargetViewPage",
            element: ProjectsBudgetOutputsAnnualTargetViewPage,
            layout: "/admin",
        },

        {
            path: "/department/administration/project/subproject",
            name: "subprojectView",
            element: SubProjectsViewPage,
            layout: "/admin",
        },
        {
            path: "/department/administration/project/subproject/budgetoutput",
            name: "department/project/subproject/budgetoutput",
            element: SubProjectBudgetOutputsViewPage,
            layout: "/admin",
        },

        {
            path: "/department/administration/project/subproject/datacollection",
            name: "datacollection",
            element: SubProjectDataCollectionPage,
            layout: "/admin",
        },
    ];

    let directorateRoutes = [
        {
            path: "/directorate",
            name: "/directorate",
            element: NewDirectorateViewPage,
            layout: "/admin",
        },

        {
            path: "/directorate/department",
            name: "/directorate/department",
            element: DepartmentViewPage,
            layout: "/admin",
        },

        {
            path: "/directorate/department/administration",
            name: "/directorate/department/administration",
            element: DepartmentAdministrationViewPage,
            layout: "/admin",
        },
        {
            path: "/directorate/department/outcome",
            name: "/directorate/department/outcome",
            element: DepartmentOutcomesViewPage,
            layout: "/admin",
        },

        {
            path: "/directorate/department/outcome/indicator",
            name: "/directorate/department/outcome/indicator",
            element: DepartmentOutcomeIndicatorsViewPage,
            layout: "/admin",
        },
        {
            path: "/directorate/department/theme",
            name: "/directorate/department/theme",
            element: DepartmentThemesViewPage,
            layout: "/admin",
        },
        {
            path: "/directorate/department/theme/indicator",
            name: "/directorate/department/theme/indicator",
            element: DepartmentThemeIndicatorsViewPage,
            layout: "/admin",
        },

        {
            path: "/directorate/department/budgetOutput",
            name: "/directorate/department/budgetOutput",
            element: DepartmentBudgetOutputsViewPage,
            layout: "/admin",
        },
        {
            path: "/directorate/department/reporting",
            name: "/directorate/department/reporting",
            element: UtilityDeptReportingPage,
            layout: "/admin",
        },
        {
            path: "/directorate/department/utility",
            name: "/directorate/department/utility",
            element: DepartmentUtilitiesViewPage,
            layout: "/admin",
        },
        {
            path: "/directorate/department/utility/report",
            name: "/directorate/department/utility/report",
            element: UtilityReportingPage,
            layout: "/admin",
        },
        {
            path: "/directorate/department/utility/indicator",
            name: "/directorate/department/utility/indicator",
            element: UtilityIndicatorsViewPage,
            layout: "/admin",
        },
        {
            path: "/directorate/department/utility/indicator/view",
            name: "/directorate/department/utility/indicator/view",
            element: UtilityAnnualIndicatorTargetsViewPage,
            layout: "/admin",
        },

        {
            path: "directorates/directorate/department",
            name: "directorates/directorate/department",
            element: DepartmentViewPage,
            layout: "/admin",
        },

        {
            path: "/directorate",
            name: "/directorate",
            element: NewDirectorateViewPage,
            layout: "/admin",
        },

        {
            path: "/directorate/department/administration/project",
            name: "/directorate/department/administration/project",
            element: NewProjectsViewPage,
            layout: "/admin",
        },

        {
            path: "/directorate/department/administration/project/budgetoutput",
            name: "/directorate/department/administration/project/budgetoutput",
            element: NewProjectBudgetOutputsViewPage,
            layout: "/admin",
        },
        {
            path: "/directorate/department/administration/project/budgetoutput/view",
            name: "/directorate/department/administration/project/budgetoutput/view",
            element: ProjectsBudgetOutputsAnnualTargetViewPage,
            layout: "/admin",
        },

        {
            path: "/directorate/department/administration/project/subproject",
            name: "subprojectView",
            element: SubProjectsViewPage,
            layout: "/admin",
        },

        {
            path: "/directorate/department/administration/project/subproject/budgetoutput",
            name: "programs/directorates/directorate/department/project/subproject/budgetoutput",
            element: SubProjectBudgetOutputsViewPage,
            layout: "/admin",
        },

        {
            path: "/directorate/department/administration/project/subproject/datacollection",
            name: "datacollection",
            element: SubProjectDataCollectionPage,
            layout: "/admin",
        },
    ];

    let departmentRoutes = [
        {
            path: "/department",
            name: "/department",
            element: DepartmentViewPage,
            layout: "/admin",
        },
        {
            path: "/department/outcome",
            name: "/department/outcome",
            element: DepartmentOutcomesViewPage,
            layout: "/admin",
        },
        {
            path: "/department/outcome/indicator",
            name: "/department/outcome/indicator",
            element: DepartmentOutcomeIndicatorsViewPage,
            layout: "/admin",
        },
        {
            path: "/department/theme",
            name: "/department/theme",
            element: DepartmentThemesViewPage,
            layout: "/admin",
        },
        {
            path: "/department/theme/indicator",
            name: "/department/theme/indicator",
            element: DepartmentThemeIndicatorsViewPage,
            layout: "/admin",
        },

        {
            path: "/department/budgetOutput",
            name: "/department/budgetOutput",
            element: DepartmentBudgetOutputsViewPage,
            layout: "/admin",
        },
        {
            path: "/department/reporting",
            name: "/department/reporting",
            element: UtilityDeptReportingPage,
            layout: "/admin",
        },
        {
            path: "/department/utility",
            name: "/department/utility",
            element: DepartmentUtilitiesViewPage,
            layout: "/admin",
        },
        {
            path: "/department/utility/report",
            name: "/department/utility/report",
            element: UtilityReportingPage,
            layout: "/admin",
        },
        {
            path: "/department/utility/indicator",
            name: "/department/utility/indicator",
            element: UtilityIndicatorsViewPage,
            layout: "/admin",
        },
        {
            path: "/department/utility/indicator/view",
            name: "/department/utility/indicator/view",
            element: UtilityAnnualIndicatorTargetsViewPage,
            layout: "/admin",
        },

        {
            path: "department",
            name: "directorate/department",
            element: DepartmentViewPage,
            layout: "/admin",
        },

        {
            path: "/department/administration/project",
            name: "/department/administration/project",
            element: NewProjectsViewPage,
            layout: "/admin",
        },

        {
            path: "/department/administration",
            name: "/department/administration",
            element: DepartmentAdministrationViewPage,
            layout: "/admin",
        },

        {
            path: "/department/administration/project/budgetoutput",
            name: "/department/administration/project/budgetoutput",
            element: NewProjectBudgetOutputsViewPage,
            layout: "/admin",
        },
        {
            path: "/department/administration/project/budgetoutput/view",
            name: "/department/administration/project/budgetoutput/view",
            element: ProjectsBudgetOutputsAnnualTargetViewPage,
            layout: "/admin",
        },

        {
            path: "/department/administration/project/subproject",
            name: "subprojectView",
            element: SubProjectsViewPage,
            layout: "/admin",
        },

        {
            path: "/department/administration/project/subproject/budgetoutput",
            name: "/department/project/subproject/budgetoutput",
            element: SubProjectBudgetOutputsViewPage,
            layout: "/admin",
        },

        {
            path: "/department/administration/project/subproject/datacollection",
            name: "datacollection",
            element: SubProjectDataCollectionPage,
            layout: "/admin",
        },

        {
            path: "department/report",
            name: "department/report",
            element: DepartmentReportFormViewPage,
            layout: "/admin",
        },
        {
            path: "department/administration/project/report",
            name: "department/project/report",
            element: ProjectReportFormViewPage,
            layout: "/admin",
        },
    ];

    let utilityRoutes = [
        {
            path: "utility",
            name: "utility",
            element: DepartmentUtilitiesViewPage,
            layout: "/admin",
        },
        {
            path: "utility/report",
            name: "utility/report",
            element: UtilityReportingPage,
            layout: "/admin",
        },
        {
            path: "utility/indicator",
            name: "utility/indicator",
            element: UtilityIndicatorsViewPage,
            layout: "/admin",
        },
        {
            path: "utility/indicator/view",
            name: "utility/indicator/view",
            element: UtilityAnnualIndicatorTargetsViewPage,
            layout: "/admin",
        },
    ];

    let departmentAdministrationRoutes = [
        {
            path: "/administration",
            name: "/administration",
            element: DepartmentAdministrationViewPage,
            layout: "/admin",
        },
        {
            path: "/administration/project",
            name: "/administration/project",
            element: NewProjectsViewPage,
            layout: "/admin",
        },

        {
            path: "/administration/project/budgetoutput",
            name: "/administration/project/budgetoutput",
            element: NewProjectBudgetOutputsViewPage,
            layout: "/admin",
        },
        {
            path: "/administration/project/budgetoutput/view",
            name: "/administration/project/budgetoutput/view",
            element: ProjectsBudgetOutputsAnnualTargetViewPage,
            layout: "/admin",
        },

        {
            path: "/administration/project/subproject",
            name: "subprojectView",
            element: SubProjectsViewPage,
            layout: "/admin",
        },

        {
            path: "/administration/project/subproject/budgetoutput",
            name: "/department/project/subproject/budgetoutput",
            element: SubProjectBudgetOutputsViewPage,
            layout: "/admin",
        },

        {
            path: "/administration/project/subproject/datacollection",
            name: "datacollection",
            element: SubProjectDataCollectionPage,
            layout: "/admin",
        },

        {
            path: "department/report",
            name: "department/report",
            element: DepartmentReportFormViewPage,
            layout: "/admin",
        },
        {
            path: "/administration/project/report",
            name: "department/project/report",
            element: ProjectReportFormViewPage,
            layout: "/admin",
        },
    ];

    let projectRoutes = [
        {
            path: "/project",
            name: "/project",
            element: NewProjectsViewPage,
            layout: "/admin",
        },
        {
            path: "project/report",
            name: "project/report",
            element: ProjectReportFormViewPage,
            layout: "/admin",
        },

        {
            path: "/project/budgetoutput",
            name: "/project/budgetoutput",
            element: NewProjectBudgetOutputsViewPage,
            layout: "/admin",
        },
        {
            path: "/project/budgetoutput/view",
            name: "/project/budgetoutput/view",
            element: ProjectsBudgetOutputsAnnualTargetViewPage,
            layout: "/admin",
        },

        {
            path: "/project/subproject",
            name: "subprojectView",
            element: SubProjectsViewPage,
            layout: "/admin",
        },

        {
            path: "/project/subproject/budgetoutput",
            name: "/project/subproject/budgetoutput",
            element: SubProjectBudgetOutputsViewPage,
            layout: "/admin",
        },

        {
            path: "/project/subproject/datacollection",
            name: "datacollection",
            element: SubProjectDataCollectionPage,
            layout: "/admin",
        },
    ];

    let subProjectRoutes = [
        {
            path: "/subproject",
            name: "subprojectView",
            element: SubProjectsViewPage,
            layout: "/admin",
        },

        {
            path: "/subproject/budgetoutput",
            name: "/subproject/budgetoutput",
            element: SubProjectBudgetOutputsViewPage,
            layout: "/admin",
        },

        {
            path: "/subproject/datacollection",
            name: "datacollection",
            element: SubProjectDataCollectionPage,
            layout: "/admin",
        },
    ];

    // utilityRoutes;

    if (loggedInUser?.role.toLowerCase() === "admin" || loggedInUser?.role.toLowerCase() === "super admin") {
        privateDefaultRoutes = [...privateDefaultRoutes, ...directorateRoutes, ...departmentRoutes, ...utilityRoutes, ...departmentAdministrationRoutes, ...projectRoutes, ...subProjectRoutes];
    } else if (loggedInUser?.role.toLowerCase() === "department commissioner") {
        privateDefaultRoutes = [...privateDefaultRoutes, ...directorateRoutes, ...departmentRoutes, ...utilityRoutes, ...departmentAdministrationRoutes, ...projectRoutes, ...subProjectRoutes];
    } else if (loggedInUser?.role.toLowerCase() === "assistant commissioner") {
        privateDefaultRoutes = [...privateDefaultRoutes, ...directorateRoutes, ...departmentRoutes, ...utilityRoutes, ...departmentAdministrationRoutes, ...projectRoutes, ...subProjectRoutes];
    } else if (loggedInUser?.role.toLowerCase() === "district water engineer") {
        privateDefaultRoutes = [...privateDefaultRoutes, ...departmentRoutes, ...departmentAdministrationRoutes, ...projectRoutes, ...subProjectRoutes];
    } else if (loggedInUser?.role.toLowerCase() === "project manager") {
        privateDefaultRoutes = [...privateDefaultRoutes, ...departmentRoutes, ...departmentAdministrationRoutes, ...projectRoutes, ...subProjectRoutes];
    } else if (loggedInUser?.role.toLowerCase() === "engineer") {
        privateDefaultRoutes = [...privateDefaultRoutes, ...projectRoutes, ...subProjectRoutes];
    } else if (loggedInUser?.role.toLowerCase() === "utility manager") {
        privateDefaultRoutes = [...privateDefaultRoutes, ...utilityRoutes];
    } else {
        // menu = DefaultMenu; // Default menu if the role doesn't match any of the above
    }

    const [privateRoutes, setPrivateRoutes] = useState(privateDefaultRoutes);

    let filteredMainRoutes = privateRoutes.filter((route) => route);

    return filteredMainRoutes;
}

export default AppRoutes;
