import axiosAPI from "../../axiosApi";

export async function getAllDepartmentUtilities(params = {}) {
    const response = await axiosAPI.get("utilities", { params: params });
    return response;
}

export async function getDepartmentUtilitieById(id) {
    const response = await axiosAPI.get(`utilities/${id}`);
    return response;
}

export async function postDepartmentUtilities(data) {
    const response = await axiosAPI.post(`utilities`, data);
    return response;
}

export async function updateDepartmentUtilities(id, data) {
    const response = await axiosAPI.put(`utilities/${id}`, data);
    return response;
}

export async function deleteDepartmentUtilitieById(id) {
    const response = await axiosAPI.delete(`utilities/${id}`);
    return response;
}
