import React, { Suspense, useState, useEffect, useRef } from "react";
import { Route, useLocation, useParams, Routes, Link } from "react-router-dom";
import ReportsHandlePage from "./ReportsHandlePage";

import BreadcrumbNav from "../../../components/mweComponents/BreadcrumbNav";

const createBreadCrump = () => {};
//
function ReportsPage({ loggedInUserData }) {
    let linksData = [{ name: "programs" }];
    return (
        <div>
            <BreadcrumbNav />

            <ReportsHandlePage loggedInUserData={loggedInUserData} />
        </div>
    );
}

export default ReportsPage;
