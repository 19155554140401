import axiosAPI from "../../axiosApi";

export async function getAllUtilityAnnualIndicatorTargets(params = {}) {
    const response = await axiosAPI.get("utility-annual-indicator-targets", { params: params });
    return response;
}

export async function getUtilityAnnualIndicatorTargetById(id) {
    const response = await axiosAPI.get(`utility-annual-indicator-targets/${id}`);
    return response;
}

export async function postUtilityAnnualIndicatorTargets(data) {
    const response = await axiosAPI.post(`utility-annual-indicator-targets`, data);
    return response;
}

export async function updateUtilityAnnualIndicatorTargets(id, data) {
    const response = await axiosAPI.put(`utility-annual-indicator-targets/${id}`, data);
    return response;
}

export async function deleteUtilityAnnualIndicatorTargetById(id) {
    const response = await axiosAPI.delete(`utility-annual-indicator-targets/${id}`);
    return response;
}
