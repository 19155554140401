import React, { useEffect, useState, forwardRef, useMemo, useRef } from "react";

import { Link } from "react-router-dom";

import { deleteSubProjectById, getAllSubProjects, getAllSubProjectsUnderUserProjectsByProjectIdOrRole } from "../../../services/sub-projects/sub-project-service";
import moment from "moment";
import * as XLSX from "xlsx";

import SubProjectsCreateForm from "./SubProjectsCreateForm";

// import ProjectOutComeIndicators from "./subprojects indicators/ProjectOutComeIndicators";

// nnn
// import EditForm from "./SubProjectEditForm";
// import SubProjectEditFormbloody from "./old/SubProjectEditFormbloody";
import { getSubProjectById } from "../../../services/sub-projects/sub-project-service";
import { getProjectByIdApi, getProjectFinancialReport } from "../../../services/projects/project-service";
// import ProjectReportPDFPage from "./subprojects reports/ProjectReportPDFPage";
// import NewSubProjectEditFormbloody from "./editforms/NewSubProjectEditFormbloody";
import SubProjectsEditForm from "./SubProjectsEditForm";

// import MaterialTable from "material-table";

// import SubProjectFilterForm from "./old/SubProjectFilterForm";
import MuiTable from "../../../components/mweComponents/MuiTable";

import { filterAndGetAllSubProjects } from "../../../services/sub-projects/sub-project-service";

// import FilterTable from "./components/FilterTable";
// import CrashCoarseMaterialTable from "./components/CrashCoarseMaterialTable";
// import PdfExport from "./components/CrashCoarseMaterialTable";
import WaterIsLoading from "../../../components/mweComponents/WaterIsLoading";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Calendar } from "primereact/calendar";

//
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ProgressBar } from "primereact/progressbar";

import SubProjectIndicatorsPage from "./subproject-indicators/SubProjectIndicatorsPage";
import { Toast as PrimeReactToast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import { confirmDialog } from "primereact/confirmdialog";

function SubProjectsList({ selectedprojectItem, ...props }) {
    const navigate = useNavigate();
    const primetoast = useRef(null);
    const queryClient = useQueryClient();
    let prevPage = props?.prevPage;
    const projectId = props?.projectid || props.loggedInUserData?.project_id;
    console.log("props project id is : ", projectId);
    const [departmentId, setDepartmentId] = useState();
    const [subProjects, setSubProjects] = useState([]);
    const [projectFinancialReport, setProjectFinancialReport] = useState();
    const [showSubProjectForm, setShowSubProjectForm] = useState(false);
    const [years, setYears] = useState([]);
    const [financial_year, setFinancialYear] = useState();
    const [quarter, setQuarter] = useState();
    const [selectedItem, setSelectedItem] = useState({ id: null });

    const [showEditForm, setShowEditForm] = useState(false);
    const [showPDF, setShowPDF] = useState(false);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const [mTableActions, setMTableActions] = useState([]);
    const [filteredTableData, setFilteredTableData] = useState([]);

    const [subLocationIdz, setSubLocationIdz] = useState([]);

    const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;

    const handleShowEditForm = async (item) => {
        console.log("subproject location is we want to fetch is : fff ", item);
        console.log("subproject location id is we want to fetch is : ", item.id);
        // let response = await getSubProjectById(item.id);
        console.log("handeleshow edit form item  data is : ", item);

        // setSelectedItem(response.data);
        setSelectedItem(item);
        setShowEditForm(true);
    };
    const handleCloseEditForm = () => {
        setSelectedItem("");
        setShowEditForm(false);
    };

    const handleShowPDF = (item) => {
        setSelectedItem(item);
        setShowPDF(true);
    };
    const handleClosePDF = () => {
        setSelectedItem({ id: null });
        setShowPDF(false);
    };

    const deleteSubProjectMutation = useMutation(deleteSubProjectById, {
        onSuccess: () => {
            toast.success("Sub Project Deleted Successfully");
            queryClient.invalidateQueries(["subprojects"]);
            queryClient.invalidateQueries(["programs", "programs-hireachy"]);
            // queryClient.resetQueries(["subprojects"]);
            setLoading(false);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
            console.log("Delete Sub projects error : ", error);
        },
    });
    // const handleDelete = async (event, id) => {
    //     var result = window.confirm("Are you sure you want to delete? ");
    //     if (result === true) {
    //         setLoading(true);
    //         deleteSubProjectMutation.mutate(id);
    //     }
    // };

    const handleDelete = async (event, id) => {
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
                setLoading(true);
                deleteSubProjectMutation.mutate(id);
            },
            reject: () => {
                // Optional: any logic you want to execute on reject
            },
        });
    };

    useEffect(() => {
        years_fn();
    }, []);

    const fetchAllSubProjects = useQuery(["subprojects", projectId], () => (!projectId ? getAllSubProjects() : getAllSubProjects({ project_id: projectId })), {
        onSuccess: (data) => {
            console.log("subprojects ddduuuuuuudd : ", data);
            setSubProjects(data?.data?.data);
            setLoading(false);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
            console.log("get list of fetchAllSubProjects : ", error);
        },
    });

    console.log("subprojects uuuuuuu : ", fetchAllSubProjects);

    const years_fn = function () {
        const d = new Date();
        const m = d.getMonth();
        var y = d.getFullYear();
        var out = [];
        if (m < 7) y = y - 1;
        var i = y;
        for (i = y; i > y - 5; i--) {
            out.push(i + "-" + (i + 1));
        }
        setYears(out);
    };

    const onCloseSubProjectForm = () => {
        setShowSubProjectForm(false);
    };

    //   Material - table managing bellow

    const [filter, setFilter] = useState(false);

    let ftableid = 0;

    const columns = [
        {
            title: "ID",
            field: "id",
            hidden: true,
        },
        {
            title: "Name",
            field: "name",
            cellStyle: {
                whiteSpace: "wrap",
                padding: "4px",
            },

            // render: (rowData) => {
            //     return (
            //         <div>
            //             <Link to={`details`} state={{ id: rowData.id }}>
            //                 {rowData.name}
            //             </Link>
            //         </div>
            //     );
            // },
        },
        {
            title: "Project",
            field: "project.project_title",
        },
        {
            title: "code",
            field: "code",
        },
        {
            title: "Start Date",
            field: "start_date",
        },
        {
            title: "End Date",
            field: "proposed_end_date",
            hidden: true,
        },
        {
            title: "Progress",
            field: "completion",
            render: (rowData) => {
                return <ProgressBar value={rowData.completion} label={`${rowData.completion}% Complete`} showValue={true} />;
            },
        },
        { title: "Status", field: "completion_status", hidden: true },
        {
            title: "Contract Amount",
            field: "contract_amount",
            hidden: true,
            render: (rowData) => {
                console.log("row data hhhh : ", rowData);
                // Ensure contract_amount is a string before replacing
                const amountString = rowData.contract_amount ? rowData.contract_amount.toString() : "";
                const amount = parseFloat(amountString.replace(/,/g, ""));
                return <div>{isNaN(amount) ? rowData.contract_amount : amount.toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>;
            },
        },
    ];

    // ======================== filtered columns ======================
    let fftableid = 0;

    const filteredcolumns = [
        {
            title: "ID",
            field: "subProject.id",
            hidden: true,
        },
        {
            title: "Name",
            field: "name",
            cellStyle: {
                whiteSpace: "nowrap",
                padding: "8px",
            },
            render: (rowData) => {
                return (
                    <Link to={`details`} state={{ id: rowData?.subProject?.id }}>
                        {rowData?.subProject?.name}
                    </Link>
                );
            },
        },
        {
            title: "Procurement No",
            field: "procurement_no",
            render: (rowData) => <div style={{ minWidth: "200px" }}>{rowData?.subProject?.procurement_no}</div>,
        },
        {
            title: "Start Date",
            field: "start_date",
            render: (rowData) => <div style={{ minWidth: "150px" }}>{rowData?.subProject?.start_date}</div>,
        },
        {
            title: "End Date",
            field: "proposed_end_date",
            render: (rowData) => <div style={{ minWidth: "150px" }}>{rowData?.subProject?.proposed_end_date}</div>,
        },
        {
            title: "Progress",
            field: "completion",
            render: (rowData) => {
                return <ProgressBar now={rowData?.subProject?.completion} label={`${rowData?.subProject?.completion}%`} />;
            },
        },
        { title: "Status", field: "subProject.completion_status" },
        { title: "Contract Amount", field: "subProject.contract_amount" },
    ];

    // =========================== download to excell ===================
    const [filterOptions, setFilterOptions] = useState(false);
    const [filteredSubProjectData, setFilteredSubProjectData] = useState();

    let excelldata = filteredSubProjectData ? filteredSubProjectData : subProjects;
    const downloadExcel = () => {
        const newData = excelldata.map((row) => {
            delete row.tableData;
            return row;
        });
        const workSheet = XLSX.utils.json_to_sheet(newData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "subprojects");
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
        //Download
        XLSX.writeFile(workBook, "SubprojectsData.xlsx");
    };

    //filtered data columns according to sublocations

    const handleMaterialTableCheckboxChange = () => {
        setFilter(!filter);
    };

    console.log("filterOptions is : ", filterOptions);

    const GetSubProjectByFilterOptions = useQuery(["filterSubProjects", filterOptions?.county_id, filterOptions?.district_id, filterOptions?.parish_id, filterOptions?.sub_county_id, filterOptions?.village_id], () => filterAndGetAllSubProjects(filterOptions), {
        enabled: filterOptions ? true : false,
        onSuccess: (data) => {
            setFilterOptions(false);
            console.log("filteredSubProjectData data?.data?.sub_project: ", data);
            const resultArray = [];

            // the for each makes suree  that only one subproject is returned
            // remember sublocations are attached to subprojects so same subproject can be returned
            // many times so here we filter out so that we get its first instance

            // for (const key in data) {
            //   if (data.hasOwnProperty(key)) {
            //     const dataArray = data[key];
            //     if (dataArray.length > 0) {
            //       const value = dataArray[0];
            //       resultArray.push(value);
            //     }
            //   }
            // }

            setFilteredSubProjectData(data);
            toast.success("Filtering is complete");
        },
        onError: () => {
            setFilterOptions(false);
            toast.error("There was a problem fetching data! Please Contact Administrator");
        },
    });

    console.log("filteredSubProjectData data?.data?.sub_project resultArray : ", filteredSubProjectData);

    // PrimeReact Dialog visibility state
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);

    const handleDeleteConfirm = (item) => {
        setItemToDelete(item);
        setShowConfirmDialog(true);
    };

    const confirmDelete = () => {
        handleDelete(itemToDelete);
        setShowConfirmDialog(false);
    };

    return (
        <div style={{ width: "100%", minHeight: "100vh", padding: "1rem" }}>
            {/* <TabView>
                <TabPanel header="Sub Projects"> */}
            {/* Your content for the "Sub Projects" tab */}

            <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                {props.loggedInUserData?.permissions?.includes("add sub projects") && <Button onClick={() => setShowSubProjectForm(true)}>Add Sub Project</Button>}

                <SubProjectsCreateForm selectedprojectItem={selectedprojectItem} loggedInUserData={props?.loggedInUserData} show={showSubProjectForm} currentProjectId={projectId} onHide={() => setShowSubProjectForm(false)} onClose={onCloseSubProjectForm} />
            </div>

            {/* {(props.loggedInUserData?.role == "Admin" || props.loggedInUserData?.role == "Commissioner") && (
                        <div className="mb-4">
                            <Filters
                                FilterOptions={(value) => {
                                    setFilterOptions(value);
                                }}
                                ClickedToViewAll={(value) => {
                                    setFilteredSubProjectData(false);
                                    setFilterOptions(false);
                                }}
                                externalIsLoading={GetSubProjectByFilterOptions.isLoading && filterOptions ? true : false}
                            />
                        </div>
                    )} */}

            <MuiTable
                tableTitle={
                    prevPage ? `${filteredSubProjectData ? "Filtered " : ""}SubProjects Under ${prevPage}` : `${filteredSubProjectData ? "Filtered Sub Projects" : props.loggedInUserData?.project ? `Sub Projects Under ${props.loggedInUserData?.project?.name} Department` : "Sub Projects"}`
                    // prevPage
                    //   ? `${
                    //       filteredSubProjectData ? "Filtered " : ""
                    //     }SubProjects Under ${prevPage}`
                    //   : `${
                    //       filteredSubProjectData ? "Filtered " : ""
                    //     }Sub Projects`
                }
                tableData={fetchAllSubProjects?.data?.data.data}
                tableColumns={columns}
                handleShowEditForm={handleShowEditForm}
                handleDelete={(e, item_id) => handleDelete(e, item_id)}
                //
                hideRowEdit={(rowData) =>
                    props.loggedInUserData?.permissions?.includes("edit sub projects") &&
                    (props.loggedInUserData?.role == "Project Manager" || props.loggedInUserData?.role == "District Water Engineer" || props.loggedInUserData?.role == "Admin") &&
                    props.loggedInUserData?.department_id == rowData.department_id
                        ? false
                        : props.loggedInUserData?.permissions?.includes("edit sub projects") && props.loggedInUserData?.project_id == rowData.project_id
                        ? false
                        : true
                }
                hideRowDelete={(rowData) =>
                    props.loggedInUserData?.permissions?.includes("edit sub projects") &&
                    (props.loggedInUserData?.role == "Project Manager" || props.loggedInUserData?.role == "District Water Engineer" || props.loggedInUserData?.role == "Admin") &&
                    props.loggedInUserData?.department_id == rowData.department_id
                        ? false
                        : props.loggedInUserData?.permissions?.includes("edit sub projects") && props.loggedInUserData?.project_id == rowData.project_id
                        ? false
                        : true
                }
                showEdit={
                    // activeUser?.department_id ==
                    //   subProjects[0]?.department_id &&
                    props.loggedInUserData?.permissions?.includes("edit sub projects")
                }
                showDelete={
                    // activeUser?.role == "Admin" ||
                    // (activeUser?.department_id ==
                    //   subProjects[0]?.department_id &&
                    //   (activeUser?.permissions.includes(
                    //     "edit sub projects"
                    //   ) ||
                    //     activeUser?.permissions.includes(
                    //       "delete sub projects"
                    //     )))
                    props.loggedInUserData?.permissions?.includes("edit sub projects")
                }
                handleViewPage={(rowData) => {
                    primetoast.current.show({ severity: "info", summary: "Subprojects", detail: "subprojects" });
                    navigate("subproject", { state: { subProjectsData: rowData, selectedprojectItem: selectedprojectItem } });
                    // navigate("project", { state: { projectData: rowData } });
                }}
                showViewPage={true}
                hideRowViewPage={false}
                loading={fetchAllSubProjects.isLoading}
                excelexporting={true}
                downloadExcel={downloadExcel}
                exportButton={true}
                pdfExportTitle="subprojects"
                csvExportTitle="subprojects"
            />
            {/* </TabPanel>
                <TabPanel header="Metrics">
                    <SubProjectIndicatorsPage projectId={projectId} />
                </TabPanel>
            </TabView> */}

            <PrimeReactToast ref={primetoast} />
            {/* Confirmation Dialog */}
            <Dialog
                visible={showConfirmDialog}
                style={{ width: "450px" }}
                header="Confirm"
                modal
                footer={
                    <>
                        <Button label="No" icon="pi pi-times" onClick={() => setShowConfirmDialog(false)} className="p-button-text" />
                        <Button label="Yes" icon="pi pi-check" onClick={confirmDelete} autoFocus />
                    </>
                }
                onHide={() => setShowConfirmDialog(false)}
            >
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {itemToDelete && <span>Are you sure you want to delete this item?</span>}
                </div>
            </Dialog>

            {/* Other components like SubProjectsCreateForm, SubProjectsEditForm, ProjectReportPDFPage, etc. */}
            {(loading || (GetSubProjectByFilterOptions.isLoading && GetSubProjectByFilterOptions.isFetching)) && <WaterIsLoading />}

            {showEditForm && <SubProjectsEditForm selectedprojectItem={selectedprojectItem} loggedInUserData={props?.loggedInUserData} subprojectdata={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} />}
            {/* {!loading && showPDF && <ProjectReportPDFPage projectid={projectId} financialyear={financial_year} quarter={quarter} show={showPDF} onHide={handleClosePDF} onClose={handleClosePDF} />} */}
        </div>
    );
}

export default SubProjectsList;
