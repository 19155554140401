import axiosAPI from "../../axiosApi";

export async function getAllDepartmentTarrifs(params = {}) {
    const response = await axiosAPI.get("tarrifs", { params: params });
    return response;
}

export async function getDepartmentTarrifById(id) {
    const response = await axiosAPI.get(`tarrifs/${id}`);
    return response;
}

export async function postDepartmentTarrif(data) {
    const response = await axiosAPI.post(`tarrifs`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function updateDepartmentTarrif(id, data) {
    try {
        console.log("api udate");
        data.forEach(function (value, key) {
            console.log(key, value);
        });
        const response = await axiosAPI.post(`tarrifs/${id}`, data, {
            headers: { "Content-Type": "multipart/form-data" },
        });
        return response;
    } catch (error) {
        console.error("Error updating department tariff:", error);
        throw error; // Re-throw the error to handle it at the caller level if needed
    }
}

export async function deleteDepartmentTarrifById(id) {
    const response = await axiosAPI.delete(`tarrifs/${id}`);
    return response;
}
