import React, { useState, useEffect } from "react";
import UgandaGeoJson from "./widgets/resources/GeoJsonFiles/uganda.json";
import CountriesGeoJson from "./widgets/resources/GeoJsonFiles/countries.json";
import { MapContainer, Map, TileLayer, useMap, Marker, Popup, GeoJSON, LayersControl } from "react-leaflet";
import * as L from "leaflet";

// import MapFilters from "../MapFilters/MapFilters";
import { TabView, TabPanel } from "primereact/tabview";

import { Accordion, AccordionTab } from "primereact/accordion";
import Filters from "./widgets/Filters";
import ProjectFilters from "./widgets/ProjectFilters";
import { ProgressBar } from "primereact/progressbar";
import "leaflet-easyprint";
import "leaflet-fullscreen";
import "leaflet-fullscreen/dist/Leaflet.fullscreen.js";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import MarkerClusterGroup from "react-leaflet-cluster";
import "leaflet/dist/leaflet.css";
import MapPrint from "./widgets/MapPrint";
import Typewriter from "typewriter-effect";

function DistrictsMap({ loggedInUserData }) {
    // const districtStyle = {
    //     fillColor: "red",
    //     fillOpacity: 1,
    //     color: "black",
    //     weight: 2,
    //     dashArray: 0,
    // };

    const { BaseLayer, Overlay } = LayersControl;
    const colors = ["#FF3D00", "#F4511E", "#FF6D00", "#FF9800", "#FFC107", "#FFEB3B", "#CDDC39", "#8BC34A", "#4CAF50", "#009688", "#00BCD4", "#03A9F4", "#2196F3", "#3F51B5", "#673AB7", "#9C27B0", "#E91E63", "#F50057", "#FF4081", "#FF5252"];

    const handleEachDistrict = (district, layer) => {
        console.log("district is : ", district);
        const districtName = district.properties.name;
        layer.bindPopup(`<h4>${districtName}</h4>`);

        const colorIndex = Math.floor(Math.random() * 21);

        // layer.options.fillColor = colors[colorIndex];
        console.log("layer.options : ", layer.options);
        layer.on({
            //   click: (event) => {
            //     event.target.setStyle({
            //       color: "green",
            //       fillColor: "yellow",
            //       fillOpacity: "0.5"
            //     });
            //   },
            // mouseover: (event) => {
            //     event.target.setStyle({
            //         color: "green",
            //         fillColor: "yellow",
            //         fillOpacity: "0.5",
            //     });
            // },
            // mouseout: (event) => {
            //     event.target.setStyle({
            //         fillColor: "red",
            //         fillOpacity: 1,
            //         color: "black",
            //     });
            // },
        });
    };

    //new
    // const onEachDistrict = (feature, layer) => {
    //     // Check if the feature has properties, which should include the district name
    //     if (feature.properties && feature.properties.name) {
    //         layer.bindPopup(feature.properties.name);
    //     }
    // };

    const districtStyle = {
        fillColor: "blue",
        weight: 2,
        opacity: 1,
        color: "white",
        fillOpacity: 0.2,
    };

    //Accodiaum
    // State to manage active tabs, empty array means all tabs are closed
    const [activeIndex, setActiveIndex] = React.useState(null);

    const [filteredDataWithDistricts, setFilteredDataWithDistricts] = useState(null);
    const [filteredAdminGeoJson, setFilteredAdminGeoJson] = useState(null);
    const [filteredProjectGeoJson, setFilteredProjectGeoJson] = useState(null);

    const [filteredUtilityGeoJson, setFilteredUtilityGeoJson] = useState(null);

    console.log("filteredDataWithDistricts hh : ", filteredDataWithDistricts);

    // Color the districts and bind popups
    // const onEachDistrict = (feature, layer) => {
    //     const districtName = feature.properties.name;
    //     const colorIndex = Math.floor(Math.random() * colors.length);
    //     const districtColor = colors[colorIndex];

    //     layer.setStyle({
    //         fillColor: districtColor,
    //         fillOpacity: 0.5,
    //         color: "black",
    //         weight: 2,
    //     });

    //     // Create popup content using project details and district name
    //     const popupContent = `
    //         <h4>${districtName}</h4>
    //         <p><strong>Project Title:</strong> ${filteredDataWithDistricts?.project?.project_title}</p>
    //         <p><strong>Project Details:</strong> ${filteredDataWithDistricts?.project?.project_details}</p>
    //         <p><strong>Completion:</strong> ${filteredDataWithDistricts?.project?.completion}%</p>
    //         <p><strong>Start Date:</strong> ${filteredDataWithDistricts?.project?.start_date}</p>
    //         <p><strong>Proposed End Date:</strong> ${filteredDataWithDistricts?.project?.proposed_end_date}</p>
    //         <div style="background-color: #e0e0e0; border-radius: 8px; padding: 2px;">
    //     <div style="height: 20px; width: ${filteredDataWithDistricts?.project?.completion}%; background-color: green; border-radius: 5px; text-align: center; line-height: 20px; color: white;">
    //         ${filteredDataWithDistricts?.project?.completion}% Complete
    //     </div>
    // </div>

    //     `;

    //     //  layer.bindPopup(`<h4>${districtName}</h4>`);
    //     layer.bindPopup(popupContent);
    // };

    // State to store the filtered GeoJSON data for the map
    const [filteredGeoJson, setFilteredGeoJson] = useState(null);
    console.log("filteredGeoJson sdfdsv : ", filteredGeoJson);

    // Function to filter GeoJSON data based on the provided districts
    const filterGeoJsonForDistricts = (districts, isProject = false) => {
        return {
            ...UgandaGeoJson,
            features: UgandaGeoJson.features
                .filter((feature) => districts.some((district) => district.name.toLowerCase() === feature.properties.name.toLowerCase()))
                .map((feature) => {
                    if (isProject) {
                        // Assign a random color for project districts
                        const colorIndex = Math.floor(Math.random() * colors.length);
                        feature.properties.fillColor = colors[colorIndex];
                    }
                    return feature;
                }),
        };
    };

    // Effect to update GeoJSON data when filteredDataWithDistricts changes
    useEffect(() => {
        if (filteredDataWithDistricts?.utility?.districts) {
            const newFilteredUtilityGeoJson = filterGeoJsonForDistricts(filteredDataWithDistricts?.utility?.districts);
            setFilteredUtilityGeoJson(newFilteredUtilityGeoJson);
        } else {
            setFilteredUtilityGeoJson(null);
        }

        if (filteredDataWithDistricts?.department_administration_districts) {
            const newFilteredAdminGeoJson = filterGeoJsonForDistricts(filteredDataWithDistricts.department_administration_districts);
            setFilteredAdminGeoJson(newFilteredAdminGeoJson);
        } else {
            setFilteredAdminGeoJson(null);
        }
        if (filteredDataWithDistricts?.project_districts) {
            const newFilteredProjectGeoJson = filterGeoJsonForDistricts(filteredDataWithDistricts.project_districts, true);
            setFilteredProjectGeoJson(newFilteredProjectGeoJson);
        } else {
            setFilteredProjectGeoJson(null);
        }
    }, [filteredDataWithDistricts]);

    // Style for admin districts (only borders)
    const adminDistrictStyle = {
        weight: 2,
        opacity: 1,
        color: "black",
        fillOpacity: 0,
    };

    // Style for project districts (with colors)
    const projectDistrictStyle = (feature) => ({
        weight: 2,
        opacity: 1,
        color: "black",
        fillColor: feature.properties.fillColor,
        fillOpacity: 0.1,
    });

    const onEachDistrict = (feature, layer) => {
        const featureDistrictName = feature.properties.name.toLowerCase();

        // Check if project_districts is defined and has the matching district
        if (filteredDataWithDistricts?.project_districts?.some((d) => d.name.toLowerCase() === featureDistrictName)) {
            // Popup content for project districts
            const projectPopupContent = `
            <h4>${feature.properties.name}</h4>
            <p><strong>Project Title:</strong> ${filteredDataWithDistricts?.project?.project_title}</p>
            <p><strong>Project Details:</strong> ${filteredDataWithDistricts?.project?.project_details}</p>
            <p><strong>Completion:</strong> ${filteredDataWithDistricts?.project?.completion}%</p>
            <p><strong>Start Date:</strong> ${filteredDataWithDistricts?.project?.start_date}</p>
            <p><strong>Proposed End Date:</strong> ${filteredDataWithDistricts?.project?.proposed_end_date}</p>
            <div style="background-color: #e0e0e0; border-radius: 8px; padding: 2px;">
                <div style="height: 20px; width: ${filteredDataWithDistricts?.project?.completion}%; background-color: green; border-radius: 5px; text-align: center; line-height: 20px; color: white;">
                    ${filteredDataWithDistricts?.project?.completion}% Complete
                </div>
            </div>`;
            layer.bindPopup(projectPopupContent);
        }
        // Check if department_administration_districts is defined and has the matching district
        else if (filteredDataWithDistricts?.department_administration_districts?.some((d) => d.name.toLowerCase() === featureDistrictName)) {
            // Popup content for department administration districts
            const adminPopupContent = `
            <h4>${feature.properties.name}</h4>
            <p><strong>Department Administration:</strong> ${filteredDataWithDistricts.department_administration.name}</p>
            <p><strong>Details:</strong> ${filteredDataWithDistricts.department_administration.details}</p>`;
            layer.bindPopup(adminPopupContent);
        }
    };

    //
    const onEachUtilityDistrict = (feature, layer) => {
        const featureDistrictName = feature.properties.name.toLowerCase();

        // Check if utility districts is defined and has the matching district
        if (filteredDataWithDistricts?.utility?.districts?.some((d) => d.name.toLowerCase() === featureDistrictName)) {
            // Popup content for utility districts
            const utilityPopupContent = `
        <h4>${feature.properties.name}</h4>
        <p><strong>Utility Name:</strong> ${filteredDataWithDistricts?.utility?.name}</p>
        <p><strong>Utility Code:</strong> ${filteredDataWithDistricts?.utility?.code}</p>
        <p><strong>Details:</strong> ${filteredDataWithDistricts?.utility?.details}</p>`;
            layer.bindPopup(utilityPopupContent);
        }
        // Additional conditions can be added here similar to how department_administration_districts was checked
    };

    //
    // Function to create marker popups
    const createMarkerPopup = (location) => {
        return (
            <div>
                <p>
                    <strong>Subproject Name:</strong> {filteredDataWithDistricts?.subproject?.name}
                </p>
                <div>
                    <strong>Completion:</strong>
                    <ProgressBar value={filteredDataWithDistricts?.subproject?.completion} label={`${filteredDataWithDistricts?.subproject?.completion}% Complete`} showValue={true} />
                </div>
                <p>
                    <strong>Start Date:</strong> {filteredDataWithDistricts?.subproject?.start_date}
                </p>
                <p>
                    <strong>Proposed End Date:</strong> {filteredDataWithDistricts?.subproject?.proposed_end_date}
                </p>
                <div style={{ marginTop: "1rem" }}>
                    <center>
                        <h3>Indicator</h3>
                    </center>
                    <p>
                        <strong>Output Indicator Name:</strong> {location.outputIndicator}
                    </p>
                    <p>
                        <strong>District:</strong> {location.districtName}
                    </p>
                    <p>
                        <strong>County:</strong> {location.countyName}
                    </p>
                    <p>
                        <strong>Sub-County:</strong> {location.subCountyName}
                    </p>
                    <p>
                        <strong>Parish:</strong> {location.parishName}
                    </p>
                    <p>
                        <strong>Village:</strong> {location.villageNameName}
                    </p>

                    <p>
                        <strong>Identifier:</strong> {location.identifier}
                    </p>
                    <p>
                        <strong>Latitude:</strong> {location.latitude}
                    </p>
                    <p>
                        <strong>Longitude:</strong> {location.longitude}
                    </p>
                </div>
            </div>
        );
    };

    //
    const createGazettePopup = (gazette) => {
        return (
            <div>
                <p>
                    <strong>Scheme Name:</strong> {gazette.name ?? "NA"}
                </p>

                <p>
                    <strong>Year of Construction:</strong> {gazette.year_of_construction ? new Date(gazette.year_of_construction).getFullYear() : "NA"}
                </p>

                <p>
                    <strong>District:</strong> {gazette.district?.name ?? "NA"}
                </p>
                <p>
                    <strong>County:</strong> {gazette.county?.name ?? "NA"}
                </p>
                <p>
                    <strong>Sub-County:</strong> {gazette.sub_county?.name ?? "NA"}
                </p>
                <p>
                    <strong>Parish:</strong> {gazette.parish?.name ?? "NA"}
                </p>
                <p>
                    <strong>Village:</strong> {gazette.village?.name ?? "NA"}
                </p>
                <p>
                    <strong>Latitude:</strong> {gazette.latitude ?? "NA"}
                </p>
                <p>
                    <strong>Longitude:</strong> {gazette.longitude ?? "NA"}
                </p>

                <p>
                    <strong>Assessment:</strong> {gazette.assessment ?? "NA"}
                </p>
                {/* <div style={{ marginTop: "1rem" }}>
                    <p>
                        <strong>Created by:</strong> {gazette.creator?.name ?? "NA"}
                    </p>
                    <p>
                        <strong>Updated by:</strong> {gazette.updater?.name ?? "NA"}
                    </p>
                </div> */}
            </div>
        );
    };

    const handleMapCreated = (map) => {
        map.options.zoomDelta = 5;
        map.options.wheelPxPerZoomLevel = 12;
    };

    return (
        <div>
            <div style={{ position: "relative" }}>
                <div>
                    <h4>
                        <Typewriter
                            options={{
                                strings: "Projects Map",
                                autoStart: true,
                                loop: false,
                                cursor: "",
                                delay: 2,
                            }}
                        />
                    </h4>
                    <p>
                        <Typewriter
                            options={{
                                strings:
                                    "The Projects Map dashboard offers a detailed visualization, showcasing a spectrum of water-related initiatives led by distinct departments such as Rural Water Supply and Sanitation, Urban Water Supply and Sewerage Services, and Water for Production. Furthermore, it highlights the gazetted schemes overseen by various utilities within the Water Utility Regulation department, including umbrella organizations. This dynamic interface allows users to sift through and comprehend the extensive scope of Uganda’s water management projects and regulatory actions. By providing a clear and organized view, the dashboard facilitates deeper insights into the nation's dedicated efforts towards enhancing water access, regulatory compliance, and environmental stewardship.",
                                autoStart: true,
                                loop: false,
                                cursor: "",
                                delay: 2,
                            }}
                        />
                    </p>
                </div>
            </div>
            <br />

            <div>
                <Accordion activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <AccordionTab header="Filters">
                        <div>
                            {/* <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                                <TabPanel header="Projects"> */}
                            <ProjectFilters setFilteredDataWithDistricts={setFilteredDataWithDistricts} loggedInUserData={loggedInUserData} setFilteredGeoJson={setFilteredGeoJson} />
                            {/* </TabPanel>
                                <TabPanel header="Sublocations">
                                    <Filters />
                                </TabPanel>
                            </TabView> */}
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>

            <h4 style={{ textAlign: "center" }}>
                <strong>{filteredDataWithDistricts?.project?.project_title ?? "map"}</strong>
            </h4>
            <>
                <div style={{ maxWidth: "90vw" }}>
                    {/* <MapContainer center={[1.3733, 32.2903]} zoom={7} scrollWheelZoom={false} attributionControl={false} style={{ height: "80vh" }}>
                        <GeoJSON
                            // style={districtStyle}
                            data={UgandaGeoJson.features}
                            onEachFeature={handleEachDistrict}
                        />
                    </MapContainer> */}
                    <MapContainer center={[1.3733, 32.2903]} zoom={7} scrollWheelZoom={true} attributionControl={false} fullscreenControl={true} whenCreated={handleMapCreated} style={{ height: "80vh", width: "100%", zIndex: "0" }}>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors' />
                        {filteredAdminGeoJson && <GeoJSON style={adminDistrictStyle} data={filteredAdminGeoJson} onEachFeature={onEachDistrict} />}
                        {filteredProjectGeoJson && <GeoJSON style={projectDistrictStyle} data={filteredProjectGeoJson} onEachFeature={onEachDistrict} />}
                        {filteredUtilityGeoJson && <GeoJSON style={projectDistrictStyle} data={filteredUtilityGeoJson} onEachFeature={onEachUtilityDistrict} />}
                        <LayersControl>
                            <BaseLayer checked name="OpenStreetMap">
                                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                            </BaseLayer>

                            <BaseLayer name="Terrain View">
                                <TileLayer url="https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}" maxZoom={20} subdomains={["mt1", "mt2", "mt3"]} />
                            </BaseLayer>

                            <BaseLayer name="Satellite View">
                                <TileLayer url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" maxZoom={20} subdomains={["mt1", "mt2", "mt3"]} />
                            </BaseLayer>
                            <BaseLayer name="Hybrid View">
                                <TileLayer url="https://{s}.google.com/vt/lyrs=h&x={x}&y={y}&z={z}" maxZoom={20} subdomains={["mt1", "mt2", "mt3"]} />
                            </BaseLayer>
                        </LayersControl>
                        {/* Add markers for each location */}
                        <MarkerClusterGroup chunkedLoading>
                            {filteredDataWithDistricts?.subproject_locations?.map((location, index) => {
                                // Function to clean the input string and convert to float
                                const cleanAndConvert = (coord) => {
                                    if (!coord) return null;
                                    const cleaned = coord.replace(/[^0-9.-]/g, ""); // Remove any characters except digits, the minus sign, and the dot
                                    const float = parseFloat(cleaned);
                                    return isNaN(float) ? null : float; // Return null if conversion fails
                                };

                                const latitude = cleanAndConvert(location?.latitude);
                                const longitude = cleanAndConvert(location?.longitude);

                                if (latitude !== null && longitude !== null) {
                                    return (
                                        <Marker key={index} position={[latitude, longitude]}>
                                            <Popup>{createMarkerPopup(location)}</Popup>
                                        </Marker>
                                    );
                                }
                                return null;
                            })}

                            {filteredDataWithDistricts?.utility?.gazettes?.map((location, index) => {
                                // Function to clean the input string and convert to float
                                const cleanAndConvert = (coord) => {
                                    if (!coord) return null;
                                    const cleaned = coord.replace(/[^0-9.-]/g, ""); // Remove any characters except digits, the minus sign, and the dot
                                    const float = parseFloat(cleaned);
                                    return isNaN(float) ? null : float; // Return null if conversion fails
                                };

                                const latitude = cleanAndConvert(location?.latitude);
                                const longitude = cleanAndConvert(location?.longitude);

                                if (latitude !== null && longitude !== null) {
                                    return (
                                        <Marker key={index} position={[latitude, longitude]}>
                                            <Popup>{createGazettePopup(location)}</Popup>
                                        </Marker>
                                    );
                                }
                                return null;
                            })}
                        </MarkerClusterGroup>
                        //
                        <MapPrint />
                    </MapContainer>
                </div>
            </>
        </div>
    );
}

export default DistrictsMap;
