import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import CreateForm from "./CreateForm";

import EditForm from "./EditForm";
import { useNavigate } from "react-router-dom";
import { deleteProgramById, getAllPrograms } from "../../../../services/programs/program-service";

import moment from "moment";

import { Link } from "react-router-dom";

import { getAllDepartmentBudjetOutputs, getDepartmentBudjetOutputById, postDepartmentBudjetOutput, updateDepartmentBudjetOutput, deleteDepartmentBudjetOutputById } from "../../../../services/departments/departments-budget-outputs-service.js";

import WaterIsLoading from "../../../../components/mweComponents/WaterIsLoading";
import MuiTable from "../../../../components/mweComponents/MuiTable";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import { Panel } from "primereact/panel";
import { Tooltip } from "primereact/tooltip";

import DepartmentsBudgetOutputIndicatorsModal from "./DepartmentsBudgetOutputIndicatorsModal";

function ListPage({ selectedDepartmentItem, ...props }) {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    console.log("props?.departmentId dsfsd  : ", props?.departmentId);

    const { data, isLoading, isError, error, status } = useQuery(["departments", "departmentBudgetOutputs", props?.departmentId], () => (!props?.departmentId ? getAllDepartmentBudjetOutputs() : getAllDepartmentBudjetOutputs({ department_id: props?.departmentId })), {
        onSuccess: (data) => {
            console.log("fetchAllDepartmentBudjetOutputs : ", data);
        },
        onError: (error) => {
            console.log("Error fetching projectSubprojectMetricsIndicator is : ", error);
        },
    });
    console.log(data);
    isError && toast.error("There was an error while fetching data");

    const DeleteSelectedItemMutation = useMutation((variables) => deleteDepartmentBudjetOutputById(variables), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(["departments", "departmentBudgetOutputs"]);
        },
    });

    // const handleDelete = async (event, id) => {
    //     var result = window.confirm("Are you sure you want to delete?");
    //     if (result === true) {
    //         ProgramDeleteMutation.mutate(id);
    //     }
    // };

    const handleDelete = (event, id) => {
        let selectedDeleteId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(selectedDeleteId),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (selectedDeleteId) => {
        if (selectedDeleteId !== null) {
            DeleteSelectedItemMutation.mutate(selectedDeleteId);
        }
    };

    const cancelDelete = () => {
        // setDeleteProgramId(null);
    };

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showBudjetOutPutAddForm, setShowBudjetOutPutAddForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    const onBudjetOutPutFormClose = () => {
        setShowBudjetOutPutAddForm(false);
    };

    //================== budget outputs indicators ==================
    const [selectedBudgetOutputItem, setSelectedBudgetOutputItem] = useState();

    const [showBudgetOutputIndicatorAddForm, setShowBudgetOutputIndicatorAddForm] = useState(false);
    const [showBudgetOutputIndicatorView, setShowBudgetOutputIndicatorView] = useState(false);

    const handleCloseBudgetOutputIndicatorView = () => {
        setSelectedBudgetOutputItem(null);
        setShowBudgetOutputIndicatorView(false);
    };

    let tableId = 0;
    const columns = [
        {
            title: "#",
            width: "5%",
            field: "name",
            render: (rowData) => {
                // tableId = rowData.tableData.id;
                tableId = tableId++;
                return <div>{rowData.tableData.index + 1}</div>;
                // return <div>{rowData.tableData.id}</div>;
            },
        },

        {
            title: "Name",
            field: "name",
        },
        {
            title: "Code",
            field: "code",
        },
        { title: "Department", field: "department.name" },

        {
            title: "Date",
            field: "created_at",
            hidden: true,
            render: (rowData) => {
                return moment(rowData.created_at).format("lll");
            },
        },
    ];

    return (
        <div style={{ width: "100%" }}>
            <div className="col-12 xl:col-12">
                <div className="card">
                    <p>Budget Outputs are goals defined at department level which are later used by projects and these budget outputs have there specific indicators</p>
                </div>
            </div>
            <Panel header="Budget Outputs" style={{ marginBottom: "20px" }}>
                {/* <div className="d-flex justify-content-end mb-3" style={{ width: "100%" }}>
                    {activeUser?.permissions.includes("add programs") && <Button label="Add Program" className="p-button-primary right-0" onClick={() => setShowAddForm(true)} />}
                    <CreateForm show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} />
                </div> */}
                <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                    {/* {activeUser?.permissions.includes("add programs") && (
                        <Button
                            label="Add Budget Outputs"
                            className="p-button-primary"
                            onClick={() => {
                                setShowBudjetOutPutAddForm(true);
                                console.log("clicked add outcomes");
                            }}
                        />
                    )} */}
                    {activeUser?.permissions.includes("edit departments") && <Button label="Add Budget Output" className="p-button-primary" onClick={() => setShowAddForm(true)} />}
                    <CreateForm selectedDepartmentItem={selectedDepartmentItem} departmentId={props?.departmentId} show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} projectId={props?.projectId} />
                    {/* <BudgetOutPutsCreateForm show={showBudjetOutPutAddForm} onHide={() => setShowBudjetOutPutAddForm(false)} onClose={onBudjetOutPutFormClose} /> */}
                </div>
                <MuiTable
                    tableTitle="Budget Outputs"
                    tableData={data?.data?.data}
                    tableColumns={columns}
                    handleShowEditForm={handleShowEditForm}
                    handleDelete={(e, item_id) => handleDelete(e, item_id)}
                    showEdit={activeUser?.permissions.includes("edit departments") || activeUser?.permissions.includes("edit departments")}
                    showDelete={activeUser?.permissions.includes("edit departments")}
                    loading={isLoading || status === "loading" || DeleteSelectedItemMutation.isLoading}
                    handleViewPage={(rowData) => {
                        navigate("budgetOutput", { state: { selectedBudjetOutputData: rowData } });
                    }}
                    showViewPage={true}
                    hideRowViewPage={false}
                    exportButton={true}
                    pdfExportTitle="Department budget outputs"
                    csvExportTitle="Department budget outputs"
                />
                {selectedItem && <EditForm selectedDepartmentItem={selectedDepartmentItem} rowData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} />}
                <DepartmentsBudgetOutputIndicatorsModal rowData={selectedBudgetOutputItem} show={showBudgetOutputIndicatorView} onHide={handleCloseBudgetOutputIndicatorView} onClose={handleCloseBudgetOutputIndicatorView} />

                {DeleteSelectedItemMutation.isLoading && <WaterIsLoading />}
                {/* <ConfirmDialog /> */}
            </Panel>
        </div>
    );
}

export default ListPage;
