import React from "react";
import { Card } from "primereact/card";
import { Image } from "primereact/image";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DownloadIcon from "@mui/icons-material/Download";

const PhotosHistory = ({ selectedSubProjectReports }) => {
    const handleDownload = (photoUrl, photoName) => {
        // Create an anchor element
        const anchor = document.createElement("a");
        anchor.href = photoUrl;
        anchor.download = photoName || "downloaded_image";

        // Append anchor to the body
        document.body.appendChild(anchor);

        // Trigger the download
        anchor.click();

        // Remove the anchor from the body
        document.body.removeChild(anchor);
    };

    return (
        <div>
            {selectedSubProjectReports.map((report, index) => (
                <Card key={index} title={report.subproject.name} subTitle={`Financial Year: ${report.financial_year.name}`}>
                    <div className="flex flex-wrap">
                        {report.photos.map((photo, photoIndex) => (
                            <div key={photoIndex} className="p-2" style={{ maxWidth: "100px" }}>
                                <Image src={`${process.env.REACT_APP_API_BASE_URL}${photo.photo_url}`} downloadable={true} alt={`Photo ${photoIndex}`} preview width="250" />
                                <div style={{ display: "flex", gap: "1rem" }}>
                                    <p>{photo.caption}</p>
                                    {/* <Tooltip title="Download">
                                        <IconButton
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleDownload(`${process.env.REACT_APP_API_BASE_URL}${photo.photo_url}`, `Photo_${photoIndex}`);
                                            }}
                                        >
                                            <DownloadIcon />
                                        </IconButton>
                                    </Tooltip> */}
                                </div>
                            </div>
                        ))}
                    </div>
                </Card>
            ))}
        </div>
    );
};

export default PhotosHistory;
