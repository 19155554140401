import React from "react";
import ListPage from "./ListPage";
import { Link } from "react-router-dom";

import BreadcrumbNav from "../../../../components/mweComponents/BreadcrumbNav";

const createBreadCrump = () => {};
//
function MonthsPage({ projectId }) {
    let linksData = [{ name: "programs" }];
    return (
        <div>
            <BreadcrumbNav />

            <ListPage projectId={projectId} />
        </div>
    );
}

export default MonthsPage;
