import React from "react";

import SubProjectsRowForm from "./widgets/SubProjectsRowForm";
import { ToastContainer } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";
function SubProjectsCreateForm({ selectedprojectItem, ...props }) {
    return (
        <>
            <Dialog header="Sub Projects Form" visible={props.show} onHide={props.onHide} maximizable>
                <SubProjectsRowForm selectedprojectItem={selectedprojectItem} show={props?.show} onHide={props?.onHide} currentProjectId={props?.currentProjectId} onClose={props?.onClose} loggedInUserData={props?.loggedInUserData} />
                <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />{" "}
            </Dialog>
        </>
    );
}

export default SubProjectsCreateForm;
