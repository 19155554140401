import axiosAPI from "../axiosApi";

export async function getAllfunders(params = {}) {
    const response = await axiosAPI.get("funders", { params: params });
    return response;
}

export async function getfundersById(id) {
    const response = await axiosAPI.get(`funders/${id}`);
    return response;
}

export async function postfunders(data) {
    const response = await axiosAPI.post(`funders`, data);
    return response;
}

export async function updatefunders(id, data) {
    const response = await axiosAPI.put(`funders/${id}`, data);
    return response;
}

export async function deletefundersById(id) {
    const response = await axiosAPI.delete(`funders/${id}`);
    return response;
}
