import React, { useState, useEffect } from "react";

import MarkerClusterGroup from "react-leaflet-cluster";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-easyprint";
import "leaflet-fullscreen";
import "leaflet-fullscreen/dist/Leaflet.fullscreen.js";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import MapPrint from "./MapPrint";
import StatisticsDisplay from "./StatisticsDisplay";
import { Card } from "primereact/card";
import { Panel } from "primereact/panel";
import { ProgressSpinner } from "primereact/progressspinner";
import { ProgressBar } from "primereact/progressbar";
import UgandaGeoJson from "./GeoJsonFiles/uganda.json";
import CountriesGeoJson from "./GeoJsonFiles/countries.json";
import { MapContainer, Map, TileLayer, useMap, Marker, Popup, GeoJSON, LayersControl } from "react-leaflet";

// Fix icon issue in Leaflet 1.x
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const colors = ["#FF3D00", "#F4511E", "#FF6D00", "#FF9800", "#FFC107", "#FFEB3B", "#CDDC39", "#8BC34A", "#4CAF50", "#009688", "#00BCD4", "#03A9F4", "#2196F3", "#3F51B5", "#673AB7", "#9C27B0", "#E91E63", "#F50057", "#FF4081", "#FF5252"];

const WaterPointDistributionSourcesMap = ({ filterQueryIsLoading, setFilterQueryIsLoading, waterPointsData, fetchAllRecordsFromLastThreeYears, loggedInUserData }) => {
    const { BaseLayer } = LayersControl;
    console.log("waterPointsData in WaterPointDistributionSourcesMap is : ", waterPointsData);
    const handleMapCreated = (map) => {
        map.options.zoomDelta = 5;
        map.options.wheelPxPerZoomLevel = 12;
        map.createPane("customPane");
        map.getPane("customPane").style.zIndex = 0; //
    };

    const [filteredAdminGeoJson, setFilteredAdminGeoJson] = useState(null);
    const [filteredUtilityGeoJson, setFilteredUtilityGeoJson] = useState(null);

    // Function to filter GeoJSON data based on the provided department administration districts
    const filterGeoJsonForAdminDistricts = (districts) => {
        return {
            ...UgandaGeoJson,
            features: UgandaGeoJson.features.filter((feature) => districts.some((district) => district.name.toLowerCase() === feature.properties.name.toLowerCase())),
        };
    };

    // Function to filter GeoJSON data based on the provided districts
    const filterGeoJsonForDistricts = (districts, isProject = false) => {
        return {
            ...UgandaGeoJson,
            features: UgandaGeoJson.features
                .filter((feature) => districts.some((district) => district.name.toLowerCase() === feature.properties.name.toLowerCase()))
                .map((feature) => {
                    if (isProject) {
                        // Assign a random color for project districts
                        // const colorIndex = Math.floor(Math.random() * colors.length);
                        // feature.properties.fillColor = colors[colorIndex];
                    }
                    return feature;
                }),
        };
    };

    // Effect to update GeoJSON data when waterPointsData changes
    useEffect(() => {
        if (waterPointsData?.utility?.districts) {
            const newFilteredUtilityGeoJson = filterGeoJsonForDistricts(waterPointsData?.utility?.districts);
            setFilteredUtilityGeoJson(newFilteredUtilityGeoJson);
        } else {
            setFilteredUtilityGeoJson(null);
        }
        if (waterPointsData?.department_administration_districts) {
            const newFilteredAdminGeoJson = filterGeoJsonForAdminDistricts(waterPointsData.department_administration_districts);
            setFilteredAdminGeoJson(newFilteredAdminGeoJson);
        }
    }, [waterPointsData]);

    const adminDistrictStyle = {
        weight: 2,
        opacity: 1,
        color: "black",
        fillOpacity: 0,
    };

    // ... existing code ...

    // const onEachDistrict = (feature, layer) => {
    //     if (waterPointsData?.department_administration_districts?.some((d) => d.name.toLowerCase() === feature.properties.name.toLowerCase())) {
    //         const districtName = feature.properties.name; // Extracting the district name
    //         const adminPopupContent = `
    //             <h4>${feature.properties.name}</h4>
    //             <p><strong>Department Administration:</strong> ${waterPointsData.department_administration.name}</p>
    //              <p><strong>District:</strong> ${districtName}</p>
    //             `;

    //         // <p><strong>Details:</strong> ${waterPointsData.department_administration.details || "N/A"}</p>
    //         layer.bindPopup(adminPopupContent);
    //     }
    // };

    const onEachDistrict = (feature, layer) => {
        const matchedDistrict = waterPointsData?.department_administration_districts?.find((d) => d.name.toLowerCase() === feature.properties.name.toLowerCase());
        if (matchedDistrict) {
            const adminPopupContent = `
            <h4>${matchedDistrict.name}</h4>
            <p><strong>Department Administration:</strong> ${waterPointsData.department_administration.name}</p>
            <p><strong>District:</strong> ${matchedDistrict.name}</p>`;
            layer.bindPopup(adminPopupContent);
        }
    };

    //
    //
    const createGazettePopup = (gazette) => {
        return (
            <div>
                <p>
                    <strong>Scheme Name:</strong> {gazette.name ?? "NA"}
                </p>

                <p>
                    <strong>Year of Construction:</strong> {gazette.year_of_construction ? new Date(gazette.year_of_construction).getFullYear() : "NA"}
                </p>

                <p>
                    <strong>District:</strong> {gazette.district?.name ?? "NA"}
                </p>
                <p>
                    <strong>County:</strong> {gazette.county?.name ?? "NA"}
                </p>
                <p>
                    <strong>Sub-County:</strong> {gazette.sub_county?.name ?? "NA"}
                </p>
                <p>
                    <strong>Parish:</strong> {gazette.parish?.name ?? "NA"}
                </p>
                <p>
                    <strong>Village:</strong> {gazette.village?.name ?? "NA"}
                </p>
                <p>
                    <strong>Latitude:</strong> {gazette.latitude ?? "NA"}
                </p>
                <p>
                    <strong>Longitude:</strong> {gazette.longitude ?? "NA"}
                </p>

                <p>
                    <strong>Assessment:</strong> {gazette.assessment ?? "NA"}
                </p>
                {/* <div style={{ marginTop: "1rem" }}>
                    <p>
                        <strong>Created by:</strong> {gazette.creator?.name ?? "NA"}
                    </p>
                    <p>
                        <strong>Updated by:</strong> {gazette.updater?.name ?? "NA"}
                    </p>
                </div> */}
            </div>
        );
    };

    //
    // Style for project districts (with colors)
    const projectDistrictStyle = (feature) => ({
        weight: 2,
        opacity: 1,
        color: "black",
        fillColor: feature.properties.fillColor,
        fillOpacity: 0.1,
    });

    //

    //
    const onEachUtilityDistrict = (feature, layer) => {
        const featureDistrictName = feature.properties.name.toLowerCase();

        // Check if utility districts is defined and has the matching district
        if (waterPointsData?.utility?.districts?.some((d) => d.name.toLowerCase() === featureDistrictName)) {
            // Popup content for utility districts
            const utilityPopupContent = `
        <h4>${feature.properties.name}</h4>
        <p><strong>Utility Name:</strong> ${waterPointsData?.utility?.name}</p>
        <p><strong>Utility Code:</strong> ${waterPointsData?.utility?.code}</p>
        <p><strong>Details:</strong> ${waterPointsData?.utility?.details}</p>`;
            layer.bindPopup(utilityPopupContent);
        }
        // Additional conditions can be added here similar to how department_administration_districts was checked
    };

    console.log("test national waterPointsData : ", waterPointsData);
    console.log("test national fetchAllRecordsFromLastThreeYears?.data?.data : ", fetchAllRecordsFromLastThreeYears?.data?.data);

    return (
        <>
            <div className="col-12 xl:col-12">
                <Panel header="Water Point Distribution Sources Map" toggleable style={{ minwidth: "100%" }}>
                    {(fetchAllRecordsFromLastThreeYears?.isLoading || filterQueryIsLoading) && (
                        <div className="p-field m-4">
                            <ProgressBar mode="indeterminate" style={{ height: "6px" }} />
                        </div>
                    )}
                    <MapContainer center={[1.3733, 32.2903]} zoom={7} scrollWheelZoom={true} attributionControl={false} fullscreenControl={true} whenCreated={handleMapCreated} pane="customPane" style={{ height: "80vh", width: "100%", zIndex: "0" }}>
                        {filteredAdminGeoJson && <GeoJSON style={adminDistrictStyle} data={filteredAdminGeoJson} onEachFeature={onEachDistrict} />}
                        {filteredUtilityGeoJson && <GeoJSON style={projectDistrictStyle} data={filteredUtilityGeoJson} onEachFeature={onEachUtilityDistrict} />}
                        <LayersControl>
                            <BaseLayer checked name="OpenStreetMap">
                                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                            </BaseLayer>

                            <BaseLayer name="Terrain View">
                                <TileLayer url="https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}" maxZoom={20} subdomains={["mt1", "mt2", "mt3"]} />
                            </BaseLayer>

                            <BaseLayer name="Satellite View">
                                <TileLayer url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" maxZoom={20} subdomains={["mt1", "mt2", "mt3"]} />
                            </BaseLayer>
                            <BaseLayer name="Hybrid View">
                                <TileLayer url="https://{s}.google.com/vt/lyrs=h&x={x}&y={y}&z={z}" maxZoom={20} subdomains={["mt1", "mt2", "mt3"]} />
                            </BaseLayer>
                        </LayersControl>
                        <MarkerClusterGroup>
                            {!filterQueryIsLoading &&
                                (waterPointsData?.data ?? (!!waterPointsData ? [] : fetchAllRecordsFromLastThreeYears?.data?.data?.data ?? [])).map((point, index) => {
                                    // Function to clean the input string and convert to float
                                    const cleanAndConvert = (coord) => {
                                        if (!coord) return null;
                                        const cleaned = coord.replace(/[^0-9.-]/g, ""); // Remove any characters except digits, the minus sign, and the dot
                                        const float = parseFloat(cleaned);
                                        return isNaN(float) ? null : float; // Return null if conversion fails
                                    };

                                    const latitude = cleanAndConvert(point?.latitude);
                                    const longitude = cleanAndConvert(point?.longitude);

                                    if (latitude !== null && longitude !== null) {
                                        return (
                                            <Marker key={index} position={[latitude, longitude]}>
                                                <Popup>
                                                    Name: {point?.source_type?.name}
                                                    <br />
                                                    Identifier: {point?.identifier}
                                                    <br />
                                                    Year: {point?.year_of_construction?.value}
                                                    <br />
                                                    District: {point?.district?.name}
                                                    <br />
                                                    County: {point?.county?.name}
                                                    <br />
                                                    Subcounty: {point?.subcounty?.name}
                                                    <br />
                                                    Parish: {point?.parish?.name}
                                                    <br />
                                                    Village: {point?.village_orig?.name}
                                                    <br />
                                                    Latitude: {latitude}
                                                    <br />
                                                    Longitude: {longitude}
                                                </Popup>
                                            </Marker>
                                        );
                                    }
                                    return null;
                                })}

                            {!filterQueryIsLoading &&
                                (waterPointsData?.subproject_locations ?? (!!waterPointsData ? [] : fetchAllRecordsFromLastThreeYears?.data?.data?.subproject_locations ?? []))?.map((location, index) => {
                                    // Function to clean the input string and convert to float
                                    const cleanAndConvert = (coord) => {
                                        if (!coord) return null;
                                        const cleaned = coord.replace(/[^0-9.-]/g, ""); // Remove any characters except digits, the minus sign, and the dot
                                        const float = parseFloat(cleaned);
                                        return isNaN(float) ? null : float; // Return null if conversion fails
                                    };

                                    const latitude = cleanAndConvert(location?.latitude);
                                    const longitude = cleanAndConvert(location?.longitude);

                                    if (latitude !== null && longitude !== null) {
                                        return (
                                            <Marker key={index} position={[latitude, longitude]}>
                                                <Popup>
                                                    <p>
                                                        <strong>Indicator Name:</strong> {location?.outputIndicator}
                                                    </p>
                                                    <p>
                                                        <strong>Identifier:</strong> {location?.identifier}
                                                    </p>
                                                    <p>
                                                        <strong>District:</strong> {location?.districtName}
                                                    </p>
                                                    <p>
                                                        <strong>County:</strong> {location?.countyName}
                                                    </p>
                                                    <p>
                                                        <strong>Sub-County:</strong> {location?.subCountyName}
                                                    </p>
                                                    <p>
                                                        <strong>Parish:</strong> {location?.parishName}
                                                    </p>
                                                    <p>
                                                        <strong>Village:</strong> {location?.villageNameName}
                                                    </p>

                                                    <p>
                                                        <strong>Latitude:</strong> {latitude}
                                                    </p>
                                                    <p>
                                                        <strong>Longitude:</strong> {longitude}
                                                    </p>
                                                </Popup>
                                            </Marker>
                                        );
                                    }
                                    return null;
                                })}

                            {!filterQueryIsLoading &&
                                waterPointsData?.utility?.gazettes?.map((location, index) => {
                                    // Function to clean the input string and convert to float
                                    const cleanAndConvert = (coord) => {
                                        if (!coord) return null;
                                        const cleaned = coord.replace(/[^0-9.-]/g, ""); // Remove any characters except digits, the minus sign, and the dot
                                        const float = parseFloat(cleaned);
                                        return isNaN(float) ? null : float; // Return null if conversion fails
                                    };

                                    const latitude = cleanAndConvert(location?.latitude);
                                    const longitude = cleanAndConvert(location?.longitude);

                                    console.log("The latitude is : " + latitude + " The longitude is : " + longitude);

                                    if (latitude !== null && longitude !== null) {
                                        return (
                                            <Marker key={index} position={[latitude, longitude]}>
                                                <Popup>{createGazettePopup(location)}</Popup>
                                            </Marker>
                                        );
                                    }
                                    return null;
                                })}
                        </MarkerClusterGroup>

                        <MapPrint />
                    </MapContainer>
                </Panel>
            </div>

            <div className="col-12 xl:col-12">
                <Panel header="Water Point Distribution Sources Statistics" toggleable style={{ minwidth: "100%" }}>
                    <StatisticsDisplay data={!filterQueryIsLoading ? waterPointsData ?? fetchAllRecordsFromLastThreeYears?.data?.data ?? [] : []} />
                </Panel>
            </div>
        </>
    );
};

export default WaterPointDistributionSourcesMap;
