import React, { useState, useRef, useEffect, useMemo } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";

import { getAllFinancialYears, getFinancialYearsById, postFinancialYears, updateFinancialYears, deleteFinancialYears } from "../../../../../../services/financial-years/financial-years-service";
import { getAllQuarters, getQuartersById, postQuarters, updateQuarters, deleteQuarters } from "../../../../../../services/financial-years/quarters";

import { postToFilterKPITargets } from "../../../../../../services/departments/utility-dept/department-utility-indicators-service";
import { getAllUtilityReports, getUtilityReportById, postUtilityReport, updateUtilityReport, deleteUtilityReportById } from "../../../../../../services/departments/utility-dept/departments-utility-report-service.js";

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { ProgressBar } from "primereact/progressbar";

import { ProgressSpinner } from "primereact/progressspinner";
import { useLocation } from "react-router-dom";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import BreadcrumbNav from "../../../../../../components/mweComponents/BreadcrumbNav";

import { Panel } from "primereact/panel";
import { TabView, TabPanel } from "primereact/tabview";
import { Accordion, AccordionTab } from "primereact/accordion";

import { useNavigate } from "react-router-dom";
import { toast as reactToastifyToast } from "react-toastify";

//
function UtilityReportingPage() {
    const queryClient = useQueryClient();
    const toast = useRef(null);
    const navigate = useNavigate();

    //
    let { state } = useLocation();
    let subprojectDetail = state ? state?.subprojectDetailData : null;
    let subprojectViewPageActiveIndex = state?.subprojectViewPageActiveIndex ? state?.subprojectViewPageActiveIndex : 0;
    let subprojectViewPageChildActiveIndex = state?.subprojectViewPageChildActiveIndex ? state?.subprojectViewPageChildActiveIndex : 0;
    console.log("Subprojects data got from state is : ", subprojectDetail);

    let selectedUtilityData = state ? state?.selectedUtilityData : null;

    console.log("selectedUtilityData data got from state is on utility report : ", selectedUtilityData);

    //
    const [financialYear, setFinancialYear] = useState();
    const [quarter, setQuarter] = useState("");
    const [name, setName] = useState("");
    const [details, setDetails] = useState("");
    const [kpiTargets, setKpiTargets] = useState([]);
    console.log("utility report kpiTargets : ", kpiTargets);
    const [formSubmissionStatus, setFormSubmissionStatus] = useState("draft");

    // Additional states
    const [errors, setErrors] = useState({});
    const [pendingData, setPendingData] = useState(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    //
    // ================== get finacial years =================

    const getAllFinancialYearsQuery = useQuery(["financialYear"], getAllFinancialYears, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching getAllFinancialYears is : ", error);
        },
    });

    // ================= quarters ================

    const getAllQuartersQuery = useQuery(["quarters"], getAllQuarters, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching quarters is : ", error);
        },
    });

    //===================== post to get get List Of utility indicator targets  ====================

    const postToGetListOfUtilityIndicatorTagetsMutation = useMutation(postToFilterKPITargets, {
        onSuccess: (data) => {
            console.log("data on success postToGetListOfUtilityIndicatorTagetsMutation : ", data);
            if (data?.data?.length < 1) {
                toast.current.show({ severity: "info", summary: "No Targets Found", detail: "No targets for the selected period", life: 3000 });
            } else {
                // const enhancedData = data?.data.map((item) => ({
                //     ...item,
                //     value: "",
                //     reasonOfVariance: "",
                //     assessment: "",
                // }));
                const enhancedData = data?.data?.data.map((item) => ({
                    ...item,
                    indicator_targets: item.indicator_targets.map((target) => ({
                        ...target,
                        value: "", // Set default or actual value
                        reasonOfVariance: "", // Set default or actual value
                        assessment: "", // Set default or actual value
                        numerator_value: "", // Set default or actual value
                        denominator_value: "", // Set default or actual value
                        score: "", // Set default or actual value
                    })),
                }));
                setKpiTargets(enhancedData);
            }
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
            console.log("create programs error : ", error);
        },
    });

    console.log("postToGetListOfUtilityIndicatorTagetsMutation kkkk : ", postToGetListOfUtilityIndicatorTagetsMutation);

    const handlePressFilter = async (event) => {
        event.preventDefault();

        postToGetListOfUtilityIndicatorTagetsMutation.mutate({ utility_id: selectedUtilityData?.id, quarter_id: quarter, fyear_id: financialYear });
    };

    //======================================= valdation logic =========================================
    const validateForm = () => {
        const validationErrors = {};

        // Validating top-level inputs
        if (!financialYear) validationErrors.financialYear = "Financial Year is required";
        if (!quarter) validationErrors.quarter = "Quarter is required";
        if (!name.trim()) validationErrors.name = "Name is required";
        if (!details.trim()) validationErrors.details = "Details are required";

        // Validating nested KPI targets
        kpiTargets.forEach((item, typeIndex) => {
            item.indicator_targets.forEach((target, indicatorIndex) => {
                if (!target.value.trim()) {
                    validationErrors[`value_${typeIndex}_${indicatorIndex}`] = "Value is required";
                }
                if (!target.reasonOfVariance.trim()) {
                    validationErrors[`reasonOfVariance_${typeIndex}_${indicatorIndex}`] = "Reason of Variance is required";
                }
                if (!target.assessment.trim()) {
                    validationErrors[`assessment_${typeIndex}_${indicatorIndex}`] = "Assessment is required";
                }

                if (target.utility_indicator.output_indicator.unit === "%") {
                    if (!target.numerator_value || target.numerator_value.toString().trim() === "") {
                        validationErrors[`numerator_${typeIndex}_${indicatorIndex}`] = "KPI Numerator is required";
                    }
                    if (!target.denominator_value || target.denominator_value.toString().trim() === "") {
                        validationErrors[`denominator_${typeIndex}_${indicatorIndex}`] = "KPI Denominator is required";
                    }
                    // if (!target.score || target.score.toString().trim() === "") {
                    //     validationErrors[`score_${typeIndex}_${indicatorIndex}`] = "KPI Score is required";
                    // }
                }
            });
        });

        setErrors(validationErrors);
        //return Object.keys(validationErrors).length === 0; // Return true if no errors
        return validationErrors;
    };

    //============================== handle change ===============================================

    const handleFinancialYearChange = (e) => {
        setFinancialYear(e.value);
        // Optionally reset errors for this field
        if (errors.financialYear) {
            setErrors({ ...errors, financialYear: null });
        }
    };

    const handleQuarterChange = (e) => {
        setQuarter(e.value);
        // Optionally reset errors for this field
        if (errors.quarter) {
            setErrors({ ...errors, quarter: null });
        }
    };

    // Handle change for the name field
    const handleNameChange = (e) => {
        setName(e.target.value);
        // Optionally reset errors for this field
        if (errors.name) {
            setErrors({ ...errors, name: null });
        }
    };

    // Handle change for the details field
    const handleDetailsChange = (e) => {
        setDetails(e.target.value);
        // Optionally reset errors for this field
        if (errors.details) {
            setErrors({ ...errors, details: null });
        }
    };

    //============================ handle kPI ==============================

    const handleReasonOfVarianceChange = (typeIndex, indicatorIndex, newReason) => {
        setKpiTargets((currentKpiTargets) =>
            currentKpiTargets.map((item, tIndex) =>
                tIndex === typeIndex
                    ? {
                          ...item,
                          indicator_targets: item.indicator_targets.map((indicator, iIndex) =>
                              iIndex === indicatorIndex
                                  ? {
                                        ...indicator,
                                        reasonOfVariance: newReason,
                                    }
                                  : indicator
                          ),
                      }
                    : item
            )
        );
    };

    const handleAssessmentChange = (typeIndex, indicatorIndex, newAssessment) => {
        setKpiTargets((currentKpiTargets) =>
            currentKpiTargets.map((item, tIndex) =>
                tIndex === typeIndex
                    ? {
                          ...item,
                          indicator_targets: item.indicator_targets.map((indicator, iIndex) =>
                              iIndex === indicatorIndex
                                  ? {
                                        ...indicator,
                                        assessment: newAssessment,
                                    }
                                  : indicator
                          ),
                      }
                    : item
            )
        );
    };

    const handleKPIValueChange = (typeIndex, indicatorIndex, newValue) => {
        setKpiTargets((currentKpiTargets) =>
            currentKpiTargets.map((item, tIndex) =>
                tIndex === typeIndex
                    ? {
                          ...item,
                          indicator_targets: item.indicator_targets.map((indicator, iIndex) =>
                              iIndex === indicatorIndex
                                  ? {
                                        ...indicator,
                                        value: newValue, // Update the value property
                                    }
                                  : indicator
                          ),
                      }
                    : item
            )
        );
    };

    const handleKPINumeratorChange = (typeIndex, rowIndex, newNumerator) => {
        setKpiTargets((currentTargets) =>
            currentTargets.map((type, index) =>
                index === typeIndex
                    ? {
                          ...type,
                          indicator_targets: type.indicator_targets.map((target, tIndex) =>
                              tIndex === rowIndex
                                  ? {
                                        ...target,
                                        numerator_value: newNumerator,
                                        value: target.utility_indicator.output_indicator.unit === "%" ? (target.denominator_value && parseFloat(target.denominator_value) !== 0 ? ((parseFloat(newNumerator) / parseFloat(target.denominator_value)) * 100).toFixed(2) : 0) : parseFloat(newNumerator),
                                    }
                                  : target
                          ),
                      }
                    : type
            )
        );
    };

    const handleKPIDenominatorChange = (typeIndex, rowIndex, newDenominator) => {
        setKpiTargets((currentTargets) =>
            currentTargets.map((type, index) =>
                index === typeIndex
                    ? {
                          ...type,
                          indicator_targets: type.indicator_targets.map((target, tIndex) =>
                              tIndex === rowIndex
                                  ? {
                                        ...target,
                                        denominator_value: newDenominator,
                                        value: target.utility_indicator.output_indicator.unit === "%" ? (newDenominator && parseFloat(newDenominator) !== 0 ? ((parseFloat(target.numerator_value || 0) / parseFloat(newDenominator)) * 100).toFixed(2) : 0) : parseFloat(target.value || 0),
                                    }
                                  : target
                          ),
                      }
                    : type
            )
        );
    };

    const handleKPIScoreChange = (typeIndex, rowIndex, newScore) => {
        setKpiTargets((currentTargets) =>
            currentTargets.map((type, index) =>
                index === typeIndex
                    ? {
                          ...type,
                          indicator_targets: type.indicator_targets.map((target, tIndex) => (tIndex === rowIndex ? { ...target, score: newScore } : target)),
                      }
                    : type
            )
        );
    };

    //========================== handle submit =======================
    // Updated handleSubmit function
    const handleSubmit = (event) => {
        event.preventDefault();

        // First, validate other form fields
        const validationErrors = validateForm(); // This now includes checks for name, details, and KPI-related errors

        console.log("utility kpi validationErrors : ", validationErrors);

        // Check if there are any validation errors
        if (Object.keys(validationErrors).length > 0) {
            // Validation errors exist
            toast.current.show({ severity: "error", summary: "Validation Error", detail: "You still have some invalid fields. Please rectify them.", life: 3000 });
            setErrors(validationErrors);
            return; // Return early if there are validation errors
        }

        // Prepare data for KPIs
        // const kpiData = kpiTargets.map((kpi) => ({
        //     indicatorName: kpi.utility_indicator?.output_indicator?.name, // Just an example, adjust according to your actual data structure
        //     financialYear: kpi.financial_year?.name,
        //     quarter: kpi.quarter?.name,
        //     weight: kpi.utility_annual_indicator_target?.weight,
        //     target: kpi.target,
        //     value: kpi.value,
        //     reasonOfVariance: kpi.reasonOfVariance,
        //     assessment: kpi.assessment,
        // }));

        // Construct the final data object, including KPIs
        const finalData = {
            utility_id: selectedUtilityData?.id,
            status: formSubmissionStatus,
            fyear_id: financialYear,
            quarter_id: quarter,
            name,
            details,
            kpiTargets, // Add the KPI data here
            // Add other form fields as needed
        };

        console.log("Final Form Utility Form Data", finalData);
        // Process the form data here (e.g., sending to an API)
        setPendingData(finalData);
        setShowConfirmDialog(true);
    };

    //
    //======================= submit mutate ====================
    const storeUtilityReportDataMutation = useMutation(postUtilityReport, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(["utility-reports"]);
            toast.current.show({ severity: "success", summary: "Success", detail: "Utility Report Created Successfully", life: 3000 });
            reactToastifyToast.success("Utility report created successfully");
            // navigate("/subproject", { state: { subProjectsData: subprojectDetail, subprojectViewPageActiveIndex: subprojectViewPageActiveIndex, subprojectViewPageChildActiveIndex: subprojectViewPageChildActiveIndex } });

            // props.onClose();
            console.log("Create utility report success:", data);
        },
        onError: (error) => {
            // props.onClose();
            let errorMessage = "An error occurred, please contact admin";
            if (error?.response?.data?.message) {
                errorMessage = error.response.data.message;
                toast.current.show({ severity: "error", summary: "Error", detail: errorMessage, life: 3000 });
            } else if (!error?.response) {
                toast.current.show({ severity: "warning", summary: "Warning", detail: "Please check your internet connection", life: 3000 });
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: errorMessage, life: 3000 });
            }

            console.log("Create Utility Report error:", error);
        },
    });

    //===================================== on confirm ==========================
    // Function to handle confirmation of form submission
    const onConfirm = () => {
        console.log("Confirmed utilit Data xxxxx ", pendingData);
        storeUtilityReportDataMutation.mutate(pendingData);
        // Close the dialog
        setShowConfirmDialog(false);
    };

    // Function to handle cancellation of the submission
    const onCancel = () => {
        // Logic to handle the cancellation
        // For example, you might clear the pending data or provide feedback
        setPendingData(null);
        // Close the dialog
        setShowConfirmDialog(false);
    };

    // Function to handle form submission

    //
    const valueTemplate = (rowData, { rowIndex }) => {
        return (
            <>
                <InputText value={rowData.value} onChange={(e) => handleKPIValueChange(rowIndex, e.target.value)} />
                {errors[`value_${rowIndex}`] && <small className="p-error">{errors[`value_${rowIndex}`]}</small>}
            </>
        );
    };
    return (
        <>
            <BreadcrumbNav />
            <Toast ref={toast} />
            <form onSubmit={handleSubmit}>
                <Panel
                    header={
                        <div>
                            <div>Reporting Module</div>

                            <div>
                                <strong>Department Name : </strong>
                                {selectedUtilityData?.department?.name}
                            </div>
                            <div>
                                <strong>Utility Name : </strong>
                                {selectedUtilityData?.name}
                            </div>
                        </div>
                    }
                    style={{ marginBottom: "20px" }}
                >
                    {/* Financial Year and Quarter in their own card */}

                    <Accordion style={{ marginBottom: "1rem" }}>
                        <AccordionTab header="Period Filters">
                            {/* <Card title="Period" style={{ marginBottom: "1rem" }}> */}
                            <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column", marginBottom: "1rem", gap: "2rem" }}>
                                    <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                        <label htmlFor="financialYear">Financial Year</label>
                                        <Dropdown
                                            value={financialYear}
                                            onChange={handleFinancialYearChange}
                                            options={getAllFinancialYearsQuery?.data?.data?.data.map((item) => ({
                                                label: item.name,
                                                value: item.id,
                                            }))}
                                            placeholder="Select a Financial Year"
                                            disabled={getAllFinancialYearsQuery.isLoading}
                                        />
                                        {getAllFinancialYearsQuery.isLoading && <ProgressSpinner style={{ width: "20px", height: "20px" }} />}
                                        {errors.financialYear && <small className="p-error">{errors.financialYear}</small>}
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                        <label>Quarter</label>
                                        <Dropdown
                                            value={quarter}
                                            onChange={handleQuarterChange}
                                            options={getAllQuartersQuery?.data?.data?.data.map((item) => ({
                                                label: item.name,
                                                value: item.id,
                                            }))}
                                            placeholder="Select a Quarter"
                                            disabled={getAllQuartersQuery.isLoading}
                                        />
                                        {getAllQuartersQuery.isLoading && <ProgressSpinner style={{ width: "20px", height: "20px" }} />}
                                        {errors.quarter && <small className="p-error">{errors.quarter}</small>}
                                    </div>

                                    <Button
                                        label="Filter"
                                        icon="pi pi-filter"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            console.log("This Button Saving village level Budget Outputs ");
                                            handlePressFilter(e);
                                        }}
                                        disabled={postToGetListOfUtilityIndicatorTagetsMutation.isLoading || !quarter || !financialYear}
                                    />

                                    {postToGetListOfUtilityIndicatorTagetsMutation.isLoading && (
                                        <div className="m-2">
                                            <ProgressBar mode="indeterminate" />
                                        </div>
                                    )}
                                </div>{" "}
                            </div>
                            {/* </Card> */}
                        </AccordionTab>
                    </Accordion>

                    {selectedUtilityData && postToGetListOfUtilityIndicatorTagetsMutation.isLoading && postToGetListOfUtilityIndicatorTagetsMutation.isFetching && (
                        <div className="m-2">
                            <ProgressBar mode="indeterminate" style={{ height: "4px" }} />
                        </div>
                    )}

                    <TabView scrollable>
                        <TabPanel header="Report Details">
                            {/* Budget Details */}
                            <Card title="Details" style={{ marginBottom: "1rem" }}>
                                <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "1rem", width: "100%" }}>
                                        <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem", width: "100%" }}>
                                            {/* Input for name */}
                                            <label htmlFor="name">Name</label>
                                            <InputText value={name} onChange={handleNameChange} placeholder="Name" />
                                            {errors.name && <small className="p-error">{errors.name}</small>}

                                            {/* Textarea for details, with existing onChange adjusted */}
                                            <label htmlFor="details">Details</label>
                                            <InputTextarea value={details} onChange={handleDetailsChange} rows={5} placeholder="Details" />
                                            {errors.details && <small className="p-error">{errors.details}</small>}
                                        </div>
                                    </div>
                                    {/* Details */}
                                    {/* <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                        <label htmlFor="details">Details</label>
                                        <InputTextarea disabled={isSubmitDisabled} value={details} onChange={handleDetailsChange} id="details" rows={5} placeholder="Details" />
                                        {errors.details && <small className="p-error">{errors.details}</small>}
                                    </div> */}
                                </div>
                            </Card>
                        </TabPanel>

                        <TabPanel header="Output Indicators / (KPI's)">
                            {kpiTargets.map((type, typeIndex) => (
                                <Card title={`${type.name}`} key={typeIndex}>
                                    <DataTable value={type.indicator_targets}>
                                        <Column style={{ whiteSpace: "nowrap" }} field="utility_indicator.output_indicator.name" header="Indicator Name" body={(rowData) => rowData.utility_indicator?.output_indicator?.name} />
                                        <Column style={{ whiteSpace: "nowrap" }} field="utility_indicator.output_indicator.output_indicator_type.name" header="Type" body={(rowData) => rowData.utility_indicator?.output_indicator?.output_indicator_type?.name} />
                                        {/* <Column style={{ whiteSpace: "nowrap" }} field="financial_year.name" header="Financial Year" body={(rowData) => rowData.financial_year?.name}></Column> */}
                                        {/* <Column style={{ whiteSpace: "nowrap" }} style={{ whiteSpace: "nowrap" }} field="quarter.name" header="Quarter" body={(rowData) => rowData.quarter?.name}></Column> */}
                                        <Column style={{ whiteSpace: "nowrap" }} field="utility_annual_indicator_target.weight" header="Weight (%)" body={(rowData) => `${parseFloat(rowData.utility_annual_indicator_target?.weight).toFixed(1)}%`} />
                                        {/* <Column style={{ whiteSpace: "nowrap" }} field="target" header="Target"></Column> */}
                                        <Column
                                            field="target"
                                            header="Target"
                                            body={(rowData) => {
                                                const numberTarget = Number(rowData.target); // Convert string to number
                                                const formattedTarget = new Intl.NumberFormat().format(numberTarget); // Format number with commas
                                                return <div>{formattedTarget}</div>;
                                            }}
                                        />
                                        <Column style={{ whiteSpace: "nowrap" }} field="utility_indicator.output_indicator.unit" header="Units" body={(rowData) => rowData.utility_indicator?.output_indicator?.unit} />
                                        {/* <Column body={valueTemplate} header="Value"></Column> */}
                                        <Column
                                            style={{ whiteSpace: "nowrap" }}
                                            field="value"
                                            header="Value"
                                            body={(rowData, { rowIndex }) => (
                                                <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                                    <InputText value={rowData.value} onChange={(e) => handleKPIValueChange(typeIndex, rowIndex, e.target.value)} disabled={rowData.utility_indicator.output_indicator.unit == "%"} />
                                                    {errors[`value_${typeIndex}_${rowIndex}`] && <small className="p-error">{errors[`value_${typeIndex}_${rowIndex}`]}</small>}
                                                </div>
                                            )}
                                        />

                                        <Column
                                            header="Numerator"
                                            body={(rowData, { rowIndex }) => {
                                                if (rowData.utility_indicator.output_indicator.unit !== "%") {
                                                    return <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>N/A</div>;
                                                }
                                                return (
                                                    <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                                        <div>
                                                            <small>{rowData.utility_indicator.output_indicator.numerator}</small>
                                                        </div>
                                                        <InputText value={rowData.numerator} onChange={(e) => handleKPINumeratorChange(typeIndex, rowIndex, e.target.value)} />
                                                        {errors[`numerator_${typeIndex}_${rowIndex}`] && <small className="p-error">{errors[`numerator_${typeIndex}_${rowIndex}`]}</small>}
                                                    </div>
                                                );
                                            }}
                                        />
                                        <Column
                                            header="Denominator"
                                            body={(rowData, { rowIndex }) => {
                                                if (rowData.utility_indicator.output_indicator.unit !== "%") {
                                                    return <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>N/A</div>;
                                                }
                                                return (
                                                    <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                                        <div>
                                                            <small>{rowData.utility_indicator.output_indicator.denominator}</small>
                                                        </div>
                                                        <InputText value={rowData.denominator} onChange={(e) => handleKPIDenominatorChange(typeIndex, rowIndex, e.target.value)} />
                                                        {errors[`denominator_${typeIndex}_${rowIndex}`] && <small className="p-error">{errors[`denominator_${typeIndex}_${rowIndex}`]}</small>}
                                                    </div>
                                                );
                                            }}
                                        />
                                        {/* <Column
                                            header="Score"
                                            body={(rowData, { rowIndex }) => (
                                                <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                                    <InputText value={rowData.score} onChange={(e) => handleKPIScoreChange(typeIndex, rowIndex, e.target.value)} />
                                                    {errors[`score_${typeIndex}_${rowIndex}`] && <small className="p-error">{errors[`score_${typeIndex}_${rowIndex}`]}</small>}
                                                </div>
                                            )}
                                        /> */}

                                        <Column
                                            style={{ whiteSpace: "nowrap" }}
                                            field="reasonOfVariance"
                                            header="Reason of Variance"
                                            body={(rowData, { rowIndex }) => (
                                                <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                                    <InputTextarea value={rowData.reasonOfVariance} rows={1} onChange={(e) => handleReasonOfVarianceChange(typeIndex, rowIndex, e.target.value)} />
                                                    {errors[`reasonOfVariance_${typeIndex}_${rowIndex}`] && <small className="p-error">{errors[`reasonOfVariance_${typeIndex}_${rowIndex}`]}</small>}
                                                </div>
                                            )}
                                        />
                                        <Column
                                            style={{ whiteSpace: "nowrap" }}
                                            field="assessment"
                                            header="Assessment"
                                            body={(rowData, { rowIndex }) => (
                                                <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                                    <InputTextarea value={rowData.assessment} rows={1} onChange={(e) => handleAssessmentChange(typeIndex, rowIndex, e.target.value)} />
                                                    {errors[`assessment_${typeIndex}_${rowIndex}`] && <small className="p-error">{errors[`assessment_${typeIndex}_${rowIndex}`]}</small>}
                                                </div>
                                            )}
                                        />
                                    </DataTable>
                                </Card>
                            ))}
                        </TabPanel>

                        {/* // Outcomes and Indicators */}
                    </TabView>
                </Panel>

                {/* Submit Button */}
                <div style={{ width: "100%", display: "flex", gap: "1rem" }}>
                    <Button
                        type="submit"
                        onClick={() => {
                            setFormSubmissionStatus("submitted");
                        }}
                        label="Submit"
                        style={{ marginTop: "2rem" }}
                        disabled={!postToGetListOfUtilityIndicatorTagetsMutation?.data?.data || postToGetListOfUtilityIndicatorTagetsMutation?.data?.data?.length < 1 || storeUtilityReportDataMutation?.isLoading}
                    />
                </div>
            </form>

            {storeUtilityReportDataMutation?.isLoading && (
                <center>
                    {" "}
                    <div className="spinner-container">
                        <ProgressSpinner
                            style={{
                                width: "50px",
                                height: "50px",
                                borderWidth: "50px", // Border thickness
                                borderColor: "blue", // Border color
                                animationDuration: "1s",
                            }}
                            strokeWidth="8"
                            animationDuration="1s"
                        />
                    </div>
                </center>
            )}

            {/* Confirmation Dialog */}
            <Dialog
                header="Confirmation"
                visible={showConfirmDialog}
                maximizable
                onHide={onCancel}
                footer={
                    <div>
                        <Button label="Yes" onClick={onConfirm} />
                        <Button label="No" onClick={onCancel} className="p-button-secondary" />
                    </div>
                }
            >
                Are you sure you want to submit this form?
            </Dialog>
        </>
    );
}

export default UtilityReportingPage;
