import React from "react";
import ListPage from "./ListPage";
import { Link } from "react-router-dom";

import BreadcrumbNav from "../../../../../components/mweComponents/BreadcrumbNav";

const createBreadCrump = () => {};
//
function SubProjectBudgetOutPutIndicatorTargetsPage({ loggedInUserData, selectedBudgetOutputItem, project_id, selectedprojectItem, subprojectDetail }) {
    let linksData = [{ name: "programs" }];
    return (
        <div>
            {/* <BreadcrumbNav /> */}

            <ListPage selectedprojectItem={selectedprojectItem} subprojectDetail={subprojectDetail} selectedParentItem={selectedBudgetOutputItem} project_id={project_id} />
        </div>
    );
}

export default SubProjectBudgetOutPutIndicatorTargetsPage;
