import React, { useState, useEffect } from "react";
// import { Card } from "primereact/card";
import { Tree } from "primereact/tree";
import { Divider } from "primereact/divider";
import { useLocation } from "react-router-dom";
import { Panel } from "primereact/panel";
import BreadcrumbNav from "../../../../components/mweComponents/BreadcrumbNav";
import { Button } from "primereact/button";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { Fieldset } from "primereact/fieldset";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { TabView, TabPanel } from "primereact/tabview";
// import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primereact/resources/themes/lara-light-blue/theme.css";
// import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

import SubProjectBudgetOutPutIndicatorTargetsPage from "./subproject-budget-output-indicator-targets/SubProjectBudgetOutPutIndicatorTargetsPage.js";
import { getAllSubprojectBudgetOutputs, getSubprojectBudgetOutputById, postSubprojectBudgetOutput, updateSubprojectBudgetOutput, deleteSubprojectBudgetOutputById } from "../../../../services/sub-projects/subproject-budget-outputs-service";

const SubProjectBudgetOutputsViewPage = ({ loggedInUserData }) => {
    const queryClient = useQueryClient();
    //
    let { state } = useLocation();
    let selectedbudgetOutputData = state ? state?.selectedbudgetOutputData : null;
    let project_id = state ? state?.project_id : null;
    let selectedprojectItem = state ? state?.selectedprojectItem : null;
    let subprojectDetail = state ? state?.subprojectDetail : null;

    console.log("selectedbudgetOutputData subproject data got from state is : ", selectedbudgetOutputData);

    //===================== getDepartmentById by id =================

    const fetchSubprojectBudgetOutputById = useQuery(["subprojects", "budgetOutputs", "byId", selectedbudgetOutputData?.id], () => getSubprojectBudgetOutputById(selectedbudgetOutputData?.id), {
        onSuccess: (data) => {
            console.log("getSubprojectBudgetOutputById budget outputs by id is hhh : ", data);
        },
        onError: (error) => {
            console.log("Error by id fetching budget outputs is : ", error);
        },
    });

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showBudjetOutPutAddForm, setShowBudjetOutPutAddForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    return (
        <div style={{ width: "100%" }}>
            <BreadcrumbNav />
            <div className="projects-view-page">
                <Panel
                    header={
                        <span>
                            Subproject Project : {selectedbudgetOutputData?.subproject?.name}
                            <br />
                            Budget Output : {selectedbudgetOutputData?.project_department_budget_output?.department_budget_output?.name}
                        </span>
                    }
                    style={{ marginBottom: "20px" }}
                >
                    <TabView scrollable>
                        <TabPanel header="Details">
                            <div className="flex flex-wrap">
                                {/* Column 1 */}
                                <div className="w-full md:w-1/2 px-2 mb-4">
                                    <p>
                                        <strong>Butdget Output Name:</strong> {selectedbudgetOutputData?.project_department_budget_output?.department_budget_output?.name}
                                    </p>
                                </div>

                                {/* Column 2 */}
                                <div className="w-full md:w-1/2 px-2 mb-4">
                                    <p>
                                        <strong>Butdget Output Code:</strong> {selectedbudgetOutputData?.project_department_budget_output?.department_budget_output?.code}
                                    </p>

                                    {/* <p>
                                        <strong>Updated By:</strong> {selectedbudgetOutputData?.updated_by?.email || "N/A"}
                                    </p> */}
                                </div>
                            </div>

                            <Divider />
                        </TabPanel>
                        <TabPanel header="Budget Output Indicator Targets">
                            <SubProjectBudgetOutPutIndicatorTargetsPage
                                project_id={project_id}
                                loggedInUserData={loggedInUserData}
                                selectedprojectItem={selectedprojectItem}
                                subprojectDetail={subprojectDetail}
                                selectedBudgetOutputItem={selectedbudgetOutputData || fetchSubprojectBudgetOutputById?.data?.data?.data}
                                departmentOutcomeIndicatorId={selectedbudgetOutputData?.department_budget_output_id || fetchSubprojectBudgetOutputById?.data?.data?.data?.id}
                                // selectedItem={props?.rowData}
                            />
                        </TabPanel>
                    </TabView>
                </Panel>
            </div>
        </div>
    );
};

export default SubProjectBudgetOutputsViewPage;

// Sample usage:
// <ProjectsViewPage project={{
//     name: 'Urban Development Project',
//     code: 'UD123',
//     department: 'Urban Planning',
//     type: 'Development',
//     responsibleOfficer: 'John Doe',
//     details: 'This project focuses on...',
//     districts: [{ id: '1', name: 'District 1' }, { id: '2', name: 'District 2' }]
// }} />
