import React, { useRef } from "react";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Image } from "primereact/image";
import { Dialog } from "primereact/dialog";
import moment from "moment"; // Import moment
import { Tag } from "primereact/tag";

import UtilityReportPrint from "./UtilityReportPrint";
import { useReactToPrint } from "react-to-print";
import { Button } from "primereact/button";
import PrinterOutlined from "@mui/icons-material/PrintOutlined";
import { ProgressBar } from "primereact/progressbar";

function UtilityReportPdfModal({ selectedItem, selectedUtilityData, ...props }) {
    console.log("selected item in UtilityReportPdfModal  : ", selectedItem);

    const componentRef = useRef();

    // Function to format date using moment
    const formatDate = (dateString) => {
        return moment(dateString).format("MMMM Do YYYY, h:mm:ss a"); // Example format
    };

    // Function to display status with color
    const statusWithColor = (status) => {
        let color;
        switch (status) {
            case "pending approval":
                color = "blue";
                break;
            case "approved":
                color = "green";
                break;
            case "pending":
                color = "orange";
                break;
            case "rejected":
                color = "red";
                break;
            default:
                color = "grey";
        }
        return <span style={{ color }}>{status?.charAt(0)?.toUpperCase() + status?.slice(1)}</span>;
    };

    // Function to display status with color using Tag
    const tagStatus = (status) => {
        let severity;
        switch (status) {
            case "pending approval":
                severity = "info"; // blue
                break;
            case "approved":
                severity = "success"; // green
                break;
            case "pending":
                severity = "warning"; // orange
                break;
            case "rejected":
                severity = "danger"; // red
                break;
            default:
                severity = "secondary"; // grey or default
        }
        return <Tag severity={severity} value={status?.charAt(0)?.toUpperCase() + status?.slice(1)} />;
    };

    const gridStyle = {
        display: "grid",
        gridTemplateColumns: "1fr 1fr", // This creates two columns of equal width
        gridGap: "1rem", // Space between the columns
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Report", // Set the document title as a string
        pageStyle: `
        @page { size: A4; margin: 1cm; }
        @media print {
          body { -webkit-print-color-adjust: exact; }
          div#print-content { height: 100%; }
          .header, .footer { background-color: transparent !important; } // Assuming .header and .footer are the classes for your header and footer
          img { display: block; margin: 0 auto; } // Center the image, if necessary
        }
      `,
    });

    // Remember to store the original title to reset it later
    const originalTitle = document.title;

    return (
        <Dialog
            header={
                <div>
                    <div style={{ color: "green" }}>Water Utility Regulation Utility Report.</div>

                    {/* <div>
                        <strong>Subproject Name : </strong>
                        {selectedItem?.subproject?.name}
                    </div> */}
                </div>
            }
            visible={props.show}
            style={{ width: "80vw" }}
            onHide={props.onHide}
            maximizable
        >
            {/* Status Information */}
            <Card title="Report Details" style={{ marginBottom: "1rem" }}>
                <div className="flex flex-wrap" style={{ justifyContent: "space-between" }}>
                    {/* Column 1 */}
                    <div className="md:w-1/2 px-2 m-1">
                        {/* Display Program and Directorate Details */}

                        <p>
                            <strong>Department Name:</strong> {selectedItem?.utility?.department?.name || "N/A"}
                        </p>

                        <p>
                            <strong>Report Name :</strong> {selectedItem?.name || "N/A"}
                        </p>
                    </div>

                    {/* Column 2 */}
                    <div className="md:w-1/2 px-2 m-1">
                        {/* <p>
                            <strong>Status:</strong> {statusWithColor(selectedItem?.status)}
                        </p> */}

                        <p>
                            <strong>Finacial Year:</strong> {selectedItem?.financial_year?.name}
                        </p>
                        <p>
                            <strong>Quarter:</strong> {selectedItem?.quarter?.name}
                        </p>
                    </div>

                    {/* Full Width Row for Details */}
                    <div className="w-full px-2 m-2">
                        <p>
                            <strong>Approval Status:</strong> {tagStatus(selectedItem?.current_status?.name)}
                        </p>

                        <p>
                            <strong>Approval Comment:</strong> {statusWithColor(selectedItem?.current_status?.reason)}
                        </p>
                    </div>

                    <div className="w-full px-2 m-2">
                        <strong>Description:</strong>
                        <p>{selectedItem?.description}</p>
                    </div>
                </div>
            </Card>

            <Card title={`Output Indicators / (KPI's)`} style={{ marginBottom: "1rem" }}>
                {selectedItem?.indicator_types?.map((indicatorType, typeIndex) => (
                    <Card title={`${indicatorType.indicator_type.name}`} key={typeIndex} style={{ marginBottom: "1rem" }}>
                        <DataTable value={indicatorType.utility_report_output_indicators} responsiveLayout="scroll">
                            <Column style={{ whiteSpace: "nowrap" }} field="utility_indicator_target.utility_indicator.output_indicator.name" header="Indicator Name" body={(rowData) => rowData?.utility_indicator_target?.utility_indicator?.output_indicator?.name}></Column>
                            {/* <Column
                                style={{ whiteSpace: "nowrap" }}
                                field="utility_indicator_target.utility_indicator.output_indicator.output_indicator_type.name"
                                header="Type"
                                body={(rowData) => rowData.utility_indicator_target?.utility_indicator?.output_indicator?.output_indicator_type?.name}
                            ></Column> */}
                            {/* <Column style={{ whiteSpace: "nowrap" }} field="financial_year.name" header="Financial Year" body={(rowData) => rowData.financial_year?.name}></Column> */}
                            {/* <Column style={{ whiteSpace: "nowrap" }} style={{ whiteSpace: "nowrap" }} field="quarter.name" header="Quarter" body={(rowData) => rowData.quarter?.name}></Column> */}
                            <Column style={{ whiteSpace: "nowrap" }} field="utility_indicator_target.utility_annual_indicator_target.weight" header="Weight (%)" body={(rowData) => `${parseFloat(rowData.utility_indicator_target?.utility_annual_indicator_target?.weight).toFixed(1)}%`}></Column>
                            {/* <Column style={{ whiteSpace: "nowrap" }} field="target" header="Target"></Column> */}
                            <Column
                                field="utility_indicator_target.target"
                                header="Target"
                                body={(rowData) => {
                                    const numberTarget = Number(rowData?.utility_indicator_target?.target); // Convert string to number
                                    const formattedTarget = new Intl.NumberFormat().format(numberTarget); // Format number with commas
                                    return <div>{formattedTarget}</div>;
                                }}
                            ></Column>
                            <Column style={{ whiteSpace: "nowrap" }} field="utility_indicator_target.utility_indicator.output_indicator.unit" header="Units" body={(rowData) => rowData?.utility_indicator_target?.utility_indicator?.output_indicator?.unit}></Column>
                            <Column field="value" header="Value"></Column>
                            <Column field="reasonOfVariance" header="Reason Of Variance"></Column>
                            <Column field="assessment" header="Assessment"></Column>
                        </DataTable>
                    </Card>
                ))}
            </Card>

            {/* Created By and Updated By Information */}
            <p>
                <strong>Created By:</strong> {selectedItem?.created_by?.name} at {formatDate(selectedItem?.created_at)}
            </p>
            {/* <p>
                <strong>Updated By:</strong> {selectedItem?.updated_by_user?.name} at {formatDate(selectedItem?.updated_at)}
            </p> */}

            {/* Print Button */}
            {/* {selectedItem?.current_status?.name == "approved" && ( */}
            <>
                {" "}
                <Button label="Print " icon={<PrinterOutlined />} onClick={handlePrint} className="p-button-primary" />
                {/* Invisible component for printing */}
                <div style={{ display: "none" }}>
                    <div ref={componentRef}>
                        <UtilityReportPrint selectedItem={selectedItem} selectedUtilityData={selectedUtilityData} />
                    </div>
                </div>
            </>
            {/* )} */}
        </Dialog>
    );
}

export default UtilityReportPdfModal;
