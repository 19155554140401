import React from "react";
import ListPage from "./ListPage";
import { Link } from "react-router-dom";

import BreadcrumbNav from "../../../../../components/mweComponents/BreadcrumbNav";

const createBreadCrump = () => {};
//
function ProjectBudgetOutPutAnnualIndicatorTargetsPage({ loggedInUserData, selectedBudgetOutputItem, project_id, selectedprojectItem }) {
    let linksData = [{ name: "programs" }];
    return (
        <div>
            {/* <BreadcrumbNav /> */}

            <ListPage loggedInUserData={loggedInUserData} selectedParentItem={selectedBudgetOutputItem} project_id={project_id} selectedprojectItem={selectedprojectItem} />
        </div>
    );
}

export default ProjectBudgetOutPutAnnualIndicatorTargetsPage;
