import axiosAPI from "../axiosApi";

export async function getAllYears(params = {}) {
    const response = await axiosAPI.get("years", { params: params });
    return response;
}

export async function getYearById(id) {
    const response = await axiosAPI.get(`years/` + id);
    return response;
}

export async function postYears(data) {
    const response = await axiosAPI.post(`years`, data);
    return response;
}

export async function updateYears(id, data) {
    const response = await axiosAPI.put(`years/${id}`, data);
    return response;
}

export async function deleteYearById(id) {
    const response = await axiosAPI.delete(`years/${id}`);
    return response;
}
// "activity-logs";
