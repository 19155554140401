import React, { useState, useEffect } from "react";
import Typewriter from "typewriter-effect";
import OutcomeIndicatorTypesPage from "../components/outcome-indicators-types/OutcomeIndicatorTypesPage";
import Lottie from "lottie-react";
import UrbanCityCarPassByRoad from "../assets/Animation - 1701992047792.json";
import UrbanCitySketch from "../assets/Animation - 1701991980160.json";
import UrbanCityBuildings from "../assets/Animation - 1701991769959.json";
import UrbanCityThreeD from "../assets/Animation - 1701991435305.json";
import RuralTreeTrashCan from "../assets/Animation - 1701991322985.json";
import RuralCows from "../assets/Animation - 1701991206926.json";
import RuralCity from "../assets/Animation - 1701991030565.json";
import UgandaFlagPopping from "../assets/Uganda flag poppingAnimation - 1703156510424.json";
import UgandaFlagSpreadingAnimation from "../assets/Uganda flag spreadingAnimation - 1703156929188.json";
import UtilityThemeChartCards from "./components/UtilityCharts/UtilityThemeChartCards";
import UtilityChartCards from "./components/UtilityCharts/UtilityChartCards";

import LocationFiltersPage from "./components/location-filters/LocationFiltersPage";

//

import BudgetOutputsAndOutputIndicatorsPage from "../components/budget-outputs-indicators/BudgetOutputsAndOutputIndicatorsPage";
import FiltersPage from "../components/filters/FiltersPage";
import NumeratorStats from "../components/numerator-stats/NumeratorStats";
import ArbsorptionRatePage from "../components/absorbtion-rate/ArbsorptionRatePage";
import { Panel } from "primereact/panel";

function WaterUmbrellaUtilitiesDashboardPage({ departmentDetail, loggedInUserData, ...props }) {
    // Array of Lottie animations
    const animations = [UgandaFlagPopping, UrbanCityBuildings, RuralCity, UrbanCitySketch];

    // Randomly select an animation
    const randomAnimation = animations[Math.floor(Math.random() * animations.length)];

    const [financialYear, setFinancialYear] = useState(null);
    const [quarter, setQuarter] = useState(null);
    const [periodIsLoading, setPeriodIsLoading] = useState(false);

    let department = loggedInUserData?.user_department ?? departmentDetail;

    //
    const MemoizedLocationFiltersPage = React.memo(LocationFiltersPage);
    const MemoizedOutcomeIndicatorTypesPage = React.memo(OutcomeIndicatorTypesPage);
    const [utilityIndicatorsData, setUtilityIndicatorsData] = useState();
    const [filterParams, setFilterParams] = useState({ department_id: department?.id });
    const [filterSelectedObjects, setFilterSelectedObjects] = useState({ selectedDepartment: department });
    const [filterQueryIsLoading, setFilterQueryIsLoading] = useState(true);

    console.log("filterSelectedObjects on utility: ", filterSelectedObjects);

    return (
        <div className="grid">
            <div className="col-12 xl:col-12">
                <div className="card" style={{ position: "relative" }}>
                    {/* Lottie background */}
                    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, opacity: 0.1, overflow: "hidden" }}>
                        <Lottie
                            animationData={randomAnimation}
                            loop={true}
                            autoplay={true}
                            style={{
                                // width: "100%", // Increase width to cover the area
                                // height: "100%", // Increase height to cover the area
                                objectFit: "cover",
                            }}
                        />
                    </div>

                    {/* Content */}
                    <div style={{ position: "relative" }}>
                        {department ? (
                            <>
                                <h4>
                                    <Typewriter
                                        options={{
                                            strings: department.name,
                                            autoStart: true,
                                            loop: false,
                                            cursor: "",
                                            delay: 2,
                                        }}
                                    />
                                </h4>
                                <p>
                                    <Typewriter
                                        options={{
                                            strings: department.details,
                                            autoStart: true,
                                            loop: false,
                                            cursor: "",
                                            delay: 2,
                                        }}
                                    />
                                </p>
                                {/* <p>
                                    <Typewriter
                                        options={{
                                            strings: department.description,
                                            autoStart: true,
                                            loop: false,
                                            cursor: "",
                                            delay: 2, // Lower value for faster typing
                                        }}
                                    />
                                </p> */}
                            </>
                        ) : (
                            <p>Welcome to the Ministry of Water and Environment Database Portal. This portal presents statistics and data for all things concerned water and environment in Uganda. The information contained here is regularly updated as data is recorded from across the country.</p>
                        )}
                    </div>
                </div>
            </div>
            <div className="col-12 xl:col-12">
                <MemoizedLocationFiltersPage
                    setFilterQueryIsLoading={setFilterQueryIsLoading}
                    filterQueryIsLoading={filterQueryIsLoading}
                    setFilterParams={setFilterParams}
                    filterParams={filterParams}
                    setFilterSelectedObjects={setFilterSelectedObjects}
                    filterSelectedObjects={filterSelectedObjects}
                    loggedInUserData={loggedInUserData}
                    utilityIndicatorsData={utilityIndicatorsData}
                    setUtilityIndicatorsData={setUtilityIndicatorsData}
                    currentDepartmentDetail={department}
                />
            </div>
            <div className="col-12 xl:col-12">
                <Panel header="Outcome Indicators" toggleable style={{ minwidth: "100%" }}>
                    <MemoizedOutcomeIndicatorTypesPage filterQueryIsLoading={filterQueryIsLoading} setFilterQueryIsLoading={setFilterQueryIsLoading} financialYear={filterSelectedObjects?.selectedFinancialYear?.id} quarter={filterSelectedObjects?.selectedQuarter?.id} department={department} />
                </Panel>
            </div>
            <div className="col-12 xl:col-12">
                <Panel header="Theme Indicators" toggleable style={{ minwidth: "100%" }}>
                    <UtilityThemeChartCards
                        utilityIndicatorsData={utilityIndicatorsData}
                        filterQueryIsLoading={filterQueryIsLoading}
                        setPeriodIsLoading={setPeriodIsLoading}
                        financialYear={filterSelectedObjects?.selectedFinancialYear?.id}
                        quarter={filterSelectedObjects?.selectedQuarter?.id}
                        department={department}
                    />
                </Panel>
            </div>
            <div className="col-12 xl:col-12">
                <Panel header="Indicators" toggleable style={{ minwidth: "100%" }}>
                    <UtilityChartCards
                        utilityIndicatorsData={utilityIndicatorsData}
                        filterQueryIsLoading={filterQueryIsLoading}
                        setPeriodIsLoading={setPeriodIsLoading}
                        financialYear={filterSelectedObjects?.selectedFinancialYear?.id}
                        quarter={filterSelectedObjects?.selectedQuarter?.id}
                        department={department}
                    />
                </Panel>
            </div>
        </div>
    );
}

export default WaterUmbrellaUtilitiesDashboardPage;
