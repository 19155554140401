import React, { useState, useRef, useEffect, useMemo } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";

import { getAllFinancialYears, getFinancialYearsById, postFinancialYears, updateFinancialYears, deleteFinancialYears } from "../../../../../services/financial-years/financial-years-service";
import { getAllQuarters, getQuartersById, postQuarters, updateQuarters, deleteQuarters } from "../../../../../services/financial-years/quarters";

import { postToFilterKPITargets } from "../../../../../services/departments/utility-dept/department-utility-indicators-service";
import { postFilterThemeIndicatorTargets } from "../../../../../services/departments/utility-dept/departments-themes-indicators-service.js";

import { getAllUtilityThemeReports, getUtilityThemeReportById, postUtilityThemeReport, updateUtilityThemeReport, deleteUtilityThemeReportById, updateUtilityThemeReportDataStatus } from "../../../../../services/departments/utility-dept/departments-utility-theme-report-service.js";

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { ProgressBar } from "primereact/progressbar";

import { ProgressSpinner } from "primereact/progressspinner";
import { useLocation } from "react-router-dom";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import BreadcrumbNav from "../../../../../components/mweComponents/BreadcrumbNav";

import { Panel } from "primereact/panel";
import { TabView, TabPanel } from "primereact/tabview";
import { Accordion, AccordionTab } from "primereact/accordion";

import { useNavigate } from "react-router-dom";
import { toast as reactToastifyToast } from "react-toastify";
import RiskMitigationComponent from "./widgets/risks/RiskMitigation";

//
import { postToGetDepartmentDetailsWithOutcomes } from "../../../../../services/departments/department-service";

function UtilityDeptReportingPage() {
    const queryClient = useQueryClient();
    const toast = useRef(null);
    const navigate = useNavigate();
    const fileRef = useRef(null);
    //
    let { state } = useLocation();

    let subprojectViewPageActiveIndex = state?.subprojectViewPageActiveIndex ? state?.subprojectViewPageActiveIndex : 0;
    let subprojectViewPageChildActiveIndex = state?.subprojectViewPageChildActiveIndex ? state?.subprojectViewPageChildActiveIndex : 0;

    let selectedParentData = state ? state?.selectedParentData : null;

    console.log("selectedParentData data got from state is on utility theme report : ", selectedParentData);

    //
    const [financialYear, setFinancialYear] = useState();
    const [quarter, setQuarter] = useState("");
    const [name, setName] = useState("");
    const [details, setDetails] = useState("");
    const [kpiTargets, setKpiTargets] = useState([]);
    const [formSubmissionStatus, setFormSubmissionStatus] = useState("draft");
    const [deptOutcomeIndicatorTargets, setDeptOutcomeIndicatorTargets] = useState([]);

    // Additional states
    const [errors, setErrors] = useState({});
    const [pendingData, setPendingData] = useState(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [risks, setRisks] = useState([]);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    //
    // ================== get finacial years =================

    const getAllFinancialYearsQuery = useQuery(["financialYear"], getAllFinancialYears, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching getAllFinancialYears is : ", error);
        },
    });

    // ================= quarters ================

    const getAllQuartersQuery = useQuery(["quarters"], getAllQuarters, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching quarters is : ", error);
        },
    });

    // Define the query outside of the useEffect
    const { data, error, isLoading } = useQuery(
        ["deptOutcomeIndicatorTargets", selectedParentData?.id, financialYear],
        () =>
            postToGetDepartmentDetailsWithOutcomes({
                department_id: selectedParentData?.id,
                fyear_id: financialYear,
            }),
        {
            onSuccess: (data) => {
                // You can still handle success here if needed
                console.log("Utility Department report output indicators Data fetched successfully: ", data);
                setDeptOutcomeIndicatorTargets(data?.data?.data);
                // Only update if deptOutcomeIndicatorTargets is an empty array
                // setDeptOutcomeIndicatorTargets((currentTargets) => {
                //     if (Array.isArray(currentTargets) && currentTargets.length === 0) {
                //         return data?.data?.data; // Assume data?.data?.data is the new targets array
                //     }
                //     return currentTargets; // Otherwise, return the existing state without change
                // });
            },
            onError: (error) => {
                // Error handling
                reactToastifyToast.error(error?.response?.data?.message || "An Error Occurred. Please Contact Admin");
                console.error("Error fetching data: ", error);
            },
            enabled: !!selectedParentData?.id && !!financialYear, // This ensures the query only runs when these conditions are met
        }
    );

    //===================== post to get get List Of utility indicator targets  ====================

    const postToGetListOfThemeIndicatorTagetsMutation = useMutation(postFilterThemeIndicatorTargets, {
        onSuccess: (data) => {
            console.log("data on success postToGetListOfThemeIndicatorTagetsMutation : ", data);
            if (data?.data?.data?.length < 1) {
                toast.current.show({ severity: "info", summary: "No Targets Found", detail: "No targets for the selected period", life: 3000 });
            } else {
                // const enhancedData = data?.data.map((item) => ({
                //     ...item,
                //     value: "",
                //     reasonOfVariance: "",
                //     assessment: "",
                // }));
                setIsSubmitDisabled(false);
                setKpiTargets(data?.data?.data);
            }
        },
        onError: (error) => {
            // props.onClose();
            console.log("create postToGetListOfThemeIndicatorTagetsMutation error : ", error?.response?.data?.message);
            error?.response?.data?.message ? reactToastifyToast.error(error?.response?.data?.message) : reactToastifyToast.error("Án Error Occured Please Contact Admin");
        },
    });

    console.log("postToGetListOfThemeIndicatorTagetsMutation kkkk : ", postToGetListOfThemeIndicatorTagetsMutation);

    const handlePressFilter = async (event) => {
        event.preventDefault();

        postToGetListOfThemeIndicatorTagetsMutation.mutate({ department_id: selectedParentData?.id, quarter_id: quarter, fyear_id: financialYear });
    };

    //======================================= valdation logic =========================================
    const validateForm = () => {
        const validationErrors = {};

        // Assuming financialYear, quarter, name, and details are state variables holding form data
        if (!financialYear) validationErrors.financialYear = "Financial Year is required";
        if (!quarter) validationErrors.quarter = "Quarter is required";
        if (!name.trim()) validationErrors.name = "Name is required";
        if (details && !details.trim()) validationErrors.details = "Details are required";

        kpiTargets.forEach((theme, themeIndex) => {
            theme.indicators.forEach((indicator, indicatorIndex) => {
                // Validate value
                if (!indicator.value) {
                    validationErrors[`value_${themeIndex}_${indicatorIndex}`] = "Value is required";
                }
                // Validate reason of variance
                if (!indicator.reasonOfVariance) {
                    validationErrors[`reasonOfVariance_${themeIndex}_${indicatorIndex}`] = "Reason of Variance is required";
                }
                // Validate assessment
                if (!indicator.assessment) {
                    validationErrors[`assessment_${themeIndex}_${indicatorIndex}`] = "Assessment is required";
                }
            });
        });

        // Validate deptOutcomeIndicatorTargets
        // Validate deptOutcomeIndicatorTargets
        deptOutcomeIndicatorTargets.forEach((item, deptIndex) => {
            item.targets.forEach((target, targetIndex) => {
                // Ensure fields are not just whitespace and are defined
                // if (!target.Uncomputed_value || target.Uncomputed_value.toString().trim() === "") {
                //     validationErrors[`Uncomputed_value_${deptIndex}_${targetIndex}`] = "Ouctome Indicator value is required";
                // }

                //new
                if (target.Uncomputed_value === null || target.Uncomputed_value === undefined || target.Uncomputed_value.toString().trim() === "") {
                    validationErrors[`Uncomputed_value_${deptIndex}_${targetIndex}`] = "Outcome Indicator value is required";
                }

                // if (!target.Uncomputed_reason_of_variance || target.Uncomputed_reason_of_variance.trim() === "") {
                //     validationErrors[`Uncomputed_reason_of_variance_${deptIndex}_${targetIndex}`] = "Ouctome Indicator Reason of Variance is required";
                // }
                if (!target.Uncomputed_assessment || target.Uncomputed_assessment.trim() === "") {
                    validationErrors[`Uncomputed_assessment_${deptIndex}_${targetIndex}`] = "Ouctome Indicator Assessment is required";
                }

                // Add new validations
                // if (!target.Uncomputed_numerator || target.Uncomputed_numerator.toString().trim() === "") {
                //     validationErrors[`Uncomputed_numerator_${deptIndex}_${targetIndex}`] = "Outcome Indicator Numerator is required";
                // }
                // if (!target.Uncomputed_denominator || target.Uncomputed_denominator.toString().trim() === "") {
                //     validationErrors[`Uncomputed_denominator_${deptIndex}_${targetIndex}`] = "Outcome Indicator Denominator is required";
                // }

                // Conditionally validate Numerator and Denominator based on the unit
                if (target.department_outcome_indicator.outcome_indicator.unit === "%") {
                    if (!target.Uncomputed_numerator || target.Uncomputed_numerator.toString().trim() === "") {
                        validationErrors[`Uncomputed_numerator_${deptIndex}_${targetIndex}`] = "Outcome Indicator Numerator is required";
                    }
                    if (!target.Uncomputed_denominator || target.Uncomputed_denominator.toString().trim() === "") {
                        validationErrors[`Uncomputed_denominator_${deptIndex}_${targetIndex}`] = "Outcome Indicator Denominator is required";
                    }
                    if (!target.Uncomputed_reason_of_variance || target.Uncomputed_reason_of_variance.trim() === "") {
                        validationErrors[`Uncomputed_reason_of_variance_${deptIndex}_${targetIndex}`] = "Ouctome Indicator Reason of Variance is required";
                    }
                }
            });
        });

        setErrors(validationErrors);

        // Check if there are any errors, return false if errors exist (form is not valid), true otherwise (form is valid)
        return Object.keys(validationErrors).length === 0;
    };

    //============================== handle change ===============================================

    const handleFinancialYearChange = (e) => {
        setFinancialYear(e.value);
        // Optionally reset errors for this field
        if (errors.financialYear) {
            setErrors({ ...errors, financialYear: null });
        }
    };

    const handleQuarterChange = (e) => {
        setQuarter(e.value);
        // Optionally reset errors for this field
        if (errors.quarter) {
            setErrors({ ...errors, quarter: null });
        }
    };

    // Handle change for the name field
    const handleNameChange = (e) => {
        setName(e.target.value);
        // Optionally reset errors for this field
        if (errors.name) {
            setErrors({ ...errors, name: null });
        }
    };

    // Handle change for the details field
    const handleDetailsChange = (e) => {
        setDetails(e.target.value);
        // Optionally reset errors for this field
        if (errors.details) {
            setErrors({ ...errors, details: null });
        }
    };

    //============================ handle kPI ==============================
    const validateField = (name, value, rowIndex) => {
        let isValid = true;
        let errorMsg = "";

        switch (name) {
            case "value":
                isValid = !isNaN(value) && value !== "";
                errorMsg = isValid ? "" : "Value must be a number and cannot be empty";
                break;
            case "reasonOfVariance":
            case "assessment":
                isValid = value.trim() !== "";
                errorMsg = isValid ? "" : "This field cannot be empty";
                break;
            default:
                break;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            [`${name}_${rowIndex}`]: errorMsg,
        }));

        return isValid;
    };

    const handleKPIValueChange = (themeIndex, indicatorIndex, newValue) => {
        setKpiTargets((currentKpiTargets) =>
            currentKpiTargets.map((theme, tIndex) => {
                if (tIndex === themeIndex) {
                    return {
                        ...theme,
                        indicators: theme.indicators.map((indicator, iIndex) => {
                            if (iIndex === indicatorIndex) {
                                return {
                                    ...indicator,
                                    value: newValue,
                                };
                            }
                            return indicator;
                        }),
                    };
                }
                return theme;
            })
        );
    };

    const handleReasonOfVarianceChange = (themeIndex, indicatorIndex, newReason) => {
        setKpiTargets((currentKpiTargets) =>
            currentKpiTargets.map((theme, tIndex) => {
                if (tIndex === themeIndex) {
                    return {
                        ...theme,
                        indicators: theme.indicators.map((indicator, iIndex) => {
                            if (iIndex === indicatorIndex) {
                                return {
                                    ...indicator,
                                    reasonOfVariance: newReason,
                                };
                            }
                            return indicator;
                        }),
                    };
                }
                return theme;
            })
        );
    };

    const handleAssessmentChange = (themeIndex, indicatorIndex, newAssessment) => {
        setKpiTargets((currentKpiTargets) =>
            currentKpiTargets.map((theme, tIndex) => {
                if (tIndex === themeIndex) {
                    return {
                        ...theme,
                        indicators: theme.indicators.map((indicator, iIndex) => {
                            if (iIndex === indicatorIndex) {
                                return {
                                    ...indicator,
                                    assessment: newAssessment,
                                };
                            }
                            return indicator;
                        }),
                    };
                }
                return theme;
            })
        );
    };

    //========================== handle submit =======================
    // Updated handleSubmit function
    const handleSubmit = (event) => {
        event.preventDefault();

        // First, validate other form fields
        const validationErrors = validateForm(); // This now includes checks for name, details, and KPI-related errors

        // Check if there are any validation errors
        if (Object.keys(validationErrors).length > 0) {
            // Validation errors exist
            toast.current.show({ severity: "error", summary: "Validation Error", detail: "You still have some invalid fields. Please rectify them.", life: 3000 });
            setErrors(validationErrors);
            return; // Return early if there are validation errors
        }

        // Validate that there is at least one risk and one recommendation
        if (risks.length === 0) {
            toast.current.show({ severity: "warn", summary: "Add Risk", detail: "Please add at least one risk.", life: 3000 });
            return;
        }

        // Prepare data for KPIs
        // const kpiData = kpiTargets.map((kpi) => ({
        //     indicatorName: kpi.utility_indicator?.output_indicator?.name, // Just an example, adjust according to your actual data structure
        //     financialYear: kpi.financial_year?.name,
        //     quarter: kpi.quarter?.name,
        //     weight: kpi.utility_annual_indicator_target?.weight,
        //     target: kpi.target,
        //     value: kpi.value,
        //     reasonOfVariance: kpi.reasonOfVariance,
        //     assessment: kpi.assessment,
        // }));

        // Construct the final data object, including KPIs
        const finalData = {
            department_id: selectedParentData?.id,
            status: formSubmissionStatus,
            fyear_id: financialYear,
            quarter_id: quarter,
            name,
            details,
            deptOutcomeIndicatorTargets,
            themes: kpiTargets, // Add the KPI data here
            risks,
        };

        console.log("Final Form Utility deprtment theme Form Data", finalData);
        // Process the form data here (e.g., sending to an API)
        setPendingData(finalData);
        setShowConfirmDialog(true);
    };

    //
    //======================= submit mutate ====================
    const storeUtilityReportDataMutation = useMutation(postUtilityThemeReport, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(["utility-theme-reports"]);
            toast.current.show({ severity: "success", summary: "Success", detail: "Department Report Created Successfully", life: 3000 });
            reactToastifyToast.success("Department theme report created successfully");
            // navigate("/subproject", { state: { subProjectsData: subprojectDetail, subprojectViewPageActiveIndex: subprojectViewPageActiveIndex, subprojectViewPageChildActiveIndex: subprojectViewPageChildActiveIndex } });

            // props.onClose();
            // console.log("Create SubprojectFieldData success:", data);
        },
        onError: (error) => {
            // props.onClose();
            let errorMessage = "An error occurred, please contact admin";
            if (error?.response?.data?.message) {
                errorMessage = error.response.data.message;
            }
            toast.current.show({ severity: "error", summary: "Error", detail: errorMessage, life: 3000 });
            console.log("Create Utility Report error:", error);
        },
    });

    //===================================== on confirm ==========================
    // Function to handle confirmation of form submission
    const onConfirm = () => {
        console.log("Confirmed utilit Data xxxxx ", pendingData);
        storeUtilityReportDataMutation.mutate(pendingData);
        // Close the dialog
        setShowConfirmDialog(false);
    };

    // Function to handle cancellation of the submission
    const onCancel = () => {
        // Logic to handle the cancellation
        // For example, you might clear the pending data or provide feedback
        setPendingData(null);
        // Close the dialog
        setShowConfirmDialog(false);
    };

    // Function to handle form submission

    //
    const valueTemplate = (rowData, { rowIndex }) => {
        return (
            <>
                <InputText value={rowData.value} onChange={(e) => handleKPIValueChange(rowIndex, e.target.value)} />
                {errors[`value_${rowIndex}`] && <small className="p-error">{errors[`value_${rowIndex}`]}</small>}
            </>
        );
    };

    // ======================== file picker ==============================

    // Assuming this is within your component where kpiTargets state is defined
    const fileUploadTemplate = (rowData, { rowIndex: indicatorIndex }, themeIndex) => (
        <div style={{ display: "flex", gap: "1rem" }}>
            <FileUpload
                name="demo[]"
                auto={false}
                ref={fileRef}
                accept=".pdf"
                customUpload={true}
                maxFileSize={1000000}
                mode="basic"
                uploadHandler={(e) => handleUpload(e, themeIndex, indicatorIndex)}
                // onSelect={(e) => handleFileSelect(e, themeIndex, indicatorIndex)}
                multiple={true}
            />

            {rowData.uploadedFiles &&
                rowData.uploadedFiles.map((file, fileIndex) => (
                    <div key={file.name} className="p-d-flex p-ai-center p-jc-between" style={{ display: "flex", gap: "1rem" }}>
                        <span>{file.name}</span>
                        <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-outlined" onClick={() => removeFile(themeIndex, indicatorIndex, fileIndex)} />
                    </div>
                ))}
        </div>
    );

    const handleUpload = (e, themeIndex, indicatorIndex) => {
        console.log("Files to process:", e.files);

        // Clear the selection
        if (fileRef.current) {
            fileRef.current.clear();
        }

        const newFiles = Array.from(e.files).map((file) => ({
            name: file.name,
            file: file,
        }));

        setKpiTargets((current) =>
            current.map((theme, tIdx) => {
                if (tIdx === themeIndex) {
                    return {
                        ...theme,
                        indicators: theme.indicators.map((indicator, iIdx) => {
                            if (iIdx === indicatorIndex) {
                                // Append new files to the existing ones
                                const updatedFiles = [...(indicator.uploadedFiles || []), ...newFiles];
                                return {
                                    ...indicator,
                                    uploadedFiles: updatedFiles,
                                };
                            }
                            return indicator;
                        }),
                    };
                }
                return theme;
            })
        );
    };

    const removeFile = (themeIndex, indicatorIndex, fileIndex) => {
        // Process file removal and update state
        setKpiTargets((current) =>
            current.map((theme, tIdx) => {
                if (tIdx === themeIndex) {
                    return {
                        ...theme,
                        indicators: theme.indicators.map((indicator, iIdx) => {
                            if (iIdx === indicatorIndex) {
                                const updatedFiles = indicator.uploadedFiles.filter((_, fIdx) => fIdx !== fileIndex);
                                return { ...indicator, uploadedFiles: updatedFiles };
                            }
                            return indicator;
                        }),
                    };
                }
                return theme;
            })
        );
    };

    //================ handle outcome indicator ====================

    const handleValueChange = (deptIndex, targetIndex, newValue) => {
        setDeptOutcomeIndicatorTargets((currentTargets) => currentTargets.map((item, index) => (index === deptIndex ? { ...item, targets: item.targets.map((target, tIndex) => (tIndex === targetIndex ? { ...target, Uncomputed_value: newValue } : target)) } : item)));
    };

    const handleReasonChange = (deptIndex, targetIndex, newReason) => {
        setDeptOutcomeIndicatorTargets((currentTargets) => currentTargets.map((item, index) => (index === deptIndex ? { ...item, targets: item.targets.map((target, tIndex) => (tIndex === targetIndex ? { ...target, Uncomputed_reason_of_variance: newReason } : target)) } : item)));
    };

    const handleDeptAssessmentChange = (deptIndex, targetIndex, newAssessment) => {
        setDeptOutcomeIndicatorTargets((currentTargets) => currentTargets.map((item, index) => (index === deptIndex ? { ...item, targets: item.targets.map((target, tIndex) => (tIndex === targetIndex ? { ...target, Uncomputed_assessment: newAssessment } : target)) } : item)));
    };

    // const handleDenominatorChange = (deptIndex, targetIndex, newDenominator) => {
    //     setDeptOutcomeIndicatorTargets((currentTargets) =>
    //         currentTargets.map((item, index) =>
    //             index === deptIndex
    //                 ? {
    //                       ...item,
    //                       targets: item.targets.map((target, tIndex) => (tIndex === targetIndex ? { ...target, Uncomputed_denominator: newDenominator } : target)),
    //                   }
    //                 : item
    //         )
    //     );
    // };

    // const handleNumeratorChange = (deptIndex, targetIndex, newNumerator) => {
    //     setDeptOutcomeIndicatorTargets((currentTargets) =>
    //         currentTargets.map((item, index) =>
    //             index === deptIndex
    //                 ? {
    //                       ...item,
    //                       targets: item.targets.map((target, tIndex) => (tIndex === targetIndex ? { ...target, Uncomputed_numerator: newNumerator } : target)),
    //                   }
    //                 : item
    //         )
    //     );
    // };

    const handleNumeratorChange = (deptIndex, targetIndex, newNumerator) => {
        setDeptOutcomeIndicatorTargets((currentTargets) =>
            currentTargets.map((item, index) =>
                index === deptIndex
                    ? {
                          ...item,
                          targets: item.targets.map((target, tIndex) =>
                              tIndex === targetIndex
                                  ? {
                                        ...target,
                                        Uncomputed_numerator: newNumerator,
                                        Uncomputed_value:
                                            target.department_outcome_indicator.outcome_indicator.unit === "%"
                                                ? target.Uncomputed_denominator && parseFloat(target.Uncomputed_denominator) !== 0
                                                    ? ((parseFloat(newNumerator) / parseFloat(target.Uncomputed_denominator)) * 100).toFixed(2)
                                                    : 0
                                                : parseFloat(target.Uncomputed_value),
                                    }
                                  : target
                          ),
                      }
                    : item
            )
        );
    };

    const handleDenominatorChange = (deptIndex, targetIndex, newDenominator) => {
        setDeptOutcomeIndicatorTargets((currentTargets) =>
            currentTargets.map((item, index) =>
                index === deptIndex
                    ? {
                          ...item,
                          targets: item.targets.map((target, tIndex) =>
                              tIndex === targetIndex
                                  ? {
                                        ...target,
                                        Uncomputed_denominator: newDenominator,
                                        Uncomputed_value:
                                            target.department_outcome_indicator.outcome_indicator.unit === "%"
                                                ? newDenominator && parseFloat(newDenominator) !== 0
                                                    ? ((parseFloat(target.Uncomputed_numerator) / parseFloat(newDenominator)) * 100).toFixed(2)
                                                    : 0
                                                : parseFloat(target.Uncomputed_value ?? 0),
                                    }
                                  : target
                          ),
                      }
                    : item
            )
        );
    };

    return (
        <>
            <BreadcrumbNav />
            <Toast ref={toast} />
            <form onSubmit={handleSubmit}>
                <Panel
                    header={
                        <div>
                            <div>Reporting Module</div>

                            <div>
                                <strong>Department Name : </strong>
                                {selectedParentData?.name}
                            </div>
                        </div>
                    }
                    style={{ marginBottom: "20px" }}
                >
                    {/* Financial Year and Quarter in their own card */}

                    <Accordion style={{ marginBottom: "1rem" }}>
                        <AccordionTab header="Period Filters">
                            {/* <Card title="Period" style={{ marginBottom: "1rem" }}> */}
                            <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column", marginBottom: "1rem", gap: "2rem" }}>
                                    <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                        <label htmlFor="financialYear">Financial Year</label>
                                        <Dropdown
                                            value={financialYear}
                                            onChange={handleFinancialYearChange}
                                            options={getAllFinancialYearsQuery?.data?.data?.data.map((item) => ({
                                                label: item.name,
                                                value: item.id,
                                            }))}
                                            placeholder="Select a Financial Year"
                                            disabled={getAllFinancialYearsQuery.isLoading}
                                        />
                                        {getAllFinancialYearsQuery.isLoading && <ProgressSpinner style={{ width: "20px", height: "20px" }} />}
                                        {errors.financialYear && <small className="p-error">{errors.financialYear}</small>}
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                        <label>Quarter</label>
                                        <Dropdown
                                            value={quarter}
                                            onChange={handleQuarterChange}
                                            options={getAllQuartersQuery?.data?.data?.data.map((item) => ({
                                                label: item.name,
                                                value: item.id,
                                            }))}
                                            placeholder="Select a Quarter"
                                            disabled={getAllQuartersQuery.isLoading}
                                        />
                                        {getAllQuartersQuery.isLoading && <ProgressSpinner style={{ width: "20px", height: "20px" }} />}
                                        {errors.quarter && <small className="p-error">{errors.quarter}</small>}
                                    </div>

                                    <Button
                                        label="Filter"
                                        icon="pi pi-filter"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            console.log("This Button Saving village level Budget Outputs ");
                                            handlePressFilter(e);
                                        }}
                                        disabled={postToGetListOfThemeIndicatorTagetsMutation.isLoading || !quarter || !financialYear}
                                    />

                                    {postToGetListOfThemeIndicatorTagetsMutation.isLoading && (
                                        <div className="m-2">
                                            <ProgressBar mode="indeterminate" />
                                        </div>
                                    )}
                                </div>{" "}
                            </div>
                            {/* </Card> */}
                        </AccordionTab>
                    </Accordion>

                    {selectedParentData && postToGetListOfThemeIndicatorTagetsMutation.isLoading && postToGetListOfThemeIndicatorTagetsMutation.isFetching && (
                        <div className="m-2">
                            <ProgressBar mode="indeterminate" style={{ height: "4px" }} />
                        </div>
                    )}

                    <TabView scrollable>
                        <TabPanel header="Report Details">
                            {/* Budget Details */}
                            <Card title="Details" style={{ marginBottom: "1rem" }}>
                                <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "1rem", width: "100%" }}>
                                        <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem", width: "100%" }}>
                                            {/* Input for name */}
                                            <label htmlFor="name">Name</label>
                                            <InputText value={name} onChange={handleNameChange} placeholder="Name" />
                                            {errors.name && <small className="p-error">{errors.name}</small>}

                                            {/* Textarea for details, with existing onChange adjusted */}
                                            <label htmlFor="details">Details</label>
                                            <InputTextarea value={details} onChange={handleDetailsChange} rows={5} placeholder="Details" />
                                            {errors.details && <small className="p-error">{errors.details}</small>}
                                        </div>
                                    </div>
                                    {/* Details */}
                                    {/* <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                        <label htmlFor="details">Details</label>
                                        <InputTextarea disabled={isSubmitDisabled} value={details} onChange={handleDetailsChange} id="details" rows={5} placeholder="Details" />
                                        {errors.details && <small className="p-error">{errors.details}</small>}
                                    </div> */}
                                </div>
                            </Card>
                        </TabPanel>
                        <TabPanel header="Outcome Indicators">
                            <div>
                                {deptOutcomeIndicatorTargets.map((data, deptIndex) => (
                                    <Card title={`Department Outcome: ${data?.outcome?.outcome.name}`} key={deptIndex}>
                                        <DataTable value={data.targets}>
                                            <Column field="department_outcome_indicator.outcome_indicator.name" header="Indicator Name" />
                                            <Column field="base_year" header="Base Year" />
                                            <Column
                                                field="base_value"
                                                header="Base Value"
                                                body={(rowData) => {
                                                    let unit = rowData.department_outcome_indicator.outcome_indicator.unit; // Extract the unit for consistent usage
                                                    return `${parseInt(rowData.base_value) || 0} ${unit}`; // Display the base value with unit
                                                }}
                                            />
                                            <Column field="target_year" header="Target Year" />
                                            <Column
                                                field="target_value"
                                                header="Target Value"
                                                body={(rowData) => {
                                                    let unit = rowData.department_outcome_indicator.outcome_indicator.unit; // Adding unit from the rowData
                                                    return `${parseInt(rowData.target_value) || 0} ${unit}`; // Display the target value with unit
                                                }}
                                            />
                                            <Column field="financial_year.name" header="Financial Year" />
                                            <Column
                                                field="financial_year_target"
                                                header="Financial Year Target"
                                                body={(rowData) => {
                                                    let unit = rowData.department_outcome_indicator.outcome_indicator.unit; // Adding unit from the rowData
                                                    return `${parseInt(rowData.financial_year_target) || 0} ${unit}`; // Display the financial year target with unit
                                                }}
                                            />
                                            <Column
                                                header="Value"
                                                body={(rowData, { rowIndex }) => (
                                                    <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                                        <InputText value={rowData.Uncomputed_value} onChange={(e) => handleValueChange(deptIndex, rowIndex, e.target.value)} disabled={rowData.department_outcome_indicator.outcome_indicator.unit === "%"} />
                                                        {errors[`Uncomputed_value_${deptIndex}_${rowIndex}`] && <small className="p-error">{errors[`Uncomputed_value_${deptIndex}_${rowIndex}`]}</small>}
                                                    </div>
                                                )}
                                            />
                                            <Column
                                                header="Numerator"
                                                body={(rowData, { rowIndex }) => {
                                                    if (rowData.department_outcome_indicator.outcome_indicator.unit !== "%") {
                                                        return <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>N/A</div>;
                                                    } else {
                                                        return (
                                                            <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                                                <div>
                                                                    <small>{rowData.department_outcome_indicator.outcome_indicator.numerator}</small>
                                                                </div>
                                                                <InputText value={rowData.Uncomputed_numerator} onChange={(e) => handleNumeratorChange(deptIndex, rowIndex, e.target.value)} />
                                                                {errors[`Uncomputed_numerator_${deptIndex}_${rowIndex}`] && <small className="p-error">{errors[`Uncomputed_numerator_${deptIndex}_${rowIndex}`]}</small>}
                                                            </div>
                                                        );
                                                    }
                                                }}
                                            />
                                            <Column
                                                header="Denominator"
                                                body={(rowData, { rowIndex }) => {
                                                    if (rowData.department_outcome_indicator.outcome_indicator.unit !== "%") {
                                                        return <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>N/A</div>;
                                                    } else {
                                                        return (
                                                            <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                                                <div>
                                                                    <small>{rowData.department_outcome_indicator.outcome_indicator.denominator}</small>
                                                                </div>
                                                                <InputText value={rowData.Uncomputed_denominator} onChange={(e) => handleDenominatorChange(deptIndex, rowIndex, e.target.value)} />
                                                                {errors[`Uncomputed_denominator_${deptIndex}_${rowIndex}`] && <small className="p-error">{errors[`Uncomputed_denominator_${deptIndex}_${rowIndex}`]}</small>}
                                                            </div>
                                                        );
                                                    }
                                                }}
                                            />

                                            {/* <Column
                                            header="Value"
                                            body={(rowData, { rowIndex }) => (
                                                <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                                    <InputText value={rowData.Uncomputed_value} onChange={(e) => handleValueChange(deptIndex, rowIndex, e.target.value)} disabled={true} />
                                                    {errors[`Uncomputed_value_${deptIndex}_${rowIndex}`] && <small className="p-error">{errors[`Uncomputed_value_${deptIndex}_${rowIndex}`]}</small>}
                                                </div>
                                            )}
                                        /> */}
                                            <Column
                                                header="Reason of Variance"
                                                body={(rowData, { rowIndex }) => {
                                                    if (rowData.department_outcome_indicator.outcome_indicator.unit !== "%") {
                                                        return <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>N/A</div>;
                                                    } else {
                                                        return (
                                                            <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                                                <InputTextarea value={rowData.Uncomputed_reason_of_variance} onChange={(e) => handleReasonChange(deptIndex, rowIndex, e.target.value)} rows={1} />
                                                                {errors[`Uncomputed_reason_of_variance_${deptIndex}_${rowIndex}`] && <small className="p-error">{errors[`Uncomputed_reason_of_variance_${deptIndex}_${rowIndex}`]}</small>}
                                                            </div>
                                                        );
                                                    }
                                                }}
                                            />
                                            <Column
                                                header="Assessment"
                                                body={(rowData, { rowIndex }) => (
                                                    <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                                        <InputTextarea value={rowData.Uncomputed_assessment} onChange={(e) => handleDeptAssessmentChange(deptIndex, rowIndex, e.target.value)} rows={1} />
                                                        {errors[`Uncomputed_assessment_${deptIndex}_${rowIndex}`] && <small className="p-error">{errors[`Uncomputed_assessment_${deptIndex}_${rowIndex}`]}</small>}
                                                    </div>
                                                )}
                                            />
                                        </DataTable>
                                    </Card>
                                ))}
                            </div>
                        </TabPanel>

                        <TabPanel header="Output Indicators / (KPI's)">
                            <Card title="Utility Output Indicators">
                                {kpiTargets.map((theme, themeIndex) => (
                                    <div key={theme.id}>
                                        <Card title={`${theme.name}`}>
                                            {/* <p>{theme.code}</p> */}
                                            <DataTable value={theme.indicators}>
                                                <Column field="utility_theme_indicator.output_indicator.name" header="Indicator Name"></Column>
                                                <Column field="financial_year.name" header="Financial Year"></Column>
                                                <Column field="quarter.name" header="Quarter"></Column>
                                                <Column field="target" header="Target"></Column>
                                                <Column
                                                    field="value"
                                                    header="Value"
                                                    body={(rowData, { rowIndex }) => (
                                                        <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                                            <InputText value={rowData.value} onChange={(e) => handleKPIValueChange(themeIndex, rowIndex, e.target.value)} />
                                                            {errors[`value_${themeIndex}_${rowIndex}`] && <small className="p-error">{errors[`value_${themeIndex}_${rowIndex}`]}</small>}
                                                        </div>
                                                    )}
                                                />
                                                <Column
                                                    field="reasonOfVariance"
                                                    header="Reason of Variance"
                                                    body={(rowData, { rowIndex }) => (
                                                        <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                                            <InputTextarea value={rowData.reasonOfVariance} rows={1} onChange={(e) => handleReasonOfVarianceChange(themeIndex, rowIndex, e.target.value)} />
                                                            {errors[`reasonOfVariance_${themeIndex}_${rowIndex}`] && <small className="p-error">{errors[`reasonOfVariance_${themeIndex}_${rowIndex}`]}</small>}
                                                        </div>
                                                    )}
                                                />
                                                <Column
                                                    field="assessment"
                                                    header="Assessment"
                                                    body={(rowData, { rowIndex }) => (
                                                        <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                                            <InputTextarea value={rowData.assessment} rows={1} onChange={(e) => handleAssessmentChange(themeIndex, rowIndex, e.target.value)} />
                                                            {errors[`assessment_${themeIndex}_${rowIndex}`] && <small className="p-error">{errors[`assessment_${themeIndex}_${rowIndex}`]}</small>}
                                                        </div>
                                                    )}
                                                />
                                                {/* <Column body={(rowData, columnProps) => fileUploadTemplate(rowData, columnProps, themeIndex)} header="Upload PDF" /> */}
                                            </DataTable>
                                        </Card>
                                    </div>
                                ))}
                            </Card>
                        </TabPanel>

                        {/* // Outcomes and Indicators */}

                        {/* // Risks and mitigation */}
                        <TabPanel header="Risks And Mitigations">
                            <RiskMitigationComponent isSubmitDisabled={isSubmitDisabled} risks={risks} setRisks={setRisks} />
                        </TabPanel>
                    </TabView>
                </Panel>

                {/* Submit Button */}
                <div style={{ width: "100%", display: "flex", gap: "1rem" }}>
                    <Button
                        type="submit"
                        onClick={() => {
                            setFormSubmissionStatus("submitted");
                        }}
                        label="Submit"
                        style={{ marginTop: "2rem" }}
                        disabled={!postToGetListOfThemeIndicatorTagetsMutation?.data?.data || postToGetListOfThemeIndicatorTagetsMutation?.data?.data?.length > 1 || storeUtilityReportDataMutation?.isLoading}
                    />
                </div>
            </form>

            {storeUtilityReportDataMutation?.isLoading && (
                <center>
                    {" "}
                    <div className="spinner-container">
                        <ProgressSpinner
                            style={{
                                width: "50px",
                                height: "50px",
                                borderWidth: "50px", // Border thickness
                                borderColor: "blue", // Border color
                                animationDuration: "1s",
                            }}
                            strokeWidth="8"
                            animationDuration="1s"
                        />
                    </div>
                </center>
            )}

            {/* Confirmation Dialog */}
            <Dialog
                header="Confirmation"
                visible={showConfirmDialog}
                maximizable
                style={{ minWidth: "30vw" }}
                onHide={onCancel}
                footer={
                    <div>
                        <Button label="Yes" onClick={onConfirm} />
                        <Button label="No" onClick={onCancel} className="p-button-secondary" />
                    </div>
                }
            >
                Are you sure you want to submit this form?
            </Dialog>
        </>
    );
}

export default UtilityDeptReportingPage;
