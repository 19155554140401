import axiosAPI from "../axiosApi";

// export async function getAllStoreSubLocationss(params = {}) {
//     const response = await axiosAPI.get("store-sub-locations", { params: params });
//     return response;
// }

// export async function getStoreSubLocationssById(id) {
//     const response = await axiosAPI.get(`store-sub-locations/${id}`);
//     return response;
// }

export async function postStoreSubLocations(data) {
    const response = await axiosAPI.post(`store-sub-locations`, data);
    return response;
}

// export async function updateStoreSubLocationss(id, data) {
//     const response = await axiosAPI.put(`store-sub-locations/${id}`, data);
//     return response;
// }

// export async function deleteStoreSubLocationssById(id) {
//     const response = await axiosAPI.delete(`store-sub-locations/${id}`);
//     return response;
// }
