import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import setFieldTouched from "final-form-set-field-touched";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { ProgressSpinner } from "primereact/progressspinner";
import { Checkbox } from "primereact/checkbox";

import { Dropdown } from "primereact/dropdown";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteProjectById, getAllProjects, getProjectsByProjectIdOrRole, getProjectsByDepartmentIdAndRole } from "../../../../../../../services/projects/project-service";
import { getAllDepartments } from "../../../../../../../services/departments/department-service";
import { classNames } from "primereact/utils";
import { getAllDepartmentBudjetOutputs, getDepartmentBudjetOutputById, postDepartmentBudjetOutput, updateDepartmentBudjetOutput, deleteDepartmentBudjetOutputById } from "../../../../../../../services/departments/departments-budget-outputs-service.js";
import { getAllFinancialYears, getFinancialYearsById, postFinancialYears, updateFinancialYears, deleteFinancialYears } from "../../../../../../../services/financial-years/financial-years-service";
import { getAllQuarters, getQuartersById, postQuarters, updateQuarters, deleteQuarters } from "../../../../../../../services/financial-years/quarters";
import { getAllDepartmentIndicators, getDepartmentIndicatorById, postDepartmentIndicator, updateDepartmentIndicator, deleteDepartmentIndicatorById } from "../../../../../../../services/departments/departments-indicators-service";
import { getAllDepartmentOutcomeIndicators, getDepartmentOutcomeIndicatorById, postDepartmentOutcomeIndicator, updateDepartmentOutcomeIndicator, deleteDepartmentOutcomeIndicatorById } from "../../../../../../../services/departments/departmentOutcomeIndicator-service.js";

function RowForm({ handleSubmit, initialData, selectedParentItem, ...props }) {
    console.log("initial data data we are submitting outcome targets : ", initialData);
    console.log("initial data data we are submitting selectedParentItem outcome targets : ", selectedParentItem);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);
    const queryClient = useQueryClient();

    // Setting up custom state for storing selected DepartmentOutcomeIndicator indicator
    const [selectedDepartmentOutcomeIndicatorIndicator, setSelectedDepartmentOutcomeIndicatorIndicator] = useState(selectedParentItem?.id);

    // ================== get finacial years =================

    const getAllFinancialYearsQuery = useQuery(["financialYear"], getAllFinancialYears, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching getAllFinancialYears is : ", error);
        },
    });

    // ================= quarters ================

    // const getAllQuartersQuery = useQuery(["quarters"], getAllQuarters, {
    //     onSuccess: (data) => {},
    //     onError: (error) => {
    //         console.log("Error fetching quarters is : ", error);
    //     },
    // });

    // 'department_indicator_id',
    //     'fyear_id',
    //     'quarter_id',
    //     'baseline',
    //     'actual',
    //     'target',
    //     'reason_of_variance',
    //     'created_by',
    //     'updated_by'

    //  /* // 'base_year', // 'base_value', // 'target_year', // 'target_value', // 'financial_year_target', */

    const validate = (values) => {
        const errors = {};

        if (!values.fyear_id) errors.fyear_id = "financial year is required";

        if (!values.department_outcome_indicator_id) errors.department_outcome_indicator_id = "Department OutCome Indicator is required";

        // if (!values.target) errors.target = "Target Budget Output is required";
        if (!values.base_year) errors.base_year = "base year Budget Output is required";
        if ((!values.base_value || values.base_value < 1 || values.base_value > 100) && matchingIndicatorObject?.outcome_indicator?.unit == "%") errors.base_value = "Please provide a valid value between 0 and 100.";
        if (!values.target_year) errors.target_year = "target year Budget Output is required";
        if ((!values.target_value || values.target_value < 1 || values.target_value > 100) && matchingIndicatorObject?.outcome_indicator?.unit == "%") errors.target_value = "Please provide a valid value between 0 and 100.";
        if ((!values.financial_year_target || values.financial_year_target < 1 || values.financial_year_target > 100) && matchingIndicatorObject?.outcome_indicator?.unit == "%") errors.financial_year_target = "Please provide a valid value between 0 and 100.";

        // Conditional validation for manual_value
        if (values.show_manual_value && !values.manual_value) {
            errors.manual_value = "Not Computed value is required when Not Computed is checked";
        }

        return errors;
    };

    // const onSubmitForm = (data) => {
    //     const errors = validate(data);
    //     if (Object.keys(errors).length === 0) {
    //         // No validation errors
    //         setPendingData(data);
    //         setShowConfirmDialog(true);
    //     } else {
    //         toast.warning("First Fill In All Required Fields");
    //     }
    // };

    const onSubmitForm = (data, form) => {
        // Add 'form' as an argument
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            // Mark all fields as touched to show validation errors
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("First fill in all required fields.");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    //================== department outcome indicator ==================

    const getAllDepartmentOutcomeIndicatorsQuery = useQuery(
        ["department-outcome-indicator", "departments", selectedParentItem?.department_id],
        () => (!selectedParentItem?.department_id ? getAllDepartmentOutcomeIndicators() : getAllDepartmentOutcomeIndicators({ department_id: selectedParentItem?.department_id })),
        {
            onSuccess: (data) => {
                console.log("success fetching department-outcome-indicator kkkkk is : ", data);
            },
            onError: (error) => {
                console.log("Error fetching department-outcome-indicator is : ", error);
            },
        }
    );

    // initialData = initialData ? { department_id: props?.department_id, ...initialData } : { department_id: selectedParentItem?.department_id, department_budget_outputs_id: selectedParentItem?.id };

    if (selectedParentItem) {
        initialData = { department_outcome_indicator_id: selectedParentItem?.id, ...initialData };
    }

    //================== departmengt budget outputs =============
    // const fetchAllDepartmentBudjetOutputs = useQuery(["departments", "departmentBudgetOutputs"], getAllDepartmentBudjetOutputs, {
    //     onSuccess: (data) => {},
    //     onError: (error) => {
    //         console.log("Error fetching projectSubprojectMetricsIndicator is : ", error);
    //     },
    // });

    // This will find the object that matches the selected ID
    const matchingIndicatorObject = getAllDepartmentOutcomeIndicatorsQuery?.data?.data?.data?.find((item) => item?.id === selectedDepartmentOutcomeIndicatorIndicator);
    console.log("matchingIndicatorObject is : ", matchingIndicatorObject);

    return (
        <div className="col-12 md:col-12">
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    mutators={{ setFieldTouched }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                console.log("values hhh : ", values);
                                console.log("event fffff : ", event);
                                onSubmitForm(values, form);
                                // handleSubmit(event, values);
                            }}
                        >
                            <div style={{ marginBottom: "1rem" }}>
                                <Field name="department_outcome_indicator_id">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Department Outcome indicator</label>
                                            <Dropdown
                                                value={input.value}
                                                options={getAllDepartmentOutcomeIndicatorsQuery?.data?.data?.data?.map((item) => ({
                                                    label: item?.outcome_indicator?.name,
                                                    value: item?.id,
                                                }))}
                                                // onChange={(e) => input.onChange(e.value)}

                                                onChange={(e) => {
                                                    input.onChange(e.value);
                                                    setSelectedDepartmentOutcomeIndicatorIndicator(e.value); // Update the custom state when the dropdown changes
                                                }}
                                                disabled={getAllDepartmentOutcomeIndicatorsQuery?.isLoading}
                                                // disabled={props?.loggedInUserData?.role !== "Admin"}

                                                placeholder="Select Department Outcome Indicator"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />

                                            {getAllDepartmentOutcomeIndicatorsQuery.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            {/* Column 2 */}

                            {/* base_year */}

                            <Field name="base_year">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="base_year">Base Year</label>
                                        <Calendar
                                            {...input}
                                            value={input.value ? new Date(input.value) : null} // Set the Calendar value
                                            view="year" // Change view to year only
                                            dateFormat="yy" // Change date format to year only
                                            // yearNavigator // Enable year navigator
                                            yearRange="2010:2030" // Set a range of years
                                            showIcon // Add this to show the calendar icon
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            onChange={(e) => {
                                                // Update the input field only if the value is a valid date
                                                if (e.value instanceof Date) {
                                                    const formattedDate = moment(e.value).format("YYYY"); // Format date to year only
                                                    input.onChange(formattedDate);
                                                }
                                            }}
                                        />
                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="base_value">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label>Base Value {matchingIndicatorObject?.outcome_indicator?.unit ? matchingIndicatorObject?.outcome_indicator?.unit : ""}</label>
                                        <InputText
                                            {...input}
                                            id="base_value"
                                            type="number"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                // Allow only non-negative numbers
                                                if (!value || parseFloat(value) >= 0) {
                                                    input.onChange(value);
                                                }
                                            }}
                                        />
                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="target_year">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="target_year">Target Year</label>
                                        <Calendar
                                            {...input}
                                            value={input.value ? new Date(input.value) : null} // Set the Calendar value
                                            view="year" // Change view to year only
                                            dateFormat="yy" // Change date format to year only
                                            // yearNavigator // Enable year navigator
                                            yearRange="2010:2030" // Set a range of years
                                            showIcon // Add this to show the calendar icon
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            onChange={(e) => {
                                                // Update the input field only if the value is a valid date
                                                if (e.value instanceof Date) {
                                                    const formattedDate = moment(e.value).format("YYYY"); // Format date to year only
                                                    input.onChange(formattedDate);
                                                }
                                            }}
                                        />
                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="target_value">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        {/* <label>Target Value {matchingIndicatorObject?.outcome_indicator?.unit ? matchingIndicatorObject?.outcome_indicator?.unit : ""}</label> */}
                                        <label>Target {matchingIndicatorObject?.outcome_indicator?.unit ? matchingIndicatorObject?.outcome_indicator?.unit : ""}</label>

                                        <InputText
                                            {...input}
                                            id="target_value"
                                            type="number"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                // Allow only non-negative numbers
                                                if (!value || parseFloat(value) >= 0) {
                                                    input.onChange(value);
                                                }
                                            }}
                                        />
                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="fyear_id">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label>Financial Year</label>
                                        <Dropdown
                                            value={input.value}
                                            options={getAllFinancialYearsQuery?.data?.data?.data.map((item) => ({
                                                label: item.name,
                                                value: item.id,
                                            }))}
                                            onChange={(e) => input.onChange(e.value)}
                                            // disabled={props?.loggedInUserData?.role !== "Admin"}
                                            placeholder="Select Financial Year"
                                        />
                                    </div>
                                )}
                            </Field>
                            <Field name="financial_year_target">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label>Financial year target {matchingIndicatorObject?.outcome_indicator?.unit ? matchingIndicatorObject?.outcome_indicator?.unit : ""}</label>
                                        <InputText
                                            {...input}
                                            id="financial_year_target"
                                            type="number"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                // Allow only non-negative numbers
                                                if (!value || parseFloat(value) >= 0) {
                                                    input.onChange(value);
                                                }
                                            }}
                                        />
                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="show_manual_value">
                                {({ input, meta }) => (
                                    <div className="p-field-checkbox m-4">
                                        <Checkbox inputId="show_manual_value" checked={!!input.value} onChange={(e) => input.onChange(e.checked)} />
                                        <label htmlFor="show_manual_value">Not Computed</label>
                                    </div>
                                )}
                            </Field>

                            {/* Conditionally render the manual_value field based on show_manual_value */}
                            {/* <Field name="manual_value">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="manual_value">Manual Value</label>
                                            <InputTextarea {...input} id="manual_value" type="text" rows={4} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field> */}
                            {/* {values.show_manual_value && selectedParentItem?.department?.code == "03" && ( */}
                            {values.show_manual_value ? (
                                <Field name="manual_value">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="manual_value">Not Computed Value {matchingIndicatorObject?.outcome_indicator?.unit ? matchingIndicatorObject?.outcome_indicator?.unit : ""}</label>
                                            <InputText {...input} id="name" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                            {meta.touched && (meta.error || form.submitSuccess || form.submitError) && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            ) : (
                                <></>
                            )}

                            <div className="d-grid gap-2">
                                <Button type="submit" label="Save" className="p-button-primary" icon="pi pi-check" />
                            </div>

                            {/* <div style={{ marginBottom: "1rem" }}>
                                <Field name="quarter_id">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Quarter</label>
                                            <Dropdown
                                                value={input.value}
                                                options={getAllQuartersQuery?.data?.data?.data.map((item) => ({
                                                    label: item.name,
                                                    value: item.id,
                                                }))}
                                                onChange={(e) => input.onChange(e.value)}
                                                // disabled={props?.loggedInUserData?.role !== "Admin"}
                                                placeholder="Select Quarter"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                        </div>
                                    )}
                                </Field>
                            </div> */}
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    style={{ width: "30vw" }}
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to submit?
                </Dialog>
            </div>
        </div>
    );
}

export default RowForm;
