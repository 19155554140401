import axiosAPI from "../axiosApi";

export async function getAllUtilityTypes(params = {}) {
    const response = await axiosAPI.get("utility-types", { params: params });
    return response;
}

export async function getUtilityTypesById(id) {
    const response = await axiosAPI.get(`utility-types/${id}`);
    return response;
}

export async function postUtilityTypes(data) {
    const response = await axiosAPI.post(`utility-types`, data);
    return response;
}

export async function updateUtilityTypes(id, data) {
    const response = await axiosAPI.put(`utility-types/${id}`, data);
    return response;
}

export async function deleteUtilityTypesById(id) {
    const response = await axiosAPI.delete(`utility-types/${id}`);
    return response;
}
