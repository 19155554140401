import axiosAPI from "../../axiosApi";

export async function getAllDepartmentUtilityGazettes(params = {}) {
    const response = await axiosAPI.get("utility-gazettes", { params: params });
    return response;
}

export async function getDepartmentUtilityGazetteById(id) {
    const response = await axiosAPI.get(`utility-gazettes/${id}`);
    return response;
}

export async function postDepartmentUtilityGazette(data) {
    const response = await axiosAPI.post(`utility-gazettes`, data);
    return response;
}

export async function updateDepartmentUtilityGazette(id, data) {
    const response = await axiosAPI.put(`utility-gazettes/${id}`, data);
    return response;
}

export async function deleteDepartmentUtilityGazetteById(id) {
    const response = await axiosAPI.delete(`utility-gazettes/${id}`);
    return response;
}
