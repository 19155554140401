import React, { useEffect, memo } from "react";
import { useQuery } from "@tanstack/react-query";
import { getAllDepartments } from "../../../../services/departments/department-service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// Import dashboard pages
import RuralDashboardPage from "../rural-dashboard/RuralDashboardPage";
import UrbanDashboardPage from "../urban-dashboard/UrbanDashboardPage";
import WaterForProductionDashboardPage from "../water-for-production/WaterForProductionDashboardPage";
import WaterUmbrellaUtilitiesDashboardPage from "../water-umbrella-utilities/WaterUmbrellaUtilitiesDashboardPage";
import UmbrellaUtilitiesDashboardPage from "../water-umbrella-utilities/UmbrellaUtilitiesDashboardPage.jsx";
import PlanningAndPolicyDasboardPage from "../planning-and-policy/PlanningAndPolicyDasboardPage";

import { ProgressBar } from "primereact/progressbar";
import { TabView, TabPanel } from "primereact/tabview";
import NoDataManSittingAnimation from "../assets/NoDataManSittingAnimation - 1702466708619.json";
import NoDataManStandingAnimation from "../assets/NoDataManStandingAnimation - 1702466565785.json";
import Lottie from "lottie-react";

// Memoize dashboard components
const MemoizedRuralDashboardPage = memo(RuralDashboardPage);
const MemoizedUrbanDashboardPage = memo(UrbanDashboardPage);
const MemoizedWaterForProductionDashboardPage = memo(WaterForProductionDashboardPage);
const MemoizedWaterUmbrellaUtilitiesDashboardPage = memo(UmbrellaUtilitiesDashboardPage);
const MemoizedPlanningAndPolicyDasboardPage = memo(PlanningAndPolicyDasboardPage);

function AdminDashboardPage({ loggedInUserData, ...props }) {
    const navigate = useNavigate();
    const fetchAllDepartments = useQuery(["departments", loggedInUserData?.user_directorate_id], () => (!loggedInUserData?.user_directorate_id ? getAllDepartments() : getAllDepartments({ directorate_id: loggedInUserData?.user_directorate_id })), {
        enabled: !!loggedInUserData?.user_directorate_id,
        onSuccess: (data) => {
            console.log("response after fetching departments on dashboard: ", data?.data);
        },
        onError: (error) => {
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("An Error Occurred Please Contact Admin");
            console.log("get list of departments: ", error);
        },
    });

    useEffect(() => {
        if (!loggedInUserData?.user_directorate_id) {
            toast.warning("You are not associated with any directorate", {
                autoClose: false,
            });
        }
    }, [loggedInUserData?.user_directorate_id]);

    if (!loggedInUserData?.user_directorate_id) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                <Lottie animationData={NoDataManStandingAnimation} loop={true} autoplay={true} style={{ width: 300 }} />
            </div>
        );
    }

    if (fetchAllDepartments.isLoading && fetchAllDepartments.isFetching) {
        return (
            <div className="p-field m-4">
                <ProgressBar mode="indeterminate" style={{ height: "6px" }} />
            </div>
        );
    }

    const renderDepartmentDashboard = (department) => {
        switch (department.code) {
            case "01":
                return <MemoizedRuralDashboardPage departmentDetail={department} />;
            case "02":
                return <MemoizedUrbanDashboardPage departmentDetail={department} />;
            case "03":
                return <MemoizedWaterUmbrellaUtilitiesDashboardPage departmentDetail={department} />;
            case "04":
                return <MemoizedWaterForProductionDashboardPage departmentDetail={department} />;
            case "05":
                return <MemoizedPlanningAndPolicyDasboardPage departmentDetail={department} />;
            default:
                return (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                        <Lottie animationData={NoDataManSittingAnimation} loop={true} autoplay={true} style={{ width: 300 }} />
                    </div>
                );
        }
    };

    return (
        <div>
            <TabView scrollable={true}>
                {fetchAllDepartments.data?.data?.data?.map((department, index) => (
                    <TabPanel key={department.id} header={department.name}>
                        {renderDepartmentDashboard(department)}
                    </TabPanel>
                ))}
            </TabView>
        </div>
    );
}

export default AdminDashboardPage;
