import axiosAPI from "../axiosApi";

export async function getAllFinancialYears(params = {}) {
    const response = await axiosAPI.get("financial-years", { params: params });
    return response;
}

export async function getFinancialYearsById(id) {
    const response = await axiosAPI.get(`financial-years/` + id);
    return response;
}

export async function postFinancialYears(data) {
    const response = await axiosAPI.post(`financial-years`, data);
    return response;
}

export async function updateFinancialYears(id, data) {
    const response = await axiosAPI.put(`financial-years/${id}`, data);
    return response;
}

export async function deleteFinancialYears(id) {
    const response = await axiosAPI.delete(`financial-years/${id}`);
    return response;
}
