import axiosAPI from "../axiosApi";

export async function getAllMonths(params = {}) {
    const response = await axiosAPI.get("months", { params: params });
    return response;
}

export async function getMonthsById(id) {
    const response = await axiosAPI.get(`months/` + id);
    return response;
}

export async function postMonths(data) {
    const response = await axiosAPI.post(`months`, data);
    return response;
}

export async function updateMonths(id, data) {
    const response = await axiosAPI.put(`months/${id}`, data);
    return response;
}

export async function deleteMonths(id) {
    const response = await axiosAPI.delete(`months/${id}`);
    return response;
}
