import React from "react";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Image } from "primereact/image";
import { Dialog } from "primereact/dialog";
import moment from "moment"; // Import moment

function SubProjectPerformanceReportPdfModal({ subprojectDetail, selectedItem, ...props }) {
    console.log("selected item in SubProjectPerfomanceReportPdfModal : ", selectedItem);
    console.log("selected item in subprojectDetail : ", subprojectDetail);
    const formatCurrency = (value) => {
        // Format your currency here
        return new Intl.NumberFormat("en-US", { style: "currency", currency: "UGX" }).format(value);
    };

    const imageBodyTemplate = (rowData) => {
        return <Image src={`${process.env.REACT_APP_API_BASE_URL}${rowData.photo_url}`} alt={rowData.caption} width="100" preview />;
    };

    // Function to format date using moment
    const formatDate = (dateString) => {
        return moment(dateString).format("MMMM Do YYYY, h:mm:ss a"); // Example format
    };

    // Function to display status with color
    const statusWithColor = (status) => {
        let color;
        switch (status) {
            case "pending approval":
                color = "blue";
                break;
            case "approved":
                color = "green";
                break;
            case "pending":
                color = "orange";
                break;
            case "rejected":
                color = "red";
                break;
            default:
                color = "grey";
        }
        // return <span style={{ color }}>{status?.charAt(0)?.toUpperCase() + status?.slice(1)}</span>;
        return <span style={{ color }}>{status}</span>;
    };

    const gridStyle = {
        display: "grid",
        gridTemplateColumns: "1fr 1fr", // This creates two columns of equal width
        gridGap: "1rem", // Space between the columns
    };

    const riskTypeBodyTemplate = (rowData) => {
        let color;
        switch (rowData.risk_type) {
            case "high":
                color = "red";
                break;
            case "moderate":
                color = "orange";
                break;
            case "low":
                color = "grey";
                break;
            default:
                color = "black";
        }

        return (
            <div>
                <span className="p-mr-2" style={{ fontSize: "1.5rem", color, fontWeight: "bold" }}>
                    •
                </span>
                {/* {rowData.risk_type.charAt(0).toUpperCase() + rowData.risk_type.slice(1)} */}
                {rowData.risk_type}
            </div>
        );
    };

    return (
        <Dialog
            header={
                <div>
                    <div style={{ color: "green" }}>Project Report.</div>

                    {/* <div>
                        <strong>Subproject Name : </strong>
                        {selectedItem?.subproject?.name}
                    </div> */}
                </div>
            }
            visible={props.show}
            style={{ width: "80vw" }}
            onHide={props.onHide}
            maximizable
        >
            {/* Status Information */}
            <Card title="Report Details" style={{ marginBottom: "1rem" }}>
                <div className="flex flex-wrap">
                    {/* Column 1 */}
                    <div className="md:w-1/2 px-2 m-1">
                        <p>
                            <strong>Project Name :</strong> {selectedItem?.subproject?.name || "N/A"}
                        </p>
                        <p>
                            <strong>Project Code:</strong> {selectedItem?.subproject?.code}
                        </p>
                        <p>
                            <strong>Project Title:</strong> {subprojectDetail?.project?.project_title || "N/A"}
                        </p>
                        <p>
                            <strong>Project Code:</strong> {subprojectDetail?.project?.project_code}
                        </p>

                        {/* <p>
                            <strong>Status:</strong> {statusWithColor(selectedItem?.status)}
                        </p> */}

                        <p>
                            <strong>Approval Status:</strong> {statusWithColor(selectedItem?.current_status?.name)}
                        </p>

                        <p>
                            <strong>Approval Comment:</strong> {statusWithColor(selectedItem?.current_status?.reason)}
                        </p>
                    </div>

                    {/* Column 2 */}
                    <div className="md:w-1/2 px-2 m-1">
                        <p>
                            <strong>Finacial Year:</strong> {selectedItem?.financial_year?.name}
                        </p>
                        <p>
                            <strong>Quarter:</strong> {selectedItem?.quarter?.name}
                        </p>
                    </div>

                    {/* Full Width Row for Details */}
                    {/* <div className="w-full px-2 m-2">
                        <strong>Project Details</strong>
                        <p>{"N/A"}</p>
                    </div> */}
                </div>
            </Card>
            {/* <Card title="Period" style={{ marginBottom: "1rem" }}></Card> */}
            <Card title="Budget Details" style={{ marginBottom: "1rem" }}>
                <div style={gridStyle}>
                    <p>
                        <strong>Approved Budget:</strong> {formatCurrency(selectedItem?.approved_budget)}
                    </p>
                    <p>
                        <strong>Spent Funds:</strong> {formatCurrency(selectedItem?.spent_funds)}
                    </p>
                    {/* <p>
                        <strong>Released Funds:</strong> {formatCurrency(selectedItem?.released_funds)}
                    </p> */}
                    <p>
                        <strong>Absorption Rate:</strong> {selectedItem?.absorption_rate}%
                    </p>
                    <p>
                        <strong>Description:</strong> {selectedItem?.description}
                    </p>
                </div>
            </Card>

            {selectedItem?.budget_outputs.map((output, index) => (
                <Card key={index} title={`Budget Output: ${output?.subproject_budget_output?.project_department_budget_output?.department_budget_output?.name}`} style={{ marginBottom: "1rem" }}>
                    <DataTable value={output?.indicators}>
                        <Column field="subproject_bgt_output_indicator_target.project_dept_bgt_output_indicator_target.department_budget_output_indicator.output_indicator.name" header="Indicator" headerStyle={{ backgroundColor: "orange", color: "white" }}></Column>
                        <Column
                            field="subproject_bgt_output_indicator_target.financial_year.name"
                            header="Financial Year"
                            headerStyle={{ backgroundColor: "orange", color: "white" }}
                            style={{ whiteSpace: "nowrap" }} // Add this line
                        ></Column>

                        <Column field="subproject_bgt_output_indicator_target.quarter.name" header="Quarter" headerStyle={{ backgroundColor: "orange", color: "white" }}></Column>
                        <Column field="subproject_bgt_output_indicator_target.target" header="Target" headerStyle={{ backgroundColor: "orange", color: "white" }}></Column>
                        <Column field="value" header="Value" headerStyle={{ backgroundColor: "orange", color: "white" }}></Column>
                        <Column field="reasonOfVariance" header="Reason of Variance" headerStyle={{ backgroundColor: "orange", color: "white" }}></Column>
                        <Column field="assessment" header="Progress to Date" headerStyle={{ backgroundColor: "orange", color: "white" }}></Column>
                    </DataTable>
                </Card>
            ))}

            {selectedItem?.risks_mitigation && (
                <Card title="Risks and Mitigations" style={{ marginBottom: "1rem" }}>
                    <DataTable value={selectedItem.risks_mitigation}>
                        <Column field="risk" header="Risk"></Column>
                        <Column field="risk_type" header="Risk Type" body={riskTypeBodyTemplate}></Column>
                        <Column field="mitigation" header="Mitigation"></Column>
                        {/* <Column field="created_at" header="Created At" body={formatDate}></Column> */}
                    </DataTable>
                </Card>
            )}

            {selectedItem?.challenge_recommendations && (
                <Card title="Challenges and Recommendations" style={{ marginBottom: "1rem" }}>
                    <DataTable value={selectedItem.challenge_recommendations}>
                        <Column field="challenges" header="Challenges"></Column>
                        <Column field="recommendations" header="Recommendations"></Column>
                        {/* <Column field="created_at" header="Created At" body={formatDate}></Column> */}
                    </DataTable>
                </Card>
            )}

            {/* Display Photos */}
            <Card title="Photos">
                <DataTable value={selectedItem?.photos}>
                    <Column header="Image" body={imageBodyTemplate}></Column>
                    <Column field="caption" header="Caption"></Column>
                </DataTable>
            </Card>

            {/* Created By and Updated By Information */}
            <p>
                <strong>Created By:</strong> {selectedItem?.created_by_user?.name} at {formatDate(selectedItem?.created_at)}
            </p>
            <p>
                <strong>Updated By:</strong> {selectedItem?.updated_by_user?.name} at {formatDate(selectedItem?.updated_at)}
            </p>
        </Dialog>
    );
}

export default SubProjectPerformanceReportPdfModal;
