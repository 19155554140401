import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";

const RecomendationsHistory = ({ selectedprojectReports }) => {
    console.log("selectedprojectReports jdghsjhsd : ", selectedprojectReports);
    return (
        <div>
            {selectedprojectReports.map((report, index) => (
                <Card
                    key={report.id || index}
                    title={
                        <>
                            <div>Project name: {report?.project?.project_title}</div>
                            <div>Department Administration name: {report?.project?.department_administration?.name}</div>
                        </>
                    }
                    subTitle={`Financial Year: ${report.financial_year.name}`}
                >
                    <p>Quarter: {report.quarter.name}</p>
                    {/* <p>Description: {report.description}</p>
                    <p>Approved Budget: {report.approved_budget}</p>
                    <p>Spent Funds: {report.spent_funds}</p>
                    <p>Absorption Rate: {report.absorption_rate}</p>
                    <p>Current Status: {report.current_status.name}</p> */}

                    {/* <h3>Challenges And Recommendations History</h3> */}
                    <DataTable value={report.challenge_recommendations}>
                        <Column field="challenges" header="Challenges"></Column>
                        <Column field="recommendations" header="Recommendations"></Column>
                    </DataTable>
                </Card>
            ))}
        </div>
    );
};

export default RecomendationsHistory;
