import React, { useState, useEffect } from "react";
// import { Card } from "primereact/card";
import { Tree } from "primereact/tree";
import { Divider } from "primereact/divider";
import { useLocation } from "react-router-dom";
import { Panel } from "primereact/panel";
import BreadcrumbNav from "../../../../../../components/mweComponents/BreadcrumbNav";
import { Button } from "primereact/button";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { Fieldset } from "primereact/fieldset";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { TabView, TabPanel } from "primereact/tabview";
// import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primereact/resources/themes/lara-light-blue/theme.css";
// import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

import { Tag } from "primereact/tag";

import { getAllDepartmentThemeIndicators, getDepartmentThemeIndicatorById, postDepartmentThemeIndicator, updateDepartmentThemeIndicator, deleteDepartmentThemeIndicatorById } from "../../../../../../services/departments/utility-dept/departments-themes-indicators-service.js";

import DepartmentThemeIndicatorTargetsPage from "./department-theme-indicator-targets/DepartmentThemeIndicatorTargetsPage";

const DepartmentThemeIndicatorsViewPage = ({ loggedInUserData }) => {
    const queryClient = useQueryClient();
    //
    let { state } = useLocation();
    let selectedThemeIndicatorData = state ? state?.selectedThemeIndicatorData : null;
    console.log("selectedThemeIndicatorData data got from state is gfdg : ", selectedThemeIndicatorData);

    //===================== getDepartmentById by id =================

    const fetchDepartmentThemeIndicatorById = useQuery(["department-theme-indicators", "by-theme-indicator-id", selectedThemeIndicatorData?.id], () => getDepartmentThemeIndicatorById(selectedThemeIndicatorData?.id), {
        onSuccess: (data) => {
            console.log("department-theme-indicators onsuccess fetching : ", data);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : toast.error("Án Error Occured Please Contact Admin");
            console.log("get list of department-theme-indicators : ", error);
        },
    });

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showBudjetOutPutAddForm, setShowBudjetOutPutAddForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    return (
        <div style={{ width: "100%" }}>
            <BreadcrumbNav />
            <div className="projects-view-page">
                <Panel
                    header={
                        <span>
                            <p>Department : {selectedThemeIndicatorData?.department?.name}</p>
                            <p>Theme : {selectedThemeIndicatorData?.utility_theme?.name}</p>
                            <p>Indicator : {selectedThemeIndicatorData?.output_indicator?.name}</p>
                        </span>
                    }
                    style={{ marginBottom: "20px" }}
                >
                    <TabView>
                        <TabPanel header="Details">
                            <div className="flex flex-wrap">
                                {/* Column 1 */}
                                <div className="w-full md:w-1/2 px-2 mb-4">
                                    <p>
                                        <strong>Theme Indicator Name:</strong> {selectedThemeIndicatorData?.output_indicator?.name}
                                    </p>
                                </div>

                                {/* Column 2 */}
                                <div className="w-full md:w-1/2 px-2 mb-4">
                                    <p>
                                        <strong>Theme Indicator Measure:</strong> {selectedThemeIndicatorData?.output_indicator?.measure}
                                    </p>
                                    <p>
                                        <strong>Theme Indicator Code:</strong> {selectedThemeIndicatorData?.output_indicator?.code}
                                    </p>

                                    <p>
                                        <strong>Theme Indicator Definition:</strong> {selectedThemeIndicatorData?.output_indicator?.definition}
                                    </p>

                                    <p>
                                        <strong>Theme :</strong> {selectedThemeIndicatorData?.utility_theme?.name}
                                    </p>
                                    {/* <p>
                                        <strong>Indicator Type:</strong> <Tag value={selectedThemeIndicatorData?.outcome_indicator?.outcome_indicator_type?.name} rounded></Tag>
                                    </p> */}
                                    {/* <p>
                                        <strong>Updated By:</strong> {selectedThemeIndicatorData?.updated_by?.email || "N/A"}
                                    </p> */}
                                </div>
                            </div>

                            <Divider />
                        </TabPanel>
                        <TabPanel header="Targets">
                            <DepartmentThemeIndicatorTargetsPage
                                loggedInUserData={loggedInUserData}
                                selectedThemeIndicatorData={selectedThemeIndicatorData || fetchDepartmentThemeIndicatorById?.data?.data?.data}
                                selectedThemeIndicatorDataId={selectedThemeIndicatorData?.id || fetchDepartmentThemeIndicatorById?.data?.data?.data?.id}
                            />
                        </TabPanel>

                        {/* <TabPanel header="Directorate Reports"></TabPanel> */}
                    </TabView>
                </Panel>
            </div>
        </div>
    );
};

export default DepartmentThemeIndicatorsViewPage;

// Sample usage:
// <ProjectsViewPage project={{
//     name: 'Urban Development Project',
//     code: 'UD123',
//     department: 'Urban Planning',
//     type: 'Development',
//     responsibleOfficer: 'John Doe',
//     details: 'This project focuses on...',
//     districts: [{ id: '1', name: 'District 1' }, { id: '2', name: 'District 2' }]
// }} />
