import React from "react";
import DepartmentList from "./DepartmentList";
import { Link } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";

import BreadcrumbNav from "../../../components/mweComponents/BreadcrumbNav";
import "primereact/resources/themes/lara-light-indigo/theme.css";

const createBreadCrump = () => {};
//
function DepartmentsPage({ loggedInUserData }) {
    // let { id } = useParams();
    let { state } = useLocation();
    const location = useLocation();
    let linkdirectorateId = state ? state.linkdirectorateId : null;
    let name = state?.name ? state?.name : null;
    let linksData = [{ name: "programs" }, { name: "Departments" }];

    console.log("linkdirectorateId : ", linkdirectorateId);
    //
    return (
        <div>
            <BreadcrumbNav />

            <DepartmentList loggedInUserData={loggedInUserData} directorateId={linkdirectorateId} oldlinks={{ names: { name } }} prevPage={name} />
        </div>
    );
}

export default DepartmentsPage;
