import React from "react";
import ListPage from "./ListPage";
import { Link } from "react-router-dom";

import BreadcrumbNav from "../../../../../../components/mweComponents/BreadcrumbNav";

const createBreadCrump = () => {};
//
function UtilitiesApprovalPage({ selectedUtilityData, loggedInUserData }) {
    let linksData = [{ name: "programs" }];
    return (
        <div>
            {/* <BreadcrumbNav /> */}

            <ListPage selectedUtilityData={selectedUtilityData} loggedInUserData={loggedInUserData} />
        </div>
    );
}

export default UtilitiesApprovalPage;
