import React, { useEffect } from "react";
import L from "leaflet";
import "leaflet-easyprint";
import "leaflet-fullscreen";
import { useMap } from "react-leaflet";

function MapPrint() {
    const map = useMap();

    useEffect(() => {
        // Add EasyPrint control
        const easyPrintControl = L.easyPrint({
            position: "topleft",
            sizeModes: ["Current", "A4Portrait", "A4Landscape"],
            hideControlContainer: false,
            title: "Print",
        });

        easyPrintControl.addTo(map);

        return () => {
            map.removeControl(easyPrintControl);
        };
    }, [map]);

    return null;
}

export default MapPrint;
