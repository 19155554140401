import axiosAPI from "../axiosApi";

export async function getAllSubprojectFieldData(params = {}) {
    const response = await axiosAPI.get("subproject-field-data", { params: params });
    return response;
}

export async function getSubprojectFieldDataById(id) {
    const response = await axiosAPI.get(`subproject-field-data/${id}`);
    return response;
}

export async function postSubprojectFieldData(data) {
    const response = await axiosAPI.post(`subproject-field-data`, data);
    return response;
}

export async function storeSubprojectFieldData(data) {
    const response = await axiosAPI.post(`storeSubProjectFieldData`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function customUpdateSubProjectFieldData(data) {
    const response = await axiosAPI.post(`updateSubProjectFieldData`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function updateSubprojectFieldData(id, data) {
    const response = await axiosAPI.put(`subproject-field-data/${id}`, data);
    return response;
}

export async function deleteSubprojectFieldDataById(id) {
    const response = await axiosAPI.delete(`subproject-field-data/${id}`);
    return response;
}

export async function updateSubprojectDataStatus(data) {
    const response = await axiosAPI.post("subprojectfielddata-update-status", data);
    return response;
}

export async function postToAggregateSubProjectReports(data) {
    const response = await axiosAPI.post(`aggregateSubProjectReports`, data);
    return response;
}
