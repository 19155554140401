import React, { useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import FiltersForm from "./FiltersForm";

function LocationFiltersPage({ filterQueryIsLoading, setFilterQueryIsLoading, filterParams, setFilterParams, filterSelectedObjects, setFilterSelectedObjects, waterPointsData, setWaterPointsData, loggedInUserData }) {
    // State to manage which tab is active, null means all tabs are closed set to null to be closed set to 0 to be open
    const [activeIndex, setActiveIndex] = useState(null);

    const handleTabChange = (e) => {
        setActiveIndex(e.index);
        // Check if the Accordion is closing (all tabs are closed)
        if (e.index === null) {
            // Set waterPointsData to null when the Accordion closes
            // setWaterPointsData(null);
        }
    };

    return (
        <div>
            <Accordion activeIndex={activeIndex} onTabChange={handleTabChange}>
                <AccordionTab header="Filters">
                    <FiltersForm
                        setFilterQueryIsLoading={setFilterQueryIsLoading}
                        filterQueryIsLoading={filterQueryIsLoading}
                        setFilterParams={setFilterParams}
                        filterParams={filterParams}
                        filterSelectedObjects={filterSelectedObjects}
                        setFilterSelectedObjects={setFilterSelectedObjects}
                        loggedInUserData={loggedInUserData}
                        waterPointsData={waterPointsData}
                        setWaterPointsData={setWaterPointsData}
                    />
                </AccordionTab>
            </Accordion>
        </div>
    );
}

export default LocationFiltersPage;
