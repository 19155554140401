import React, { useRef } from "react";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Image } from "primereact/image";
import { Dialog } from "primereact/dialog";
import moment from "moment"; // Import moment
import { Tag } from "primereact/tag";

import SubprojectAggregationPrint from "./SubprojectAggregationPrint";
import { useReactToPrint } from "react-to-print";
import { Button } from "primereact/button";
import PrinterOutlined from "@mui/icons-material/PrintOutlined";
import { ProgressBar } from "primereact/progressbar";

function SubprojectAggregationModal({ aggregatedReportDetail, subprojectDetail, selectedItem, ...props }) {
    console.log("selected item in SubProjectPerfomanceReportPdfModal : ", selectedItem);
    console.log("selected item in subprojectDetail : ", subprojectDetail);
    const componentRef = useRef();
    const formatCurrency = (value) => {
        // Format your currency here
        return new Intl.NumberFormat("en-US", { style: "currency", currency: "UGX" }).format(value);
    };

    const imageBodyTemplate = (rowData) => {
        return <Image src={`${process.env.REACT_APP_API_BASE_URL}${rowData.photo_url}`} alt={rowData.caption} width="100" preview />;
    };

    // Function to format date using moment
    const formatDate = (dateString) => {
        return moment(dateString).format("MMMM Do YYYY, h:mm:ss a"); // Example format
    };

    // Function to display status with color
    const statusWithColor = (status) => {
        let color;
        switch (status) {
            case "pending approval":
                color = "blue";
                break;
            case "approved":
                color = "green";
                break;
            case "pending":
                color = "orange";
                break;
            case "rejected":
                color = "red";
                break;
            default:
                color = "grey";
        }
        return <span style={{ color }}>{status?.charAt(0)?.toUpperCase() + status?.slice(1)}</span>;
    };

    // Function to display status with color using Tag
    const tagStatus = (status) => {
        let severity;
        switch (status) {
            case "pending approval":
                severity = "info"; // blue
                break;
            case "approved":
                severity = "success"; // green
                break;
            case "pending":
                severity = "warning"; // orange
                break;
            case "rejected":
                severity = "danger"; // red
                break;
            default:
                severity = "secondary"; // grey or default
        }
        return <Tag severity={severity} value={status?.charAt(0)?.toUpperCase() + status?.slice(1)} />;
    };

    const gridStyle = {
        display: "grid",
        gridTemplateColumns: "1fr 1fr", // This creates two columns of equal width
        gridGap: "1rem", // Space between the columns
    };

    const riskTypeBodyTemplate = (rowData) => {
        let color;
        switch (rowData.risk_type) {
            case "high":
                color = "red";
                break;
            case "moderate":
                color = "orange";
                break;
            case "low":
                color = "grey";
                break;
            default:
                color = "black";
        }

        return (
            <div>
                <span className="p-mr-2" style={{ fontSize: "1.5rem", color, fontWeight: "bold" }}>
                    •
                </span>
                {rowData.risk_type.charAt(0).toUpperCase() + rowData.risk_type.slice(1)}
            </div>
        );
    };

    // const handlePrint = useReactToPrint({
    //     content: () => componentRef.current,
    //     documentTitle: `${(
    //         <div style={{ display: "flex", gap: "1rem", textAlign: "center", alignItems: "center", justifyContent: "center", margin: "20px 0" }}>
    //             <img src="/Coat_of_arms_of_Uganda.svg" alt="Coat of Arms of Uganda" style={{ width: "20px", height: "auto" }} /> <p>Ministry of Water and Environment</p>
    //         </div>
    //     )}`,
    //     pageStyle: `
    //     @page { size: A4; margin: 1cm; }
    //     @media print {
    //       body { -webkit-print-color-adjust: exact; }
    //       div#print-content { height: 100%; }
    //     }
    //   `,
    // });

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Report", // Set the document title as a string
        pageStyle: `
        @page { size: A4; margin: 1cm; }
        @media print {
          body { -webkit-print-color-adjust: exact; }
          div#print-content { height: 100%; }
          .header, .footer { background-color: transparent !important; } // Assuming .header and .footer are the classes for your header and footer
          img { display: block; margin: 0 auto; } // Center the image, if necessary
        }
      `,
    });
    // const handlePrint = useReactToPrint({
    //     content: () => componentRef.current,
    //     documentTitle: "Ministry of Water and Environment Performance Report", // This sets the PDF file name
    //     pageStyle: `
    //     @page {
    //         size: A4;
    //         margin: 1; // Set all page margins to 0
    //     }
    //     @media print {
    //         body { -webkit-print-color-adjust: exact; }
    //         div#print-content { height: 100%; }
    //         .header, .footer { display: none !important; } // Make sure .header and .footer elements do not display
    //         img { display: block; margin: 0 auto; } // Center the image, if necessary
    //         h4.class-name { /* Custom styles for h4 elements with class-name, if any */ }
    //     }
    // `,
    // });

    // const handlePrint = useReactToPrint({
    //     content: () => componentRef.current,
    //     documentTitle: "Ministry of Water and Environment Performance Report", // This sets the PDF file name
    //     pageStyle: `
    //     @page {
    //         size: A4;
    //         margin: 1cm; // Adjusted margin; you can also set individual margins (top, right, bottom, left) if needed
    //     }
    //     @media print {
    //         body, html {
    //             -webkit-print-color-adjust: exact;
    //             background: white;
    //             color: black;
    //         }
    //         div#print-content {
    //             height: 100%;
    //             background: white;
    //         }
    //         .header, .footer {
    //             display: none !important; // Make sure .header and .footer elements do not display
    //         }
    //         img {
    //             display: block;
    //             margin: 0 auto; // Center the image, if necessary
    //         }
    //     }
    // `,
    // });

    // const handlePrint = useReactToPrint({
    //     content: () => componentRef.current,
    //     documentTitle: "", // Set the default file name here
    //     pageStyle: `
    // @page {  size: A4; margin: 1cm; }
    // @media print {
    //   body { -webkit-print-color-adjust: exact; background: white; }
    //   div#print-content { height: 100%; background: white; }
    // }
    // `,
    //     onBeforeGetContent: () => {
    //         document.title = "Subproject Performance Report"; // Set the title here
    //     },
    //     onAfterPrint: () => {
    //         document.title = originalTitle; // Reset the title after printing
    //     },
    // });

    // Remember to store the original title to reset it later
    const originalTitle = document.title;

    return (
        <Dialog
            header={
                <div>
                    <div style={{ color: "green" }}>Performance Report Record.</div>

                    {/* <div>
                        <strong>Subproject Name : </strong>
                        {selectedItem?.subproject?.name}
                    </div> */}
                </div>
            }
            visible={props.show}
            style={{ width: "80vw" }}
            onHide={props.onHide}
            maximizable
        >
            {/* Status Information */}
            <Card title="Report Details" style={{ marginBottom: "1rem" }}>
                {/* Display Program and Directorate Details */}
                {aggregatedReportDetail?.subprojects?.map((subproject, index) => (
                    <div key={index}>
                        {subproject?.project?.department_administration?.department?.program_directorate_department?.map((pdd, pddIndex) => {
                            const programName = pdd?.program_directorate?.program?.name || "N/A";
                            const directorateName = pdd?.program_directorate?.directorate?.name || "N/A";
                            return (
                                <div key={`pdd-${pddIndex}`}>
                                    <p>
                                        <strong>Program:</strong> {programName}
                                    </p>
                                    <p>
                                        <strong>Directorate:</strong> {directorateName}
                                    </p>
                                </div>
                            );
                        })}
                        <p>
                            <strong>Department Name:</strong> {subproject?.project?.department_administration?.department?.name || "N/A"}
                        </p>
                        <p>
                            <strong>Department Administration Name:</strong> {subproject?.project?.department_administration?.name || "N/A"}
                        </p>
                        <p>
                            <strong>Project Title:</strong> {subproject?.project?.project_title || "N/A"}
                        </p>
                        <p>
                            <strong>Project Code:</strong> {subproject?.project?.project_code || "N/A"}
                        </p>
                        <p>
                            <strong>Subproject Name:</strong> {subproject?.name || "N/A"}
                        </p>
                        <p>
                            <strong>Subproject Code:</strong> {subproject?.code || "N/A"}
                        </p>
                        <p>
                            <strong>Subproject Status:</strong> {subproject?.status || "N/A"}
                        </p>
                        <p>
                            <strong>Start Date:</strong> {subproject?.start_date ? moment(subproject.start_date).format("MMMM Do, YYYY") : "N/A"}
                        </p>
                        <p>
                            <strong>End Date:</strong> {subproject?.actual_end_date ? moment(subproject.actual_end_date).format("MMMM Do, YYYY") : "N/A"}
                        </p>
                    </div>
                ))}
            </Card>

            <Card title="Financial Details" style={{ marginBottom: "1rem" }}>
                <p>
                    <strong>Total Released:</strong> {formatCurrency(aggregatedReportDetail?.total_approved_budget) || "N/A"}
                </p>
                <p>
                    <strong>Total Spent Funds:</strong> {formatCurrency(aggregatedReportDetail?.total_spent_funds) || "N/A"}
                </p>
                <p>
                    <strong>Absorption Rate:</strong> {aggregatedReportDetail?.absorption_rate ? `${aggregatedReportDetail.absorption_rate}%` : "N/A"}
                </p>
            </Card>

            <Card title="Period Details" style={{ marginBottom: "1rem" }}>
                {/* <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", width: "100%" }}>
                    <div>
                        {aggregatedReportDetail?.financial_years?.map((year, index) => (
                            <p key={index}>
                                <strong>{year.name}</strong>
                            </p>
                        ))}
                    </div>
                    <div>
                        {aggregatedReportDetail?.quarters?.map((quarter, index) => (
                            <span key={index}>
                                <strong>{quarter.name} , </strong>
                            </span>
                        ))}
                    </div>
                </div> */}

                <div>
                    {/* Displaying Financial Year and Quarters Details from the new structure */}
                    {aggregatedReportDetail?.periods.map((period, index) => (
                        <div key={index} className="md:w-1/2 px-2 m-1">
                            <p>
                                <strong>Financial Year:</strong> {period.financial_year.name || "N/A"}
                            </p>
                            {period.quarters.map((quarter, qIndex) => (
                                <ul key={qIndex}>
                                    <li>{quarter.name}</li>
                                </ul>
                            ))}
                        </div>
                    ))}
                </div>
            </Card>

            {aggregatedReportDetail?.budget_outputs?.map((output, index) => (
                <Card key={index} title={`Budget Output: ${output.project_department_budget_output.department_budget_output.name}`} style={{ marginBottom: "1rem" }}>
                    <DataTable value={output.indicators}>
                        <Column field="details.name" header="Indicator" headerStyle={{ backgroundColor: "orange", color: "white" }}></Column>
                        <Column field="details.unit" header="Units" headerStyle={{ backgroundColor: "orange", color: "white" }}></Column>
                        <Column field="total_value" header="Total Value" headerStyle={{ backgroundColor: "orange", color: "white" }}></Column>

                        <Column
                            header="Village Details"
                            body={(rowData) =>
                                rowData.village_details.map((village, vIndex) => (
                                    <div key={vIndex} style={{ marginBottom: "10px" }}>
                                        <strong>Village Name:</strong> {village.village_info.name}
                                        <br />
                                        <strong>Total Value:</strong> {village.total_value}
                                        <br />
                                        {village.coordinates.map((coord, cIndex) => (
                                            <div key={cIndex} style={{ cursor: "pointer", textDecoration: "underline", marginTop: "5px" }} onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${coord.latitude},${coord.longitude}`, "_blank")}>
                                                <div style={{ textDecoration: "none" }}>Identifier: {coord.identifier}</div>
                                                Lat: {coord.latitude}, Long: {coord.longitude}
                                            </div>
                                        ))}
                                    </div>
                                ))
                            }
                            headerStyle={{ backgroundColor: "orange", color: "white" }}
                        ></Column>

                        <Column
                            field="assessments"
                            header="Assessments"
                            body={(rowData) =>
                                rowData.assessments.map((assess, aIndex) => (
                                    <div key={aIndex}>
                                        <p>
                                            <strong>Assessment:</strong> {assess.assessment}
                                        </p>
                                        <p>
                                            <strong>Reason of Variance:</strong> {assess.reasonOfVariance}
                                        </p>
                                    </div>
                                ))
                            }
                            headerStyle={{ backgroundColor: "orange", color: "white" }}
                        ></Column>
                    </DataTable>
                </Card>
            ))}
            {aggregatedReportDetail?.risks_mitigations && (
                <Card title="Risks and Mitigations" style={{ marginBottom: "1rem" }}>
                    <DataTable value={aggregatedReportDetail?.risks_mitigations}>
                        <Column field="risk" header="Risk"></Column>
                        <Column field="risk_type" header="Risk Type" body={riskTypeBodyTemplate}></Column>
                        <Column field="mitigation" header="Mitigation"></Column>
                        {/* <Column field="created_at" header="Created At" body={formatDate}></Column> */}
                    </DataTable>
                </Card>
            )}

            {aggregatedReportDetail?.recommendations && (
                <Card title="Challenges and Recommendations" style={{ marginBottom: "1rem" }}>
                    <DataTable value={aggregatedReportDetail?.recommendations}>
                        <Column field="challenges" header="Challenges"></Column>
                        <Column field="recommendations" header="Recommendations"></Column>
                        {/* <Column field="created_at" header="Created At" body={formatDate}></Column> */}
                    </DataTable>
                </Card>
            )}

            {/* Display Photos */}
            <Card title="Photos">
                <DataTable value={aggregatedReportDetail?.photos}>
                    <Column header="Image" body={imageBodyTemplate}></Column>
                    <Column field="caption" header="Caption"></Column>
                </DataTable>
            </Card>

            {/* Created By and Updated By Information */}
            {/* <p>
                <strong>Created By:</strong> {selectedItem?.created_by_user?.name} at {formatDate(selectedItem?.created_at)}
            </p> */}
            {/* <p>
                <strong>Updated By:</strong> {selectedItem?.updated_by_user?.name} at {formatDate(selectedItem?.updated_at)}
            </p> */}

            {/* Print Button */}
            {/* {selectedItem?.current_status?.name == "approved" && ( */}

            <Button label="Print " icon={<PrinterOutlined />} onClick={handlePrint} className="p-button-primary" style={{ marginTop: "1rem" }} />
            {/* Invisible component for printing */}
            <div style={{ display: "none" }}>
                <div ref={componentRef}>
                    <SubprojectAggregationPrint aggregatedReportDetail={aggregatedReportDetail} />
                </div>
            </div>

            {/* )} */}
        </Dialog>
    );
}

export default SubprojectAggregationModal;
