import axiosAPI from "../axiosApi";

export async function getAllProjects(params = {}) {
    const response = await axiosAPI.get("projects", { params: params });
    return response;
}

export async function getAOutComesIndicatorsAndBudgetOutputIndicatorsFromProject(params = {}) {
    const response = await axiosAPI.get("getAOutComesIndicatorsAndBudgetOutputIndicators", { params: params });
    return response;
}

export async function getAllProjectsById(id) {
    const response = await axiosAPI.get(`projects/` + id);
    return response;
}

export async function getProjectsByProjectIdOrRole() {
    const response = await axiosAPI.get("getProjectsByProjectIdOrRole");
    return response;
}

export async function getProjectsByDepartmentIdAndRole(id) {
    const response = await axiosAPI.get(`getProjectsByDepartmentIdAndRole/` + id);
    return response;
}

export async function getProjectFinancialReport(id, financial_year = null, quarter = null) {
    const response = await axiosAPI.get(`project-financial-report/` + id, {
        params: {
            financial_year,
            quarter,
        },
    });
    return response;
}

export async function getProjectIndicatorData(outComeId, projectId, financial_year = null, quarter = null) {
    const response = await axiosAPI.get(`project-indicator-data/${outComeId}/${projectId}`, {
        params: {
            financial_year,
            quarter,
        },
    });
    return response;
}

export async function getAllProjectIndicatorData(projectId, financial_year = null, quarter = null) {
    const response = await axiosAPI.get(`all-project-indicator-data/${projectId}`, {
        params: {
            financial_year,
            quarter,
        },
    });
    return response;
}

export async function getProjectByIdApi(id) {
    const response = await axiosAPI.get(`projects/` + id);
    return response;
}

export async function createProject(data) {
    const response = await axiosAPI.post(`projects`, data);
    return response;
}

export async function updateProjectsApi(id, data) {
    const response = await axiosAPI.put(`projects/${id}`, data);
    return response;
}

export async function deleteProjectById(id) {
    const response = await axiosAPI.delete(`projects/${id}`);
    return response;
}
//project-subproject-metrics-indicators

//
export async function filterProjectToGetItsDistricts(data) {
    const response = await axiosAPI.post(`project-with-districts`, data);
    return response;
}

export async function getProjectDeptOutcomeIndicatorsData(data) {
    const response = await axiosAPI.post(`project-dept-outcome-indicator-targets`, data);
    return response;
}
