import React, { useState, useRef } from "react";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import { ProgressBar } from "primereact/progressbar";
import * as XLSX from "xlsx";

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { postStoreSubLocations } from "../../../../services/data-migrations/sublocations-service";

export default function LocationFilesPage() {
    const [loading, setLoading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const toast = useRef(null);
    const fileUploadRef = useRef(null);

    const queryClient = useQueryClient();
    const createSubLocationsMutation = useMutation(postStoreSubLocations, {
        onSuccess: (data) => {
            console.log("data", data);
            //  queryClient.invalidateQueries(["projects"]);
            // toast.success("Locations Stored Successfully");
            toast.current.show({ severity: "success", summary: "Success", detail: data?.data?.message || "Files processed", life: 3000 });
            if (fileUploadRef.current) {
                fileUploadRef.current.clear();
            }
            setSelectedFiles([]); // Clear selected files state
        },
        onError: (error) => {
            // toast.error(error?.response?.data?.message || "An Error Occurred. Please Contact Admin");
            toast.current.show({
                severity: "error",
                summary: "Error",
                detail: error?.response?.data?.message || "An Error Occurred. Please Contact Admin",
                life: 3000,
            });
            console.log("error uploading locations is : ", error);
        },
    });

    const onTemplateSelect = (e) => {
        // Check if the selected file is an Excel file
        const allowedTypes = [".xls", ".xlsx"];
        const isExcelFile = e.files.every((file) => allowedTypes.some((type) => file.name.endsWith(type)));

        if (!isExcelFile) {
            // If not, show a warning toast and remove the file
            toast.current.show({ severity: "warn", summary: "Warning", detail: "Only Excel files are allowed.", life: 3000 });
            e.files = []; // Clear out the non-Excel files
            if (fileUploadRef.current) {
                fileUploadRef.current.clear();
            }
        }

        // Set the selected files to state
        setSelectedFiles(e.files);
    };

    // const onUpload = () => {
    //     // Show loading indicator
    //     setLoading(true);

    //     // Process the selected files
    //     selectedFiles.forEach((file) => {
    //         const reader = new FileReader();
    //         reader.onload = (e) => {
    //             const workbook = XLSX.read(e.target.result, { type: "binary" });
    //             const sheetName = workbook.SheetNames[0];
    //             const worksheet = workbook.Sheets[sheetName];
    //             const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false });
    //             console.log(jsonData); // Log the Excel data to the console
    //             createSubLocationsMutation.mutate(jsonData);
    //         };
    //         reader.readAsBinaryString(file);
    //     });

    //     // Hide loading and show success message after 1 minute
    //     setTimeout(() => {
    //         setLoading(false);
    //         toast.current.show({ severity: "success", summary: "Success", detail: "Files processed", life: 3000 });
    //         if (fileUploadRef.current) {
    //             fileUploadRef.current.clear();
    //         }
    //         setSelectedFiles([]); // Clear selected files state
    //     }, 60000); // 60000 ms = 1 minute
    // };

    const onUpload = async () => {
        // Show loading indicator
        // setLoading(true);

        for (const file of selectedFiles) {
            try {
                const reader = new FileReader();
                reader.onload = async (e) => {
                    const workbook = XLSX.read(e.target.result, { type: "binary" });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false });
                    // Use await to wait for the mutation to complete before continuing to the next file
                    await createSubLocationsMutation.mutateAsync(jsonData);
                };
                reader.onerror = (error) => {
                    // Handle FileReader errors here
                    console.error("Error reading file:", error);
                    toast.current.show({ severity: "error", summary: "Error", detail: "Failed to read file", life: 3000 });
                };
                reader.readAsBinaryString(file);
            } catch (error) {
                // Handle errors that may occur during the upload process
                console.error("Error processing file:", error);
                toast.current.show({ severity: "error", summary: "Error", detail: "Failed to process file", life: 3000 });
            }
        }

        // Hide loading indicator
        // setLoading(false);
        // // Show success message
        // toast.current.show({ severity: "success", summary: "Success", detail: "All files processed", life: 3000 });
        // // Clear the file input
        // if (fileUploadRef.current) {
        //     fileUploadRef.current.clear();
        // }
        // // Clear selected files state
        // setSelectedFiles([]);
    };

    return (
        <div className="card">
            <Toast ref={toast} />
            {createSubLocationsMutation?.isLoading && <ProgressBar mode="indeterminate" style={{ height: "6px" }} />}
            <FileUpload ref={fileUploadRef} name="demo[]" accept=".xls,.xlsx" maxFileSize={1000000} customUpload={true} onSelect={onTemplateSelect} uploadHandler={onUpload} emptyTemplate={<p className="m-0">Drag and drop Excel files here to upload.</p>} />
        </div>
    );
}
