import React, { useRef, useState } from "react";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Image } from "primereact/image";
import { Dialog } from "primereact/dialog";
import moment from "moment"; // Import moment
import { Tag } from "primereact/tag";

import UtilityDeptReportPrint from "./UtilityDeptReportPrint";
import { useReactToPrint } from "react-to-print";
import { Button } from "primereact/button";
import PrinterOutlined from "@mui/icons-material/PrintOutlined";
import { ProgressBar } from "primereact/progressbar";

function UtilityDeptReportPdfModal({ selectedItem, selectedDepartmentItem, ...props }) {
    console.log("selected item in SubProjectPerfomanceReportPdfModal : ", selectedItem);

    const [departmentOutcomePage, setDepartmentOutcomePage] = useState(0);
    const rowsPerPage = 5; // Number of rows per page
    // Paginator template
    const paginatorTemplate = "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown";
    const currentPageReportTemplate = "Showing {first} to {last} of {totalRecords} entries";

    const componentRef = useRef();

    // Function to format date using moment
    const formatDate = (dateString) => {
        return moment(dateString).format("MMMM Do YYYY, h:mm:ss a"); // Example format
    };

    // Function to display status with color
    const statusWithColor = (status) => {
        let color;
        switch (status) {
            case "pending approval":
                color = "blue";
                break;
            case "approved":
                color = "green";
                break;
            case "pending":
                color = "orange";
                break;
            case "rejected":
                color = "red";
                break;
            default:
                color = "grey";
        }
        return <span style={{ color }}>{status?.charAt(0)?.toUpperCase() + status?.slice(1)}</span>;
    };

    // Function to display status with color using Tag
    const tagStatus = (status) => {
        let severity;
        switch (status) {
            case "pending approval":
                severity = "info"; // blue
                break;
            case "approved":
                severity = "success"; // green
                break;
            case "pending":
                severity = "warning"; // orange
                break;
            case "rejected":
                severity = "danger"; // red
                break;
            default:
                severity = "secondary"; // grey or default
        }
        return <Tag severity={severity} value={status?.charAt(0)?.toUpperCase() + status?.slice(1)} />;
    };

    const riskTypeBodyTemplate = (rowData) => {
        let color;
        switch (rowData.risk_type) {
            case "high":
                color = "red";
                break;
            case "moderate":
                color = "orange";
                break;
            case "low":
                color = "grey";
                break;
            default:
                color = "black";
        }

        return (
            <div>
                <span className="p-mr-2" style={{ fontSize: "1.5rem", color, fontWeight: "bold" }}>
                    •
                </span>
                {rowData.risk_type.charAt(0).toUpperCase() + rowData.risk_type.slice(1)}
            </div>
        );
    };

    const gridStyle = {
        display: "grid",
        gridTemplateColumns: "1fr 1fr", // This creates two columns of equal width
        gridGap: "1rem", // Space between the columns
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Report", // Set the document title as a string
        pageStyle: `
        @page { size: A4; margin: 1cm; }
        @media print {
          body { -webkit-print-color-adjust: exact; }
          div#print-content { height: 100%; }
          .header, .footer { background-color: transparent !important; } // Assuming .header and .footer are the classes for your header and footer
          img { display: block; margin: 0 auto; } // Center the image, if necessary
        }
      `,
    });

    // Remember to store the original title to reset it later
    const originalTitle = document.title;

    return (
        <Dialog
            header={
                <div>
                    <div style={{ color: "green" }}>Water Utility Regulation Department Report.</div>

                    {/* <div>
                        <strong>Subproject Name : </strong>
                        {selectedItem?.subproject?.name}
                    </div> */}
                </div>
            }
            visible={props.show}
            style={{ width: "80vw" }}
            onHide={props.onHide}
            maximizable
        >
            {/* Status Information */}
            <Card title="Report Details" style={{ marginBottom: "1rem" }}>
                <div className="flex flex-wrap" style={{ justifyContent: "space-between" }}>
                    {/* Column 1 */}
                    <div className="md:w-1/2 px-2 m-1">
                        {/* Display Program and Directorate Details */}

                        <p>
                            <strong>Department Name:</strong> {selectedItem?.department?.name || "N/A"}
                        </p>

                        <p>
                            <strong>Report Name :</strong> {selectedItem?.name || "N/A"}
                        </p>
                        <p>
                            <strong>Approval Status:</strong> {tagStatus(selectedItem?.current_status?.name)}
                        </p>

                        <p>
                            <strong>Approval Comment:</strong> {statusWithColor(selectedItem?.current_status?.reason)}
                        </p>
                    </div>

                    {/* Column 2 */}
                    <div className="md:w-1/2 px-2 m-1">
                        {/* <p>
                            <strong>Status:</strong> {statusWithColor(selectedItem?.status)}
                        </p> */}

                        <p>
                            <strong>Finacial Year:</strong> {selectedItem?.financial_year?.name}
                        </p>
                        <p>
                            <strong>Quarter:</strong> {selectedItem?.quarter?.name}
                        </p>
                    </div>

                    {/* Full Width Row for Details */}
                    <div className="w-full px-2 m-2">
                        <strong>Description:</strong>
                        <p>{selectedItem?.description}</p>
                    </div>
                </div>
            </Card>

            <div>
                {/* Department Outcomes */}
                {selectedItem?.utility_theme_report_department_outcomes.map((outcome, index) => (
                    <Card key={index} title={`Department Outcome: ${outcome.department_outcome.outcome.name}`} style={{ marginBottom: "1rem" }}>
                        <DataTable value={outcome.utility_theme_reportindicator_target_actuals} paginator paginatorTemplate={paginatorTemplate} currentPageReportTemplate={currentPageReportTemplate} rows={rowsPerPage} first={departmentOutcomePage} onPage={(e) => setDepartmentOutcomePage(e.first)}>
                            <Column field="dept_outcomes_indicator_target.department_outcome_indicator.outcome_indicator.name" header="Outcome Indicator Name"></Column>
                            {/* <Column field="dept_outcomes_indicator_target.department_outcome_indicator.outcome_indicator.code" header="Outcome Indicator Code"></Column> */}

                            <Column field="dept_outcomes_indicator_target.base_year" header="Base Year"></Column>
                            <Column
                                field="dept_outcomes_indicator_target.base_value"
                                header="Base Value"
                                body={(rowData, column) => {
                                    console.log("Dept Table Row data : ", rowData);
                                    // Extract the unit from rowData
                                    let unit = rowData.dept_outcomes_indicator_target.department_outcome_indicator.outcome_indicator.unit;
                                    return `${parseInt(rowData.dept_outcomes_indicator_target.base_value) || 0} ${unit}`;
                                }}
                            ></Column>
                            <Column field="dept_outcomes_indicator_target.target_year" header="Target Year"></Column>
                            <Column
                                field="dept_outcomes_indicator_target.target_value"
                                header="Target Value"
                                body={(rowData, column) => {
                                    // Extract the unit from rowData
                                    let unit = rowData.dept_outcomes_indicator_target.department_outcome_indicator.outcome_indicator.unit;
                                    return `${parseInt(rowData.dept_outcomes_indicator_target.target_value) || 0} ${unit}`;
                                }}
                            ></Column>
                            <Column field="dept_outcomes_indicator_target.financial_year.name" header="Financial Year"></Column>
                            <Column
                                field="dept_outcomes_indicator_target.financial_year_target"
                                header="Financial Year Target"
                                body={(rowData, column) => {
                                    // Extract the unit from rowData
                                    let unit = rowData.dept_outcomes_indicator_target.department_outcome_indicator.outcome_indicator.unit;
                                    return `${parseInt(rowData.dept_outcomes_indicator_target.financial_year_target) || 0} ${unit}`;
                                }}
                            ></Column>
                            {/* <Column
                            field="value"
                            header="Value"
                            body={(rowData, column) => {
                                // Extract the unit from rowData
                                let unit = rowData.dept_outcomes_indicator_target.department_outcome_indicator.outcome_indicator.unit;
                                return `${parseInt(rowData[column.field]) || 0}${unit}`;
                            }}
                        ></Column> */}

                            <Column
                                field="value"
                                header="Value"
                                body={(rowData, column) => {
                                    // Initialize a variable to store the final value
                                    let finalValue;
                                    let unit = rowData.dept_outcomes_indicator_target.department_outcome_indicator.outcome_indicator.unit; // Extract the unit for use in this column as well

                                    // Check if manual value should be used based on 'show_manual_value' field from 'department_outcome_indicator_target'
                                    if (rowData?.dept_outcomes_indicator_target?.show_manual_value) {
                                        // Use the manual value and store in finalValue
                                        finalValue = `${parseInt(rowData?.uncomputed_value) || 0} ${unit}`;
                                    } else {
                                        // Otherwise, use the target value and store in finalValue
                                        finalValue = `${parseInt(rowData[column.field]) || 0} ${unit}`;
                                    }

                                    // Return the finalValue
                                    return finalValue;
                                }}
                            ></Column>

                            {/* <Column field="numerator_value" header="Numerator"></Column>
            <Column field="denominator_value" header="Denominator"></Column> */}
                            {/* <Column field="census_name" header="Census"></Column> */}
                            {/* <Column field="assessment" header="Assessment"></Column>
            <Column field="reasonOfVariance" header="Reason of Variance"></Column> */}
                            {/* Other columns as necessary */}
                        </DataTable>
                    </Card>
                ))}
            </div>

            {/* Themes and Indicators */}
            <div>
                {selectedItem?.utility_theme_reports_themes?.map((theme, themeIndex) => (
                    <Card key={theme.id} title={theme.utility_theme?.name || "N/A"}>
                        <DataTable value={theme.utility_theme_report_indicators}>
                            <Column field="utility_theme_indicator_target.utility_theme_indicator.output_indicator.name" header="Indicator Name"></Column>
                            <Column field="utility_theme_indicator_target.target" header="Target"></Column>
                            <Column field="value" header="Value" />
                            <Column field="reasonOfVariance" header="Reason of Variance" />
                            <Column field="assessment" header="Assessment" />
                        </DataTable>
                    </Card>
                ))}
            </div>
            {selectedItem?.risks && (
                <Card title="Risks and Mitigations" style={{ marginBottom: "1rem" }}>
                    <DataTable value={selectedItem.risks}>
                        <Column field="risk" header="Risk"></Column>
                        <Column field="risk_type" header="Risk Type" body={riskTypeBodyTemplate}></Column>
                        <Column field="mitigation" header="Mitigation"></Column>
                        {/* <Column field="created_at" header="Created At" body={formatDate}></Column> */}
                    </DataTable>
                </Card>
            )}

            {/* Created By and Updated By Information */}
            <p>
                <strong>Updated By:</strong> {selectedItem?.updated_by?.name} at {formatDate(selectedItem?.updated_at)}
            </p>

            {/* <p>
                <strong>Updated By:</strong> {selectedItem?.updated_by_user?.name} at {formatDate(selectedItem?.updated_at)}
            </p> */}

            {/* Print Button */}
            {/* {selectedItem?.current_status?.name == "approved" && ( */}
            <>
                {" "}
                <Button label="Print " icon={<PrinterOutlined />} onClick={handlePrint} className="p-button-primary" />
                {/* Invisible component for printing */}
                <div style={{ display: "none" }}>
                    <div ref={componentRef}>
                        <UtilityDeptReportPrint selectedItem={selectedItem} selectedDepartmentItem={selectedDepartmentItem} />
                    </div>
                </div>
            </>
            {/* )} */}
        </Dialog>
    );
}

export default UtilityDeptReportPdfModal;
