import axiosAPI from "../axiosApi";

export async function getAllProjectDepartmentBudgetOutputs(params = {}) {
    const response = await axiosAPI.get("projectDepartmentBudgetOutput", { params: params });
    return response;
}

export async function getProjectDepartmentBudgetOutputById(id) {
    const response = await axiosAPI.get(`projectDepartmentBudgetOutput/${id}`);
    return response;
}

export async function postProjectDepartmentBudgetOutput(data) {
    const response = await axiosAPI.post(`projectDepartmentBudgetOutput`, data);
    return response;
}

export async function updateProjectDepartmentBudgetOutput(id, data) {
    const response = await axiosAPI.put(`projectDepartmentBudgetOutput/${id}`, data);
    return response;
}

export async function deleteProjectDepartmentBudgetOutputById(id) {
    const response = await axiosAPI.delete(`projectDepartmentBudgetOutput/${id}`);
    return response;
}
