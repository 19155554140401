import axiosAPI from "../axiosApi";

export async function getAllDepartmentAdministrations(params = {}) {
    const response = await axiosAPI.get("department-administrations", { params: params });
    return response;
}

export async function getDepartmentAdministrationById(id) {
    const response = await axiosAPI.get(`department-administrations/${id}`);
    return response;
}

export async function postDepartmentAdministration(data) {
    const response = await axiosAPI.post(`department-administrations`, data);
    return response;
}

export async function updateDepartmentAdministration(id, data) {
    const response = await axiosAPI.put(`department-administrations/${id}`, data);
    return response;
}

export async function deleteDepartmentAdministrationById(id) {
    const response = await axiosAPI.delete(`department-administrations/${id}`);
    return response;
}

//
export async function filterToGetDepartmentAdministrationDistrictsForMap(data) {
    const response = await axiosAPI.post(`dept-admins-for-map`, data);
    return response;
}
