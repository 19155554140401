import React, { useEffect, useState, useRef } from "react";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Chart } from "primereact/chart";

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import moment from "moment";

import { getAllFinancialYears, getFinancialYearsById, postFinancialYears, updateFinancialYears, deleteFinancialYears } from "../../../../../services/financial-years/financial-years-service";
import { getAllQuarters, getQuartersById, postQuarters, updateQuarters, deleteQuarters } from "../../../../../services/financial-years/quarters";
import { getAllOutcomeIndicatorTypes, getOutcomeIndicatorTypeById, postOutcomeIndicatorType, updateOutcomeIndicatorType, deleteOutcomeIndicatorTypeById } from "../../../../../services/directorates/outcome-indicator-types-service.js";
import { getDeptOutcomeIndicatorTargetsGroupedByIndicatorTypes } from "../../../../../services/departments/departmentOutcomeIndicatorTarget-service";
import { getAllDepartmentBudjetOutputsForDashboard } from "../../../../../services/departments/departments-budget-outputs-service.js";

import { toast } from "react-toastify";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useNavigate } from "react-router-dom";

import { Panel } from "primereact/panel";

import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";

import { Accordion, AccordionTab } from "primereact/accordion";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ProgressBar } from "primereact/progressbar";
import { Toast as PrimeReactToast } from "primereact/toast";
import BudgetOutputsAndOutputIndicatorsGraph from "./widgets/BudgetOutputsAndOutputIndicatorsGraph";

const BudgetOutputsAndOutputIndicatorsPage = ({ department, financialYear, setFinancialYear, quarter, setQuarter }) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [outcomeIndicatorType, setOutcomeIndicatorType] = useState(null);

    const primeReactToast = useRef(null);
    // Sample data

    const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;
    //department_id;

    //==================== department budget outputs for dashboad =======
    const getAllDepartmentBudjetOutputsForDashboardQuery = useQuery(
        ["department-budgetoutpust-for-dashboard", "departments", financialYear, quarter, department?.id],
        () => getAllDepartmentBudjetOutputsForDashboard({ financial_year_id: financialYear, quarter_id: quarter, department_id: department?.id }),
        {
            enabled: financialYear && quarter ? true : false,
            onSuccess: (data) => {
                console.log("fetching department-budgetoutpust-for-dashboard data is : ", data);
            },
            onError: (error) => {
                console.log("Error fetching department-budgetoutpust-for-dashboard is : ", error);
            },
            // staleTime: 3 * 60 * 60 * 1000, // 3 hours in milliseconds
        }
    );

    //

    const [errors, setErrors] = useState({});
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    const validate = () => {
        let newErrors = {};
        if (!financialYear) newErrors.financialYear = "Financial year is required";

        if (!quarter) newErrors.quarter = "Quarter year is required";

        if (!outcomeIndicatorType) newErrors.outcomeIndicatorType = "OutcomeIndicatorType is required";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleFilter = () => {
        if (validate()) {
            setShowConfirmDialog(true);
        }
    };

    const onConfirmFilter = () => {
        console.log("Selected Financial Year:", financialYear);

        setShowConfirmDialog(false);
        primeReactToast.current.show({
            severity: "info",
            summary: "Report Filtration",
            detail: "Hang on there reports are being filtered for you!",
            life: 3000,
        });
        // Additional logic to fetch data based on filters
    };

    // State to manage the active index of the accordion
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <div>
            <PrimeReactToast ref={primeReactToast} />
            {getAllDepartmentBudjetOutputsForDashboardQuery.isLoading && getAllDepartmentBudjetOutputsForDashboardQuery.isFetching ? (
                <div className="p-field m-4">
                    <ProgressBar mode="indeterminate" style={{ height: "6px" }} />
                </div>
            ) : (
                <div>
                    <BudgetOutputsAndOutputIndicatorsGraph data={getAllDepartmentBudjetOutputsForDashboardQuery?.data?.data?.data} />
                </div>
            )}
        </div>
    );
};

export default BudgetOutputsAndOutputIndicatorsPage;
