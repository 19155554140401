import React, { useState, useRef, useEffect } from "react";
import { Form, Field } from "react-final-form";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { useQuery } from "@tanstack/react-query";
import { classNames } from "primereact/utils";
import { toast } from "react-toastify";
import setFieldTouched from "final-form-set-field-touched";

import { getAllPrograms } from "../../../../services/programs/program-service";
import { getAllDirectorates } from "../../../../services/departments/directorate-service";
import { deleteDistrictById, getAllDistricts } from "../../../../services/districts/districts-service";

import { AutoComplete } from "primereact/autocomplete";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DeleteIcon from "@mui/icons-material/Delete";
import { ProgressSpinner } from "primereact/progressspinner";

function DepartmentsRowForm({ selectedDirectorateItem, handleSubmit, departmentsData = { name: "", code: "", details: "", description: "", directorate_id: "" }, ...props }) {
    const ref = useRef();
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);

    //================= list of programs ========================
    const [filteredPrograms, setFilteredPrograms] = useState([]);

    const getListOfPrograms = useQuery(["programs"], getAllPrograms, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching programs is : ", error);
        },
    });

    console.log("getListOfPrograms lll ", getListOfPrograms);
    console.log("selectedDirectorateItem dsfdsfvd : ", selectedDirectorateItem);
    console.log("selectedDirectorateItem  departmentsData : ", departmentsData);

    const [dropdownPrograms, setDropdownPrograms] = useState([]);
    // const [selectedPrograms, setSelectedPrograms] = useState(DirectorateData.programs ? DirectorateData.programs : programDetail ? [programDetail] : []);
    // const [selectedPrograms, setSelectedPrograms] = useState(departmentsData?.program_directorate_department);
    // Assuming departmentsData.program_directorate_department is the array you provided
    const [selectedPrograms, setSelectedPrograms] = useState([]);

    // Function to transform department data to program details
    const transformToProgramDetails = (data) => {
        return data.map((pdd) => ({
            id: pdd.program_directorate.program.id,
            name: pdd.program_directorate.program.name,
            code: pdd.program_directorate.program.code,
            description: pdd.program_directorate.program.description,
            details: pdd.program_directorate.program.details,
            directorateId: pdd.program_directorate.directorateId,
            directorateName: pdd.program_directorate.directorate.name,
            createdAt: pdd.program_directorate.created_at,
            createdBy: pdd.program_directorate.created_by,
            updatedAt: pdd.program_directorate.updated_at,
            updatedBy: pdd.program_directorate.updated_by,
        }));
    };

    // useEffect to update selectedPrograms when departmentsData changes
    useEffect(() => {
        if (departmentsData && departmentsData.program_directorate_department) {
            const newProgramDetails = transformToProgramDetails(departmentsData.program_directorate_department);

            // Assuming that the first item in the array always contains the directorateId
            const firstDirectorateId = departmentsData.program_directorate_department[0]?.program_directorate?.directorateId;

            departmentsData = {
                directorate_id: firstDirectorateId, // Set the first directorateId
                programsIds: newProgramDetails?.map((program) => program.id),
                ...departmentsData,
            };

            setSelectedPrograms(newProgramDetails);
        }
    }, [departmentsData]);

    //
    const fetchSuggestions = (query) => {
        const filteredPrograms = getListOfPrograms.data?.data?.data.filter((program) => !selectedPrograms.map((sp) => sp.id).includes(program.id) && program.name.toLowerCase().startsWith(query.toLowerCase()));
        setDropdownPrograms(filteredPrograms);
    };

    //=================== directorates ===================
    const directorates = useQuery(["directorates"], getAllDirectorates);

    let dropdownDirectorates = directorates?.data?.data?.data?.map((item) => ({ label: item.name, value: item.id }));

    const validate = (values) => {
        const errors = {};
        if (!values.name) errors.name = "Name is required";
        if (!values.code) errors.code = "Code is required";
        if (!values.directorate_id) errors.directorate_id = "Directorate is required";
        if (!values.details) errors.details = "Detail is required";
        if (!values.programsIds || values.programsIds.length === 0) errors.programsIds = "At least one program is required";

        // if (!values.districtIds || values.districtIds.length === 0) errors.districtIds = "At least one district is required";
        return errors;
    };

    // const onSubmitForm = (data) => {
    //     const errors = validate(data);
    //     // ref.current.focus();
    //     if (Object.keys(errors).length === 0) {
    //         // No validation errors
    //         setPendingData(data);
    //         setShowConfirmDialog(true);
    //     } else {
    //         toast.warning("First Fill In All Required Fields");
    //     }
    // };

    const onSubmitForm = (data, form) => {
        // Add 'form' as an argument
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            // Mark all fields as touched to show validation errors
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("First fill in all required fields.");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    //===== add initial parent directorate

    // if (props?.directorateId) {
    //     departmentWithDistrictIds = {
    //         ...departmentsData,
    //         directorate_id: props?.directorateId,
    //         districtIds: departmentsData.districts?.map((district) => district.id),
    //     };
    // }

    //

    return (
        <div className="p-fluid">
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={departmentsData}
                    initialValuesEqual={() => true}
                    // initialValuesEqual with a function returning true prevents the form from
                    // reinitializing when the initialValues prop changes. This is useful when you
                    // want to avoid re-rendering or reinitializing the form due to changes in initial values.
                    // Be cautious using this if your initial values are meant to be dynamic and responsive
                    // to changes in your application's state.
                    validate={validate}
                    mutators={{ setFieldTouched }}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                console.log("values hhh : ", values);
                                console.log("event fffff : ", event);
                                onSubmitForm(values, form);
                                // handleSubmit(event, values);
                            }}
                        >
                            <Field name="name">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="name">Name</label>
                                        <InputText {...input} id="name" type="text" ref={ref} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && (meta.error || form.submitSuccess || form.submitError) && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>
                            <Field name="code">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="code">Code</label>
                                        <InputText {...input} id="code" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="directorate_id">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="directorate_id">Directorate</label>
                                        <Dropdown {...input} options={dropdownDirectorates} id="directorate_id" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="programsIds">
                                {({ input, meta }) => {
                                    console.log("kkkkkkk first main ffffff input.value : ", input.value);
                                    return (
                                        <div className="p-field m-4">
                                            <label htmlFor="programsIds">Programs</label>
                                            <AutoComplete
                                                {...input}
                                                suggestions={dropdownPrograms}
                                                completeMethod={(e) => fetchSuggestions(e.query)}
                                                disabled={getListOfPrograms.isLoading}
                                                multiple={true}
                                                value={selectedPrograms} // Display the program names
                                                field="name"
                                                dropdown={true}
                                                onChange={(e) => {
                                                    const newPrograms = e.value;

                                                    if (newPrograms.length > selectedPrograms.length) {
                                                        // Program added
                                                        const addedProgram = newPrograms[newPrograms.length - 1];
                                                        const existingProgram = selectedPrograms.find((sp) => sp.id === addedProgram.id);
                                                        if (existingProgram) {
                                                            toast.warning("This program is already selected.");
                                                        } else {
                                                            setSelectedPrograms([...selectedPrograms, addedProgram]);
                                                            input.onChange([...input.value, addedProgram.id]);
                                                        }
                                                    } else {
                                                        // Program removed

                                                        const removedProgram = selectedPrograms.find((prog) => !newPrograms.map((np) => np.id).includes(prog.id));
                                                        const updatedSelectedPrograms = selectedPrograms.filter((program) => program.id !== removedProgram.id);
                                                        setSelectedPrograms(updatedSelectedPrograms);

                                                        input.onChange([...updatedSelectedPrograms.map((prog) => prog.id)]);
                                                        fetchSuggestions("");
                                                    }
                                                }}
                                                id="programsIds"
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                            {/* DataTable to display selected programs */}
                                            {input.value && input.value.length > 0 && (
                                                <div className="p-field m-4">
                                                    <DataTable value={selectedPrograms}>
                                                        <Column field="id" header="Program ID"></Column>
                                                        <Column field="name" header="Program Name"></Column>
                                                        <Column
                                                            body={(rowData) => (
                                                                <DeleteIcon
                                                                    style={{ color: "tomato", cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        const updatedSelectedPrograms = selectedPrograms.filter((program) => program.id !== rowData.id);
                                                                        setSelectedPrograms(updatedSelectedPrograms);
                                                                        input.onChange(updatedSelectedPrograms.map((prog) => prog.id));
                                                                        fetchSuggestions(""); // Refresh the dropdown suggestions
                                                                    }}
                                                                />
                                                            )}
                                                            header="Actions"
                                                        ></Column>
                                                    </DataTable>
                                                </div>
                                            )}
                                            {getListOfPrograms.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        </div>
                                    );
                                }}
                            </Field>

                            <Field name="details">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="details">Details</label>
                                        <InputTextarea {...input} rows={5} cols={30} id="details" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>
                            {/* <Field name="description">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="description">Description</label>
                                        <InputTextarea {...input} rows={5} cols={30} id="description" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field> */}

                            <div className="d-grid gap-2">
                                <Button type="submit" label="Save" className="p-button-primary" icon="pi pi-check" />
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    onHide={onCancel}
                    style={{ width: "30vw" }}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to submit?
                </Dialog>
            </div>
        </div>
    );
}

export default DepartmentsRowForm;
