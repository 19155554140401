import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";
import { Card } from "primereact/card";

// Initialize the required Highcharts modules
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);

const IndicatorTypesGraphs = ({ data }) => {
    // Define a method to format date strings
    const formatDate = (dateString) => {
        return moment(dateString).format("YYYY-MM-DD HH:mm:ss");
    };

    // Function to generate Highcharts options
    const getChartOptions = (indicator) => {
        return {
            chart: {
                type: "column",
            },
            title: {
                text: `${indicator.department_outcome_indicator.outcome_indicator.name}`,
            },
            subtitle: {
                text: `${indicator.department_outcome_indicator.outcome_indicator.definition}`,
            },
            xAxis: {
                categories: ["Baseline", "Actual", "Target"],
                title: {
                    text: null,
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: "Values",
                    align: "high",
                },
                labels: {
                    overflow: "justify",
                },
            },
            tooltip: {
                valueSuffix: " units",
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true,
                    },
                },
            },
            credits: {
                enabled: false,
            },
            series: [
                {
                    name: "Values",
                    data: [parseFloat(indicator.base_value), parseFloat(indicator.financial_year_target), parseFloat(indicator.target_value)],
                    colorByPoint: true, // This will apply different colors to each bar
                },
            ],
        };
    };

    return (
        <TabView scrollable={false}>
            {data?.map((item, index) => (
                <TabPanel key={index} header={item.type}>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {item.data.map((indicator, idx) => (
                            <Card style={{ flex: "1 1 auto", maxWidth: "400px", margin: "1rem" }} key={idx}>
                                <div style={{ margin: "1rem 0" }}>
                                    <HighchartsReact highcharts={Highcharts} options={getChartOptions(indicator)} />
                                </div>
                            </Card>
                        ))}
                    </div>
                </TabPanel>
            ))}
        </TabView>
    );
};

export default IndicatorTypesGraphs;
