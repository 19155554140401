import axiosAPI from "../axiosApi";

export async function getAllDepartmentOutcomeIndicatorTargets(params = {}) {
    const response = await axiosAPI.get("departmentOutcomeIndicatorTarget", { params: params });
    return response;
}

export async function getDeptOutcomeIndicatorTargetsGroupedByIndicatorTypes(params = {}) {
    const response = await axiosAPI.get("/dept-outcome-indicator-targets/grouped-by-type", { params: params });
    return response;
}

export async function getDepartmentOutcomeIndicatorTargetById(id) {
    const response = await axiosAPI.get(`departmentOutcomeIndicatorTarget/${id}`);
    return response;
}

export async function postDepartmentOutcomeIndicatorTarget(data) {
    const response = await axiosAPI.post(`departmentOutcomeIndicatorTarget`, data);
    return response;
}

export async function updateDepartmentOutcomeIndicatorTarget(id, data) {
    const response = await axiosAPI.put(`departmentOutcomeIndicatorTarget/${id}`, data);
    return response;
}

export async function deleteDepartmentOutcomeIndicatorTargetById(id) {
    const response = await axiosAPI.delete(`departmentOutcomeIndicatorTarget/${id}`);
    return response;
}
