import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import DirectorateForm from "./DirectorateForm";

import { getAllDirectorates, deleteDirectorateById } from "../../../services/departments/directorate-service";
import DirectorateEditForm from "./DirectorateEditForm";
import { Panel } from "primereact/panel";
import { TabView, TabPanel } from "primereact/tabview";

import moment from "moment";

import { Link } from "react-router-dom";

import WaterIsLoading from "../../../components/mweComponents/WaterIsLoading";
import MuiTable from "../../../components/mweComponents/MuiTable";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { useNavigate } from "react-router-dom";

function DirectorateList(props) {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const programId = props.programId;
    console.log("programId is : ", programId);
    // useEffect(() => {
    //     queryClient.removeQueries("directorate");
    // }, [programId]);

    const { data, isLoading, isError, error } = useQuery(["directorate", programId], () => (!programId ? getAllDirectorates() : getAllDirectorates({ programId: programId })), {
        onSuccess: (data) => {},
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
            console.log("get list of directorates : ", error);
        },
    });

    const DirectorateDeleteMutation = useMutation((variables) => deleteDirectorateById(variables), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(["directorate"]);
            queryClient.invalidateQueries(["programs", "programs-hireachy"]);
        },
        onError: (error) => {
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
            console.log("The error That Occured While Deleting Directorates is : ", error);
        },
    });

    // const handleDelete = async (event, id) => {
    //     var result = window.confirm("Are you sure you want to delete?");
    //     if (result === true) {
    //         DirectorateDeleteMutation.mutate(id);
    //     }
    // };

    const handleDelete = (event, id) => {
        console.log("Delete Id Is : ", id);

        let deleteDirectorateId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(deleteDirectorateId),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (deleteDirectorateId) => {
        if (deleteDirectorateId !== null) {
            console.log("Ohh Yea delete Directorate Id Not Null");
            DirectorateDeleteMutation.mutate(deleteDirectorateId);
        }
    };

    const cancelDelete = () => {
        // setDeleteDirectorateId(null);
    };

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    let tableId = 0;
    const columns = [
        {
            title: "#",
            field: "name",
            width: "5%",
            render: (rowData, index) => {
                // console.log("rowData is : ", rowData);
                // console.log("index is : ", index);
                // tableId = rowData.tableData.id;
                tableId = tableId + 1;
                return <div>{rowData.tableData.index + 1}</div>;
                // return <div>{index}</div>;
            },
        },
        {
            title: "Name",
            field: "name",
            cellStyle: {
                whiteSpace: "nowrap",
                padding: "8px",
            },
        },
        {
            title: "Details",
            field: "details",
            hidden: true,
            render: (rowData) => {
                return rowData.details;
            },
        },
        {
            title: "Date",
            hidden: true,
            field: "created_at",
            render: (rowData) => {
                return moment(rowData.created_at).format("lll");
            },
        },
    ];

    return (
        <div style={{ width: "100%" }}>
            {/* <TabView>
                <TabPanel header="Directorates"> */}{" "}
            <Panel header="Directorates" style={{ marginBottom: "20px" }}>
                {props.loggedInUserData?.permissions?.includes("add directorates") && (
                    <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end" }}>
                        <Button onClick={() => setShowAddForm(true)}>Add Directorate</Button>

                        <DirectorateForm programDetail={props?.programDetail} currentProgramId={programId} show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} />
                    </div>
                )}

                <MuiTable
                    tableTitle="Directorates"
                    tableData={data?.data?.data}
                    tableColumns={columns}
                    handleShowEditForm={handleShowEditForm}
                    handleViewPage={(rowData) => {
                        navigate("directorate", { state: { DirectorateData: rowData } });
                    }}
                    // handleViewPage={(rowData) => {
                    //     navigate("/directorates/view", { state: { DirectorateData: rowData } });
                    // }}
                    showViewPage={true}
                    hideRowViewPage={false}
                    handleDelete={(e, item_id) => handleDelete(e, item_id)}
                    showEdit={props.loggedInUserData?.permissions?.includes("edit directorates")}
                    showDelete={props.loggedInUserData?.permissions?.includes("delete directorates")}
                    loading={isLoading}
                    exportButton={true}
                    pdfExportTitle={"Directorates"}
                    csvExportTitle={"Directorates"}
                />
                {selectedItem && <DirectorateEditForm directoratedata={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} />}
                {/* {loading && <WaterIsLoading />} */}

                {DirectorateDeleteMutation.isLoading && <WaterIsLoading />}
            </Panel>
            {/* </TabPanel>
                <TabPanel header="Directorate Reports"></TabPanel>
            </TabView> */}
        </div>
    );
}

export default DirectorateList;
