import React, { useEffect, useState } from "react";
import StatisticsDisplay from "./widgets/StatisticsDisplay";
import LocationFiltersPage from "./widgets/location-filters/LocationFiltersPage";
import Typewriter from "typewriter-effect";
import Lottie from "lottie-react";
import UrbanCityBuildings from "../assets/Animation - 1701991769959.json"; // Path to your Lottie animation file
import UgandaFlagPopping from "../assets/Uganda flag poppingAnimation - 1703156510424.json";
import UgandaFlagSpreadingAnimation from "../assets/Uganda flag spreadingAnimation - 1703156929188.json";
import WaterPointDistributionSourcesMap from "./widgets/water-point-distribution-points-map/WaterPointDistributionSourcesMap";
import VillageStatisticsPieChart from "./widgets/villages-served/VillageServedStatisticsPieChart";
import UgandaVisionImage from "../assets/Uganda-Vision-2040-p1-normal-removebg-preview.png";
import { getAllPointWaterSources, getPointWaterSourceById, postPointWaterSources, updatePointWaterSources, deletePointWaterSourceById } from "../../../../services/national-dashboard/point-water-sources-service.js";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import OutcomeIndicatorsPage from "./widgets/outcome-indicators/OutcomeIndicatorsPage";

//
function NationalDashboardPage({ loggedInUserData }) {
    const queryClient = useQueryClient();
    const [waterPointsData, setWaterPointsData] = useState();
    const [filterParams, setFilterParams] = useState({ department_id: loggedInUserData?.user_department_id });
    const [filterSelectedObjects, setFilterSelectedObjects] = useState();
    const [filterQueryIsLoading, setFilterQueryIsLoading] = useState();

    console.log("national dashboard logged in user : loggedInUserData ", loggedInUserData);

    //
    const MemoizedLocationFiltersPage = React.memo(LocationFiltersPage);
    const MemoizedVillageStatisticsPieChart = React.memo(VillageStatisticsPieChart);
    const MemoizedWaterPointDistributionSourcesMap = React.memo(WaterPointDistributionSourcesMap);
    const MemoizedOutcomeIndicatorsPage = React.memo(OutcomeIndicatorsPage);

    //
    const fetchAllRecordsFromLastThreeYears = useQuery(["point-water-sources", loggedInUserData?.user_department_id], () => (!loggedInUserData?.user_department_id ? getAllPointWaterSources() : getAllPointWaterSources({ department_id: loggedInUserData?.user_department_id })), {
        onSuccess: (data) => {
            console.log("data fetching point water sources from last 3 years : ", data);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
            console.log("get list of departments : ", error);
        },
        // staleTime: 5 * 60 * 60 * 1000, // 5 hours in milliseconds
    });

    return (
        <div className="grid">
            <div className="col-12 xl:col-12">
                <div className="card" style={{ position: "relative" }}>
                    {/* Lottie background */}
                    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, opacity: 0.1, overflow: "hidden" }}>
                        {/* <Lottie
                            animationData={UgandaFlagSpreadingAnimation}
                            loop={true}
                            autoplay={true}
                            style={{
                                width: "100%", // Increase width to cover the area
                                // height: "100%", // Increase height to cover the area
                            }}
                        /> */}
                        <center>
                            <img
                                src={UgandaVisionImage}
                                alt="Uganda Vision"
                                style={{
                                    //width: "100%", // Ensure the image covers the entire area
                                    // height: "100%", // Adjust as needed
                                    objectFit: "cover", // This will ensure the image covers the area nicely
                                }}
                            />
                        </center>
                    </div>

                    {/* Content */}
                    <div style={{ position: "relative" }}>
                        <div>
                            <h4>
                                <Typewriter
                                    options={{
                                        strings: "National Dashboard",
                                        autoStart: true,
                                        loop: false,
                                        cursor: "",
                                        delay: 2,
                                    }}
                                />
                            </h4>
                            <p>
                                <Typewriter
                                    options={{
                                        strings:
                                            "The National Dashboard presents an initial overview of Uganda's point water sources from the last two years. Users can delve deeper into specific aspects by applying various filters, such as by department, to gain more targeted insights. This dashboard combines both historical and current data, categorized by financial years, to provide a comprehensive picture of the country's water management efforts and progress. This integration helps in aligning with Uganda's Vision for improved water access and environmental conservation.",
                                        autoStart: true,
                                        loop: false,
                                        cursor: "",
                                        delay: 2,
                                    }}
                                />
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 xl:col-12">
                <MemoizedLocationFiltersPage
                    setFilterQueryIsLoading={setFilterQueryIsLoading}
                    filterQueryIsLoading={filterQueryIsLoading}
                    setFilterParams={setFilterParams}
                    filterParams={filterParams}
                    setFilterSelectedObjects={setFilterSelectedObjects}
                    filterSelectedObjects={filterSelectedObjects}
                    loggedInUserData={loggedInUserData}
                    waterPointsData={waterPointsData}
                    setWaterPointsData={setWaterPointsData}
                />
            </div>
            {/* <div className="col-12 xl:col-12">
                <MemoizedVillageStatisticsPieChart
                    setFilterQueryIsLoading={setFilterQueryIsLoading}
                    filterQueryIsLoading={filterQueryIsLoading}
                    setFilterParams={setFilterParams}
                    filterParams={filterParams}
                    loggedInUserData={loggedInUserData}
                    waterPointsData={waterPointsData?.data}
                    setWaterPointsData={setWaterPointsData}
                />
            </div> */}
            <div className="col-12 xl:col-12">
                <MemoizedWaterPointDistributionSourcesMap
                    setFilterQueryIsLoading={setFilterQueryIsLoading}
                    filterQueryIsLoading={filterQueryIsLoading}
                    loggedInUserData={loggedInUserData}
                    waterPointsData={waterPointsData}
                    setWaterPointsData={setWaterPointsData}
                    fetchAllRecordsFromLastThreeYears={fetchAllRecordsFromLastThreeYears}
                />
            </div>
            {/* <div className="col-12 xl:col-12">
                <MemoizedOutcomeIndicatorsPage
                    setFilterQueryIsLoading={setFilterQueryIsLoading}
                    filterQueryIsLoading={filterQueryIsLoading}
                    setFilterParams={setFilterParams}
                    filterParams={filterParams}
                    loggedInUserData={loggedInUserData}
                    waterPointsData={waterPointsData?.data}
                    setWaterPointsData={setWaterPointsData}
                />
            </div> */}
            {/* <div className="col-12 xl:col-12">
                <LocationFiltersPage setFilterParams={setFilterParams} filterParams={filterParams} loggedInUserData={loggedInUserData} waterPointsData={waterPointsData} setWaterPointsData={setWaterPointsData} />
            </div>

            <div className="col-12 xl:col-12">
                <VillageStatisticsPieChart setFilterParams={setFilterParams} filterParams={filterParams} loggedInUserData={loggedInUserData} waterPointsData={waterPointsData} setWaterPointsData={setWaterPointsData} />
            </div>
            <div className="col-12 xl:col-12">
                <WaterPointDistributionSourcesMap loggedInUserData={loggedInUserData} waterPointsData={waterPointsData} setWaterPointsData={setWaterPointsData} fetchAllRecordsFromLastThreeYears={fetchAllRecordsFromLastThreeYears} />
            </div>
            <div className="col-12 xl:col-12">
                {" "}
                <OutcomeIndicatorsPage setFilterParams={setFilterParams} filterParams={filterParams} loggedInUserData={loggedInUserData} waterPointsData={waterPointsData} setWaterPointsData={setWaterPointsData} />
            </div> */}
            {/* <div className="col-12 xl:col-12">
                <StatisticsDisplay />
            </div> */}
        </div>
    );
}

export default NationalDashboardPage;
