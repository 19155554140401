import React, { useEffect, useState, useRef } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
// import CreateForm from "./CreateForm";

// import EditForm from "./EditForm";

import moment from "moment";

import WaterIsLoading from "../../../../../components/mweComponents/WaterIsLoading";
import MuiTable from "../../../../../components/mweComponents/MuiTable";
import { toast } from "react-toastify";
import { Button } from "primereact/button";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useNavigate } from "react-router-dom";

//
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Tooltip from "@mui/material/Tooltip";

import { Panel } from "primereact/panel";
import SubProjectPerfomanceReportPdfModal from "./SubProjectPerfomanceReportPdfModal";
import ProjectReportPdfModal from "./ProjectReportPdfModal";
import { TabView, TabPanel } from "primereact/tabview";
import { Toast as PrimeToast } from "primereact/toast";
import { postToAggregateProjectReports } from "../../../../../services/projects/project-field-data-service.js";
import SubprojectAggregationModal from "./ProjectAggregationModal.jsx";

//
function ProjectReportsList({ filterQueryIsLoading, reportsData, ...props }) {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showBudjetOutPutAddForm, setShowBudjetOutPutAddForm] = useState(false);

    //edition for aggregation
    const primeToast = useRef(null);
    const [tableSelectedRows, setTableSelectedRows] = useState([]);
    const [isCreateConfirmDialogVisible, setIsCreateConfirmDialogVisible] = useState(false);
    const [aggregateSubProjectReportsData, setAggregateSubProjectReportsData] = useState([]);
    const [showAggregatedPDF, setShowAggregatedPDF] = useState(false);

    const validateSelectedRows = () => {
        if (tableSelectedRows.length === 0) {
            primeToast.current.show({ severity: "warn", summary: "Warning", detail: "No rows selected", life: 3000 });
            return false;
        }

        // const firstRow = tableSelectedRows[0];
        // const isSameQuarterAndYear = tableSelectedRows.every((row) => row.financial_year.name === firstRow.financial_year.name && row.quarter.name === firstRow.quarter.name);

        // if (!isSameQuarterAndYear) {
        //     primeToast.current.show({ severity: "warn", summary: "Warning", detail: "All selected rows must have the same quarter and financial year", life: 3000 });
        //     return false;
        // }

        return true;
    };

    const handleCreateAggregatedReportClick = () => {
        if (validateSelectedRows()) {
            setIsCreateConfirmDialogVisible(true);
        }
    };

    // get aggregateSubProjectReportsData
    const postToAggregateSubProjectReportsDataMutation = useMutation(postToAggregateProjectReports, {
        onSuccess: (data) => {
            //   queryClient.invalidateQueries(["projects"]);
            //   toast.success("Project Created Successfully");
            console.log("getProjectDeptOutcomeIndicatorsDataMutation data : ", data);

            handleShowAggregatedPDF(data?.data);
        },
        onError: (error) => {
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
        },
    });

    const handleShowAggregatedPDF = (item) => {
        // setSelectedItem(item);
        setAggregateSubProjectReportsData(item);
        setShowAggregatedPDF(true);
    };

    const handleCreateAggregateSubprojectReportConfirm = () => {
        setIsCreateConfirmDialogVisible(false);
        setAggregateSubProjectReportsData([]);
        // Logic for creating the project report goes here
        console.log("project report adfdafafa report selected report : ", tableSelectedRows);
        postToAggregateSubProjectReportsDataMutation.mutate({ reports_data: tableSelectedRows });
        // navigate("report", { state: { subProjectReports: tableSelectedRows, selectedprojectItem: selectedprojectItem } });
    };

    const handleCreateAggregateSubprojectReportReject = () => {
        setIsCreateConfirmDialogVisible(false);
        // Logic for rejection (if any) goes here
    };

    //edition for aggregation

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    const onBudjetOutPutFormClose = () => {
        setShowBudjetOutPutAddForm(false);
    };

    let tableId = 0;

    const columns = [
        {
            title: "#",
            width: "5%",
            field: "name",
            render: (rowData) => {
                // tableId = rowData.tableData.id;
                tableId = tableId++;
                return <div>{rowData.tableData.index + 1}</div>;
                // return <div>{rowData.tableData.id}</div>;
            },
        },
        {
            title: "Project Name",
            field: "project.project_title",
        },
        {
            title: "Financial Year",
            field: "financial_year.name",
        },
        {
            title: "Quarter",
            field: "quarter.name",
        },
        {
            title: "Released Funds",
            hidden: true,
            field: "approved_budget",
            render: (rowData) => {
                // Ensure approved_budget is a string before replacing
                const amountString = rowData.approved_budget ? rowData.approved_budget.toString() : "";
                const amount = parseFloat(amountString.replace(/,/g, ""));
                return <div>{isNaN(amount) ? rowData.approved_budget : amount.toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>;
            },
        },
        {
            title: "Spent Funds",
            field: "spent_funds",
            hidden: true,
            render: (rowData) => {
                // Ensure spent_funds is a string before replacing
                const amountString = rowData.spent_funds ? rowData.spent_funds.toString() : "";
                const amount = parseFloat(amountString.replace(/,/g, ""));
                return <div>{isNaN(amount) ? rowData.spent_funds : amount.toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>;
            },
        },
        // {
        //     title: "Released Funds",
        //     field: "released_funds",
        //     hidden: true,
        // },

        {
            title: "Absorption rate",
            hidden: true,
            field: "absorption_rate",
            render: (rowData) => {
                return <div>{rowData?.absorption_rate} %</div>;
            },
        },
        {
            title: "Approval Status",
            hidden: true,
            field: "current_status.name",
            render: (rowData) => {
                let labelStyle = { padding: "3px", color: "#fff", borderRadius: "4px" };
                let labelClass = "";

                switch (rowData?.current_status?.name) {
                    case "draft":
                        labelStyle = { ...labelStyle, backgroundColor: "#f0ad4e" }; // Bootstrap warning color
                        labelClass = "label label-default";
                        break;
                    case "submitted":
                        labelStyle = { ...labelStyle, backgroundColor: "#5bc0de" }; // Bootstrap info color
                        labelClass = "label label-info";
                        break;
                    case "approved":
                        labelStyle = { ...labelStyle, backgroundColor: "#5cb85c" }; // Bootstrap success color
                        labelClass = "label label-success";
                        break;
                    case "rejected":
                        labelStyle = { ...labelStyle, backgroundColor: "#d9534f" }; // Bootstrap danger color
                        labelClass = "label label-danger";
                        break;
                    default:
                        labelStyle = { ...labelStyle, backgroundColor: "#5bc0de" }; // Bootstrap info color
                        labelClass = "label label-info";
                }

                return (
                    <strong style={labelStyle} className={labelClass}>
                        {rowData?.current_status?.name.charAt(0).toUpperCase() + rowData?.current_status?.name.slice(1)}
                    </strong>
                );
            },
        },

        {
            title: "Status",
            field: "status",
            hidden: true,
            render: (rowData) => {
                let labelStyle = { padding: "3px", color: "#fff", borderRadius: "4px" };
                let labelClass = "";

                switch (rowData.status) {
                    case "draft":
                        labelStyle = { ...labelStyle, backgroundColor: "#f0ad4e" }; // Bootstrap warning color
                        labelClass = "label label-default";
                        break;
                    case "submitted":
                        labelStyle = { ...labelStyle, backgroundColor: "#5bc0de" }; // Bootstrap info color
                        labelClass = "label label-info";
                        break;
                    case "approved":
                        labelStyle = { ...labelStyle, backgroundColor: "#5cb85c" }; // Bootstrap success color
                        labelClass = "label label-success";
                        break;
                    case "rejected":
                        labelStyle = { ...labelStyle, backgroundColor: "#d9534f" }; // Bootstrap danger color
                        labelClass = "label label-danger";
                        break;
                    default:
                        labelStyle = { ...labelStyle, display: "none" }; // Hide label if status is unknown
                }

                return (
                    <strong style={labelStyle} className={labelClass}>
                        {rowData.status.charAt(0).toUpperCase() + rowData.status.slice(1)}
                    </strong>
                );
            },
        },
        {
            title: "View",
            field: "view",
            render: (rowData) => {
                return (
                    <div style={{ color: "blue", cursor: "pointer" }} onClick={(e) => handleShowPDF(e, rowData)}>
                        <Tooltip title="Click to view report">
                            <PictureAsPdfIcon style={{ color: "orange" }} />
                            {/* <span>{rowData?.subproject?.name}</span> */}
                        </Tooltip>
                    </div>
                );
            },
        },

        {
            title: "Updated By",
            hidden: true,
            field: "updated_by_user.email",
        },

        {
            title: "Created By",
            hidden: true,
            field: "created_by_user.email",
        },
        {
            title: "Date",
            hidden: true,
            field: "created_at",
            render: (rowData) => {
                return moment(rowData.created_at).format("lll");
            },
        },
    ];

    //======================= pdf ====================
    //  const [selectedItem, setSelectedItem] = useState({ id: null });
    const [showPDF, setShowPDF] = useState(false);
    const handleShowPDF = (e, item) => {
        setSelectedItem(item);
        setShowPDF(true);
    };
    const handleClosePDF = () => {
        setSelectedItem({ id: null });
        setShowPDF(false);
    };

    const [tableSelection, setTableSelection] = useState(false);

    //
    return (
        <div style={{ width: "100%" }}>
            <PrimeToast ref={primeToast} />
            <Panel header="Project Reports" style={{ marginBottom: "20px" }}>
                {/* <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                    {activeUser?.permissions.includes("add reports") && (
                        <Button
                            label={tableSelection ? "Single Create" : "Bulk Create"}
                            className="p-button-primary"
                            onClick={() => {
                                setTableSelection(!tableSelection);
                            }}
                        />
                    )}
                </div> */}
                {activeUser?.permissions.includes("add reports") && tableSelectedRows.length > 0 && (
                    <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                        <Button
                            label="Aggregate Report"
                            className="p-button-primary"
                            disabled={postToAggregateSubProjectReportsDataMutation.isLoading}
                            onClick={() => {
                                handleCreateAggregatedReportClick();
                            }}
                        />
                    </div>
                )}
                <MuiTable
                    tableTitle="Project Reports"
                    // tableData={data?.data?.data}
                    tableData={reportsData ?? []}
                    tableColumns={columns}
                    handleViewPdf={(e, item_id) => handleShowPDF(e, item_id)}
                    //
                    exportButton={false}
                    hideViewPdfRow={(rowData) => {
                        console.log("rowdata llllvvccd : ", rowData);
                        return rowData.status === "submitted" || rowData.status === "approved" ? false : true;
                    }}
                    showViewPdf={true}
                    loading={filterQueryIsLoading || postToAggregateSubProjectReportsDataMutation.isLoading}
                    //approvals
                    selection={true}
                    showSelectAllCheckbox={true}
                    showTextRowsSelected={true}
                    selectionChange={(selectedRows) => {
                        console.log("selected rows on sselection change : ", selectedRows);
                        setTableSelectedRows(selectedRows);
                    }}
                    handleDataApproval={() => {}}
                />

                {selectedItem && <ProjectReportPdfModal projectReport={selectedItem} loggedInUserData={props?.loggedInUserData} show={showPDF} onHide={() => setShowPDF(false)} onClose={onFormClose} />}
                {showAggregatedPDF && <SubprojectAggregationModal aggregatedReportDetail={aggregateSubProjectReportsData} loggedInUserData={props?.loggedInUserData} show={showAggregatedPDF} onHide={() => setShowAggregatedPDF(false)} />}
                {/* <ConfirmDialog /> */}
            </Panel>

            <ConfirmDialog
                visible={isCreateConfirmDialogVisible}
                onHide={() => setIsCreateConfirmDialogVisible(false)}
                message="Are you sure you want to create an aggregated report?"
                header="Confirmation"
                icon="pi pi-exclamation-triangle"
                accept={handleCreateAggregateSubprojectReportConfirm}
                reject={handleCreateAggregateSubprojectReportReject}
            />
        </div>
    );
}

export default ProjectReportsList;
