import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import CreateForm from "./CreateForm";

import EditForm from "./EditForm";

import { deleteProgramById, getAllPrograms } from "../../../../services/programs/program-service";

import moment from "moment";

import { Link } from "react-router-dom";

import { getAllProjectSubprojectMetricsIndicators, getProjectSubprojectMetricsIndicatorById, postProjectSubprojectMetricsIndicator, updateProjectSubprojectMetricsIndicator, deleteProjectSubprojectMetricsIndicatorById } from "../../../../services/projects/subproject-metrics-indicators-service";
import { getAllProjectDepartmentBudgetOutputs, getProjectDepartmentBudgetOutputById, postProjectDepartmentBudgetOutput, updateProjectDepartmentBudgetOutput, deleteProjectDepartmentBudgetOutputById } from "../../../../services/projects/project-department-budget-outputs-service.js";

import WaterIsLoading from "../../../../components/mweComponents/WaterIsLoading";
import MuiTable from "../../../../components/mweComponents/MuiTable";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import { Panel } from "primereact/panel";
import { Tooltip } from "primereact/tooltip";
import { useNavigate } from "react-router-dom";

import ProjectBudjetOutputsIndicatorsModal from "./ProjectBudjetOutputsIndicatorsModal.jsx";

function ListPage({ selectedprojectItem, loggedInUserData, ...props }) {
    const navigate = useNavigate();
    console.log("project id fffffffffdss : ", props?.projectId);
    const queryClient = useQueryClient();
    const { data, isLoading, isError, error, status } = useQuery(["project", "budgetOutputs", props?.projectId], () => (!props?.projectId ? getAllProjectDepartmentBudgetOutputs() : getAllProjectDepartmentBudgetOutputs({ project_id: props?.projectId })), {
        onSuccess: (data) => {
            console.log("getAllProjectDepartmentBudgetOutputs budget outputs is hhh : ", data);
        },
        onError: (error) => {
            console.log("Error fetching budget outputs is : ", error);
        },
    });

    console.log("outputs hdhhdh : ", data);
    console.log(data);
    isError && toast.error("There was an error while fetching data");

    const DeleteSelectedItemMutation = useMutation((variables) => deleteProjectDepartmentBudgetOutputById(variables), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(["project", "budgetOutputs"]);
        },
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
            console.log("Delete list of budgetOutputs : ", error);
        },
    });

    // const handleDelete = async (event, id) => {
    //     var result = window.confirm("Are you sure you want to delete?");
    //     if (result === true) {
    //         ProgramDeleteMutation.mutate(id);
    //     }
    // };

    const handleDelete = (event, id) => {
        let selectedDeleteId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(selectedDeleteId),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (selectedDeleteId) => {
        if (selectedDeleteId !== null) {
            DeleteSelectedItemMutation.mutate(selectedDeleteId);
        }
    };

    const cancelDelete = () => {
        // setDeleteProgramId(null);
    };

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showBudjetOutPutAddForm, setShowBudjetOutPutAddForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const activeUser = loggedInUserData ? loggedInUserData : localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    const onBudjetOutPutFormClose = () => {
        setShowBudjetOutPutAddForm(false);
    };

    let tableId = 0;
    const columns = [
        {
            title: "#",
            width: "5%",
            field: "department_budget_output.name",
            render: (rowData) => {
                // tableId = rowData.tableData.id;
                tableId = tableId++;
                return <div>{rowData.tableData.index + 1}</div>;
                // return <div>{rowData.tableData.id}</div>;
            },
        },
        // {
        //     title: "Code",
        //     field: "department_budget_output.code",
        //     render: (rowData) => {
        //         return (
        //             <strong
        //                 onClick={(e) => {
        //                     e.preventDefault();
        //                     setCurrentIndicatorRowData(rowData?.department_budget_output?.department_budget_output_indicator);
        //                     setShowIndicatorModal(true);
        //                 }}
        //                 className="custom-target-icon text-decoration-none"
        //                 style={{
        //                     display: "inline-block",
        //                     padding: "5px 10px",
        //                     color: "blue",
        //                     borderRadius: "4px",
        //                     fontSize: "1em",
        //                     cursor: "pointer",
        //                 }}
        //                 data-pr-tooltip="Click to view indicators"
        //                 data-pr-position="top"
        //             >
        //                 {rowData?.department_budget_output?.code}
        //                 <Tooltip target=".custom-target-icon" />
        //             </strong>
        //         );
        //     },
        // },
        {
            title: "Name",
            field: "department_budget_output.name",
        },
        {
            title: "Code",
            field: "department_budget_output.code",
        },
        { title: "Project", field: "project.project_title" },

        {
            title: "Date",
            hidden: true,
            field: "created_at",
            render: (rowData) => {
                return moment(rowData.created_at).format("lll");
            },
        },
    ];

    //============ project budget outputs indicators ===========
    const [showIndicatorModal, setShowIndicatorModal] = useState(false);
    const [currentIndicatorRowData, setCurrentIndicatorRowData] = useState(null);

    return (
        <div style={{ width: "100%" }}>
            <div className="col-12 xl:col-12">
                <div className="card">
                    <p>Budget Outputs attached to a project</p>
                </div>
            </div>
            <Panel header="Project Budget outputs" style={{ marginBottom: "20px" }}>
                {/* <div className="d-flex justify-content-end mb-3" style={{ width: "100%" }}>
                    {activeUser?.permissions.includes("add programs") && <Button label="Add Program" className="p-button-primary right-0" onClick={() => setShowAddForm(true)} />}
                    <CreateForm show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} />
                </div> */}
                <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                    {/* {activeUser?.permissions.includes("add programs") && (
                        <Button
                            label="Add Budget Outputs"
                            className="p-button-primary"
                            onClick={() => {
                                setShowBudjetOutPutAddForm(true);
                                console.log("clicked add outcomes");
                            }}
                        />
                    )} */}
                    {(activeUser?.permissions.includes("add projects") || loggedInUserData?.permissions.includes("add projects")) && <Button label="Assign Budget Output" className="p-button-primary" onClick={() => setShowAddForm(true)} />}
                    <CreateForm selectedprojectItem={selectedprojectItem} show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} projectId={props?.projectId} />
                    {/* <BudgetOutPutsCreateForm show={showBudjetOutPutAddForm} onHide={() => setShowBudjetOutPutAddForm(false)} onClose={onBudjetOutPutFormClose} /> */}
                </div>
                <MuiTable
                    tableTitle="Project Budget Outputs"
                    tableData={data?.data?.data}
                    tableColumns={columns}
                    // handleShowEditForm={handleShowEditForm}
                    // showEdit={activeUser?.permissions.includes("edit programs") || activeUser?.permissions.includes("delete programs")}
                    handleDelete={(e, item_id) => handleDelete(e, item_id)}
                    showDelete={activeUser?.permissions.includes("delete projects")}
                    handleViewPage={(rowData) => {
                        navigate("budgetoutput", { state: { selectedbudgetOutputData: rowData, project_id: props?.projectId, selectedprojectItem: selectedprojectItem } });
                    }}
                    showViewPage={true}
                    hideRowViewPage={false}
                    loading={isLoading || status === "loading" || DeleteSelectedItemMutation.isLoading}
                    exportButton={true}
                    pdfExportTitle="Projects budget outputs"
                    csvExportTitle="Projects budget outputs"
                />
                {selectedItem && <EditForm selectedprojectItem={selectedprojectItem} rowData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} />}
                {/* <ConfirmDialog /> */},
                <ProjectBudjetOutputsIndicatorsModal visible={showIndicatorModal} onHide={() => setShowIndicatorModal(false)} indicatorData={currentIndicatorRowData} />
                {DeleteSelectedItemMutation.isLoading && <WaterIsLoading />}
            </Panel>
        </div>
    );
}

export default ListPage;
