import React, { useState, useEffect, useRef } from "react";
// import { Card } from "primereact/card";
import { Tree } from "primereact/tree";
import { Divider } from "primereact/divider";
import { useLocation } from "react-router-dom";
import { Panel } from "primereact/panel";
import BreadcrumbNav from "../../../components/mweComponents/BreadcrumbNav";
import { Button } from "primereact/button";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { Fieldset } from "primereact/fieldset";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { TabView, TabPanel } from "primereact/tabview";
// import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primereact/resources/themes/lara-light-blue/theme.css";
// import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Toast as PrimeToast } from "primereact/toast";

import { deleteSubProjectById, getAllSubProjects, getSubProjectById, getAllSubProjectsUnderUserProjectsByProjectIdOrRole } from "../../../services/sub-projects/sub-project-service";
import { ProgressBar } from "primereact/progressbar";
import SubProjectLocationsPage from "./subproject-locations/SubProjectLocationsPage";
import SubProjectBudgetPage from "./subproject-budget/SubProjectBudgetPage";
// import SubProjectReportPage from "./subproject-reports/SubProjectReportPage";
import SubProjectDataCollectionPage from "./subproject-data-collection/SubProjectDataCollectionPage";
import SubprojectFieldDataList from "./subproject-data-collection/ListPage";
import SubProjectBudgetOutputsPage from "./subproject-budgetOutputs/SubProjectBudgetOutputsPage.js";
import moment from "moment";
import DashboardPage from "./dashboard/DashboardPage";

const SubProjectsViewPage = ({ loggedInUserData }) => {
    const queryClient = useQueryClient();
    //
    let { state } = useLocation();
    let subProjectsData = state ? state.subProjectsData : null;
    let selectedprojectItem = state ? state.selectedprojectItem : null;
    let componentDetailFromNavLink = state?.componentDetailFromNavLink ? state?.componentDetailFromNavLink : null;
    let subprojectViewPageActiveIndexFromUrlState = state?.subprojectViewPageActiveIndex ? state?.subprojectViewPageActiveIndex : 0;
    let subprojectViewPageChildActiveIndexFromUrlState = state?.subprojectViewPageChildActiveIndex ? state?.subprojectViewPageChildActiveIndex : 0;

    //
    const [subprojectViewPageActiveIndex, setSubprojectViewPageActiveIndex] = useState(subprojectViewPageActiveIndexFromUrlState);
    const [subprojectViewPageChildActiveIndex, setSubprojectViewPageChildActiveIndex] = useState(subprojectViewPageChildActiveIndexFromUrlState);

    console.log("projects data got from state is : ", subProjectsData);
    console.log("logged in user on subproject view page : ", loggedInUserData);

    const toastRef = useRef(null);
    useEffect(() => {
        if (!subProjectsData && !loggedInUserData?.user_subproject_id && !componentDetailFromNavLink?.details?.id) {
            // Use PrimeToast here
            toastRef.current.show({ severity: "error", summary: "Error", detail: "You are not assigned any subproject", life: 3000 });
        }
    }, [subProjectsData, loggedInUserData?.user_subproject_id, componentDetailFromNavLink?.details?.id]);

    //===================== feytch projects by id =================
    const fetchSubProjectById = useQuery(
        ["subprojects", "getById", subProjectsData?.id ?? componentDetailFromNavLink?.details?.id ?? loggedInUserData?.user_subproject_id],
        () => getSubProjectById(subProjectsData?.id ?? componentDetailFromNavLink?.details?.id ?? loggedInUserData?.user_subproject_id),
        {
            onSuccess: (data) => {
                console.log("fetchSubProjectById onsuccess fetching projects dsfvdsvd : ", data);
            },
            onError: (error) => {
                // props.onClose();
                error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("An Error Occured Please Contact Admin");
                console.log("get list of projects : ", error);
            },
        }
    );

    //incase subproject data is not set
    subProjectsData = subProjectsData ?? fetchSubProjectById?.data?.data?.data;

    const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;

    //===============  funding ======================
    const funderTreeNodes = fetchSubProjectById?.data?.data?.data?.funders?.map((funder) => ({
        key: funder.id,
        label: funder.name,
    }));

    const fundersTreeData = [
        {
            key: "root",
            label: "Funders list",
            children: funderTreeNodes,
        },
    ];

    //===============  Operators ======================
    const OperatorTreeNodes = fetchSubProjectById?.data?.data?.data?.operators?.map((operator) => ({
        key: operator.id,
        label: operator.name,
    }));

    const operatorsTreeData = [
        {
            key: "root",
            label: "Operators list",
            children: OperatorTreeNodes,
        },
    ];

    // Convert Currencies array to tree structure
    const currencyTreeNodes = fetchSubProjectById?.data?.data?.data?.currencies?.map((currency) => ({
        key: currency.id,
        label: currency.name,
    }));

    const currencyTreeData = [
        {
            key: "root",
            label: "Currencies",
            children: currencyTreeNodes,
        },
    ];

    const onTabChange = (e) => {
        setSubprojectViewPageActiveIndex(e.index); // Update the active tab index
    };

    const onChildTabChange = (e) => {
        setSubprojectViewPageChildActiveIndex(e.index); // Update the active tab index
    };

    return (
        <div style={{ width: "100%" }}>
            <PrimeToast ref={toastRef} />
            <BreadcrumbNav />
            <div className="projects-view-page">
                {fetchSubProjectById.isLoading && fetchSubProjectById.isFetching && !subProjectsData && (
                    <div className="m-2">
                        <ProgressBar mode="indeterminate" style={{ height: "4px" }} />
                    </div>
                )}
                <Panel header={`(Subproject / Activity) : ${subProjectsData?.name} `} style={{ marginBottom: "20px" }}>
                    <TabView scrollable={true} activeIndex={subprojectViewPageChildActiveIndex} onTabChange={onChildTabChange}>
                        <TabPanel header="Details">
                            <div className="flex flex-wrap">
                                {/* Column 1 */}
                                <div className="md:w-1/2 px-2 m-4">
                                    <p>
                                        <strong>Project Name:</strong> {subProjectsData?.project?.project_title || "N/A"}
                                    </p>
                                    <p>
                                        <strong>Subproject / Activity Name:</strong> {subProjectsData?.name || "N/A"}
                                    </p>
                                    <p>
                                        <strong>Code:</strong> {subProjectsData?.code || "N/A"}
                                    </p>
                                    <p>
                                        <strong>Procurement No:</strong> {subProjectsData?.procurement_no || "N/A"}
                                    </p>
                                    <p>
                                        <strong>Start Date:</strong>
                                        {subProjectsData?.start_date ? moment(subProjectsData.start_date).format("DD/MM/YY") : "N/A"}
                                    </p>
                                    <p>
                                        <strong>Proposed End Date:</strong>
                                        {subProjectsData?.proposed_end_date ? moment(subProjectsData.proposed_end_date).format("DD/MM/YY") : "N/A"}
                                    </p>

                                    <p>
                                        <strong>Actual End Date:</strong>
                                        {subProjectsData?.completion && subProjectsData.completion === 100 ? (subProjectsData?.actual_end_date ? moment(subProjectsData.actual_end_date).format("DD/MM/YYYY") : "Please provide an actual end date") : "N/A"}
                                    </p>
                                    <p>
                                        <strong>Actual Cost:</strong>
                                        {subProjectsData?.completion && subProjectsData.completion === 100 ? (subProjectsData?.actual_cost ? `$${subProjectsData.actual_cost}` : "Please provide an actual cost") : "N/A"}
                                    </p>

                                    <p>
                                        <strong>Completion Status:</strong> {subProjectsData?.completion_status || "N/A"}
                                    </p>

                                    <p>
                                        <strong>Completion:</strong> <ProgressBar value={subProjectsData?.completion} label={`${subProjectsData?.completion}% Complete`} showValue={true} />
                                    </p>
                                    <p>
                                        <strong>Physical perfomence:</strong> {subProjectsData?.status || "N/A"}
                                    </p>
                                    {/* <p>
                                        <strong>Currency:</strong> {subProjectsData?.currency || "N/A"}
                                    </p> */}
                                    <p>
                                        <strong>Projected Cost:</strong> {subProjectsData?.estimated_cost ? `${subProjectsData.estimated_cost.toLocaleString()}` : "N/A"}
                                    </p>

                                    {/* <p>
                                        <strong>Funding:</strong> {subProjectsData?.funding || "N/A"}
                                    </p> */}
                                </div>

                                {/* Column 2 */}
                                <div className="md:w-1/2 px-2 m-4">
                                    <p>
                                        <strong>Capital-Current Ratio:</strong> {subProjectsData?.capital_current_ratio || "N/A"}
                                    </p>
                                    <p>
                                        <strong>Contractor:</strong> {subProjectsData?.contractor || "N/A"}
                                    </p>
                                    <p>
                                        <strong>Contract Amount:</strong> {subProjectsData?.contract_amount ? `${subProjectsData.contract_amount.toLocaleString()}` : "N/A"}
                                    </p>
                                    <p>
                                        <strong>Supervisor:</strong> {subProjectsData?.supervisor || "N/A"}
                                    </p>
                                    <p>
                                        <strong>Per Capita Investment Cost:</strong> {subProjectsData?.per_capita_investment_cost ? `${subProjectsData.per_capita_investment_cost.toLocaleString()}` : "N/A"}
                                    </p>

                                    {/* <p>
                                        <strong>Project ID:</strong> {subProjectsData?.project_id || "N/A"}
                                    </p> */}

                                    <p>
                                        <strong>Location Type:</strong> {subProjectsData?.location_type || "N/A"}
                                    </p>
                                    {/* Assuming created_by and updated_by are user IDs, and you have their user data */}
                                    {/* <p>
                                        <strong>Created By:</strong> {subProjectsData?.created_by || "N/A"}
                                    </p>
                                    <p>
                                        <strong>Updated By:</strong> {subProjectsData?.updated_by || "N/A"}
                                    </p> */}
                                </div>

                                {/* Full Width Row for Additional Details */}
                                <div className="w-full px-2 m-4">
                                    <p>
                                        <strong>Target Beneficiaries:</strong> {subProjectsData?.target_beneficiaries || "N/A"}
                                    </p>
                                    <p>
                                        <strong>Total Number of Beneficiaries:</strong>
                                        {subProjectsData?.target_beneficiaries_total ? parseInt(subProjectsData.target_beneficiaries_total).toLocaleString() : "N/A"}
                                    </p>
                                </div>
                            </div>
                            <Divider />
                            <div className="m-4">
                                <Fieldset legend="Source of funding">
                                    <Tree value={fundersTreeData} />
                                </Fieldset>
                            </div>
                            <div className="m-4">
                                <Fieldset legend="Currencies">
                                    <Tree value={currencyTreeData} />
                                </Fieldset>
                            </div>
                            <div className="m-4">
                                <Fieldset legend="Operation And Maitenance">
                                    <Tree value={operatorsTreeData} />
                                </Fieldset>
                            </div>
                        </TabPanel>
                        <TabPanel header="(Subproject / Activity) Budget">
                            <SubProjectBudgetPage selectedprojectItem={selectedprojectItem} subprojectDetail={subProjectsData || fetchSubProjectById?.data?.data?.data} />
                        </TabPanel>
                        <TabPanel header="Budget Outputs">
                            <SubProjectBudgetOutputsPage selectedprojectItem={selectedprojectItem} subprojectDetail={subProjectsData || fetchSubProjectById?.data?.data?.data} />
                        </TabPanel>

                        <TabPanel header="(Subproject / Activity) Locations">
                            <SubProjectLocationsPage loggedInUserData={loggedInUserData} selectedprojectItem={selectedprojectItem} subprojectDetail={subProjectsData || fetchSubProjectById?.data?.data?.data} />
                        </TabPanel>
                        <TabPanel header="Perfomance Reports">
                            {/* <SubProjectDataCollectionPage subprojectDetail={subProjectsData || fetchSubProjectById?.data?.data?.data} /> */}
                            <SubprojectFieldDataList
                                subprojectViewPageActiveIndex={subprojectViewPageActiveIndex}
                                subprojectViewPageChildActiveIndex={subprojectViewPageChildActiveIndex}
                                selectedprojectItem={selectedprojectItem}
                                subprojectDetail={subProjectsData || fetchSubProjectById?.data?.data?.data}
                            />
                        </TabPanel>
                        {/* <TabPanel header="Sub Projects Reports">
                            <SubProjectReportPage subprojectDetail={subProjectsData || fetchSubProjectById?.data?.data?.data} />
                        </TabPanel> */}
                    </TabView>
                </Panel>
            </div>
        </div>
    );
};

export default SubProjectsViewPage;

// Sample usage:
// <SubProjectsViewPage project={{
//     name: 'Urban Development Project',
//     code: 'UD123',
//     department: 'Urban Planning',
//     type: 'Development',
//     responsibleOfficer: 'John Doe',
//     details: 'This project focuses on...',
//     districts: [{ id: '1', name: 'District 1' }, { id: '2', name: 'District 2' }]
// }} />
