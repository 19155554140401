import React, { useState, useRef, useEffect } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import setFieldTouched from "final-form-set-field-touched";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { AutoComplete } from "primereact/autocomplete";
import { ProgressSpinner } from "primereact/progressspinner";

import { Dropdown } from "primereact/dropdown";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { classNames } from "primereact/utils";

//
import { getAllOutputIndicators, getOutputIndicatorById, postOutputIndicator, updateOutputIndicator, deleteOutputIndicatorById } from "../../../../../../../services/directorates/output-indicator-service.js";
import { getAllYears, getYearById, postYears, updateYears, deleteYearById } from "../../../../../../../services/national-dashboard/years-service.js";
import { deleteDistrictById, getAllDistricts } from "../../../../../../../services/districts/districts-service";
import { deleteCountyById, getAllCounty } from "../../../../../../../services/county/county-service";
import { deleteSubcountyById, getAllSubcounty } from "../../../../../../../services/subcounty/subcounty-service";
import { deleteParishById, getAllParish } from "../../../../../../../services/parish/parish-service";
import { deleteVillageById, getAllVillage } from "../../../../../../../services/village/village-service";
import { deleteDepartmentById, getAllDepartments, getDepartmentByProgramId } from "../../../../../../../services/departments/department-service";
import {
    getAllDepartmentAdministrations,
    getDepartmentAdministrationById,
    postDepartmentAdministration,
    filterToGetDepartmentAdministrationDistrictsForMap,
    updateDepartmentAdministration,
    deleteDepartmentAdministrationById,
} from "../../../../../../../services/departments/department-administrations-service";
import { Toast as PrimeReactToast } from "primereact/toast";
import { getAllDepartmentUtilities, getDepartmentUtilitieById, postDepartmentUtilities, updateDepartmentUtilities, deleteDepartmentUtilitieById } from "../../../../../../../services/departments/utility-dept/department-utilities-service.js";
import { getAllUtilityTypes, getUtilityTypesById, postUtilityTypes, updateUtilityTypes, deleteUtilityTypesById } from "../../../../../../../services/settings/utility-types-service.jsx";
import { getAllFinancialYears, getFinancialYearsById, postFinancialYears, updateFinancialYears, deleteFinancialYears } from "../../../../../../../services/financial-years/financial-years-service";
import { getAllQuarters, getQuartersById, postQuarters, updateQuarters, deleteQuarters } from "../../../../../../../services/financial-years/quarters";

function RowForm({ filterQueryIsLaoding, currentDepartmentDetail, loggedInUserData, setFilterParams, filterSelectedObjects, setFilterSelectedObjects, handleSubmit, initialData, selectedParentItem, ...props }) {
    console.log("filterParams hhhh initialData : ", initialData);
    console.log("filterParams filterSelectedObjects : ", filterSelectedObjects);
    const toastRef = useRef(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(initialData);
    const queryClient = useQueryClient();

    const [selectedFinancialYear, setSelectedFinancialYear] = useState(filterSelectedObjects?.selectedFinancialYear);
    const [filteredFinancialYears, setFilteredFinancialYears] = useState([]);

    const [selectedQuarter, setSelectedQuarter] = useState(filterSelectedObjects?.selectedQuarter);
    const [filteredQuarters, setFilteredQuarters] = useState([]);

    const [selectedUtilityType, setSelectedUtilityType] = useState(filterSelectedObjects?.selectedUtilityType);
    const [filteredUtilityType, setFilteredUtilityType] = useState();

    const [selectedUtility, setSelectedUtility] = useState(filterSelectedObjects?.selectedUtility);
    const [filteredUtility, setFilteredUtility] = useState();

    const validate = (values) => {
        const errors = {};

        if (currentDepartmentDetail?.code == "03" && !!currentDepartmentDetail) {
            if (!values.utility_id) {
                errors.utility_id = "A Utility is required.";
            }

            if (!values.utility_type_id) {
                errors.utility_type_id = "A Utility Type is required.";
            }

            if (!values.fyear_id) {
                errors.fyear_id = "Financial Year is required.";
            }

            if (!values.quarter_id) {
                errors.quarter_id = "Quarter is required.";
            }
        }

        // Perform any other validation checks that you might need

        return errors;
    };

    // const onSubmitForm = (data) => {
    //     const errors = validate(data);
    //     if (Object.keys(errors).length === 0) {
    //         // No validation errors
    //         setPendingData(data);
    //         setShowConfirmDialog(true);
    //     } else {
    //         toast.warning("First Fill In All Required Fields");
    //     }
    // };

    const onSubmitForm = (data, form) => {
        if (selectedFinancialYear && !data.fyear_id) {
            data.fyear_id = selectedFinancialYear?.id;
        }
        if (selectedQuarter && !data.quarter_id) {
            data.quarter_id = selectedQuarter?.id;
        }
        // Add 'form' as an argument
        console.log("data for submission water utility regulation dashboard filters : ", data);
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            // Mark all fields as touched to show validation errors
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("First fill in all required fields.");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            let dataObjects = {
                selectedUtilityType,
                selectedUtility,
                selectedFinancialYear,
                selectedQuarter,
            };
            setFilterSelectedObjects(dataObjects);
            // setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    // Add reset function
    const resetForm = (form) => {
        setFilterSelectedObjects(null);
        setFilterParams(null);
        setPendingData(null);

        // Reset the form values
        form.restart();
    };

    console.log("selectedParentItem shsjfbdsj : ", selectedParentItem);

    if (selectedParentItem) {
        initialData = { subproject_id: selectedParentItem?.id, ...initialData };
    }

    useEffect(() => {
        if (getAllFinancialYearsQuery?.data?.data?.data?.length > 0) {
            const latestFinancialYear = getAllFinancialYearsQuery?.data?.data?.data[getAllFinancialYearsQuery?.data?.data?.data?.length - 1]; // Get the last item
            if (!selectedFinancialYear) {
                setSelectedFinancialYear(latestFinancialYear);
                setFilterSelectedObjects({ ...filterSelectedObjects, selectedFinancialYear: latestFinancialYear });
                setFilterParams({ ...initialData, fyear_id: selectedFinancialYear?.id });
            } // Update the financialYear state
        }

        if (getAllQuartersQuery?.data?.data?.data?.length > 0) {
            const latestQuarter = getAllQuartersQuery?.data?.data?.data[0];
            if (!selectedQuarter) {
                setSelectedQuarter(latestQuarter);
                setFilterSelectedObjects({ ...filterSelectedObjects, selectedQuarter: latestQuarter });
                setFilterParams({ ...initialData, quarter_id: selectedQuarter?.id });
            }
            // Update the financialYear state
        }
    }, [selectedQuarter, selectedFinancialYear]);

    // ================== get finacial years =================

    const getAllFinancialYearsQuery = useQuery(["financialYear"], getAllFinancialYears, {
        onSuccess: (data) => {
            // Assuming the latest financial year is the last item in the array
            if (data?.data?.data?.length > 0) {
                const latestFinancialYear = data?.data?.data[data?.data?.data?.length - 1];
                if (!selectedFinancialYear) {
                    setSelectedFinancialYear(latestFinancialYear);
                    setFilterSelectedObjects({ ...filterSelectedObjects, selectedFinancialYear: latestFinancialYear });
                    setFilterParams({ ...initialData, fyear_id: selectedFinancialYear?.id });
                } // // Update the financialYear state
            }
        },
        onError: (error) => {
            console.log("Error fetching getAllFinancialYears is : ", error);
        },
    });

    // ================= quarters ================

    const getAllQuartersQuery = useQuery(["quarters"], getAllQuarters, {
        onSuccess: (data) => {
            // Assuming the latest financial year is the last item in the array
            if (data?.data?.data?.length > 0) {
                const latestQuarter = getAllQuartersQuery?.data?.data?.data[0]; // Get the last item
                if (!selectedQuarter) {
                    setSelectedQuarter(latestQuarter);
                    setFilterSelectedObjects({ ...filterSelectedObjects, selectedQuarter: latestQuarter });
                    setFilterParams({ ...initialData, quarter_id: selectedQuarter?.id });
                } // Update the financialYear state
            }
        },
        onError: (error) => {
            console.log("Error fetching quarters is : ", error);
        },
    });

    // ======================  utility type ==============================

    const fetchAllDepartmentUtilityTypes = useQuery(["utility-types"], () => getAllUtilityTypes(), {
        enabled: currentDepartmentDetail?.code == "03",
        onSuccess: (data) => {
            console.log("success department fetching utility-types is : ", data);
        },
        onError: (error) => {
            console.log("Error fetching utility-types is : ", error);
        },
    });

    // ====================== department utility ==============================

    const fetchAllDepartmentUtilities = useQuery(
        ["utilities", currentDepartmentDetail?.id, selectedUtilityType?.id],
        () => (!currentDepartmentDetail?.id ? getAllDepartmentUtilities() : getAllDepartmentUtilities({ department_id: currentDepartmentDetail?.id, utility_type_id: selectedUtilityType?.id })),
        {
            enabled: currentDepartmentDetail?.code == "03" && !!selectedUtilityType?.id,
            onSuccess: (data) => {
                console.log("success department fetching utilities is : ", data);
            },
            onError: (error) => {
                console.log("Error fetching utilities is : ", error);
            },
        }
    );

    return (
        <div className="col-12 md:col-12">
            <PrimeReactToast ref={toastRef} />
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    mutators={{ setFieldTouched }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                console.log("values hhh : ", values);
                                console.log("event fffff : ", event);
                                onSubmitForm(values, form);
                                // handleSubmit(event, values);
                            }}
                        >
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                                {currentDepartmentDetail?.code == "03" && !!currentDepartmentDetail && (
                                    <>
                                        {" "}
                                        <Field name="utility_type_id">
                                            {({ input, meta }) => (
                                                <div className="m-2" style={{ maxWidth: "300px" }}>
                                                    <label htmlFor="utility_type_id">Utility Type</label>
                                                    <AutoComplete
                                                        value={selectedUtilityType?.name || ""}
                                                        key={selectedUtilityType?.name || "department-utility-default-key"}
                                                        suggestions={filteredUtilityType}
                                                        disabled={fetchAllDepartmentUtilityTypes.isLoading}
                                                        completeMethod={(e) => {
                                                            const results = fetchAllDepartmentUtilityTypes.data?.data?.data?.filter((department) => {
                                                                return department.name.toLowerCase().includes(e.query.toLowerCase());
                                                            });
                                                            setFilteredUtilityType(results);
                                                        }}
                                                        field="name"
                                                        dropdown={true}
                                                        onChange={(e) => {
                                                            if (typeof e.value === "string") {
                                                                // Update the display value to the typed string and reset the selected department
                                                                setSelectedUtilityType({ name: e.value });
                                                                input.onChange("");
                                                            } else if (typeof e.value === "object" && e.value !== null) {
                                                                // Update the selected department and set the form state with the selected department's ID
                                                                setSelectedUtilityType(e.value);
                                                                input.onChange(e.value.id);
                                                                // Clear the values of the child locations
                                                                // Reset the child fields

                                                                // setSelectedProject(null);
                                                                // setSelectedSubproject(null);
                                                                // setSelectedFinancialYear(null);
                                                                // setSelectedQuarter(null);
                                                                setSelectedUtility(null);

                                                                // // Conditional form changes
                                                                if (form) {
                                                                    form.change("utility_id", undefined);

                                                                    // form.change("project_id", undefined);
                                                                    // form.change("subproject_id", undefined);
                                                                    // form.change("fyear_id", undefined);
                                                                    // form.change("quarter_id", undefined);
                                                                }
                                                            }
                                                        }}
                                                        id="utility_type_id"
                                                        selectedItemTemplate={(value) => <div>{value ? value.value : "Select Utility Type"}</div>}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                    {fetchAllDepartmentUtilityTypes.isLoading && fetchAllDepartmentUtilityTypes.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            )}
                                        </Field>
                                        <Field name="utility_id">
                                            {({ input, meta }) => (
                                                <div className="m-2" style={{ maxWidth: "300px" }}>
                                                    <label htmlFor="utility_id">Utility</label>
                                                    <AutoComplete
                                                        value={selectedUtility?.name || ""}
                                                        key={selectedUtility?.name || "department-utility-default-key"}
                                                        suggestions={filteredUtility}
                                                        disabled={fetchAllDepartmentUtilities.isLoading}
                                                        completeMethod={(e) => {
                                                            const results = fetchAllDepartmentUtilities.data?.data?.data?.filter((department) => {
                                                                return department.name.toLowerCase().includes(e.query.toLowerCase());
                                                            });
                                                            setFilteredUtility(results);
                                                        }}
                                                        field="name"
                                                        dropdown={true}
                                                        onChange={(e) => {
                                                            if (typeof e.value === "string") {
                                                                // Update the display value to the typed string and reset the selected department
                                                                setSelectedUtility({ name: e.value });
                                                                input.onChange("");
                                                            } else if (typeof e.value === "object" && e.value !== null) {
                                                                // Update the selected department and set the form state with the selected department's ID
                                                                setSelectedUtility(e.value);
                                                                input.onChange(e.value.id);
                                                                // Clear the values of the child locations
                                                                // Reset the child fields

                                                                // setSelectedProject(null);
                                                                // setSelectedSubproject(null);
                                                                // setSelectedFinancialYear(null);
                                                                // setSelectedQuarter(null);

                                                                // // Conditional form changes
                                                                if (form) {
                                                                    // form.change("project_id", undefined);
                                                                    // form.change("subproject_id", undefined);
                                                                    // form.change("fyear_id", undefined);
                                                                    // form.change("quarter_id", undefined);
                                                                }
                                                            }
                                                        }}
                                                        id="utility_id"
                                                        selectedItemTemplate={(value) => <div>{value ? value.value : "Select a Utility"}</div>}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                    {fetchAllDepartmentUtilities.isLoading && fetchAllDepartmentUtilities.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            )}
                                        </Field>
                                    </>
                                )}

                                <Field name="fyear_id">
                                    {({ input, meta }) => (
                                        <div className="m-2" style={{ maxWidth: "300px" }}>
                                            <label htmlFor="financialYear">Financial Year</label>
                                            <AutoComplete
                                                value={selectedFinancialYear?.name || ""}
                                                key={selectedFinancialYear?.name || "finacial-year-key"}
                                                suggestions={filteredFinancialYears}
                                                completeMethod={(e) => {
                                                    const results = getAllFinancialYearsQuery?.data?.data?.data.filter((year) => {
                                                        return year.name.toLowerCase().includes(e.query.toLowerCase());
                                                    });
                                                    setFilteredFinancialYears(results);
                                                }}
                                                field="name"
                                                dropdown
                                                onChange={(e) => {
                                                    if (typeof e.value === "string") {
                                                        setSelectedFinancialYear({ name: e.value });
                                                        input.onChange("");
                                                    } else if (typeof e.value === "object" && e.value !== null) {
                                                        setSelectedFinancialYear(e.value);
                                                        input.onChange(e.value.id);
                                                    }
                                                }}
                                                id="fyear_id"
                                                disabled={getAllFinancialYearsQuery.isLoading}
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                            {getAllFinancialYearsQuery.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        </div>
                                    )}
                                </Field>
                                <Field name="quarter_id">
                                    {({ input, meta }) => (
                                        <div className="m-2" style={{ maxWidth: "300px" }}>
                                            <label htmlFor="quarter">Quarter</label>
                                            <AutoComplete
                                                value={selectedQuarter?.name || ""}
                                                key={selectedQuarter?.name || "quarter-key"}
                                                suggestions={filteredQuarters}
                                                completeMethod={(e) => {
                                                    const results = getAllQuartersQuery?.data?.data?.data.filter((quarter) => {
                                                        return quarter.name.toLowerCase().includes(e.query.toLowerCase());
                                                    });
                                                    setFilteredQuarters(results);
                                                }}
                                                field="name"
                                                dropdown
                                                onChange={(e) => {
                                                    if (typeof e.value === "string") {
                                                        setSelectedQuarter({ name: e.value });
                                                        input.onChange("");
                                                    } else if (typeof e.value === "object" && e.value !== null) {
                                                        setSelectedQuarter(e.value);
                                                        input.onChange(e.value.id);
                                                    }
                                                }}
                                                id="quarter_id"
                                                disabled={getAllQuartersQuery.isLoading}
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                            {getAllQuartersQuery.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        </div>
                                    )}
                                </Field>

                                <div className="d-grid gap-2" style={{ marginTop: "1rem", maxWidth: "300px" }}>
                                    <div className="m-2 fluid">
                                        <Button
                                            disabled={filterQueryIsLaoding}
                                            type="submit"
                                            label="Filter"
                                            //  disabled={!selectedYear}
                                            className="p-button-primary"
                                            icon="pi pi-check"
                                        />
                                    </div>
                                </div>

                                {filterSelectedObjects && (
                                    <div className="d-grid gap-2" style={{ marginTop: "1rem", maxWidth: "300px" }}>
                                        <div className="m-2">
                                            <Button disabled={filterQueryIsLaoding} type="button" label="Clear" className="p-button-secondary" icon="pi pi-times" onClick={() => resetForm(form)} />
                                        </div>{" "}
                                    </div>
                                )}
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    maximizable
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to Filter?
                </Dialog>
            </div>
        </div>
    );
}

export default RowForm;
