import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import { deleteDepartmentById, getAllDepartments, getDepartmentByProgramId } from "../../../../services/departments/department-service";
import { Calendar } from "primereact/calendar";
import moment from "moment";

import { useQuery } from "@tanstack/react-query";
import { classNames } from "primereact/utils";
import { deleteDistrictById, getAllDistricts } from "../../../../services/districts/districts-service";
import { AutoComplete } from "primereact/autocomplete";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DeleteIcon from "@mui/icons-material/Delete";
import setFieldTouched from "final-form-set-field-touched";
import { ProgressSpinner } from "primereact/progressspinner";
import { getAllDepartmentFunctions, getDepartmentFunctionById, postDepartmentFunction, updateDepartmentFunction, deleteDepartmentFunctionById } from "../../../../services/departments/department-functions-service.js";
import { getAllfunders, getfundersById, postfunders, updatefunders, deletefundersById } from "../../../../services/settings/funders-service";

//
import { getAllDepartmentAdministrations, getDepartmentAdministrationById, postDepartmentAdministration, updateDepartmentAdministration, deleteDepartmentAdministrationById } from "../../../../services/departments/department-administrations-service";
import { getAllDepartmentProjectClusters, getDepartmentProjectClusterById, postDepartmentProjectCluster, updateDepartmentProjectCluster, deleteDepartmentProjectClusterById } from "../../../../services/departments/department-project-clusters-service.js";
import { getAllCurrencies, getCurrenciesById, postCurrencies, updateCurrencies, deleteCurrenciesById } from "../../../../services/settings/currencies.jsx";

function ProjectsRowForm({ handleSubmit, ProjectsData, selectedParentDetail, ...props }) {
    console.log("Projects datta xxxxx : ", ProjectsData);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);

    const validate = (values) => {
        const errors = {};

        // Mandatory field validations
        if (!values.project_title) errors.project_title = "Project title is required";
        if (!values.project_code) errors.project_code = "Project code is required";

        if (!values.department_id) errors.department_id = "Department is required";
        if (!values.start_date) errors.start_date = "Please provide a valid Date.";
        if (!values.proposed_end_date) errors.proposed_end_date = "Please provide a valid date.";
        // Date validation to ensure end date is after start date
        if (values.start_date && values.proposed_end_date) {
            const startDate = new Date(values.start_date);
            const endDate = new Date(values.proposed_end_date);
            if (endDate <= startDate) {
                errors.proposed_end_date = "End date must be after the start date.";
            }
        }
        if (!values.completion || values.completion < 1 || values.completion > 100) errors.completion = "Please provide a valid value between 0 and 100.";

        // Individual responsible officer details validations
        // if (!values.responsible_officer_title) errors.responsible_officer_title = "Responsible officer title is required";
        // if (!values.responsible_officer_phonenumber) errors.responsible_officer_phonenumber = "Responsible officer phone number is required";
        // if (!values.responsible_officer_email) errors.responsible_officer_email = "Responsible officer email is required";
        // if (!values.responsible_officer_name) errors.responsible_officer_name = "Responsible officer name is required";

        // Additional field validations
        if (!values.vote) errors.vote = "Vote is required";
        if (!values.vote_code) errors.vote_code = "Vote code is required";
        if (!values.capital_current_ratio) errors.capital_current_ratio = "Capital-current ratio is required";
        if (!values.project_details) errors.project_details = "Project details are required";
        if (!values.target_beneficiaries) errors.target_beneficiaries = "Target beneficiaries are required";
        if (!values.target_beneficiaries_total) errors.target_beneficiaries_total = "Target beneficiaries Amount are required";
        if (!values.department_functions_id) errors.department_functions_id = "Department function is required";
        if (!values.status) errors.status = "Please provide a valid value.";
        // Completion Status
        if (!values.completion_status) errors.completion_status = "Please provide a valid completion status.";

        // Validation for department_administrations_id
        if (!values.department_administrations_id) {
            errors.department_administrations_id = "Department administration is required";
        }

        // Validation for department_project_clusters_id
        // if (!values.department_project_clusters_id) {
        //     errors.department_project_clusters_id = "Department project cluster is required";
        // }

        // Districts validation
        if (!values.districtIds || values.districtIds.length === 0) errors.districtIds = "At least one district is required";

        if (!values.funder_ids || values.funder_ids.length === 0) errors.funder_ids = "At least one Funder is required";

        if (!values.currency_ids || values.currency_ids.length === 0) errors.currency_ids = "At least one Currency is required";

        if (values.completion && values.completion == 100 && !values.actual_end_date) errors.actual_end_date = "Please provide an actual end date";
        if (values.completion && values.completion == 100 && !values.actual_cost) errors.actual_cost = "Please provide an actual cost";

        return errors;
    };

    // const onSubmitForm = (data) => {
    //     const errors = validate(data);
    //     if (Object.keys(errors).length === 0) {
    //         setPendingData(data);
    //         setShowConfirmDialog(true);
    //     } else {
    //         toast.warning("Please fill in all required fields");
    //     }
    // };

    const onSubmitForm = (data, form) => {
        // Add 'form' as an argument
        const actual_cost = typeof data?.actual_cost === "string" ? parseFloat(data.actual_cost.replace(/,/g, "")) : data?.actual_cost;
        const submissionData = {
            ...data,
            actual_cost,
        };
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            setPendingData(submissionData);
            setShowConfirmDialog(true);
        } else {
            // Mark all fields as touched to show validation errors
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("First fill in all required fields.");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => setShowConfirmDialog(false);

    //========================== department functions ===================
    const fetchAllDepartmentsFunctions = useQuery(["departments", "department-functions", selectedParentDetail?.department_id], () => (!selectedParentDetail?.id ? getAllDepartmentFunctions() : getAllDepartmentFunctions({ department_id: selectedParentDetail?.department_id })), {
        onSuccess: (data) => {
            console.log("fetching department-outcome-indicator-target data is : ", data);
        },
        onError: (error) => {
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("Án Error Occured Please Contact Admin");
            console.log("Error fetching department-outcome-indicator-target is : ", error);
        },
    });

    //====================== departments ========================
    console.log("selectedParentDetail dfdjskfjdsv : ", selectedParentDetail);

    const [selectedDepartment, setSelectedDepartment] = useState(ProjectsData?.department ? ProjectsData?.department : selectedParentDetail ? selectedParentDetail?.department : null);

    const [selectedDepartmentAdministration, setSelectedDepartmentAdministration] = useState(selectedParentDetail ? selectedParentDetail : null);
    // const [selectedDepartmentProjectCluster, setSelectedDepartmentProjectCluster] = useState(null);

    let getDepartmentId = selectedParentDetail ? selectedParentDetail?.department?.id : null;
    console.log("dsvdsvdsvds : dscdas : ", getDepartmentId);

    const fetchAllDepartments = useQuery(["departments", getDepartmentId], () => getAllDepartments({ department_id: getDepartmentId }), {
        onSuccess: (data) => {},
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("Án Error Occured Please Contact Admin");
            console.log("get list of departments : ", error);
        },
    });

    //================= DepartmentAdministrations ===========================
    const fetchAllDepartmentAdministrations = useQuery(["department-administration", getDepartmentId], () => getAllDepartmentAdministrations({ department_id: getDepartmentId }), {
        onSuccess: (data) => {},
        onError: (error) => {
            // props.onClose();
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("Án Error Occured Please Contact Admin");
            console.log("get list of departments : ", error);
        },
    });

    //==================  DepartmentProjectClusters =======================
    // const fetchAllDepartmentProjectClusters = useQuery(["department-project-clusters", getDepartmentId], () => getAllDepartmentProjectClusters({ department_id: getDepartmentId }), {
    //     onSuccess: (data) => {},
    //     onError: (error) => {
    //         // props.onClose();
    //         error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("Án Error Occured Please Contact Admin");
    //         console.log("get list of departments : ", error);
    //     },
    // });

    const districtIds = selectedParentDetail?.department_administration_districts?.map((item) => item.districtId) || [];
    console.log("Extracted district IDs: ", districtIds);

    //==================== districts ====================
    const getListOfDistricts = useQuery(["districts"], () => getAllDistricts({ districtIds: districtIds }), {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching districts : ", error);
            error?.response?.data?.message ? toast.error(error?.response?.data?.message) : !error?.response ? toast.warning("Check Your Internet Connection Please") : toast.error("Án Error Occured Please Contact Admin");
        },
    });

    const [dropdownDistricts, setDropdownDistricts] = useState([]);
    const [selectedDistrict, setSelectedDistrict] = useState(ProjectsData?.districts || []);
    console.log("kkkkkkk selectedDistrict xxxxx  : ", selectedDistrict);

    let projectWithDistrictIds = {
        department_id: ProjectsData?.department_id,
        department: ProjectsData?.department,
        ...ProjectsData,
        districtIds: ProjectsData?.districts?.map((district) => district.id),
    };

    console.log("ProjectDistrictIds : ", projectWithDistrictIds);

    // Special option for selecting all districts
    const selectAllOption = { id: "all", name: "Select All Districts" };

    //
    const fetchSuggestions = (query) => {
        let filteredDistricts = getListOfDistricts.data?.data?.data.filter((district) => {
            return !selectedDistrict.map((sp) => sp.id).includes(district.id) && district.name.toLowerCase().includes(query.toLowerCase());
        });

        // Add 'Select All' option only if no districts are selected
        if (selectedDistrict.length === 0 && (query.toLowerCase() === "select all" || query === "")) {
            filteredDistricts = [selectAllOption, ...filteredDistricts];
        }

        setDropdownDistricts(filteredDistricts);
    };

    // ===================== departments  ==================
    const [filteredDepartments, setFilteredDepartments] = useState();

    //=========================== data table pagination ======================
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(5); // Convert to state variable

    // Function to handle page changes
    const onPaging = (event) => {
        setPage(event.first / event.rows);
        setRows(event.rows); // Update number of rows per page
    };

    //===================== project type ====================
    const projectTypeOptions = [
        // { label: "None", value: null },
        { label: "Umbrella", value: "Umbrella" },
        { label: "Facility", value: "Facility" },
        { label: "Project", value: "Project" },
    ];

    // const projectTypeOptions = ["Umbrella", "Facility", "Project"];

    //
    if (selectedParentDetail) {
        projectWithDistrictIds = {
            ...projectWithDistrictIds,
            department_id: selectedParentDetail?.department_id,
            department: selectedParentDetail,
            vote: "Ministry Of Water And Enviroment",
            vote_code: "019",
            department_administrations_id: selectedParentDetail?.id,
        };
    }

    console.log("projectWithDistrictIds dsgsf : ", projectWithDistrictIds);

    //================ funders =======================

    const getAllFundersQuery = useQuery(["funders"], getAllfunders, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching getAllfunders is : ", error);
        },
    });

    //  const [dropdownItem, setDropdownItem] = useState([]);

    //  const [selectedAutoCompleteDropdownItem, setSelectedAutoCompleteDropdownItem] = useState(DirectorateData.programs || []);

    // const [dropdownDistricts, setDropdownDistricts] = useState([]);
    const [dropdownItem, setDropdownItem] = useState([]);
    // const [selectedDistrict, setSelectedDistrict] = useState(ProjectsData.districts || []);
    const [selectedAutoCompleteDropdownItem, setSelectedAutoCompleteDropdownItem] = useState(ProjectsData?.funders || []);

    if (ProjectsData?.funders) {
        projectWithDistrictIds = { ...projectWithDistrictIds, funder_ids: ProjectsData?.funders?.map((funder) => funder.id) };
    }
    console.log("kkkkkkk selectedDistrict xxxxx  : ", selectedAutoCompleteDropdownItem);

    // Special option for selecting all districts
    const selectAllFundersOption = { id: "all", name: "Select All" };

    //
    const fetchFundersSuggestions = (query) => {
        let filteredItems = getAllFundersQuery.data?.data?.data.filter((district) => {
            return !selectedAutoCompleteDropdownItem.map((sp) => sp.id).includes(district.id) && district.name.toLowerCase().includes(query.toLowerCase());
        });

        // Add 'Select All' option only if no districts are selected
        if (selectedAutoCompleteDropdownItem.length > 0 && (query.toLowerCase() === "select all" || query === "")) {
            filteredItems = [selectAllFundersOption, ...filteredItems];
        }

        setDropdownItem(filteredItems);
    };

    //=========================== data table pagination ======================
    const [funderPage, setFunderPage] = useState(0);
    const [funderRows, setFunderRows] = useState(5); // Convert to state variable

    // Function to handle page changes
    const onFunderPaging = (event) => {
        setFunderPage(event.first / event.rows);
        setFunderRows(event.rows); // Update number of rows per page
    };

    //============================================================== Projects Currencies ============================================================

    const getAllCurrenciesQuery = useQuery(["currencies"], getAllCurrencies, {
        onSuccess: (data) => {},
        onError: (error) => {
            console.log("Error fetching getAllcurrencies is : ", error);
        },
    });

    const [currencyDropdownItem, setCurrencyDropdownItem] = useState([]);
    // const [selectedDistrict, setSelectedDistrict] = useState(ProjectsData.districts || []);
    const [selectedCurrencyAutoCompleteDropdownItem, setSelectedCurrencyAutoCompleteDropdownItem] = useState(ProjectsData?.currencies || []);

    //currency_ids;
    if (ProjectsData?.currencies) {
        projectWithDistrictIds = { ...projectWithDistrictIds, currency_ids: ProjectsData?.currencies?.map((item) => item.id) };
    }
    console.log("kkkkkkk selectedDistrict xxxxx  : ", selectedCurrencyAutoCompleteDropdownItem);

    // Special option for selecting all districts
    const selectAllCurrencyOption = { id: "all", name: "Select All" };

    //
    const fetchCurrencySuggestions = (query) => {
        let filteredItems = getAllCurrenciesQuery.data?.data?.data.filter((district) => {
            return !selectedCurrencyAutoCompleteDropdownItem.map((sp) => sp.id).includes(district.id) && district.name.toLowerCase().includes(query.toLowerCase());
        });

        // Add 'Select All' option only if no districts are selected
        if (selectedCurrencyAutoCompleteDropdownItem.length > 0 && (query.toLowerCase() === "select all" || query === "")) {
            filteredItems = [selectAllCurrencyOption, ...filteredItems];
        }

        setCurrencyDropdownItem(filteredItems);
    };

    //=========================== data table pagination ======================
    const [currencyPage, setCurrencyPage] = useState(0);
    const [currencyRows, setCurrencyRows] = useState(5); // Convert to state variable

    // Function to handle page changes
    const onCurrencyPaging = (event) => {
        setCurrencyPage(event.first / event.rows);
        setCurrencyRows(event.rows); // Update number of rows per page
    };

    return (
        <div className="col-12 md:col-12">
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={projectWithDistrictIds}
                    initialValuesEqual={() => true}
                    // initialValuesEqual with a function returning true prevents the form from
                    // reinitializing when the initialValues prop changes. This is useful when you
                    // want to avoid re-rendering or reinitializing the form due to changes in initial values.
                    // Be cautious using this if your initial values are meant to be dynamic and responsive
                    // to changes in your application's state.
                    mutators={{ setFieldTouched }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form onSubmit={handleSubmit}>
                            <Field name="project_title">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="project_title">Project Title</label>
                                        <InputText {...input} id="project_title" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>
                            <Field name="project_code">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="project_code">Project Code</label>
                                        <InputText {...input} id="project_code" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="project_details">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="project_details">project details</label>
                                        <InputTextarea {...input} id="project_details" rows={5} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="department_id">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="department_id">Department</label>
                                        <AutoComplete
                                            value={selectedDepartment?.name || ""}
                                            suggestions={filteredDepartments}
                                            disabled={fetchAllDepartments.isLoading}
                                            completeMethod={(e) => {
                                                const results = fetchAllDepartments.data?.data?.data.filter((department) => {
                                                    return department.name.toLowerCase().startsWith(e.query.toLowerCase());
                                                });
                                                setFilteredDepartments(results);
                                            }}
                                            field="name"
                                            dropdown={true}
                                            onChange={(e) => {
                                                if (typeof e.value === "string") {
                                                    // Update the display value to the typed string and reset the selected department
                                                    setSelectedDepartment({ name: e.value });
                                                    input.onChange("");
                                                } else if (typeof e.value === "object" && e.value !== null) {
                                                    // Update the selected department and set the form state with the selected department's ID
                                                    setSelectedDepartment(e.value);
                                                    input.onChange(e.value.id);
                                                }
                                            }}
                                            id="department"
                                            selectedItemTemplate={(value) => <div>{value ? value.name : "Select a Department"}</div>}
                                        />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        {fetchAllDepartments.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                    </div>
                                )}
                            </Field>

                            <Field name="department_administrations_id">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label>Department Administration</label>
                                        <Dropdown
                                            value={selectedDepartmentAdministration?.id}
                                            options={fetchAllDepartmentAdministrations.data?.data?.data.map((item) => ({
                                                label: item.name,
                                                value: item.id,
                                            }))}
                                            onChange={(e) => {
                                                setSelectedDepartmentAdministration(e.value);
                                                input.onChange(e.value);
                                            }}
                                            placeholder="Select Department Administration"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            disabled={fetchAllDepartmentAdministrations.isLoading}
                                        />
                                        {fetchAllDepartmentAdministrations.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            {/* <Field name="department_project_clusters_id">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label>Department Project Cluster</label>
                                        <Dropdown
                                            // value={selectedDepartmentProjectCluster?.id}
                                            value={input.value}
                                            options={fetchAllDepartmentProjectClusters.data?.data?.data.map((item) => ({
                                                label: item.name,
                                                value: item.id,
                                            }))}
                                            onChange={(e) => {
                                                setSelectedDepartmentProjectCluster(e.value);
                                                input.onChange(e.value);
                                            }}
                                            placeholder="Select Department Project Cluster"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            disabled={fetchAllDepartmentProjectClusters.isLoading}
                                        />
                                        {fetchAllDepartmentProjectClusters.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field> */}

                            <div style={{ marginBottom: "1rem" }}>
                                <Field name="department_functions_id">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Department Functions</label>
                                            <Dropdown
                                                value={input.value}
                                                options={fetchAllDepartmentsFunctions?.data?.data?.data.map((item) => ({
                                                    label: item.name,
                                                    value: item.id,
                                                }))}
                                                onChange={(e) => input.onChange(e.value)}
                                                // disabled={props?.loggedInUserData?.role !== "Admin"}
                                                placeholder="Select Department Function"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                disabled={fetchAllDepartmentsFunctions.isLoading}
                                            />
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                            {fetchAllDepartmentsFunctions.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <Field name="vote">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="vote">Vote Name</label>
                                        <InputText {...input} id="vote" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>
                            <Field name="vote_code">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="vote_code">vote code</label>
                                        <InputText {...input} id="vote_code" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <div style={{ marginBottom: "1rem" }}>
                                <Field name="completion_status">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label>Completion Status</label>
                                            <Dropdown
                                                value={input.value}
                                                options={[
                                                    { label: "Ongoing", value: "Ongoing" },
                                                    { label: "Completed", value: "Completed" },
                                                ]}
                                                onChange={(e) => input.onChange(e.value)}
                                                placeholder="Select Status"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <Field name="completion">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label>Percentage Completion (%)</label>
                                        <InputText
                                            {...input}
                                            id="completion"
                                            type="number"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                // Allow only non-negative numbers
                                                if (!value || parseFloat(value) >= 0) {
                                                    input.onChange(value);
                                                }
                                            }}
                                        />
                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="status">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="status">Physical perfomence</label>
                                        <InputTextarea {...input} rows={5} cols={30} id="status" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>
                            {/* <Field name="completion">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label>Completion (%)</label>
                                        <InputText
                                            {...input}
                                            id="completion"
                                            type="number"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                // Allow only non-negative numbers
                                                if (!value || parseFloat(value) >= 0) {
                                                    input.onChange(value);
                                                }
                                            }}
                                        />
                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field> */}
                            <Field name="start_date">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="start_date">Start Date</label>
                                        <Calendar
                                            {...input}
                                            value={input.value ? new Date(input.value) : null} // Set the Calendar value
                                            showIcon
                                            showTime
                                            dateFormat="dd-mm-yy"
                                            hourFormat="24"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            onSelect={(e) => {
                                                // Format the date when selected and update the input value
                                                const formattedDate = moment(e.value).format("YYYY-MM-DD HH:mm:ss");
                                                input.onChange(formattedDate);
                                            }}
                                            onChange={(e) => {
                                                // Update the input field only if the value is a valid date
                                                if (e.value instanceof Date) {
                                                    const formattedDate = moment(e.value).format("YYYY-MM-DD HH:mm:ss");
                                                    input.onChange(formattedDate);
                                                }
                                            }}
                                        />
                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>
                            <Field name="proposed_end_date">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label>Proposed End Date</label>
                                        <Calendar
                                            {...input}
                                            value={input.value ? new Date(input.value) : null}
                                            showIcon
                                            showTime
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            dateFormat="dd-mm-yy"
                                            hourFormat="24"
                                            onChange={(e) => {
                                                // Update the input field only if the value is a valid date
                                                if (e.value instanceof Date) {
                                                    const formattedDate = moment(e.value).format("YYYY-MM-DD HH:mm:ss");
                                                    input.onChange(formattedDate);
                                                }
                                            }}
                                            onSelect={(e) => {
                                                // Format the date when selected and update the input value
                                                const formattedDate = moment(e.value).format("YYYY-MM-DD HH:mm:ss");
                                                input.onChange(formattedDate);
                                            }}
                                        />
                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="actual_end_date">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label>Actual End Date</label>
                                        <Calendar
                                            {...input}
                                            value={input.value ? new Date(input.value) : null}
                                            showIcon
                                            showTime
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            dateFormat="dd-mm-yy"
                                            hourFormat="24"
                                            onChange={(e) => {
                                                // Update the input field only if the value is a valid date
                                                if (e.value instanceof Date) {
                                                    const formattedDate = moment(e.value).format("YYYY-MM-DD HH:mm:ss");
                                                    input.onChange(formattedDate);
                                                }
                                            }}
                                            onSelect={(e) => {
                                                // Format the date when selected and update the input value
                                                const formattedDate = moment(e.value).format("YYYY-MM-DD HH:mm:ss");
                                                input.onChange(formattedDate);
                                            }}
                                        />
                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <div style={{ marginBottom: "1rem" }}>
                                <Field name="actual_cost">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="actual_cost">Actual Project Cost</label>
                                            <InputText
                                                {...input}
                                                id="actual_cost"
                                                type="text" // Use text type to allow formatting
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                value={input.value}
                                                onChange={(e) => {
                                                    const handleNumberChange = (input, value) => {
                                                        // Strip out non-numeric characters (except decimal point)
                                                        const numericValue = value.replace(/[^\d.]/g, "");

                                                        // Check the length of digits before the decimal point does not exceed 15
                                                        if (numericValue.split(".")[0].length > 15) {
                                                            // If it does, do not update the input value and potentially show a warning message
                                                            return;
                                                        }

                                                        // Convert to a number and then back to a string to remove leading zeroes
                                                        const number = numericValue ? parseFloat(numericValue).toString() : "";

                                                        // Format the number with commas for display purposes
                                                        const formattedValue = number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                                                        // Update the input's displayed value with formattedValue
                                                        input.onChange(formattedValue);
                                                    };
                                                    return handleNumberChange(input, e.target.value);
                                                }}
                                            />
                                            {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <Field name="currency_ids">
                                {({ input, meta }) => {
                                    console.log("kkkkkkk first currency ids input.value : ", input.value);
                                    return (
                                        <div className="p-field m-4">
                                            <label htmlFor="cunrrency_ids">Project Currencies</label>
                                            <AutoComplete
                                                {...input}
                                                suggestions={currencyDropdownItem}
                                                completeMethod={(e) => fetchCurrencySuggestions(e.query)}
                                                multiple={true}
                                                value={selectedCurrencyAutoCompleteDropdownItem}
                                                disabled={getAllCurrenciesQuery.isLoading} // Disable component if data is still loading
                                                field="name"
                                                dropdown={true}
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                onChange={(e) => {
                                                    const newSelected = e.value;

                                                    // Handle 'Select All Districts'
                                                    if (newSelected.some((district) => district.id === "all")) {
                                                        // Add all districts to the selection
                                                        const allDistricts = getAllCurrenciesQuery.data?.data?.data || [];
                                                        setSelectedCurrencyAutoCompleteDropdownItem(allDistricts);
                                                        input.onChange(allDistricts.map((district) => district.id));
                                                    } else {
                                                        if (newSelected.length < selectedCurrencyAutoCompleteDropdownItem.length) {
                                                            // District removed
                                                            const removedDistrict = selectedCurrencyAutoCompleteDropdownItem.find((district) => !newSelected.includes(district));
                                                            if (removedDistrict) {
                                                                const updatedSelectedDistricts = selectedCurrencyAutoCompleteDropdownItem.filter((district) => district.id !== removedDistrict.id);
                                                                setSelectedCurrencyAutoCompleteDropdownItem(updatedSelectedDistricts);
                                                                input.onChange(updatedSelectedDistricts.map((district) => district.id));
                                                            }
                                                        } else {
                                                            // District added
                                                            const addedDistrict = newSelected[newSelected.length - 1];
                                                            setSelectedCurrencyAutoCompleteDropdownItem([...selectedCurrencyAutoCompleteDropdownItem, addedDistrict]);
                                                            input.onChange([...input.value, addedDistrict.id]);
                                                        }
                                                    }

                                                    fetchCurrencySuggestions("");
                                                }}
                                                id="currency_ids"
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                            {/* DataTable to display selected programs */}
                                            {input.value && input.value.length > 0 && (
                                                <div className="p-field m-4">
                                                    <DataTable
                                                        value={selectedCurrencyAutoCompleteDropdownItem}
                                                        paginator
                                                        rows={currencyRows}
                                                        first={currencyPage * currencyRows}
                                                        onPage={onCurrencyPaging}
                                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                        rowsPerPageOptions={[5, 10, 20]}
                                                    >
                                                        <Column field="id" header="ID"></Column>
                                                        <Column field="name" header="Name"></Column>
                                                        <Column field="code" header="code"></Column>
                                                        <Column
                                                            body={(rowData) => (
                                                                <DeleteIcon
                                                                    style={{ color: "tomato", cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        const updatedSelectedCurrency = selectedCurrencyAutoCompleteDropdownItem.filter((district) => district.id !== rowData.id);
                                                                        setSelectedCurrencyAutoCompleteDropdownItem(updatedSelectedCurrency);
                                                                        input.onChange(updatedSelectedCurrency.map((prog) => prog.id));
                                                                        fetchCurrencySuggestions(""); // Refresh the dropdown suggestions
                                                                    }}
                                                                />
                                                            )}
                                                            header="Actions"
                                                        ></Column>
                                                    </DataTable>
                                                </div>
                                            )}

                                            {getAllFundersQuery.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        </div>
                                    );
                                }}
                            </Field>

                            <Field name="capital_current_ratio">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="capital_current_ratio">Capital Current Ratio</label>
                                        <InputText {...input} id="capital_current_ratio" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="funder_ids">
                                {({ input, meta }) => {
                                    console.log("kkkkkkk first main funders input.value : ", input.value);
                                    return (
                                        <div className="p-field m-4">
                                            <label htmlFor="funder_ids">Source of Funders</label>
                                            <AutoComplete
                                                {...input}
                                                suggestions={dropdownItem}
                                                completeMethod={(e) => fetchFundersSuggestions(e.query)}
                                                multiple={true}
                                                value={selectedAutoCompleteDropdownItem}
                                                disabled={getAllFundersQuery.isLoading} // Disable component if data is still loading
                                                field="name"
                                                dropdown={true}
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                onChange={(e) => {
                                                    const newSelected = e.value;

                                                    // Handle 'Select All Districts'
                                                    if (newSelected.some((district) => district.id === "all")) {
                                                        // Add all districts to the selection
                                                        const allDistricts = getAllFundersQuery.data?.data?.data || [];
                                                        setSelectedAutoCompleteDropdownItem(allDistricts);
                                                        input.onChange(allDistricts.map((district) => district.id));
                                                    } else {
                                                        if (newSelected.length < selectedAutoCompleteDropdownItem.length) {
                                                            // District removed
                                                            const removedDistrict = selectedAutoCompleteDropdownItem.find((district) => !newSelected.includes(district));
                                                            if (removedDistrict) {
                                                                const updatedSelectedDistricts = selectedAutoCompleteDropdownItem.filter((district) => district.id !== removedDistrict.id);
                                                                setSelectedAutoCompleteDropdownItem(updatedSelectedDistricts);
                                                                input.onChange(updatedSelectedDistricts.map((district) => district.id));
                                                            }
                                                        } else {
                                                            // District added
                                                            const addedDistrict = newSelected[newSelected.length - 1];
                                                            setSelectedAutoCompleteDropdownItem([...selectedAutoCompleteDropdownItem, addedDistrict]);
                                                            input.onChange([...input.value, addedDistrict.id]);
                                                        }
                                                    }

                                                    fetchFundersSuggestions("");
                                                }}
                                                id="funder_ids"
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                            {/* DataTable to display selected programs */}
                                            {input.value && input.value.length > 0 && (
                                                <div className="p-field m-4">
                                                    <DataTable
                                                        value={selectedAutoCompleteDropdownItem}
                                                        paginator
                                                        rows={funderRows}
                                                        first={funderPage * rows}
                                                        onPage={onFunderPaging}
                                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                        rowsPerPageOptions={[5, 10, 20]}
                                                    >
                                                        <Column field="id" header="ID"></Column>
                                                        <Column field="name" header="Name"></Column>
                                                        <Column field="code" header="code"></Column>
                                                        <Column
                                                            body={(rowData) => (
                                                                <DeleteIcon
                                                                    style={{ color: "tomato", cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        const updatedSelectedDistrict = selectedAutoCompleteDropdownItem.filter((district) => district.id !== rowData.id);
                                                                        setSelectedAutoCompleteDropdownItem(updatedSelectedDistrict);
                                                                        input.onChange(updatedSelectedDistrict.map((prog) => prog.id));
                                                                        fetchFundersSuggestions(""); // Refresh the dropdown suggestions
                                                                    }}
                                                                />
                                                            )}
                                                            header="Actions"
                                                        ></Column>
                                                    </DataTable>
                                                </div>
                                            )}

                                            {getAllFundersQuery.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        </div>
                                    );
                                }}
                            </Field>

                            <Field name="responsible_officer_title">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="responsible_officer_title">Responsible Officer Title</label>
                                        <InputText {...input} id="responsible_officer_title" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>
                            <Field name="responsible_officer_phonenumber">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="responsible_officer_phonenumber">Responsible Officer Phone Number</label>
                                        <InputText {...input} id="responsible_officer_phonenumber" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>
                            <Field name="responsible_officer_email">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="responsible_officer_email">Responsible Officer Email</label>
                                        <InputText {...input} id="responsible_officer_email" type="email" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>
                            <Field name="responsible_officer_name">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="responsible_officer_name">Responsible Officer Name</label>
                                        <InputText {...input} id="responsible_officer_name" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>
                            <Field name="target_beneficiaries_total">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="target_beneficiaries_total">Target beneficiaries (number)</label>
                                        <InputText
                                            {...input}
                                            id="target_beneficiaries_total"
                                            type="number"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                // Allow only non-negative numbers
                                                if (!value || parseFloat(value) >= 0) {
                                                    input.onChange(value);
                                                }
                                            }}
                                        />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>
                            <Field name="target_beneficiaries">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="target_beneficiaries">Target Beneficiaries (Description)</label>
                                        <InputTextarea {...input} id="target_beneficiaries" rows={5} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            {/* <Field name="department_id">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="department_id">Department</label>
                                        <AutoComplete
                                            value={selectedDepartment ? selectedDepartment.name : ""}
                                            suggestions={filteredDepartments}
                                            disabled={fetchAllDepartments.isLoading}
                                            completeMethod={(e) => {
                                                const results = fetchAllDepartments.data?.data?.data.filter((department) => {
                                                    return department.name.toLowerCase().startsWith(e.query.toLowerCase());
                                                });
                                                setFilteredDepartments(results);
                                            }}
                                            field="name"
                                            dropdown={true}
                                            onChange={(e) => {
                                                console.log("dddddddDepartment selected:", e.value);
                                                setSelectedDepartment(e.value); // Store the entire department object
                                                input.onChange(e.value ? e.value.id : ""); // Set only the ID in the form
                                            }}
                                            // onChange={(e) => {
                                            //     console.log("Department selected:", e.value);
                                            //     if (e.value && typeof e.value === "object") {
                                            //         setSelectedDepartment(e.value); // Store the entire department object
                                            //         input.onChange(e.value.id); // Set only the ID in the form
                                            //     }
                                            // }}
                                            id="department"
                                            selectedItemTemplate={(value) => <div>{value ? value.name : "Select a Department"}</div>}
                                        />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        {fetchAllDepartments.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                    </div>
                                )}
                            </Field> */}

                            {/* <Field name="responsible_officer">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="responsible_officer">Responsible Officer</label>
                                        <InputText {...input} id="responsible_officer" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field> */}
                            <Field name="districtIds">
                                {({ input, meta }) => {
                                    console.log("kkkkkkk first main ffffff input.value : ", input.value);
                                    return (
                                        <div className="p-field m-4">
                                            <label htmlFor="districtIds">Districts</label>
                                            <AutoComplete
                                                {...input}
                                                suggestions={dropdownDistricts}
                                                completeMethod={(e) => fetchSuggestions(e.query)}
                                                multiple={true}
                                                disabled={getListOfDistricts.isLoading}
                                                value={selectedDistrict}
                                                field="name"
                                                dropdown={true}
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                onChange={(e) => {
                                                    const newSelected = e.value;

                                                    // Handle 'Select All Districts'
                                                    if (newSelected.some((district) => district.id === "all")) {
                                                        // Add all districts to the selection
                                                        const allDistricts = getListOfDistricts.data?.data?.data || [];
                                                        setSelectedDistrict(allDistricts);
                                                        input.onChange(allDistricts.map((district) => district.id));
                                                    } else {
                                                        if (newSelected.length < selectedDistrict.length) {
                                                            // District removed
                                                            const removedDistrict = selectedDistrict.find((district) => !newSelected.includes(district));
                                                            if (removedDistrict) {
                                                                const updatedSelectedDistricts = selectedDistrict.filter((district) => district.id !== removedDistrict.id);
                                                                setSelectedDistrict(updatedSelectedDistricts);
                                                                input.onChange(updatedSelectedDistricts.map((district) => district.id));
                                                            }
                                                        } else {
                                                            // District added
                                                            const addedDistrict = newSelected[newSelected.length - 1];
                                                            setSelectedDistrict([...selectedDistrict, addedDistrict]);
                                                            input.onChange([...input.value, addedDistrict.id]);
                                                        }
                                                    }

                                                    fetchSuggestions("");
                                                }}
                                                id="districtIds"
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                            {/* DataTable to display selected programs */}
                                            {input.value && input.value.length > 0 && (
                                                <div className="p-field m-4">
                                                    <DataTable value={selectedDistrict} paginator rows={rows} first={page * rows} onPage={onPaging} paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" rowsPerPageOptions={[5, 10, 20]}>
                                                        <Column field="id" header="District ID"></Column>
                                                        <Column field="name" header="District Name"></Column>
                                                        <Column
                                                            body={(rowData) => (
                                                                <DeleteIcon
                                                                    style={{ color: "tomato", cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        const updatedSelectedDistrict = selectedDistrict.filter((district) => district.id !== rowData.id);
                                                                        setSelectedDistrict(updatedSelectedDistrict);
                                                                        input.onChange(updatedSelectedDistrict.map((prog) => prog.id));
                                                                        fetchSuggestions(""); // Refresh the dropdown suggestions
                                                                    }}
                                                                />
                                                            )}
                                                            header="Actions"
                                                        ></Column>
                                                    </DataTable>
                                                </div>
                                            )}
                                            {getListOfDistricts.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        </div>
                                    );
                                }}
                            </Field>
                            <div className="d-grid gap-2">
                                <Button type="submit" label="Save" className="p-button-primary" />
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    style={{ width: "30vw" }}
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to submit this form?
                </Dialog>
            </div>
        </div>
    );
}

export default ProjectsRowForm;
