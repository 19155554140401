import axiosAPI from "../axiosApi";

export async function getAllDepartmentReports(params = {}) {
    const response = await axiosAPI.get("department-reports", { params: params });
    return response;
}

export async function getDepartmentReportById(id) {
    const response = await axiosAPI.get(`department-reports/${id}`);
    return response;
}

export async function postDepartmentReport(data) {
    const response = await axiosAPI.post(`store-department-reports`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function updateDepartmentReport(id, data) {
    const response = await axiosAPI.put(`updateDepartmentReports/${id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function deleteDepartmentReportById(id) {
    const response = await axiosAPI.delete(`department-reports/${id}`);
    return response;
}

// export async function updateDepartmentReportStatus(data) {
//     const response = await axiosAPI.post("Departmentreport-update-status", data);
//     return response;
// }

export async function getDeptReportDashboardStats(params = {}) {
    const response = await axiosAPI.get("dept-dashboard-statistics", { params: params });
    return response;
}
