import React, { useState } from "react";
import DepartmentReportsList from "./components/department-reports/DepartmentReportsList";
import ProjectReportsList from "./components/project-reports/ProjectReportsList";
import SubProjectReportsList from "./components/subproject-reports/ListPage.js";
import UtilityDeptReportsViewPage from "./components/utility-department-reports/DeptReportsViewPage";
import UtilitiesReportPage from "./components/utility-reports/UtilitiesReportPage.js";
import Lottie from "lottie-react";
import GraphBlue from "../../../assets/lottiefiles/graphblue.json";
import { Panel } from "primereact/panel";

import ReportFiltersPage from "./components/report-filters/ReportFiltersPage.jsx";

function ReportsHandlePage({ loggedInUserData }) {
    const [filterParams, setFilterParams] = useState();
    const [filterQueryIsLoading, setFilterQueryIsLoading] = useState();
    const [reportsData, setReportsData] = useState();
    const [filterSelectedObjects, setFilterSelectedObjects] = useState();

    console.log("filterParams reports : ", filterParams);

    return (
        <>
            <ReportFiltersPage
                reportsData={reportsData}
                setReportsData={setReportsData}
                filterQueryIsLoading={filterQueryIsLoading}
                setFilterQueryIsLoading={setFilterQueryIsLoading}
                filterParams={filterParams}
                setFilterParams={setFilterParams}
                filterSelectedObjects={filterSelectedObjects}
                setFilterSelectedObjects={setFilterSelectedObjects}
                loggedInUserData={loggedInUserData}
            />

            {/* Conditional Rendering based on report_type */}
            {filterParams?.report_type === "department_reports" && filterSelectedObjects?.selectedDepartment?.code !== "03" && <DepartmentReportsList loggedInUserData={loggedInUserData} reportsData={reportsData?.data} filterQueryIsLoading={filterQueryIsLoading} />}
            {filterParams?.report_type === "department_reports" && filterSelectedObjects?.selectedDepartment?.code == "03" && <UtilityDeptReportsViewPage loggedInUserData={loggedInUserData} reportsData={reportsData?.data} filterQueryIsLoading={filterQueryIsLoading} />}
            {filterParams?.report_type === "utility_reports" && <UtilitiesReportPage loggedInUserData={loggedInUserData} reportsData={reportsData?.data} filterQueryIsLoading={filterQueryIsLoading} />}
            {filterParams?.report_type === "project_reports" && <ProjectReportsList loggedInUserData={loggedInUserData} reportsData={reportsData?.data} filterQueryIsLoading={filterQueryIsLoading} />}
            {filterParams?.report_type === "subproject_reports" && <SubProjectReportsList loggedInUserData={loggedInUserData} reportsData={reportsData?.data} filterQueryIsLoading={filterQueryIsLoading} />}
            {!["department_reports", "project_reports", "subproject_reports", "utility_reports"].includes(filterParams?.report_type) && (
                <Panel style={{ marginBottom: "20px" }}>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            justifyContent: "center",
                            marginTop: "3rem",
                        }}
                    >
                        <div style={{ width: "40%" }}>
                            <h3 style={{ margin: "1rem" }}>Filter To View Reports</h3>
                            <Lottie animationData={GraphBlue} loop={true} autoplay={true} />
                        </div>
                    </div>
                </Panel>
            )}
        </>
    );
}

export default ReportsHandlePage;
