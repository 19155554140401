import React, { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import CreateProjectReports from "./widgets/create-project-reports/CreateProjectReports.jsx";
import ProjectReportsList from "./widgets/project-reports/ProjectReportsList";

function ProjectsReportsViewPage({ subprojectDetail, selectedprojectItem, ...props }) {
    // State for active tab index
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <div style={{ width: "100%" }}>
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header="Reports">
                    {/* Content for Project Reports Tab */}
                    <ProjectReportsList subprojectDetail={subprojectDetail} selectedprojectItem={selectedprojectItem} />
                </TabPanel>
                <TabPanel header="Create Reports">
                    {/* Content for Create Reports Tab */}
                    <CreateProjectReports subprojectDetail={subprojectDetail} selectedprojectItem={selectedprojectItem} />
                </TabPanel>
            </TabView>
        </div>
    );
}

export default ProjectsReportsViewPage;
