import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { TabView, TabPanel } from "primereact/tabview";
import { ProgressBar } from "primereact/progressbar";
import Lottie from "lottie-react";
import NoData from "../../../assets/Animation - nodata lottie 1702085133252.json";

const UtilityChartCards = ({ filterQueryIsLoading, utilityIndicatorsData }) => {
    console.log("filterQueryIsLoading on utility graph : ", filterQueryIsLoading);
    const [graphContainerWidth, setGraphContainerWidth] = useState("100%");

    useEffect(() => {
        const handleResize = () => {
            setGraphContainerWidth(window.innerWidth >= 768 ? "420px" : "100%");
        };

        handleResize(); // Set initial value
        window.addEventListener("resize", handleResize); // Add event listener

        return () => window.removeEventListener("resize", handleResize); // Clean up
    }, []);

    // const generateChartOptions = (indicatorName, totalTargets, totalValues, unit) => {
    //     const yAxisTitle = unit ? `Counts (${unit})` : "Counts";
    //     return {
    //         chart: { type: "column" },
    //         title: { text: indicatorName },
    //         xAxis: { categories: ["Metrics"] },
    //         yAxis: {
    //             min: 0,
    //             title: { text: yAxisTitle },
    //         },

    //         credits: { enabled: false },
    //         series: [
    //             {
    //                 name: "Target",
    //                 data: [{ y: totalTargets, color: Highcharts.getOptions().colors[0] }],
    //             },
    //             {
    //                 name: "Actual",
    //                 data: [{ y: totalValues, color: Highcharts.getOptions().colors[1] }],
    //             },
    //         ],

    //         plotOptions: {
    //             column: {
    //                 dataLabels: {
    //                     enabled: true,
    //                     format: `{point.y} ${unit}`, // Display the unit next to each value
    //                 },
    //             },
    //         },
    //     };
    // };

    // const generateChartOptions = (indicatorName, totalTargets, totalValues, unit) => {
    //     const isPercentage = unit === "%";
    //     const yAxisTitle = `Counts ${isPercentage ? "(%)" : `(${unit})`}`;

    //     const options = {
    //         chart: {
    //             type: "column", // Use column type for both percentages and counts
    //         },
    //         title: {
    //             text: indicatorName,
    //         },
    //         xAxis: {
    //             categories: ["Metrics"],
    //         },
    //         yAxis: {
    //             min: 0,
    //             title: {
    //                 text: yAxisTitle,
    //             },
    //             labels: {
    //                 format: `{value} ${unit}`,
    //             },
    //         },
    //         credits: {
    //             enabled: false,
    //         },
    //         series: [
    //             {
    //                 name: "Actual",
    //                 data: [{ y: totalValues, color: Highcharts.getOptions().colors[1] }],
    //                 tooltip: {
    //                     valueSuffix: ` ${unit}`,
    //                 },
    //             },
    //         ],
    //         plotOptions: {
    //             column: {
    //                 dataLabels: {
    //                     enabled: true,
    //                     format: `{point.y} ${unit}`,
    //                 },
    //             },
    //         },
    //     };

    //     // Add target data if unit is not a percentage
    //     if (!isPercentage) {
    //         options.series.unshift({
    //             name: "Target",
    //             data: [{ y: totalTargets, color: Highcharts.getOptions().colors[0] }],
    //             tooltip: {
    //                 valueSuffix: ` ${unit}`,
    //             },
    //         });
    //     }

    //     return options;
    // };

    const generateChartOptions = (indicatorName, totalTargets, totalValues, unit) => {
        const isPercentage = unit === "%";

        if (isPercentage) {
            // Configuration for doughnut chart
            return {
                chart: {
                    type: "pie",
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                },
                title: {
                    text: indicatorName,
                },
                tooltip: {
                    // pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
                    pointFormat: "{point.name}: <b>{point.y:.1f} %</b>",
                },
                accessibility: {
                    point: {
                        valueSuffix: "%",
                    },
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: "pointer",
                        dataLabels: {
                            enabled: true,
                            // format: "<b>{point.name}</b>: {point.percentage:.1f} %",
                            format: "<b>{point.name}</b>: {point.y:.1f} %",
                        },
                        innerSize: "50%", // This creates a doughnut chart
                        colors: ["#50B432", "#ED561B", "#DDDF00", "#24CBE5", "#64E572", "#FF9655", "#FFF263", "#6AF9C4"], // Custom colors
                    },
                },
                credits: {
                    enabled: false,
                },
                series: [
                    {
                        name: "Actual",
                        colorByPoint: true,
                        data: [
                            {
                                name: "Actual",
                                y: totalValues,
                                color: "#50B432", // Green for actual
                            },
                            {
                                name: "Remaining",
                                y: 100 - totalValues,
                                color: "#ED561B", // Red for remaining
                            },
                        ],
                    },
                ],
            };
        } else {
            // Configuration for column chart
            return {
                chart: {
                    type: "column",
                },
                title: {
                    text: indicatorName,
                },
                xAxis: {
                    categories: ["Metrics"],
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: `Counts (${unit})`,
                    },
                    labels: {
                        format: `{value} ${unit}`,
                    },
                },
                credits: {
                    enabled: false,
                },
                series: [
                    {
                        name: "Target",
                        data: [{ y: totalTargets, color: Highcharts.getOptions().colors[0] }],
                        tooltip: {
                            valueSuffix: ` ${unit}`,
                        },
                    },
                    {
                        name: "Actual",
                        data: [{ y: totalValues, color: Highcharts.getOptions().colors[1] }],
                        tooltip: {
                            valueSuffix: ` ${unit}`,
                        },
                    },
                ],
                plotOptions: {
                    column: {
                        dataLabels: {
                            enabled: true,
                            format: `{point.y} ${unit}`,
                        },
                    },
                },
            };
        }
    };

    if (filterQueryIsLoading) {
        return (
            <div className="p-field m-4">
                <ProgressBar mode="indeterminate" style={{ height: "6px" }} />
            </div>
        );
    }
    return (
        <div>
            {/* Loader */}
            {/* {filterQueryIsLoading && (
                    <div className="p-field m-4">
                        <ProgressBar mode="indeterminate" style={{ height: "6px" }} />
                    </div>
                )} */}
            {/* Summary Cards */}
            <div className="p-grid">
                <div className="p-col-12 p-md-6">
                    {!Array.isArray(utilityIndicatorsData?.aggregated_utility_reports?.utilities) || utilityIndicatorsData?.aggregated_utility_reports?.utilities.length === 0 ? (
                        <center>
                            <Lottie
                                animationData={NoData}
                                loop={true}
                                autoplay={true}
                                style={{
                                    width: "300px", // Adjust width as needed
                                }}
                            />
                        </center>
                    ) : (
                        <Card title="Utilities">
                            {utilityIndicatorsData.aggregated_utility_reports.utilities.map((util, index) => (
                                <p key={index}>{util.name}</p>
                            ))}
                        </Card>
                    )}
                </div>
                <br />
                <div className="p-col-12 p-md-6">
                    {!Array.isArray(utilityIndicatorsData?.aggregated_utility_reports?.utilities) || utilityIndicatorsData?.aggregated_utility_reports?.utilities.length === 0 ? (
                        <center>
                            <Lottie
                                animationData={NoData}
                                loop={true}
                                autoplay={true}
                                style={{
                                    width: "300px", // Adjust width as needed
                                }}
                            />
                        </center>
                    ) : (
                        <Card title="Periods">
                            {utilityIndicatorsData?.aggregated_utility_reports?.periods?.map((period, index) => (
                                <p key={index}>
                                    {period.financial_year.name} ({period.quarters.map((q) => q.name).join(", ")})
                                </p>
                            ))}
                        </Card>
                    )}
                </div>
            </div>

            {/* Tabs for each indicator type */}
            <TabView className="m-2">
                {utilityIndicatorsData?.aggregated_utility_reports?.indicator_groups?.map((group, index) => (
                    <TabPanel key={index} header={group.indicator_type.name}>
                        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around" }}>
                            {group.indicators.map((indicator, idx) => (
                                <div key={idx} style={{ width: graphContainerWidth, margin: "0.5rem" }}>
                                    <Card>
                                        <HighchartsReact highcharts={Highcharts} options={generateChartOptions(indicator.indicator_details.name, indicator.total_targets, indicator.total_values, indicator.indicator_details.unit)} />
                                        {/* Statistics below each chart */}
                                        <div className="p-mt-3">
                                            <h5>
                                                {indicator.indicator_details.name} ({indicator.indicator_details.unit})
                                            </h5>
                                            {indicator.indicator_details.unit !== "%" && (
                                                <p>
                                                    Target: {indicator.total_targets} {indicator.indicator_details.unit}
                                                </p>
                                            )}
                                            <p>
                                                Actual: {indicator.total_values} {indicator.indicator_details.unit}
                                            </p>
                                        </div>
                                    </Card>
                                </div>
                            ))}
                        </div>
                    </TabPanel>
                ))}
            </TabView>
        </div>
    );
};

export default UtilityChartCards;
