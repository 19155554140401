import React from "react";
import moment from "moment";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Image } from "primereact/image";
import { Dialog } from "primereact/dialog";
import { ProgressBar } from "primereact/progressbar";
import { Tag } from "primereact/tag";

function AggregationPrint({ aggregatedData, ...props }) {
    // Helper function to format currency
    const formatCurrency = (value) => {
        return new Intl.NumberFormat("en-US", { style: "currency", currency: "UGX" }).format(value);
    };

    // Helper function to format date
    const formatDate = (dateString) => {
        return moment(dateString).format("MMMM Do YYYY, h:mm:ss a");
    };

    // Inline styles
    const tableHeaderStyle = {
        backgroundColor: "#00B9E8",
        color: "white",
        padding: "10px",
        textAlign: "left",
    };

    const tableCellStyle = {
        padding: "10px",
    };

    const cardStyle = {
        marginBottom: "20px",
        padding: "15px",
        border: "1px solid #ddd",
        borderRadius: "5px",
    };

    const cardTitleStyle = {
        color: "#00B9E8",
        fontWeight: "bold",
        marginBottom: "10px",
    };

    // Inline styles
    const tableStyle = {
        width: "100%",
        marginBottom: "20px",
        borderCollapse: "collapse",
    };

    const thStyle = {
        backgroundColor: "#00B9E8",
        color: "white",
        padding: "10px",
        textAlign: "left",
        border: "1px solid #ddd",
    };

    const tdStyle = {
        padding: "10px",
        border: "1px solid #ddd",
    };

    const riskTypeBodyTemplate = (rowData) => {
        let color;
        switch (rowData.risk_type) {
            case "high":
                color = "red";
                break;
            case "moderate":
                color = "orange";
                break;
            case "low":
                color = "grey";
                break;
            default:
                color = "black";
        }

        return (
            <div>
                <span className="p-mr-2" style={{ fontSize: "1.5rem", color, fontWeight: "bold" }}>
                    •
                </span>
                {rowData.risk_type.charAt(0).toUpperCase() + rowData.risk_type.slice(1)}
            </div>
        );
    };

    const gridStyle = {
        display: "grid",
        gridTemplateColumns: "1fr 1fr", // This creates two columns of equal width
        gridGap: "1rem", // Space between the columns
    };

    // Function to display status with color using Tag
    const tagStatus = (status) => {
        let severity;
        switch (status) {
            case "pending approval":
                severity = "info"; // blue
                break;
            case "approved":
                severity = "success"; // green
                break;
            case "pending":
                severity = "warning"; // orange
                break;
            case "rejected":
                severity = "danger"; // red
                break;
            default:
                severity = "secondary"; // grey or default
        }
        return <Tag severity={severity} value={status?.charAt(0)?.toUpperCase() + status?.slice(1)} />;
    };

    return (
        <div style={{ backgroundColor: "white" }}>
            {/* Ministry Logo */}
            <div style={{ display: "flex", gap: "1rem", textAlign: "center", alignItems: "center", justifyContent: "center", margin: "1rem", width: "100%" }}>
                <img src="/images/coatOfArms.png" alt="Coat of Arms of Uganda" style={{ width: "20px", height: "auto" }} />{" "}
                <span className="font-medium ml-2">
                    <span>Ministry of Water and Environment</span> <br /> <span>M & E Portal Aggregated Utility Report</span>
                </span>
            </div>
            {/* Department Information */}
            <div style={cardStyle}>
                <div style={cardTitleStyle}>Department Details</div>
                <div className="flex flex-wrap">
                    {/* Iterate over departments */}
                    {aggregatedData.departments.map((department, index) => (
                        <React.Fragment key={index}>
                            <div className="md:w-1/2 px-2 m-1">
                                <p>
                                    <strong>Department Name:</strong> {department.name || "N/A"}
                                </p>
                                <p>
                                    <strong>Department Details:</strong> {department.details || "N/A"}
                                </p>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
            {/* Periods Information */}
            <div style={cardStyle}>
                <div style={cardTitleStyle}>Periods Details</div>
                <div className="flex flex-wrap">
                    {/* Iterate over periods */}
                    {aggregatedData.periods.map((period, index) => (
                        <React.Fragment key={index}>
                            <div className="md:w-full px-2 m-2">
                                <p>
                                    <strong>Financial Year:</strong> {period.financial_year?.name}
                                </p>
                                <div>
                                    <strong>Quarters:</strong>
                                    <ul>
                                        {period.quarters.map((quarter, qIndex) => (
                                            <li key={qIndex}>{quarter.name}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
            {/*  */}
            <div>
                {aggregatedData?.outcomes?.map((outcome, outcomeIndex) => (
                    <div key={outcomeIndex} style={{ marginBottom: "1rem" }}>
                        <div style={cardTitleStyle}>{`Outcome: ${outcome.details.name}`}</div>
                        <table style={tableStyle}>
                            <thead>
                                <tr>
                                    <th style={thStyle}>Outcome Indicator Name</th>
                                    <th style={thStyle}>Total Value (Unit)</th>
                                    {/* <th style={thStyle}>Total Uncomputed Numerator (Unit)</th>
                                    <th style={thStyle}>Total Uncomputed Denominator (Unit)</th>
                                    <th style={thStyle}>Assessments</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {outcome.outcome_indicator_targets.map((target, targetIndex) => (
                                    <tr key={targetIndex}>
                                        <td style={tdStyle}>{target.details.name}</td>
                                        <td style={tdStyle}>{`${parseInt(target?.details?.show_manual_value ? target?.target_summations?.total_uncomputed_value : target?.target_summations?.total_value)} ${target?.details?.unit}`}</td>
                                        {/* <td style={tdStyle}>{`${target.target_summations.total_uncomputed_numerator} ${target.details.unit}`}</td>
                                        <td style={tdStyle}>{`${target.target_summations.total_uncomputed_denominator} ${target.details.unit}`}</td>
                                        <td style={tdStyle}>
                                            {target.assessments.map((assessment, assessIndex) => (
                                                <div key={assessIndex}>
                                                    <div>{`Assessment: ${assessment.uncomputed_assessment}`}</div>
                                                    <div>{`Reason of Variance: ${assessment.uncomputed_reason_of_variance}`}</div>
                                                </div>
                                            ))}
                                        </td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ))}
            </div>

            {/* KPIs */}
            <div style={cardStyle}>
                <div style={cardTitleStyle}>Output Indicators / (KPI's)</div>
                {aggregatedData?.indicator_groups?.map((group, groupIndex) => (
                    <div key={groupIndex} style={{ marginBottom: "1rem" }}>
                        <div style={cardTitleStyle}>{group.utility_theme.name}</div>
                        <table style={tableStyle}>
                            <thead>
                                <tr>
                                    <th style={thStyle}>Indicator Name</th>
                                    <th style={thStyle}>Target</th>
                                    <th style={thStyle}>Value</th>
                                    <th style={thStyle}>Reason of Variance</th>
                                    <th style={thStyle}>Assessment</th>
                                </tr>
                            </thead>
                            <tbody>
                                {group.utility_theme_report_indicators.map((indicator, index) => (
                                    <tr key={index}>
                                        <td style={tdStyle}>{indicator.output_indicator.name}</td>
                                        <td style={tdStyle}>{new Intl.NumberFormat().format(Number(indicator.total_targets))}</td>
                                        <td style={tdStyle}>{new Intl.NumberFormat().format(Number(indicator.total_values))}</td>
                                        <td style={tdStyle}>
                                            {indicator.assessments.map((assess, assessIndex) => (
                                                <div key={assessIndex}>{assess.reasonOfVariance}</div>
                                            ))}
                                        </td>
                                        <td style={tdStyle}>
                                            {indicator.assessments.map((assess, assessIndex) => (
                                                <div key={assessIndex}>{assess.assessment}</div>
                                            ))}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ))}
            </div>
            {/* Risks and Mitigations */}
            {aggregatedData?.risks && (
                <div style={cardStyle}>
                    <div style={cardTitleStyle}>Risks and Mitigations</div>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={thStyle}>Risk</th>
                                <th style={thStyle}>Risk Type</th>
                                <th style={thStyle}>Mitigation</th>
                            </tr>
                        </thead>
                        <tbody>
                            {aggregatedData.risks.map((risk, index) => (
                                <tr key={index}>
                                    <td style={tdStyle}>{risk.risk}</td>
                                    <td style={tdStyle}>{risk.risk_type}</td>
                                    <td style={tdStyle}>{risk.mitigation}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}

export default AggregationPrint;
