import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import CreateForm from "./CreateForm";

import EditForm from "./EditForm";

import { deleteProgramById, getAllPrograms } from "../../../../../services/programs/program-service";

import moment from "moment";

import { Link } from "react-router-dom";

import { getAllDepartmentBudjetOutputIndicators, getDepartmentBudjetOutputIndicatorById, postDepartmentBudjetOutputIndicator, updateDepartmentBudjetOutputIndicator, deleteDepartmentBudjetOutputIndicatorById } from "../../../../../services/departments/departments-budget-outputs-indicators-service";

import WaterIsLoading from "../../../../../components/mweComponents/WaterIsLoading";
import MuiTable from "../../../../../components/mweComponents/MuiTable";
import { toast } from "react-toastify";
import { Button } from "primereact/button";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

import { Panel } from "primereact/panel";

function ListPage({ selectedBudgetOutputItem, ...props }) {
    const queryClient = useQueryClient();

    console.log("selectedBudgetOutputItem?.id llll : ", selectedBudgetOutputItem);

    //
    const { data, isLoading, isError, error, status } = useQuery(
        ["departments", "departmentBudgetOutputs", "departmentBudgetOutputsIndicators", selectedBudgetOutputItem?.id],
        () => (!selectedBudgetOutputItem?.id ? getAllDepartmentBudjetOutputIndicators() : getAllDepartmentBudjetOutputIndicators({ department_budget_outputs_id: selectedBudgetOutputItem?.id, department_id: selectedBudgetOutputItem?.department_id })),
        {
            onSuccess: (data) => {
                console.log("fetching departmentBudgetOutputsIndicators data is : ", data);
            },
            onError: (error) => {
                console.log("Error fetching departmentBudgetOutputsIndicators is : ", error);
            },
        }
    );
    console.log(data);
    isError && toast.error("There was an error while fetching data");

    const DeleteSelectedItemMutation = useMutation((variables) => deleteDepartmentBudjetOutputIndicatorById(variables), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(["departments", "departmentBudgetOutputs", "departmentBudgetOutputsIndicators"]);
        },
    });

    // const handleDelete = async (event, id) => {
    //     var result = window.confirm("Are you sure you want to delete?");
    //     if (result === true) {
    //         ProgramDeleteMutation.mutate(id);
    //     }
    // };

    const handleDelete = (event, id) => {
        let selectedDeleteId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(selectedDeleteId),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (selectedDeleteId) => {
        if (selectedDeleteId !== null) {
            DeleteSelectedItemMutation.mutate(selectedDeleteId);
        }
    };

    const cancelDelete = () => {
        // setDeleteProgramId(null);
    };

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showBudjetOutPutAddForm, setShowBudjetOutPutAddForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    const onBudjetOutPutFormClose = () => {
        setShowBudjetOutPutAddForm(false);
    };

    let tableId = 0;
    const columns = [
        {
            title: "#",
            width: "5%",
            field: "name",
            render: (rowData) => {
                // tableId = rowData.tableData.id;
                tableId = tableId++;
                return <div>{rowData.tableData.index + 1}</div>;
                // return <div>{rowData.tableData.id}</div>;
            },
        },
        {
            title: "Name",
            field: "output_indicator.name",
        },
        { title: "Measure", field: "output_indicator.measure" },
        { title: "Budget Output", field: "department_budget_output.name" },
        { title: "Department", field: "department.name" },

        {
            title: "Date",
            hidden: true,
            field: "created_at",
            render: (rowData) => {
                return moment(rowData.created_at).format("lll");
            },
        },
    ];

    console.log("selectedBudgetOutputItem : ", selectedBudgetOutputItem);
    return (
        <div style={{ width: "100%" }}>
            <div className="col-12 xl:col-12">
                <div className="card">
                    <p>Budget Output Indicators are indicators thats contribute to budget outputs defined at department level which are later used by projects</p>
                </div>
            </div>
            <Panel header="Budget Output Indicators" style={{ marginBottom: "20px" }}>
                {/* <div className="d-flex justify-content-end mb-3" style={{ width: "100%" }}>
                    {activeUser?.permissions.includes("add programs") && <Button label="Add Program" className="p-button-primary right-0" onClick={() => setShowAddForm(true)} />}
                    <CreateForm show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} />
                </div> */}

                <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                    {/* {activeUser?.permissions.includes("add programs") && (
                        <Button
                            label="Add Budget Outputs"
                            className="p-button-primary"
                            onClick={() => {
                                setShowBudjetOutPutAddForm(true);
                                console.log("clicked add outcomes");
                            }}
                        />
                    )} */}
                    {activeUser?.permissions.includes("edit departments") && <Button label="Assign Output Indicator" className="p-button-primary" onClick={() => setShowAddForm(true)} />}
                    <CreateForm show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} projectId={props?.projectId} selectedBudgetOutputItem={selectedBudgetOutputItem} />
                    {/* <BudgetOutPutsCreateForm show={showBudjetOutPutAddForm} onHide={() => setShowBudjetOutPutAddForm(false)} onClose={onBudjetOutPutFormClose} /> */}
                </div>

                <MuiTable
                    tableTitle="Output Indicators"
                    tableData={data?.data?.data}
                    tableColumns={columns}
                    // handleShowEditForm={handleShowEditForm}
                    // showEdit={activeUser?.permissions.includes("edit programs") || activeUser?.permissions.includes("delete programs")}
                    handleDelete={(e, item_id) => handleDelete(e, item_id)}
                    showDelete={activeUser?.permissions.includes("edit departments")}
                    loading={isLoading || status === "loading" || DeleteSelectedItemMutation.isLoading}
                    exportButton={true}
                    pdfExportTitle="Department budget output indicators"
                    csvExportTitle="Department budget output indicators"
                />

                {selectedItem && <EditForm rowData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} />}

                {DeleteSelectedItemMutation.isLoading && <WaterIsLoading />}
                {/* <ConfirmDialog /> */}
            </Panel>
        </div>
    );
}

export default ListPage;
